import { login } from '../../login/login';
import { session } from '../../utils';
import refresh from '../refresh/refresh';

class NoActiveSessionException extends Error {
  constructor() {
    super('No active session. You need an active session before utilizing this call.');
  }
}

const DEFAULT_OPTIONS = {
  alwaysRun: false,
};

/**
 * Wraps a function ensure a valid session.
 *
 * Will refresh the current session if needed
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withSession = <T extends (...args: any[]) => any>(
  target: T,
  options: Partial<typeof DEFAULT_OPTIONS> = {},
) => {
  const resolvedOptions = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  return async (...args: Parameters<T>) => {
    // If this device has no active session, this call will throw an error.
    if (!session.isActive() && !resolvedOptions.alwaysRun) {
      throw new NoActiveSessionException();
    }

    // Although unclear, here this is considered an internal implementation detail
    // The refresh function will only interrupt flow when a refresh is neccesary.
    // So to shorten this implementation, we could leverage the already existing
    // implementation here, although confusing to read. Please consult:
    //
    // ../refresh/refresh.ts
    if (session.isActive()) {
      await refresh();
    }

    try {
      return await Promise.resolve(target(...args));
    } catch (error) {
      const apiError = error as { status: number };

      if (apiError.status === 401) {
        login({ redirect: window.location.href });
      }

      throw error;
    }
  };
};

export default withSession;
