import type { CtaFields } from '@boss/types/b2b-b2c';
import { IconCard, Link } from '@boss/ui';

import { SectionMapperProps } from '..';
import { getButtonProps } from '../../PropsMapper';

const LinkTilesMapper = ({ entry, theme, locale }: SectionMapperProps) => {
  const ctas = entry.content.filter((item): item is CtaFields => item?.__typename === 'cta');

  if (!ctas.length) {
    return null;
  }

  return (
    <div
      className="grid grid-cols-3 gap-2 sm:grid-cols-4 md:grid-cols-5 md:gap-6 lg:grid-cols-6"
      data-testid="link-tiles-grid"
    >
      {ctas.map(cta => {
        const { icon, href, label } = getButtonProps(cta, theme, locale);

        return (
          <Link className="w-full" href={href} key={cta.id ?? `${cta.label}-${cta.updatedAt}`}>
            <IconCard icon={icon} title={label} />
          </Link>
        );
      })}
    </div>
  );
};

export default LinkTilesMapper;
