import { twMerge } from 'tailwind-merge';

import { ColorPickerSkeletonPropsInstanceProps } from '..';
import { Repeat, Skeleton } from '../..';

const SecondaryColorPickerSkeleton = ({ className }: ColorPickerSkeletonPropsInstanceProps) => (
  <div className={twMerge('flex flex-col gap-5', className)}>
    <div className="flex items-center justify-between gap-5">
      <Skeleton className="w-15 h-6" />
      <div className="flex items-center gap-3">
        <Repeat amount={2}>
          <Skeleton className="h-12.5 w-20" />
        </Repeat>
      </div>
    </div>
    <div className="flex items-center justify-between gap-5 lg:grid lg:grid-cols-2">
      <Skeleton className="w-30 h-6" />
      <div className="flex items-center gap-5">
        <Skeleton className="h-[72px] w-[72px] flex-shrink-0 [&>*]:rounded-full" />
        <div className="flex flex-col gap-1">
          <Skeleton className="h-4 w-20" />
          <Skeleton className="h-3.5 w-20" />
        </div>
      </div>
    </div>
  </div>
);

export default SecondaryColorPickerSkeleton;
