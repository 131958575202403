import { useEffect, useState } from 'react';

import { IStore } from '@boss/services';
import { IAddressSuggestion } from '@boss/types/b2b-b2c';
import { shopIsOpen } from '@boss/utils';

import { useAddressById } from '../../client-queries';

interface StoreLocation extends IStore {
  distance?: number;
}

export type Coordinates = { latitude: number; longitude: number };

/**
 * Calculate the distance between two coordinates on earth as the crow flies
 * The calculation is done with the Haversine formula
 *
 * @returns distance in km
 */
const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const radius = 6371; // Earth's radius in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return radius * c;
};

/**
 * Sorts an array of stores from closest to furthest starting from the target coordinates.
 * Locations without coordinates are placed at the end of the list.
 */
const sortByClosestLocation = (arr: StoreLocation[], targetCoordinates: Coordinates): StoreLocation[] => {
  const locationsWithDistance: StoreLocation[] = arr.map(obj => {
    const { latitude, longitude } = obj;
    const { latitude: lat, longitude: lng } = targetCoordinates;

    // If either latitude or longitude is missing, set distance as undefined
    const distance =
      latitude !== undefined && longitude !== undefined ? calculateDistance(latitude, longitude, lat, lng) : undefined;

    return { ...obj, distance };
  });

  locationsWithDistance.sort((a, b) => {
    // Move locations without coordinates (distance undefined) to the end
    if (a.distance === undefined && b.distance === undefined) {
      return 0;
    }
    if (a.distance === undefined) {
      return 1;
    }
    if (b.distance === undefined) {
      return -1;
    }
    return a.distance - b.distance; // Sort by distance
  });

  return locationsWithDistance;
};

const sortByOpenStatus = (stores: IStore[]): IStore[] => {
  return [...stores.filter(store => shopIsOpen(store)), ...stores.filter(store => !shopIsOpen(store))];
};

/**
 * Custom hook for sorting the stores by distance, either by given coordinates
 * or by searching for coordinates with the BOSS API
 *
 * @returns object with search functions and react state
 */
export const useOrderStoresByProx = (stores: IStore[] = []) => {
  const [shouldSortByOpenStatus, setShouldSortByOpenStatus] = useState(false);
  const [isCurrentLocationSearch, setIsCurrentLocationSearch] = useState(false);
  const [location, setLocation] = useState('');

  const [filteredStores, setFilteredStores] = useState<IStore[]>([]);
  const [placeId, setPlaceId] = useState('');

  // Sort the stores with a fixed given coordinate
  const sortStoresByCoord = (c: Coordinates) => {
    if (stores?.length) {
      const sortedStores = sortByClosestLocation(stores, c);

      setFilteredStores(shouldSortByOpenStatus ? sortByOpenStatus(sortedStores) : sortedStores);
      setIsCurrentLocationSearch(true);
    }
  };

  const sortStoresByLocationId = (suggestion: IAddressSuggestion) => {
    setPlaceId(suggestion.placeid);
    setLocation(suggestion.description);
  };

  const resetStoreOrder = () => {
    setPlaceId('');
    setLocation('');
    setFilteredStores(stores);
    setIsCurrentLocationSearch(false);
  };

  // Fetch data from BOSS
  const { data: address, isLoading } = useAddressById(placeId);

  // Awaits the boss api for the lat/long values and then sort the store arr
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (stores?.length) {
      if (address) {
        const sortedStores = sortByClosestLocation(stores, address);

        setFilteredStores(shouldSortByOpenStatus ? sortByOpenStatus(sortedStores) : sortedStores);
      } else {
        setFilteredStores(stores);
      }
      setIsCurrentLocationSearch(false);
    }
  }, [stores, isLoading, address, shouldSortByOpenStatus]);

  useEffect(() => {
    if (shouldSortByOpenStatus) {
      setFilteredStores(currentStores => sortByOpenStatus(currentStores));
    }
  }, [shouldSortByOpenStatus]);

  return {
    location,
    isCurrentLocationSearch,
    sortStoresByLocationId,
    filteredStores,
    sortByOpenStatus,
    resetStoreOrder,
    sortStoresByCoord,
    setSortByOpenStatus: setShouldSortByOpenStatus,
  };
};

export default useOrderStoresByProx;
