import { Cta, LocalizedSlugs, NavigationItem, Nullable, SimpleImage } from '@boss/types/b2b-b2c';

import MainNavPrimary from './Primary';
import MainNavSecondary from './Secondary';
import { SearchResults } from '../SearchMenu';
export type MainNavTranslations = {
  menuLabel: string;
};

export type MainNavInstanceProps = {
  className?: string;
  closeMenu: () => void;
  favorite: NavigationItem;
  basket: NavigationItem & { amount: number };
  handleMobileMenuClick: () => void;
  highlightedCta: Nullable<NavigationItem>;
  isOpen?: boolean;
  localizedSlugs: LocalizedSlugs;
  logo: Nullable<SimpleImage>;
  extraNav: Nullable<Cta[]>;
  mainNav: Nullable<NavigationItem[]>;
  onSearch: () => void;
  personal: NavigationItem;
  searchLabel: string;
  secondaryNavItems: Nullable<NavigationItem[]>;
  translations: MainNavTranslations;
  searchValue: string;
  setSearchValue: (val: string) => void;
  type?: 'default' | 'simple';
  searchResults?: SearchResults;
  onCloseSearchMenu?: () => void;
  suggestions?: JSX.Element;
  barcodeScanner?: JSX.Element;
  showSearchSuggestions?: boolean;
  onShowSearchSuggestions?: (val: boolean) => void;
};

type MainNavProps = MainNavInstanceProps & {
  variant: 'primary' | 'secondary';
};

const MainNav = ({ variant, ...props }: MainNavProps) => {
  if (variant === 'primary') {
    return <MainNavPrimary {...props} />;
  }
  if (variant === 'secondary') {
    return <MainNavSecondary {...props} />;
  }
  return null;
};

export default MainNav;
