import { twMerge } from 'tailwind-merge';

import { ColorPickerSkeletonPropsInstanceProps } from '..';
import { Repeat, Skeleton } from '../..';

const PrimaryColorPickerSkeleton = ({ className }: ColorPickerSkeletonPropsInstanceProps) => (
  <div className={twMerge('flex flex-col gap-3 md:mb-4', className)}>
    <div className="flex items-center gap-2">
      <Skeleton className="h-5 w-40" />
      <Skeleton className="w-30 h-3.5" />
    </div>

    <Skeleton className="w-50 h-5" />

    <div className="flex items-center gap-4">
      <Skeleton className="h-20 w-20 flex-shrink-0 [&>*]:rounded-full" />

      <div className="flex w-full flex-col flex-wrap gap-3">
        <div className="flex h-8 flex-wrap gap-3 overflow-hidden">
          <Repeat amount={4}>
            <Skeleton className="h-8 w-8 [&>*]:rounded-full" />
          </Repeat>
        </div>
        <Skeleton className="w-50 h-12" />
      </div>
    </div>
  </div>
);

export default PrimaryColorPickerSkeleton;
