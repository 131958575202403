import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';

import { usePagination } from '@boss/hooks';
import { IInvoice, PAYMENT_TYPE } from '@boss/services/client';
import { Alert, Invoice, InvoiceProps, Pagination, Repeat, Skeleton, Tabs } from '@boss/ui';
import { downloadFile } from '@boss/utils';

import { useCreatePaymentRequest, useInvoices } from '../../../client-queries';
import { DateFilters } from '../../../components';
import { B2B_INVOICE_ID_PARAMETER, ORDER_STATUS_ID_PARAMETER } from '../../../constants/checkout';
import { PaymentStatus, getPaymentStatusByCode, isB2b } from '../../../utils';

type Props = {
  className?: string;
};

const Wrapper = ({ expired, children }: { expired: boolean; children: React.ReactNode }) => {
  const { t } = useTranslation('account');

  return expired ? (
    <div className="bg-yellow-soft px-5 py-5">
      <div className="text-brown-dark flex flex-col font-bold">
        <span>{t('invoices.notifications.expiredInvoice.title')}</span>
        <span>{t('invoices.notifications.expiredInvoice.subtitle')}</span>
        <span className="mt-4">{t('invoices.notifications.expiredInvoice.text')}</span>
      </div>
      <div className="md:px-15 mt-7">{children}</div>
    </div>
  ) : (
    <div>{children}</div>
  );
};

const Invoices = ({ className }: Props) => {
  const ITEMS_PER_PAGE = 5;
  const { t } = useTranslation('account');
  const { locale, query } = useRouter();
  const tabs = [
    { label: t('invoices.tabs.outstanding'), value: 'OUTSTANDING' },
    { label: t('invoices.tabs.paid'), value: 'PAID' },
  ];
  const { mutateAsync: createPaymentRequest } = useCreatePaymentRequest();

  const [filters, setFilters] = useState<{ fromDate?: string | undefined; toDate?: string | undefined }>({});
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  const {
    data: invoices,
    isError,
    isLoading,
  } = useInvoices(locale as string, {
    paid: activeTab === 'PAID',
    /*
     * Since we don't have a way to get the total number of invoices from the API,
     * we have to set the limit to a high number and then use the pagination to
     * limit the number of invoices per page.
     */
    limit: 100,
    offset: 0,
    ...filters,
  });

  const { currentPage, paginate, currentItems, totalPages } = usePagination<IInvoice>(ITEMS_PER_PAGE, invoices);
  const invoiceCardTranslations: InvoiceProps['translations'] = t('invoices.card', { returnObjects: true });

  const paidInvoiceId = query[B2B_INVOICE_ID_PARAMETER];
  const orderStatusIdParameter = query[ORDER_STATUS_ID_PARAMETER];
  const paymentStatus = getPaymentStatusByCode(Number(orderStatusIdParameter));

  useEffect(() => {
    if (paidInvoiceId && orderStatusIdParameter) {
      if (paymentStatus === PaymentStatus.SUCCESS) {
        toast.success(t('invoices.payment.success', { invoiceId: paidInvoiceId }));
      } else {
        toast.error(t('invoices.payment.error', { invoiceId: paidInvoiceId }));
      }
    }
  }, [paidInvoiceId, orderStatusIdParameter, paymentStatus, locale, t]);

  const payInvoice = async (invoice: IInvoice) => {
    const paymentInfo = await createPaymentRequest({
      type: PAYMENT_TYPE.invoice,
      locale: locale ?? 'nl',
      referenceId: `${invoice.recid}`,
      amount: invoice.amounttopay * 100,
      accountId: invoice.accountnumber,
      invoiceId: invoice.invoice,
    });

    if (paymentInfo) {
      window.location.href = paymentInfo?.paymentUrl;
    } else {
      toast.error(t('invoices.payment.createError'));
    }
  };

  return (
    <div className={className}>
      <h1 className="mb-5">{t('invoices.title')}</h1>
      <div className="mb-5 flex flex-col gap-7">
        {!!isError && <Alert type="error">{t('invoices.notifications.error')}</Alert>}
        <DateFilters fromDate={filters.fromDate} onSetFilters={setFilters} toDate={filters.toDate} />
        <Tabs
          activeTab={activeTab}
          onTabChange={tab => {
            setActiveTab(tab);
            paginate(0);
          }}
          tabs={tabs}
        />
        {!isLoading &&
          !!currentItems?.length &&
          currentItems?.map(invoice => (
            <Wrapper expired={invoice.expired} key={invoice.invoice}>
              <Invoice
                amount={activeTab !== 'PAID' ? invoice.amounttopay : invoice.amountcurrency}
                dueDate={new Date(invoice.duedate)}
                invoiceNumber={invoice.invoice}
                isCompleted={activeTab === 'PAID'}
                isExpired={invoice.expired}
                onHandleDownload={
                  invoice.documenturl && isB2b
                    ? () => {
                        downloadFile(invoice.documenturl, invoice.invoice);
                      }
                    : undefined
                }
                onHandlePayment={() => payInvoice(invoice)}
                paymentPending={paymentStatus === PaymentStatus.SUCCESS && `${invoice.invoice}` === paidInvoiceId}
                transactionDate={new Date(invoice.transactiondate)}
                translations={invoiceCardTranslations}
              />
            </Wrapper>
          ))}
        {!isLoading && !currentItems?.length && <Alert type="info">{t('invoices.notifications.noInvoices')}</Alert>}
        {isLoading && (
          <div className="flex flex-wrap items-center justify-between gap-5">
            <Repeat amount={ITEMS_PER_PAGE}>
              <Skeleton className="h-40 w-full" />
            </Repeat>
          </div>
        )}
        {totalPages > 1 && <Pagination currentPage={currentPage} onPaginate={paginate} totalPages={totalPages} />}
      </div>
    </div>
  );
};

export default Invoices;
