import { IBasketLine } from '@boss/services/client';
import { DeepPartial, IArticle, SimpleColor } from '@boss/types/b2b-b2c';

import { ArticleValues } from '../../components/ArticlesTable';

export const mapBasketLine = (article: IArticle, articleValues: ArticleValues, color?: SimpleColor): IBasketLine => ({
  item: {
    color: {
      colorcode: color?.code ?? '',
      colorname: color?.name ?? '',
      colorid: color?.id ?? '',
      rgb: color?.rgb ?? '',
    },
    skuid: article.id,
    description: article.name,
  },
  price: {
    salesprice: article.pricing.price,
    netamountexvat: article.pricing.price,
    netamountinclvat: article.pricing.price,
  },
  quantity: {
    salesquantity: articleValues.quantity ?? '',
    salesunit: articleValues?.unit ?? article?.unit ?? '',
    secondaryquantity: articleValues.quantity ?? '',
    secondaryunit: articleValues?.unit ?? article?.unit ?? '',
  },
});

/**
 * Utility function which returns a mock basketline based on a given newLine. This function is only useful for the optimistic UI portion of updating the basket
 * @param {AddBasketLine} line - line to be mapped to a basketLine
 **/
export const mapNewBasketLineToMockBasketLine = (line: DeepPartial<IBasketLine>): DeepPartial<IBasketLine> => ({
  creationdatetime: '',
  id: line.id ?? 'temp-id',
  item: {
    ...line?.item,
    vatcode: '',
  },
  quantity: { ...line.quantity, salesquantity: 1, salesunit: '' },
  price: line.price,
  lastsynceddatetimeutc: '',
  modifiedby: '',
});

export const getBasketTotalPrices = (lines: IBasketLine[]) =>
  lines.reduce(
    (prev, curr) => {
      const quantity = curr.quantity.salesquantity;

      return {
        salesPrice: prev.salesPrice + curr.price.salesprice * quantity,
        netAmountExVat: prev.netAmountExVat + curr.price.netamountexvat * quantity,
        netAmountInclVat: prev.netAmountInclVat + curr.price.netamountinclvat * quantity,
      };
    },
    { salesPrice: 0, netAmountExVat: 0, netAmountInclVat: 0 },
  );
