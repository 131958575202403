import { article } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false, authScope: process.env.BOSS_API_AUTH_SCOPE_PRODUCT };

/**
 * Fetch an article by article id
 */
export const getArticleByArticleIds = ({ articleIds, locale }: { articleIds: string[]; locale: string }) => {
  const queryPath = articleIds.map(articleId => `skuid=${articleId}`).join('&');

  return bossApi<article.Article[]>(`/product/v2/skus/byskuid?${queryPath}`, {
    ...DEFAULT_OPTIONS,
    m2m: false,
    locale,
  });
};

/**
 * Fetch articles by product id (non-paint)
 */
export const getArticleByProductId = ({ productId, locale }: { productId: string; locale: string }) => {
  return bossApi<article.Article[]>(`/product/v2/skus?productid=${productId}`, {
    ...DEFAULT_OPTIONS,
    m2m: false,
    locale,
  });
};

/**
 * Fetch an article by product id and color (mymx)
 */
export const getMymxArticles = ({
  productId,
  locale,
  colorId,
}: {
  productId: string;
  locale: string;
  colorId?: string;
}) => {
  const queryPath = colorId ? `productid=${productId}&colorid=${colorId}` : `productid=${productId}`;

  return bossApi<article.Article[]>(`/product/v2/skus/bymymx?${queryPath}`, {
    ...DEFAULT_OPTIONS,
    m2m: false,
    locale,
  });
};

/**
 * Fetch an article by product id and color (premix)
 */
export const getPremixedArticles = ({
  productId,
  locale,
  colorId,
}: {
  productId: string;
  locale: string;
  colorId: string;
}) => {
  return bossApi<article.Article[]>(`/product/v2/skus/bypremixedcolor?productid=${productId}&color=${colorId}`, {
    ...DEFAULT_OPTIONS,
    m2m: false,
    locale,
  });
};

/**
 * Fetch an article by product id and color (bybase)
 */
export const getBaseArticles = ({
  productId,
  locale,
  colorId,
}: {
  productId: string;
  locale: string;
  colorId: string;
}) => {
  return bossApi<article.Article[]>(`/product/v2/skus/bybase?productid=${productId}&base=${colorId}`, {
    ...DEFAULT_OPTIONS,
    m2m: false,
    locale,
  });
};

//fetch stock by warehouse ids
export const getArticleStockByWarehouseIds = async ({
  skus,
  warehouseIds,
}: {
  skus: {
    skuid: string;
    quantity?: number;
    unit?: string;
  }[];
  warehouseIds: string[];
}) => {
  const warehouseParameters = warehouseIds
    .filter(id => !!id)
    .map(warehouseId => `warehouse=${warehouseId}`)
    .join('&');

  return await bossApi<article.ArticleStock>(`/product/v2/skus/stockbywarehouses?${warehouseParameters}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    m2m: false,
    body: skus as unknown as Record<string, unknown>,
  });
};

/**
 * Fetches account price info by sku ids (use account token)
 * Don't use this function unless you don't need to specify the unit and quantity
 */
export const getAccountPriceInfoBySkuIds = ({ skuIds, locale }: { skuIds: string[]; locale: string }) => {
  const queryPath = skuIds.map(skuid => `skuid=${skuid}`).join('&');

  return bossApi<article.AccountPriceInfBlock[]>(`/user/product/v2/skus/priceinfo/byskuid?${queryPath}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Fetches account price info by sku id (use account token)
 */
export const getAccountPriceInfoBySkuId = ({
  skuId,
  unit,
  quantity,
  locale,
}: {
  skuId: string;
  locale: string;
  unit?: string;
  quantity?: number;
}) => {
  const queryPath = `unit=${unit}&qty=${quantity}`;

  return bossApi<article.AccountPriceInfBlock>(`/user/product/v2/skus/${skuId}/priceinfo/?${queryPath}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Fetches  account price info by article id (use account token)
 */

export const getDiscountInfoBySkuId = ({
  skuId,
  unit,
  locale,
  quantity,
}: {
  skuId: string;
  unit: string;
  locale: string;
  quantity: number;
}) => {
  return bossApi<article.SkuDiscountInfo>(`/user/product/v2/skus/${skuId}/discountinfo?unit=${unit}&qty=${quantity}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};
