import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const definition: IconDefinition = {
  prefix: 'fas', // Needs something predefined. Take fas. Has no value
  iconName: 'paint-roller', // Needs something predefined. Take closest value
  icon: [
    33, // Viewport width
    38, // Viewport height
    [], // Aliasses (no relevance for this approach) dummy value
    '2b', // unicode char (no relevance for this approach) dummy value
    // SVG Path or an array of SVG Paths
    'M30.4794 4.25H28.0732V1.15625C28.0732 0.584766 27.6134 0.125 27.0419 0.125L1.60442 0.125C1.03293 0.125 0.573166 0.584766 0.573166 1.15625L0.573166 12.8438C0.573166 13.4152 1.03293 13.875 1.60442 13.875L27.0419 13.875C27.6134 13.875 28.0732 13.4152 28.0732 12.8438L28.0732 7H29.4482L29.4482 15.5938L14.3661 15.5938C13.4165 15.5938 12.6474 16.3629 12.6474 17.3125V23.1563L10.7568 23.1562C10.5677 23.1562 10.413 23.3109 10.413 23.5L10.413 33.2969C10.413 33.3441 10.4216 33.3914 10.4388 33.4301C10.4216 33.4988 10.413 33.5676 10.413 33.6406C10.413 35.6344 12.0286 37.25 14.0224 37.25C16.0161 37.25 17.6318 35.6344 17.6318 33.6406C17.6318 33.5676 17.6232 33.4988 17.606 33.4301C17.6232 33.3871 17.6318 33.3441 17.6318 33.2969L17.6318 23.5C17.6318 23.3109 17.4771 23.1563 17.288 23.1563H15.3974V18.3438H30.4794C31.429 18.3438 32.1982 17.5746 32.1982 16.625V5.96875C32.1982 5.01914 31.429 4.25 30.4794 4.25Z',
  ],
};

export default definition;
