import { env, login, register } from '@boss/auth';
import { accountPageSlugs, loginPageSlugs, registerPageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';

import { useProfile } from '../use-profile/use-profile';

export const useLogin = () => {
  const { isLoggedIn } = useProfile();
  const { push, locale } = useRouter();

  const handleLogin = async (redirect = accountPageSlugs[locale]) => {
    if (env.FLOW === 'code') {
      return login({ redirect: `${window.location.origin}/${locale}/${redirect}` });
    }

    return push(`/${loginPageSlugs[locale]}?redirect=${redirect}`);
  };

  const navigateToProtectedPage = async (path?: string) => {
    if (isLoggedIn) {
      return push(path || `/${accountPageSlugs[locale]}`);
    }

    return await handleLogin();
  };

  const handleRegister = async (redirect = accountPageSlugs[locale]) => {
    if (env.FLOW === 'code') {
      return register({ redirect: `${window.location.origin}/${locale}/${redirect}` });
    }

    return push(`/${registerPageSlugs[locale]}?redirect=${redirect}`);
  };

  return {
    isLoggedIn,
    handleRegister,
    handleLogin,
    navigateToProtectedPage,
  };
};

export default useLogin;
