import { SectionDuplex } from '@boss/ui';

import { SectionMapperProps } from '..';
import { sectionQuoteImageProps, sectionQuoteProps, sectionQuoteQuoteProps } from '../../../../utils/options';
import ComponentMapper from '../../ComponentMapper';

const TwoColumnQuoteMapper = ({ entry, locale, className }: SectionMapperProps) => {
  const quoteContentIndex = entry.content.findIndex(item => item?.__typename === 'quote');
  const imageContentIndex = entry.content.findIndex(item => item?.__typename === 'image');

  const quoteContent = entry.content[quoteContentIndex];
  const imageContent = entry.content[imageContentIndex];

  if (!quoteContent && imageContent) {
    return null;
  }

  const imageFirst = imageContentIndex < quoteContentIndex;

  const imageComponent = (
    <ComponentMapper additionalProps={sectionQuoteImageProps} entry={imageContent} locale={locale} />
  );
  const quoteComponent = (
    <ComponentMapper additionalProps={sectionQuoteQuoteProps} entry={quoteContent} locale={locale} />
  );

  const contentA = imageFirst ? imageComponent : quoteComponent;
  const contentB = imageFirst ? quoteComponent : imageComponent;

  const additionalProps = sectionQuoteProps;

  const getFocus = () => {
    const focusValue = additionalProps?.focus;

    if (focusValue === 'image') {
      return imageFirst ? 'A' : 'B';
    }
    if (focusValue === 'quote') {
      return imageFirst ? 'B' : 'A';
    }

    return focusValue;
  };

  return (
    <SectionDuplex
      {...additionalProps}
      className={`${className} ${additionalProps?.className}`}
      contentA={contentA}
      contentB={contentB}
      focus={getFocus()}
    />
  );
};

export default TwoColumnQuoteMapper;
