import deepMerge from 'deepmerge';

import { FormColumn } from '../../components/DynamicForm';
import { FormField } from '../../components/Mappers/FormFieldMapper';

/**
 * Uses a list of field names to only return the desired fields from a formField list
 **/
export const getFieldsToShow = (baseFields: FormField[], fieldsToShow?: string[]) =>
  fieldsToShow
    ? fieldsToShow.reduce((prev: FormField[], currentFieldName: string) => {
        const currentFieldObject = baseFields.find(field => field.name === currentFieldName);

        if (currentFieldObject) {
          return [...prev, currentFieldObject];
        }

        return prev;
      }, [])
    : baseFields;

/**
 * Overwrites the values of a formField list with the values from a fieldsOverwrite object
 **/
export const applyFieldsOverwrite = (fields: FormField[], fieldsOverwrite?: Record<string, Partial<FormField>>) =>
  fieldsOverwrite
    ? fields.map(field => {
        const currentFieldOverwrite = fieldsOverwrite[field.name];

        if (currentFieldOverwrite) {
          return deepMerge(field, currentFieldOverwrite);
        }

        return field;
      })
    : fields;

/**
 * Function that builds the form fields
 * @param baseField FormField[] - Array of FormFields that function as a base for building this array of correct formFields
 * @param fieldsToShow string[] - Array of field names to return from the baseField array
 * @param additionalFields FormField[] - Array of additional FormFields to return from the baseField array
 * @param fieldsOverwrite Record<string, Partial<FormField>> - Object of field names to overwrite fields values of the base formfields
 * @returns FormField[] - Array of correct formFields to be used in the DynamicForm component.
 *
 * @example
 * buildFormFields(baseFields, fieldsToShow, additionalFields, fieldsOverwrite)
 **/
export const buildFormFields = (
  baseFields: FormField[],
  fieldsToShow?: string[],
  additionalFields?: FormField[],
  fieldsOverwrite?: Record<string, Partial<FormField>>,
) => {
  const fields = getFieldsToShow(baseFields.concat(additionalFields ?? []), fieldsToShow);

  return applyFieldsOverwrite(fields, fieldsOverwrite);
};

const excludeFormFields = (fieldNames: string[], fields: FormField[]) =>
  fields.filter(field => !fieldNames.includes(field.name));

const excludeFormFieldFromFormColumns = (fieldNames: string[], columns: FormColumn[]) =>
  columns.map(column => ({ ...column, fields: excludeFormFields(fieldNames, column.fields) }));

/**
 * Function that excludes certain country specific fields to ensure consistent behaviour
 * @param locale string - The current locale
 * @param columns FormColumn[] - A list of form columns which contain formFields
 * @returns FormColumn[] - Array of all desired FormColumns
 **/
export const alignCountrySpecificFields = (locale: string, columns: FormColumn[]) => {
  if (locale === 'nl-NL') {
    return columns;
  }

  return excludeFormFieldFromFormColumns(['kvknumber'], columns);
};
