import { Presence, Skeleton } from '@boss/ui';

import { useProductById } from '../../../client-queries';
import { ProductCardWrapper } from '../../../components';
import { useProductMap } from '../../../hooks';

type Props = {
  id: string;
};

const ProductCard = ({ id }: Props) => {
  const { data: product, isLoading } = useProductById(id);
  const { mapProductComponent } = useProductMap();

  return (
    <Presence
      className="h-full"
      id="product-card-presence"
      isLoading={isLoading}
      loader={<Skeleton className="h-100" />}
      visible
    >
      {product && <ProductCardWrapper {...mapProductComponent(product)} />}
    </Presence>
  );
};

export default ProductCard;
