import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { IWishlist } from '@boss/services/client';

import {
  useCreateWishlist,
  useCreateWishlistLine,
  useCreateWishlistLines,
  useDeleteWishlistLine,
  useUpdateWishlist,
  useUpdateWishlistLine,
} from '../../client-queries';

export const useWishlistOperations = (list?: IWishlist) => {
  const { t } = useTranslation('common');

  const updateSuccessToast = {
    title: t('toast.updateWishlist.success.title'),
    description: t('toast.updateWishlist.success.description'),
  };

  const updateErrorToast = {
    title: t('errors.submitTitle'),
    description: t('success.submitSubtitle'),
  };

  const {
    mutate: createWishlist,
    isError: createWishListError,
    isLoading: createWishlistLoading,
  } = useCreateWishlist({
    toasts: { success: updateSuccessToast, error: updateErrorToast },
  });

  const {
    mutate: deleteWishlistLine,
    isError: deleteWishListError,
    isLoading: deleteWishlistLoading,
  } = useDeleteWishlistLine({
    toasts: { success: updateSuccessToast, error: updateErrorToast },
  });
  const {
    mutateAsync: createWishlistLine,
    isError: createWishListLineError,
    isLoading: createWishlistLineLoading,
  } = useCreateWishlistLine({
    toasts: { success: updateSuccessToast, error: updateErrorToast },
  });
  const {
    mutateAsync: createWishlistLines,
    isError: createWishListLinesError,
    isLoading: createWishlistLinesLoading,
  } = useCreateWishlistLines({
    toasts: { success: updateSuccessToast, error: updateErrorToast },
  });
  const {
    mutate: updateWishlistLine,
    isError: updateWishListError,
    isLoading: updateWishlistLoading,
  } = useUpdateWishlistLine({
    toasts: { success: updateSuccessToast, error: updateErrorToast },
  });

  const { mutate: updateWishlist } = useUpdateWishlist({
    toasts: { success: updateSuccessToast, error: updateErrorToast },
  });
  const [listName, setListName] = useState<string>(list?.description ?? '');
  const [editMode, setEditMode] = useState<boolean>(false);
  const showError =
    (createWishListLineError && !createWishlistLineLoading) || (deleteWishListError && !deleteWishlistLoading);

  const updateListName = () => {
    if (list) {
      updateWishlist({
        ...list,
        description: listName,
      });
    }

    setEditMode(false);
  };

  const removeFromList = (lineId: string) => {
    const wishlistLineId = list?.lines.find(line => line.id === lineId)?.id;
    const wishlistId = list?.id ?? '';

    if (wishlistLineId && wishlistId) {
      deleteWishlistLine({
        wishlistLineId,
        wishlistId,
        wishlistType: list.type,
      });
    }
  };

  return {
    createWishlist,
    createWishListError,
    createWishlistLoading,
    createWishlistLine,
    createWishlistLines,
    createWishListLinesError,
    createWishlistLinesLoading,
    deleteWishlistLine,
    deleteWishListError,
    deleteWishlistLoading,
    createWishListLineError,
    createWishlistLineLoading,
    updateWishlistLine,
    updateWishListError,
    updateWishlistLoading,
    updateWishlist,
    updateListName,
    removeFromList,
    listName,
    setListName,
    editMode,
    setEditMode,
    showError,
  };
};
