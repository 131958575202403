import FormFieldWrapper from '../FormFieldWrapper';
import { InputFieldProps, InputFieldStyles } from '../InputField';

type Props = Omit<InputFieldProps, 'type' | 'onBlur' | 'onChange'> & {
  rows?: number;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

const Textarea = ({
  className,
  error,
  label,
  variant,
  name,
  onBlur,
  onChange,
  placeholder,
  rows = 4,
  touched,
  value,
  disclaimer,
  required,
  tooltip,
  id,
}: Props) => {
  return (
    <FormFieldWrapper
      className={className}
      disclaimer={disclaimer}
      error={error}
      id={id}
      label={label}
      name={name}
      required={required}
      tooltip={tooltip}
      touched={touched}
    >
      <textarea
        className={InputFieldStyles({
          error: !!error,
          variant,
        })}
        id={id ?? name}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        value={value}
      />
    </FormFieldWrapper>
  );
};

export default Textarea;
