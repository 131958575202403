import { cva } from 'class-variance-authority';

import { RichText } from '@boss/rich-text';
import { StepByStepPlanStepFields } from '@boss/types/b2b-b2c';
import { slugify } from '@boss/utils';

import RoundedItem from './RoundedItem';
import { ComponentMapper } from '../Mappers';

type propsType = {
  steps: StepByStepPlanStepFields[];
  locale: string;
};

const titleColor = 'text-brown-dark';

const StepsTitleStyle = cva('text-h4-mobile md:text-h3 ml-3 md:ml-6', {
  variants: {
    theme: {
      colora: titleColor,
      'boss-paints': titleColor,
      corporate: titleColor,
      jobs: titleColor,
    },
  },
});

const StepByStep = ({ steps, locale }: propsType) => {
  return (
    <div className="relative flex flex-col">
      <ul className="mb-25">
        {steps.map((step, index) => (
          <li className="scroll-mt-15" id={slugify(step.stepTitle)} key={step.id}>
            <div className="flex flex-col">
              <div className={`my-2 flex flex-row items-center md:my-4 ${index === 0 && 'mt-0 md:mt-0'}`}>
                <RoundedItem className="relative h-10 w-10 bg-white">{index + 1}</RoundedItem>
                <h3 className={StepsTitleStyle({ theme: 'colora' })}>{step.stepTitle}</h3>
              </div>
              <div
                className={`border-steps-borderColor ml-5 border-l-4 border-solid pl-11 ${
                  index === steps.length - 1 && 'border-none'
                }`}
              >
                <RichText
                  ComponentMapper={ComponentMapper}
                  content={step.content.content}
                  mapperOptions={{ theme: 'blue', locale }}
                  renderOptions={{ noXSpacing: true }}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StepByStep;
