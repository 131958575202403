import { useTranslation } from 'next-i18next';

import { RegisterSection } from '../../components';

const RegisterPage = () => {
  const { t } = useTranslation('register');

  return (
    <div className="flex flex-col">
      <h1 className="mt-4 md:mt-12">{t('title')}</h1>
      <RegisterSection />
    </div>
  );
};

export default RegisterPage;
