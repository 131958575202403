import { session, shouldRefresh } from '../../utils';
import env from '../env';
import { fromLoginAttempt } from '../login/login';
import logout from '../logout/logout';

export const refresh = async () => {
  const profile = session.getData();

  if (shouldRefresh(profile) && session.getStatus() === 'idle') {
    try {
      session.setStatus('loading');
      const result = await fetch(`${env.PATH_PREFIX}/refresh`);

      await handleFetchResult(result);
    } catch (error) {
      logout();
    }

    session.setStatus('idle');
  }
};

// Handles the response from the fetch call.
const handleFetchResult = async (result: Response) => {
  // Handle client errors.
  if (result.status >= 400 && result.status < 500) {
    if (fromLoginAttempt()) {
      session.deactivate();
    }
    return;
  }

  // Process the successful response.
  const resultData = await result.json();

  session.activate(resultData);
};

export default refresh;
