import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { IProductCategory } from '@boss/services';
import { Nullable } from '@boss/types/b2b-b2c';
import { Link, Pill, Section } from '@boss/ui';

import { mapNextToContentfulLocale } from '../../utils/localeMapper';

type Props = {
  className?: string;
  title: Nullable<string>;
  categories: IProductCategory[];
};

const CategoryPillsSection = ({ className, categories, title }: Props) => {
  const { locale } = useRouter();

  return (
    <Section
      className={twMerge('flex flex-col gap-2 md:gap-6', className)}
      content={
        <>
          {title && <span className="h2">{title}</span>}
          <div className="mx-[calc((100vw-100%)/-2)] flex gap-x-4 gap-y-2 overflow-auto px-[calc((100vw-100%)/2)] md:mx-0 md:flex-wrap md:px-0">
            {categories.map(category => (
              <Link href={category.uris[mapNextToContentfulLocale(locale)]} key={`${category.id}-pill`}>
                <Pill className="bg-pillBrown text-white">{category.description}</Pill>
              </Link>
            ))}
          </div>
        </>
      }
      testId="category-overview-page-popular-categories"
    />
  );
};

export default CategoryPillsSection;
