import { Asset } from 'contentful';

import { useIsSSR } from '@boss/hooks';
import { Nullable } from '@boss/types/b2b-b2c';

import LoginSectionPrimary from './Primary';
import LoginSectionSecondary from './Secondary';
import { useProfile } from '../../hooks';
import { loginSectionConfig } from '../../utils';

type Props = {
  footerLink?: {
    href: string;
    label: string;
  };
  visible?: 'always' | 'auth' | 'anonymous';
  bannerImage?: Nullable<Asset>;
};

export type LoginSectionProps = Omit<Props, 'visible'>;

const LoginSection = ({ footerLink, visible = 'always', bannerImage }: Props) => {
  const isSSR = useIsSSR();
  const { isLoggedIn } = useProfile();
  const { variant } = loginSectionConfig;

  if (
    (visible !== 'always' && isSSR) ||
    (visible === 'anonymous' && isLoggedIn) ||
    (visible === 'auth' && !isLoggedIn)
  ) {
    return null;
  }

  if (variant === 'primary') {
    return <LoginSectionPrimary footerLink={footerLink} />;
  }

  if (variant === 'secondary') {
    return <LoginSectionSecondary bannerImage={bannerImage} />;
  }

  return null;
};

export default LoginSection;
