import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent } from 'react';
import { twMerge } from 'tailwind-merge';

import FormFieldWrapper, { BaseFieldProps } from '../FormFieldWrapper';
import { InputFieldStyles } from '../InputField';

type Option = {
  value: string;
  label: string;
};

type Props = BaseFieldProps & {
  light?: boolean;
  options: Option[];
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  selectClassName?: string;
  iconClassName?: string;
  value?: string;
  placeholder?: string | null;
};

const Select = ({
  touched,
  disclaimer,
  error,
  label,
  value,
  name,
  options,
  onChange,
  className,
  selectClassName,
  iconClassName,
  required,
  tooltip,
  id,
  placeholder,
  variant = 'light',
}: Props) => (
  <FormFieldWrapper
    className={className}
    disclaimer={disclaimer}
    error={error}
    id={id}
    label={label}
    name={name}
    required={required}
    tooltip={tooltip}
    touched={touched}
  >
    <div className="relative">
      <select
        aria-label={`select-${value}`}
        className={twMerge(
          'min-h-10 appearance-none bg-none',
          InputFieldStyles({
            variant,
            error: !!error,
          }),
          'pr-10',
          selectClassName,
        )}
        id={id ?? name}
        name={name}
        onChange={onChange}
        value={value}
      >
        {placeholder && (
          <option disabled selected value="">
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option aria-label={`select-option-${option.value}`} key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <FontAwesomeIcon
        className={twMerge('pointer-events-none absolute right-4 top-1/2 -translate-y-1/2', iconClassName)}
        icon={faChevronDown}
      />
    </div>
  </FormFieldWrapper>
);

export default Select;
