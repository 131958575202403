export const Spinner = () => {
  return (
    <div
      className="h-10 w-10 animate-spin rounded-full border-r-4 border-t-4 border-blue-500"
      style={{ borderTopColor: 'transparent' }}
    />
  );
};

export default Spinner;
