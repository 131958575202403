import Image from '../Image';

export type PhotoCollectionProps = {
  images: {
    src: string;
    alt: string;
  }[];
};

const PhotoCollection = ({ images }: PhotoCollectionProps) => {
  const getClassName = (index: number) => {
    switch (index) {
      case 0:
        return 'aspect-[5/3] w-1/3 flex-none';
      case 1:
        return 'z-[5] -ml-8 -mt-20 aspect-[5/3] w-[40%] flex-none';
      case 2:
        return '-ml-8 aspect-[5/3] w-1/3 flex-none';
      default:
        return '';
    }
  };

  return (
    <div className="flex h-full w-full items-center justify-center p-12">
      {images.map((image, index) => (
        <div className={getClassName(index)} key={`image-${index}`}>
          <Image alt={image.alt} src={image.src} />
        </div>
      ))}
    </div>
  );
};

export default PhotoCollection;
