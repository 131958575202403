import { twMerge } from 'tailwind-merge';

const RoundedItem = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <div className={twMerge('shadow-m rounded-full', className)}>
      <span className="absolute top-1/2 left-1/2 transform -translate-x-[50%] -translate-y-[50%]">{children}</span>
    </div>
  );
};

export default RoundedItem;
