import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * Defines the structure of a suggestion object.
 */
interface Suggestion {
  text: string;
  timestamp: number;
}

/**
 * Custom React hook for managing a list of suggestions with local storage support.
 * It prevents adding duplicates and ensures suggestions are unique and recent.
 *
 * @param {number} maxSuggestions - The maximum number of suggestions to keep.
 * @param {number} minTextLength - The minimum length of suggestion text.
 * @returns An object containing the list of suggestion texts and a function to add a new suggestion.
 */
export const useSuggestions = (maxSuggestions = 5, minTextLength = 4) => {
  const STORAGE_KEY = 'suggestions';
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  // Effect to load suggestions from local storage on mount
  useEffect(() => {
    const stored = localStorage.getItem(STORAGE_KEY);

    if (stored) {
      try {
        const parsed = JSON.parse(stored);

        setSuggestions(parsed);
      } catch (error) {
        console.error('Failed to parse suggestions from local storage:', error);
      }
    }
  }, [STORAGE_KEY]);

  // Effect to save suggestions to local storage when they change
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(suggestions));
  }, [suggestions, STORAGE_KEY]);

  /**
   * Adds a new suggestion if it meets the requirements and is not a duplicate.
   *
   * @param {string} newText - The text of the new suggestion.
   */
  const addSuggestion = useCallback(
    (newText: string) => {
      const trimmedText = newText.trim();

      if (trimmedText.length < minTextLength) {
        return;
      }

      setSuggestions(prev => {
        const newSuggestion = { text: trimmedText, timestamp: Date.now() };

        // Check for duplicate texts and remove suggestions not older than 10 seconds
        const isDuplicate = prev.some(s => s.text === trimmedText);

        if (isDuplicate) {
          return prev;
        }

        const filtered = prev.filter(s => Date.now() - s.timestamp > 10000);

        return [newSuggestion, ...filtered].slice(0, maxSuggestions);
      });
    },
    [minTextLength, maxSuggestions],
  );

  const clearSuggestions = useCallback(() => {
    setSuggestions([]);
  }, []);

  const removeSuggestion = useCallback(
    (text: string) => {
      setSuggestions(prev => prev.filter(s => s.text !== text));
    },
    [setSuggestions],
  );

  // Memoize the suggestion texts to avoid unnecessary recalculations
  const suggestionTexts = useMemo(() => suggestions.map(s => s.text), [suggestions]);

  return { suggestions: suggestionTexts, addSuggestion, clearSuggestions, removeSuggestion };
};

export default useSuggestions;
