import deepmerge from 'deepmerge';
import { produce } from 'immer';
import { useLocalStorage } from 'usehooks-ts';

import { useIsSSR } from '@boss/hooks';
import { IBasketLine } from '@boss/services/client';
import { DeepPartial } from '@boss/types/b2b-b2c';

import { getBasketTotalPrices } from '../../utils';

/**
 * Custom hook for accessing the local shopping basket state and functions.
 * @returns Generic basket API functions
 */
export const useLocalBasket = () => {
  const isSSR = useIsSSR();
  const [basketLines, setLocalBasketLines] = useLocalStorage<IBasketLine[]>('BASKET_LINES', []);
  const { salesPrice, netAmountExVat, netAmountInclVat } = getBasketTotalPrices(basketLines);

  const addBasketLine = async (newLine: IBasketLine) =>
    Promise.resolve(
      setLocalBasketLines(oldLines => {
        const oldQuantity =
          basketLines.find(line => line.item.skuid === newLine.item.skuid)?.quantity?.secondaryquantity ?? 0;
        const mappedLine = produce(newLine, draft => {
          draft.quantity.secondaryquantity = oldQuantity + newLine.quantity.secondaryquantity;
        });

        return [
          ...oldLines.filter(line => line.item.skuid !== mappedLine.item.skuid),
          { id: mappedLine.item.skuid, ...mappedLine },
        ];
      }),
    );

  const addBasketLines = async (newLines: IBasketLine[]) =>
    Promise.resolve(
      setLocalBasketLines(oldLines =>
        newLines.reduce((prev, newLine) => {
          const oldQuantity =
            basketLines.find(line => line.item.skuid === newLine.item.skuid)?.quantity?.secondaryquantity ?? 0;
          const mappedLine = produce(newLine, draft => {
            draft.quantity.secondaryquantity = oldQuantity + newLine.quantity.secondaryquantity;
          });

          return [
            ...prev.filter(line => line.item.skuid !== mappedLine.item.skuid),
            { id: mappedLine.item.skuid, ...mappedLine },
          ];
        }, oldLines),
      ),
    );

  const deleteBasketLine = (skuId: string) => {
    setLocalBasketLines(prev => prev.filter(prevItem => prevItem.item.skuid !== skuId));
  };

  const updateBasketLine = async (item: DeepPartial<IBasketLine>) =>
    Promise.resolve(
      setLocalBasketLines(prev =>
        prev.map(prevItem => {
          if (prevItem.id === item.id) {
            return deepmerge(prevItem, item) as IBasketLine;
          }

          return prevItem;
        }),
      ),
    );

  return {
    basketId: 'local-basket-id',
    basketLines: isSSR ? [] : basketLines, // To avoid hydration errors
    isBasketLoading: false,
    addBasketLine,
    addBasketLines,
    deleteBasketLine,
    updateBasketLine,
    salesPrice: isSSR ? 0 : salesPrice,
    netAmountExVat: isSSR ? 0 : netAmountExVat,
    netAmountInclVat: isSSR ? 0 : netAmountInclVat,
    errors: { isAddError: false, isDeleteError: false, isUpdateError: false },
  };
};
