import { DeepPartial } from '@boss/types/b2b-b2c';

import { basket } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false };
const HEADERS = {
  'Content-Type': 'application/json',
};

/**
 * Fetch basket for the logged in user
 */
export const getBasket = (accountId?: string) => {
  const queryParam = accountId ? `?accountId=${accountId}` : '';

  return bossApi<basket.Basket>(`/basket/v2/baskets${queryParam}`, DEFAULT_OPTIONS);
};

/**
 * Fetch basket by basket id
 */
export const getBasketById = (basketId: string) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}`, DEFAULT_OPTIONS);
};

/**
 * Update the basket payment status to pending
 */
export const updateBasketPaymentStatus = (basketId: string, body: DeepPartial<basket.Basket>) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}`, {
    ...DEFAULT_OPTIONS,
    method: 'PUT',
    headers: HEADERS,
    body: body,
  });
};

/**
 * Adds a basket line for the logged in user
 */
export const addBasketLines = (basketId: string, basketLines: DeepPartial<basket.BasketLine>[]) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}/lines`, {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    headers: HEADERS,
    body: basketLines as unknown as Record<string, unknown>,
  });
};

/**
 * Deletes a basket line for the logged in user
 */
export const deleteBasketLine = (basketId: string, basketLineId: string) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}/lines/${basketLineId}`, {
    ...DEFAULT_OPTIONS,
    method: 'DELETE',
  });
};

/**
 * Updates a basket line for the logged in user
 */
export const updateBasketLine = (basketId: string, basketLine: DeepPartial<basket.BasketLine>) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}/lines/${basketLine.id}`, {
    ...DEFAULT_OPTIONS,
    method: 'PUT',
    headers: HEADERS,
    body: basketLine as unknown as Record<string, unknown>,
  });
};

/**
 * Updates the basket for the logged in user
 */
export const updateBasket = (basketId: string, basket: DeepPartial<basket.Basket>) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}`, {
    ...DEFAULT_OPTIONS,
    method: 'PUT',
    headers: HEADERS,
    body: basket as unknown as Record<string, unknown>,
  });
};

/**
 * Patch the basket for the logged in user
 */
export const patchBasket = (basketId: string, patchLines: basket.BasketPatchLine[]) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}`, {
    ...DEFAULT_OPTIONS,
    method: 'PATCH',
    headers: HEADERS,
    body: patchLines as unknown as Record<string, unknown>,
  });
};

/**
 * Fetch develiry dates for the basket
 */
export const getDeliveryDates = ({
  modeOfDelivery,
  storeId,
  address,
}: {
  modeOfDelivery: number;
  storeId?: string;
  address?: basket.BasketDeliveryAddress;
}) => {
  const queryParam = storeId
    ? `?deliveryPointId=${storeId}&deliveryMethod=${modeOfDelivery}`
    : `?deliveryMethod=${modeOfDelivery}`;

  return bossApi<basket.DeliveryDates>(`/basket/v2/deliverydates${queryParam}`, {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    headers: HEADERS,
    ...(address && { body: address as unknown as Record<string, unknown> }),
  });
};
