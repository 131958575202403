import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva, cx } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import ButtonOrAnchor from '../_Button';

export type Props = {
  children?: ReactNode;
  icon?: IconDefinition;
  color: 'beige' | 'green' | 'brown';
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  href?: string;
  label?: string;
  variant?: 'primary' | 'secondary' | 'service';
};

const ButtonStyles = cva(
  'flex justify-center items-center font-medium leading-7 text-2xl rounded-22 relative text-center',
  {
    variants: {
      variant: {
        primary: 'w-46 h-46',
        secondary: 'h-full w-full rounded-md align-middle',
        service: 'h-full w-full rounded-md align-middle text-xl',
      },
    },
  },
);

const colorMapper = {
  beige: { bgColor: 'bg-beige', textColor: 'text-brown-dark', iconColor: 'text-pink' },
  green: { bgColor: 'bg-green-medium', textColor: 'text-white', iconColor: 'text-brown-dark' },
  brown: { bgColor: 'bg-brown-dark', textColor: 'text-white', iconColor: 'text-pink' },
};

const ColorfulButton = ({ color, icon, onClick, onKeyDown, href, label, variant = 'primary' }: Props) => {
  const { bgColor, textColor, iconColor } = colorMapper[color];

  return (
    <ButtonOrAnchor
      className={twMerge(ButtonStyles({ variant }), `${bgColor} ${textColor}`)}
      href={href}
      onClick={onClick}
      onKeyDown={onKeyDown}
    >
      <div className="h-auto">{label}</div>
      {variant !== 'service' && icon && (
        <FontAwesomeIcon
          className={cx(
            'invisible absolute right-2 top-2 translate-y-1/3 text-[2.625rem] md:visible xl:right-[0px] xl:top-[0px]',
            iconColor,
          )}
          icon={icon}
        />
      )}
    </ButtonOrAnchor>
  );
};

export default ColorfulButton;
