import type { Store } from '../../types';
import bossApi from '../../utils/fetch-api';
import { mapStore } from '../../utils/mappers';

export const getStores = async (pathSuffix = '', deliverymethod?: string) => {
  const query = deliverymethod ? `?deliverymethod=${deliverymethod}` : '';

  const shops = await bossApi<Store[]>(`/store/v2/stores${pathSuffix}${query}`, {
    m2m: true,
    authScope: process.env.BOSS_API_AUTH_SCOPE_STORE,
  });

  if (!shops) {
    return [];
  }

  // Made sure we get a unique list of items, since calculations on distance are done by comparison with previous/next
  return [...new Map(shops.map(item => [item['id'], mapStore(item)])).values()];
};

export default getStores;
