import { faSliders } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { ComponentProps, Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { useConfigure, useHits, useInstantSearch, usePagination } from 'react-instantsearch';
import { twMerge } from 'tailwind-merge';
import { useLockedBody } from 'usehooks-ts';

import { DEFAULT_EXIT_ANIMATION, DEFAULT_PRODUCT_GRID_SIZE } from '@boss/constants/b2b-b2c';
import { useIsResponsive, useRouter } from '@boss/hooks';
import { ExtendedResults } from '@boss/types/b2b-b2c';
import { Link, Portal, Presence, ProductsGrid, PromotionCard, Repeat, SearchBar, Skeleton } from '@boss/ui';

import { FilterDrawer, FilterPanel, PaginationWidget } from '../';
import { PAINT_GUIDE_CTA_BANNER_INSERT_INDEX } from '../../../constants';
import Sort, { SortOption } from '../Sort';

type Props = {
  title?: string;
  promotion?: ComponentProps<typeof PromotionCard>;
  attributes: string[];
  initialMinHeight?: string;
  actionType?: 'refine' | 'navigate' | 'expand';
  children?: JSX.Element[];
  indexName: string;
  sortOptions: SortOption[];
  onSearch?: () => void;
  setSearchValue?: (val: string) => void;
  searchValue?: string;
  searchLabel?: string;
  searchPrefixLabel?: string;
  internalSearch?: boolean;
  ctaBanner?: ReactNode;
  hideTotalResults?: boolean;
};

const SearchGrid = ({
  title,
  promotion,
  attributes,
  actionType,
  children,
  indexName,
  sortOptions,
  onSearch,
  setSearchValue,
  searchValue,
  searchLabel,
  searchPrefixLabel,
  internalSearch,
  ctaBanner,
  hideTotalResults,
}: Props) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const { asPath } = useRouter();
  const { t } = useTranslation();
  const { results } = useHits();
  const { status } = useInstantSearch();
  const [hasSearched, setHasSearched] = useState(false);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const { isFirstPage } = usePagination();
  const isMobile = useIsResponsive('lg');

  useLockedBody(showFilterDrawer, 'root');

  useConfigure({
    hitsPerPage: promotion && isFirstPage ? DEFAULT_PRODUCT_GRID_SIZE - 1 : DEFAULT_PRODUCT_GRID_SIZE,
  });

  const hits = results?.hits;
  const totalHits = results?.nbHits;
  const params = (results as ExtendedResults)?.params;

  // Since we are intercepting the hitsPerPage on the first page, the total pages calculation in default is wrong.
  const totalPages = totalHits ? Math.ceil(totalHits / DEFAULT_PRODUCT_GRID_SIZE) : 0;
  const noResults = status === 'idle' && !hits?.length;
  const isLoading = status === 'stalled' || status === 'loading' || params === 'waitForSearchQuery';

  useEffect(() => {
    if (!isMobile && showFilterDrawer) {
      setShowFilterDrawer(false);
    }
  }, [isMobile, showFilterDrawer]);

  useEffect(() => {
    // Adding of additional hasSearched check to avoid "no results" flash due to first render not having content
    if (!hasSearched && isLoading) {
      setHasSearched(true);
    }
  }, [status, hasSearched, isLoading]);

  useEffect(() => {
    setShowFilterDrawer(false);
  }, [asPath]);

  const onPaginate = () => {
    window.scrollTo({
      top: (sectionRef?.current?.offsetTop ?? 0) - 100,
      behavior: 'smooth',
    });
  };

  const closeFilterDrawer = () => {
    setShowFilterDrawer(false);
  };

  return (
    <div>
      <Portal>
        <FilterDrawer
          actionType={actionType}
          attributes={attributes}
          className={twMerge('z-drawer fixed left-0 top-0 lg:hidden', showFilterDrawer ? '' : 'hidden')} // Hide section as Algolia widgets should not be rendered conditionally
          indexName={indexName}
          onClose={closeFilterDrawer}
          sortOptions={sortOptions}
        />
      </Portal>

      <Presence id="search-grid-presence" initial={DEFAULT_EXIT_ANIMATION} visible>
        {noResults && !internalSearch && !isLoading && <h1>{t('noResults')}</h1>}
        <div
          className={twMerge(
            'relative lg:pt-0',
            noResults && !internalSearch ? 'hidden' : '',
            attributes?.length ? 'lg:break-before lg:before:bg-blue-light/20' : '',
          )} // Hide section as Algolia widgets should not be rendered conditionally
          ref={sectionRef}
        >
          <div className="mb-20 grid grid-cols-1 gap-5 lg:grid-cols-4 lg:gap-10">
            <div className="flex justify-between gap-5 lg:hidden">
              {<h1 className="first-letter:uppercase">{title}</h1>}
              <button className="text-blue ml-auto" onClick={() => setShowFilterDrawer(true)}>
                <FontAwesomeIcon icon={faSliders} size="xl" />
              </button>
            </div>

            {!!attributes?.length && (
              <FilterPanel
                actionType={actionType}
                attributes={attributes}
                className="bg-blue-light/20 col-span-1 hidden rounded-br-lg py-8 pr-8 lg:flex"
                title={title}
              />
            )}

            <div className={twMerge('relative flex flex-col gap-8 lg:col-span-3')}>
              {!!sortOptions?.length && (
                <div className="flex hidden items-center gap-6 self-end lg:flex">
                  <h5 className="text-titleColor whitespace-nowrap">{t('sortBy')}</h5>
                  <Sort indexName={indexName} options={sortOptions} />
                </div>
              )}

              {onSearch && setSearchValue && (
                <div className="flex w-full flex-col gap-3 lg:flex-row lg:items-center lg:justify-end">
                  {searchPrefixLabel && <span className="text-textColor font-medium">{searchPrefixLabel}</span>}
                  <SearchBar
                    className="lg:w-1/2"
                    onSearch={onSearch}
                    searchLabel={searchLabel}
                    setSearchValue={setSearchValue}
                    type="primary"
                    value={searchValue}
                  />
                </div>
              )}

              <div className="relative flex flex-col gap-8 lg:gap-4">
                <Presence
                  id="search-grid-product-grid-presence"
                  isLoading={isLoading}
                  loader={
                    <div className="grid grid-cols-1 gap-6 gap-y-12 sm:grid-cols-2 lg:grid-cols-3 ">
                      <Repeat amount={12}>
                        <Skeleton className="h-100" />
                      </Repeat>
                    </div>
                  }
                  visible
                >
                  <div className="flex flex-col gap-8 lg:gap-4">
                    {!hideTotalResults && (
                      <span className={twMerge('small text-textColor')}>
                        {t(totalHits === 1 ? 'singularAmountOfResults' : 'amountOfResults', { amount: totalHits })}
                      </span>
                    )}
                    {children && (
                      <ProductsGrid className="col-span-3">
                        {children.map((child, index) => {
                          const showPromotion = promotion && index === 2 && isFirstPage;
                          const ctaIndex = promotion
                            ? PAINT_GUIDE_CTA_BANNER_INSERT_INDEX - 1
                            : PAINT_GUIDE_CTA_BANNER_INSERT_INDEX;

                          return (
                            <Fragment key={child.key}>
                              {showPromotion && (
                                <Link href={promotion.href}>
                                  <PromotionCard
                                    imageAlt={promotion.imageAlt}
                                    imageSrc={promotion.imageSrc}
                                    text={promotion.text}
                                    variant={promotion.variant}
                                  />
                                </Link>
                              )}
                              {child}
                              {index === ctaIndex && isFirstPage && ctaBanner}
                            </Fragment>
                          );
                        })}
                      </ProductsGrid>
                    )}
                  </div>
                </Presence>

                <PaginationWidget
                  className="absolute -bottom-20 left-1/2 -translate-x-1/2"
                  onPaginate={onPaginate}
                  totalPages={totalPages}
                />
              </div>
            </div>
          </div>
        </div>
      </Presence>
    </div>
  );
};

export default SearchGrid;
