import { ButtonType, LocalizedSlugs, NavigationItem, Nullable, SimpleImage } from '@boss/types/b2b-b2c';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import MainBar from './MainBar';
import MobileMainBar from './MobileMainBar';
import MobileMenu from './MobileMenu';
import SubBar from './SubBar';
import TopBar from './TopBar';

type Translations = {
  menuLabelOpen: string;
  menuLabelClosed: string;
};

export type Props = {
  activeLinkStyle?: string;
  className?: string;
  handleMobileMenuClick: () => void;
  highlightedButtonClassName?: string;
  highlightedButtonMobileType?: ButtonType;
  highlightedButtonType?: ButtonType;
  highlightedLinks?: Nullable<NavigationItem[]>;
  invertedLogo: Nullable<SimpleImage>;
  isOpen?: boolean;
  linkStyle?: string;
  links: Nullable<NavigationItem[]>;
  localizedSlugs: LocalizedSlugs;
  logo: Nullable<SimpleImage>;
  mobileMenuClassName?: string;
  topLinks?: Nullable<NavigationItem[]>;
  translations: Translations;
};

const CorporateMainNav = ({
  className,
  handleMobileMenuClick,
  highlightedButtonType,
  highlightedButtonMobileType,
  highlightedButtonClassName,
  highlightedLinks,
  isOpen,
  links,
  localizedSlugs,
  logo,
  mobileMenuClassName,
  topLinks,
  translations,
  activeLinkStyle,
  linkStyle,
  invertedLogo,
}: Props) => {
  const [activeLink, setActiveLink] = useState<NavigationItem | null>(null);

  const handleLink = (link: NavigationItem) => {
    if (link && link !== activeLink) {
      return setActiveLink(link);
    }

    setTimeout(() => setActiveLink(null), 3000);
  };

  const handleMouseLeave = () => {
    setTimeout(() => setActiveLink(null), 3000);
  };

  return (
    <>
      <nav className={twMerge('z-nav relative -ml-5 flex flex-col md:hidden', className)}>
        <MobileMainBar
          invertedLogo={invertedLogo}
          isOpen={isOpen}
          logo={logo}
          onClick={handleMobileMenuClick}
          translations={translations}
        />
        {isOpen && (
          <MobileMenu
            className={twMerge('absolute left-0 top-full h-[calc(100vh-100%)]', mobileMenuClassName)}
            handleLink={handleLink}
            highlightedButtonClassName={highlightedButtonClassName}
            highlightedButtonMobileType={highlightedButtonMobileType}
            highlightedLinks={highlightedLinks}
            links={links}
            localizedSlugs={localizedSlugs}
          />
        )}
      </nav>
      <nav className={twMerge('relative hidden flex-col md:mb-8 md:flex', className)} onMouseLeave={handleMouseLeave}>
        {!!topLinks?.length && <TopBar localizedSlugs={localizedSlugs} topLinks={topLinks} />}
        <MainBar
          activeLinkStyle={activeLinkStyle}
          handleLink={handleLink}
          highlightedButtonClassName={highlightedButtonClassName}
          highlightedButtonType={highlightedButtonType}
          highlightedLinks={highlightedLinks}
          linkStyle={linkStyle}
          links={links}
          logo={logo}
        />
        {!!activeLink?.childNavigationItems?.length && <SubBar links={activeLink.childNavigationItems} />}
      </nav>
    </>
  );
};

export default CorporateMainNav;
