import Cookies from 'universal-cookie';

import { session } from '../../utils';
import env from '../env';

/**
 * Format and go to logout url with correct parameters
 */
export const logout = (target?: string) => {
  // Deactivate the session
  session.deactivate();

  // Clear the cookie
  const cookies = new Cookies(null, { path: '/' });

  cookies.remove(env.ACCESS_TOKEN_COOKIE_NAME, { path: '/' });

  if (target) {
    window.location.href = target;
  }
};

export default logout;
