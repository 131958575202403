/**
 * Renders a text with a specific part in bold.
 *
 * This component takes a string `label` and a string `boldText`.
 * It renders the `label` with the part that matches `boldText` in bold.
 * The search for the bold text part is case-insensitive.
 *
 * @param {Object} props - The props for the component.
 * @param {string} props.label - The full text to be displayed.
 * @param {string} props.boldText - The part of the text that should be bold.
 * @returns {JSX.Element} The text with the specified part in bold.
 */
const BoldTextPart = ({ label, boldText }: { label: string; boldText: string }) => {
  const escapedBoldText = boldText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  // Find the index of the bold text in a case-insensitive manner
  const regex = new RegExp(escapedBoldText, 'i');
  const match = regex.exec(label);

  // If the bold text is not found, return the original label
  if (!match) {
    return <span>{label}</span>;
  }

  const boldStartIndex = match.index ?? 0;
  const matchedText = match[0]; // The actual text that matches, preserving original casing

  // Split the label into parts
  const beforeBold = label.substring(0, boldStartIndex);
  const afterBold = label.substring(boldStartIndex + matchedText.length);

  return (
    <span>
      {beforeBold}
      <span className="font-bold">{matchedText}</span>
      {afterBold}
    </span>
  );
};

export default BoldTextPart;
