import { IProduct } from '@boss/services';
import { ISearchProduct } from '@boss/types/b2b-b2c';

import {
  SURFACE_ATTRIBUTE_KEY,
  SURFACE_ATTRIBUTE_VALUE,
  TRANSPARENT_ATTRIBUTE_KEY,
  TRANSPARENT_ATTRIBUTE_VALUE,
} from '../../constants';

export const getProductColorType = (product: IProduct | ISearchProduct): 'transparant' | 'default' => {
  let isTransparent = false;

  if ('attr_transparency' in product && 'attr_surface' in product) {
    isTransparent =
      !!product['attr_transparency']?.includes(TRANSPARENT_ATTRIBUTE_VALUE) &&
      !!product['attr_surface']?.includes(SURFACE_ATTRIBUTE_VALUE);
  } else {
    isTransparent =
      !!product?.attributes?.[TRANSPARENT_ATTRIBUTE_KEY]?.includes(TRANSPARENT_ATTRIBUTE_VALUE) &&
      product?.attributes?.[SURFACE_ATTRIBUTE_KEY]?.includes(SURFACE_ATTRIBUTE_VALUE);
  }

  return isTransparent ? 'transparant' : 'default';
};
