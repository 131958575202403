import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  height: number;
  width: number;
};

/**
 * To pass a background color, style the border bottom color
 **/
const Triangle = ({ className, height, width }: Props) => (
  <div
    className={twMerge(`h-0 w-0 border-l-transparent border-r-transparent`, className)}
    style={{ borderBottomWidth: height, borderRightWidth: width / 2, borderLeftWidth: width / 2 }}
  />
);

export default Triangle;
