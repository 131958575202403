import * as code from '../code';
import * as ropc from '../ropc';
import { env } from '../utils';

/**
 * Hook to use to get the correct handles for logging in.
 */
export const login = (() => {
  if (env.FLOW === 'code') {
    return code.login;
  }

  if (env.FLOW === 'ropc') {
    return ropc.login;
  }

  throw new Error('@boss/auth is incorrectly configured.');
})();

export default login;
