import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import deepMerge from 'deepmerge';
import { useEffect, useRef, useState } from 'react';
import { Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { twMerge } from 'tailwind-merge';
import { useWindowSize } from 'usehooks-ts';

import IconButton from '../IconButton';

const defaultOptions = {
  slidesPerView: 1,
  navigation: false,
  pagination: false,
  spaceBetween: 16,
} as SwiperProps;

type CarouselProps = {
  id?: string;
  children: React.ReactNode[];
  className?: string;
  carouselClassName?: string;
  options?: SwiperProps;
};

const Carousel = ({ id, children = [], className, carouselClassName, options: initialOptions = {} }: CarouselProps) => {
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const options: SwiperProps = deepMerge(defaultOptions, initialOptions);
  const swiperRef = useRef<SwiperType>();
  const { width } = useWindowSize();

  const validatePosition = (swiper: SwiperType) => {
    setHasNext(!swiper.isEnd);
    setHasPrev(!swiper.isBeginning);
  };

  useEffect(() => {
    if (swiperRef.current) {
      validatePosition(swiperRef.current);
    }
  }, [width]);

  return (
    // Grid styling with minmax as a workaround for infinite width in non-full-screen layouts (https://github.com/nolimits4web/swiper/issues/2914)
    <div className={twMerge('relative grid w-full grid-cols-[minmax(0,auto)]', className)}>
      <Swiper
        {...options}
        className={twMerge('-m-2 p-2', carouselClassName)}
        modules={[Navigation]}
        onSlideChange={swiper => validatePosition(swiper)}
        onSwiper={swiper => {
          swiperRef.current = swiper;
          validatePosition(swiper);
        }}
      >
        {children?.map((child, index) => (
          <SwiperSlide className="h-auto" key={`${id ?? 'Carousel'}-slide-${index}`}>
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
      {options.navigation && (hasNext || hasPrev) && (
        <div>
          <IconButton
            chip
            className={twMerge('absolute -left-4 top-1/2 z-10 -translate-y-1/2', hasPrev ? '' : 'hidden')}
            icon={faChevronLeft}
            onClick={() => swiperRef.current?.slidePrev()}
          />
          <IconButton
            chip
            className={twMerge('absolute -right-4 top-1/2 z-10 -translate-y-1/2', hasNext ? '' : 'hidden')}
            icon={faChevronRight}
            onClick={() => swiperRef.current?.slideNext()}
          />
        </div>
      )}
    </div>
  );
};

export default Carousel;
