import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface PaginationProps {
  className?: string;
  onPaginate: (pageNumber: number) => void;
  currentPage?: number;
  totalPages?: number;
  iconClassName?: string;
  pageNumberClassName?: string;
}
type PaginationButtonProps = {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
  testId?: string;
  isDisabled?: boolean;
};

const PaginationButton = ({ children, onClick, className, testId, isDisabled }: PaginationButtonProps) => (
  <button
    className={twMerge('px-3 py-1.5', isDisabled ? 'text-gray-faded' : '', className)}
    data-testid={testId}
    disabled={isDisabled}
    onClick={onClick}
  >
    {children}
  </button>
);

const Pagination = ({
  className,
  iconClassName,
  onPaginate,
  currentPage = 0,
  totalPages = 0,
  pageNumberClassName,
}: PaginationProps) => {
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === totalPages - 1;
  const prev = currentPage - 1;
  const next = currentPage + 1;

  if (totalPages <= 1) {
    return null;
  }

  return (
    <div
      className={twMerge('border-1 border-gray-light flex w-fit justify-between rounded-full', className)}
      data-testid="pagination"
    >
      <PaginationButton isDisabled={isFirstPage} onClick={() => onPaginate(prev)} testId="pagination-back">
        <FontAwesomeIcon className={iconClassName} icon={faChevronLeft} />
      </PaginationButton>
      <div className="flex">
        {totalPages > 2 && currentPage > 1 && (
          <>
            <PaginationButton className={pageNumberClassName} onClick={() => onPaginate(0)} testId="pagination-first">
              1
            </PaginationButton>
            {currentPage > 2 && (
              <PaginationButton className={pageNumberClassName} onClick={() => onPaginate(1)}>
                ...
              </PaginationButton>
            )}
          </>
        )}
        {!isFirstPage && (
          <PaginationButton className={pageNumberClassName} onClick={() => onPaginate(prev)}>
            {prev + 1}
          </PaginationButton>
        )}
        <PaginationButton className={twMerge('font-bold', pageNumberClassName)}>{currentPage + 1}</PaginationButton>
        {!isLastPage && (
          <PaginationButton className={pageNumberClassName} onClick={() => onPaginate(next)}>
            {next + 1}
          </PaginationButton>
        )}
        {totalPages > 2 && currentPage < totalPages - 2 && (
          <>
            {currentPage < totalPages - 3 && (
              <PaginationButton className={pageNumberClassName} onClick={() => onPaginate(totalPages - 2)}>
                ...
              </PaginationButton>
            )}
            <PaginationButton className={pageNumberClassName} onClick={() => onPaginate(totalPages - 1)}>
              {totalPages}
            </PaginationButton>
          </>
        )}
      </div>
      <PaginationButton isDisabled={isLastPage} onClick={() => onPaginate(next)} testId="pagination-forward">
        <FontAwesomeIcon className={iconClassName} icon={faChevronRight} />
      </PaginationButton>
    </div>
  );
};

export default Pagination;
