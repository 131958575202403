import { useState } from 'react';

const getLatitudeLongitude = (): Promise<{ latitude: number; longitude: number }> => {
  return new Promise((resolve, reject) => {
    if (navigator?.geolocation?.getCurrentPosition) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          resolve({ latitude, longitude });
        },
        error => reject(error),
      );
    } else {
      reject('Geolocation is not supported by this browser.');
    }
  });
};

/**
 * Use the navigator web API to get the users latitude and longitude,
 * either by requesting the info on mount with the prefetch flag, or by calling the fetchGeoLocation function
 */
export const useGeoLocation = () => {
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchGeolocation = async () => {
    try {
      const { latitude, longitude } = await getLatitudeLongitude();

      setLatitude(latitude);
      setLongitude(longitude);
      setLoading(false);
      setPermissionDenied(false);
      return { latitude, longitude };
    } catch (error) {
      if (error instanceof GeolocationPositionError) {
        if (error.code === 1) {
          setPermissionDenied(true);
        } else {
          console.error('Geolocation error: ' + error.message);
        }
      }

      setLoading(false);
    }
  };

  return { latitude, longitude, permissionDenied, loading, fetchGeolocation };
};

export default useGeoLocation;
