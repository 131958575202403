import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { CorporateTabItem } from '..';
import Image from '../../Image';

/**
 * Represents the properties of the CorporateTabNavigationTab component.
 * @date 4-1-2024 - 16:25:11
 *
 * @interface CorporateTabItemProps
 * @typedef {CorporateTabItemProps}
 * @property {CorporateTabItem} item - Item to be rendered inside the component.
 * @property {number} [index] - The index of the active tab
 * @property {boolean} [isActive] - boolean that indicates if the tab is active
 * @property {(index: number) => void} [navigate] - action to be done when clicking on a tab
 */
interface CorporateTabItemProps {
  item: CorporateTabItem;
  index: number;
  isActive: boolean;
  navigate: (index: number) => void;
  buttonClassName?: string;
}

/**
 * Styling of a tab element
 */
const ItemStyling = cva(
  'md:col-span-1 w-full gap-2 md:gap-0 relative aspect-[11/3] md:aspect-square p-5 pr-10 md:p-2 lg:p-4 md:pr-4 text-white transition-transform md:justify-between items-center flex md:flex-col',
  {
    variants: {
      active: {
        true: 'md:scale-110 xl:scale-105',
      },
    },
  },
);

/**
 * Styling of the arrow icon element
 */
const ArrowStyling = cva('transition-transform flex md:hidden absolute right-4', {
  variants: {
    active: {
      true: 'rotate-180',
    },
  },
});

/**
 * Functional React Component that displays the corporate tabs button
 * @param {CorporateTabItemProps} props - The props for the button component
 * @returns {JSX.Element} - The JSX representation of the button component
 */
const CorporateTabNavigationTab: React.FC<CorporateTabItemProps> = ({
  item,
  index,
  isActive,
  navigate,
  buttonClassName,
}) => {
  return (
    <button
      className={twMerge(ItemStyling({ active: isActive }), buttonClassName)}
      onClick={() => navigate(index)}
      style={{ backgroundColor: item.color }}
    >
      {isActive && (
        <div
          className="absolute left-[50%] top-[100%] h-5 w-5 -translate-x-[50%] -translate-y-[50%] rotate-45 xl:h-7 xl:w-7"
          style={{ backgroundColor: item.color }}
        ></div>
      )}
      <div className="flex max-w-[20%] items-center md:max-h-[50%] md:w-full md:max-w-full">
        {item.icon && (
          <Image
            alt={item.icon?.imageAlt ?? ''}
            className="h-auto w-auto  object-contain md:h-full"
            src={item.icon?.imageUrl}
            wrapperClassName="w-auto h-auto md:h-full"
          />
        )}
      </div>
      <p className="large flex items-end text-left md:aspect-[2/1] md:w-full">{item.title}</p>
      <div className={ArrowStyling({ active: isActive })}>
        <FontAwesomeIcon icon={faAngleDown} size="lg" />
      </div>
    </button>
  );
};

export default CorporateTabNavigationTab;
