import { useState } from 'react';

import { useIsSSR } from '@boss/hooks';

import { useProfile } from '../../hooks';
import { priceConfig } from '../../utils';

/**
 * usePrice hook which exports price related consts and functions
 * @returns {Object} - showPrice indicates wether the price should be visible or not
 */
export const usePrice = () => {
  const isSSR = useIsSSR();
  const { isLoggedIn } = useProfile();

  const [showPrivatePrices, setShowPrivatePrices] = useState(false);

  const getPriceVisibility = () => {
    if (priceConfig.visible === 'auth') {
      return !isSSR && isLoggedIn;
    }

    return priceConfig.visible;
  };

  const handleTogglePrivatePrices = () => setShowPrivatePrices((val: boolean) => !val);

  return {
    showPrivatePrices,
    handleTogglePrivatePrices,
    showPrice: getPriceVisibility(),
  };
};

export default usePrice;
