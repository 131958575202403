import { BasicVariant } from '@boss/types/b2b-b2c';

import PrimaryColorGroupButton from './Primary';
import SecondaryColorGroupButton from './Secondary';

type Props = {
  active: boolean;
  className?: string;
  backgroundColor: string;
  name: string;
  onClick: () => void;
  variant?: BasicVariant;
};

export type ColorGroupButtonProps = Omit<Props, 'variant'>;

const ColorGroupButton = ({ variant = 'primary', ...props }: Props) => {
  if (variant === 'secondary') {
    return <SecondaryColorGroupButton {...props} />;
  }
  return <PrimaryColorGroupButton {...props} />;
};

export default ColorGroupButton;
