import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';

const ServiceInformation = ({ className }: { className?: string }) => {
  const { t } = useTranslation('common');

  return (
    <div className={`bg-blue-light max-w-176 mb-10 flex flex-col gap-3 p-6 ${className}`}>
      <p className="large">{t('serviceContainer.serviceAfterSales')}</p>
      <p className="large">{t('serviceContainer.clientTeamAvailable')}</p>
      <div className="my-3 flex flex-col gap-3">
        <div className="flex gap-3">
          <FontAwesomeIcon icon={faPhone} />
          <p>{t('serviceContainer.callUs')}</p>
        </div>
        <div className="flex gap-3">
          <FontAwesomeIcon icon={faEnvelope} />
          <p>{t('serviceContainer.mailUs')}</p>
        </div>
      </div>
      <p className="small">{t('serviceContainer.availability')}</p>
    </div>
  );
};

export default ServiceInformation;
