import { useTranslation } from 'next-i18next';
import { ComponentProps, useEffect, useState } from 'react';

import { IProduct, IStore } from '@boss/services';
import { Color, ColorGroup, IArticle, ISearchProduct } from '@boss/types/b2b-b2c';
import { calcAmountOfpaintForSurface } from '@boss/utils';

import { PaintGuideResultCard, SurfaceCalculatorModal } from '../../components';

type Product = IProduct | ISearchProduct;

type Props = {
  finishProduct: Product;
  supportProduct: Product | null | undefined;
  withCounter?: boolean;
  preCalculatedSurface?: number;
  store: IStore | undefined;
  colorGroups: ColorGroup[] | null;
  selectedColor?: Color | null;
  showBaseColors?: boolean;
};

type ResultProps = {
  counter: number;
  product: Product;
  subtitle: string;
  store: IStore | undefined;
  totalPaintNeeded: number;
  onCalculateSurface?: (articles: IArticle[]) => void;
  withCounter?: boolean;
  colorGroups: ColorGroup[] | null;
  selectedColor?: Color | null;
  useDefaultColor?: boolean;
  showBaseColors?: boolean;
};

const Result = ({
  withCounter = false,
  totalPaintNeeded,
  counter,
  product,
  subtitle,
  store,
  onCalculateSurface,
  colorGroups,
  selectedColor,
  useDefaultColor = false,
  showBaseColors = false,
}: ResultProps) => (
  <div className="xl:gap-x-30 my-10 lg:flex">
    <div className="lg:min-w-48 mb-5 flex items-baseline md:flex-col">
      {withCounter && <h2 className="lg:text-40 mb-3 mr-3 text-xl">{counter}.</h2>}
      <h4>{subtitle}</h4>
    </div>
    <PaintGuideResultCard
      amountOfPaint={totalPaintNeeded}
      colorGroups={colorGroups}
      onCalculateSurface={onCalculateSurface}
      preChosenColor={selectedColor}
      product={product}
      selectedStore={store}
      showBaseColors={showBaseColors}
      useDefaultColor={useDefaultColor}
    />
  </div>
);

const PaintguideResultsWrapper = ({ preCalculatedSurface = 0, finishProduct, supportProduct, ...props }: Props) => {
  const { t } = useTranslation('paintguide');

  const [totalPaintNeeded, setTotalPaintNeeded] = useState(0);
  const [showSurfaceCalculatorModal, setShowSurfaceCalculatorModal] = useState(false);
  const [calculated, setCalculated] = useState<ComponentProps<typeof SurfaceCalculatorModal>['calculated']>();
  const [articles, setArticles] = useState<IArticle[]>();

  useEffect(() => {
    if (preCalculatedSurface) {
      const activeConsumption = finishProduct.consumptionOptions[0].usage['average'];
      const totalPaintNeeded = calcAmountOfpaintForSurface(activeConsumption, preCalculatedSurface);

      setTotalPaintNeeded(totalPaintNeeded);
    }
  }, [preCalculatedSurface, finishProduct.consumptionOptions]);

  return (
    <>
      {supportProduct && (
        <Result
          {...props}
          counter={1}
          onCalculateSurface={articles => {
            setShowSurfaceCalculatorModal(true);
            setArticles(articles);
          }}
          product={supportProduct}
          subtitle={t('supportLayer')}
          totalPaintNeeded={totalPaintNeeded}
          useDefaultColor
        />
      )}
      <Result
        counter={supportProduct ? 2 : 1}
        onCalculateSurface={articles => {
          setShowSurfaceCalculatorModal(true);
          setArticles(articles);
        }}
        product={finishProduct}
        subtitle={t('finshedLayer')}
        totalPaintNeeded={totalPaintNeeded}
        {...props}
      />
      {showSurfaceCalculatorModal && (
        <SurfaceCalculatorModal
          articles={articles}
          calculated={calculated}
          consumptionOptions={finishProduct.consumptionOptions}
          onClose={() => setShowSurfaceCalculatorModal(false)}
          onConfirm={calc => {
            setTotalPaintNeeded(calc.totalPaintNeeded);
            setCalculated(calc);
          }}
          preCalculated={preCalculatedSurface}
          product={supportProduct}
        />
      )}
    </>
  );
};

export default PaintguideResultsWrapper;
