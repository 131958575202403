import { Repeat, Skeleton } from '@boss/ui';

const ContactSkeleton = () => (
  <div className="flex flex-col justify-between gap-5">
    <Repeat amount={3}>
      <Skeleton className="xl:w-100 h-7" />
      <div className="shadow-m p-5">
        <Skeleton className="xl:w-100 mb-5 h-7" />
        <Repeat amount={3}>
          <div className="my-3 flex h-5 gap-2">
            <Skeleton className="xl:w-7" /> <Skeleton className="xl:w-50" />
          </div>
        </Repeat>
        <div className="flex gap-5">
          <Skeleton className="xl:w-100 my-3 h-10" />
          <Skeleton className="xl:w-100 my-3 h-10" />
        </div>
      </div>
    </Repeat>
  </div>
);

export default ContactSkeleton;
