import { twMerge } from 'tailwind-merge';

import Image from '../Image';

import { TimelineEvent } from './index';

type TimeLineEventBlockProps = {
  timelineEvent: TimelineEvent;
  reversed?: boolean;
};

const TimelineEventBlock = ({ timelineEvent, reversed }: TimeLineEventBlockProps) => {
  const { content, year, image } = timelineEvent;

  return (
    <div className="mb-8 grid w-full grid-cols-1 gap-4 md:mb-20 md:grid-cols-2 md:gap-12">
      <Image
        alt={image?.imageAlt}
        className="aspect-[3/2]"
        src={image?.imageUrl}
        wrapperClassName={reversed ? 'md:order-2' : 'order-0'}
      />
      <div className="order-1 flex flex-col justify-around">
        <div className="flex flex-col gap-4">
          <div className={twMerge('text-gray-medium text-5x font-rosarivo')}>{year}</div>
          <div>{content}</div>
        </div>
      </div>
    </div>
  );
};

export default TimelineEventBlock;
