import { CHANNEL } from '@boss/constants/shared';
import { Platforms } from '@boss/hooks';
import { faArrowUpFromBracket, faEllipsisVertical, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

type PWAPopupProps = {
  platform: string;
  className?: string;
  onClick?: () => void;
};

const IosContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-brown-dark text-sm font-medium">{t('toast.ios.install.title')}</p>
      <p className="text-brown mt-1 text-sm">{t('toast.ios.install.description')}</p>
      <br />
      <p className="text-brown-dark mt-1 text-sm">
        {t('toast.ios.install.tap')} &nbsp;
        <FontAwesomeIcon aria-hidden="true" className="text-blue inline-block h-4 w-4" icon={faArrowUpFromBracket} />
        &nbsp; {t('toast.ios.install.add')}
      </p>
    </>
  );
};
const AndroidContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-brown text-sm font-medium">{t('toast.android.install.title')}</p>
      <p className="text-brown mt-1 text-sm">{t('toast.android.install.description')}</p>
      <br />
      <p className="text-brown mt-1 text-sm">
        {t('toast.android.install.tap')} &nbsp;
        <FontAwesomeIcon aria-hidden="true" className="text-blue inline-block h-4 w-4" icon={faEllipsisVertical} />
        &nbsp; {t('toast.android.install.add')}
      </p>
    </>
  );
};

const PWAPopup = ({ platform, onClick, className }: PWAPopupProps) => {
  const [show, setShow] = useState(true);
  const { t } = useTranslation();
  const favicon = CHANNEL === 'colora' ? '/favicon_b2c.ico' : '/favicon_b2b.ico';

  return (
    <>
      {show && (
        <div
          aria-live="assertive"
          className={twMerge(
            'pointer-events-none fixed inset-0 bottom-6 z-30 flex items-end px-6 py-8 sm:items-start sm:p-6',
            className,
          )}
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <div className="shadow-m pointer-events-auto w-full max-w-sm overflow-hidden bg-white ">
              <div className="p-4">
                <div className="flex items-start text-center ">
                  <div className="flex items-start justify-center text-center"></div>
                  <div className="ml-3 w-0 flex-1 pt-0.5 ">
                    <img alt="app logo" className="mx-auto my-0 p-4" src={favicon} />
                    {platform === Platforms.Ios ? <IosContent /> : <AndroidContent />}
                  </div>
                  {onClick && (
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        className="inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          onClick();
                          setShow(false);
                        }}
                        type="button"
                      >
                        <span className="sr-only">{t('close')}</span>
                        <FontAwesomeIcon aria-hidden="true" className="text-brown h-5 w-5" icon={faXmark} />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PWAPopup;
