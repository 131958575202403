import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { Image } from '../..';
import Link from '../../Link';
export interface StockProductCardProps {
  title: string;
  price?: string;
  variant?: 'primary' | 'secondary';
  productImage: {
    src?: string | null;
    alt?: string;
  };
  className?: string;
}

const CardWrapperStyling = cva('flex items-center', {
  variants: {
    variant: {
      primary: 'bg-beige-light rounded-md',
      secondary: 'bg-gray-lightest',
    },
  },
});

export const StockProductCard = ({
  variant = 'primary',
  price,
  title,
  productImage,
  className,
}: StockProductCardProps) => {
  if (!price && !title) {
    return null;
  }

  return (
    <div className={twMerge(CardWrapperStyling({ variant }), className)}>
      <Link className="sm:-mt-17.5 -mt-10 h-fit" href={productImage.src}>
        <Image
          alt={productImage?.alt}
          className="w-15 h-15 object-contain"
          height={200}
          src={productImage.src}
          useNext
          width={200}
        />
      </Link>
      <div className="flex w-full items-center justify-between pl-4">
        {title && <p>{title}</p>}
        {price && <p>{price}</p>}
      </div>
    </div>
  );
};

export default StockProductCard;
