import { CHANNEL } from '@boss/constants/b2b-b2c';
import { ButtonProps } from '@boss/types/b2b-b2c';

import BossPaintsButton from './BossPaintsButton';
import ColoraButton from './ColoraButton';
import CorporateButton from './CorporateButton';
import JobsBossPaintsButton from './JobsBossPaintsButton';
import JobsColoraButton from './JobsColoraButton';

const Button = (props: ButtonProps) => {
  const channel = props.channel ?? CHANNEL;

  if (channel === 'colora') {
    return <ColoraButton {...props} />;
  }

  if (channel === 'corporate') {
    return <CorporateButton {...props} />;
  }

  if (channel === 'boss-paints') {
    return <BossPaintsButton {...props} />;
  }

  if (channel === 'depot') {
    return <BossPaintsButton {...props} />;
  }

  if (channel === 'jobs-b2c') {
    return <JobsColoraButton {...props} />;
  }

  if (channel === 'jobs-b2b') {
    return <JobsBossPaintsButton {...props} />;
  }

  return null;
};

export default Button;
