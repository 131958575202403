import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
};

const Pill = ({ children, className, onClick }: Props) => (
  <div className={twMerge('white w-fit whitespace-nowrap rounded-full px-3 py-2', className)} onClick={onClick}>
    {children}
  </div>
);

export default Pill;
