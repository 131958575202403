import { faHeart } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';

import IconButton from '../IconButton';

type Props = {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isFavorite?: boolean;
};

const FavoriteButton = ({ isFavorite, className, onClick }: Props) => (
  <IconButton
    chip
    className={className}
    classNameIcon={isFavorite ? 'text-red' : ''}
    icon={isFavorite ? faHeartSolid : faHeart}
    onClick={onClick}
    testid="favorite-button"
  />
);

export default FavoriteButton;
