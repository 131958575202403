import { faChevronDown, faChevronUp, faVideo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import { useCollapse } from 'react-collapsed';

import { IAccordionProps } from '../';

const BorderStyle = cva('w-full h-0.25 block pt-1 my-3 last-of-type ', {
  variants: {
    solid: {
      false: 'border-t-4 border-dotted border-brown-dark',
      true: 'border-t-1 border-gray',
    },
  },
});

const AccordionSecondary = ({
  title,
  children,
  hasVideo,
  solid = false,
  isOpen = false,
  progressiveRender = false,
}: IAccordionProps) => {
  const [expanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded: expanded });

  useEffect(() => {
    setExpanded(isOpen);
  }, [isOpen]);

  return (
    <div>
      <div
        className="flex items-start justify-between py-3"
        {...getToggleProps({
          onClick: () => setExpanded(prevExpanded => !prevExpanded),
        })}
      >
        <span className="md:m-w-2/3 h4 flex items-center">
          {title}
          {hasVideo && <FontAwesomeIcon className="ml-3 md:text-xl" icon={faVideo} />}
        </span>
        <FontAwesomeIcon className="md:text-blue ml-3 md:text-xl" icon={expanded ? faChevronUp : faChevronDown} />
      </div>
      <div {...getCollapseProps()}>
        {progressiveRender ? (
          expanded && <div className="py-3">{children}</div>
        ) : (
          <div className="py-3">{children}</div>
        )}
      </div>
      <span className={BorderStyle({ solid: solid })}></span>
    </div>
  );
};

export default AccordionSecondary;
