import { Nullable } from '@boss/types/b2b-b2c';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import type { Props as ColorfulButtonProps } from '../ColorfulButton';
import ColorfulButton from '../ColorfulButton';
import type { Props as PromotionCtaProps } from '../PromotionCta';
import PromotionCta from '../PromotionCta';

type Translations = {
  title: string;
  service: string;
  services: Array<ServiceTranslateObject>;
};

type ServiceTranslateObject = {
  key?: string;
  description?: string;
};

type Props = {
  services?: Array<string>;
  translations?: object;
  promotionCta?: Nullable<PromotionCtaProps>;
  buttons?: Nullable<
    Array<
      ColorfulButtonProps & {
        alternativeText?: ReactNode;
      }
    >
  >;
};

const PromotionColStyles = cva('col-span-2 mb-6 md:mb-0 md:h-auto', {
  variants: {
    hasButtons: {
      true: 'md:col-span-5',
      false: 'md:col-span-8',
    },
  },
});

const isInstanceOfTranslation = (translation: Nullable<object> | string): translation is Translations =>
  !!translation && typeof translation === 'object' && 'service' in translation && 'services' in translation;

const ServicesBlock = ({ translations, services, buttons, promotionCta }: Props) => {
  const mappedServices: ServiceTranslateObject[] = [];
  const hasButtons = buttons && buttons.length > 0;

  if (isInstanceOfTranslation(translations) && services?.length) {
    for (const serviceName of services) {
      const service = translations.services.find(service => service.key === serviceName);

      if (service) {
        mappedServices.push(service);
      }
    }
  }

  const { t } = useTranslation();

  return (
    <div className="bg-generic-blue-light break-before break-after after:bg-generic-blue-light before:bg-generic-blue-light relative ">
      <div className="xl:gap-35 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-6 lg:gap-6 xl:grid-cols-5">
        {promotionCta && (
          <div className="col-span-1 pt-10 md:col-span-4 lg:mb-0 lg:pb-20 lg:pt-16 xl:col-span-3">
            <div className="grid grid-cols-2 md:grid-cols-8 md:gap-6">
              <div className={PromotionColStyles({ hasButtons })}>
                <PromotionCta className="md:min-h-55.5 min-h-44 w-full" variant="service" {...promotionCta} />
              </div>
              {hasButtons && (
                <div className="col-span-3 flex flex-col gap-y-6 md:justify-between">
                  {buttons?.map(props => (
                    <div
                      className={`h-15 text-center md:h-20 ${props.alternativeText ? 'mb-4' : 'mb-0'} `}
                      key={`${props.label}`}
                    >
                      <ColorfulButton {...props} variant="service" />
                      {props.alternativeText && (
                        <span className="text-gray-dark mt-1 block pb-8 [&>*]:text-xs">{props.alternativeText}</span>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="col-span-1 mb-6 md:col-span-2 lg:mb-0">
          {mappedServices && mappedServices.length !== 0 && isInstanceOfTranslation(translations) && (
            <div className="pt-10 lg:pb-20 lg:pt-16">
              <h3 className="mb-8">{translations.service}</h3>
              {mappedServices.map(service => (
                <div className="mb-4 last-of-type:mb-0 md:mb-8" key={service?.key}>
                  <p className="block font-bold">{t(`${service?.key}`, { ns: 'stores' })}</p>
                  <p>{service?.description}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServicesBlock;
