import { cva } from 'class-variance-authority';
import { Dispatch, SetStateAction } from 'react';

import Repeat from '../Repeat';

type TimeScaleProps = {
  years: number[];
  setActiveYear: Dispatch<SetStateAction<number | undefined>>;
  activeYear?: number;
};

const activeStyle = cva('w-16 body cursor-pointer text-center font-rosarivo', {
  variants: {
    active: {
      true: 'font-bold large ',
      false: 'text-gray-dark',
    },
  },
});

const activeLineStyle = cva('cursor-pointer body', {
  variants: {
    active: {
      true: 'font-bold large',
      false: 'text-gray-dark',
    },
  },
});

const TimeScale = ({ years, setActiveYear, activeYear }: TimeScaleProps) => {
  return (
    <div className="grid auto-cols-fr grid-flow-col">
      {years.map((year, index) => (
        <button
          className="grid items-center"
          key={year}
          onClick={() => {
            setActiveYear(year);
          }}
        >
          <div className={activeStyle({ active: activeYear === year })}>{year}</div>
          <div className="relative flex w-16 items-center justify-center">
            <div className={activeLineStyle({ active: activeYear === year })}>|</div>
            {index !== years.length - 1 && (
              <div className="grid-col-8 pointer-events-none absolute left-[50%] grid h-[40%] w-full cursor-default auto-cols-fr grid-flow-col">
                <Repeat amount={7}>
                  <div className="border-r-1 border-r-solid- border-r-gray-dark" />
                </Repeat>
                <div />
              </div>
            )}
          </div>
        </button>
      ))}
    </div>
  );
};

export default TimeScale;
