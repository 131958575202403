import * as bossClient from '@boss/boss-client';

import { IIncidentType, WorkerOrderType } from './types';

/**
 * Transform incident type from bossClient IncidentType to IIncidentType
 * @param incidentType
 * @returns {IIncidentType}
 */
const transform = (incidentType: bossClient.types.incidentType.IncidentType): IIncidentType => ({
  id: incidentType.id,
  name: incidentType.name,
});

/**
 * Get all incident types
 * @returns {Promise<IIncidentType[]>} - List of incident types
 * @param workerOrderType
 */
export const getIncidentTypes = async (workerOrderType: WorkerOrderType): Promise<IIncidentType[]> => {
  const incidentTypes = await bossClient.incidentTypeClient.getIncidentTypes(workerOrderType);

  return incidentTypes?.map(transform) ?? [];
};
