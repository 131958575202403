import type { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import { useTranslation } from 'next-i18next';
import { useRefinementList } from 'react-instantsearch';
import { twMerge } from 'tailwind-merge';

import { REFINEMENT_LIMIT } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { Link } from '@boss/ui';
import { slugify } from '@boss/utils';

import { getCurrentCategoryFromFacetValue, getNextSearchAttribute } from '../../../utils';

type Props = {
  className?: string;
  attribute: string;
  shouldNavigate?: boolean;
  actionType?: 'refine' | 'navigate' | 'expand';
  label?: string;
  parent?: string;
};

const Facets = ({ className, attribute, actionType = 'refine', label, parent }: Props) => {
  const { t } = useTranslation('facets');
  const facetTranslations: Record<string, string> = t('facets', { returnObjects: true });
  const { asPath } = useRouter();
  const { items: initialItems, refine } = useRefinementList({ attribute, limit: REFINEMENT_LIMIT });
  const items = parent ? initialItems.filter(item => item.value.includes(parent)) : initialItems;
  const nextAttribute = getNextSearchAttribute(attribute);

  if (!items.length) {
    return null;
  }

  const getHref = (item: RefinementListItem) => {
    if (actionType !== 'navigate') {
      return null;
    }

    const splitValue = item.value.split('>');
    const lastValue = splitValue[splitValue.length - 1];
    const itemSlug = slugify(lastValue);

    const base = asPath.split('?')[0]; // Remove query params

    return `${base}/${itemSlug}`;
  };

  const handleChange = (item: RefinementListItem) => {
    if (['refine', 'expand'].includes(actionType)) {
      refine(item.value);
    }
  };

  return (
    <div className={twMerge('flex flex-col gap-4', className)}>
      {!!label && <span className="text-titleColor font-bold">{label}</span>}
      {items.map(item => {
        const categoryLabel = getCurrentCategoryFromFacetValue(item.label);
        const label = facetTranslations[categoryLabel.toLowerCase()] ?? categoryLabel;

        return (
          <div className="flex flex-col gap-4" key={item.value}>
            <Link className="flex cursor-pointer items-center gap-2.5 [&>*]:cursor-pointer" href={getHref(item)}>
              <input
                checked={item.isRefined}
                className="text-blue-dark focus:ring-blue-dark h-4 w-4 shrink-0 rounded border-gray-300 bg-gray-100"
                id={item.value}
                onChange={() => handleChange(item)}
                type="checkbox"
                value=""
              />
              <label className="caption text-textColor" htmlFor={item.value}>
                {label} ({item.count})
              </label>
            </Link>
            {actionType === 'expand' && item.isRefined && nextAttribute && (
              <Facets actionType={actionType} attribute={nextAttribute} className="ml-4" parent={item.value} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Facets;
