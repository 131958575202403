import { Fragment } from 'react';

import DesktopNavigationItem from './components/DesktopNavigationItem';
import FooterNavItem from './components/FooterNavItem';
import FooterWrapper from './components/FooterWrapper';
import LegalFootnotes from './components/LegalFootNotes';
import MobileNavigationItem from './components/MobileNavigationItem';
import PaymentMethods from './components/PaymentMethods';
import StripeBackground from './components/StripeBackground';

import { FooterProps } from '.';

const Footer = ({ type, navigationItems, legalLinks, paymentMethods }: FooterProps) => (
  <FooterWrapper className="relative">
    {type !== 'simple' && (
      <>
        <nav className="pt-35 z-base w-full pb-11 lg:pb-12">
          <ul className="grid grid-cols-1 gap-x-4 gap-y-5 sm:grid-cols-6 sm:gap-x-6">
            {navigationItems?.map((navItem, i) => (
              <Fragment key={`${navItem.id}-${i}`}>
                <DesktopNavigationItem navItem={navItem} />
                <MobileNavigationItem isCollapsible={true} navItem={navItem} type="secondary" />
              </Fragment>
            ))}
          </ul>
        </nav>
        <StripeBackground />
      </>
    )}

    <div className="z-base bg-footer-secondary break-before before:bg-footer-secondary break-after after:bg-footer-secondary relative flex flex-col gap-4 py-6 text-white after:z-10 md:flex-row md:items-center md:justify-between">
      {paymentMethods && <PaymentMethods paymentMethods={paymentMethods} />}
      <LegalFootnotes>
        <FooterNavItem>© {new Date().getFullYear()} BOSS paints</FooterNavItem>
        {legalLinks?.map((link, i) => {
          return (
            <li className="inline" key={`${link.id}-${i}`}>
              <span> | </span>
              <FooterNavItem className="hover:underline" link={link.href}>
                {link.label}
              </FooterNavItem>
            </li>
          );
        })}
      </LegalFootnotes>
    </div>
  </FooterWrapper>
);

export default Footer;
