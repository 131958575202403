import { type VariantProps, cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { CommonProps } from '../../types';
import Link from '../Link';

export interface TextLinkProps extends CommonProps, VariantProps<typeof TextLinkStyles> {
  href: string;
  underline?: boolean;
  children?: ReactNode;
}

export const TextLinkStyles = cva('font-medium', {
  variants: {
    underline: {
      true: 'underline',
    },
  },
  defaultVariants: {
    underline: true,
  },
});

const TextLink = ({ underline = true, href, className, children, ...props }: TextLinkProps) => {
  return (
    <Link className={twMerge(TextLinkStyles({ underline }), className)} href={href} {...props}>
      {children}
    </Link>
  );
};

export default TextLink;
