import * as Sentry from '@sentry/nextjs';

import { withSession } from '@boss/auth';
import { events as eventService } from '@boss/services/client';

const fetchEvents = async (locale: string) => {
  try {
    return await eventService.getUserEvents(locale);
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Get all events',
      },
    });

    // Needs a re-throw
    throw error;
  }
};

const fetchEventById = async (locale: string, eventId: string) => {
  try {
    return await eventService.getEventById({
      locale,
      eventId,
    });
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Get event by ID',
      },
    });

    // Needs a re-throw
    throw error;
  }
};

const participateEvent = withSession(async (eventId: string, data: Record<string, unknown>) => {
  try {
    return await eventService.participate(eventId, data);
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Participate event',
      },
    });

    // Needs a re-throw
    throw error;
  }
});

export { fetchEvents, participateEvent, fetchEventById };
