import { useTranslation } from 'next-i18next';

import { ContactCard, Presence } from '@boss/ui';

import { useAccount } from '../../../client-queries';
import { ContactSkeleton } from '../../../components';

type Props = {
  locale: string;
};

const AccountContacts = ({ locale }: Props) => {
  const { data: account, isLoading } = useAccount(locale);
  const { t } = useTranslation(['common', 'account']);

  const contactCardTranslations = {
    call: t('contactCard.call'),
    mail: t('contactCard.mail'),
  };

  return (
    <Presence className="mb-10" id="account-ists-page" isLoading={isLoading} loader={<ContactSkeleton />} visible>
      <h1 className="mb-5">{t('contact.title', { ns: 'account' })}</h1>
      <div className="mb-10 flex flex-col gap-6">
        {account?.serviceteam?.map(contact => (
          <div key={contact.type}>
            <div className="mb-3 text-xl font-bold">{t(`contacts.${contact.type}`, { ns: 'account' })}</div>
            <ContactCard
              email={contact.email}
              firstname={contact.name}
              phone={contact.phonenumber}
              translations={contactCardTranslations}
            />
          </div>
        ))}
      </div>
    </Presence>
  );
};

export default AccountContacts;
