import { type VariantProps, cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import type { CommonProps } from '../../types';

export const TextStyles = cva('', {
  variants: {
    size: {
      lg: 'text-gray-dark text-lg tracking-normal',
      md: 'text-gray-dark text-base tracking-normal',
      sm: 'text-gray-dark font-light text-sm tracking-normal',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export interface TextProps extends CommonProps, VariantProps<typeof TextStyles> {
  children?: ReactNode;
}

const Text = ({ size, className, children, ...props }: TextProps) => {
  return (
    <span className={twMerge(TextStyles({ size, className }))} {...props}>
      {children}
    </span>
  );
};

export default Text;
