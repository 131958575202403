import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ReactNode, useEffect, useState } from 'react';

import { Alert, Button } from '@boss/ui';

import { useLogin } from '../../hooks';

type Props = {
  behavior: 'showLogin' | 'showAlways' | 'showNothingWhenLoggedOut';
  children: ReactNode;
};

const FormWrapper = ({ behavior, children }: Props) => {
  const { t } = useTranslation(['forms', 'common']);
  const { handleLogin, isLoggedIn, handleRegister } = useLogin();

  const { asPath } = useRouter();

  const [showLogin, setShowLogin] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (['showLogin', 'showNothingWhenLoggedOut'].includes(behavior) && !isLoggedIn) {
      setShowLogin(true);
    } else {
      setShowForm(true);
    }
  }, [behavior, isLoggedIn]);

  if (showLogin) {
    return (
      <Alert className="mx-auto w-full gap-4 text-left" type="info">
        <p className="inline">
          {t('unavailable.title', { ns: 'forms' })}. &nbsp;
          <Button
            className="inline"
            label={t('unavailable.register')}
            onClick={() => handleRegister(asPath)}
            type="link"
          />
          {t('or', { ns: 'common' })}
          <Button
            className="inline md:text-[1.25rem]"
            label={t('unavailable.login')}
            labelClassName="lowercase"
            onClick={() => handleLogin(asPath)}
            type="link"
          />
        </p>
      </Alert>
    );
  }
  if (!showForm) {
    return null;
  }
  return <>{children}</>;
};

export default FormWrapper;
