import { wishlists as wishlistMock } from '../../__mocks__/wishlist';
import { wishlist } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false };
const MOCK_ACCOUNT_API = process.env.MOCK_ACCOUNT_API === 'true';
const HEADERS = {
  'Content-Type': 'application/json',
};

/**
 * Fetch all wishlists for an account
 */
export const getAllWishlists = (accountId: string) => {
  if (MOCK_ACCOUNT_API) {
    return Promise.resolve(wishlistMock as unknown as wishlist.Wishlists);
  }
  return bossApi<wishlist.Wishlists>(`/wishlist/v2/wishlists?accountId=${accountId}`, DEFAULT_OPTIONS);
};

/**
 * Create a new wishlist
 */

export const createWishlist = ({ wishlist }: { wishlist: wishlist.Wishlist }) => {
  return bossApi<wishlist.Wishlist>(`/wishlist/v2/wishlists`, {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    headers: HEADERS,
    body: wishlist as unknown as Record<string, unknown>,
  });
};

/**
 *  Delete a wishlist
 */

export const deleteWishlist = ({ wishlistId }: { wishlistId: string }) => {
  return bossApi<wishlist.Wishlists>(`/wishlist/v2/wishlists/${wishlistId}`, {
    ...DEFAULT_OPTIONS,
    method: 'DELETE',
    headers: HEADERS,
  });
};

/**
 * Update a new wishlist
 */

export const updateWishlist = ({ wishlist }: { wishlist: wishlist.Wishlist }) => {
  return bossApi<wishlist.Wishlists>(`/wishlist/v2/wishlists/${wishlist.id}`, {
    ...DEFAULT_OPTIONS,
    method: 'PUT',
    headers: HEADERS,
    body: wishlist as unknown as Record<string, unknown>,
  });
};

/**
 *  Create a wishlistLine
 */

export const createWishlistLine = ({
  wishlistLine,
  wishlistId,
}: {
  wishlistLine: wishlist.WishlistLine;
  wishlistId: string;
}) => {
  return bossApi<wishlist.WishlistLine>(`/wishlist/v2/wishlists/${wishlistId}/lines`, {
    ...DEFAULT_OPTIONS,
    method: 'POST',
    headers: HEADERS,
    body: wishlistLine as unknown as Record<string, unknown>,
  });
};

/**
 *  Delete a wishlistLine
 */

export const deleteWishlistLine = ({ wishlistLineId, wishlistId }: { wishlistLineId: string; wishlistId: string }) => {
  return bossApi<wishlist.Wishlists>(`/wishlist/v2/wishlists/${wishlistId}/lines/${wishlistLineId}`, {
    ...DEFAULT_OPTIONS,
    method: 'DELETE',
    headers: HEADERS,
  });
};

/**
 *  Update a wishlistLine
 */

export const updateWishlistLine = ({
  wishlistLine,
  wishlistId,
}: {
  wishlistLine: wishlist.WishlistLine;
  wishlistId: string;
}) => {
  return bossApi<wishlist.WishlistLine>(`/wishlist/v2/wishlists/${wishlistId}/lines/${wishlistLine.id}`, {
    ...DEFAULT_OPTIONS,
    method: 'PUT',
    headers: HEADERS,
    body: wishlistLine as unknown as Record<string, unknown>,
  });
};
