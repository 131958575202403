import Script from 'next/script';

import { Pinterest } from '..';
import { isB2b } from '../../../utils';

type Props = { locale: string };

const getTrustedShopWidgetID = (locale: string) => {
  return {
    nl: process.env.NEXT_PUBLIC_TRUSTEDSHOPS_ID_BE,
    fr: process.env.NEXT_PUBLIC_TRUSTEDSHOPS_ID_FR,
    'nl-NL': process.env.NEXT_PUBLIC_TRUSTEDSHOPS_ID_NL,
  }[locale];
};

const disableBadge = process.env.NEXT_PUBLIC_REMOVE_BADGE_FOR_DEV;

const AppScripts = ({ locale }: Props) => (
  <>
    {/* Trusted shops badge */}
    {!isB2b && !disableBadge && (
      <Script
        async
        data-color-scheme="light"
        data-desktop-custom-width="156"
        data-desktop-disable-reviews="false"
        data-desktop-enable-custom="false"
        data-desktop-enable-fadeout="false"
        data-desktop-position="right"
        data-desktop-y-offset="0"
        data-disable-mobile="false"
        data-disable-trustbadge="false"
        data-mobile-custom-width="156"
        data-mobile-disable-reviews="false"
        data-mobile-enable-custom="false"
        data-mobile-enable-fadeout="true"
        data-mobile-enable-topbar="false"
        data-mobile-position="left"
        data-mobile-y-offset="0"
        src={`https://widgets.trustedshops.com/js/${getTrustedShopWidgetID(locale)}.js`}
      />
    )}
    <Pinterest />
  </>
);

export default AppScripts;
