import { Nullable } from '@boss/types/b2b-b2c';

import { article } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: true, authScope: process.env.BOSS_API_AUTH_SCOPE_PRODUCT };
const SKU_PAGE_LIMIT = 500; // seems like an optimum to fetch 1000 skus a time.

/**
 * Fetch an article by product id
 */
export const getArticlesByProductId = ({
  productId,
  locale,
  colourid,
}: {
  productId: string;
  locale: string;
  colourid?: string;
}) => {
  const buildPath = () => {
    const base = `/product/v2/skus?productid=${productId}`;

    if (colourid) {
      return `${base}&colourid=${base}`;
    }

    return base;
  };

  return bossApi<article.Article[]>(buildPath(), { ...DEFAULT_OPTIONS, locale });
};

/**
 * Fetch an article by article id
 */
export const getArticleByArticleIds = ({ articleIds, locale }: { articleIds: string[]; locale: string }) => {
  const queryPath = articleIds.map(articleId => `skuid=${articleId}`).join('&');

  return bossApi<article.Article[]>(`/product/v2/skus/byskuid?${queryPath}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Get all articles
 *
 * WARNING: This function is a generator and will return an iterator.
 * The reason for this behaviour is limiting the memory footprint.
 * Before the next batch is processed, the previous one is made eligible for
 * garbage collecion.
 *
 * This is to be consumed with a for await loop
 */
export async function* getAll(locale: string): AsyncGenerator<article.Article> {
  let offset = 0;
  let result: Nullable<article.Article[]>;

  while (
    (result = await bossApi(
      `/product/v2/skus/byskipandlimit?${new URLSearchParams({
        skip: offset.toString(),
        limit: SKU_PAGE_LIMIT.toString(),
      }).toString()}`,
      { ...DEFAULT_OPTIONS, locale },
    ))
  ) {
    for (const article of result) {
      yield article;
    }
    offset += SKU_PAGE_LIMIT;
  }
}
