import { twMerge } from 'tailwind-merge';

interface ProductTabProps {
  className?: string;
  onClick: () => void;
  label: string;
  active: boolean;
  amount?: null | number;
}

const ProductTab = ({ className, label, onClick, active, amount }: ProductTabProps) => (
  <button
    className={twMerge(
      'flex h-10 items-center justify-center border-b-2 px-4',
      active ? 'border-b-blue' : 'border-b-gray',
      className,
    )}
    onClick={onClick}
  >
    <span className={twMerge('text-brown-dark whitespace-nowrap', active ? 'font-bold' : '')}>{label}</span>
    {amount !== null ? <span className="pl-1">({amount ?? 0})</span> : <span className="w-6 " />}
  </button>
);

export default ProductTab;
