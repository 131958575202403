import * as Sentry from '@sentry/nextjs';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';

import { submitForm } from './connector';

type Toast = {
  title: string;
  description?: string;
};

type Props = {
  toasts?: {
    error?: Toast;
    success?: Toast;
  };
};

/**
 * useMutation implementation to submit a form.
 *
 * @returns {Object} An object with a mutate function and mutation status
 */
export const useSubmitForm = ({ toasts }: Props = {}) => {
  return useMutation({
    mutationFn: async (formData: Record<string, unknown>) => await submitForm(formData),
    onSuccess: () => {
      const successToast = toasts?.success;

      if (successToast) {
        toast.success(successToast.title, { description: successToast.description });
      }
    },
    onError: error => {
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          type: 'Submitting form',
        },
      });

      const errorToast = toasts?.error;

      if (errorToast) {
        toast.error(errorToast.title, { description: errorToast.description });
      }
    },
  });
};
