import EventEmitter from 'events';

import { session } from '../../utils';
import { env } from '../env';
import { fromLoginAttempt } from '../login/login';
import refresh from '../refresh/refresh';

// Define the Profile type for tracking the session state.
type Profile = {
  status: 'loading' | 'idle' | 'pristine' | 'error';
  data: session.Session;
};

// Initialize an event emitter for broadcasting status updates.
const EMITTER = new EventEmitter();

//temp fix for the max listeners issue
EMITTER.setMaxListeners(10);

// Variables to track the load promise and the current status.
let loadPromise: Promise<Response> | null = null;
let refreshPromise: Promise<void> | null = null;
let status: Profile['status'] = 'pristine';

// Event constants for the emitter.
export const EMITTER_UPDATE_EVENT = 'update';
export const on = EMITTER.on.bind(EMITTER);
export const off = EMITTER.off.bind(EMITTER);
export const getStatus = () => status;

// Function to update the status and emit the update event.
const updateStatus = (newStatus: Profile['status']) => {
  status = newStatus;
  EMITTER.emit(EMITTER_UPDATE_EVENT, { status, data: session.getData() });
};

// Handles the response from the fetch call.
const handleFetchResult = async (result: Response) => {
  // Handle client errors.
  if (result.status >= 400 && result.status < 500) {
    if (fromLoginAttempt()) {
      session.deactivate();
    }
    return;
  }

  // Handle server errors.
  if (result.status >= 500) {
    updateStatus('error');
    return;
  }

  // Process the successful response.
  const resultData = await result.json();

  session.activate(resultData);
};

// Initiates the loading process.
const initiateLoad = async () => {
  updateStatus('loading');
  loadPromise = fetch(`${env.PATH_PREFIX}/me`);

  try {
    const result = await loadPromise;

    await handleFetchResult(result);
  } catch {
    // Handle any errors during the fetch.
    if (fromLoginAttempt()) {
      session.deactivate();
    }
  }

  // Update the status after processing the load.
  updateStatus(status !== 'error' ? 'idle' : status);
};

// Main function to load the session.
export const load = async () => {
  // Check if there is no initial load required.
  if (!loadPromise && !(fromLoginAttempt() || session.isActive())) {
    updateStatus('idle');
    return { status, data: session.getData() };
  }

  // Check if there's no ongoing refresh process.
  if (!refreshPromise) {
    //Try to refresh the token if needed.
    refreshPromise = refresh();
    await refreshPromise;
  } else {
    await refreshPromise;
  }

  // Start loading if there's no ongoing load process.
  if (!loadPromise) {
    await initiateLoad();
  } else {
    // Wait for the existing load process to complete.
    await loadPromise;
  }

  return { status, data: session.getData() };
};

export default load;
