export const SEARCH_ATTRIBUTES_OBJECT: { [key: string]: string } = {
  l1: 'categories.l1',
  l2: 'categories.l2',
  l3: 'categories.l3',
  l4: 'categories.l4',
  l5: 'categories.l5',
};

export const SORT_OPTIONS = [
  { key: 'priceAscending', value: 'priceAscending' },
  { key: 'priceDescending', value: 'priceDescending' },
];

export const ATTRIBUTE_KEYS = [
  'attr_interior-exterior',
  'attr_layer_type',
  'attr_maintainability',
  'attr_space',
  'attr_surface',
  'attr_application',
  'attr_surface-condition',
  'attr_spray-paint',
  'attr_transparency',
];

export const SEARCH_CONFIG = {
  attributesToHide: [
    SEARCH_ATTRIBUTES_OBJECT.l2,
    SEARCH_ATTRIBUTES_OBJECT.l3,
    SEARCH_ATTRIBUTES_OBJECT.l4,
    SEARCH_ATTRIBUTES_OBJECT.l5,
  ],
};

export const CATEGORY_SEARCH_CONFIG = {
  attributesToHide: [
    SEARCH_ATTRIBUTES_OBJECT.l1,
    SEARCH_ATTRIBUTES_OBJECT.l2,
    SEARCH_ATTRIBUTES_OBJECT.l3,
    SEARCH_ATTRIBUTES_OBJECT.l4,
    SEARCH_ATTRIBUTES_OBJECT.l5,
  ],
};

export const PAINT_GUIDE_CTA_BANNER_INSERT_INDEX = 5;

export const FACET_STATUS_ACTIVE_FILTER = 'status:active';

/**
 * Base filters to be applied to all Algolia searches to eliminate non-active entries
 **/
export const FACET_FILTERS_BASE = FACET_STATUS_ACTIVE_FILTER;
