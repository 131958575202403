import { ParsedUrlQuery } from 'querystring';

import { twMerge } from 'tailwind-merge';

type Props = { query?: ParsedUrlQuery; className?: string };

const getUrlParamString = (query: ParsedUrlQuery | undefined) => {
  if (!query) {
    return '';
  }

  const uri = Object.entries(query)?.reduce((uri: string, [key, value]) => {
    if (typeof value !== 'string') {
      return uri;
    }
    return `${uri}&${key}=${value}`;
  }, '');

  return encodeURI(uri);
};

const ColorTool = ({ query, className }: Props) => {
  return (
    <div className={twMerge('overflow-x-scroll', className)}>
      <iframe
        className="border-0 p-0"
        height="492"
        scrolling="no"
        seamless={true}
        src={`https://clientdata.colorjive.com/mobile/lite4mobile/index.html?brand=colora${getUrlParamString(query)}`}
        title="color tool"
        width="958"
      ></iframe>
    </div>
  );
};

export default ColorTool;
