import { useRouter } from '@boss/hooks';

import { mapNextToContentfulLocale } from '../../utils/localeMapper';

/**
 * Returns a localized country name based on the provided language and countryCode
 * E.g: language: "fr" and countryCode "BE" results in Belgique
 */
const getLocalizedCountryName = (language: string, countryCode: string) => {
  return new Intl.DisplayNames([language], {
    type: 'region',
  }).of(countryCode);
};

/**
 * useCountry hook which exports generic country information
 */
export const useCountry = () => {
  const { locale } = useRouter();

  const _locale = mapNextToContentfulLocale(locale);

  const [language, countryCode] = _locale.split('-');

  const countryName = getLocalizedCountryName(language, countryCode);

  return { countryCode, countryName, getLocalizedCountryName };
};
