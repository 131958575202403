import * as jose from 'jose';

type WellKnownJWKSResponse = {
  jwksUri: string;
};

let __jwks: jose.JWTVerifyGetKey | null = null;

/**
 * Fetch JWKS from well known, construct and cache
 */
const getJWKS = async (wellKnownGetter: () => Promise<WellKnownJWKSResponse>) => {
  if (!__jwks) {
    const wellKnownConfig = await wellKnownGetter();

    __jwks = jose.createRemoteJWKSet(new URL(wellKnownConfig.jwksUri));
  }

  return __jwks;
};

/**
 * Verify the current id token JWT and return it's contents
 */
export const createGetIdentity = (wellKnownGetter: () => Promise<WellKnownJWKSResponse>) => async (value: string) => {
  try {
    const { payload } = await jose.jwtVerify(value, await getJWKS(wellKnownGetter));

    return payload;
  } catch (error) {
    return null;
  }
};
