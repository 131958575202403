import { CHANNEL } from '@boss/constants/b2b-b2c';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { ReactNode } from 'react';

import AccordionPrimary from './Primary';
import AccordionSecondary from './Secondary';
import AccordionSimple from './Simple';

export interface IAccordionProps {
  title: ReactNode;
  children: ReactNode;
  iconClassName?: string;
  variant?: 'primary' | 'secondary' | 'simple';
  hasVideo?: boolean;
  solid?: boolean;
  className?: string;
  buttonClassName?: string;
  closedIcon?: IconDefinition;
  expandedIcon?: IconDefinition;
  titleClassName?: string;
  isOpen?: boolean;
  ariaLabel?: string;
  testId?: string;
  hideDivider?: boolean;
  noIndent?: boolean;
  progressiveRender?: boolean;
}

const Accordion = ({ variant = 'primary', ...props }: IAccordionProps) => {
  // NOTE: The dom signature of both variants is almost equal, aggregate and simplify
  // This is bad for maintainability
  // TODO: Mark for refactor

  if (variant === 'primary') {
    return <AccordionPrimary {...props} />;
  }
  // NOTE: This is a very verbose, badly manageable way of passing a boolean value (i.e. solid)
  // TODO: Simplify
  if (variant === 'secondary' && CHANNEL === 'colora') {
    return <AccordionSecondary {...props} />;
  }
  if (variant === 'secondary' && CHANNEL === 'boss-paints') {
    return <AccordionSecondary solid={true} {...props} />;
  }
  if (variant === 'simple') {
    return <AccordionSimple {...props} />;
  }
  return null;
};

export default Accordion;
