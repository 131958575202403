import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface Props {
  title: string;
  children?: ReactNode;
  className?: string;
}

const PricingCard = ({ title, children, className }: Props) => {
  return (
    <div
      className={twMerge(
        'rounded-l-modal rounded-b-modal shadow-m sm:rounded-b-modal sm:rounded-r-modal w-full bg-white p-6 sm:rounded-none',
        className,
      )}
      data-testid="pricing-card"
    >
      <h4>{title}</h4>
      {children}
    </div>
  );
};

export default PricingCard;
