import { useEffect, useState } from 'react';

import { useIsSSR } from '@boss/hooks';
import { IProduct } from '@boss/services';
import { ISearchProduct, SimpleColor } from '@boss/types/b2b-b2c';

import { useColorByColorTypeGroup, useColorsByColorCodes, useSearchColor } from '../../client-queries';
import { COLOR_TYPE } from '../../constants';
import { useColors, useFavorites, useRecentViewedColors } from '../../hooks';
import { getProductColorType } from '../../utils';

type Props = {
  showBaseColors: boolean;
  isPaint: boolean;
  product: ISearchProduct | IProduct;
  initialColor?: string;
};

/**
 * Hook to consolidate most of the functionality of the PDP.
 * This is far from perfect, however for practical reasons, this is as good as it get without really rethinking the structure.
 * The Ideal scenario would be a collection of maybe 4 differant hooks, who each return max 5 members
 *
 * @todo
 * integrate this in the Product detail page and on paintguide result card, by combinging the methods in this whook with the product data hook
 * - pdp page/paintguideresultcard methods about color go in this hook
 * - this hook should be integrated in the useproductdata hook
 * - spit it all out again, reducing duplicate code significantly
 */
export function useProductColor({ isPaint, product, initialColor }: Props) {
  const [showColorModal, setShowColorModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchedValue, setSearchedValue] = useState('');
  const [selectedColor, setSelectedColor] = useState<SimpleColor | undefined>();
  const {
    colorBase,
    setSelectedColorGroup,
    showColors,
    productOptions,
    selectedColorGroup,
    handleProductOptionClick,
    activeProductOption,
  } = useColors({ showBaseColors: false });
  const { favorites } = useFavorites();
  const initialColorCode = initialColor ?? product.defaultColorCodes?.[0] ?? undefined;

  const isSSR = useIsSSR();
  const productId = product.id;

  const { data: favoriteColors } = useColorsByColorCodes(favorites.COLORS, productId);
  const { data: colorsByGroup } = useColorByColorTypeGroup(colorBase, productId, colorBase !== 'mymx');
  // Since no default color is available at the moment, a generic search with the initialColor is done and the first available item is set
  const { data: initialColors, isInitialLoading: isInitialColorLoading } = useSearchColor({
    value: initialColorCode,
    productId,
    enabled: showColors && isPaint,
  });
  const { data: searchColors, isInitialLoading: searchLoading } = useSearchColor({
    value: searchedValue,
    productId,
    enabled: !!searchedValue,
    colortypegroup: COLOR_TYPE.MYMX,
  });
  const { addColor, viewedColors } = useRecentViewedColors();

  const isPanelLoading = isSSR || searchLoading || isInitialColorLoading;

  const onColorClick = (simpleColor: SimpleColor) => {
    setSelectedColor(simpleColor);
    if (colorBase !== 'mymx') {
      return;
    }
    addColor(simpleColor);
  };

  const handleProductPanelSearch = () => {
    setSearchedValue(searchValue);

    setShowColorModal(true);
  };

  const handleColorGroupClick = (color?: SimpleColor) => {
    if (color) {
      setSelectedColorGroup(color);
    }

    setShowColorModal(true);
  };

  useEffect(() => {
    const color = initialColors?.[0];

    if (color) {
      setSelectedColor(color);
      setSelectedColorGroup(color.groups?.[0]);
    }
  }, [initialColors, setSelectedColor, setSelectedColorGroup]);

  return {
    favoriteColors,
    colorsByGroup,
    colorType: getProductColorType(product),
    handleColorGroupClick,
    onColorClick,
    searchValue,
    selectedColor,
    setSearchValue,
    searchedValue,
    setSelectedColorGroup,
    isPanelLoading,
    handleProductPanelSearch,
    showColorModal,
    viewedColors,
    searchColors,
    productOptions,
    selectedColorGroup,
    handleProductOptionClick,
    activeProductOption,
    setShowColorModal,
    setSelectedColor,
    showColors,
  };
}
