import { twMerge } from 'tailwind-merge';

import Image from '../../Image';
import { HeroProps } from '../index';

const HeroSecondary = ({ title, image, imageAlt, backgroundColor, className }: HeroProps) => (
  <div
    className={twMerge(
      'relative flex min-h-[300px] w-full items-center justify-center bg-gray-600 py-4 md:min-h-[500px]',
      className,
    )}
    style={{ backgroundColor }}
  >
    <div className="absolute inset-x-0 inset-y-0">
      {image && <Image alt={imageAlt} className="object-fit" src={image} />}
    </div>
    <h1 className="h1 relative p-4 text-center text-white md:w-1/3">{title}</h1>
  </div>
);

export default HeroSecondary;
