/* eslint-disable sonarjs/no-duplicate-string */
export default [
  {
    appointmentid: '1',
    appointmenttype: 'Afterwork Boss paints Kontich',
    startdatetime: '2023-08-31T08:12:25.225Z',
    enddatetime: '2023-08-31T08:12:25.225Z',
    bosscontact: 'Mark',
    worksite: {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'De Wachter Bvba',
      street: 'Dorpslplein',
      number: '12',
      postbox: '',
      zipcode: '2850',
      city: 'Kontich',
      country: 'België',
    },
  },
  {
    appointmentid: '2',
    appointmenttype: 'Afspraak met vertegenwoordiger',
    startdatetime: '2023-01-01T16:05:25.225Z',
    enddatetime: '2023-08-31T08:12:25.225Z',
    bosscontact: 'Francine',
    worksite: {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'De Wachter Bvba',
      street: 'Kerkstraat',
      number: '2',
      postbox: 'A',
      zipcode: '9000',
      city: 'Gent',
      country: 'België',
    },
  },
  {
    appointmentid: '3',
    appointmenttype: 'Technisch advies',
    startdatetime: '2023-11-21T08:35:25.225Z',
    enddatetime: '2023-08-31T08:12:25.225Z',
    bosscontact: 'Jerome',
    worksite: {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'De Wachter Bvba',
      street: 'Land van Waaslaan',
      number: '923',
      postbox: '',
      zipcode: '1000',
      city: 'Brussel',
      country: 'België',
    },
  },
  {
    appointmentid: '4',
    appointmenttype: 'Technisch advies',
    startdatetime: '2023-12-21T08:35:25.225Z',
    enddatetime: '2023-12-31T08:12:25.225Z',
    bosscontact: 'Jerome',
    worksite: {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'De Wachter Bvba',
      street: 'Land van Waaslaan',
      number: '923',
      postbox: '',
      zipcode: '1000',
      city: 'Brussel',
      country: 'België',
    },
  },
  {
    appointmentid: '5',
    appointmenttype: 'Technisch advies',
    startdatetime: '2023-12-20T08:35:25.225Z',
    enddatetime: '2023-12-20T08:12:25.225Z',
    bosscontact: 'Jerome',
    worksite: {
      id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      name: 'De Wachter Bvba',
      street: 'Land van Waaslaan',
      number: '923',
      postbox: '',
      zipcode: '1000',
      city: 'Brussel',
      country: 'België',
    },
  },
];
