import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
  elevatedValue?: string | number;
  unit?: string;
}

// visual component for displaying square meters
const SquaredUnit = ({ className, elevatedValue = 2, unit = 'm' }: Props) => (
  <span className={twMerge('relative', className)}>
    {unit}
    <span className="absolute bottom-1/3 left-full text-[9px]">{elevatedValue}</span>
  </span>
);

export default SquaredUnit;
