import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface SearchHistoryProps {
  suggestions: string[];
  onClear: () => void;
  onRemove: (term: string) => void;
  onSelect: (term: string) => void;
  translations: {
    title: string;
    clearAll: string;
  };
}

const SearchHistory: React.FC<SearchHistoryProps> = ({ suggestions, onSelect, onClear, onRemove, translations }) => {
  return (
    <div className="rounded-lg bg-white p-4 shadow">
      <div className="mb-2 flex items-center justify-between">
        <span className="text-sm font-semibold text-gray-700">{translations.title}</span>
        <button className="text-xs font-semibold text-blue-600 hover:underline" onClick={onClear}>
          {translations.clearAll}
        </button>
      </div>
      <div className="space-y-2">
        {suggestions.map((term, index) => (
          <div className="flex items-center justify-between" key={index}>
            <button className="text-sm text-gray-600" onClick={() => onSelect(term)}>
              {term}
            </button>
            <button className="text-gray-400 hover:text-gray-500" onClick={() => onRemove(term)}>
              <FontAwesomeIcon icon={faClose} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchHistory;
