import { useQuery } from '@tanstack/react-query';
import { Nullable } from 'vitest';

import { useRouter } from '@boss/hooks';
import { TQuotationFileLink, TQuotations } from '@boss/services/client';

import { fetchQuotationLink, fetchQuotations } from './connector';
import { useProfile } from '../../hooks';

import { quotationKeys } from '.';

/**
 * useQuery implementation to fetch quotations
 *
 */
export const useQuotations = () => {
  const profile = useProfile();
  const accountId = profile.data?.extension_AccountId?.toString() || '';

  return useQuery<TQuotations>({
    queryKey: quotationKeys.quotations(accountId),
    queryFn: async () => await fetchQuotations(accountId || ''),
    enabled: !!accountId,
  });
};

/**
 * useQuery implementation to fetch quotations
 *
 */
export const useQuotationFile = (quotationId = '') => {
  const { locale } = useRouter();

  return useQuery<Nullable<TQuotationFileLink>>({
    queryKey: quotationKeys.fileLink(quotationId, locale),
    queryFn: async () => await fetchQuotationLink(quotationId, locale),
    enabled: !!quotationId,
  });
};
