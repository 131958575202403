import { faFacebook, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';

import SocialShareIconWrapper from './SocialShareIconWrapper';
/**
 * Represents the properties of the HeroBanner
 * @property {boolean} facebook - whether the component includes facebook sharing
 * @property {boolean} linkedIn - whether the component includes linkedIn sharing
 * @property {boolean} mail - whether the component includes mail sharing
 * @property {boolean} xTwitter - whether the component includes twitter (X) sharing
 * @property {string} url - the link to be shared
 * @property {string} iconColor - an optional color in which the icons will be displayed
 */

export type SocialShareProps = {
  body?: string;
  facebook?: boolean;
  iconColor?: string;
  linkedIn?: boolean;
  mail?: boolean;
  title?: string;
  url: string;
  xTwitter?: boolean;
};

const SocialShare = ({ body, facebook, iconColor, linkedIn, mail, xTwitter, url, title }: SocialShareProps) => {
  return (
    <div className="inline-flex items-center justify-center gap-2">
      {facebook && (
        <FacebookShareButton url={url}>
          <div className=" flex items-center justify-center">
            <FontAwesomeIcon className="h-6 w-6 rounded-full text-2xl" icon={faFacebook} style={{ color: iconColor }} />
          </div>
        </FacebookShareButton>
      )}
      {mail && (
        <EmailShareButton body={body} subject={title} url={url}>
          <SocialShareIconWrapper icon={faEnvelope} iconColor={iconColor} />
        </EmailShareButton>
      )}
      {linkedIn && (
        <LinkedinShareButton summary={title} title={title} url={url}>
          <SocialShareIconWrapper icon={faLinkedinIn} iconColor={iconColor} />
        </LinkedinShareButton>
      )}
      {xTwitter && (
        <TwitterShareButton title={title} url={url}>
          <SocialShareIconWrapper icon={faXTwitter} iconColor={iconColor} />
        </TwitterShareButton>
      )}
    </div>
  );
};

export default SocialShare;
