import { StepByStepPlanStepFields } from '@boss/types/b2b-b2c';
import { List, TextLink } from '@boss/ui';
import { slugify } from '@boss/utils';

type Props = {
  steps: StepByStepPlanStepFields[];
};

const StepAnchorLinks = ({ steps }: Props) => {
  return (
    <List as="ordered" className="my-2 flex flex-col md:mb-7">
      {steps.map(step => (
        <li className="mb-5 md:mb-3" key={step.id}>
          <TextLink
            className="font-body-mobile md:font-body font-medium md:font-normal"
            href={`#${slugify(step.stepTitle)}`}
            underline
          >
            {step.stepTitle}
          </TextLink>
        </li>
      ))}
    </List>
  );
};

export default StepAnchorLinks;
