import PrimaryColorPickerSkeleton from './Primary';
import SecondaryColorPickerSkeleton from './Secondary';

type ColorPickerSkeletonProps = {
  className?: string;
  variant: 'primary' | 'secondary';
};

export type ColorPickerSkeletonPropsInstanceProps = Omit<ColorPickerSkeletonProps, 'variant'>;

const ColorPickerSkeleton = ({ variant, ...props }: ColorPickerSkeletonProps) => {
  if (variant === 'primary') {
    return <PrimaryColorPickerSkeleton {...props} />;
  }
  if (variant === 'secondary') {
    return <SecondaryColorPickerSkeleton {...props} />;
  }
  return null;
};

export default ColorPickerSkeleton;
