/**
 * This module stores session state in local storage
 *
 * On session confirmation, the marker should be set to true
 * When logging out, the marker should be set to false
 * When a refresh fails, the marker should be set to false
 */

import { EventEmitter } from 'events';

import { JWTPayload } from 'jose';
import Cookies from 'universal-cookie';

import { COOKIE_EXPIRATION_TIME_1_MONTH, UP_ID_STORAGE_KEY } from '@boss/constants/b2b-b2c';

const EMITTER = new EventEmitter();
const STORAGE_KEY = 'up'; // Short for user profile
const REFRESH_TOKEN_STORAGE_KEY = 'rt'; // Short for refresh token

EMITTER.setMaxListeners(10);

const cookies = new Cookies(null, { path: '/' });

let profile: Session = (() => {
  if (typeof window === 'object') {
    const stringifiedValue = window.localStorage.getItem(STORAGE_KEY);

    if (stringifiedValue) {
      try {
        return JSON.parse(stringifiedValue);
      } catch (error) {
        // Do nothing
      }
    }
  }

  return null;
})();

let __refreshToken: string | null = (() => {
  if (typeof window === 'object') {
    const value = window.localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);

    if (value) {
      return value;
    }
  }

  return null;
})();

let __status: 'loading' | 'idle' | 'pristine' = profile ? 'idle' : 'pristine';

/**
 * Set the value of the session
 */
const set = (value: typeof profile, refreshToken: string | null = null) => {
  profile = value;
  __refreshToken = refreshToken;

  if (typeof window === 'object') {
    if (value) {
      window.localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
      // We set a cookie with the user id, to shield the checkout page
      cookies.set(UP_ID_STORAGE_KEY, value.extension_AccountId, { maxAge: COOKIE_EXPIRATION_TIME_1_MONTH });

      if (refreshToken) {
        window.localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
      }
    } else {
      cookies.remove(UP_ID_STORAGE_KEY, { path: '/' });
      window.localStorage.removeItem(STORAGE_KEY);
      window.localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    }
  }

  // Notify change
  EMITTER.emit(EMITTER_CHANGE_EVENT, profile);
};

// Export types
export type Session = JWTPayload | null;

/**
 * Export update registration event subscriber
 */
export const EMITTER_CHANGE_EVENT = 'change';
export const EMITTER_STATUS_CHANGE_EVENT = 'statusChange';
export const on = EMITTER.on.bind(EMITTER);
export const off = EMITTER.off.bind(EMITTER);

/**
 * Get the value of a session
 */
export const isActive = (): boolean => {
  return !!profile;
};

/**
 * Get the profile or null
 */
export const getData = () => profile;

/**
 * Get the refreshToken if it's ever been set
 */
export const getRefreshToken = () => __refreshToken;

/**
 * Activate a session
 */
export const activate = (newProfile: Session, refreshToken: string | null = null) => set(newProfile, refreshToken);

/**
 * Clear session
 */
export const deactivate = () => {
  set(null, null);
  EMITTER.removeAllListeners(); // Remove all listeners
};

/**
 * Set the status
 */
export const setStatus = (status: 'loading' | 'idle' | 'pristine') => {
  __status = status;
  EMITTER.emit(EMITTER_STATUS_CHANGE_EVENT, __status);
};

/**
 * Fetch the status
 */
export const getStatus = () => __status;
