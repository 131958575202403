import { useLocalStorage } from 'usehooks-ts';

const MAX_AMOUNT_OF_ITEMS = 20;

export const useRecentViewedProducts = () => {
  const [viewedProductIds, setViewedIds] = useLocalStorage<string[]>('RECENT_VIEWED_PRODUCTS', []);

  const addProductId = (id: string) => {
    const previousIds = viewedProductIds.filter(pId => pId !== id);
    const newRecentIds = [...new Set([id, ...previousIds].slice(0, MAX_AMOUNT_OF_ITEMS))];

    setViewedIds(newRecentIds);
  };

  return { addProductId, viewedProductIds };
};

export default useRecentViewedProducts;
