import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { toast } from 'sonner';

import { useRouter } from '@boss/hooks';
import {
  IAccount,
  IAccountDiscountInfo,
  IAccountOrder,
  IAdvice,
  IAppointment,
  IContact,
  IInvoice,
  IInvoiceFilterOptions,
  IWorksite,
  IWorksiteDetail,
  PaintguideResults,
  TAccountDiscountInfoType,
} from '@boss/services/client';

import {
  fetchAccount,
  fetchAccountDiscountInfo,
  fetchAdvices,
  fetchAppointments,
  fetchContacts,
  fetchInProcessOrders,
  fetchInvoices,
  fetchOrders,
  fetchPaintguideResults,
  fetchWorksites,
  fetchWorksitesDetail,
  updateAccount,
  updateContact,
  updatePaintguideResults,
} from './connector';
import { RESULTS } from '../../constants';
import { useOptimistic, useProfile } from '../../hooks';
import { isB2b } from '../../utils';

import { accountKeys } from '.';

const errorMessage = 'toast.fetchAccount.error.title';

/**
 * useQuery implementation to fetch account.
 *
 * @param {string} locale
 * @returns {Promise<IAccount>}
 */
export const useAccount = (locale: string) => {
  const { t } = useTranslation('common');
  const { accountId, status } = useProfile();

  return useQuery<IAccount>({
    queryKey: accountKeys.account(locale, accountId, status),
    // We can safely pass the falsy account id. The connector decorator will capture it
    queryFn: async () => await fetchAccount(locale, accountId),
    refetchOnWindowFocus: false,
    enabled: status === 'idle',
    meta: {
      errorMessage: t(errorMessage),
    },
  });
};

export const useWorksites = (locale: string) => {
  const { t } = useTranslation('common');
  const { accountId, status } = useProfile();

  return useQuery<IWorksite[]>({
    queryKey: accountKeys.worksites(locale, accountId, status),
    queryFn: async () => await fetchWorksites(locale, accountId),
    enabled: status === 'idle',
    meta: {
      errorMessage: t(errorMessage),
    },
  });
};

export const useWorkSitesDetail = (locale: string) => {
  const { t } = useTranslation('common');
  const { accountId, status, isLoggedIn } = useProfile();

  return useQuery<IWorksiteDetail[]>({
    queryKey: accountKeys.worksitesDetail(locale, accountId, status),
    queryFn: async () => await fetchWorksitesDetail(locale, accountId),
    enabled: isLoggedIn,
    meta: {
      errorMessage: t(errorMessage),
    },
  });
};

/**
 * useQuery implementation to fetch appointments.
 *
 * @param {string} locale
 * @returns {Promise<IAccount>}
 */
export const useAppointments = (locale: string) => {
  const { t } = useTranslation('common');
  const { status, accountId } = useProfile();

  return useQuery<IAppointment[]>({
    queryKey: accountKeys.appointments(locale, accountId),
    // We can safely pass the falsy account id. The connector decorator will capture it
    queryFn: async () => await fetchAppointments(locale, accountId),
    enabled: status === 'idle',
    meta: {
      errorMessage: t('toast.fetchAppointments.error.title'),
    },
  });
};

/**
 * useMutation implementation to update account.
 *
 * @param {account} IAccount
 * @returns {Promise<IAccount>}
 **/
export const useUpdateAccount = () => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { accountId } = useProfile();
  const { locale } = useRouter();

  const accountKey = accountKeys.account(locale, accountId, 'idle');

  return useMutation({
    mutationFn: async (account: IAccount) => {
      return await updateAccount(accountId, account);
    },
    onMutate: async data => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: accountKey });

      // Snapshot the previous value
      const previousAccountInfo = queryClient.getQueryData(accountKey);

      // Optimistically update to the new value
      queryClient.setQueryData(accountKey, data);

      // Return a context object with the snapshotted value
      return { previousAccountInfo };
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(accountKey, context?.previousAccountInfo);
      toast.error(t('toast.updateAccount.error.title'));
    },
    onSuccess: () => toast.success(t('toast.updateAccount.success.title')),
  });
};

/**
 * useQuery implementation to fetch contacts.
 *
 * @param {string} locale
 * @returns {Promise<IContact[]>}
 **/
export const useContacts = (locale: string) => {
  const { t } = useTranslation('common');
  const { accountId, status, isLoggedIn } = useProfile();

  return useQuery<IContact[]>({
    queryKey: accountKeys.contacts(locale, accountId),
    queryFn: async () => await fetchContacts(accountId, locale),
    enabled: status === 'idle' && isLoggedIn,
    meta: {
      errorMessage: t('toast.fetchContacts.error.title'),
    },
  });
};

/**
 * useMutation implementation to update contact.
 * @param {IContact} contact
 * @returns {Promise<IContact>}
 * */
export const useUpdateContact = () => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { accountId } = useProfile();
  const { locale } = useRouter();
  const { onMutateHelper } = useOptimistic();

  const accountKey = accountKeys.contacts(locale, accountId);

  return useMutation({
    mutationFn: async (contact: IContact) => {
      return await updateContact(contact);
    },
    onMutate: async data => {
      const previousContacts = queryClient.getQueryData<IContact[]>(accountKey);

      const updatedContacts = previousContacts?.map(contact => {
        if (contact.id === data.id) {
          return data;
        }
        return contact;
      });

      return onMutateHelper(accountKey, previousContacts, updatedContacts);
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(accountKey, context?.previousData);
      toast.error(t('toast.updateContact.error.title'));
    },
    onSuccess: () => toast.success(t('toast.updateContact.success.title')),
  });
};

/**
 * useQuery implementation to fetch invoices.
 *
 * @param {string} locale
 * @returns {Promise<IInvoice[]>}
 **/
export const useInvoices = (locale: string, filterOptions: IInvoiceFilterOptions) => {
  const { t } = useTranslation('common');
  const { status, accountId } = useProfile();

  return useQuery<IInvoice[]>({
    queryKey: accountKeys.invoices(locale, accountId, filterOptions),
    // We can safely pass the falsy account id. The connector decorator will capture it
    queryFn: async () => await fetchInvoices(accountId, locale, filterOptions),
    enabled: status === 'idle',
    meta: {
      errorMessage: t('toast.fetchInvoices.error.title'),
    },
  });
};

/**
 * useMutation implementation to save paint guide result url to an account
 **/
export const usePaintguideResults = (locale: string) => {
  const { t } = useTranslation('common');
  const profile = useProfile();
  // Ensure a value. The connector decorator will capture lack of session before execution
  const accountId = profile.data?.extension_AccountId?.toString() || '';

  return useQuery<PaintguideResults>({
    queryKey: accountKeys.paintguides(locale, accountId),
    // We can safely pass the falsy account id. The connector decorator will capture it
    queryFn: async () => await fetchPaintguideResults(accountId, locale),
    enabled: profile.status === 'idle',
    onError: e => {
      console.error(e);
    },
    meta: {
      errorMessage: t('toast.PaintguideResults.error.title'),
    },
  });
};

/**
 * useMutation implementation to save paint guide result url to an account
 **/
export const useUpdatePaintguideResults = () => {
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();
  const { accountId } = useProfile();
  const { locale, asPath } = useRouter();
  const { onMutateHelper } = useOptimistic();

  const accountKey = accountKeys.paintguide(locale, accountId);

  return useMutation({
    mutationFn: async ({ description }: { description: string }) => {
      const [path, queryString] = asPath.split('?');
      const searchParams = new URLSearchParams(queryString);

      if (isB2b) {
        searchParams.append(RESULTS, 'true');
      }

      return await updatePaintguideResults({
        url: `${path}?${searchParams.toString()}`,
        accountnumber: accountId,
        description,
      });
    },
    onMutate: async data => {
      const previousAccountInfo = queryClient.getQueryData(accountKey);

      return onMutateHelper(accountKey, previousAccountInfo, data);
    },
    onError: (_, __, context) => {
      queryClient.setQueryData(accountKey, context?.previousData);
      toast.error(t('toast.updatePaintguideResults.error.title'));
    },
    onSuccess: () => toast.success(t('toast.updatePaintguideResults.success.title')),
  });
};

/**
 * useQuery implementation to fetch orders.
 *
 * @param {string} locale
 * @returns {Promise<{
 * orders: IAccountOrder[],
 * count: number
 * }>}
 **/
export const useOrders = (
  locale: string,
  filterOptions: { limit?: number; offset?: number; fromDate?: string; toDate?: string; orderId?: string },
) => {
  const { t } = useTranslation('common');
  const { status, accountId } = useProfile();

  return useQuery<{
    orders: IAccountOrder[];
    count: number;
  }>({
    queryKey: accountKeys.orders(locale, accountId, filterOptions),
    // We can safely pass the falsy account id. The connector decorator will capture it
    queryFn: async () => await fetchOrders(accountId, locale, filterOptions),
    enabled: status === 'idle',
    meta: {
      errorMessage: t('toast.fetchOrders.error.title'),
    },
  });
};

// fetch order method (only used for b2c, since b2b uses the useGetOrderById hook)
export const useOrder = (locale: string, orderId: string) => {
  const { t } = useTranslation('common');
  const { status, accountId } = useProfile();

  return useQuery<IAccountOrder>({
    queryKey: accountKeys.order(locale, accountId, orderId),
    queryFn: async () => {
      const orders = await fetchOrders(accountId, locale, { orderId });

      return orders.orders[0];
    },
    enabled: status === 'idle',
    meta: {
      errorMessage: t('toast.fetchOrder.error.title'),
    },
  });
};

/**
 * useQuery implementation to fetch inProcess orders.
 *
 * @param {string} locale
 * @returns {Promise<{
 * orders: IAccountOrder[],
 * count: number
 * }>}
 **/
export const useInProcessOrders = (locale: string, filterOptions: { limit?: number; offset?: number; type: 1 | 2 }) => {
  const { t } = useTranslation('common');
  const { status, accountId } = useProfile();

  return useQuery<{
    orders: IAccountOrder[];
    count: number;
  }>({
    queryKey: accountKeys.inProcessOrders(locale, accountId, filterOptions),
    // We can safely pass the falsy account id. The connector decorator will capture it
    queryFn: async () => await fetchInProcessOrders(accountId, locale, filterOptions),
    enabled: status === 'idle',
    meta: {
      errorMessage: t('toast.fetchInProcessOrders.error.title'),
    },
  });
};

/**
 * useQuery implementation to fetch advices.
 *
 * @param {string} locale
 * @returns {Promise<IAdvice[]>}
 **/
export const useAdvices = (locale: string) => {
  const { t } = useTranslation('common');
  const { status, accountId } = useProfile();

  return useQuery<IAdvice[]>({
    queryKey: accountKeys.advices(locale, accountId),
    // We can safely pass the falsy account id. The connector decorator will capture it
    queryFn: async () => await fetchAdvices(accountId, locale),
    enabled: status === 'idle',
    meta: {
      errorMessage: t('toast.fetchAdvices.error.title'),
    },
  });
};

/**
 * useQuery implementation to fetch account discount info.
 *
 * @param {string} locale
 * @returns {Promise<IAccountDiscountInfo[]>}
 **/
export const useAccountDiscountInfo = (locale: string, type: TAccountDiscountInfoType) => {
  const { t } = useTranslation('common');
  const { status, accountId } = useProfile();

  return useQuery<IAccountDiscountInfo[]>({
    queryKey: accountKeys.accountDiscountInfo(locale, accountId, type),
    // We can safely pass the falsy account id. The connector decorator will capture it
    queryFn: async () => await fetchAccountDiscountInfo(accountId, locale, type),
    enabled: status === 'idle',
    meta: {
      errorMessage: t('toast.fetchAccountDiscountInfo.error.title'),
    },
  });
};
