import { LocalizedSlugs, NavigationItem } from '@boss/types/corporate-jobs';

import { Link, LocaleSelector } from '../../..';

type Props = {
  localizedSlugs: LocalizedSlugs;
  topLinks?: NavigationItem[];
};

const TopBar = ({ localizedSlugs, topLinks }: Props) => (
  <div className="border-b-1 border-gray-medium flex w-full items-center justify-end gap-10 border-solid py-2">
    {topLinks?.map(link => (
      <Link href={link.href} key={link.id || `${link.id || 'main-link'}-${link.label}`}>
        <span className="small hover:text-green-sheen h-full">{link.label}</span>
      </Link>
    ))}
    {localizedSlugs && <LocaleSelector localizedSlugs={localizedSlugs} />}
  </div>
);

export default TopBar;
