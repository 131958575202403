import { useRouter } from 'next/router';

import { CHANNEL } from '@boss/constants/b2b-b2c';
import { Tracker } from '@boss/utils';

import { useProfile } from '../';

export const useEventTracker = () => {
  const { accountId, isLoggedIn } = useProfile();
  const { locale } = useRouter();

  const commonFields = {
    language: locale,
    visitorLoginStatus: isLoggedIn ? 'logged-in' : 'logged-out',
    userId: accountId,
    brand: CHANNEL ?? '',
  };

  const tracker = new Tracker(commonFields);

  const trackPageView = (pageType: string) =>
    tracker.trackPageView({
      pageType,
    });

  const trackLinkClick = (props: Record<string, unknown>) =>
    tracker.trackLinkClick({
      ...props,
    });

  const trackCustomEvent = (event: string, props: Record<string, unknown>) =>
    tracker.trackCustomEvent({
      event,
      ...props,
    });

  return {
    trackPageView,
    trackLinkClick,
    trackCustomEvent,
  };
};
