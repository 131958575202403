export const getPixelPositionOffset = ({
  width,
  height,
  offsetX = 0,
  offsetY = 0,
}: {
  width: number;
  height: number;
  offsetX?: number;
  offsetY?: number;
}) => {
  return { x: -(width / 2 - offsetX), y: -(height / 2 - offsetY) };
};
