type LegalFootnotesProps = {
  children: React.ReactNode;
};

const LegalFootnotes = ({ children }: LegalFootnotesProps) => {
  return (
    <div className="col-span-full md:col-start-7 md:ml-auto">
      <ul className="text-sm text-white">{children}</ul>
    </div>
  );
};

export default LegalFootnotes;
