import { CSSProperties } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  skeletonClassName?: string;
  style?: CSSProperties;
};

const Skeleton = ({ className, skeletonClassName, style }: Props) => (
  <div className={twMerge('animate-pulse', className)} style={style}>
    <div className={twMerge('bg-skeleton h-full w-full rounded-md', skeletonClassName)} />
  </div>
);

export default Skeleton;
