import { TFunction } from 'next-i18next';

import { IEnrichedQuestion, IOption } from '@boss/services';

export interface Step {
  label: string;
  id?: string;
  selected?: string;
  color?: string;
  active?: boolean;
}

/**
 * Returns steps objects pulled from the options util and mapped with translations from the translation files
 *
 * @param t - translate function from the useTranslation hook
 * @param paintguideQuestionAttributes - Array with question name attributes
 */
export const getPaintguideStepsFromTranslations = (
  paintguideQuestionAttributes: string[],
  t: TFunction,
  enrichedQuestions?: Record<string, IEnrichedQuestion>,
) => {
  const steps: Step[] = [];

  for (const question of paintguideQuestionAttributes) {
    steps.push({
      id: question,
      label: enrichedQuestions?.[question]?.question ?? t(`${question}`),
    });
  }

  return steps;
};

/**
 * Returns steps objects from the paintguide questions and mapped with translations from the translation files
 *
 * @param t - translate function from the useTranslation hook
 * @param paintguideQuestionAttributes - Array with question name attributes
 * @param questions - filled in questions from a paintguide result
 */
export const getPaintguideStepsFromQuestions = (
  paintguideQuestionAttributes: string[],
  t: TFunction,
  questions: Record<string, IOption>,
  enrichedQuestions?: Record<string, IEnrichedQuestion>,
) => {
  const steps: Step[] = [];

  for (const question of paintguideQuestionAttributes) {
    const selected = questions[question.split('.')[1]]?.label ?? '';

    steps.push({
      label: enrichedQuestions?.[question]?.question ?? t(`${question}`),
      selected,
      id: question,
    });
  }

  return steps;
};
