import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { IArticle } from '@boss/types/b2b-b2c';
import { PriceNote } from '@boss/ui';

import PrimaryArticleTable from './Primary';
import SecondaryArticleTable from './Secondary';
import { usePrice } from '../../hooks';
import { productPanelVariant } from '../../utils';

export type ArticleValues = {
  quantity: number;
  unit: string;
  productImageSrc?: string;
  lineId?: string;
  price?: number;
};
export type ArticleIdsObject = Record<string, ArticleValues>;

export type Props = {
  articles?: IArticle[];
  articleType: 'paint' | 'nonPaint';
  selectedArticleIdsObject: ArticleIdsObject;
  setSelectedArticleIdsObject: Dispatch<SetStateAction<ArticleIdsObject>>;
  showPrivatePrices?: boolean;
  togglePrivatePrices?: () => void;
  type?: 'default' | 'slim';
  isLoading?: boolean;
  variant?: 'primary' | 'secondary';
  isLoggedIn?: boolean;
};

const ArticlesTable = ({
  variant,
  togglePrivatePrices,
  showPrivatePrices,
  setSelectedArticleIdsObject,
  ...props
}: Props) => {
  const { t } = useTranslation(['common', 'product']);
  const { showPrice } = usePrice();

  const updateSelectedArticlesIdsObject = (id: string, newObject: ArticleValues) => {
    setSelectedArticleIdsObject(idsObject => {
      const { [id]: originalObject, ...rest } = idsObject;
      const mergedObject = { ...originalObject, ...newObject };

      return { ...rest, [id]: mergedObject };
    });
  };

  const commonProps = {
    showPrivatePrices,
    setSelectedArticleIdsObject,
    updateSelectedArticlesIdsObject,
  };

  return (
    <>
      {productPanelVariant === 'primary' ? (
        <PrimaryArticleTable {...props} {...commonProps} />
      ) : (
        <SecondaryArticleTable {...props} {...commonProps} />
      )}
      {togglePrivatePrices && showPrice && (
        <div className="relative">
          <PriceNote
            active={!!showPrivatePrices}
            onClick={togglePrivatePrices}
            translations={t('productPanel.priceNote', { returnObjects: true, ns: 'product' })}
          />
          <div className="right-1/20 border-l-10 border-r-10 border-b-10 border-b-blue-light absolute bottom-full h-0 w-0 border-l-transparent border-r-transparent" />
        </div>
      )}
    </>
  );
};

export default ArticlesTable;
