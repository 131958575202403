import { twMerge } from 'tailwind-merge';

import Skeleton from '../Skeleton';

type Props = {
  className?: string;
};

const ColorGroupButtonSkeleton = ({ className }: Props) => (
  <button
    className={twMerge(
      'border-1 flex w-full items-center justify-between gap-3 border-transparent px-5 py-3',
      className,
    )}
  >
    <Skeleton className="h-8 w-8 [&>*]:rounded-full" />
    <Skeleton className="h-4 w-20" />
  </button>
);

export default ColorGroupButtonSkeleton;
