import { twMerge } from 'tailwind-merge';

import Link from '../Link';

interface Props {
  imageSrc?: string;
  imageAlt?: string;
  title: string;
  slug?: string;
  trackInfo?: Record<string, string | number>;
}

const Card = ({ imageSrc, imageAlt, title, slug, trackInfo }: Props) => {
  return (
    <Link
      className="shadow-m rounded-brand flex h-full flex-col rounded-bl-none bg-white"
      href={slug || ''}
      trackInfo={trackInfo}
    >
      <div className={twMerge('h-50 w-full flex-grow', imageSrc ? '' : 'rounded-brand rounded-b-none bg-red-900')}>
        {imageSrc && (
          <img alt={imageAlt} className="rounded-brand h-full w-full rounded-b-none object-cover" src={imageSrc} />
        )}
      </div>
      <div className="rounded-br-brand h4 min-h-20 bg-white px-6 py-4">{title}</div>
    </Link>
  );
};

export default Card;
