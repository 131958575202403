import { ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { IS_RECAPTCHA_ENABLED } from '../../../constants';

type Props = {
  children: ReactNode;
};

const RecaptchaProvider = ({ children }: Props) => {
  if (!IS_RECAPTCHA_ENABLED) {
    return <>{children}</>;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY ?? ''}
      scriptProps={{ async: true, defer: true, appendTo: 'body' }}
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default RecaptchaProvider;
