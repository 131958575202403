import { getAlgolia } from '@boss/algolia-client';
import { ISearchPaintSystem } from '@boss/types/b2b-b2c';

const fetchPaintSystems = async (indexName: string, query: string) => {
  const { searchIndex } = getAlgolia({
    indexName,
  });

  if (!searchIndex) {
    return null;
  }

  return await searchIndex.search<ISearchPaintSystem>('', {
    facets: ['*'],
    filters: query,
  });
};

export { fetchPaintSystems };
