import { Nullable } from '@boss/types/b2b-b2c';

import productMock from '../../__mocks__/product';
import { product } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: true, authScope: process.env.BOSS_API_AUTH_SCOPE_PRODUCT };
const PRODUCT_PAGE_LIMIT = 100;
const MOCK_PRODUCT_API = process.env.MOCK_PRODUCT_API === 'true';

/**
 * Fetches a list of assortiments
 */
export const getAllAssortiments = async (locale: string): Promise<product.IAssortment[] | undefined> => {
  const result = await bossApi<product.Assortiment[]>(`/product/v2/producttrees`, { ...DEFAULT_OPTIONS, locale });

  return result?.map(assortiment => ({ ...assortiment, parentcategoryid: null, slug: {} }));
};

/**
 * Fetches the product tree
 */
export const getProductTree = async (locale: string) =>
  await bossApi<product.Assortiment[]>(`/product/v2/producttrees`, { ...DEFAULT_OPTIONS, locale });

/**
 * Fetch a product
 */
export const getProduct = (productId: string, locale: string) => {
  if (MOCK_PRODUCT_API) {
    return Promise.resolve(productMock as unknown as product.Product);
  }

  return bossApi<product.Product>(`/product/v2/products/${productId}`, { ...DEFAULT_OPTIONS, locale });
};

/**
 * Fetch products by product ids
 */
export const getProductsByProductIds = ({ productIds, locale }: { productIds: string[]; locale: string }) => {
  const queryPath = productIds.map(productId => `productid=${productId}`).join('&');

  return bossApi<product.Product[]>(`/product/v2/products/byproductid?${queryPath}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

/**
 * Get all products
 *
 * WARNING: This function is a generator and will return an iterator.
 * The reason for this behaviour is limiting the memory footprint.
 * Before the next batch is processed, the previous one is made eligible for
 * garbage collecion.
 *
 * This is to be consumed with a for await loop
 *
 * Example:
 *
 * import bossClient from '@boss/boss-client';
 *
 * for await (const product of bossClient.product.getAllProducts(locale)) {
 *     console.log(product.name);
 * }
 */
export async function* getAllProducts(locale: string): AsyncGenerator<product.Product> {
  let offset = 1;
  let result: Nullable<product.Product[]>;

  while (
    (result = await bossApi(
      `/product/v2/products?${new URLSearchParams({
        skip: offset.toString(),
        limit: PRODUCT_PAGE_LIMIT.toString(),
      }).toString()}`,
      { ...DEFAULT_OPTIONS, locale },
    ))
  ) {
    for (const product of result) {
      yield product;
    }
    offset += PRODUCT_PAGE_LIMIT;
  }
}
