import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouter } from '@boss/hooks';
import { IWishlist } from '@boss/services/client';
import { SimpleColor } from '@boss/types/b2b-b2c';
import { Button } from '@boss/ui';
import { getAccountPageSlugs } from '@boss/utils';

import { useColorByColorId } from '../../../../client-queries';
import { ColorGridWrapper } from '../../../../components';
import { accountPageConfig, mapColorSlugField } from '../../../../utils';

const { listPageTypeToShow } = accountPageConfig;

interface Props {
  list: IWishlist;
}

const ColorList = ({ list }: Props) => {
  const { t } = useTranslation('account', { keyPrefix: 'list' });
  const { locale, push } = useRouter();
  const [selectedColor, setSelectedColor] = useState<SimpleColor>();
  const { data: selectedCfColor } = useColorByColorId(selectedColor?.id);

  useEffect(() => {
    if (!selectedCfColor) {
      return;
    }

    const slug = mapColorSlugField(locale, selectedCfColor).slug;

    if (slug) {
      push(slug);
    }
  }, [selectedCfColor, locale, push]);

  return (
    <div className="mb-10 flex flex-col gap-5">
      <h2>{t('overview.actions.favoriteColors')}</h2>
      <ColorGridWrapper
        colors={list.lines.map(line => ({
          code: line.typeid ?? '',
          name: line.color?.colorname ?? '',
          rgb: line.color?.rgb ?? '',
          id: line.typeid ?? '',
        }))}
        gridClassName="md:grid-cols-5"
        onChangeColorClick={color => setSelectedColor(color)}
        translations={t('grid', { ns: 'color', returnObjects: true })}
        variant="secondary"
      />
      <Button
        className="ml-auto mt-5"
        href={`${getAccountPageSlugs(listPageTypeToShow)[locale]}`}
        label={t('detailPage.backToOverview')}
        type="primary"
      />
    </div>
  );
};

export default ColorList;
