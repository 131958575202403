import * as Sentry from '@sentry/nextjs';

import { color } from '../../types';

export const getColorGroups = async (): Promise<color.ColorGroup[] | null> => {
  try {
    const response = await fetch(`/api/colorgroups`);

    return await response.json();
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Error fetching colorgroups',
      },
    });
    return null;
  }
};
