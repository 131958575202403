import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import * as Yup from 'yup';

import { getAlgolia } from '@boss/algolia-client';
import { ISearchProduct } from '@boss/types/b2b-b2c';
import { Alert, Button, InputField } from '@boss/ui';
import { getAccountPageSlugs } from '@boss/utils';

import { useCreateWishlist } from '../../../../client-queries';
import { InstantSearch, PagedProducts } from '../../../../components';
import { accountPageConfig, getProductSearchIndexName, isB2b } from '../../../../utils';
import ProductSearch from '../search/products';

type Props = {
  onListCreated: () => void;
};

const CreateProductList = ({ onListCreated: handleListCreated }: Props) => {
  const { listPageTypeToShow, wishlistType } = accountPageConfig;
  const [selectedResults, setSelectedResults] = useState<ISearchProduct[]>([]);
  const { locale } = useRouter();
  const { mutate: createWishlist, isError: createWishListError } = useCreateWishlist({});
  const { t } = useTranslation('account', { keyPrefix: 'list.createNewList' });

  const createList = () => {
    createWishlist(
      {
        description: formik.values.listName,
        type: wishlistType,
        lines: [
          ...selectedResults.map(product => ({
            typeid: product.id,
          })),
        ],
      },
      {
        onSuccess: () => {
          handleListCreated();
        },
      },
    );
  };

  const formik = useFormik({
    initialValues: {
      listName: '',
    },
    validationSchema: Yup.object({
      listName: Yup.string().required(t('nameRequired') || ''),
    }),
    onSubmit: createList,
  });

  const addProductToList = (product: ISearchProduct) => {
    setSelectedResults([...selectedResults, product]);
  };

  const removeResult = (product: ISearchProduct) => {
    setSelectedResults(selectedResults.filter(selectedProduct => selectedProduct.id !== product.id));
  };

  return (
    <div className="mb-10 flex flex-col gap-5">
      <h1>{t('title')}</h1>
      {createWishListError && (
        <Alert className="mt-2" type="error">
          {t('createError')}
        </Alert>
      )}
      {isB2b && (
        <div className="md:w-1/2">
          <InputField
            error={formik.errors.listName}
            label={t('nameField.label') || ''}
            name="listName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder={t('nameField.placeholder') || ''}
            testId="input-search-product"
            value={formik.values.listName}
            variant="light"
          />
        </div>
      )}

      <PagedProducts onRemove={removeResult} products={selectedResults} />

      <InstantSearch
        disableRouting
        indexName={getProductSearchIndexName(locale as string)}
        searchClient={getAlgolia({}).algoliaClient}
      >
        <ProductSearch addProductToList={addProductToList} />
      </InstantSearch>

      <div className="flex justify-end">
        <Button
          className="mr-4 px-10 py-4"
          href={getAccountPageSlugs(listPageTypeToShow)[locale as string]}
          label={t('cancel')}
          testId="cancel"
          type="secondary"
        />
        <Button
          className="px-10 py-4"
          label={t('saveList')}
          onClick={() => formik.handleSubmit()}
          testId="save-list"
          type="primary"
        />
      </div>
    </div>
  );
};

export default CreateProductList;
