import { faArrowDownToBracket, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { CustomAsset, StepByStepPlanFields } from '@boss/types/b2b-b2c';
import { Link, TextLink } from '@boss/ui';

import { Characteristics, ComponentMapper, StepAnchorLinks, StepByStep, StepByStepProductList } from '../../components';
import { youtubeLink } from '../../utils';

type Props = {
  blogPost: StepByStepPlanFields;
};

const titleColor = 'text-brown-dark';
const TitleStyle = cva('text-h2-mobile lg:text-h2 mb-5 lg:mb-12', {
  variants: {
    theme: {
      colora: titleColor,
      'boss-paints': titleColor,
      corporate: titleColor,
      jobs: titleColor,
    },
  },
});

const DownloadLink = ({ file, className }: { file: CustomAsset; className?: string }) => {
  const { t } = useTranslation();

  if (!file?.file?.url) {
    return null;
  }

  return (
    <Link
      anchorClassName={twMerge('w-fit', className)}
      className="text-textColor flex items-center gap-3 underline"
      href={`https:${file.file.url}`}
    >
      <FontAwesomeIcon icon={faArrowDownToBracket} />
      <span>
        {t('roadmapLabel')}
        {` `}
        <b>{file.title}</b>
      </span>
    </Link>
  );
};

const RoadmapDetailPage = ({ blogPost }: Props) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { difficulty, budget, duration } = blogPost;

  return (
    <div className="flex flex-col">
      {blogPost?.prefixSections?.map(entry => (
        <ComponentMapper entry={entry} key={`roadmap-prefix-section-${entry.id}`} locale={locale} />
      ))}
      <section
        className={twMerge(
          'bg-blue-light/30 break-before break-after before:bg-blue-light/30 after:bg-blue-light/30 relative w-full pb-9 pt-8 lg:pb-20 lg:pt-16',
        )}
      >
        <div className="grid grid-cols-1 lg:grid-cols-12 lg:gap-x-20">
          <div className="col-span-1 lg:col-span-7">
            <div className="relative flex flex-col gap-8">
              <div className="flex flex-col">
                <h2 className={TitleStyle({ theme: 'colora' })}>{blogPost.stepsTitle}</h2>
                <StepAnchorLinks steps={blogPost.steps} />
                {blogPost.faqSection && (
                  <TextLink
                    className="font-body-mobile lg:font-body font-medium lg:font-normal"
                    href="#steps-faq"
                    underline
                  >
                    {t('roadmap.faqAnchorLink')}
                  </TextLink>
                )}
              </div>
              <div className="max-w-176 flex flex-col items-start gap-y-6 md:my-9 md:flex-row md:justify-between">
                {difficulty && <Characteristics difficulty={difficulty} />}
                {duration && <Characteristics duration={duration} />}
                {budget && <Characteristics budget={budget} />}
              </div>
              {blogPost.disclaimerText && <ComponentMapper entry={blogPost.disclaimerText} locale={locale} />}
              <DownloadLink className="lg:hidden" file={blogPost.file} />
              <StepByStep locale={locale} steps={blogPost.steps} />
            </div>
          </div>
          <div className="col-span-1 flex flex-col gap-20 lg:col-span-5">
            <DownloadLink className="hidden lg:flex" file={blogPost.file} />
            <div className="flex flex-col gap-8">
              <Link
                anchorClassName="hidden items-center justify-end gap-5 lg:flex"
                className="flex items-center justify-end gap-5"
                href={youtubeLink}
              >
                {t('youtubeLink', { ns: 'routes' })}
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
              <StepByStepProductList steps={blogPost.steps} />
            </div>
          </div>
        </div>

        {blogPost.faqSection && (
          <>
            <div id="steps-faq" />
            <ComponentMapper
              additionalProps={{ className: 'pb-0', titleClassName: 'text-generic-blue' }}
              entry={blogPost.faqSection}
              key={`roadmap-faq-section-${blogPost.faqSection.id}`}
              locale={locale}
            />
          </>
        )}
      </section>
      {blogPost?.suffixSections?.map(entry => (
        <ComponentMapper entry={entry} key={`roadmap-sufix-section-${entry.id}`} locale={locale} />
      ))}
    </div>
  );
};

export default RoadmapDetailPage;
