import { UrlObject } from 'url';

import { Nullable } from '@boss/types/b2b-b2c';
import { getHrefString, isAnchorLink, isExternalUrl } from '@boss/utils';
import NextLink from 'next/link';
import { AriaRole, MouseEventHandler, ReactNode, cloneElement } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  children?: ReactNode;
  className?: string;
  anchorClassName?: string;
  href?: Nullable<string | UrlObject>;
  onClick?: MouseEventHandler<HTMLElement>;
  onMouseEnter?: MouseEventHandler<HTMLElement>;
  role?: AriaRole;
  locale?: string;
  'aria-label'?: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  testId?: string;
  newTab?: boolean;
  download?: boolean;
  title?: string | null;
  trackInfo?: Record<string, string | number>;
};

const Link = ({
  href,
  onClick,
  onMouseEnter,
  className,
  anchorClassName,
  children,
  role = 'link',
  locale,
  'aria-label': ariaLabel,
  onKeyDown,
  testId,
  newTab,
  download,
  title,
  trackInfo,
}: Props) => {
  const trackAttributes = trackInfo
    ? Object.keys(trackInfo).reduce((acc, key) => {
        acc[`data-track-${key}`] = trackInfo[key];
        return acc;
      }, {} as Record<string, string | number>)
    : {};

  const body = (
    <span
      aria-label={ariaLabel}
      className={twMerge((!!href || onClick) && 'cursor-pointer', className)}
      data-track-info={trackInfo}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      title={title ?? undefined}
      {...trackAttributes}
    >
      {children}
    </span>
  );

  const hrefString = href && getHrefString(href);

  if (!hrefString) {
    return cloneElement(body, { ...body.props, className: twMerge(body.props.className, anchorClassName), role });
  }

  const isAnchor = isAnchorLink(hrefString);

  const baseProps = {
    role,
    onMouseEnter,
    onClick,
    onKeyDown,
    'aria-label': ariaLabel,
    className: anchorClassName,
    ...trackAttributes,
  };

  if (isAnchor) {
    return (
      <a {...baseProps} href={hrefString ?? undefined}>
        {body}
      </a>
    );
  }

  const isExternal = download ?? newTab ?? (hrefString && isExternalUrl(hrefString));
  const hrefToUse = hrefString && !isExternal && !hrefString.startsWith('/') ? `/${hrefString}` : hrefString;

  return (
    <NextLink
      {...baseProps}
      aria-label={ariaLabel || `link-to-${hrefToUse}`}
      data-testid={testId}
      download={download ? true : undefined}
      href={download ? `https:${hrefToUse}` : hrefToUse}
      locale={download ? false : locale}
      passHref
      rel={isExternal ? 'noopener noreferrer' : ''}
      target={isExternal ? '_blank' : '_self'}
    >
      {body}
    </NextLink>
  );
};

export default Link;
