import { Nullable, SimpleImage } from '@boss/types/corporate-jobs';
import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import TimelineEventBlock from './TimelineEventBlock';
import TimeScale from './TimeScale';

export type TimelineEvent = {
  image: Nullable<SimpleImage>;
  content: ReactNode;
  year: number;
};

type TimelineProps = {
  timelineEvents: TimelineEvent[];
  isDev?: boolean;
};

const Timeline = ({ timelineEvents, isDev }: TimelineProps) => {
  const sortedEvents = timelineEvents.sort(function (event1, event2) {
    return event1.year - event2.year;
  });
  const years = sortedEvents.map(event => event.year);
  const [activeYear, setActiveYear] = useState<number | undefined>(undefined);
  const activeYearRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  useEffect(() => {
    if (activeYearRef) {
      activeYearRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [activeYear]);

  const stickyClassName = isDev ? 'top-[3rem]' : '';
  const scrollTopClassName = isDev ? 'scroll-mt-[9.5rem]' : 'scroll-mt-16';

  return (
    <div className="relative">
      <div
        className={twMerge(
          'sticky top-0 z-10 mb-6 hidden items-center justify-center bg-white pb-8 pt-4 md:flex',
          stickyClassName,
        )}
      >
        <TimeScale activeYear={activeYear} setActiveYear={setActiveYear} years={years} />
      </div>

      {sortedEvents.map((timelineEvent, index) => {
        return (
          <div
            className={scrollTopClassName}
            key={`${timelineEvent.year}-${index}`}
            ref={activeYear === timelineEvent.year ? activeYearRef : undefined}
          >
            <TimelineEventBlock reversed={index % 2 === 1} timelineEvent={timelineEvent} />
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
