import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { IArticle } from '@boss/types/b2b-b2c';
import { Counter, PackagingInfo, Presence, Price, Table } from '@boss/ui';

import type { Props as CommonProps } from '../';
import { usePrice } from '../../../hooks';
import { getPackagingOptions, unitConfig } from '../../../utils';
import Packaging from '../Packaging';
import ArticlesTableSkeleton from '../Skeleton';

export type ArticleValues = {
  quantity: number;
  unit: string;
  productImageSrc?: string;
  lineId?: string;
  price?: number;
  discount?: number;
};
export type ArticleIdsObject = Record<string, ArticleValues>;

const BASE_HEADER_KEYS = ['packaging', 'amount', 'price'] as const;
const NON_PAINT_HEADER_KEYS = [...BASE_HEADER_KEYS, 'variant'] as const;
const PAINT_HEADER_KEYS = [...BASE_HEADER_KEYS, 'content'] as const;
const ALL_HEADER_KEYS = [...new Set([...NON_PAINT_HEADER_KEYS, ...PAINT_HEADER_KEYS])] as const;

type NonPaintHeaderKey = (typeof NON_PAINT_HEADER_KEYS)[number];
type PaintHeaderKey = (typeof PAINT_HEADER_KEYS)[number];

type Props = CommonProps & {
  updateSelectedArticlesIdsObject: (id: string, newObject: ArticleValues) => void;
};

const createTableDataItems = ({
  articles,
  selectedArticleIdsObject,
  updateSelectedArticlesIdsObject,
  showPrivatePrices,
}: {
  articles?: IArticle[];
  selectedArticleIdsObject: ArticleIdsObject;
  updateSelectedArticlesIdsObject: (id: string, newObject: ArticleValues) => void;
  showPrivatePrices: boolean;
}) => {
  if (!articles?.length || !getPackagingOptions) {
    return null;
  }

  return articles.map(article => {
    const { id: articleId, unit: defaultUnit, description } = article;
    const { unit = defaultUnit, quantity = 0 } = selectedArticleIdsObject[articleId] || {};
    const packagingOptions = getPackagingOptions(article);

    const price = article.pricing.price;
    const privatePrice = article.pricing?.privatePrice ?? 0;

    return {
      variant: description, // This line is specific to non-paint data
      content: <PackagingInfo packageValue={description} />, // This line is specific to paint data
      packaging: (
        <Packaging
          onChange={e => updateSelectedArticlesIdsObject(articleId, { quantity, unit: e.target.value })}
          packagingOptions={packagingOptions}
          unit={unit}
        />
      ),
      amount: (
        <Counter
          className="mx-auto"
          onChange={quantity => updateSelectedArticlesIdsObject(articleId, { quantity, unit })}
          value={quantity}
        />
      ),
      price: (
        <Price
          className="[&>*]:font-sans"
          privatePrice={showPrivatePrices ? privatePrice : undefined}
          size="small"
          value={price}
          variant="info"
        />
      ),
    };
  });
};

const PrimaryArticlesTable = ({
  articles,
  articleType,
  selectedArticleIdsObject,
  updateSelectedArticlesIdsObject,
  showPrivatePrices,
  type = 'default',
  isLoading,
}: Props) => {
  const { visible: showUnits } = unitConfig;
  const { showPrice } = usePrice();
  const isPaint = articleType === 'paint';
  const { t } = useTranslation(['common', 'product']);

  // TODO: Implement useAccountPriceInfo once the endpoint works for b2c
  // const { data: accountPriceInfo } = useAccountPriceInfo(articles?.map(article => article.id) ?? []);

  const getHeaderFilterKeys = () => {
    if (type == 'slim') {
      return ALL_HEADER_KEYS.filter(key => key !== 'content');
    }

    const headerFilterKeys: (NonPaintHeaderKey | PaintHeaderKey)[] = [];

    if (!showPrice) {
      headerFilterKeys.push('price');
    }

    if (!showUnits) {
      headerFilterKeys.push('packaging');
    }

    return headerFilterKeys;
  };

  const tableData = useMemo(() => {
    const headers =
      articleType === 'paint'
        ? ['content', 'packaging', 'amount', 'price']
        : ['variant', 'packaging', 'amount', 'price'];

    const items = createTableDataItems({
      articles,
      selectedArticleIdsObject,
      updateSelectedArticlesIdsObject,
      showPrivatePrices: !!showPrivatePrices,
    });

    return {
      headers: headers.map(key => ({ key: key as NonPaintHeaderKey | PaintHeaderKey, label: t(key) })),
      items,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles, selectedArticleIdsObject, showPrivatePrices, articleType, t]);
  const headerFilterKeys = getHeaderFilterKeys();
  const headers = tableData.headers.filter(header => !headerFilterKeys.includes(header.key));

  return (
    <Presence
      id="articles-table-presence"
      isLoading={isLoading}
      loader={<ArticlesTableSkeleton />}
      visible={!!articles?.length}
    >
      {!!tableData.items?.length && (
        <div className="flex flex-col gap-2">
          <Table
            headers={headers}
            items={tableData.items}
            tdClassName="px-1"
            thClassName={twMerge(
              'border-b-[1rem] border-transparent',
              isPaint ? 'first:text-left' : 'first:text-center',
            )}
            trClassName={twMerge('border-b-[1rem] border-transparent', type === 'slim' ? 'inline-block mr-4' : '')}
          />
        </div>
      )}
    </Presence>
  );
};

export default PrimaryArticlesTable;
