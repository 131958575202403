import { type VariantProps, cva, cx } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { type TextProps, TextStyles } from '../Text';

export interface ParagraphProps extends TextProps, VariantProps<typeof paragraphStyles> {}

const paragraphStyles = cva(['text-body-mobile sm:text-body my-5']);

const Paragraph = ({ size, className, children, ...props }: ParagraphProps) => {
  return (
    <p className={twMerge(cx(TextStyles({ size }), twMerge(paragraphStyles({ className }))))} {...props}>
      {children}
    </p>
  );
};

export default Paragraph;
