import algoliasearch from 'algoliasearch';

import { getSearchIndexName } from '../utils';

interface Credentials {
  APP_ID: string;
  SECRET_KEY: string;
  PUBLIC_KEY: string;
}

const sharedCredentials = {
  APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_SPACE_ID_SHARED as string,
  SECRET_KEY: process.env.ALGOLIA_WRITE_KEY_SHARED as string,
  PUBLIC_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY_SHARED as string,
};

const channel_credentials = {
  APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_SPACE_ID as string,
  SECRET_KEY: process.env.ALGOLIA_WRITE_KEY as string,
  PUBLIC_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY as string,
};

const userClient = (credentials: Credentials) => algoliasearch(credentials.APP_ID, credentials.PUBLIC_KEY);
const serverClient = (credentials: Credentials) => algoliasearch(credentials.APP_ID, credentials.SECRET_KEY);
const getClient = (credentials: Credentials) =>
  typeof window !== 'undefined' ? userClient(credentials) : serverClient(credentials);
const getIndex = (credentials: Credentials, indexName: string, locale?: string) =>
  getClient(credentials).initIndex(getSearchIndexName(indexName, locale));

// Hard defining of both clients to avoid client changing between renders (Algolia warning)
const sharedAlgoliaClient = getClient(sharedCredentials);
const channelAlgoliaClient = getClient(channel_credentials);

const algoliaFactory = ({
  indexName,
  locale,
  isShared,
  noEmptySearch,
}: {
  indexName?: string;
  locale?: string;
  isShared?: boolean;
  noEmptySearch?: boolean;
}) => {
  const credentials = isShared ? sharedCredentials : channel_credentials;
  let algoliaClient = isShared ? sharedAlgoliaClient : channelAlgoliaClient;

  if (noEmptySearch) {
    const originalSearch = algoliaClient.search;

    /*
     *  This is a workaround to avoid empty searches to Algolia.
     *  Reference: https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react/
     */

    algoliaClient = {
      ...algoliaClient,
      search(requests) {
        if (requests.every(({ params }) => !params?.query)) {
          return Promise.resolve({
            results: requests.map(() => ({
              hits: [],
              nbHits: 0,
              nbPages: 0,
              page: 0,
              processingTimeMS: 0,
              hitsPerPage: 0,
              exhaustiveNbHits: false,
              query: '',
              params: 'waitForSearchQuery',
            })),
          });
        }

        return originalSearch(requests);
      },
    };
  }

  return {
    algoliaClient,
    searchIndex: indexName ? getIndex(credentials, indexName, locale) : null,
    getSearchIndexName,
  };
};

export default algoliaFactory;
