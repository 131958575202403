const { resolve } = require('path');

const locales =
  process.env.NEXT_PUBLIC_CHANNEL_NAME === 'colora' ? ['default', 'nl', 'fr', 'nl-NL'] : ['default', 'nl', 'fr'];

const domains =
  process.env.NEXT_PUBLIC_CHANNEL_NAME === 'colora'
    ? [
        {
          domain: process.env.HOST_COLORA_NL ?? 'localhost',
          defaultLocale: 'nl-NL',
          locales: ['nl-NL'],
        },
      ]
    : null;

module.exports = {
  i18n: {
    locales,
    defaultLocale: 'default',
    // since the locale detection is causing redirect loops, we disable it for now
    localeDetection: false,
    domains,
  },
  localePath: resolve('./public/locales'),
};
