import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'next-i18next';
import { ComponentProps, useEffect, useState } from 'react';

import { usePagination } from '@boss/hooks';
import { IAccounPriceInfo, IArticle, ISearchArticle, SimpleColor } from '@boss/types/b2b-b2c';
import { Alert, ArticleCard, Button, Pagination, Presence } from '@boss/ui';

import Loader from './LoadingAnimation';
import { ArticleCardWrapper, ColorPickerModal } from '../';
import { COLOR_TYPE } from '../../constants';
import { useColors, usePrice } from '../../hooks';
import { articleCardVariant, getPackagingOptions } from '../../utils';

export type ExtentedArticle = (ISearchArticle | IArticle) & {
  selectedQuantity?: number;
  selectedPackaging?: string;
  selectedColor?: SimpleColor;
  salesQuantity?: number;
  salesUnit?: string;
  slug?: string;
  imageSrc?: string | null;
  price?: {
    netInclVat: number;
    netExclVat: number;
    salesPrice: number;
  };
  discount?: number;
  lineId: string;
};

interface Props {
  articles: ExtentedArticle[];
  onConfirmSelection?: (articles: ExtentedArticle[]) => void;
  onRemove?: (lineId: string) => void;
  getConfirmLabel?: (numberOfSelectedResults: number) => string;
  isLoading?: boolean;
  editableArticles?: boolean;
  onUpdateQuantity?: (lineId: string, quantity: number) => void;
  onUpdatePackaging?: (lineId: string, packaging: string) => void;
  onUpdateColor?: (lineId: string, color: SimpleColor) => void;
  itemsPerPage?: number;
  hidePrice?: boolean;
  isUpdating?: boolean;
  discountConditions?: Record<string, string[]>;
  onUpdateResults?: (articles: ExtentedArticle[]) => void;
  accountPriceInfo?: IAccounPriceInfo[];
}

const PagedArticles = ({
  articles,
  onConfirmSelection: confirmSelection,
  onRemove: handleRemove,
  getConfirmLabel,
  isLoading,
  editableArticles,
  onUpdateQuantity: handleUpdateQuantity,
  onUpdatePackaging: handleUpdatePackaging,
  onUpdateColor: handleUpdateColor,
  itemsPerPage = 5,
  hidePrice,
  isUpdating,
  discountConditions,
  onUpdateResults,
  accountPriceInfo,
}: Props) => {
  const { showPrice: _showPrice } = usePrice();
  const showPrice = _showPrice && !hidePrice;
  const { showColors } = useColors({});
  const [currentlyUpdating, setCurrentlyUpdating] = useState('');
  const { t } = useTranslation('common');
  const itemsSelectable = !!confirmSelection;
  const { currentPage, paginate, currentItems, totalPages } = usePagination<ExtentedArticle>(itemsPerPage, articles);
  const [selectedResults, _setSelectedResults] = useState<ExtentedArticle[]>([]);
  const [showColorPickerForArticle, setShowColorPickerForArticle] = useState<ExtentedArticle | null>(null);
  const [showColorSelectionWarning, setShowColorSelectionWarning] = useState(false);

  const updateAmount = <T,>(id: string, quantity: T, updater: (s: string, x: T) => void) => {
    setCurrentlyUpdating(id);
    updater(id, quantity);
  };

  const setSelectedResults = (results: ExtentedArticle[]) => {
    _setSelectedResults(results);
    onUpdateResults?.(results);
  };

  const toggleResult = (item: ExtentedArticle) => {
    if (isSelected(item)) {
      setSelectedResults(selectedResults.filter(selectedItem => selectedItem.lineId !== item.lineId));
    } else {
      setSelectedResults([...selectedResults, item]);
    }
  };

  const updateColor = (item: ExtentedArticle, color: SimpleColor) => {
    if (!isSelected(item)) {
      setSelectedResults([...selectedResults, { ...item, selectedColor: color }]);
    } else {
      setSelectedResults(
        selectedResults.map(selectedItem => {
          if (selectedItem.lineId === item.lineId) {
            return { ...selectedItem, selectedColor: color };
          }

          return selectedItem;
        }),
      );
    }

    if (handleUpdateColor) {
      handleUpdateColor(item.lineId, color);
    }
  };

  const onRemove = (id: string) => {
    setSelectedResults(selectedResults.filter(selectedItem => selectedItem.lineId !== id));
    handleRemove?.(id);
  };

  const isSelected = (item: ExtentedArticle) => {
    return selectedResults.some(selectedItem => selectedItem.lineId === item.lineId);
  };

  const validateSelection = () => {
    const isValid = selectedResults.every(article => {
      const isMyMxArticle = article.colortypegroups.includes(COLOR_TYPE.MYMX);

      return !isMyMxArticle || !!article.selectedColor?.id;
    });

    if (!isValid) {
      setShowColorSelectionWarning(true);
    }

    return isValid;
  };

  useEffect(() => {
    if (showColorSelectionWarning) {
      setShowColorSelectionWarning(!validateSelection());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResults, showColorSelectionWarning]);

  useEffect(() => {
    // If the current page is empty, go back one page
    if (!currentItems?.length && articles.length && currentPage > 0) {
      paginate(currentPage - 1);
    }
  }, [currentItems?.length, articles.length, currentPage, paginate]);

  const articleCardTranslations: ComponentProps<typeof ArticleCard>['translations'] = t('articleCard', {
    returnObjects: true,
  });

  const getPrice = (article: ExtentedArticle, quantity: number) => {
    const unitsForSelectedPackaging =
      article.packaging?.units?.find(unit => unit?.unit === article.selectedPackaging)?.quantity ?? 1;

    const accountPrice = accountPriceInfo?.find(price => price?.id === article.id);

    if (article.price) {
      return article.price;
    }

    const unitPrice = accountPriceInfo ? accountPrice?.netunitprice : article.pricing?.price ?? 0;

    const price = unitPrice ?? 0 * quantity;
    const totalPrice = quantity * unitsForSelectedPackaging * price;

    return {
      netInclVat: totalPrice,
      netExclVat: totalPrice,
      salesPrice: totalPrice,
    };
  };

  const invalidArticles = selectedResults.filter(
    article => article.colortypegroups.includes(COLOR_TYPE.MYMX) && !article.selectedColor?.id,
  );

  return (
    <Presence
      className="flex flex-col gap-5"
      id="paged-articles-presence"
      isLoading={isLoading}
      loader={<Loader />}
      visible
    >
      {showColorSelectionWarning && (
        <Alert type="warning">
          <div className="mb-2">
            {t('list.createNewList.selectColorWarning', {
              count: invalidArticles.length,
              ns: 'account',
            })}
          </div>
          <ul>
            {invalidArticles.map(article => (
              <li key={article.lineId}>{article.name}</li>
            ))}
          </ul>
        </Alert>
      )}
      {currentItems?.map(article => {
        const quantity = article.selectedQuantity || 1;

        return (
          <ArticleCardWrapper
            article={article}
            color={
              article.selectedColor?.code
                ? {
                    name: article.selectedColor.name
                      ? `${article.selectedColor.code} - ${article.selectedColor.name}`
                      : article.selectedColor.code,
                    rgb: article.selectedColor.rgb,
                  }
                : undefined
            }
            deleted={article.itemdeleted}
            disabled={isUpdating && currentlyUpdating !== article.lineId}
            discount={article.discount}
            discountConditions={discountConditions?.[article.id]}
            imageSrc={article.imageSrc}
            isPaint={article?.isPaint}
            isUpdating={isUpdating}
            key={article.lineId}
            onColorSelect={showColors ? () => setShowColorPickerForArticle(article) : undefined}
            onRemove={handleRemove && (() => onRemove(article.lineId))}
            onSelect={itemsSelectable ? () => toggleResult(article) : undefined}
            onUpdatePackaging={
              handleUpdatePackaging && (packaging => updateAmount(article.lineId, packaging, handleUpdatePackaging))
            }
            onUpdateQuantity={
              handleUpdateQuantity && (quantity => updateAmount(article.lineId, quantity, handleUpdateQuantity))
            }
            packaging={article.selectedPackaging}
            packagingOptions={getPackagingOptions(article)}
            price={showPrice ? getPrice(article, quantity) : undefined}
            quantity={quantity}
            selected={isSelected(article)}
            showColorPicker={article?.colortypegroups?.includes(COLOR_TYPE.MYMX)}
            slug={article.slug}
            translations={articleCardTranslations}
            type={editableArticles ? 'extended' : 'default'}
            variant={articleCardVariant}
          />
        );
      })}

      {showColorPickerForArticle && (
        <ColorPickerModal
          articleId={showColorPickerForArticle.id}
          onClose={() => setShowColorPickerForArticle(null)}
          selectedColor={showColorPickerForArticle.selectedColor}
          setSelectedColor={color => {
            updateColor(showColorPickerForArticle, color);
          }}
        />
      )}
      <div className="flex flex-col items-center justify-between md:flex-row">
        {totalPages > 1 && <Pagination currentPage={currentPage} onPaginate={paginate} totalPages={totalPages} />}
        {!!selectedResults.length && (
          <Button
            className="my-5 ml-auto py-2"
            icon={faPlus}
            iconPosition="left"
            label={
              getConfirmLabel
                ? getConfirmLabel(selectedResults.length)
                : t('articleCard.addResults', { count: selectedResults.length })
            }
            onClick={
              itemsSelectable
                ? () => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    if (validateSelection()) {
                      setSelectedResults([]);
                      confirmSelection(selectedResults);
                      setShowColorSelectionWarning(false);
                    }
                  }
                : undefined
            }
            testId="add-results"
            type="primary"
          />
        )}
      </div>
    </Presence>
  );
};

export default PagedArticles;
