import env from '../env';

type Props = { redirect?: string };

/**
 * Format and go to login url with correct parameters
 */
export const register = ({ redirect }: Props) => {
  const current = new URL(redirect ?? window.location.href);

  window.location.href = `${env.PATH_PREFIX}/register?source=${current.toString()}`;
};

export default register;
