type OrderMock = {
  id: string;
  title: string;
  description: string;
  orderTitle: string;
  typeOfOrder: 'store' | 'online';
  deliveryStatus: 'onTheMove' | 'ready';
  products: { id: string; name: string; image: { url: string } }[];
};

const ordersMock: OrderMock[] = [
  {
    id: '1',
    title: 'Reeds afgehaald',
    description: 'Colora Gent Sint-Amandsberg',
    orderTitle: 'Bisol voorraad',
    deliveryStatus: 'ready',
    typeOfOrder: 'store',
    products: [
      {
        id: '1',
        name: 'Bolatex 2,5l',
        image: {
          url: 'https://resources-test.boss.be/dam?type=packshot&productgroepnr=000462&maxsize=low',
        },
      },
      {
        id: '2',
        name: 'Prim 5l',
        image: {
          url: 'https://resources-test.boss.be/dam?type=packshot&productgroepnr=001847&maxsize=low',
        },
      },
      {
        id: '3',
        name: 'Bolatex 2,5l',
        image: {
          url: 'https://resources-test.boss.be/dam?type=packshot&productgroepnr=001208&maxsize=low',
        },
      },
      {
        id: '4',
        name: 'Bolatex 3l',
        image: {
          //eslint-disable-next-line
          url: 'https://resources-test.boss.be/dam?type=packshot&productgroepnr=001859&maxsize=low',
        },
      },
      {
        id: '4',
        name: 'Bolatex 3l',
        image: {
          url: 'https://resources-test.boss.be/dam?type=packshot&productgroepnr=001859&maxsize=low',
        },
      },
    ],
  },
  {
    id: '2',
    title: 'Wordt vandaag bezorgd',
    description: 'Tussen 18u en 22u',
    typeOfOrder: 'online',
    orderTitle: 'Showroom BMW',
    deliveryStatus: 'onTheMove',
    products: [
      {
        id: '1',
        name: 'Formule MC Ontvetter 1l',
        image: {
          url: 'https://resources-test.boss.be/dam?type=packshot&productgroepnr=001208&maxsize=low',
        },
      },
    ],
  },
  {
    id: '3',
    title: 'Klaar voor afhaling',
    description: 'Boss Paints Antwerpen',
    typeOfOrder: 'online',
    orderTitle: 'Familie Jansens',
    deliveryStatus: 'onTheMove',
    products: [
      {
        id: '1',
        name: 'Bolatex 5l ',
        image: {
          url: 'https://resources-test.boss.be/dam?type=packshot&productgroepnr=000462&maxsize=low',
        },
      },
    ],
  },
];

export default ordersMock;
