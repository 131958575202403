import { ICustomer } from '@boss/types/b2b-b2c';
import { Faker, en } from '@faker-js/faker';

export const faker = new Faker({
  locale: [en],
});

const generateCustomers = (count: number) => {
  const orders: ICustomer[] = [];

  for (let i = 0; i < count; i++) {
    orders.push({
      id: faker.number.int({ min: 100000, max: 9000000 }),
      number: faker.number.int({ min: 100000, max: 9000000 }),
      client: faker.company.name(),
      address: faker.location.direction(),
      telephone: faker.phone.number(),
      contactName: faker.person.fullName(),
    });
  }
  return orders;
};

export { generateCustomers };
