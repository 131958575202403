/**
 * Format price regarding the locale that is send with it
 * @date 12-9-2023 - 08:34:54
 *
 * @param {number} price
 * @param {string} l
 * @returns {*}
 */
const formatPrice = (price: number, l: string) => {
  return new Intl.NumberFormat(l, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price);
};

/**
 * Calculate pricing by quantity.
 * TODO: Fix the multiplying of the floating numbers since this will bring up side effects in rounding them
 * @date 12-9-2023 - 08:35:39
 *
 * @param {number} price
 * @param {boolean} isPaint
 * @returns {number}
 */
const calculatePriceByQuantity = (price: number, quantity: number | undefined | null = 1) => {
  if (!quantity) {
    return price;
  }
  return quantity * price;
};

export { formatPrice, calculatePriceByQuantity };
