import { faCalendarAlt, faSliders } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { TAdviceType } from '@boss/services/client';
import { Accordion, Button } from '@boss/ui';

import FromToDatePicker from '../Date/FromToDatePicker';

type Filters = {
  fromDate?: string;
  toDate?: string;
  order: 'asc' | 'desc';
  types: TAdviceType[];
};

interface Props {
  order: 'asc' | 'desc';
  onSetFilters: (filters: Filters) => void;
}

const DateFilterOptions = ({ date, label }: { date: string; label: string }) => {
  return (
    <div>
      <FontAwesomeIcon className="mr-2" icon={faCalendarAlt} />
      <span>{label}: </span>
      <span>{date}</span>
    </div>
  );
};

const AdviceFilters = ({ order, onSetFilters: handleSetFilters }: Props) => {
  const { t } = useTranslation('account');
  const defaultFilters = {
    fromDate: undefined,
    toDate: undefined,
    order,
    types: ['CA' as const, 'TA' as const],
  };

  const [selectedFilters, setSelectedFilters] = useState<Filters>(defaultFilters);

  const applySelectedFilters = (filters: Filters) => {
    setSelectedFilters(filters);
    handleSetFilters(filters);
  };

  return (
    <>
      <Accordion
        buttonClassName="py-4 text-blue-dark font-bold justify-end gap-4 font-normal"
        closedIcon={faSliders}
        title={t('advices.filters.title')}
        variant="simple"
      >
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-2">
            <div className="font-bold">{t('advices.filters.sortTitle')}</div>
            <div className="flex gap-2 md:flex-row">
              {['desc' as const, 'asc' as const].map(order => {
                return (
                  <Button
                    disabled={selectedFilters.order === order}
                    key={order}
                    label={t(`advices.filters.sort.${order}`)}
                    onClick={() => {
                      applySelectedFilters({ ...selectedFilters, order });
                    }}
                    type="secondary"
                  />
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-bold">{t('advices.filters.adviceType')}</div>
            {['CA' as const, 'TA' as const].map(type => {
              return (
                <label className="flex items-center gap-2" key={type}>
                  <input
                    checked={selectedFilters.types.includes(type)}
                    onChange={() => {
                      const types = selectedFilters.types.includes(type)
                        ? selectedFilters.types.filter(t => t !== type)
                        : [...selectedFilters.types, type];

                      applySelectedFilters({ ...selectedFilters, types });
                    }}
                    type="checkbox"
                  />
                  {t(`advices.filters.type.${type}`)}
                </label>
              );
            })}
          </div>
          <div className="flex flex-col gap-2">
            <div className="font-bold">{t('advices.filters.date')}</div>
            <FromToDatePicker
              className="flex bg-gray-50 p-4"
              filters={{
                fromDate: selectedFilters.fromDate ? new Date(selectedFilters.fromDate) : undefined,
                toDate: selectedFilters.toDate ? new Date(selectedFilters.toDate) : undefined,
              }}
              setFilters={filters =>
                applySelectedFilters({
                  ...selectedFilters,
                  fromDate: filters.fromDate ? filters.fromDate.toDateString() : '',
                  toDate: filters.toDate ? filters.toDate.toDateString() : '',
                })
              }
            />
          </div>
        </div>
      </Accordion>
      {(selectedFilters.fromDate ?? selectedFilters.toDate) && (
        <section className="flex flex-col gap-4 rounded border p-4 font-bold shadow-sm md:flex-row">
          <div>{t('advices.filters.selectedPeriod')}:</div>
          {selectedFilters.fromDate && (
            <DateFilterOptions date={selectedFilters.fromDate} label={t('advices.filters.from')} />
          )}
          {selectedFilters.toDate && (
            <DateFilterOptions date={selectedFilters.toDate} label={t('advices.filters.to')} />
          )}
          <div className="flex gap-5 md:ml-auto">
            <button
              className="flex items-center gap-2 text-[#132D88]"
              onClick={() => {
                applySelectedFilters(defaultFilters);
              }}
            >
              {t('advices.filters.clear')}
            </button>
          </div>
        </section>
      )}
    </>
  );
};

export default AdviceFilters;
