import { twMerge } from 'tailwind-merge';

export type FooterWrapperProps = {
  children: React.ReactNode;
  className?: string;
};

const FooterWrapper = ({ children, className }: FooterWrapperProps) => {
  return <footer className={twMerge('bottom flex flex-col', className)}>{children}</footer>;
};

export default FooterWrapper;
