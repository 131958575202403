import { useIsResponsive } from '@boss/hooks';
import { faCheck, faChevronDown, faChevronRight, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ColorPickerInstanceProps } from '..';
import { Orb, PaintDrop, SearchBar } from '../../';

const PrimaryColorPicker = ({
  colors,
  onSearch,
  className,
  translations,
  selectedColor,
  onChangeGroupClick,
  onColorClick,
  pageSize = 10,
  searchValue,
  setSearchValue,
  colorType,
}: ColorPickerInstanceProps) => {
  const isMobile = useIsResponsive();
  const amountOfPages = Math.ceil(colors.length / pageSize);
  const [activePage, setActivePage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const colorsToShow = colors.slice(activePage * pageSize, (activePage + 1) * pageSize);

  if (isMobile) {
    return (
      <div className={twMerge('flex flex-col gap-3', className)}>
        <button className="flex w-full items-center justify-end" onClick={() => setIsOpen(isOpen => !isOpen)}>
          {selectedColor && (
            <div className="flex items-center gap-2">
              <span className="h3">
                {translations.chosenColor}: {selectedColor.code}
              </span>
              <span>{selectedColor.name}</span>
            </div>
          )}
          <FontAwesomeIcon className={`${isOpen ? 'rotate-180' : ''} ml-auto`} icon={faChevronDown} />
        </button>

        <div className="flex items-center gap-3">
          <div className="relative">
            <PaintDrop colorCode={selectedColor?.rgb} colorType={colorType} />
            <FontAwesomeIcon
              className="text-gray-dark absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
              icon={faCheck}
            />
          </div>

          <button
            className="small text-gray-dark mt-2 flex items-center gap-1 whitespace-nowrap underline md:absolute md:left-1/2 md:top-full md:-translate-x-1/2"
            onClick={() => onChangeGroupClick()}
          >
            {translations.changeColor}
            <FontAwesomeIcon icon={faPencil} />
          </button>
        </div>

        {isOpen && (
          <div className="flex flex-col gap-5">
            <button
              className="flex items-center gap-2 underline md:no-underline"
              onClick={() => onChangeGroupClick()}
              type="button"
            >
              <span className="h4 font-bold">{translations.chooseColorFromGroup}</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
            <div className="flex w-full items-center justify-between gap-3">
              <div className="grid w-full grid-cols-5 gap-5">
                {colorsToShow.map(color => (
                  <button key={`${color.code}-orb`} onClick={() => onColorClick(color)}>
                    <Orb style={{ backgroundColor: color.rgb }} />
                  </button>
                ))}
              </div>
              {colorsToShow?.length > pageSize && (
                <button onClick={() => setActivePage(activePage => (activePage + 1) % amountOfPages)}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              )}
            </div>
            <SearchBar
              className="border-b-1 p-0 text-xs"
              onSearch={onSearch}
              searchLabel={translations.searchByColorName}
              setSearchValue={setSearchValue}
              value={searchValue}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={twMerge('flex flex-col gap-3 md:mb-4', className)}>
      {selectedColor && (
        <div className="flex items-center gap-2">
          <span className="font-medium">{translations.chosenColor}:</span>
          <span className="small">
            {selectedColor?.name ? `${selectedColor.code} - ${selectedColor.name}` : selectedColor?.code}
          </span>
        </div>
      )}
      <button
        className="flex items-center gap-2 text-left underline md:no-underline"
        onClick={() => onChangeGroupClick()}
        type="button"
      >
        <span className="font-medium">{translations.chooseColorFromGroup}</span>
        <FontAwesomeIcon className="small" icon={faChevronRight} />
      </button>
      <div className="flex items-center gap-4">
        <div className="relative">
          <PaintDrop colorCode={selectedColor?.rgb} colorType={colorType} />
          <FontAwesomeIcon
            className="text-gray-dark absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            icon={faCheck}
          />
          <button
            className="small text-gray-dark mt-2 flex items-center gap-1 whitespace-nowrap underline md:absolute md:left-1/2 md:top-full md:-translate-x-1/2"
            onClick={() => onChangeGroupClick()}
          >
            {translations.changeColor}
            <FontAwesomeIcon icon={faPencil} />
          </button>
        </div>

        <div className="flex w-full flex-col flex-wrap gap-3">
          <div className="flex h-8 flex-wrap gap-3 overflow-hidden">
            {colorsToShow.map((color, index) => (
              <button key={`${color.code}-orb-${index}`} onClick={() => onChangeGroupClick(color)}>
                <Orb style={{ backgroundColor: color.rgb }} />
              </button>
            ))}
          </div>
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2 xl:flex-nowrap">
            <SearchBar
              className="border-b-1 bg-inherit p-0 text-xs"
              onSearch={onSearch}
              searchLabel={translations.searchByColorName}
              setSearchValue={setSearchValue}
              value={searchValue}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrimaryColorPicker;
