import { Environment } from '@boss/types/b2b-b2c';

import { environments } from '../../constants';

export const isValidEnvironment = (env: string): env is Environment => !!environments.find(env => env === env);

export const getEnvironmentName = () => {
  const env = (process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT ?? process.env.CONTENTFUL_ENVIRONMENT) as string;

  if (isValidEnvironment(env)) {
    return env;
  }

  return environments[0];
};
