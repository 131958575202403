import { useMemo } from 'react';

import { IAdvice } from '@boss/services/client';

type FilterType = 'CA' | 'TA';

const processAdvices = (
  advices: IAdvice[],
  sortDirection: 'asc' | 'desc',
  filterTypes: FilterType[],
  fromDate?: string,
  toDate?: string,
): IAdvice[] => {
  let filteredAdvices = advices;

  // Filter by type
  if (filterTypes) {
    filteredAdvices = filteredAdvices.filter(advice => filterTypes.includes(advice.advicetype));
  }

  // Filter by date range
  if (fromDate && toDate) {
    filteredAdvices = filteredAdvices.filter(advice => {
      const adviceDate = new Date(advice.date);

      return adviceDate >= new Date(fromDate) && adviceDate <= new Date(toDate);
    });
  }

  // Sort by date
  filteredAdvices.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    return sortDirection === 'asc' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
  });

  return filteredAdvices;
};

interface UseAdviceOptions {
  advices: IAdvice[];
  sortDirection: 'asc' | 'desc';
  filterTypes: FilterType[];
  fromDate?: string;
  toDate?: string;
}

export const useAdvices = ({ advices, sortDirection, filterTypes, fromDate, toDate }: UseAdviceOptions) => {
  return useMemo(() => {
    return processAdvices(advices, sortDirection, filterTypes, fromDate, toDate);
  }, [advices, sortDirection, filterTypes, fromDate, toDate]);
};
