import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  onClick: () => void;
  className?: string;
  label?: ReactNode;
  ariaLabel?: string;
};

const FilterOrb = ({ onClick, className, label, ariaLabel }: Props) => (
  <button
    aria-label={ariaLabel}
    className={twMerge(
      'caption border-1 border-filterOrb-border text-filterOrb-text flex w-fit items-center gap-2 rounded-full bg-white px-3 py-1 font-medium',
      className,
    )}
    onClick={onClick}
  >
    {label}
    <FontAwesomeIcon className="text-gray-dark" icon={faClose} size="xs" />
  </button>
);

export default FilterOrb;
