import * as Sentry from '@sentry/nextjs';

import { withSession } from '@boss/auth';
import { PAYMENT_TYPE_UNION, order as orderService } from '@boss/services/client';

export interface OrderParams {
  basketId: string;
  paymentType: PAYMENT_TYPE_UNION;
  payReference: string | undefined;
  timeStamp: string;
}

/**
 * Confirms the invoice order
 *
 * @async
 * @returns {Promise}
 * @param basketId
 */
const confirmInvoiceOrder = withSession(async (params: OrderParams) => {
  try {
    return await orderService.createOrder(params);
  } catch (error) {
    console.error(error);

    Sentry.captureException(error, {
      tags: {
        type: 'Confirm invoice order',
      },
    });

    throw error;
  }
});

/**
 * Get order by id
 */
const getOrderById = withSession(async (orderId: string, locale: string) => {
  try {
    return await orderService.getOrderById(orderId, locale);
  } catch (error) {
    console.error(error);

    Sentry.captureException(error, {
      tags: {
        type: 'Get order by id',
      },
    });

    throw error;
  }
});

export { confirmInvoiceOrder, getOrderById };
