import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { QuoteInstanceProps } from '..';
import Image from '../../Image';

const WrapperStyle = cva('md-h-112.5 h-124 relative flex max-w-xs  flex-col gap-3 md:gap-10', {
  variants: {
    roundedCorners: {
      true: 'rounded-tr-[3.125rem] rounded-br-[3.125rem] rounded-bl-[3.125rem] overflow-hidden',
      false: '',
    },
  },
});

const AuthorStyle = cva('font-dinDisplay absolute bottom-4  flex flex-row-reverse gap-6 text-white md:flex-row', {
  variants: {
    roundedCorners: {
      true: 'right-8',
      false: 'right-4',
    },
  },
});

const QuoteCarousel = ({ author, company, className, image, quote, roundedCorners }: QuoteInstanceProps) => {
  return (
    <div aria-label="quote" className={twMerge(WrapperStyle({ roundedCorners }), className)}>
      {image ? (
        <div className="h-full w-full">
          <Image className="shadow-m flex h-full object-cover" {...image} />
        </div>
      ) : (
        <div className="shadow-m flex h-full w-full bg-black object-cover" />
      )}
      <div className="absolute top-0">
        <h6
          className="font-dinDisplay large p-7 font-normal text-white before:content-[attr(title)] after:content-[attr(title)]"
          title={`"`}
        >
          {quote}
        </h6>
      </div>
      {author && (
        <div className={AuthorStyle({ roundedCorners })}>
          <div>
            <div className="author text-right font-bold">{author}</div>
            {company && <div className="function whitespace-nowrap text-right font-extralight ">{company}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuoteCarousel;
