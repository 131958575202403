import { useState } from 'react';
import { useHits } from 'react-instantsearch';

import { useRouter } from '@boss/hooks';
import { IContentItem } from '@boss/services';
import { ISearchObject, OverviewFields } from '@boss/types/b2b-b2c';
import { PageCard, Section } from '@boss/ui';
import { getPagePathByPageType } from '@boss/utils';

import { ComponentMapper, SearchGrid } from '../../components';

type Props = {
  cmsData?: OverviewFields | null;
  indexName: string;
  overviewPageSlug: string;
};

const OverviewPage = ({ cmsData, indexName, overviewPageSlug }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const { results } = useHits<IContentItem & ISearchObject>();
  const pages = results?.hits;
  const { locale } = useRouter();

  return (
    <div>
      {cmsData?.prefixSections?.map(entry => (
        <ComponentMapper entry={entry} key={entry.id} locale={locale} />
      ))}

      <Section
        className="pt-10"
        content={
          <SearchGrid
            attributes={[]}
            hideTotalResults
            indexName={indexName}
            internalSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            sortOptions={[]}
          >
            {pages?.map(page => {
              const image = {
                src: page.mainImage?.src,
                alt: page.title,
              };

              const slug = getPagePathByPageType(page.slug, locale, 'contentPage', page?.tags);

              return (
                <PageCard imageAlt={image?.alt} imageSrc={image?.src} key={page.id} slug={slug} title={page.title} />
              );
            })}
          </SearchGrid>
        }
      />

      {cmsData?.suffixSections?.map(entry => (
        <ComponentMapper entry={entry} key={entry.id} locale={locale} />
      ))}
    </div>
  );
};

export default OverviewPage;
