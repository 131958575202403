import { SCREENS } from '@boss/constants/b2b-b2c';
import { BasicVariant } from '@boss/types/b2b-b2c';
import { ReactNode } from 'react';
import { SwiperProps } from 'swiper/react';
import { twMerge } from 'tailwind-merge';

import Carousel from '../Carousel';

type Props = {
  id?: string;
  className?: string;
  options?: SwiperProps;
  variant?: BasicVariant;
  title?: string | null;
  children: ReactNode[];
};

const defaultOptions: SwiperProps = {
  navigation: true,
  slidesPerView: 1.2,
  breakpoints: {
    [SCREENS.md]: {
      slidesPerView: 2.4,
    },
    [SCREENS.lg]: {
      slidesPerView: 4,
    },
  },
};

const CarouselProducts = ({ id, children, className, options = defaultOptions, title }: Props) => {
  if (!children?.length) {
    return null;
  }

  return (
    <div className={twMerge('flex flex-col gap-10', className)}>
      {title && <h3>{title}</h3>}

      <Carousel id={id ?? 'carousel-products'} options={options}>
        {children}
      </Carousel>
    </div>
  );
};

export default CarouselProducts;
