import { incidentType } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false };

export const getIncidentTypes = (workerOrderType: string) => {
  return bossApi<incidentType.IncidentType[]>(`/service/v2/incidenttypes?workerordertype=${workerOrderType}`, {
    ...DEFAULT_OPTIONS,
  });
};
