import { useIsResponsive, useRouter } from '@boss/hooks';
import { BasicVariant, I18nFilter } from '@boss/types/b2b-b2c';
import { faChevronLeft, faClose, faSliders } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useEffect, useState } from 'react';

import Filter from './Filter';
import SelectedButton from './SelectedButton';
import Button from '../Button';
import { QueryObject } from '../InspirationGrid';
import Portal from '../Portal';

type Props = {
  filters: FilterType[];
  onSelect: (query: string) => void;
  translations: I18nFilter;
  className?: string;
  preSelected?: QueryObject;
  variant?: BasicVariant;
};

interface FilterValue {
  value: string;
  label: string;
}

export type FilterState = Record<string, string[]>;

export interface FilterType {
  filterName: string;
  filterLabel: string;
  filterValues: FilterValue[];
}

const ClearButtonStyles = cva('hover:bg-beige-light bg-brown-dark hover:text-brown-dark mx-2 my-2 text-white', {
  variants: {
    variant: {
      primary: '',
      secondary: 'rounded-none',
    },
  },
});

const SelectedButtonStyles = cva('mx-2 my-2', {
  variants: {
    variant: {
      primary: '',
      secondary: 'rounded-none',
    },
  },
});

const getFiltersStruct = (filters: FilterType[]) =>
  filters.reduce((filterState: FilterState, { filterName }) => {
    filterState[filterName] = [];
    return filterState;
  }, {});

const createQueryString = (filters: FilterState) => {
  const queryArr = [];

  for (const [filterGroup, selectedFilters] of Object.entries(filters)) {
    for (const filter of selectedFilters) {
      queryArr.push(`${filterGroup.toLowerCase()}:"${filter}"`);
    }
  }

  return queryArr.join(' AND ');
};

const Filters = ({ filters, variant = 'primary', onSelect, translations, className, preSelected }: Props) => {
  const { push, asPath } = useRouter();
  const basePath = asPath.split('?')?.[0]; // Needs to be done like this as the "pathname" prop can be the path with underscores
  const isMobile = useIsResponsive();

  const [showClearButton, setShowClearButton] = useState(false);
  const [activeFilters, setActiveFilters] = useState<FilterState>({});
  const [currentlyActive, setCurrentlyActive] = useState(0);
  const [openPortal, setOpenPortal] = useState(false);

  const { filtersMobile, filtersTitle, showResults, clearButtonText, filterLabels } = translations;

  const getFilterLabel = (name: string) => {
    const label = filterLabels[name];

    if (typeof label === 'string') {
      return label;
    }
    return name;
  };

  const handleSelect = (filterName: string, value: string) => {
    const activeFiltersValues = activeFilters[filterName];
    const index = activeFiltersValues.indexOf(value);
    const activeCount = index === -1 ? currentlyActive + 1 : currentlyActive - 1;

    if (index > -1) {
      activeFiltersValues.splice(index, 1);
    } else {
      activeFiltersValues.push(value);
    }

    setCurrentlyActive(activeCount);
    setActiveFilters(filters => ({
      ...filters,
      [filterName]: activeFiltersValues,
    }));

    setShowClearButton(!!activeCount);
    onSelect(createQueryString(activeFilters));
  };

  const clearFilters = () => {
    setActiveFilters(getFiltersStruct(filters));
    setShowClearButton(false);
    setCurrentlyActive(0);
    onSelect('');
    push(basePath, basePath, { shallow: true });
  };

  useEffect(() => {
    setActiveFilters(activeFilters => ({ ...getFiltersStruct(filters), ...activeFilters }));
  }, [filters]);

  useEffect(() => {
    if (!preSelected?.filt || !preSelected.val) {
      return;
    }

    setCurrentlyActive(1);
    setActiveFilters(filters => {
      return {
        ...filters,
        [preSelected.filt]: [preSelected.val],
      };
    });

    setShowClearButton(true);
  }, [preSelected]);

  return !isMobile && filters.length ? (
    <div className={className}>
      <h4 className="mb-3 font-semibold">{filtersTitle}</h4>
      <div className="flex flex-wrap">
        {filters.map(filter => {
          const { filterName, filterValues, filterLabel } = filter;

          return (
            <Filter
              activeFilters={activeFilters[filterName]}
              className="mb-2 mr-4"
              filterLabel={filterLabel}
              filterName={filterName}
              filterValues={filterValues}
              key={filterName}
              onChange={(value: string, filterName: string) => handleSelect(filterName, value)}
            />
          );
        })}
      </div>

      <div
        className={`${
          showClearButton ? 'h-auto opacity-100' : 'h-0 opacity-0'
        } delay-50 mt-0 flex flex-wrap overflow-hidden transition-all ease-in-out`}
      >
        <Button
          className={ClearButtonStyles({ variant })}
          label={clearButtonText}
          onClick={clearFilters}
          type="primary"
        />
        {Object.entries(activeFilters).flatMap(([filterName, filterValues]) =>
          filterValues.map(filterValue => (
            <SelectedButton
              className={SelectedButtonStyles({ variant })}
              filterName={filterName}
              filterValue={filterValue}
              key={filterValue}
              label={getFilterLabel(filterValue)}
              onClick={handleSelect}
            />
          )),
        )}
      </div>
    </div>
  ) : (
    <>
      <button className="mb-6 ml-auto flex items-center gap-2 text-[#132D88]" onClick={() => setOpenPortal(true)}>
        <FontAwesomeIcon icon={faSliders} />
        {filtersMobile}
      </button>

      <div className="my-5 flex flex-wrap">
        {Object.entries(activeFilters).flatMap(([filterName, filterValues]) =>
          filterValues.map(filterValue => (
            <SelectedButton
              className="mx-1 my-1 text-sm [&>*]:text-sm"
              filterName={filterName}
              filterValue={filterValue}
              key={filterValue}
              label={getFilterLabel(filterValue)}
              onClick={handleSelect}
            />
          )),
        )}
      </div>
      {openPortal && (
        <Portal>
          <div className="z-drawer fixed left-0 top-0 flex h-screen w-screen flex-col overflow-y-scroll bg-white p-4">
            <button className="mb-1 ml-auto block" onClick={() => setOpenPortal(false)}>
              <FontAwesomeIcon icon={faClose} />
            </button>

            <div className="border-b-solid border-b-1 mt-3 flex justify-between border-b-[#F5F5F5] pb-4 text-sm">
              <button onClick={() => setOpenPortal(false)}>
                <FontAwesomeIcon className="mr-2" icon={faChevronLeft} />
                {`${filtersMobile} (${currentlyActive})`}
              </button>
              {showClearButton && (
                <button onClick={() => clearFilters()} type="button">
                  {clearButtonText}
                </button>
              )}
            </div>

            {filters.map(filter => (
              <Filter
                activeFilters={activeFilters[filter.filterName]}
                className="md:mr-4"
                isMobile={isMobile}
                key={filter.filterName}
                onChange={(value: string, filterName: string) => handleSelect(filterName, value)}
                {...filter}
              />
            ))}

            {showClearButton && (
              <div className="my-5 flex flex-wrap">
                {Object.entries(activeFilters).flatMap(([filterName, filterValues]) =>
                  filterValues.map(filterValue => (
                    <SelectedButton
                      className="mx-1 my-1 text-sm [&>*]:text-sm"
                      filterName={filterName}
                      filterValue={filterValue}
                      key={filterValue}
                      label={getFilterLabel(filterValue)}
                      onClick={handleSelect}
                    />
                  )),
                )}
              </div>
            )}

            <button
              className="mt-4 w-full rounded-s bg-[#c6c6c6] py-3 text-center font-semibold text-black"
              onClick={() => setOpenPortal(false)}
            >
              {showResults}
            </button>
          </div>
        </Portal>
      )}
    </>
  );
};

export default Filters;
