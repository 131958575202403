import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { SimpleColor } from '@boss/types/b2b-b2c';
import { Alert, Button, InputField, Modal, RadioGroup, SearchDropdown } from '@boss/ui';
import { getAccountPageSlugs } from '@boss/utils';

import { useWishlists } from '../../client-queries';
import { useWishlistOperations } from '../../hooks';
import { ArticleIdsObject } from '../ArticlesTable';

type Props = {
  onClose: () => void;
  selectedArticleIdsObject: ArticleIdsObject;
  selectedColor?: SimpleColor;
};

const WishlistModal = ({ onClose: handleClose, selectedArticleIdsObject, selectedColor }: Props) => {
  const { locale, push } = useRouter();
  const { t } = useTranslation(['product', 'common']);
  const { data: wishlists, isLoading: wishlistsLoading } = useWishlists();
  const [selectedWishlist, setSelectedWishlist] = useState<string>('');
  const [newWishlistName, setNewWishlistName] = useState<string>('');
  const [selectedWishlistType, setSelectedWishlistType] = useState<string>();
  const { createWishlist, createWishlistLines } = useWishlistOperations();
  const wishlistSlug = getAccountPageSlugs('LIST')?.[locale as string] ?? '';
  const [showWarning, setShowWarning] = useState(false);

  const skuListOptions = wishlists?.sku?.map(sku => ({
    label: sku.description ?? '',
    value: sku.id ?? '',
  }));

  const confirm = async () => {
    if (
      (selectedWishlistType === 'existing' && !selectedWishlist) ||
      (selectedWishlistType === 'new' && !newWishlistName)
    ) {
      setShowWarning(true);
      return;
    }

    const wishlistLines = Object.entries(selectedArticleIdsObject).map(([articleId, selectedArticleObject]) => ({
      typeid: articleId,
      quantity: selectedArticleObject.quantity,
      color: selectedColor
        ? {
            colorcode: selectedColor?.code,
            colorname: selectedColor?.name,
            rgb: selectedColor?.rgb,
          }
        : undefined,
    }));

    if (selectedWishlistType === 'new') {
      createWishlist(
        {
          description: newWishlistName,
          type: 'sku',
          lines: wishlistLines,
        },
        {
          onSuccess: data => {
            handleClose();
            push(`/${wishlistSlug}/view/${data.id}`);
          },
        },
      );
    } else {
      const wishlistToUpdate = wishlists?.sku?.find(wishlist => wishlist.id === selectedWishlist);

      if (wishlistToUpdate?.lines) {
        createWishlistLines(
          { wishlistLines, wishlistId: selectedWishlist },
          {
            onSuccess: () => {
              handleClose();
              push(`/${wishlistSlug}/view/${selectedWishlist}`);
            },
          },
        );
      }
    }
  };

  useEffect(() => {
    if (selectedWishlist || (selectedWishlistType === 'new' && newWishlistName)) {
      setShowWarning(false);
    }
  }, [selectedWishlist, selectedWishlistType, newWishlistName]);

  return (
    <Modal ariaLabel="wishlist-modal" className="w-auto" onClose={handleClose}>
      <div className="flex flex-col gap-5 pb-20 pt-10">
        <h3 className="md:col-span-full">{t('productPanel.wishlistModal.title')}</h3>
        <RadioGroup
          name="wishlistType"
          onChange={setSelectedWishlistType}
          options={[
            {
              disabled: !skuListOptions?.length,
              label: (
                <div className="flex flex-col">
                  <span className="pb-2">{t('productPanel.wishlistModal.addToList')}</span>
                  {!wishlistsLoading && skuListOptions?.length ? (
                    <SearchDropdown
                      label={t('productPanel.wishlistModal.dropdownLabel')}
                      onChange={setSelectedWishlist}
                      options={skuListOptions}
                      searchLabel={t('productPanel.wishlistModal.dropdownSearchLabel') ?? ''}
                      value={selectedWishlist}
                    />
                  ) : (
                    <span className="text-gray">
                      {wishlistsLoading ? t('common:loading') : t('productPanel.wishlistModal.noWishlists')}
                    </span>
                  )}
                </div>
              ),

              value: 'existing',
            },
            {
              label: (
                <InputField
                  className="my-5"
                  label={t('productPanel.wishlistModal.newWishlistLabel')}
                  name="wishlistName"
                  onChange={e => setNewWishlistName(e.target.value)}
                  onFocus={() => setSelectedWishlistType('new')}
                  placeholder={t('productPanel.wishlistModal.wishlistName') ?? ''}
                  value={newWishlistName}
                />
              ),
              value: 'new',
            },
          ]}
          value={selectedWishlistType}
        />
        {showWarning && <Alert type="info">{t('productPanel.wishlistModal.selectOptionWarning')}</Alert>}
        <Button label={t('productPanel.wishlistModal.confirm')} onClick={confirm} type="primary" />
      </div>
    </Modal>
  );
};

export default WishlistModal;
