import { Nullable } from '@boss/types/b2b-b2c';

import { isDefined } from './general';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const getEntries = <T extends object>(obj: T) => Object.entries(obj) as Entries<T>;

// TODO: Mark for removal
// NOTE: Could be replaced with Array.isArray(thing) && thing.length
// This should be an inline test
export const isArrayWithContent = <T extends Array<unknown>>(thing: T): thing is T =>
  isDefined(thing) && Array.isArray(thing) && thing.length !== 0;

// TODO: Mark for removal
// NOTE: This can be replaced with [...arr].splice(index, 0, newItem)
// Splice but avoids mutation
export const insertIntoArray = <T>(arr: Array<T> = [], index: number, newItem: T) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

// TODO: Mark for removal
// NOTE: This is not true. The equivalent of this test is "!!value"
// For testing if a value is not null, including undefined, "value !== null" is sufficient
// undefined or null => !!value
// null => value !== null
// undefined => value !== undefined
export const notNull = <T>(value: Nullable<T>): value is T => value !== null && value !== undefined;

export const excludeUndefinedFromArray = <T>(array: (T | undefined)[]) =>
  array.reduce((prev: T[], curr) => {
    if (curr) {
      return [...prev, curr];
    }

    return prev;
  }, []);

export const excludeDuplicatesFromObjectsArrayByKey = <T>(array: T[], key: keyof T) =>
  array.filter((v, i, a) => a.findIndex(v2 => v2[key] === v[key]) === i);

/**
 * Chunkify an array of T by a given maxSize of an array
 * The chunk lengths are balanced out to flatten the distribution
 *
 * @Example Arr: [1,2,3,4,5,6,7,8,9,10], maxSize: 4
 * -> [ [ 1, 2, 3, 4 ], [ 5, 6, 7 ], [ 8, 9, 10 ] ]
 */
export const chunkify = <T>(arr: T[], maxSize: number) => {
  const arrays: T[][] = [];
  const numChunks = Math.floor((arr.length - 1) / maxSize) + 1; // Total of final chunks
  const minChunkSize = Math.floor(arr.length / numChunks); // size of the smallest chunk
  const maxChunkSize = minChunkSize + 1; // size of the biggest chunk
  const numSmallChunks = numChunks * (minChunkSize + 1) - arr.length; // Total of unmaxed chunks
  const copy = [...arr]; //Arr copy to splice from

  for (let i = 0; i < numChunks; i++) {
    if (i < numChunks - numSmallChunks) {
      arrays.push(copy.splice(0, maxChunkSize));
    } else {
      arrays.push(copy.splice(0, minChunkSize));
    }
  }

  return arrays;
};
