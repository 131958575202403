import { useQueryClient } from '@tanstack/react-query';

/**
 * Custom hook for accessing the generic account data
 * @returns an object with generic account info
 */
export const useOptimistic = () => {
  const queryClient = useQueryClient();

  /**
   * Helper function to reduce the boiler plate code and complexity of using the optimistic ui approach.
   * Use this function as the return value of your onMutate function
   * @param {string[]} queryKey the query key to be used to identify the query
   * @param previousData the previous data of the query
   * @param optimisticValue the optimistic value to be set
   * @returns a context object with the snapshotted value
   *  **/
  const onMutateHelper = async <T, U>(queryKey: string[], previousData: T, optimisticValue: U) => {
    // Cancel any outgoing refetches
    // (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries({ queryKey });

    // Optimistically update to the new value
    queryClient.setQueryData(queryKey, optimisticValue);

    // Return a context object with the snapshotted value
    return { previousData };
  };

  return { onMutateHelper };
};
