import { Nullable } from '@boss/types/b2b-b2c';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type DeliveryStatus = 'success' | 'error' | 'default';

export type DeliveryOptionProps = {
  button?: Nullable<{
    icon: IconDefinition;
    label: string;
    onClick: () => void;
  }>;
  className?: string;
  disclaimer?: ReactNode;
  icon: IconDefinition;
  status?: Nullable<string>;
  statusColor?: DeliveryStatus;
  title: string;
};

const StatusStyling = cva('font-raleway block small md:body', {
  variants: {
    color: {
      success: 'text-green',
      error: 'text-red',
      default: 'text-red-light',
    },
  },
});

const DeliveryOption = ({
  button,
  className,
  disclaimer,
  icon,
  status,
  statusColor = 'default',
  title,
}: DeliveryOptionProps) => {
  return (
    <div className={twMerge('flex gap-3', className)}>
      <FontAwesomeIcon className="text-delivery-icon" icon={icon} />
      <div className="flex flex-col gap-1">
        <span className="text-delivery-title font-bold">{title}</span>

        {status && <span className={StatusStyling({ color: statusColor })}>{status}</span>}
        {button && (
          <button
            className="text-delivery-link small flex items-center gap-2 text-left underline"
            onClick={button.onClick}
          >
            {button.icon && <FontAwesomeIcon icon={button.icon} />}
            {button.label}
          </button>
        )}
        {disclaimer && <span className="caption text-delivery-disclaimer italic">{disclaimer}</span>}
      </div>
    </div>
  );
};

export default DeliveryOption;
