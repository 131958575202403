import { useEffect, useState } from 'react';

import { session } from '../../utils';
import * as load from '../load/load';

type Profile = {
  status: 'loading' | 'idle' | 'pristine';
  data: session.Session;
};

/**
 * A hook that communicates the current status
 */
export const useProfile = () => {
  const [profileState, setProfileState] = useState(session.getData());
  const [statusState, setStatusState] = useState(load.getStatus());

  const updateStatus = ({ status }: Profile) => setStatusState(status);
  const updateProfile = (profile: Profile['data']) => setProfileState(profile);

  useEffect(() => {
    load.on(load.EMITTER_UPDATE_EVENT, updateStatus);
    session.on(session.EMITTER_CHANGE_EVENT, updateProfile);

    // Load the session whenever trying to use it
    load.load();

    return () => {
      load.off(load.EMITTER_UPDATE_EVENT, updateStatus);
      session.off(session.EMITTER_CHANGE_EVENT, updateProfile);
    };
  }, []);

  return {
    accountId: profileState?.extension_AccountId?.toString() ?? '',
    data: profileState,
    isLoggedIn: session.isActive(),
    status: statusState,
  };
};

export default useProfile;
