import { Repeat, Skeleton } from '@boss/ui';

const PaintguideResultsSkeleton = () => (
  <div className="mb-10 mt-20 flex">
    <div className="w-full px-10">
      <Repeat amount={5}>
        <Skeleton className="mt-10 h-8 w-full" />
        <Skeleton className="mt-10 h-64 w-full" />
      </Repeat>
    </div>
  </div>
);

export default PaintguideResultsSkeleton;
