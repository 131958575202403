import Fuse from 'fuse.js';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { ISearchArticle, ISearchProduct } from '@boss/types/b2b-b2c';

import SearchWrapper, { ProductSearchContext } from './wrapper';
import { PagedArticles } from '../../../../components';
import { ExtentedArticle } from '../../../../components/PagedArticles';

type Props = {
  addArticlesToList: (products: (ISearchArticle | ExtentedArticle)[]) => void;
};

const transformItems = (items: ISearchProduct[], query: string): ISearchArticle[] => {
  const articles = items.reduce((prev: ISearchArticle[], item) => {
    const newArticles =
      item.articles.map(article => ({
        ...article,
        product: {
          name: item.name,
          id: item.id,
          slug: item.slug,
          type: item.type,
        },
      })) || [];

    return [...prev, ...newArticles];
  }, []);

  const fuseOptions = {
    keys: ['name', 'description', 'id', 'product.name'],
    useExtendedSearch: true,
  };

  const fuseIndex = Fuse.createIndex(fuseOptions.keys, articles);

  const fuse = new Fuse(articles, fuseOptions, fuseIndex);

  /*
   * by adding  ' in front of every splitted search-term
   * the extended search will be used
   */
  const adaptedSearchTerm = query
    .split(' ')
    .map(word => `'${word}`)
    .join(' ');

  const results = fuse.search(adaptedSearchTerm);

  // Sort results so articles with 'mymx' in colortypegroups come first
  return results
    .map(result => result.item)
    .sort((a, b) => {
      const aHasMymx = a.colortypegroups && a.colortypegroups.includes('mymx');
      const bHasMymx = b.colortypegroups && b.colortypegroups.includes('mymx');

      // Sort: if 'mymx' is present in a but not in b, a comes first
      if (aHasMymx && !bHasMymx) {
        return -1;
      }
      if (!aHasMymx && bHasMymx) {
        return 1;
      }
      return 0; // If both or neither have 'mymx', maintain current order
    });
};

const SearchArticles = ({ addArticlesToList: handleAddArticlesToList }: Props) => {
  const { t } = useTranslation('account', { keyPrefix: 'list.createNewList' });
  const getArticles = (products: ISearchProduct[], query: string) => {
    return transformItems(products, query);
  };

  const [selectedArticles, setSelectedArticles] = useState<ExtentedArticle[]>([]);

  const confirmSelection = (articles: (ISearchArticle | ExtentedArticle)[]) => {
    handleAddArticlesToList(articles);
  };

  return (
    <SearchWrapper>
      <ProductSearchContext.Consumer>
        {({ products, query, loading, hideResults }) => {
          const transformedArticles = getArticles(products, query).map((article, index) => {
            const lineId = `${article.id}-${index}`;
            const selectedArticle = selectedArticles.find(selectedArticle => selectedArticle.lineId === lineId);

            return {
              ...article,
              selectedColor: selectedArticle?.selectedColor,
              lineId,
            };
          });

          const handleConfirmSelection = () => {
            confirmSelection(selectedArticles);
            hideResults();
          };

          return (
            <>
              <PagedArticles
                articles={transformedArticles}
                editableArticles
                hidePrice
                isLoading={loading}
                onConfirmSelection={handleConfirmSelection}
                onUpdateResults={setSelectedArticles}
              />
              {!loading && query && transformedArticles.length === 0 && <div className="mt-5">{t('noResults')}</div>}
            </>
          );
        }}
      </ProductSearchContext.Consumer>
    </SearchWrapper>
  );
};

export default SearchArticles;
