import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHits } from 'react-instantsearch';

import { paintguidePageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { ExtendedResults, ISearchObject } from '@boss/types/b2b-b2c';
import { CtaBanner, Section } from '@boss/ui';

import { SearchGrid } from '../../components';
import { SortOption } from '../../components/Algolia/Sort';
import { useSearchAttributes } from '../../hooks';
import { categoryPageConfig } from '../../utils';

interface Props<T, K = T> {
  indexName: string;
  onUpdateTotalResults: (results: number | null) => void;
  renderCard: (item: K, index: number) => ReactElement;
  transformer?: (item: T) => K;
  sortOptions?: SortOption[];
}

/**
 * General search grid that
 * @type T is the type that will return from Algolia
 * @type K (optional) is used in combination with an transform function to transform type T to type K,K defaults to T
 */
const CommonSearchGrid = <T extends ISearchObject, K = T>({
  indexName,
  onUpdateTotalResults: handleResultsUpdate,
  renderCard,
  sortOptions = [],
  transformer,
}: Props<T, K>) => {
  const { t } = useTranslation();
  const { results } = useHits<T>();
  const { attributes } = useSearchAttributes();
  const { showPaintGuideLink } = categoryPageConfig;

  const { locale } = useRouter();
  // Casting to allow the function to pass T as K, when no transformer is given
  const transform = transformer ?? ((item: T) => item as unknown as K);

  useEffect(() => {
    handleResultsUpdate((results as ExtendedResults)?.params === 'waitForSearchQuery' ? null : results?.nbHits ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const ctaBanner = showPaintGuideLink && (
    <CtaBanner
      className="col-span-full"
      cta={{
        label: t('product.ctaBanner.ctaLabel'),
        icon: faArrowRight,
        href: paintguidePageSlugs[locale],
      }}
      description={t('product.ctaBanner.description')}
      prefix="😳"
      title={t('product.ctaBanner.title')}
    />
  );

  return (
    <Section
      content={
        <SearchGrid attributes={attributes} ctaBanner={ctaBanner} indexName={indexName} sortOptions={sortOptions}>
          {results?.hits?.map((item, index) => renderCard(transform(item), index))}
        </SearchGrid>
      }
    />
  );
};

export default CommonSearchGrid;
