import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from '../Link';

export interface Props {
  title: string;
  subTitle?: string;
  phoneText: string;
  mailText: string;
  phoneNumber: string;
  mailAddress: string;
  footNote: string;
}

const SalesInfoCard = ({ title, subTitle, phoneText, mailText, phoneNumber, mailAddress, footNote }: Props) => {
  return (
    <div className="bg-blue-light max-w-110 px-6 pb-11 pt-6 shadow-xl md:px-11">
      <h4>{title}</h4>
      {subTitle && <h4>{subTitle}</h4>}
      <Link className="mt-4 block" href={`tel:${phoneNumber}`}>
        <FontAwesomeIcon className="mr-3" icon={faPhone} size="lg" />
        <span>{phoneText} </span>
        <span>{phoneNumber}</span>
      </Link>
      <Link className="mt-4 block" href={`mailto:${mailAddress}`}>
        <FontAwesomeIcon className="mr-3" icon={faEnvelope} size="lg" />
        <span>{mailText} </span>
        <span>{mailAddress}</span>
      </Link>
      <p className="-ml-2 mt-5 text-xs">{footNote}</p>
    </div>
  );
};

export default SalesInfoCard;
