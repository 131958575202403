import { CustomAsset, Nullable } from '@boss/types/b2b-b2c';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VariantProps, cva } from 'class-variance-authority';
import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { PreloadedIcon, getPreloadedIcon } from '../../icons';
import Button from '../_Button';

interface Props extends VariantProps<typeof styles> {
  icon?: PreloadedIcon;
  children?: ReactNode;
  href?: string;
  label?: Nullable<string>;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void;
  className?: string;
  body?: ReactNode;
  fileToDownload?: CustomAsset;
}

const styles = cva(
  'text-xl text-center self-start items-center rounded-5 shadow-m inline-flex flex-col justify-center text-blue tracking-[0.15px]',
  {
    variants: {
      type: {
        horizontal: 'max-w-72 font-medium pt-7.5 pb-5.5 px-20.75 rounded-bl-none',
        vertical: 'max-w-45 font-medium px-8.25 py-8.25 text-base rounded-bl-none',
        inline: 'w-full px-4 py-6 text-blue-dark font-bold flex-row',
        enriched: 'w-full h-full flex justify-start bg-white',
        link: 'text-xs text-gray-800 shadow-[none]',
      },
    },
  },
);

const IconStyle = cva('text-[2rem]', {
  variants: {
    type: {
      horizontal: 'text-blue mb-6',
      vertical: 'text-blue mb-8.25',
      inline: 'text-md mr-4',
      enriched: '',
      link: '',
    },
  },
});

const Cta = ({ label, href, icon, onClick, type, className, body, fileToDownload }: Props) => {
  return (
    <Button
      className={twMerge(styles({ type }), className)}
      fileToDownload={fileToDownload?.file?.url}
      href={href}
      onClick={onClick}
    >
      {icon && <FontAwesomeIcon className={IconStyle({ type })} icon={getPreloadedIcon(icon)} />}
      {type === 'enriched' ? body : label}
    </Button>
  );
};

export default Cta;
