interface Props {
  type: 'exclude' | 'not-implemented' | 'required-content' | 'excluded-section' | 'wrong-channel';
  componentType: string;
  env: string;
}

const CardLabel = ({ type, componentType, env }: Props) => {
  //Unpublished component;
  if (!componentType) {
    return null;
  }

  if (['staging', 'acceptance', 'master'].includes(env)) {
    return null;
  }

  const getMessage = () => {
    switch (type) {
      case 'exclude':
        return 'not supported on this channel';
      case 'not-implemented':
        return 'not yet implemented';
      case 'required-content':
        return 'missing required content';
      case 'excluded-section':
        return 'not supported in this section type';
      case 'wrong-channel':
        return 'not supported on this channel';
    }
  };

  return (
    <div className="my-5 flex h-[5em] items-center justify-center">
      <div className={`${type === 'exclude' ? 'bg-amber-200' : 'bg-red-300'} p-5 text-center`}>
        <p>
          <strong>{componentType}</strong> component is {getMessage()}
        </p>
        <p className="text-sm">
          (This message will be omitted on <strong>staging</strong> and <strong>production</strong>)
        </p>
      </div>
    </div>
  );
};

export default CardLabel;
