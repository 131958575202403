import { useTranslation } from 'next-i18next';
import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as Yup from 'yup';
import 'yup-phone';

import { useAddressValidation } from '../../client-queries';
import { FormValues } from '../../components/DynamicForm';
import { FormField } from '../../components/Mappers/FormFieldMapper';
import { IS_RECAPTCHA_ENABLED } from '../../constants';
import { CountryValidationValues } from '../use-form-field/use-form-field';

/**
 * Gets the initial values for the form fields.
 *
 * @param {Array} fields - Array of form fields
 */
const getInitialValues = (fields: FormField[]) => {
  const initialValues: FormValues = {};

  fields.forEach(field => {
    initialValues[field.name] = field.initialValue ?? '';

    // If there are subfields, loop them and set the initial value for them.
    if ('fields' in field) {
      field.fields.forEach(f => {
        initialValues[f.name] = f.initialValue ?? '';
      });
    }
  });

  return initialValues;
};

/**
 * useForm hook which exports generic form functions
 */
export const useForm = () => {
  const { t } = useTranslation('forms');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { mutateAsync: validateAddress } = useAddressValidation();

  const verifyRecaptcha = useCallback(async () => {
    if (!IS_RECAPTCHA_ENABLED) {
      return true;
    }

    if (!executeRecaptcha) {
      return true;
    }

    const token = await executeRecaptcha();

    // This is a temporary validation which will get refactored/extended once the Forms api is available
    // TODO: Implement Forms api once available
    return !!token;
  }, [executeRecaptcha]);

  /**
   * Creates and returns input field validation based on the validationType field value
   */
  const getInputFieldValidation = (field: Extract<FormField, { type: 'input' }>) => {
    const validationType = field.validationType;
    const fieldKey = field.key;

    if (fieldKey === 'zipcode') {
      return Yup.string().when(['city', 'country'], {
        is: (city: string, country: string) => !!city && !!country,
        then: Yup.string().test(
          'validate-address',
          t('errors.invalidZipcode') ?? '',
          async (value: string | undefined, context) => {
            if (typeof value === 'undefined') {
              return true;
            }
            const parent = context.parent;
            const { city, country } = parent;

            const mappedCountry = CountryValidationValues[country as keyof typeof CountryValidationValues];

            const address = {
              zipcode: value ?? '',
              city: city ?? '',
              street: '',
              streetnumber: '',
              country: mappedCountry ?? '',
              postbox: '',
            };

            const validationData = await validateAddress(address);

            return validationData?.zipcodevalidated;
          },
        ),
      });
    }

    if (validationType === 'email') {
      return Yup.string().email(t('errors.email') ?? '');
    }

    if (validationType === 'number') {
      return Yup.number();
    }

    if (validationType === 'password') {
      return Yup.string().required(t('errors.password') ?? '');
    }

    return Yup.string();
  };

  /**
   * Generic function which maps the given field based on its field type and applies the correct validation
   */
  const getFieldValidation = (field: FormField, allFields: FormField[]) => {
    const { name: fieldName, validation: initialValidation, required = true } = field;
    const baseLabel = t(`fields.${fieldName}`, { ns: 'forms' });
    const baseErrorLabel =
      field.errorLabel ?? t('errors.required', { ns: 'forms', value: baseLabel ?? fieldName }) ?? '';

    let validation = undefined;

    if (initialValidation) {
      validation = initialValidation;
    } else if (field.type === 'input') {
      validation = getInputFieldValidation(field);
    } else if (field.type === 'phone' && field.required) {
      validation = Yup.string().phone(undefined, false, t('errors.phonenumber') ?? '');
    } else if (field.type === 'checkboxGroup') {
      validation = Yup.array();
    } else if (field.type === 'checkbox') {
      validation = required ? Yup.bool().isTrue(baseErrorLabel) : Yup.bool();
    } else if (field.type === 'counter') {
      validation = Yup.number().min(0);
    } else {
      validation = Yup.string();
    }

    if (required) {
      validation = validation.required(baseErrorLabel);
    }

    return validation;
  };

  /**
   * Generates the validation schema for the given form fields
   */
  const generateValidationSchema = (fields: FormField[]) => {
    const allFields: FormField[] = [];

    fields.forEach(field => {
      allFields.push(field);

      if ('fields' in field) {
        allFields.push(...field.fields);
      }
    });

    return allFields.reduce(
      (prev, curr) =>
        prev.shape({
          [curr.name]: getFieldValidation(curr, allFields),
        }),
      Yup.object(),
    );
  };

  return { generateValidationSchema, getInitialValues, verifyRecaptcha };
};
