// Tabs.tsx
import { cva } from 'class-variance-authority';
import React, { FC, KeyboardEvent, useEffect, useRef } from 'react';

interface Tab {
  label: string;
  value: string;
}

interface TabsProps {
  tabs: Tab[];
  activeTab: string;
  onTabChange: (value: string) => void;
}

const TabStyle = cva(
  'flex-1 px-4 py-2 text-xl text-center text-sm transition-colors duration-300 ease-in-out font-bold cursor-pointer',
  {
    variants: {
      active: {
        true: 'text-blue-dark border-blue-dark border-b-2 -mb-0.5',
        false: 'hover:text-blue-dark text-gray-600',
      },
    },
  },
);

const Tabs: FC<TabsProps> = ({ tabs, activeTab, onTabChange }) => {
  const activeTabRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (activeTabRef.current) {
      activeTabRef.current.focus();
    }
  }, [activeTab]);

  const handleKeyDown = (event: KeyboardEvent<HTMLUListElement>) => {
    const currentIndex = tabs.findIndex(tab => tab.value === activeTab);

    if (event.key === 'ArrowRight') {
      const nextTab = tabs[(currentIndex + 1) % tabs.length];

      onTabChange(nextTab.value);
    } else if (event.key === 'ArrowLeft') {
      const prevTab = tabs[(currentIndex - 1 + tabs.length) % tabs.length];

      onTabChange(prevTab.value);
    }
  };

  return (
    <div className="border-secondary border-b-2" role="tablist">
      <ul className="flex w-full divide-x" onKeyDown={handleKeyDown}>
        {tabs.map(tab => (
          <li
            aria-selected={activeTab === tab.value ? 'true' : 'false'}
            className={TabStyle({ active: activeTab === tab.value })}
            key={tab.value}
            onClick={() => onTabChange(tab.value)}
            ref={activeTab === tab.value ? activeTabRef : null}
            role="tab"
            tabIndex={activeTab === tab.value ? 0 : -1}
          >
            {tab.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
