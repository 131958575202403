import * as Sentry from '@sentry/nextjs';
import { Nullable } from 'vitest';

import { withSession } from '@boss/auth';
import { IWishlist, IWishlistLine, wishlist as wishListService } from '@boss/services/client';
/**
 * Client query to fetch all wishlists, needs a locale
 *
 * @async
 * @param {string} locale
 * @param {string} accountId The account id to fetch the wishlists for
 * @returns {Promise<IWhislist>}
 */
const fetchWishlists = withSession(
  async (accountId: string) => {
    try {
      return await wishListService.all(accountId);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          type: 'Fetch wishlists',
        },
      });

      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client mutation to create a new wishlist
 *
 * @async
 * @param {IWishlist} wishlistData The data for the new wishlist
 * @returns {Promise<IWishlist>} The created wishlist
 */
const createWishlist = withSession(
  async (wishlist: IWishlist) => {
    try {
      return await wishListService.create({
        wishlist,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          type: 'Create wishlist',
        },
      });

      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client mutation to delete a wishlist
 *
 * @async
 * @param {string} wishlistId The id of the wishlist to delete
 * @returns {Promise<void>}
 * */
const deleteWishlist = withSession(
  async (wishlistId: string) => {
    try {
      return await wishListService.deleteWishlist({
        wishlistId,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          type: 'Delete wishlist',
        },
      });

      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client mutation to update a wishlist
 *
 * @async
 * @param {IWishlist} wishlistData The data for the wishlist to update
 * @returns {Promise<IWishlist>} The updated wishlist
 * */
const updateWishlist = withSession(
  async (wishlist: IWishlist) => {
    try {
      return await wishListService.update({
        wishlist,
      });
    } catch (e) {
      console.error(e);
      Sentry.captureException(e, {
        tags: {
          type: 'Update wishlist',
        },
      });
    }
  },
  { alwaysRun: true },
);

/**
 * Client mutation to create a new wishlist line
 *
 * @async
 * @param {IWishlistLine} wishlistLine The data for the new wishlist line
 * @param {string} wishlistId The id of the wishlist to add the line to
 * @returns {Promise<IWishlistLine>} The created wishlist line
 *
 * */
const createWishlistLine = withSession(
  async (wishlistLine: IWishlistLine, wishlistId: string): Promise<Nullable<IWishlistLine>> => {
    try {
      return await wishListService.createLine({
        wishlistLine,
        wishlistId,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          type: 'Create wishlist line',
        },
      });

      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client mutation to delete a wishlist line
 * @async
 * @param {string} wishlistLineId The id of the wishlist line to delete
 * @param {string} wishlistId The id of the wishlist to delete the line from
 * @returns {Promise<void>}
 * */
const deleteWishlistLine = withSession(
  async (wishlistLineId: string, wishlistId: string) => {
    try {
      return await wishListService.deleteLine({
        wishlistLineId,
        wishlistId,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          type: 'Delete wishlist line',
        },
      });

      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client mutation to update a wishlist line
 * @async
 * @param {IWishlistLine} wishlistLine The data for the wishlist line to update
 * @param {string} wishlistId The id of the wishlist to update the line from
 * @returns {Promise<IWishlistLine>} The updated wishlist line
 * */
const createWishlistLines = withSession(
  async (wishlistLines: IWishlistLine[], wishlistId: string): Promise<Nullable<IWishlistLine>[]> => {
    try {
      return await wishListService.createLines({
        wishlistLines,
        wishlistId,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          type: 'Create wishlist lines',
        },
      });

      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client mutation to update a wishlist line
 * @async
 * @param {IWishlistLine} wishlistLine The data for the wishlist line to update
 * @param {string} wishlistId The id of the wishlist to update the line from
 * @returns {Promise<IWishlistLine>} The updated wishlist line
 * */
const updateWishlistLine = withSession(
  async (wishlistLine: IWishlistLine, wishlistId: string): Promise<Nullable<IWishlistLine>> => {
    try {
      return await wishListService.updateLine({
        wishlistLine,
        wishlistId,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          type: 'Update wishlist line',
        },
      });

      throw error;
    }
  },
  { alwaysRun: true },
);

export {
  fetchWishlists,
  createWishlist,
  deleteWishlist,
  updateWishlist,
  createWishlistLine,
  createWishlistLines,
  deleteWishlistLine,
  updateWishlistLine,
};
