import { faCheck, faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import Image from '../Image';
import Link from '../Link';

interface Props {
  imageSrc?: string;
  imageAlt?: string;
  title: string;
  optionId: string;
  onClick: (id: string) => void;
  tooltip?: boolean;
  active?: boolean;
  className?: string;
  onToolTipClick?: () => void;
  hideImage?: boolean;
}

const LinkStyles = cva('shadow-l overflow-hidden relative rounded-brand flex h-full flex-col rounded-bl-none', {
  variants: {
    active: { true: 'border-blue-dark border-[3px]' },
  },
});

const Title = ({ title }: { title: string }) => (
  <div className="rounded-br-brand min-h-20 inline-flex items-center bg-white py-4 pl-6 pr-8">
    <h4>{title}</h4>
  </div>
);

const OptionCard = ({
  onToolTipClick,
  imageSrc,
  imageAlt,
  title,
  optionId,
  onClick,
  tooltip = false,
  active = false,
  className,
  hideImage,
}: Props) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onToolTipClick?.();
  };

  const renderImage = () => {
    if (hideImage) {
      return null;
    }

    return (
      <div className="h-75 w-full bg-red-900">
        {imageSrc && imageAlt && (
          <Image alt={imageAlt} className="rounded-brand h-full w-full rounded-b-none object-cover" src={imageSrc} />
        )}
      </div>
    );
  };

  const renderTooltipButton = () => {
    if (!tooltip) {
      return null;
    }

    return (
      <button
        aria-label="tooltip-button"
        className="absolute right-4 top-4 inline-flex"
        onClick={handleClick}
        type="button"
      >
        <FontAwesomeIcon className="text-blue-dark text-base" icon={faCircleInfo} />
      </button>
    );
  };

  const renderActiveIndicator = () => {
    if (!active) {
      return null;
    }

    return (
      <span className="min-h-4 bg-blue-dark min-w-4 absolute bottom-5 right-5 ml-7 inline-flex h-4 w-4 items-center justify-center rounded-full">
        <FontAwesomeIcon className="h-2 w-2 text-white" icon={faCheck} />
      </span>
    );
  };

  return (
    <div className={twMerge(LinkStyles({ active }), className)} data-testid="paintguide-answer">
      <Link onClick={() => onClick(optionId)}>
        {renderImage()}
        <div className="relative">
          <Title title={title} />
          {renderTooltipButton()}
        </div>
        {renderActiveIndicator()}
      </Link>
    </div>
  );
};

export default OptionCard;
