export const downloadFile = (fileUrl: string, filename: string): void => {
  // Create an anchor element
  const anchor = document.createElement('a');
  // Set the href attribute with the PDF URL

  anchor.href = fileUrl;

  // Set the target attribute to open in a new tab
  anchor.target = '_blank';

  // Set the download attribute with the specified filename for the downloaded file
  anchor.download = filename;

  // Append the anchor to the document body
  document.body.appendChild(anchor);

  // Trigger a click event on the anchor to start the download
  anchor.click();

  // Remove the anchor from the document body
  document.body.removeChild(anchor);
};
