import { CHANNEL } from '@boss/constants/b2b-b2c';
import type { NavigationItem, Nullable, SimpleImage } from '@boss/types/b2b-b2c';
import { ReactNode } from 'react';

import BossPaintsFooter from './BossPaintsFooter';
import ColoraFooter from './ColoraFooter';
import CorporateFooter from './CorporateFooter';
import JobsFooter from './JobsFooter';

export type FooterProps = {
  className?: string;
  form?: ReactNode;
  legalLinks?: Nullable<NavigationItem[]>;
  locale: string;
  logo?: Nullable<SimpleImage>;
  navigationItems: Nullable<NavigationItem[]>;
  paymentMethods?: Nullable<SimpleImage[]>;
  socialLinks?: Nullable<NavigationItem[]>;
  socialsLogo?: Nullable<SimpleImage>;
  translations?: {
    brand?: string | null;
    referral?: string | null;
    referralLinkText?: string | null;
    brandReferral?: string | null;
    brandReferralLinkText?: string | null;
  };
  referralLink?: string | null;
  brandReferralLink?: string | null;
  type?: 'default' | 'simple';
};

const Footer = ({
  navigationItems,
  legalLinks,
  socialLinks,
  socialsLogo,
  logo,
  className,
  locale,
  referralLink,
  brandReferralLink,
  translations,
  paymentMethods,
  form,
  type,
}: //form,
FooterProps) => {
  if (CHANNEL === 'colora') {
    return (
      <ColoraFooter
        className={className}
        form={form}
        legalLinks={legalLinks}
        locale={locale}
        navigationItems={navigationItems}
        paymentMethods={paymentMethods}
        socialLinks={socialLinks}
        type={type}
      />
    );
  }
  if (CHANNEL === 'boss-paints') {
    return (
      <BossPaintsFooter
        className={className}
        legalLinks={legalLinks}
        locale={locale}
        navigationItems={navigationItems}
        paymentMethods={paymentMethods}
        type={type}
      />
    );
  }

  if (CHANNEL === 'corporate') {
    return (
      <CorporateFooter
        className={className}
        legalLinks={legalLinks}
        locale={locale}
        logo={logo}
        navigationItems={navigationItems}
        translations={translations}
        type={type}
      />
    );
  }

  if (CHANNEL === 'jobs-b2b' || CHANNEL === 'jobs-b2c') {
    return (
      <JobsFooter
        brandReferralLink={brandReferralLink}
        className={className}
        legalLinks={legalLinks}
        locale={locale}
        logo={logo}
        navigationItems={navigationItems}
        referralLink={referralLink}
        socialLinks={socialLinks}
        socialsLogo={socialsLogo}
        translations={translations}
      />
    );
  } else {
    return null;
  }
};

export default Footer;
