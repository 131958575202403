import { useIsResponsive } from '@boss/hooks';
import { BasicVariant } from '@boss/types/b2b-b2c';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useElementSize } from 'usehooks-ts';

import paintBrush from '../../icons/paintBrush';
import Alert from '../Alert';
import Modal from '../Modal';
import Orb from '../Orb';
import Portal from '../Portal';

interface Step {
  label: string;
  active?: boolean;
  id?: string;
  selected?: string;
  color?: string;
}

interface Props {
  steps: Step[];
  onClick: (step: number) => void;
  mobileTitle: string;
  mobileButtonText: string;
  translate: ({ step, steps }: { step: number; steps: number }) => string;
  currentlyActive?: number;
  variant?: BasicVariant;
  className?: string;
  noSkipQuestions?: boolean;
  testId?: string;
  onlyRouting?: boolean;
  disableLastStep?: boolean;
  infoText?: string;
  showInfoBox?: boolean;
  onCloseInfoBox?: () => void;
}

interface StepsProps {
  activeStep: number;
  onClick: (step: number) => void;
  steps: Step[];
  longerVariant: boolean;
  spacerWidths?: number[];
  progressWidth?: number;
  variant?: BasicVariant;
  noSkipQuestions?: boolean;
  testId?: string;
}

const StepStyles = cva(
  'relative mb-3 z-50 flex h-8 w-8 min-h-8 min-w-8 md:h-12 md:w-12 items-center justify-center rounded-full',
  {
    variants: {
      active: {
        false: 'bg-white shadow-l text-blue',
        true: 'bg-blue text-white',
      },
    },
  },
);

const LabelStyles = cva('text-sm text-center md:text-base lg:text-xl', {
  variants: {
    active: {
      false: 'text-gray-faded',
      true: 'text-blue',
    },
  },
});

const ProgressStyles = cva('rounded-2 absolute left-full top-1/2 z-20 ml-1.25 lg:ml-5 h-1 lg:h-2 -translate-y-1/2', {
  variants: {
    variant: {
      primary: 'bg-red-dark',
      secondary: 'bg-brown',
    },
  },
});

const ProgressIconStyles = cva('text-40 hidden lg:block absolute bottom-[-0.25rem] right-[-1.5rem] z-50', {
  variants: {
    variant: {
      primary: 'text-bordeaux',
      secondary: 'text-brown-dark',
    },
  },
});

const WrapperStyles = cva('align-center hidden w-full items-start justify-between gap-3 lg:gap-2', {
  variants: {
    longerVariant: {
      true: 'lg:flex',
      false: 'md:flex',
    },
  },
});

const MobileBoxWrapperStyles = cva('fixed left-0 bottom-15 w-full py-5 px-4 bg-white z-nav', {
  variants: {
    longerVariant: {
      true: 'lg:hidden',
      false: 'md:hidden',
    },
  },
});

const StepWrapperStyles = cva('flex cursor-pointer gap-3 flex-row items-baseline justify-center ', {
  variants: {
    longerVariant: {
      true: 'lg:flex-col lg:items-center lg:gap-0',
      false: 'sm:flex-col sm:items-center sm:gap-0',
    },
    noPointerEvents: {
      true: 'pointer-events-none',
    },
  },
});

const MobileSpacerStyle = cva('bg-generic-gray-dark rounded-2 block absolute -top-1 -translate-y-full z-0 h-9 w-0.5', {
  variants: {
    longerVariant: {
      true: 'lg:hidden',
      false: 'sm:hidden',
    },
  },
});

/**
 * Calculate the horizantal space between two div elements
 * space = {X pos Element B} - {X pos element A} - {full width Element A} - {the chosen offset between the two elements}
 *
 * @param elementA - HTMLDivElement
 * @param elementB - HTMLDivElement
 * @param offset - number
 * @returns number;
 */
const calculateHorizontalSpace = (elementA: HTMLDivElement, elementB: HTMLDivElement, offset = 0) => {
  const currentXPosition = elementA.getBoundingClientRect().x;
  const nextXPosition = elementB.getBoundingClientRect().x;

  return Math.max(0, nextXPosition - currentXPosition - elementA.offsetWidth - offset);
};

/**
 * Internal component that renders the steps with an optional Ref
 * */
const Steps = forwardRef<Array<HTMLDivElement | null> | null, StepsProps>(function Steps(
  {
    activeStep,
    steps,
    onClick,
    spacerWidths,
    progressWidth,
    variant = 'primary',
    longerVariant,
    noSkipQuestions,
    testId = 'step-button',
  },
  forwardRef,
) {
  const handleForwarededRef = (divRef: HTMLDivElement | null, i: number) => {
    if (!forwardRef || typeof forwardRef === 'function' || !forwardRef.current || !divRef) {
      return;
    }

    forwardRef.current[i] = divRef;
  };

  return (
    <>
      {steps.map(({ label, selected, color, active = false }, i) => (
        <button
          className={StepWrapperStyles({
            longerVariant,
            noPointerEvents: noSkipQuestions && !active && i > activeStep,
          })}
          data-testid={testId + i}
          key={label}
          onClick={() => onClick(i)}
          tabIndex={noSkipQuestions ? -1 : 0}
        >
          <div className={StepStyles({ active: i === activeStep })} ref={r => handleForwarededRef(r, i)}>
            {i < activeStep ? (
              <FontAwesomeIcon icon={faCheck} size="lg" />
            ) : (
              <span className="text-sm md:text-xl">{i + 1}</span>
            )}

            {/* spacers between */}
            {i < steps.length && i >= activeStep && !!spacerWidths?.[i] && (
              <span
                className="bg-gray-softer rounded-2 ml-1.25 absolute left-full top-1/2 z-10 h-1 -translate-y-1/2 lg:ml-5 lg:h-2"
                style={{ width: `${spacerWidths[i]}px` }}
              ></span>
            )}

            {/* progress bar */}
            {i === 0 && !!progressWidth && (
              <span className={ProgressStyles({ variant })} style={{ width: `${progressWidth}px` }}>
                <FontAwesomeIcon className={ProgressIconStyles({ variant })} icon={paintBrush} />
              </span>
            )}

            {/* mobile spacers */}
            {i > 0 && <span className={MobileSpacerStyle({ longerVariant })}></span>}
          </div>
          <p className={LabelStyles({ active: i === activeStep })}>{label}</p>
          {selected && (
            <p className="caption rounded-2 max-w-50 align-center mt-3 line-clamp-1 hidden bg-white px-3 py-1 text-center sm:flex">
              {color && <Orb className="mr-1 translate-y-1/2" size="xxs" style={{ backgroundColor: color }} />}
              {selected}
            </p>
          )}
        </button>
      ))}
    </>
  );
});

/**
 * Main stepper component with a modal on mobile
 */
const Stepper = ({
  steps,
  onClick,
  currentlyActive = 0,
  variant = 'primary',
  translate,
  mobileTitle,
  mobileButtonText,
  className,
  noSkipQuestions,
  testId = 'stepper-component',
  onlyRouting = false,
  infoText,
  disableLastStep,
  showInfoBox,
  onCloseInfoBox,
}: Props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [showInfoMessage, setShowInfoMessage] = useState(false);
  const [spacerWidths, setSpacerWidths] = useState<Array<number>>([]);
  const [progressWidth, setProgressWidth] = useState(0);

  const [stepsWrapperRef, { width }] = useElementSize();
  const stepsRef = useRef<Array<HTMLDivElement | null>>([]);
  const onMobile = useIsResponsive('lg');

  const longerVariant = steps.length > 5;

  const handleClick = (step: number) => {
    if (onlyRouting) {
      onClick(step);
      return;
    }
    if (disableLastStep && step === steps.length - 1) {
      setShowInfoMessage(true);
      return;
    }

    setActiveStep(step);
    onClick(step);
  };

  const handleMobileClick = (step: number) => {
    if (onlyRouting) {
      onClick(step);
      setOpenModal(false);
      return;
    }
    setActiveStep(step);
    onClick(step);
    setOpenModal(false);
  };

  useEffect(() => {
    setShowInfoMessage(!!showInfoBox);
  }, [showInfoBox]);

  // Calculate the width of the spacers between elements
  useEffect(() => {
    const steps = stepsRef.current;
    const _spacerWidths: number[] = [];

    if (!steps.length) {
      return;
    }

    for (let i = 0; i < steps.length; i++) {
      const currentElement = steps[i];
      const nextElement = steps[i + 1];

      if (!currentElement || !nextElement) {
        continue;
      }

      const spaceMargin = calculateHorizontalSpace(currentElement, nextElement, onMobile ? 10 : 35);

      _spacerWidths.push(spaceMargin);
    }

    setSpacerWidths(_spacerWidths);
  }, [width, onMobile]);

  // Calculate the width of the progress bar
  useEffect(() => {
    if (!onMobile) {
      setOpenModal(false);
    }

    const steps = stepsRef.current;
    const first = steps[0];
    const active = steps[activeStep];

    if (!steps.length || !first || !active) {
      return;
    }

    const spaceMargin = calculateHorizontalSpace(first, active, onMobile ? 10 : 45);

    setProgressWidth(spaceMargin);
  }, [width, activeStep, onMobile, steps]);

  const closeInfoMessage = useCallback(() => {
    setShowInfoMessage(false);
    if (onCloseInfoBox) {
      onCloseInfoBox();
    }
  }, [onCloseInfoBox]);

  useEffect(() => {
    if (currentlyActive !== activeStep) {
      setActiveStep(currentlyActive);
      closeInfoMessage();
    }
  }, [currentlyActive, activeStep, closeInfoMessage]);

  return (
    <>
      {showInfoMessage && infoText && (
        <Alert className="mb-10" onClose={() => closeInfoMessage()} type="info">
          {infoText}
        </Alert>
      )}
      <div className={twMerge(WrapperStyles({ longerVariant }), className)} data-testid={testId} ref={stepsWrapperRef}>
        <Steps
          activeStep={activeStep}
          longerVariant={longerVariant}
          noSkipQuestions={noSkipQuestions}
          onClick={handleClick}
          progressWidth={progressWidth}
          ref={stepsRef}
          spacerWidths={spacerWidths}
          steps={steps}
          variant={variant}
        />
      </div>

      <Portal>
        <div className={MobileBoxWrapperStyles({ longerVariant })}>
          {/* Progress lines */}
          <span
            className={twMerge(ProgressStyles({ variant }), 'left-0 right-0 top-0 m-0 h-2 rounded-none')}
            style={{ width: `calc(${(activeStep + 1) / steps.length} * 100%` }}
          >
            <FontAwesomeIcon
              className={twMerge(ProgressIconStyles({ variant }), 'bottom-0 right-0 m-0 block translate-x-1/2')}
              icon={paintBrush}
              size="2x"
            />
          </span>
          <span
            className={twMerge(ProgressStyles({ variant }), 'bg-gray-light left-0 right-0 top-0 z-10 h-2 w-full')}
          ></span>

          {/* Info box */}
          <div className="flex justify-between">
            <div>
              <p className="caption mb-2">{translate({ step: activeStep + 1, steps: steps.length })}</p>
              <p className="text-sm font-bold">{steps[activeStep].label}</p>
            </div>
            <div className="flex cursor-pointer" onClick={() => setOpenModal(true)}>
              <span className="block underline">{mobileButtonText}</span>
              <FontAwesomeIcon className="ml-3" icon={faChevronUp} size="xs" />
            </div>
          </div>
        </div>
      </Portal>

      {openModal && (
        <Modal className="bg-blue-light flex flex-col items-baseline gap-y-8" onClose={() => setOpenModal(false)}>
          <h4>{mobileTitle}</h4>
          <Steps
            activeStep={activeStep}
            longerVariant={longerVariant}
            noSkipQuestions={noSkipQuestions}
            onClick={handleMobileClick}
            ref={stepsRef}
            steps={steps}
            variant={variant}
          />
        </Modal>
      )}
    </>
  );
};

export default Stepper;
