import * as bossClient from '@boss/boss-client';

import { IEvent } from './types';

/**
 * Transform event from bossClient Event to IEvent
 * @param event
 * @returns {IB2BTraining}
 */
export const transform = (event: bossClient.types.event.Event): IEvent => ({
  id: event.id ?? '',
  status: event.status ?? '',
  type: event.type ?? '',
  description: event.description ?? '',
  location: event.location ?? '',
  locationId: event.locationid ?? '',
  productLink: event.productlink ?? '',
  date: event.date ?? '',
  startTime: event.starttime ?? '',
  endTime: event.endtime ?? '',
  duration: event.duration ?? '',
  price: event.price ?? 0,
  complete: event.complete ?? false,
  numberOfParticipants: event.numberofparticipants ?? 0,
});
