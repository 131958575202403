import { useTranslation } from 'next-i18next';

const SPECIFICATION_KEYS = ['paySafe', 'possiblePickup', 'possibleDropOff', 'retour'];

/**
 * Central specification hook that returns generic specification related functions and constants for consistent behaviour
 * @returns {object} returns an object containing the following properties:
 * @property {string[]} specifications List of translated specifications
 */
export const useSpecification = () => {
  const { t } = useTranslation('common');

  const specifications = SPECIFICATION_KEYS.map(key => t(`specification.${key}`));

  return { specifications };
};

export default useSpecification;
