import Quagga from '@ericblade/quagga2';
import {
  QuaggaJSCodeReader,
  QuaggaJSResultCallbackFunction,
  QuaggaJSResultObject,
  QuaggaJSResultObject_CodeResult,
} from '@ericblade/quagga2';
import { useCallback, useLayoutEffect } from 'react';

type ScannerProps = {
  onDetected: (code: string) => void;
  scannerRef: React.RefObject<HTMLDivElement>;
  onScannerReady?: () => void;
  cameraId?: string;
  facingMode?: string;
  constraints?: {
    width?: number;
    height?: number;
  };
  locator?: {
    patchSize: 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
    halfSample: boolean;
    willReadFrequently: boolean;
  };
  decoders?: QuaggaJSCodeReader[];
  locate?: boolean;
};

const getMedian = (arr: number[]): number => {
  const newArr = [...arr].sort((a, b) => a - b);
  const half = Math.floor(newArr.length / 2);

  return newArr.length % 2 === 1 ? newArr[half] : (newArr[half - 1] + newArr[half]) / 2;
};

const getMedianOfCodeErrors = (codeResults: QuaggaJSResultObject_CodeResult): number => {
  const errors = codeResults.decodedCodes.flatMap(x => x.error ?? 0);

  return getMedian(errors);
};

const defaultConstraints = {
  width: 480,
  height: 640,
};

const defaultLocatorSettings = {
  patchSize: 'medium' as const,
  halfSample: true,
  willReadFrequently: true,
  numOfWorkers: typeof navigator !== 'undefined' ? navigator?.hardwareConcurrency ?? 4 : 4,
  frequency: 10,
};

const defaultDecoders: QuaggaJSCodeReader[] = ['ean_reader'];

const Scanner = ({
  onDetected,
  scannerRef,
  onScannerReady,
  cameraId,
  facingMode,
  constraints = defaultConstraints,
  locator = defaultLocatorSettings,
  decoders = defaultDecoders,
  locate = true,
}: ScannerProps) => {
  const errorCheck: QuaggaJSResultCallbackFunction = useCallback(
    (result: QuaggaJSResultObject) => {
      if (!onDetected) {
        return;
      }

      const err = getMedianOfCodeErrors(result.codeResult);

      if (err < 0.25) {
        onDetected(result.codeResult.code ?? '');
      }
    },
    [onDetected],
  );

  const handleProcessed: QuaggaJSResultCallbackFunction = result => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;

    drawingCtx.font = '24px Arial';
    drawingCtx.fillStyle = 'green';

    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(
          0,
          0,
          parseInt(drawingCanvas.getAttribute('height') || '0'),
          parseInt(drawingCanvas.getAttribute('width') || '0'),
        );
        result.boxes
          .filter(box => box !== result.box)
          .forEach(box => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: 'purple', lineWidth: 2 });
          });
      }
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: 'blue', lineWidth: 2 });
      }
      if (result.codeResult && result.codeResult.code) {
        drawingCtx.fillText(result.codeResult.code, 10, 20);
      }
    }
  };

  useLayoutEffect(() => {
    let ignoreStart = false;
    const init = async () => {
      await new Promise(resolve => setTimeout(resolve, 1));
      if (ignoreStart) {
        return;
      }

      await Quagga.init(
        {
          inputStream: {
            type: 'LiveStream',
            constraints: {
              ...constraints,
              ...(cameraId && { deviceId: cameraId }),
              ...(!cameraId && { facingMode }),
            },
            target: scannerRef.current as HTMLElement,
            willReadFrequently: true,
          },
          locator,
          decoder: { readers: decoders },
          locate: true,
        },
        async err => {
          Quagga.onProcessed(handleProcessed);

          if (err) {
            console.error('Error starting Quagga:', err);
            return;
          }

          if (scannerRef.current) {
            await Quagga.start();
            onScannerReady?.();
          }
        },
      );

      Quagga.onDetected(errorCheck as QuaggaJSResultCallbackFunction);
    };

    init();

    return () => {
      ignoreStart = true;
      Quagga.stop();
      Quagga.offDetected(errorCheck as QuaggaJSResultCallbackFunction);
      Quagga.offProcessed(handleProcessed);
    };
  }, [
    cameraId,
    onDetected,
    onScannerReady,
    scannerRef,
    errorCheck,
    constraints,
    locator,
    decoders,
    locate,
    facingMode,
  ]);

  return <div className="h-full w-full" ref={scannerRef} />;
};

export default Scanner;
