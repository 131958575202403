import { CHANNEL } from '@boss/constants/b2b-b2c';
import type { Component, Theme } from '@boss/types/b2b-b2c';
import { Image as CfImage, PageCard, Quote, Video } from '@boss/ui';

import { ComponentMapper, getImageProps, getPageCardProps, getQuoteCarouselProps, getVideoProps } from '../../';
import { isB2b, specialQuoteCarousel } from '../../../utils/options';

type Props = {
  entry: Component;
  theme: Theme;
  inline?: boolean;
  locale: string;
};

const Mapper = ({ entry, locale }: Props): JSX.Element | null => {
  if (entry.__typename === 'image') {
    return <CfImage {...getImageProps(entry)} className={!isB2b ? 'rounded-brand' : ''} />;
  }

  if (entry.__typename === 'video') {
    return <Video {...getVideoProps(entry)} />;
  }

  if (entry.__typename === 'quote' && specialQuoteCarousel) {
    return <Quote variant="carousel" {...getQuoteCarouselProps(entry)} />;
  }

  if ((entry.__typename === 'contentPage' || entry.__typename === 'overviewPage') && entry.channel.includes(CHANNEL)) {
    return <PageCard {...getPageCardProps(entry, locale)} />;
  }

  if ((entry.__typename === 'contentPage' || entry.__typename === 'overviewPage') && !entry.channel.includes(CHANNEL)) {
    return null;
  }

  return <ComponentMapper entry={entry} locale={locale} />;
};

const CarouselMapper = ({ inline, ...props }: Props) => {
  const body = <Mapper {...props} />;

  return inline ? <span className="inline">{body}</span> : body;
};

export default CarouselMapper;
