import { Repeat, Skeleton } from '@boss/ui';
type Props = {
  withNavigation?: boolean;
};

const ArticlesTableSkeleton = ({ withNavigation }: Props) => (
  <div className="flex">
    <Repeat amount={3}>
      <div className="w-100">
        <Repeat amount={3}>
          <Skeleton className="mb-5 h-5 w-full pl-2" />
        </Repeat>
      </div>
    </Repeat>
  </div>
);

export default ArticlesTableSkeleton;
