import { useTranslation } from 'next-i18next';
import { useCurrentRefinements, useRefinementList } from 'react-instantsearch';

import { REFINEMENT_LIMIT } from '@boss/constants/b2b-b2c';
import { Accordion } from '@boss/ui';

import Facets from '../Facets';

type AccordionProps = {
  actionType?: 'refine' | 'navigate' | 'expand';
  attribute: string;
};

const FilterDrawerAccordion = ({ attribute, actionType }: AccordionProps) => {
  const { items: refinementGroups } = useCurrentRefinements();
  const { t } = useTranslation();
  const { items } = useRefinementList({ attribute, limit: REFINEMENT_LIMIT });

  if (!items.length) {
    return null;
  }

  const attributeTranslations: Record<string, string> = t('attributes', { returnObjects: true });
  const label = attributeTranslations[attribute] ?? t('categories');
  const activeGroup = refinementGroups.find(group => group.attribute === attribute);
  const amountOfRefinements = activeGroup?.refinements?.length || 0;
  const amountOfRefinementsLabel = amountOfRefinements > 0 ? `(${amountOfRefinements})` : '';

  return (
    <Accordion
      buttonClassName="py-4 text-black font-bold small"
      className="border-b-1 border-b-gray"
      key={attribute}
      title={`${label} ${amountOfRefinementsLabel}`}
      variant="simple"
    >
      <Facets actionType={actionType} attribute={attribute} className="pb-4" />
    </Accordion>
  );
};

export default FilterDrawerAccordion;
