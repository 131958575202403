import { ISearchColor, SimpleColor } from '@boss/types/b2b-b2c';
import { getTranslatedSlugsByType } from '@boss/utils';

/**
 * Ensures the slug field of every color navigates to the correct page
 **/
export const mapColorSlugField = (locale: string, color: SimpleColor): SimpleColor => ({
  ...color,
  slug: color.slug ? getTranslatedSlugsByType(color.slug ?? '', 'Color overview')[locale] ?? color.slug : '',
});

export const mapColorsSlugField = (locale: string, colors?: SimpleColor[] | null): SimpleColor[] =>
  colors?.map(color => mapColorSlugField(locale, color)) ?? [];

// Remove all spaces, hyphens, and convert to lowercase
export const convertColorCodeToId = (colorCode: string): string => colorCode?.replace(/[\s-]+/g, '').toLowerCase();

//Map from searchColor to a simpleColor with the correct slug
export const mapToSimpleColor = (color: ISearchColor, locale: string): SimpleColor =>
  mapColorSlugField(locale, {
    id: color.id,
    code: color.code,
    rgb: color.rgb,
    name: color.name,
    slug: color.slug,
    trending: color.trending,
  });
