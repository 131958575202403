import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Backdrop from '../Backdrop';

type Props = {
  className?: string;
  children: ReactNode;
  onClose?: () => void;
  ariaLabel?: string;
  closeButtonClassName?: string;
};

/*
 * Generic Modal component which displays a basic popup window with a backdrop. Use this component as a baseline for all modals.
 */
const Modal = ({ children, className, onClose, ariaLabel, closeButtonClassName }: Props) => (
  <Backdrop className="z-modalBackdrop" onClick={onClose} visible>
    <div
      aria-label={ariaLabel}
      className={twMerge(
        'z-modal max-w-315 max-h-8/10 rounded-b-modal rounded-l-modal fixed left-5 right-5 top-1/2 m-auto -translate-y-1/2 overflow-y-auto bg-white p-6 shadow-lg md:p-10',
        className,
      )}
    >
      {children}
      {onClose && (
        <button className={twMerge('absolute right-6 top-6', closeButtonClassName)} onClick={onClose}>
          <FontAwesomeIcon className="text-xl" icon={faClose} />
        </button>
      )}
    </div>
  </Backdrop>
);

export default Modal;
