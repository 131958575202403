import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { twMerge } from 'tailwind-merge';

import Button from '../../Button';

export interface Props {
  className?: string;
  disabled?: boolean;
  edit?: {
    label: string;
    action: () => void;
    icon?: IconDefinition;
    iconPosition?: 'left' | 'right';
  };
  submit: {
    label: string;
    action: () => void;
    icon?: IconDefinition;
    iconPosition?: 'left' | 'right';
    disabled?: boolean;
  };
  buttonClassName?: string;
}

const ShoppingCartActions = ({ className, edit, submit, buttonClassName, disabled = false }: Props) => {
  return (
    <div className={twMerge('flex justify-between', className)}>
      {edit && (
        <Button
          className={buttonClassName}
          icon={edit.icon}
          iconPosition={edit.iconPosition}
          label={edit.label}
          onClick={edit.action}
          type="link"
        />
      )}
      <Button
        className={!edit ? twMerge('w-full', buttonClassName) : ''}
        disabled={submit.disabled || disabled}
        icon={submit.icon}
        iconPosition={submit.iconPosition}
        label={submit.label}
        onClick={submit.action}
        type="primary"
      />
    </div>
  );
};

export default ShoppingCartActions;
