/// <reference types="user-agent-data-types" />
export const getAgentSystem = (): string => {
  if (!('navigator' in window)) {
    return 'unknown';
  }

  const platform = (navigator.userAgentData?.platform || navigator.platform)?.toLowerCase();

  if (platform?.startsWith('win')) {
    return 'windows';
  } else if (platform?.startsWith('mac')) {
    return 'macos';
  } else if (platform?.startsWith('linux')) {
    return 'linux';
  } else {
    return 'unknown';
  }
};
