import { cva } from 'class-variance-authority';

import { StoreCardTranslations } from '../StoreCard';

/* eslint-disable-next-line */
export interface StatusLabelProps {
  isOpen: boolean;
  translations: StoreCardTranslations;
}

const statusColor = cva('small md:text-lg', {
  variants: {
    isOpen: {
      true: 'text-green',
      false: 'text-red',
    },
  },
});

export const StatusLabel = ({ isOpen, translations }: { isOpen: boolean; translations?: StoreCardTranslations }) => {
  return (
    <span
      className={statusColor({
        isOpen,
      })}
    >
      &#8226; {isOpen ? translations?.openLabel ?? 'Open' : translations?.closedLabel ?? 'Gesloten'}
    </span>
  );
};

export default StatusLabel;
