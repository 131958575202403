import Cookies from 'universal-cookie';

import { session } from '../../utils';
import env from '../env';
import logout from '../logout/logout';
import * as wellKnown from '../well-known/well-known';

class AuthROPCWrongUsageException extends Error {
  constructor() {
    super('AuthROPCWrongUsageException: The login call was wrongly used with either a username or a password missing');
  }
}

type Props = { username?: string; password?: string };

/**
 * Login the user
 *
 * Establish the session
 */
export const login = async ({ username, password }: Props) => {
  if (!username || !password) {
    throw new AuthROPCWrongUsageException();
  }

  try {
    session.setStatus('loading');
    const wn = await wellKnown.get();

    const response = await fetch(wn.tokenUri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        username,
        password,
        grant_type: 'password',
        scope: env.SCOPE,
        client_id: env.CLIENT_ID,
        response_type: 'token id_token',
      }),
    });

    const data = await response.json();

    // If succesful, establish the session
    session.activate(JSON.parse(atob(data.id_token.split('.')[1])), data.refresh_token);
    // Store the access token as an access cookie
    const cookies = new Cookies(null, { path: '/' });

    cookies.set(env.ACCESS_TOKEN_COOKIE_NAME, data.access_token, { maxAge: data.expires_in });
  } catch (error) {
    logout();
    throw error;
    // TODO: How are we gonna notify an error. Investigate code flow
  }

  session.setStatus('idle');
};

export default login;
