import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { useRouter } from '@boss/hooks';
import { InspirationOverviewFields, Nullable } from '@boss/types/b2b-b2c';
import { InspirationGrid, Section } from '@boss/ui';

import { ComponentMapper, getInspirationImageProps } from '../components';

type Props = { page: InspirationOverviewFields; locale: string };

const InspirationOverviewPage = ({ page, locale }: Props) => {
  const { t } = useTranslation('inspiration');

  const router = useRouter();
  const [query, setQuery] = useState<Nullable<{ filt: string; val: string }>>(null);

  useEffect(() => {
    const { filt, val } = router.query;

    if (!router.isReady || !filt || !val || typeof val !== 'string' || typeof filt !== 'string') {
      return;
    }
    const translations = Object.entries(t('filters', { returnObjects: true }));

    const filter = translations.find(([, translation]) => translation === filt)?.[0] ?? '';
    const value = translations.find(([, translation]) => translation === val)?.[0] ?? '';

    setQuery({ filt: filter, val: value });
  }, [router.query, router.isReady, t]);

  return (
    <div className="w-full">
      {page.prefixSections?.length &&
        page.prefixSections.map(entry => <ComponentMapper entry={entry} key={entry.id} locale={locale} theme={null} />)}
      <Section content={<InspirationGrid {...getInspirationImageProps(null, locale, t)} query={query} />} />
      {page.suffixSections?.length &&
        page.suffixSections.map(entry => <ComponentMapper entry={entry} key={entry.id} locale={locale} theme={null} />)}
    </div>
  );
};

export default InspirationOverviewPage;
