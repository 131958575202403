import { useTranslation } from 'next-i18next';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { IWishlistLine } from '@boss/services/client';
import { SimpleColor } from '@boss/types/b2b-b2c';

import { useCreateWishlist, useCreateWishlistLine, useDeleteWishlistLine, useWishlists } from '../../client-queries';
import { useProfile } from '../../hooks';
import useLogin from '../use-login/use-login';

/**
 * Represents the type of favorite, which can be either 'COLORS' or 'PRODUCTS'.
 */
type favoriteType = 'COLORS' | 'PRODUCTS';

/**
 * Represents the state of favorite items.
 */
interface FavoriteState {
  favorites: Record<favoriteType, string[]>;
  /**
   * Adds an item to the favorites list of the specified type.
   * @param type - The type of favorite (either 'COLORS' or 'PRODUCTS').
   * @param id - The unique identifier of the item to add.
   */
  addFavorite: (type: favoriteType, id: string) => void;
  /**
   * Removes an item from the favorites list of the specified type.
   * @param type - The type of favorite (either 'COLORS' or 'PRODUCTS').
   * @param id - The unique identifier of the item to remove.
   */
  removeFavorite: (type: favoriteType, id: string) => void;
  /**
   * Checks if an item is in the favorites list of the specified type.
   * @param type - The type of favorite (either 'COLORS' or 'PRODUCTS').
   * @param id - The unique identifier of the item to check.
   * @returns True if the item is in the favorites list, false otherwise.
   */
  isFavorite: (type: favoriteType, id: string) => boolean;
  /**
   * Toggles the favorite status of an item in the favorites list of the specified type.
   * If the item is already a favorite, it will be removed; otherwise, it will be added.
   * @param type - The type of favorite (either 'COLORS' or 'PRODUCTS').
   * @param id - The unique identifier of the item to toggle.
   */
  toggleFavorite: (type: favoriteType, id: string, color?: SimpleColor) => void;
}

type Props = {
  children: React.ReactNode;
  locale?: string;
};

const FavoriteContext = createContext<FavoriteState | undefined>(undefined);

/**
 * Provides a context for managing favorite items.
 * @param children - The child components wrapped by this provider.
 */
export const FavoriteProvider: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation('common');
  const successToast = {
    title: t('toast.updateWishlist.success.title'),
    description: t('toast.updateWishlist.success.description'),
  };
  const errorToast = {
    title: t('errors.submitTitle'),
    description: t('success.submitSubtitle'),
  };
  const { data: wishlistsData, isSuccess } = useWishlists();
  const { mutate: createWishlist } = useCreateWishlist({});
  const { mutate: createWishlistLine } = useCreateWishlistLine({
    toasts: { success: successToast, error: errorToast },
  });
  const { mutate: deleteWishlistLine } = useDeleteWishlistLine({
    toasts: { success: successToast, error: errorToast },
  });
  const { navigateToProtectedPage } = useLogin();
  // const [wishlists, setWishlists] = useState<IWishlists>();

  const profile = useProfile();

  useEffect(() => {
    if (isSuccess && (wishlistsData.favoritecolor || wishlistsData.favoriteproduct)) {
      setFavorites({
        COLORS: wishlistsData?.favoritecolor?.lines.map(color => color.typeid || '') || [],
        PRODUCTS: wishlistsData?.favoriteproduct?.lines.map(product => product.typeid || '') || [],
      });
    }
  }, [isSuccess, wishlistsData]);

  const [favorites, setFavorites] = useState<Record<favoriteType, string[]>>({
    COLORS: [],
    PRODUCTS: [],
  });

  const login = () => {
    navigateToProtectedPage();
  };

  /**
   * Adds an item to the favorites list of the specified type.
   * @param type - The type of favorite (either 'COLORS' or 'PRODUCTS').
   * @param id - The unique identifier of the item to add.
   */
  const addFavorite = async (type: favoriteType, id: string, color?: SimpleColor) => {
    const accountid = profile.data?.extension_AccountId as string;
    const wishlistLine: IWishlistLine = { typeid: id };
    const wishlistType = type === 'COLORS' ? 'favoritecolor' : 'favoriteproduct';
    const wishlist = wishlistsData?.[wishlistType];

    if (type === 'COLORS' && color) {
      wishlistLine.color = {
        colorcode: color.code,
        colorname: color.name,
        rgb: color.rgb,
      };
    }

    if (!accountid) {
      return login();
    }

    if (!wishlist) {
      createWishlist({ type: wishlistType, accountid, lines: [wishlistLine] });
    } else {
      createWishlistLine({
        wishlistId: wishlist.id || '',
        wishlistLine,
        wishlistType,
      });
    }
  };

  /**
   * Removes an item from the favorites list of the specified type.
   * @param type - The type of favorite (either 'COLORS' or 'PRODUCTS').
   * @param id - The unique identifier of the item to remove.
   */
  const removeFavorite = (type: favoriteType, id: string) => {
    const wishlistType = type === 'COLORS' ? 'favoritecolor' : 'favoriteproduct';
    const wishlist = wishlistsData?.[wishlistType];

    const wishlistLineId = wishlist?.lines.find(color => color.typeid === id)?.id;
    const wishlistId = wishlist?.id;

    if (!wishlistId || !wishlistLineId || !wishlist) {
      return;
    }

    deleteWishlistLine({ wishlistId, wishlistLineId, wishlistType });
  };

  /**
   * Checks if an item is in the favorites list of the specified type.
   * @param type - The type of favorite (either 'COLORS' or 'PRODUCTS').
   * @param id - The unique identifier of the item to check.
   * @returns True if the item is in the favorites list, false otherwise.
   */
  const isFavorite = (type: favoriteType, id: string) => {
    return (favorites[type] || []).includes(id);
  };

  /**
   * Toggles the favorite status of an item in the favorites list of the specified type.
   * If the item is already a favorite, it will be removed; otherwise, it will be added.
   * @param type - The type of favorite (either 'COLORS' or 'PRODUCTS').
   * @param id - The unique identifier of the item to toggle.
   */
  const toggleFavorite = (type: favoriteType, id: string, color?: SimpleColor) => {
    if (!profile.data) {
      return login();
    }

    if (isFavorite(type, id)) {
      removeFavorite(type, id);
    } else {
      addFavorite(type, id, color);
    }
  };

  return (
    <FavoriteContext.Provider
      value={{
        favorites,
        addFavorite,
        removeFavorite,
        isFavorite,
        toggleFavorite,
      }}
    >
      {children}
    </FavoriteContext.Provider>
  );
};

/**
 * Custom hook for accessing the favorite state and functions.
 * @returns The favorite state and functions.
 */
export const useFavorites = (): FavoriteState => {
  const context = useContext(FavoriteContext);

  if (!context) {
    throw new Error('useFavorites must be used within a FavoriteProvider');
  }
  return context;
};
