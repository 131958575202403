import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';
import { Presence } from '@boss/ui';

import { useContacts } from '../../client-queries';
import { FormType, useFormField, useProfile, useServiceRequest } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

export const FORM_FIELD_KEYS = [
  'clientnumber',
  'companyname',
  'firstname',
  'lastname',
  'address',
  'accountaddress',
  'new',
  'message',
  'termsandconditions',
];

type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};

type Props = {
  type: FormType;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: FieldOverwrite;
  additionalFields?: FormField[];
  className?: string;
};

const TechnicalAdviceForm = ({
  fieldsToShow: initialFieldsToShow,
  fieldsOverwrite,
  additionalFields,
  className,
  type,
}: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { onSubmit, isSubmitting, isSuccess, reset } = useServiceRequest();

  const { locale } = useRouter();

  const { t } = useTranslation('forms');

  const { clientnumber, firstname, lastname, termsandconditions, companyname, question, address } = useFormField();

  const { data: contacts } = useContacts(locale);

  const { data: profile } = useProfile();
  const contact = contacts?.find(contact => contact.id === profile?.extension_ContactPersonId);

  const baseFields: FormField[] = [
    {
      ...clientnumber,
    },
    {
      ...companyname,
      disabled: true,
    },
    {
      ...firstname,
      initialValue: contact?.firstname ?? '',
      disabled: true,
    },
    {
      ...lastname,
      initialValue: contact?.lastname ?? '',
      disabled: true,
    },
    address,
    {
      ...question,
      label: t('fields.questionHelp'),
      placeholder: t('placeholders.technicalquestion') ?? '',
      disclaimer: t('disclaimers.colorAdvice'),
    },
    termsandconditions,
  ];

  const getFieldsToShow = () => {
    return fieldsToShow ?? FORM_FIELD_KEYS;
  };

  const handleSubmit = (vals: FormValues) => {
    const submitObject = {
      ...vals,
    };

    onSubmit(type, submitObject);
  };

  return (
    <Presence className={twMerge('flex flex-col gap-4', className)} id="help-form-presence" visible>
      <DynamicForm
        buttonProps={{
          label: t('buttons.submitQuestion') ?? '',
        }}
        disclaimer={t('disclaimers.technicaladvice') ?? ''}
        fields={buildFormFields(baseFields, getFieldsToShow(), additionalFields, fieldsOverwrite)}
        id={type}
        isSubmitting={isSubmitting}
        isSuccess={isSuccess}
        onCloseAlert={reset}
        onSubmit={handleSubmit}
        variant="light"
      />
    </Presence>
  );
};

export default TechnicalAdviceForm;
