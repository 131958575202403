import { useTranslation } from 'next-i18next';
import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

import { Select } from '@boss/ui';

import { getPackagingOptions } from '../../utils';

type Props = {
  name?: string;
  className?: string;
  onChange: ComponentProps<typeof Select>['onChange'];
  unit: string;
  packagingOptions: ReturnType<typeof getPackagingOptions>;
};

const Packaging = ({ name = 'packaging', className, onChange, unit, packagingOptions }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Select
      className={twMerge('w-full', className)}
      iconClassName="right-2"
      name={name}
      onChange={onChange}
      options={packagingOptions.map(option => ({
        label: t('perValue', { value: t(`unit.${option.name}`) + ` (${option.quantity})` })?.toLowerCase(),
        value: option.name,
      }))}
      selectClassName="min-h-0 h-7.5 py-0 pl-3 pr-7"
      value={unit}
    />
  );
};

export default Packaging;
