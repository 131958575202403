import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { ContactInfoBlock, StoreMap } from '@boss/ui';

import { storeMapCenterPostion } from '../../constants';
import { usePointOfSale } from '../../hooks';
import { contactInfoConfig, storeCardVariant } from '../../utils';

type Cta = {
  label: string;
  href: string;
  id: string;
};

type Props = {
  phone: string;
  email: string;
  address?: string;
  ctas?: Cta[];
  className?: string;
};

const ContactInformation = ({ className, phone, email, address, ctas }: Props) => {
  const { enableMap } = contactInfoConfig;
  const { t } = useTranslation('stores');
  const { stores } = usePointOfSale();

  return (
    <div className={twMerge('flex flex-col gap-10', className)}>
      <div className="flex flex-col gap-8">
        <span className="h2 text-[2rem]">{t('contactBlock.title')}</span>
        <ContactInfoBlock address={address} ctas={ctas} email={email} phone={phone} />
      </div>
      {enableMap && (
        <div className="h-75 w-full">
          <StoreMap
            apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string}
            centerPosition={storeMapCenterPostion}
            storeBasePath={'/'}
            storeCardVariant={storeCardVariant}
            stores={stores}
            translations={t('storeFinder.card', { returnObjects: true })}
          />
        </div>
      )}
    </div>
  );
};

export default ContactInformation;
