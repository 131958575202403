import { session } from '../../utils';
import env from '../env';

const getTarget = (value?: string) => {
  if (value) {
    return new URL(value, window.location.origin).toString();
  }

  return window.location.href;
};

/**
 * Format and go to logout url with correct parameters
 */
export const logout = (target?: string) => {
  // Deactivate the session even if the logout fails
  session.deactivate();

  window.location.href = `${env.PATH_PREFIX}/logout?target=${getTarget(target)}`;
};

export default logout;
