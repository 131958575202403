import { CustomAsset } from '@boss/types/b2b-b2c';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Icon from '../Icon';
import Image from '../Image';
import Link from '../Link';

type Props = {
  asset?: CustomAsset;
  imageUrl?: string;
  imageAlt?: string;
  caption?: ReactNode;
  order?: number;
  className?: string;
  imageClassName?: string;
  slug?: string;
  trackInfo?: Record<string, string | number>;
};

const getCournerClass = (num?: number) => {
  if (!num) {
    return '';
  }
  if (num % 4 === 0) {
    return 'rounded-tl-none';
  }
  if (num % 3 === 0) {
    return 'rounded-tr-none';
  }
  if (num % 2 === 0) {
    return 'rounded-bl-none';
  }
  if (num % 1 === 0) {
    return 'rounded-br-none';
  }
};

const Asset = ({ asset, imageClassName, order }: Pick<Props, 'asset' | 'imageClassName' | 'order' | 'imageUrl'>) => (
  <Image className={twMerge('h-85 rounded-brand object-cover', getCournerClass(order), imageClassName)} image={asset} />
);

const getPinterestUrl = ({ asset, imageUrl }: Pick<Props, 'asset' | 'imageUrl'>) => {
  return `https://www.pinterest.com/pin/create/button/?url=https:${asset?.file ? asset.file.url : imageUrl}`;
};

const InspirationImage = ({ imageClassName, slug, asset, caption, className, order, imageAlt, trackInfo }: Props) => {
  return (
    <div className={twMerge('mb-10 inline-block md:mb-0', className)} data-testid="inspirationImage">
      <div className="relative">
        <Link href={slug} trackInfo={trackInfo}>
          <Asset asset={asset} imageClassName={imageClassName} order={order} />
        </Link>
        <a
          className="shadow-m absolute right-4 top-3 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-white"
          data-pin-custom="true"
          data-pin-description={asset ? asset.description : imageAlt}
          data-pin-do="buttonPin"
          data-pin-round="true"
          href={getPinterestUrl({ asset })}
          rel="noreferrer"
          target="_blank"
        >
          <Icon colorClass="text-[#E60023]" name="pinterest" />
        </a>
      </div>
      {caption}
    </div>
  );
};

export default InspirationImage;
