import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  children: ReactNode[] | ReactNode;
  className?: string;
};

export const ProductsGrid = ({ children, className }: Props) => {
  return (
    <div className={twMerge('grid w-full grid-cols-1 gap-6 gap-y-12 sm:grid-cols-2 lg:grid-cols-3', className)}>
      {children}
    </div>
  );
};

export default ProductsGrid;
