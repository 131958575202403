import { useTranslation } from 'next-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLockedBody } from 'usehooks-ts';

import { types } from '@boss/boss-client';
import { basketPageSlugs, specialDecoTechniqueCategoryId } from '@boss/constants/b2b-b2c';
import { useIsSSR, useRouter } from '@boss/hooks';
import { IProduct } from '@boss/services';
import { ColorGroup, SimpleColor } from '@boss/types/b2b-b2c';
import { IArticle } from '@boss/types/b2b-b2c';
import { Button, ProductPanel } from '@boss/ui';

import { ArticlesTable, DeliveryPromises, ProductDetailModals } from '..';
import { COLOR_TYPE, CONSUMPTION_UNIT } from '../../constants';
import { useLogin, useProductData } from '../../hooks';
import { isB2b, productPanelVariant, surfaceCalculationConfig } from '../../utils';
import { ArticleIdsObject } from '../ArticlesTable';
import ArticlesTableSkeleton from '../ArticlesTable/Skeleton';
import { Modal } from '../Modals/ProductDetailModals';

type Props = {
  hasArticles: boolean;
  addToBasket: (params: { callback?: () => void; isColorProduct: boolean }) => void;
  className?: string;
  colorGroups: ColorGroup[] | null;
  colorType: 'transparant' | 'default';
  hasError: boolean;
  price?: number;
  product: IProduct;
  savedStore?: types.Store;
  selectedArticleIdsObject: ArticleIdsObject;
  selectedColor?: SimpleColor;
  setHasError: (bool: boolean) => void;
  setInfoMessage: (message: { title: string; message: string } | undefined) => void;
  setSelectedArticleIdsObject: Dispatch<SetStateAction<ArticleIdsObject>>;
  setSelectedColor: (color: SimpleColor | undefined) => void;
  showProductActions?: boolean;
  showBaseColors?: boolean;
  hasArticlesSelected: boolean;
  setArticles: Dispatch<SetStateAction<IArticle[]>>;
  isBasketUpdateLoading?: boolean;
  infoMessage?: {
    title: string;
    message: string;
  };
};

// TODO: Refactor this component to be more readable
const ProductPanelWrapper = ({
  addToBasket,
  className,
  colorGroups,
  colorType,
  hasError,
  price,
  product,
  selectedArticleIdsObject,
  selectedColor,
  setHasError,
  setInfoMessage,
  setSelectedArticleIdsObject,
  setSelectedColor,
  showProductActions,
  showBaseColors,
  hasArticlesSelected,
  setArticles,
  hasArticles,
  isBasketUpdateLoading,
  infoMessage,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const isSSR = useIsSSR();
  const { handleLogin, isLoggedIn, handleRegister } = useLogin();
  const { t } = useTranslation(['product', 'stores', 'common']);
  const { asPath, locale, isReady, query } = useRouter();
  const [activeModal, setActiveModal] = useState<Modal | undefined>();
  const openSurfaceCalculatorModal = () => setActiveModal('surfaceCalculator');

  const isPaint = product.type !== 'NON_PAINT';
  const isSpecialDecoTechnique = product.categories.some(category => category.id === specialDecoTechniqueCategoryId);

  /**
   * This is about as good as it gets without restructering and rethinking the whole PDP functionality
   */
  const {
    favoriteColors,
    colorsByGroup,
    searchColors,
    searchLoading,
    activeProductOption,
    setSelectedColorGroup,
    productOptions,
    selectedColorGroup,
    handleProductOptionClick,
    setSearchedValue,
    searchValue,
    addColor,
    calculated,
    searchedValue,
    setSavedStore,
    setCalculated,
    setSearchValue,
    specifications,
    viewedColors,
    showPrivatePrices,
    showStock,
    stock,
    showPrice,
    savedStore,
    showColors,
    handleTogglePrivatePrices,
    articles,
    articlesLoading,
    preSelectedColor,
    preSelectedColorIsLoading,
    setSelectedColorPerGroup,
    selectedColorPerGroup,
    noArticlesAvailable,
  } = useProductData({
    showBaseColors: !!showBaseColors,
    isPaint,
    productId: product.id,
    selectedArticleIdsObject,
    selectedColor,
    hasArticles,
    colorTypes: product.colortypegroups,
  });

  const enableColors = showColors && isPaint && !!productOptions?.length;

  // should be in the comp?
  const enableStock = showStock && hasArticlesSelected;

  const openColorModal = () => {
    setActiveModal('color');
  };

  const handleProductPanelSearch = () => {
    setSearchedValue(searchValue);

    openColorModal();
  };

  const handleColorGroupClick = (color?: SimpleColor) => {
    if (color) {
      setSelectedColorGroup(color);
    }
    openColorModal();
  };

  const onColorClick = (simpleColor: SimpleColor) => {
    _setSelectedColor(simpleColor);
    addColor(simpleColor);
    setSelectedArticleIdsObject({});
    setAmountOfPaintNeeded(0);
  };

  useEffect(() => {
    if (articles?.length) {
      setArticles(articles);
    }
  }, [articles, setArticles]);

  const _setSelectedColor = (color: SimpleColor) => {
    setAmountOfPaintNeeded(0);
    if (activeProductOption?.key) {
      setSelectedColorPerGroup({
        ...selectedColorPerGroup,
        [activeProductOption.key]: color,
      });
    }
    setSelectedColor(color);
  };

  const setProductOption = (option: { key: string; value: string }) => {
    const color = selectedColorPerGroup[option.key];

    setSelectedColor(color ?? undefined);

    handleProductOptionClick({
      key: option.key as COLOR_TYPE,
      value: option.value,
    });
  };

  useEffect(() => {
    if (preSelectedColor) {
      _setSelectedColor(preSelectedColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedColor]);

  const isPanelLoading = isSSR || searchLoading || preSelectedColorIsLoading || !isReady;

  const handleAddToBasket = async () => {
    addToBasket({
      callback: !isB2b ? () => setActiveModal('crossSelling') : undefined,
      isColorProduct: enableColors,
    });
  };

  useEffect(() => {
    if (query.colorId && query.colorGroupType && colorsByGroup?.length && isReady) {
      const color = colorsByGroup.find(color => color.colorid === query.colorId);

      if (color) {
        setSelectedColor({
          code: color.colorcode,
          name: color.colorname,
          rgb: color.rgb,
          id: color.colorid,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.colorId, query.colorGroupType, colorsByGroup, isReady]);

  const [amountOfPaintNeeded, setAmountOfPaintNeeded] = useState(0);

  useLockedBody(!!activeModal, 'root');

  const showWishlistModal = () => {
    if (hasArticlesSelected && (selectedColor || product.type === 'NON_PAINT')) {
      setActiveModal('wishlist');
      setInfoMessage(undefined);
    } else if (hasArticlesSelected && !selectedColor) {
      setInfoMessage({
        title: t('productPanel.infoTitle'),
        message: t('productPanel.infoMessage.selectColorForWishlistRequired'),
      });
    } else {
      setInfoMessage({
        title: t('productPanel.infoTitle'),
        message: t('productPanel.infoMessage.selectArticlesForWishlistRequired'),
      });
    }
  };

  return (
    <>
      {activeModal && (
        <ProductDetailModals
          activeModal={activeModal}
          articles={articles}
          calculated={calculated}
          colorGroups={colorGroups}
          enableStock={enableStock}
          product={product}
          searchedValue={searchedValue}
          selectedArticleIdsObject={selectedArticleIdsObject}
          selectedColor={selectedColor}
          selectedColorGroup={selectedColorGroup}
          setActiveModal={setActiveModal}
          setAmountOfPaintNeeded={setAmountOfPaintNeeded}
          setCalculated={setCalculated}
          setSavedStore={setSavedStore}
          setSearchedValue={setSearchedValue}
          setSelectedArticleIdsObject={setSelectedArticleIdsObject}
          setSelectedColor={_setSelectedColor}
          setSelectedColorGroup={setSelectedColorGroup}
        />
      )}
      <ProductPanel
        activeProductOption={activeProductOption}
        addToBasket={handleAddToBasket}
        addToWishlist={showWishlistModal}
        alternativeActions={
          <div className="-mx-8 flex flex-col gap-4 bg-gray-100 px-8 py-8">
            <h4 className="text-center">{t('alternativeActions.title')}</h4>
            <Button
              className="w-full p-3 text-center"
              label={t('alternativeActions.register')}
              onClick={() => handleRegister(asPath)}
              type="primary"
            />
            <Button
              className="w-full p-3 text-center"
              label={t('alternativeActions.login')}
              onClick={() => handleLogin(asPath)}
              type="secondary"
            />
          </div>
        }
        calculatorResult={{
          value: amountOfPaintNeeded,
          unit: t(product.consumptionUnit === CONSUMPTION_UNIT.KILO ? 'kilo' : 'liter', { ns: 'common' }),
        }}
        className={className}
        colorOptions={colorsByGroup}
        colorType={colorType}
        colors={colorGroups}
        deliveryPromises={
          <DeliveryPromises
            onShopDeliveryClick={() => setActiveModal('stock')}
            savedStore={savedStore}
            stock={stock}
            stockEnabled={enableStock}
          />
        }
        disclaimerLink={showPrice ? basketPageSlugs[locale] : ''}
        enableCalculator={
          (surfaceCalculationConfig.showTechnique && isPaint) ||
          (!surfaceCalculationConfig.showTechnique && product.brush !== 0 && product.roller !== 0 && isPaint)
        }
        enableColorPicker={enableColors}
        favoriteColors={favoriteColors}
        hasArticles={hasArticles}
        hasError={hasError}
        hasSearched={!!searchedValue}
        inStock
        infoMessage={infoMessage}
        isBasketUpdateLoading={isBasketUpdateLoading}
        isLoading={isPanelLoading}
        isLoggedIn={isLoggedIn}
        isSpecialDecoTechnique={isSpecialDecoTechnique}
        noArticlesAvailable={noArticlesAvailable}
        onCalculateSurface={openSurfaceCalculatorModal}
        onChangeGroupClick={handleColorGroupClick}
        onCloseAlert={() => setHasError(false)}
        onCloseInfo={() => setInfoMessage(undefined)}
        onColorClick={onColorClick}
        onProductOptionClick={setProductOption}
        onSearch={handleProductPanelSearch}
        openOrderForm={() => setActiveModal('specialForm')}
        price={{ value: price }}
        productOptions={productOptions}
        recentColors={viewedColors}
        savedStore={savedStore}
        searchColors={searchColors}
        searchLoading={searchLoading}
        searchValue={searchValue}
        selectedColor={selectedColor}
        setSearchValue={setSearchValue}
        showProductActions={showProductActions}
        specifications={specifications}
        table={
          /*
           * for some reason the internal loading state is not working properly
           */
          !articlesLoading ? (
            <ArticlesTable
              articleType={isPaint ? 'paint' : 'nonPaint'}
              articles={articles}
              isLoading={articlesLoading}
              isLoggedIn={isLoggedIn}
              selectedArticleIdsObject={selectedArticleIdsObject}
              setSelectedArticleIdsObject={setSelectedArticleIdsObject}
              showPrivatePrices={showPrivatePrices}
              togglePrivatePrices={productPanelVariant === 'secondary' ? handleTogglePrivatePrices : undefined}
              type={showProductActions ? 'default' : 'slim'}
            />
          ) : (
            <ArticlesTableSkeleton />
          )
        }
        translations={{ ...t('productPanel', { ns: 'product', returnObjects: true }) }}
        variant={productPanelVariant}
      />
    </>
  );
};

export default ProductPanelWrapper;
