import { SCREENS } from '@boss/constants/b2b-b2c';
import { Nullable, Image as ProductDetailImage } from '@boss/types/b2b-b2c';
import { SwiperProps } from 'swiper/react';

import Carousel from '../Carousel';
import Image from '../Image';
type Props = {
  images?: Nullable<ProductDetailImage[]>;
  className?: string;
  options?: SwiperProps;
};

const defaultOptions = {
  slidesPerView: 1,
  breakpoints: {
    [SCREENS.md]: {
      spaceBetween: 32,
      slidesPerView: 2,
    },
  },
} as SwiperProps;

const ProductInspirationImages = ({ images, className, options = defaultOptions }: Props) => {
  if (!images?.length) {
    return null;
  }

  return (
    <Carousel className={className} options={options}>
      {images.map(image => (
        <div className="h-full max-h-40 w-full" key={image?.image?.title}>
          <Image className="shadow-m flex h-full rounded-md object-cover" {...image} />
        </div>
      ))}
    </Carousel>
  );
};

export default ProductInspirationImages;
