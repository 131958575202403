import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';

import { Section, Specifications } from '@boss/ui';

import { RegisterForm } from '..';

const CardStyling = cva('rounded-brand flex flex-col gap-6 rounded-bl-none px-5 py-4 lg:col-span-5 md:gap-8 md:p-9', {
  variants: {
    variant: {
      form: 'bg-blue-light/30 lg:col-start-2',
      info: 'bg-beige-light md:rounded-bl-brand md:rounded-br-none',
    },
  },
});

const RegisterSection = () => {
  const { t } = useTranslation('register', { keyPrefix: 'registerSection' });

  return (
    <Section
      className="md:pt-13 md:pb-35 grid gap-4 pb-20 md:grid-cols-2 md:gap-6 lg:grid-cols-12"
      content={
        <>
          <div className={CardStyling({ variant: 'form' })}>
            <h2 className="md:h3">{t('title')}</h2>
            <RegisterForm />
          </div>
          <div className={CardStyling({ variant: 'info' })}>
            <div className="text-blue flex flex-col gap-3 md:gap-6">
              <span className="h3">{t('benefits.title')}</span>
              <Specifications
                border={false}
                className="md:gap-4"
                id="register-client-benefits"
                items={[1, 2].map((item, index) => (
                  <span className="text-textColor" key={`register-client-benefit-${item}-${index}`}>
                    {t(`benefits.${item}`)}
                  </span>
                ))}
              />
            </div>
          </div>
        </>
      }
    />
  );
};

export default RegisterSection;
