import { TFunction } from 'i18next';

import { IStore } from '@boss/services';
import { getCurrentOpeningHours, toHoursAndMinutes } from '@boss/utils';

/**
 * Get remarks of the store block for stock overview.
 * It will generate an array of strings, holding the delivery promise and the opening hours currently.
 * @date 25-9-2023 - 10:03:09
 *
 * @param {IStore} store
 * @param {TFunction<('stores' | 'routes' | 'product')[], undefined, ('stores' | 'routes' | 'product')[]>} t
 * @returns {string[]}
 */
export const getRemarks = (
  store: IStore,
  t: TFunction<('stores' | 'routes' | 'product')[], undefined, ('stores' | 'routes' | 'product')[]>,
): string[] => {
  const remarks: string[] = [t('deliverypromise', { ns: 'stores' })];

  const openingHours = getCurrentOpeningHours(store);

  if (!openingHours || openingHours.length === 0) {
    return remarks;
  }

  let openingHourString = t('openingHours.storeIsOpenFrom', { ns: 'stores' });

  openingHours?.forEach((hour, index) => {
    openingHourString += ` ${toHoursAndMinutes(hour.from)} ${t('openingHours.openTill', {
      ns: 'stores',
    })} ${toHoursAndMinutes(hour.till)}`;

    if (openingHours?.length !== 1 && index !== openingHours.length - 1) {
      openingHourString += ` & ${t('openingHours.openFrom', {
        ns: 'stores',
      })}`;
    }
  });

  remarks.push(openingHourString);

  return remarks;
};
