import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCollapse } from 'react-collapsed';
import { twMerge } from 'tailwind-merge';

import { IAccordionProps } from '..';

const AccordionSimple = ({
  className,
  buttonClassName,
  title,
  children,
  closedIcon = faPlus,
  expandedIcon = faMinus,
}: IAccordionProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const collapseProps = getCollapseProps();
  const toggleProps = getToggleProps();

  return (
    <div className={className}>
      <button
        aria-label="accordion-button"
        className={twMerge('flex w-full items-center justify-between', buttonClassName)}
        type="button"
        {...toggleProps}
      >
        {title}
        <FontAwesomeIcon icon={isExpanded ? expandedIcon : closedIcon} />
      </button>
      <div aria-labelledby="menu-button" aria-orientation="vertical" {...collapseProps}>
        {children}
      </div>
    </div>
  );
};

export default AccordionSimple;
