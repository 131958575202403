import { faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

const PAGE_OFFSET = 20;

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [lastYPosition, setLastYPosition] = useState(0);

  useEffect(() => {
    const toggleVisibility = () => {
      const footer = document.getElementsByTagName('footer')[0];
      const rect = footer?.getBoundingClientRect();

      if (
        window.scrollY > PAGE_OFFSET &&
        lastYPosition > window.scrollY &&
        (!footer || !rect || rect.top + window.scrollY > window.innerHeight + window.scrollY)
      ) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      setLastYPosition(window.scrollY);
    };

    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  });

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="mb-3.75 mr-3.75 fixed bottom-0 right-0 z-50">
      <button className="bg-scrollToTop-bg w-17.5 h-17.5 rounded-full p-3" onClick={handleScrollToTop}>
        <FontAwesomeIcon className="text-scrollToTop-text text-2xl" icon={faArrowUp} />
      </button>
    </div>
  );
};

export default ScrollToTop;
