/* eslint-disable sonarjs/no-duplicate-string */
export default [
  {
    recid: 5638647898,
    transtype: 'Sales',
    accountnumber: '4092',
    invoice: 'VF23045939',
    transactiondate: '2023-09-28T22:00:00Z',
    amountcurrency: 3301.62,
    duedate: '2023-10-30T23:00:00Z',
    settleamountcurrency: 0.0,
    amountoutstanding: 3301.62,
    invoiceoutstanding: true,
    onlinepaymentok: false,
    amounttopay: 3301.62,
    documenturl: 'https://www.orimi.com/pdf-test.pdf',
  },
  {
    recid: 5638636852,
    transtype: 'Sales',
    accountnumber: '4092',
    invoice: 'VF23044971',
    transactiondate: '2023-09-21T22:00:00Z',
    amountcurrency: 8168.16,
    duedate: '2024-10-30T23:00:00Z',
    settleamountcurrency: 0.0,
    amountoutstanding: 8168.16,
    invoiceoutstanding: true,
    onlinepaymentok: false,
    amounttopay: 8168.16,
    documenturl: 'https://resources-test.boss.be/invoices/ea5b449f-a31e-4c46-bfea-095860bd7a13',
  },
  {
    recid: 5638636852,
    transtype: 'Sales',
    accountnumber: '4092',
    invoice: 'VF23044974',
    transactiondate: '2023-09-21T22:00:00Z',
    amountcurrency: 8168.16,
    duedate: '2024-10-30T23:00:00Z',
    settleamountcurrency: 0.0,
    amountoutstanding: 8168.16,
    invoiceoutstanding: false,
    onlinepaymentok: false,
    amounttopay: 8168.16,
    documenturl: 'https://resources-test.boss.be/invoices/ea5b449f-a31e-4c46-bfea-095860bd7a13',
  },
  {
    recid: 5638636859,
    transtype: 'Sales',
    accountnumber: '4092',
    invoice: 'VF23044974',
    transactiondate: '2023-09-21T22:00:00Z',
    amountcurrency: 8168.16,
    duedate: '2024-10-30T23:00:00Z',
    settleamountcurrency: 0.0,
    amountoutstanding: 8168.16,
    invoiceoutstanding: false,
    onlinepaymentok: false,
    amounttopay: 8168.16,
    documenturl: 'https://resources-test.boss.be/invoices/ea5b449f-a31e-4c46-bfea-095860bd7a13',
  },
];
