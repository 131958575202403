import { IconDefinition, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import { Image, Orb, Price, Skeleton } from '..';
import Link from '../Link';

type Props = {
  className?: string;
  icon: IconDefinition;
  subtitle?: string;
  title?: string;
  href: string;
  product: {
    imageSrc?: string | null;
    eyebrow: string;
    price?: number;
    priceInfoLoading?: boolean;
  };
  color?: {
    eyebrow: string;
    title: string;
    href: string;
    footerLabel: string;
    footerHref: string;
    hex: string;
  };
};

const PointerCard = ({ className, icon, subtitle, title, href, product, color }: Props) => (
  <div className={twMerge('shadow-m flex flex-col gap-4 rounded-md bg-white px-4 py-3', className)}>
    {title && (
      <Link className="flex items-center justify-between font-bold" href={href}>
        {title}
        <FontAwesomeIcon icon={faChevronRight} />
      </Link>
    )}

    {subtitle && (
      <div className="flex gap-1">
        {icon && <FontAwesomeIcon icon={icon} />}
        <span className="caption first-letter:uppercase">{subtitle}</span>
      </div>
    )}

    {product.imageSrc && (
      <div className="flex gap-4">
        {product.imageSrc && (
          <div className="h-8 w-8">
            <Image className="object-contain" src={product.imageSrc} />
          </div>
        )}
        <div className="flex flex-col">
          <span className="text-2xs leading-14 text-gray-dark">{product.eyebrow}</span>
          {product.priceInfoLoading || !product.price ? (
            <Skeleton className="h-7 w-10 pt-2" />
          ) : (
            <Price className="[&>*]:small [&>*]:font-bold" value={product.price} />
          )}
        </div>
      </div>
    )}

    {color && (
      <div className="rounded-2 flex flex-col gap-2 bg-[#FAFAFA] p-1.5">
        <span className="caption">{color.eyebrow}</span>
        <Link className="caption -ml-3 flex items-center gap-1 underline" href={color.href}>
          <Orb size="xs" style={{ background: color.hex }} />
          {color.title}
        </Link>
        <Link anchorClassName="leading-14" className="caption" href={color.footerHref}>
          {color.footerLabel} <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </div>
    )}
  </div>
);

export default PointerCard;
