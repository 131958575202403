import { event } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: true, authScope: process.env.BOSS_API_AUTH_SCOPE_SERVICE };

export const getEvents = (locale: string) => {
  return bossApi<event.Event[]>(`/service/v2/events`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

export const getEventById = (locale: string, eventId: string) => {
  return bossApi<event.Event>(`/service/v2/events/${eventId}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};
