import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface Props {
  title?: string;
  children?: ReactNode;
  className?: string;
}

const ProductOverviewCard = ({ title, children, className }: Props) => {
  return (
    <div className={twMerge('rounded-b-modal rounded-l-modal shadow-m w-full bg-white p-6', className)}>
      {title && <h1>{title}</h1>}
      {children}
    </div>
  );
};

export default ProductOverviewCard;
