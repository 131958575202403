import { useTranslation } from 'next-i18next';
import { SwiperProps } from 'swiper/react';

import { CarouselProducts, Presence, Section } from '@boss/ui';

import { ProductCardWrapper } from '..';
import { useProductsById } from '../../client-queries';
import { useProductMap, useRecentViewedProducts } from '../../hooks';

type Props = {
  className?: string;
  options?: SwiperProps;
  backgroundColor?: string;
  testId?: string;
};

const RecentlyViewedProducts = ({
  options,
  className,
  backgroundColor,
  testId = 'recently-viewed-products-section',
}: Props) => {
  const { viewedProductIds } = useRecentViewedProducts();
  const { data: recentProducts } = useProductsById(viewedProductIds);
  const { mapProductComponent } = useProductMap();
  const { t } = useTranslation('common');

  return (
    <Presence className={className} id="recently-viewed-products-presence" visible={!!recentProducts?.length}>
      {!!recentProducts?.length && (
        <Section
          backgroundColor={backgroundColor}
          content={
            <CarouselProducts
              id="related-products-carousel"
              options={options}
              title={t('product.recently.visited') ?? undefined}
            >
              {recentProducts.map(product => {
                return product ? <ProductCardWrapper {...mapProductComponent(product)} key={product.id} /> : null;
              })}
            </CarouselProducts>
          }
          testId={testId}
        />
      )}
    </Presence>
  );
};

export default RecentlyViewedProducts;
