import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useDebounce, useOnClickOutside } from 'usehooks-ts';

import { IAddress, IAddressSuggestion } from '@boss/types/b2b-b2c';
import { BaseFieldProps, InputField } from '@boss/ui';

import { useAddressById, useAutoCompleteAddress } from '../../client-queries';

export type AddressSuggestionProps = BaseFieldProps & {
  placeholder?: string;
  testId?: string;
  scope?: string;
  onSelectSuggestion?: (val: IAddress) => void;
};

/**
 * AddressSuggestion component that will do calls to BOSS api to get addresses for suggestion purposes
 * @date 25-1-2024 - 09:21:13
 */
const AddressSuggestion = ({
  className,
  placeholder,
  name,
  testId,
  onSelectSuggestion,
  scope,
  ...props
}: AddressSuggestionProps) => {
  const [internalInput, setInternalInput] = useState('');
  const [place, setPlace] = useState<IAddressSuggestion>();
  const [isOpen, setIsOpen] = useState(false);
  const debouncedInput = useDebounce(internalInput, 200);
  const debouncedPlaceId = useDebounce(place?.placeid ?? '', 200);
  const ref = useRef<HTMLUListElement | null>(null);
  const searchAddress = (val: string) => {
    setInternalInput(val);
  };

  const { data: suggestions } = useAutoCompleteAddress(debouncedInput);
  const { data: address, isLoading } = useAddressById(debouncedPlaceId);

  useEffect(() => {
    if (address && !isLoading && onSelectSuggestion) {
      onSelectSuggestion(address);
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, address]);

  useEffect(() => {
    if (suggestions && suggestions?.length > 0) {
      setIsOpen(true);
    }
  }, [suggestions]);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className={twMerge('relative', className)} data-test-id={testId}>
      <InputField
        name={scope ? `${scope}-search` : 'search'}
        onChange={function (event: ChangeEvent<HTMLInputElement>): void {
          searchAddress(event.target.value);
        }}
        placeholder={placeholder}
        required={false}
      />
      {isOpen && (
        <ul className="max-h-30 absolute left-0 right-0 top-[100%] z-50 overflow-scroll bg-white shadow-md" ref={ref}>
          {suggestions?.map((option, i) => {
            return (
              <li
                className="hover:bg-gray focus:bg-gray relative cursor-pointer select-none py-2 pl-3 pr-9 outline-none"
                key={i}
                onClick={() => setPlace(option)}
              >
                {option.description}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

AddressSuggestion.displayName = 'AddressSuggestion';

export default AddressSuggestion;
