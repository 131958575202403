import type { Nullable, SimpleImage } from '@boss/types/corporate-jobs';
import { cva } from 'class-variance-authority';
import React, { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import CorporateTabsNavigationDropdown from './CorporateTabsNavigationDropdown';
import CorporateTabsNavigationTabs from './CorporateTabsNavigationTabs';

/**
 * Represents the type of the tab item
 *
 * @export
 * @typedef {CorporateTabItem}
 * @property {string} title - The title of the tab
 * @property {IconDefinition} icon - The icon of the tab
 * @property {string} color - The colorcode given to use
 * @property {ReactNode | string} content - content given for the tab
 */
export type CorporateTabItem = {
  title?: string;
  icon?: Nullable<SimpleImage>;
  color: string;
  content: ReactNode | string;
};

/**
 * Represents the properties of the CorporateTabs component.
 *
 * @interface CorporateTabsProps
 * @typedef {CorporateTabsProps}
 * @property {CorporateTabitem[]} items - Items to be rendered inside the component.
 * @property {string} [className] - The CSS class name(s) to be applied on the tab section
 * @property {string} [contentClassName] - The CSS class name(s) to be applied on the content section
 * @property {string} [buttonClassName] - The CSS class name(s) to be applied on the button section
 */
interface CorporateTabsProps {
  items: CorporateTabItem[];
  activeItem?: number;
  className?: string;
  contentClassName?: string;
  buttonClassName?: string;
}

/**
 * Styling of the item element
 * @property {boolean} active - represents the active state of the element
 */
const ItemStyling = cva('', {
  variants: {
    active: {
      true: 'visible',
      false: 'hidden',
    },
  },
});

/**
 * Functional React component that displays an CorporateTabs component.
 * @param {CorporateTabsProps} props - The props for the CorporateTabs component.
 * @returns {JSX.Element} - The JSX representation of the CorporateTabs component.
 */
const CorporateTabs: React.FC<CorporateTabsProps> = ({
  items,
  activeItem = 0,
  className,
  contentClassName,
  buttonClassName,
}) => {
  const [currentTab, setCurrentTab] = useState<number>(activeItem);

  const usedItems = items.slice(0, 5);

  return (
    <div className={twMerge('my-4', className)}>
      <div className="hidden md:block">
        <CorporateTabsNavigationTabs
          buttonClassName={buttonClassName}
          currentTab={currentTab}
          items={usedItems}
          navigate={setCurrentTab}
        />
        <div className={twMerge('mt-4', contentClassName)}>
          {usedItems.map((item: CorporateTabItem, index: number) => {
            return (
              <div className={ItemStyling({ active: index === currentTab })} key={`content-${index}`}>
                {item.content}
              </div>
            );
          })}
        </div>
      </div>
      <CorporateTabsNavigationDropdown
        buttonClassName={buttonClassName}
        className="visible md:hidden"
        currentTab={currentTab}
        items={usedItems}
        navigate={setCurrentTab}
      />
    </div>
  );
};

export default CorporateTabs;
