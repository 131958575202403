import Repeat from '../Repeat';
import Skeleton from '../Skeleton';

type Props = {
  className?: string;
};

const ProductPanelFooterSkeleton = ({ className }: Props) => (
  <div className={className}>
    <div className="flex flex-col gap-2.5">
      <div className="flex items-center justify-between gap-2">
        <Skeleton className="h-5 w-40" />
        <Skeleton className="w-25 h-8" />
      </div>

      <Skeleton className="w-50 h-3" />
    </div>

    <Skeleton className="h-16 w-full" />

    <div className="flex flex-col gap-4 md:grid md:grid-cols-2">
      <Repeat amount={2}>
        <div className="flex gap-3">
          <Skeleton className="h-5 w-5" />
          <div className="flex flex-col gap-1">
            <Skeleton className="h-5 w-20" />
            <Skeleton className="h-5 w-20" />
          </div>
        </div>
      </Repeat>
    </div>
  </div>
);

export default ProductPanelFooterSkeleton;
