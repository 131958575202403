import { BLOCKS, Document } from '@contentful/rich-text-types';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';

import { RichText } from '@boss/rich-text';
import { ILegalInfo, IProduct } from '@boss/services';
import { Nullable } from '@boss/types/b2b-b2c';
import { Accordion, Image, Video } from '@boss/ui';

import { isB2b, showProductDocuments } from '../../utils/options';

interface Props {
  product: IProduct;
  locale: string;
  className?: string;
}

const AccordionStep = ({ label, content, locale }: { label: string; locale: string; content?: Nullable<Document> }) => {
  return content ? (
    <div className="border-b-1 border-b-gray no-underline-last mb-4 grid grid-cols-1 lg:grid-cols-5">
      <div className="col-span-2 pb-4 font-bold">{label}</div>
      <div className="col-span-3">
        <RichText
          content={content}
          mapperOptions={{ locale }}
          renderNode={{
            [BLOCKS.PARAGRAPH]: (_, children) => <p className="mb-5 mt-1">{children}</p>,
          }}
        />
      </div>
    </div>
  ) : null;
};

const getTitleOrTranslation = (title: string | undefined, translation: string) => {
  return title ?? translation;
};

const hasTextOrPicto = (legalInfo: ILegalInfo | null) => {
  return legalInfo?.legalText || legalInfo?.legalPicto;
};

const getDocuments = (product: IProduct, t: (key: string) => string) => {
  return [
    product.technicalSheet && {
      label: getTitleOrTranslation(product.technicalSheet.publicationtitle, t('documents.technicalSheet')),
      ...product.technicalSheet,
    },
    product.sustainabilityInfo && {
      label: getTitleOrTranslation(product.sustainabilityInfo.publicationtitle, t('documents.sustainabilityInfo')),
      ...product.sustainabilityInfo,
    },
    ...(product.productInformation?.length
      ? product.productInformation.map(document => ({
          ...document,
          label: getTitleOrTranslation(document.publicationtitle, t('documents.productRelatedInfo')),
        }))
      : []),
    ...(product.safetyDocument?.length
      ? product.safetyDocument.map(document => ({
          ...document,
          label: getTitleOrTranslation(document.publicationtitle, t('documents.safetyDocument')),
        }))
      : []),
  ].filter(document => !!document);
};

const getAccordionItems = (product: IProduct, locale: string, t: (key: string) => string) => {
  const accordionItems = [];
  const documents = getDocuments(product, t);

  if (product.details && !isB2b) {
    accordionItems.push({
      title: t('accordion.productdetails'),
      content: <RichText content={product.details} mapperOptions={{ locale }} />,
    });
  }
  if (product.technicalInformation) {
    accordionItems.push({
      title: t('accordion.technicalInfo'),
      content: <RichText content={product.technicalInformation} mapperOptions={{ locale }} />,
    });
  }
  if (product.dryingTimeInfo || product.cleansingProcedure || product.applications || product.usage) {
    accordionItems.push({
      title: t('accordion.howToUse'),
      isVideo: !!product.usageVideo,
      content: (
        <>
          {product.usageVideo && (
            <div className="py-6">
              <Video {...product.usageVideo} />
            </div>
          )}
          <AccordionStep content={product.applications} label={t('accordion.applications')} locale={locale} />
          <AccordionStep content={product.procedure} label={t('accordion.procedure')} locale={locale} />
          <AccordionStep content={product.usage} label={t('accordion.usage')} locale={locale} />
          <AccordionStep content={product.dryingTimeInfo} label={t('accordion.dryTime')} locale={locale} />
          <AccordionStep
            content={product.cleansingProcedure}
            label={t('accordion.cleansingProcedure')}
            locale={locale}
          />
        </>
      ),
    });
  }
  if (product.preparationVideo || product.preparation) {
    accordionItems.push({
      title: t('accordion.preparation'),
      isVideo: !!product.preparationVideo,
      content: (
        <>
          {product.preparationVideo ? (
            <div className="py-6">
              <Video {...product.preparationVideo} />
            </div>
          ) : null}
          {product.preparation ? <RichText content={product.preparation} mapperOptions={{ locale }} /> : null}
        </>
      ),
    });
  }
  if (!!documents.length && showProductDocuments) {
    accordionItems.push({
      title: t('accordion.documents'),
      content: (
        <div className="flex flex-col space-y-4">
          {documents.map((document, index) => (
            <a
              className="flex items-center justify-between py-1"
              href={document?.url}
              key={index}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="font-base md:text-lg">{document?.label}</span>
              <FontAwesomeIcon icon={faDownload} />
            </a>
          ))}
        </div>
      ),
    });
  }

  return accordionItems;
};

const ProductDetailAccordion = ({ product, locale, className }: Props) => {
  const { t } = useTranslation('product');

  const accordionItems = getAccordionItems(product, locale, t);

  return (
    <div className={className}>
      {accordionItems
        .filter(item => item.content)
        .map(item => (
          <Accordion hasVideo={item.isVideo} key={item.title} title={item.title} variant="secondary">
            {item.content}
          </Accordion>
        ))}
      {hasTextOrPicto(product.legalinfo) && (
        <Accordion isOpen={true} title={t('accordion.legalinfoLabel')} variant="secondary">
          {product?.legalinfo?.legalPicto && (
            <>
              <span className="mb-4 block font-medium">{t('legalinfo.hazardLabel')}</span>
              <div className="mb-4 grid grid-cols-4 gap-4 md:grid-cols-8">
                {product.legalinfo.legalPicto?.map(icon => (
                  <Image alt={icon} key={icon} src={`/legalpictograms/${icon}.svg`} />
                ))}
              </div>
            </>
          )}
          <p>{product.legalinfo?.legalText}</p>
        </Accordion>
      )}
    </div>
  );
};

export default ProductDetailAccordion;
