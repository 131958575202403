import { Fragment } from 'react';

import DesktopNavigationItem from './components/DesktopNavigationItem';
import FooterNavItem from './components/FooterNavItem';
import FooterWrapper from './components/FooterWrapper';
import LegalFootnotes from './components/LegalFootNotes';
import MobileNavigationItem from './components/MobileNavigationItem';
import PaymentMethods from './components/PaymentMethods';
import SocialLinks from './components/SocialLinks';

import { FooterProps } from '.';

const Footer = ({ navigationItems, legalLinks, paymentMethods, socialLinks, locale, type, form }: FooterProps) => (
  <FooterWrapper className="bg-footer-primary break-before before:bg-footer-primary break-after after:bg-footer-primary relative text-white">
    {type !== 'simple' && (
      <div className="pt-15  flex flex-col gap-10 pb-10 md:gap-20 md:py-20">
        <nav className="w-full">
          <ul className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-4 sm:gap-x-6 lg:grid-cols-6">
            {navigationItems?.map((navItem, i) => (
              <Fragment key={`${navItem.id}-${i}`}>
                <DesktopNavigationItem navItem={navItem} />
                <MobileNavigationItem boldLabel={false} isCollapsible={true} navItem={navItem} />
              </Fragment>
            ))}
          </ul>
        </nav>

        <div className="grid gap-6 text-white md:grid-cols-8 lg:grid-cols-12 lg:gap-20">
          {form && <div className="flex items-end md:col-span-4 lg:col-span-4">{form}</div>}
          {socialLinks && (
            <SocialLinks
              className="justify-center md:col-span-4 md:mb-2 md:items-end md:justify-end lg:col-span-8 lg:justify-start"
              links={socialLinks}
              locale={locale}
            />
          )}
        </div>
      </div>
    )}
    <div className="bg-footer-secondary break-before before:bg-footer-secondary break-after after:bg-footer-secondary relative flex flex-col gap-4 py-6 text-white after:z-10 md:flex-row md:items-center md:justify-between">
      {paymentMethods && <PaymentMethods paymentMethods={paymentMethods} />}
      <LegalFootnotes>
        <FooterNavItem>© colora {new Date().getFullYear()}</FooterNavItem>
        {legalLinks?.map((link, i) => {
          return (
            <li className="inline" key={`${link.id}-${i}`}>
              <span> | </span>
              <FooterNavItem className="hover:underline" link={link.href}>
                {link.label}
              </FooterNavItem>
            </li>
          );
        })}
      </LegalFootnotes>
    </div>
  </FooterWrapper>
);

export default Footer;
