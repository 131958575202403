import { UrlObject } from 'url';

import { Nullable } from '@boss/types/b2b-b2c';
import { getHrefString } from '@boss/utils';
import React, { ReactNode } from 'react';

import Link from '../Link';

type Props = {
  alternativeText?: ReactNode;
  anchorClassName?: string;
  children: ReactNode;
  className: string;
  disabled?: boolean;
  externalLink?: string;
  fileToDownload?: string;
  href?: Nullable<string | UrlObject>;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  submitButton?: boolean;
  testId?: string;
};

type Wrapper = {
  text: ReactNode;
  children: ReactNode;
};

const Wrapper = ({ text, children }: Wrapper) => {
  if (text) {
    return (
      <div className="inline-flex flex-col items-center justify-center">
        {children}
        <span className="mb-1 mt-3 block text-xs">{text}</span>
      </div>
    );
  }

  return <>{children}</>;
};

const _Button = ({
  alternativeText,
  disabled,
  onClick,
  onKeyDown,
  href,
  submitButton,
  testId = 'button',
  anchorClassName,
  fileToDownload,
  ...props
}: Props) => {
  if (onClick) {
    return (
      <Wrapper text={alternativeText}>
        <button
          aria-label="button-with-onClick"
          data-testid={testId}
          disabled={disabled}
          {...props}
          onClick={onClick}
          onKeyDown={onKeyDown}
          type={submitButton ? 'submit' : 'button'}
        >
          {props.children}
        </button>
      </Wrapper>
    );
  }

  if (fileToDownload) {
    return (
      <Wrapper text={alternativeText}>
        <Link
          anchorClassName={anchorClassName}
          aria-label="button-with-download"
          download
          href={fileToDownload}
          onKeyDown={onKeyDown}
          testId={testId}
          {...props}
        >
          {props.children}
        </Link>
      </Wrapper>
    );
  }

  return (
    <Wrapper text={alternativeText}>
      <Link
        anchorClassName={anchorClassName}
        aria-label={`button-with-href-to-${getHrefString(href) ?? '/'}`}
        href={href ?? '/'}
        onKeyDown={onKeyDown}
        testId={testId}
        {...props}
      >
        {props.children}
      </Link>
    </Wrapper>
  );
};

export default _Button;
