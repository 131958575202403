import * as Sentry from '@sentry/nextjs';

import { serviceRequest as serviceRequestService } from '@boss/services/client';

const saveServiceRequest = async (data: Record<string, unknown>) => {
  try {
    return await serviceRequestService.saveServiceRequest(data);
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Save Service Request',
      },
    });

    // Needs a re-throw
    throw error;
  }
};

export { saveServiceRequest };
