import { Image, Repeat } from '../../';

const StripeBackground = () => (
  <div className="absolute bottom-0 left-0 ml-[calc((100vw-100%)/-2)] h-full w-[100vw] overflow-x-hidden bg-left">
    <Image
      alt="footer-stripe"
      className="-mx-20 w-[calc(100vw+200px)] max-w-[calc(100vw+200px)] object-fill md:hidden"
      height={600}
      src="/stripes/b2b-footer-stripe.png"
      useNext
      width={787}
    />
    <div className="hidden h-full md:flex lg:hidden [&>*:nth-child(even)]:scale-x-[-1]">
      <Repeat amount={2}>
        <Image
          alt="footer-stripe"
          className="-mx-20 object-fill"
          height={700}
          src="/stripes/b2b-footer-stripe.png"
          useNext
          width={800}
        />
      </Repeat>
    </div>
    <div className="hidden h-full lg:flex [&>*:nth-child(even)]:scale-x-[-1]">
      <Repeat amount={3}>
        <Image
          alt="footer-stripe"
          className="-mx-20 w-1/2 object-fill"
          height={600}
          src="/stripes/b2b-footer-stripe.png"
          useNext
          width={700}
        />
      </Repeat>
    </div>
  </div>
);

export default StripeBackground;
