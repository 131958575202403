export const mapNextToContentfulLocale = (locale: string) => {
  switch (locale) {
    case 'fr':
      return 'fr-BE';
    case 'nl-NL':
      return 'nl-NL';
    case 'nl':
      return 'nl-BE';
    default:
      return locale;
  }
};

export const mapContentfulToNextLocale = (locale: string) => {
  switch (locale) {
    case 'fr-BE':
      return 'fr';
    case 'nl-NL':
      return 'nl-NL';
    case 'nl-BE':
      return 'nl';
    default:
      return locale;
  }
};
