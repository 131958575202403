import { IStore } from '@boss/services';

import { Options } from '../options/default';

/**
 * Utility function that puts stores that match with the given prefix to the front of the list.
 * @returns {array} returns an array of sorted stores
 * @param {IStore[]} stores A list of stores
 * @property {string} storeType Value used to prioritise those stores. E.g: passing Colora will make sure the first items in the returned list are Colora stores.
 */
export const prioritiseStoresByStoreType = (stores: IStore[], storeType: IStore['storetype']) => {
  const priorityStores: IStore[] = [];
  const regularStores: IStore[] = [];

  stores?.forEach(store => {
    if (store.storetype === storeType) {
      return priorityStores.push(store);
    }

    return regularStores.push(store);
  });

  return priorityStores.concat(regularStores);
};

/**
 * Utility function that excludes stores of which the name starts with the given prefix
 * @returns {array} returns an array of sorted stores
 * @param {IStore[]} stores A list of stores
 * @property {string} prefix Stores of which the name matches with this value get excluded from the list
 */
const excludeStoresByPrefix = (stores: IStore[], prefix: string) =>
  stores.filter(store => !store.name.toLowerCase()?.startsWith(prefix.toLowerCase()));

/**
 * Utility function that returns a list of stores with the name lowercased if the name starts with the given prefix
 * @returns {array} returns an array of stores
 * @param {IStore[]} stores A list of stores
 * @property {string} prefix Stores of which the name starts with this value gets their name lowerCased
 */
const lowerCaseMatchingStores = (stores: IStore[], prefix: string) =>
  stores.map(store => {
    if (store.name.toLowerCase().startsWith(prefix.toLowerCase())) {
      return { ...store, name: store.name.toLowerCase() };
    }

    return store;
  });

/**
 * Utility function that handles the custom stores business logic.
 * By default, it returns a list of stores alphabetically sorted by name
 * Passing the optional config object enables additional sorting and exclusion of certain stores
 * @returns {array} returns an array of sorted stores
 * @param {IStore[]} stores A list of stores
 * @property {Options['storeConfig']} config Options used to apply additional sorting or filtering. See the Options['storeConfig'] type for additional config options.
 */
export const handleStores = (stores: IStore[], config?: Options['storeConfig']) => {
  let mappedStores = stores?.sort(sortAlpha);

  if (!config) {
    return mappedStores;
  }

  if (config.excludeNamePrefix) {
    mappedStores = excludeStoresByPrefix(stores, config.excludeNamePrefix);
  }

  if (config.sortPriorityStoreType) {
    mappedStores = prioritiseStoresByStoreType(mappedStores, config.sortPriorityStoreType);
  }

  if (config.lowerCasePrefix) {
    mappedStores = lowerCaseMatchingStores(mappedStores, config.lowerCasePrefix);
  }

  if (config?.fetchOnlyType) {
    mappedStores = mappedStores.filter(shop => shop.storetype === config.fetchOnlyType);
  }

  if (config?.shopsOnly) {
    mappedStores = mappedStores.filter(shop => shop.isshop);
  }

  if (config?.filterTypesOut?.length) {
    const excluded = config.filterTypesOut;

    mappedStores = mappedStores.filter(shop => !excluded.includes(shop.storetype));
  }

  if (config?.orderDepotFirst) {
    mappedStores = mappedStores.sort(sortByStoreType);
  }

  return mappedStores;
};

const sortAlpha = (a: IStore, b: IStore) => a.name?.toLowerCase().localeCompare(b.name.toLowerCase());

/**
 * Sort shops in alphabetical groups with "Depot" first, "Colora" second and "Geen" last
 */
const sortByStoreType = (a: IStore, b: IStore) => {
  if ((a.storetype === 'Depot' && b.storetype !== 'Depot') || (a.storetype === 'Colora' && b.storetype === 'Geen')) {
    return -1;
  }

  if ((a.storetype !== 'Depot' && b.storetype === 'Depot') || (a.storetype === 'Geen' && b.storetype === 'Colora')) {
    return 1;
  }

  return sortAlpha(a, b);
};
