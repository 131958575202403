import { SwiperProps } from 'swiper/react';

import { SCREENS } from '@boss/constants/b2b-b2c';

export const CAROUSEL_SETTINGS = {
  navigation: true,
  slidesPerView: 1.2,
  breakpoints: {
    [SCREENS.md]: {
      slidesPerView: 2.4,
    },
    [SCREENS.lg]: {
      slidesPerView: 4,
    },
  },
} as SwiperProps;

export const DASHBOARD_CAROUSEL_SETTINGS = {
  navigation: true,
  slidesPerView: 1.2,
  breakpoints: {
    [SCREENS.md]: {
      slidesPerView: 1.4,
    },
    [SCREENS.lg]: {
      slidesPerView: 3,
    },
  },
};

export const B2B_CAROUSEL_SETTINGS = {
  slidesPerView: 2,
  breakpoints: {
    [SCREENS.lg]: {
      slidesPerView: 4,
    },
  },
};
