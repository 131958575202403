import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import { ColorGroupButtonProps } from '..';
import { Orb } from '../..';

const SecondaryColorGroupButton = ({ active, className, backgroundColor, name, onClick }: ColorGroupButtonProps) => (
  <button
    className={twMerge(
      'border-1 flex w-full items-center justify-between gap-3 rounded-full px-5 py-3',
      active ? '' : 'border-transparent',
      className,
    )}
    onClick={onClick}
    style={{ borderColor: active ? backgroundColor : '' }}
  >
    <Orb className="shadow-sm" size="sm" style={{ backgroundColor: backgroundColor }} />
    <span className="caption">{name}</span>
    <FontAwesomeIcon className={active ? 'invisible' : ''} icon={faChevronRight} size="xs" />
  </button>
);

export default SecondaryColorGroupButton;
