import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { toast } from 'sonner';

import { saveServiceRequest } from './connector';
import { useProfile } from '../../hooks';

const useSaveServiceRequest = () => {
  const { t } = useTranslation('common');
  const { data: profile } = useProfile();

  return useMutation({
    mutationFn: async (formData: Record<string, unknown>) => {
      if (!profile?.extension_ContactPersonId) {
        throw new Error('Profile not found');
      }

      await saveServiceRequest({
        ...formData,
        contactpersonid: profile.extension_ContactPersonId as string,
      });
    },
    onError: () => {
      toast.error(t('serviceRequest.error'));
    },
  });
};

export { useSaveServiceRequest };
