import { cva } from 'class-variance-authority';
import { CSSProperties, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Skeleton from '../Skeleton';

export const OrbStyles = cva('flex rounded-full shrink-0', {
  variants: {
    size: {
      xl: 'w-20 h-20',
      lg: 'w-14 h-14',
      md: 'w-10 h-10',
      sm: 'w-8 h-8',
      xs: 'w-4 h-4',
      xxs: 'w-2 h-2',
    },
  },
});

type Props = {
  size?: 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs';
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  loading?: boolean;
};

const Orb = ({ loading, size = 'sm', style, className, children }: Props) => {
  if (loading) {
    return <Skeleton className={twMerge('[&>*]:rounded-full', OrbStyles({ size }), className)} style={style} />;
  }

  return (
    <div className={twMerge(OrbStyles({ size }), className)} style={style}>
      {children}
    </div>
  );
};

export default Orb;
