import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

export interface Props {
  label: string;
  subtext: string;
  className?: string;
  icon?: IconDefinition;
  onClick: () => void;
  variant?: 'mobile' | 'desktop';
}

const DiscountButtonStyles = cva('flex w-full shadow-md cursor-pointer px-6 py-3 bg-white', {
  variants: {
    variant: {
      mobile: 'rounded-modal',
      desktop: 'rounded-full',
    },
  },
});

const DiscountButton = ({ label, icon, subtext, className, onClick, variant = 'mobile' }: Props) => {
  return (
    <button className={twMerge(DiscountButtonStyles({ variant }), className)} onClick={onClick}>
      {icon && <FontAwesomeIcon className="text-blue mr-3 mt-1" icon={icon} size="sm" />}
      <div>
        <p className="small text-blue font-bold">{label}</p>
        {subtext && <p className="small text-gray-500">{subtext}</p>}
      </div>
    </button>
  );
};

export default DiscountButton;
