import { useMutation, useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { toast } from 'sonner';

import { IEvent } from '@boss/services/client';

import { fetchEventById, fetchEvents, participateEvent } from './connector';
import { useProfile } from '../../hooks';

import { eventKeys } from './index';

const useEvents = () => {
  const { locale } = useRouter();

  return useQuery<IEvent[]>(eventKeys.allEvents(locale as string), async () => await fetchEvents(locale as string));
};

const useEventById = (eventId: string) => {
  const { locale } = useRouter();

  return useQuery<IEvent | null>(
    eventKeys.eventById(locale as string, eventId),
    async () => await fetchEventById(locale as string, eventId),
  );
};

const useParticipateEvent = () => {
  const { t } = useTranslation('common');
  const { data: profile } = useProfile();

  return useMutation({
    mutationFn: async ({ eventId, formData }: { eventId: string; formData: Record<string, unknown> }) =>
      await participateEvent(eventId, {
        ...formData,
        contactpersonid: profile?.extension_ContactPersonId,
      }),
    onError: () => {
      toast.error(t('participate.error', { ns: 'event' }));
    },
  });
};

export { useEvents, useParticipateEvent, useEventById };
