import { ButtonProps } from '@boss/types/b2b-b2c';
import { SimpleImage } from '@boss/types/corporate-jobs';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import Button from '../Button';
import Image from '../Image';

/**
 * Represents the properties of the JobBlockProps
 * @property {'b2c' | 'b2b'} variant - The variant of the banner
 */
export type JobBlockProps = {
  cta: ButtonProps;
  title: string;
  location?: string;
  className?: string;
} & ({ variant: 'b2c' } | { variant: 'b2b'; image?: SimpleImage });

/**
 * Styling of the JobBlock wrapping container
 * @date 8-1-2024 - 15:15:02
 * @property {'b2b' | 'b2c'} variant - Variant styling of the banner
 * @property {boolean} hasImage - Does the block contain an image
 */
const BannerStyles = cva('grid gap-4 border-b-1', {
  variants: {
    variant: {
      b2b: 'py-4 grid-cols-5 md:grid-cols-6',
      b2c: 'grid-cols-3 py-6',
    },
  },
});

const ContentStyles = cva(
  'flex flex-col flex-col-reverse md:flex-row items-center justify-center md:justify-space-between',
  {
    variants: {
      variant: {
        b2b: 'col-span-4',
        b2c: 'flex-row-reverse col-span-2',
      },
    },
  },
);

const IconStyles = cva('', {
  variants: {
    variant: {
      b2b: 'text-green',
      b2c: 'text-blue',
    },
  },
});

const TitleStyles = cva('w-full md:w-[50%]', {
  variants: {
    variant: {
      b2b: 'font-bold text-lg',
      b2c: '',
    },
  },
});

const LocationStyles = cva('mb-2 md: mb-0 flex w-full md:w-[50%] items-center gap-4', {
  variants: {
    variant: {
      b2b: '',
      b2c: 'font-bold text-lg',
    },
  },
});

const JobBlock: React.FC<JobBlockProps> = Props => {
  const { cta, variant, title, location, className } = Props;

  /**
   * Needs separate checking, since the prop is not always given. Direct destructuring would not work
   * @type {boolean}
   */
  const hasImage = 'image' in Props;

  return (
    <div className={twMerge(BannerStyles({ variant }), className)}>
      {variant === 'b2b' && (
        <div className="aspect-square max-w-[120px] md:aspect-[3/2]">
          {hasImage && (
            <Image alt={Props.image?.imageAlt ?? ''} className="object-contain" src={Props.image?.imageUrl} />
          )}
        </div>
      )}
      <div className={ContentStyles({ variant })}>
        <div className={LocationStyles({ variant })}>
          {location && <FontAwesomeIcon className={IconStyles({ variant })} icon={faLocationDot} width={16} />}
          {location}
        </div>
        <div className={TitleStyles({ variant })}>{title}</div>
      </div>
      <div className="col-span-5 flex w-full items-center justify-end md:col-span-1">
        <Button {...cta} />
      </div>
    </div>
  );
};

export default JobBlock;
