import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Tooltip from '../Tooltip';

export type BaseFieldProps = {
  className?: string;
  disclaimer?: ReactNode;
  error?: string;
  label?: ReactNode;
  name: string;
  required?: boolean;
  touched?: boolean;
  tooltip?: ReactNode;
  id?: string;
  variant?: 'light' | 'dark' | 'transparent';
};

type Props = BaseFieldProps & {
  children: ReactNode;
};

export const FieldTooltipWrapper = ({ tooltip, children }: { tooltip?: ReactNode; children: ReactNode }) => {
  if (!tooltip) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      buttonClassName="bottom-auto left-auto -top-1 -right-4"
      className="w-fit"
      content={tooltip}
      floatingClassName="max-w-4/5 md:max-w-1/2"
    >
      {children}
    </Tooltip>
  );
};

const LabelStyling = cva('mb-1 flex', {
  variants: {
    variant: {
      light: 'text-gray-700',
      dark: '',
      transparent: '',
    },
  },
});

const FormFieldWrapper = ({
  tooltip,
  children,
  className,
  touched,
  error,
  label,
  name,
  disclaimer,
  required,
  variant,
  id,
}: Props) => {
  return (
    <div className={twMerge('w-full', className)}>
      {label && (
        <FieldTooltipWrapper tooltip={tooltip}>
          <label className={LabelStyling({ variant })} htmlFor={id ?? name}>
            {label}
            {required ? '*' : ''}
          </label>
        </FieldTooltipWrapper>
      )}
      {children}
      {!!touched && !!error && <div className="mt-1 text-sm text-red-500">{error}</div>}
      {disclaimer && typeof disclaimer === 'string' ? (
        <span className="small mt-1 italic">{disclaimer}</span>
      ) : (
        disclaimer
      )}
    </div>
  );
};

export default FormFieldWrapper;
