import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { login } from '@boss/auth';
import { accountPageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { Link } from '@boss/ui';

import { useFormField } from '../../hooks';
import DynamicForm, { FormValues } from '../DynamicForm';

const LoginForm = () => {
  const { t } = useTranslation(['forms', 'login']);
  const { locale, push, query } = useRouter();
  const [isError, setIsError] = useState(false);
  const { email, password } = useFormField({ enableInitialValues: false });

  const fields = [
    { ...email, placeholder: t('placeholders.email') ?? '' },
    {
      ...password,
      placeholder: t('placeholders.passwordAlt') ?? '',
      disclaimer: (
        /* TODO: Add support for forgotten passwords */
        <Link className="small text-blue-dark mt-4 flex underline" href="#">
          {t('disclaimers.passwordForgotten')}
        </Link>
      ),
    },
  ];

  const handleSubmit = async (vals: FormValues) => {
    try {
      await login({ username: vals.email?.toString(), password: vals.password?.toString() });

      const redirectValue = query.redirect;
      const url = typeof redirectValue === 'string' ? redirectValue : redirectValue?.join('');

      push(url ?? accountPageSlugs[locale]);
    } catch (error) {
      setIsError(true);
    }
  };

  return (
    <DynamicForm
      buttonProps={{
        className: 'h-12 w-full justify-center text-center md:w-auto [&>*]:w-auto md:self-start',
        label: t('buttons.login') ?? '',
        iconPosition: 'right',
        icon: faArrowRight,
      }}
      errorSubtitle={t('existingClientCard.error.subtitle', { ns: 'login' }) ?? ''}
      errorTitle={t('existingClientCard.error.title', { ns: 'login' }) ?? ''}
      fields={fields}
      formFieldsWrapperClassName="flex flex-col gap-4 md:gap-6"
      id="login-form"
      isError={isError}
      onSubmit={handleSubmit}
    />
  );
};

export default LoginForm;
