import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { Button, InputField } from '@boss/ui';

interface ListNameEditProps {
  listName?: string;
  setListName: (arg0: string) => void;
  updateListName: () => void;
  editMode: boolean;
  setEditMode: (arg0: boolean) => void;
  listDescription: string;
}

const ListNameEdit = ({
  listName,
  setListName,
  updateListName,
  editMode,
  setEditMode,
  listDescription,
}: ListNameEditProps) => {
  const { t } = useTranslation('account', { keyPrefix: 'list.detailPage' });

  return (
    <div className="flex gap-3">
      {!editMode ? (
        <>
          <h1 className="whitespace-wrap overflow-hidden text-ellipsis">{listDescription}</h1>
          <button className="text-gray h-[100%] md:whitespace-nowrap" onClick={() => setEditMode(true)}>
            {t('editListName')} <FontAwesomeIcon className="ml-1" icon={faPenToSquare} />
          </button>
        </>
      ) : (
        <>
          <InputField
            className="w-1/2"
            name="list-name"
            onBlur={updateListName}
            onChange={e => setListName(e.target.value)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                updateListName();
              } else if (event.key === 'Escape') {
                setListName(listName ?? '');
                setEditMode(false);
              }
            }}
            value={listName}
            variant="light"
          />
          <Button
            className="py-2.75 h-full"
            disabled={!listName || listName === listDescription}
            label={t('saveListname')}
            onClick={updateListName}
            type="primary"
          />
        </>
      )}
    </div>
  );
};

export default ListNameEdit;
