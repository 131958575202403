import { format, formatRFC3339 } from 'date-fns';
import { useTranslation } from 'next-i18next';

import { IProduct } from '@boss/services';
import { Alert } from '@boss/ui';

import { AMOUNT_OF_DELIVERY_DATES_TO_SHOW, DEFAULT_DATE_FORMAT, INFO_TEXTAREA_ROWS } from '../../constants';
import { FormType, useFormField, useFormSubmit } from '../../hooks';
import { buildFormFields, getXAmountOfBusinessDays } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

const FORM_FIELD_KEYS = [
  'deliverymethod',
  'deliverydate',
  'clientnumber',
  'name',
  'phonenumber',
  'email',
  'orderreference',
  'orderinfo',
  'termsandconditions',
] as const;

type FormFieldKeys = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKeys]: Partial<FormField>;
};

type Props = {
  fieldsToShow?: FormFieldKeys[];
  fieldsOverwrite?: FieldOverwrite;
  additionalFields?: FormField[];
  className?: string;
  type?: FormType;
  product?: IProduct;
};

const deliveryMethodKeys = [
  'deliveryPermanentAddress',
  'pickupBossPaints',
  'pickupColora',
  'deliveryColora',
  'deliverySiteAddress',
];

const SpecialOrderForm = ({
  fieldsToShow: initialFieldsToShow,
  fieldsOverwrite,
  additionalFields,
  className,
  type = 'special-order',
  product,
}: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { onSubmit, isSubmitting, isSuccess, isError } = useFormSubmit();
  const { t } = useTranslation('forms');
  const { clientnumber, name, phonenumber, email, termsandconditions, orderreference } = useFormField();

  const deliveryMethodLabels: Record<string, string> = t('select.specialOrderDeliveryMethodOptions', {
    returnObjects: true,
  });
  const deliveryMethodOptions = deliveryMethodKeys.map(key => ({ value: key, label: deliveryMethodLabels[key] }));

  const today = new Date();
  const businessDays = getXAmountOfBusinessDays(today, AMOUNT_OF_DELIVERY_DATES_TO_SHOW);
  const dateOptions = businessDays.map(date => ({
    label: format(date, DEFAULT_DATE_FORMAT),
    value: formatRFC3339(date),
  }));

  const deliverymethod: FormField = {
    name: 'deliverymethod',
    type: 'select',
    options: deliveryMethodOptions,
    initialValue: deliveryMethodOptions[0].value,
  };
  const deliverydate: FormField = {
    name: 'deliverydate',
    type: 'select',
    options: dateOptions,
    initialValue: dateOptions[0].value,
  };
  const orderinfo: FormField = {
    name: 'orderinfo',
    label: t('fields.yourOrder') ?? '',
    type: 'textarea',
    initialValue:
      t('defaultValues.yourOrder', {
        cat: product?.categories[0]?.name ?? '',
        name: product?.name,
        articleNumber: product?.id,
        description: product?.description,
      }) ?? '',
    rows: INFO_TEXTAREA_ROWS,
  };

  const baseFields: FormField[] = [
    deliverymethod,
    deliverydate,
    clientnumber,
    name,
    phonenumber,
    email,
    orderreference,
    orderinfo,
    termsandconditions,
  ];

  const handleSubmit = (vals: FormValues) => onSubmit(type, vals);

  return (
    <>
      <DynamicForm
        className={className}
        fields={buildFormFields(baseFields, fieldsToShow, additionalFields, fieldsOverwrite)}
        id={type}
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        variant="light"
      />
      {isSuccess && (
        <Alert className="mt-5" iconSize="xl" title={t('success.title')} type="confirm">
          {t('success.subtitle')}
        </Alert>
      )}
      {isError && (
        <Alert className="mt-8" iconSize="xl" title={t('errors.submitTitle')} type="error">
          {t('errors.submitSubtitle')}
        </Alert>
      )}
    </>
  );
};

export default SpecialOrderForm;
