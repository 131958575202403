/**
 * Runtime cached well known cache
 */

type Response = {
  authorization_endpoint: string;
  token_endpoint: string;
  end_session_endpoint: string;
  jwks_uri: string;
};

type WellKnown = {
  authorizationUri: string;
  tokenUri: string;
  logoutUri: string;
  jwksUri: string;
};

let __promise: Promise<WellKnown> | null = null;
const __cache: Record<string, WellKnown> = {};

/**
 * Transform the response
 */
const transform = (response: Response): WellKnown => ({
  authorizationUri: response.authorization_endpoint,
  tokenUri: response.token_endpoint,
  logoutUri: response.end_session_endpoint,
  jwksUri: response.jwks_uri,
});

/**
 * A well known exception
 */
export class WellKnownException extends Error {
  /**
   * Creates a WellKnown Fetch Exception
   *
   * @param {string} The well known uri
   */
  constructor(uri: string) {
    super(`Could not fetch the well known items from the IDP: ${uri}`);
  }
}

/**
 * Creates a getter. To be used in different implementations
 *
 * Get the well known values
 *
 * Will use a runtime cache
 */
export const createGetter = (wellKnownUri: string) => async (): Promise<WellKnown> => {
  if (__cache?.[wellKnownUri]) {
    return __cache[wellKnownUri];
  }

  if (!__promise) {
    __promise = fetch(wellKnownUri)
      .then(result => result.json())
      .then(result => transform(result))
      .catch(() => {
        throw new WellKnownException(wellKnownUri);
      });
  }

  const result = await __promise;

  if (result) {
    __cache[wellKnownUri] = result;
  }

  __promise = null;
  return result;
};
