import React from 'react';
import { twMerge } from 'tailwind-merge';
type Props = {
  items?: Array<CharacteristicsObject>;
  className?: string;
};

type CharacteristicsObject = {
  label?: string;
  value?: string;
};

const Characteristics = ({ items, className }: Props) => {
  return (
    <div
      className={twMerge(
        `flex flex-wrap md:flex-nowrap ${items && items?.length > 2 ? 'justify-between' : 'md:gap-20'}`,
        className,
      )}
    >
      {items?.map(item => (
        <div className="mb-4 flex w-1/2 flex-col text-center md:mb-0 md:w-fit" key={item?.label}>
          <span className="text-brown-dark h4 md:mb-4">{item?.label}</span>
          <span className="medium font-light">{item?.value}</span>
        </div>
      ))}
    </div>
  );
};

export default Characteristics;
