import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Image, Link } from '..';

type Props = {
  text: ReactNode;
  imageSrc: string;
  imageAlt: string;
  className?: string;
  variant?: 'primary' | 'secondary';
  href?: string;
};

const LinkStyling = cva('h-full flex w-full', {
  variants: {
    variant: {
      primary: 'sm:pt-12.5',
      secondary: '',
    },
  },
});

const CardWrapperStyling = cva('bg-promotion-cardBackground h-full w-full', {
  variants: {
    variant: {
      primary: 'flex flex-col justify-between p-8 xl:p-10 xl:pb-0 pb-0 rounded-30 rounded-tl-none',
      secondary: 'justify-between px-5 py-8 rounded-md flex gap-2 sm:flex-col-reverse',
    },
  },
});

const ImageWrapperStyling = cva('', {
  variants: {
    variant: {
      primary: 'w-1/2 self-end -mt-15 -mx-4 sm:w-full sm:m-0',
      secondary: 'flex self-start w-1/3 sm:self-end sm:-mt-17.5 sm:w-1/2',
    },
  },
});

const TextWrapperStyling = cva('text-white hyphens-auto	', {
  variants: {
    variant: {
      primary: 'w-2/3 sm:w-full',
      secondary: 'w-2/3 mt-4 sm:w-full',
    },
  },
});

const PromotionCard = ({ href, text, imageSrc, imageAlt, className, variant = 'primary' }: Props) => {
  if (!imageSrc && !text) {
    return null;
  }

  return (
    <Link className={twMerge(LinkStyling({ variant }), className)} href={href}>
      <div className={twMerge(CardWrapperStyling({ variant }))}>
        {text && <div className={TextWrapperStyling({ variant })}>{text}</div>}
        {imageSrc && (
          <div className={ImageWrapperStyling({ variant })}>
            <Image alt={imageAlt} className="flex object-contain" height={300} src={imageSrc} width={300} />
          </div>
        )}
      </div>
    </Link>
  );
};

export default PromotionCard;
