import { twMerge } from 'tailwind-merge';

import { ColorGroupButtonProps } from '..';
import { Orb } from '../../';

const PrimaryColorGroupButton = ({ active, className, backgroundColor, name, onClick }: ColorGroupButtonProps) => (
  <button
    className={twMerge(
      'border-1 flex w-full items-center gap-3 rounded-md p-3',
      active ? 'border-blue-dark' : 'border-gray-light',
      className,
    )}
    onClick={onClick}
  >
    <Orb className="shadow-sm" size="sm" style={{ backgroundColor: backgroundColor }} />
    <span className="caption">{name}</span>
  </button>
);

export default PrimaryColorGroupButton;
