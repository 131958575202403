import { event } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false };
const HEADERS = { 'Content-Type': 'application/json' };

export const getEvents = (locale: string) => {
  return bossApi<event.Event[]>(`/service/v2/events`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

export const participateEvent = (eventId: string, data: Record<string, unknown>) => {
  return bossApi(`/service/v2/events/${eventId}/participants`, {
    ...DEFAULT_OPTIONS,
    headers: HEADERS,
    method: 'POST',
    body: data,
  });
};

export const getEventById = (locale: string, eventId: string) => {
  return bossApi<event.Event>(`/service/v2/events/${eventId}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};
