import { ImageProps } from '@boss/types/b2b-b2c';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import Image from '../Image';

type Props = {
  image: ImageProps;
  caption?: string;
  variant: 'primary' | 'secondary';
};

const imageStyle = cva(' md:max-h-full h-87.5 xl:w-full object-cover  mb-2.5', {
  variants: {
    variant: {
      primary: 'rounded-tl-md rounded-b-md md:rounded-50 md:rounded-bl-none',
      secondary: '',
    },
  },
});

const captionStyle = cva('small', {
  variants: {
    variant: {
      primary: 'text-brown-dark',
      secondary: 'text-gray-dark',
    },
  },
});

const ImageWithCaption = ({ image, caption, variant }: Props) => {
  return (
    <>
      <Image {...image} className={twMerge(imageStyle({ variant }))} />
      {caption && <span className={twMerge(captionStyle({ variant }))}>{caption}</span>}
    </>
  );
};

export default ImageWithCaption;
