import * as Sentry from '@sentry/nextjs';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { toast } from 'sonner';

import { IOrder } from '@boss/types/b2b-b2c';

import { OrderParams, confirmInvoiceOrder, getOrderById } from './connector';

import { orderKeys } from '.';

/**
 * useMutation implementation to confirm the invoice order
 * @returns {object} An object with a mutate function and mutation status
 */
const useConfirmInvoiceOrder = () => {
  const { t } = useTranslation('common');

  return useMutation({
    mutationFn: async (params: OrderParams) => {
      return confirmInvoiceOrder(params);
    },
    onError: () => {
      toast.error(t('toast.confirmInvoiceOrder.error.title'));
    },
  });
};

/**
 * useQuery implementation to get order by id
 * @returns {object} An object with a data and status
 * @param orderId
 */
const useGetOrderById = (orderId: string) => {
  const { locale } = useRouter();

  return useQuery<IOrder | null>({
    queryKey: orderKeys.order(locale as string, orderId),
    queryFn: async () => {
      return await getOrderById(orderId, locale as string);
    },
    enabled: !!orderId,
    onError: e => {
      console.error(e);
      Sentry.captureException(e, {
        tags: {
          type: 'Fetch client side  order by id',
        },
      });
    },
  });
};

export { useConfirmInvoiceOrder, useGetOrderById };
