import { SimpleImage } from '@boss/types/b2b-b2c';

type PaymentProps = {
  paymentMethods: SimpleImage[];
};

const PaymentMethods = ({ paymentMethods }: PaymentProps) => (
  <div className="col-span-full md:col-span-4 md:col-start-2">
    <ul className="gap-3.75 flex flex-row items-center">
      {paymentMethods.map((paymentMethod, i) => (
        <li key={`${paymentMethod.imageAlt}-${i}`}>
          {/* TODO - Swap out with future image component */}
          <img
            alt={paymentMethod.imageAlt}
            className="h-6"
            onContextMenu={e => e.preventDefault()}
            src={paymentMethod.imageUrl}
          />
        </li>
      ))}
    </ul>
  </div>
);

export default PaymentMethods;
