enum PageNames {
  MAIN_CATEGORY_OVERVIEW = 'mainCategoryOverview',
}

enum PageOptions {
  BREADCRUMB_VISIBILITY = 'breadcrumbVisibility',
}

enum PageTypes {
  ACCOUNT = 'Account',
  BASKET = 'Basket',
  CATEGORY_DETAIL = 'Category detail',
  CATEGORY_OVERVIEW = 'Category overview',
  CHECKOUT = 'Checkout',
  CHECKOUT_CONFIRMATION = 'Checkout confirmation',
  COLOR_DETAIL = 'Color detail',
  COLOR_OVERVIEW = 'Color overview',
  COLOR_TOOL = 'Color tool',
  CONTENT = 'Content page',
  EVENT_DETAIL = 'Workshop detail',
  EVENT_OVERVIEW = 'Workshop overview',
  INSPIRATION_DETAIL = 'Inspiration detail',
  INSPIRATION_OVERVIEW = 'Inspiration overview',
  NOT_FOUND = 'Not found page',
  OVERVIEW_PAGE = 'Overview page',
  PAINTGUIDE_OVERVIEW = 'Paint guide overview',
  PAINTGUIDE_RESULT = 'Paint guide result',
  PRODUCT_DETAIL = 'Product detail',
  PRODUCT_OVERVIEW = 'Product overview',
  ROADMAP_DETAIL = 'Roadmap detail',
  ROADMAP_OVERVIEW = 'Roadmap overview',
  SEARCH = 'Search',
  STORE_DETAIL = 'Store detail',
  STORE_OVERVIEW = 'Store overview',
}

export { PageNames, PageOptions, PageTypes };
