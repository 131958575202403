import { useEffect, useState } from 'react';

import { types } from '@boss/boss-client';

import { usePointsOfSales } from '../../client-queries';
import { handleStores, storeConfig } from '../../utils';

/**
 * Central point of sales hook that returns generic point of sales related functions and constants for consistent behaviour
 * @returns {object} returns an object containing the following properties:
 * @property {boolean} isLoading Are the stores still loading?
 * @property {Store[]} stores List of stores alphabetically sorted by names
 */
export function usePointOfSale(deliverymethod?: number) {
  const { data, isLoading } = usePointsOfSales(deliverymethod ?? 1);
  const [stores, setStores] = useState<types.Store[]>([]);

  useEffect(() => {
    if (isLoading || !data) {
      return;
    }

    setStores(handleStores(data, storeConfig));
  }, [isLoading, data]);

  return { isLoading, stores };
}

export default usePointOfSale;
