import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  icon: IconDefinition;
  iconColor?: string;
};

const SocialShareIconWrapper = ({ icon, iconColor }: Props) => (
  <div
    className="flex h-6 w-6 items-center justify-center rounded-full p-1"
    style={{ backgroundColor: iconColor ?? 'currentColor' }}
  >
    <FontAwesomeIcon className="text-l text-white" height={14} icon={icon} width={14} />
  </div>
);

export default SocialShareIconWrapper;
