import { twMerge } from 'tailwind-merge';

import { ColorButtonProps } from '..';
import { Orb, PaintDrop, Skeleton } from '../..';
import FavoriteButton from '../../FavoriteButton';
import Link from '../../Link';

const SecondaryColorButton = ({
  active,
  className,
  backgroundColor,
  id,
  isFavorite,
  name,
  onClick,
  onFavorite,
  translations,
  trending,
  loading,
  onClickAction,
  slug,
  colorType,
  trackInfo,
}: ColorButtonProps) => {
  if (loading) {
    return (
      <div className={twMerge('flex flex-col items-center gap-3', className)}>
        <Orb className="shadow-lg" loading size="xl" />
        <div className="flex flex-col items-center gap-2">
          <Skeleton className="w-15 h-3" />
          <Skeleton className="h-3 w-10" />
        </div>
      </div>
    );
  }

  return (
    <div className={twMerge('relative w-full', className)}>
      <Link
        className="flex	flex-col items-center gap-3"
        href={onClickAction === 'navigate' ? slug : undefined}
        onClick={onClick}
        trackInfo={trackInfo}
      >
        <div className="relative">
          <div className={twMerge('border-1 rounded-full p-1', active ? 'border-blue-dark' : 'border-transparent')}>
            <PaintDrop colorCode={backgroundColor} colorType={colorType} />
          </div>
          {trending && translations && (
            <span className="caption bg-blue absolute bottom-0 left-1/2 -translate-x-1/2 rounded-lg px-1.5 font-bold text-white">
              {translations.trending}
            </span>
          )}
        </div>
        {(id || name) && (
          <div className="flex flex-col items-center">
            {id && <span className="caption text-center">{id}</span>}
            {name && <span className="caption text-center">{name}</span>}
          </div>
        )}
      </Link>
      {onFavorite && (
        <FavoriteButton
          className="-right-15 absolute -top-2 left-0 ml-auto mr-auto"
          isFavorite={isFavorite}
          onClick={onFavorite}
        />
      )}
    </div>
  );
};

export default SecondaryColorButton;
