import { TableData } from '@boss/types/b2b-b2c';
import { twMerge } from 'tailwind-merge';

import Skeleton from '../Skeleton';

type Props = TableData & {
  className?: string;
  thClassName?: string;
  trClassName?: string;
  tdClassName?: string;
  theadClassName?: string;
  name?: string;
  loading?: boolean;
  loadingColumn?: number;
  loadingRows?: number;
  skeletonHeight?: string;
  onGetTrPrefix?: (index: number) => React.ReactNode;
  onGetTrSuffix?: (index: number) => React.ReactNode;
};

const Table = ({
  loading,
  loadingColumn = 4,
  loadingRows = 3,
  name,
  headers,
  items,
  className,
  thClassName,
  trClassName,
  tdClassName,
  theadClassName,
  skeletonHeight = '30px',
  onGetTrPrefix: handleGetTrPrefix,
  onGetTrSuffix: handleGetTrSuffix,
}: Props) => {
  if (loading) {
    const getLoadingWidth = (index: number) => {
      return (((index + 1) * 40) % 100) + 10;
    };

    return (
      <table className={twMerge('w-full', className)}>
        <thead>
          <tr>
            {[...Array(loadingColumn)].map((_, columnIndex) => (
              <th
                className={twMerge('text-table-header font-normal', thClassName)}
                key={`loading-table-header-${columnIndex}`}
              >
                <Skeleton style={{ width: `${getLoadingWidth(columnIndex)}%`, height: skeletonHeight }} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...Array(loadingRows)].map((_, rowIndex) => {
            return (
              <tr className={trClassName} key={`loading-table-row-${rowIndex}`}>
                {[...Array(loadingColumn)].map((_, columnIndex) => (
                  <td
                    className={twMerge('text-table-data py-2 text-center', tdClassName)}
                    key={`loading-table-data-${columnIndex}-${rowIndex}`}
                  >
                    <Skeleton style={{ width: `${getLoadingWidth(columnIndex)}%`, height: skeletonHeight }} />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <table className={twMerge('relative w-full', className)}>
      <thead className={theadClassName}>
        <tr>
          {headers?.map(({ key, label }) => (
            <th className={twMerge('text-table-header font-normal', thClassName)} key={key}>
              {label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!!items?.length &&
          items.map((item, index) => (
            <>
              {handleGetTrPrefix?.(index)}
              <tr className={trClassName} key={`${name ?? 'table-row-item'}-${index}`}>
                {headers.map(({ key }) => (
                  <td
                    className={twMerge('text-table-data py-2 text-center', tdClassName)}
                    key={`${name ?? 'table-data-item'}-${key}-${index}`}
                  >
                    {item[key]}
                  </td>
                ))}
              </tr>
              {handleGetTrSuffix?.(index)}
            </>
          ))}
      </tbody>
    </table>
  );
};

export default Table;
