import { useCallback, useEffect, useState } from 'react';

/**
 * A custom React hook that manages the display of a list of items with pagination.
 *
 * @template T The type of items in the list.
 * @param {T[] | undefined} initialItems The initial list of items.
 * @param {number} itemsPerPage The number of items to display per page.
 * @returns An object containing the current list of displayed items, a flag indicating
 *          if there are more items to show, and a function to load more items.
 */
export const useShowMoreItems = <T,>(initialItems: T[] | undefined, itemsPerPage: number) => {
  const [displayItems, setDisplayItems] = useState<T[]>([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState((initialItems?.length || 0) > itemsPerPage);

  useEffect(() => {
    // Effect to handle updating the displayed items when initial items or page changes
    if (!initialItems) {
      return;
    }
    setHasMore(initialItems.length > displayItems.length);
    const nextItems = initialItems?.slice(0, (page + 1) * itemsPerPage);

    setDisplayItems(nextItems);
  }, [initialItems, page, itemsPerPage, displayItems.length]);

  /**
   * A callback function that increments the page count to load more items.
   */
  const loadMoreItems = useCallback(() => {
    setPage(prevPage => prevPage + 1);
  }, []);

  return { displayItems, hasMore, loadMoreItems };
};
