import { JWTPayload } from 'jose';

const DEFAULT_MARGIN = 60;
const HAS_REFRESH_TOKEN_COOKIE = 'hrt';

export const hasRefreshToken = (document: Document): boolean =>
  document.cookie.includes(`${HAS_REFRESH_TOKEN_COOKIE}=true`);

export const shouldRefresh = (profile: JWTPayload | null, margin: number = DEFAULT_MARGIN): boolean => {
  return profile !== null && hasRefreshToken(document) && needsRefresh(profile, margin);
};

/**
 * Checks if the session is still valid based on the profile data
 * passed. (No integrity check is done, only a time based check).
 *
 * An optional margin in seconds can be passed.
 *
 * Returns true if the token needs a refresh, false if its still
 * valid.
 */
export const needsRefresh = (profile: JWTPayload, margin: number = DEFAULT_MARGIN): boolean => {
  const now = Math.floor(Date.now() / 1000);

  return !!profile?.exp && profile.exp - margin <= now;
};

export default needsRefresh;
