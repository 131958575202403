import { useTranslation } from 'next-i18next';

import { useRouter } from '@boss/hooks';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';

import { DynamicForm } from '..';
import { useContacts } from '../../client-queries';
import { FormType, useFormField, useProfile, useServiceRequest } from '../../hooks';
import { buildFormFields } from '../../utils';
import { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

export const FORM_FIELD_KEYS = [
  'clientnumber',
  'companyname',
  'firstname',
  'lastname',
  'address',
  'street',
  'streetnumber',
  'bus',
  'zipcode',
  'city',
  'preferenceDate',
  'country',
  'service',
  'message',
  'newsletter',
  'termsandconditions',
] as const;

export type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};

type Props = {
  type: FormType;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: Partial<FieldOverwrite>;
  additionalFields?: FormField[];
};

const B2BAdviceForm = ({ fieldsToShow: initialFieldsToShow, fieldsOverwrite, additionalFields, type }: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { t } = useTranslation('forms');
  const { locale } = useRouter();
  const { onSubmit, isSubmitting, isSuccess, reset } = useServiceRequest();
  const { data: contacts } = useContacts(locale);
  const { data: profile } = useProfile();
  const contactInfo = contacts?.find(contact => contact.id === profile?.extension_ContactPersonId);

  const { clientnumber, companyname, firstname, lastname, address, message, termsandconditions } = useFormField();

  const baseFields: FormField[] = [
    {
      ...clientnumber,
    },
    {
      ...companyname,
      disabled: true,
    },
    {
      ...firstname,
      disabled: true,
      initialValue: contactInfo?.firstname ?? '',
    },
    {
      ...lastname,
      disabled: true,
      initialValue: contactInfo?.lastname ?? '',
    },
    address,
    {
      ...message,
      disclaimer: t('disclaimers.colorAdvice'),
      label: t('fields.questionHelp'),
      required: false,
    },
    termsandconditions,
  ];

  const handleSubmit = (vals: FormValues) => onSubmit(type, { ...vals, incidenttype: 'CA' });

  return (
    <DynamicForm
      fields={buildFormFields(baseFields, fieldsToShow, additionalFields, fieldsOverwrite)}
      id={type}
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      onCloseAlert={reset}
      onSubmit={handleSubmit}
      variant="light"
    />
  );
};

export default B2BAdviceForm;
