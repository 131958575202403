import { faChevronDown, faChevronUp, faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { createContext, useRef, useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { useHits, useInstantSearch, useSearchBox } from 'react-instantsearch';

import { ISearchProduct } from '@boss/types/b2b-b2c';
import { Button, InputField } from '@boss/ui';

type Props = {
  children: JSX.Element;
};

type TContext = {
  products: ISearchProduct[];
  query: string;
  loading: boolean;
  hideResults: () => void;
};

export const ProductSearchContext = createContext<TContext>({
  products: [],
  query: '',
  loading: true,
  hideResults: () => null,
});

const SearchWrapper = ({ children }: Props) => {
  const { status } = useInstantSearch();
  const [showResults, setShowResults] = useState(true);
  const { query, refine } = useSearchBox();
  const [inputValue, setInputValue] = useState(query);
  const { hits } = useHits<ISearchProduct>();
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('account', { keyPrefix: 'list.createNewList' });

  const searchResultsLoading = ['stalled', 'loading'].includes(status);

  const { getCollapseProps, getToggleProps } = useCollapse({
    isExpanded: showResults,
  });

  const handleSearch = () => {
    if (inputValue) {
      refine(inputValue);
      setShowResults(true);
    }
  };

  const hideResults = () => {
    refine('');
    setShowResults(false);
  };

  return (
    <div className="shadow-m p-5">
      <div className="flex flex-col md:flex-row md:items-center">
        <div className="w-auto">
          <FontAwesomeIcon className="text-blue-dark -mt-1 mr-3 text-xl" icon={faCirclePlus} />
          <span className="font-bold">{t('addProduct')}</span>
        </div>
        <div className="mt-3 flex flex-1 md:mt-0">
          <InputField
            className="mb-0 mr-3 md:mx-3"
            name="search"
            onBlur={event => setInputValue(event.currentTarget.value)}
            onChange={event => setInputValue(event.currentTarget.value)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
            placeholder={t('searchPlaceholder') ?? ''}
            ref={inputRef}
            testId="input-search-results"
            type="search"
            value={inputValue}
            variant="light"
          />
          <Button
            className="py-2.75 text-xl"
            label={t('search')}
            onClick={handleSearch}
            testId="search-product"
            type="primary"
          />
        </div>
      </div>
      {query && (
        <div className="mt-5">
          <div className="mb-5 flex items-center text-lg md:text-2xl">
            {t('resultsFor')} <strong className="ml-2">{`"${query}"`}</strong>
            <span>
              <Button
                className="ml-3"
                label={!showResults ? t('showResults') : t('hideResults')}
                {...getToggleProps({
                  onClick: () => setShowResults(prevExpanded => !prevExpanded),
                })}
                icon={showResults ? faChevronUp : faChevronDown}
                iconPosition="right"
                testId="show-hide-results"
                type="tertiary"
              />
            </span>
          </div>
          <div {...getCollapseProps()}>
            <ProductSearchContext.Provider
              value={{
                products: hits,
                query,
                loading: searchResultsLoading,
                hideResults,
              }}
            >
              {children}
            </ProductSearchContext.Provider>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchWrapper;
