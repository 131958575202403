import Link from '../Link';

export type QuickLinksProps = {
  title: string;
  links: Link[];
};

type Link = {
  label?: string;
  id: string;
  href?: string;
};

const QuickLinks = ({ title, links }: QuickLinksProps) => {
  return (
    <div className="w-full">
      <div className="h3 mb-2">{title}</div>
      <nav className="flex w-full flex-col">
        {links.map(link => {
          return link.label ? (
            <Link
              anchorClassName="border-b-1 border-b-solid py-2"
              className=" hover:text-green-sheen w-full cursor-pointer  uppercase"
              href={link.href}
              key={link.id}
            >
              {link.label}
            </Link>
          ) : null;
        })}
      </nav>
    </div>
  );
};

export default QuickLinks;
