import { useStores } from '../../client-queries';
import { handleStores, storeConfig } from '../../utils';

/**
 * Central store hook that returns generic store related functions and constants for consistent behaviour
 * @returns {object} returns an object containing the following properties:
 * @property {boolean} isLoading Are the stores still loading?
 * @property {Store[]} stores List of stores alphabetically sorted by names
 */
export function useStore() {
  const { data: stores, isLoading } = useStores();

  return { isLoading, stores: stores?.length ? handleStores(stores, storeConfig) : [] };
}

export default useStore;
