import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const definition: IconDefinition = {
  prefix: 'fas', // Needs something predefined. Take fas. Has no value
  iconName: 'paint-brush', // Needs something predefined. Take closest value
  icon: [
    40, // Viewport width
    41, // Viewport height
    [], // Aliasses (no relevance for this approach) dummy value
    '3b', // unicode char (no relevance for this approach) dummy value
    // SVG Path or an array of SVG Paths
    [
      'M39.5114 6.15282C34.9045 9.60233 29.4535 15.1177 29.4535 15.1177C28.1647 16.5433 27.0206 16.0154 26.4507 15.249L23.6916 11.5381C22.252 9.60197 19.5433 9.20213 17.6057 10.6398C17.2828 10.8794 17.2154 11.3362 17.4501 11.6941L32.5707 31.9882C32.8106 32.3109 33.2679 32.3784 33.626 32.1439C35.5636 30.7063 35.963 28.0003 34.5234 26.0642L31.7643 22.3533C31.1945 21.5869 30.9072 20.6109 32.7577 19.5195C32.7577 19.5195 39.6182 15.8999 44.2551 12.4907C46.0312 11.1729 46.3943 8.71289 45.0747 6.93811C43.7851 5.20367 41.2875 4.835 39.5114 6.15282ZM42.688 8.74034C43.1678 9.38571 43.0381 10.2643 42.3923 10.7435C41.7464 11.2227 40.867 11.0929 40.3871 10.4475C39.9073 9.80215 40.037 8.92357 40.6828 8.44436C41.3287 7.96515 42.2081 8.09497 42.688 8.74034Z',
      'M15.3269 14.1468L12.3128 16.7901C11.9391 17.1299 11.5407 17.3943 11.1122 17.6183L2.91034 22.0453C2.48188 22.2694 2.35852 22.8616 2.62324 23.2598L13.3 37.6193C13.6898 38.1437 14.4286 38.2527 14.9482 37.8985L20.6634 34.0022C20.9811 33.7977 21.311 34.2414 21.0233 34.4862L15.7527 39.0227C15.2631 39.4173 15.1946 40.1253 15.5844 40.6497L16.3942 41.7388C16.6341 42.0614 17.0863 42.1641 17.4392 41.9648L25.611 37.4975C26.0395 37.2734 26.4432 36.9739 26.8116 36.6692L30.1135 33.7812C30.6486 33.3215 30.7276 32.5432 30.3077 31.9785L17.2017 14.3517C16.7467 13.7819 15.8972 13.6924 15.3269 14.1468Z',
    ],
  ],
};

export default definition;
