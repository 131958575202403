import { SimpleImage } from '@boss/types/corporate-jobs';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Button, Image } from '..';

export type CardCtaProps = {
  cta?: Cta;
  description?: ReactNode;
  image?: SimpleImage;
  title?: string;
  className?: string;
  backGroundColorClassName?: string;
};

type Cta = {
  href: string;
  id: string;
  label: string;
};

const CardCta = ({ image, title, description, cta, className, backGroundColorClassName }: CardCtaProps) => (
  <div className={twMerge('flex w-full flex-col', className)}>
    {image && <Image alt={image.imageAlt} className="aspect-video" src={image.imageUrl} wrapperClassName="mb-8" />}
    {!image && <div className={twMerge('mb-8 aspect-video', backGroundColorClassName)}></div>}
    {title && <h3 className="h3 mb-4">{title}</h3>}
    {description && <div className="body mb-4">{description}</div>}
    {cta && <Button className="w-fit" href={cta.href} label={cta.label} type="link" />}
  </div>
);

export default CardCta;
