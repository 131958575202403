import * as Sentry from '@sentry/nextjs';

import { withSession } from '@boss/auth';
import {
  IBasket,
  IBasketDeliveryAddress,
  IBasketLine,
  IBasketPatchLine,
  basket as basketService,
} from '@boss/services/client';
import { DeepPartial } from '@boss/types/b2b-b2c';

import { isB2b } from '../../utils';

/**
 * Function to fetch the current user's basket
 *
 * @async
 * @returns {Promise}
 */
const getBasket = withSession(
  async (accountId?: string) => {
    try {
      return await basketService.getBasket(accountId);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Fetch basket',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Function to fetch basket by basket id
 *
 * @async
 * @returns {Promise}
 */
const getBasketById = withSession(
  async (basketId: string) => {
    try {
      return await basketService.getBasketById(basketId);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Fetch basket by basket id',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Client mutation to add a new basket line
 *
 * @async withSession
 * @param {string} basketId - Id of the current active basket
 * @param {IBasketLine} basketLine - New basket line that needs to be added
 * @returns {Promise<IBasket>} The new basket
 */
const addBasketLines = withSession(
  async (basketId: string, newBasketLines: DeepPartial<IBasketLine>[]) => {
    try {
      return await basketService.addBasketLines(basketId, newBasketLines);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Create wishlist',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Client mutation to delete a new basket line
 *
 * @async withSession
 * @param {string} basketId - Id of the current active basket
 * @param {string} basketLineId - Id of the basket line that needs to be deleted
 * @returns {Promise<IBasket>} The new basket
 */
const deleteBasketLine = withSession(
  async (basketId: string, basketLineId: string) => {
    try {
      return await basketService.deleteBasketLine(basketId, basketLineId);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Delete basket line',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Client mutation to update a basket line
 *
 * @async withSession
 * @param {string} basketId - Id of the current active basket
 * @param {IBasketLine} basketLine - Updated basket line
 * @returns {Promise<IBasket>} The new basket
 */
const updateBasketLine = withSession(
  async (basketId: string, basketLine: DeepPartial<IBasketLine>) => {
    try {
      return await basketService.updateBasketLine(basketId, basketLine);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Update basket line',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Client mutation to update the basket
 * @async withSession
 * @param {string} basketId - Id of the current active basket
 * @param {IBasket} basket - Updated basket
 * @returns {Promise<IBasket>} The new basket
 */
const updateBasket = withSession(
  async (basketId: string, basket: DeepPartial<IBasket>) => {
    try {
      return await basketService.updateBasket(basketId, basket);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Update basket',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Client mutation to path the basket
 * @async withSession
 * @param {string} basketId - Id of the current active basket
 * @param {IBasketPatchLine[]} patchLines - Patch lines
 * @returns {Promise<IBasket>} The new basket
 * */
const patchBasket = withSession(
  async (basketId: string, patchLines: IBasketPatchLine[]) => {
    try {
      return await basketService.patchBasket(basketId, patchLines);
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Patch basket',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
  {
    alwaysRun: !isB2b,
  },
);

/**
 * Function to fetch delivery dates for the basket
 * @async withSession
 * @param {string} accountId - Id of the current active basket
 * @param {number} modeOfDelivery - Mode of delivery
 */
const getDeliveryDates = withSession(
  async ({
    modeOfDelivery,
    storeId,
    address,
  }: {
    modeOfDelivery: number;
    storeId?: string;
    address?: IBasketDeliveryAddress;
  }) => {
    try {
      return await basketService.getDeliveryDates({ modeOfDelivery, storeId, address });
    } catch (e) {
      console.error(e);

      Sentry.captureException(e, {
        tags: {
          type: 'Fetch delivery dates',
        },
      });

      // Needs a re-throw
      throw e;
    }
  },
);

export {
  addBasketLines,
  deleteBasketLine,
  getBasket,
  getBasketById,
  updateBasketLine,
  getDeliveryDates,
  updateBasket,
  patchBasket,
};
