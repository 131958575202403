import { cva } from 'class-variance-authority';
import router, { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useContext, useEffect, useState } from 'react';

import { getAlgolia } from '@boss/algolia-client';
import { CHANNEL, searchPageSlugs, trackEvents } from '@boss/constants/b2b-b2c';
import { ProductTab, Section } from '@boss/ui';
import { processQuery } from '@boss/utils';

import ColorSearch from './color';
import ContentPageSearch from './contentPages';
import InpirationImageSearch from './inspirationImages';
import ProductSearch from './products';
import StepByStepSearch from './stepByStep';
import { InstantSearch } from '../../components';
import { useEventTracker } from '../../hooks';
import {
  getColorSearchIndexName,
  getContenPagesIndexName,
  getInspirationImagesSearchIndexName,
  getProductSearchIndexName,
  getStepByStepSearchIndexName,
} from '../../utils';
import { PagePropsContext } from '../googleTagManager';

const channelClient = getAlgolia({ noEmptySearch: true }).algoliaClient;
const sharedClient = getAlgolia({ isShared: true, noEmptySearch: true }).algoliaClient;

const SearchContainerStyle = cva('', {
  variants: {
    hidden: {
      true: 'hidden',
    },
  },
});

const SearchPage = () => {
  const { locale, query, replace } = useRouter();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activeTab, setActiveTab] = useState<string>();
  const pageProps = useContext(PagePropsContext);
  const { trackCustomEvent } = useEventTracker();

  const [combinedResults, setCombinedResults] = useState({
    products: null,
    inspirationImages: null,
    stepByStep: null,
    colors: null,
    contentPages: null,
  });

  const handleResultsUpdate = (tab: string, results: number | null) => {
    setCombinedResults(prevResults => ({
      ...prevResults,
      [tab]: results,
    }));
  };

  const { t } = useTranslation('search');

  const productIndex = getProductSearchIndexName(locale as string);
  const inspirationImagesIndex = getInspirationImagesSearchIndexName(locale as string);
  const stepByStepIndex = getStepByStepSearchIndexName(locale as string);
  const contentIndex = getContenPagesIndexName(locale as string);
  const colorIndex = getColorSearchIndexName(locale as string);

  const channelFilter = `channel:"${CHANNEL}"`;

  const TABS = {
    PRODUCTS: {
      label: t('tabs.products'),
      index: productIndex,
      amount: combinedResults.products,
      searchClient: channelClient,
      filter: undefined,
      query: searchQuery,
      component: (
        <ProductSearch
          indexName={productIndex}
          onUpdateTotalResults={results => handleResultsUpdate('products', results)}
          query={searchQuery}
        />
      ),
    },
    INSPIRATION_IMAGES: {
      label: t('tabs.inpirationImages'),
      index: inspirationImagesIndex,
      amount: combinedResults.inspirationImages,
      searchClient: sharedClient,
      filter: channelFilter,
      query: searchQuery,
      component: (
        <InpirationImageSearch
          indexName={inspirationImagesIndex}
          onUpdateTotalResults={results => handleResultsUpdate('inspirationImages', results)}
          query={searchQuery}
        />
      ),
    },
    STEP_BY_STEP: {
      label: t('tabs.stepByStep'),
      index: stepByStepIndex,
      amount: combinedResults.stepByStep,
      searchClient: sharedClient,
      filter: channelFilter,
      component: (
        <StepByStepSearch
          indexName={stepByStepIndex}
          onUpdateTotalResults={results => handleResultsUpdate('stepByStep', results)}
          query={searchQuery}
        />
      ),
    },
    COLOR: {
      label: t('tabs.colors'),
      index: colorIndex,
      amount: combinedResults.colors,
      searchClient: sharedClient,
      filter: undefined,
      query: searchQuery,
      component: (
        <ColorSearch
          indexName={colorIndex}
          onUpdateTotalResults={results => handleResultsUpdate('colors', results)}
          query={searchQuery}
        />
      ),
    },
    CONTENT: {
      label: t('tabs.pages'),
      index: contentIndex,
      amount: combinedResults.contentPages,
      searchClient: sharedClient,
      filter: channelFilter,
      query: searchQuery,
      component: (
        <ContentPageSearch
          indexName={contentIndex}
          onUpdateTotalResults={results => handleResultsUpdate('contentPages', results)}
          query={searchQuery}
        />
      ),
    },
  };

  const trackSearch = (searchQuery: string) => {
    const trackingProperties = {
      keyword: searchQuery,
      productResultCount: combinedResults.products,
      contentResultCount: combinedResults.contentPages,
      colorResultCount: combinedResults.colors,
      stepbystepResultCount: combinedResults.stepByStep,
      inspirationResultCount: combinedResults.inspirationImages,
      totalresultCount:
        (combinedResults.products ?? 0) +
        (combinedResults.contentPages ?? 0) +
        (combinedResults.colors ?? 0) +
        (combinedResults.stepByStep ?? 0) +
        (combinedResults.inspirationImages ?? 0),
      pageInfo: pageProps,
      ExecutedofAutomaticSearch: 'executed',
    };

    trackCustomEvent(trackEvents.KEYWORD_SEARCHED, trackingProperties);
  };

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    const _searchQuery = processQuery(query, 'q');
    const _activeTab = processQuery(query, 'type');

    if (_searchQuery && _searchQuery !== searchQuery) {
      trackSearch(_searchQuery);
      setSearchQuery(_searchQuery);
    }

    setActiveTab(_activeTab?.toUpperCase() ?? 'PRODUCTS');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, searchQuery]);

  const changeTab = (type: string) => {
    setActiveTab(type);
    replace(
      {
        pathname: searchPageSlugs[locale as string],
        query: {
          q: searchQuery,
          type: type.toLocaleLowerCase(),
        },
      },
      undefined,
      {
        scroll: false,
      },
    );
  };

  return (
    <>
      <Section
        className="py-6"
        content={
          <div className="flex flex-col gap-10">
            {searchQuery && <h1>{t('title', { query: `"${searchQuery}"` })}</h1>}
            <div className="flex gap-4 overflow-x-auto">
              {Object.entries(TABS).map(([type, tab]) => (
                <ProductTab
                  active={type === activeTab}
                  amount={tab.amount}
                  key={tab.label}
                  label={tab.label}
                  onClick={() => changeTab(type)}
                />
              ))}
            </div>
          </div>
        }
      />
      {Object.entries(TABS).map(([type, tab]) => (
        <div className={SearchContainerStyle({ hidden: activeTab !== type })} key={type}>
          {tab.index ? (
            <InstantSearch
              disableRouting
              facetFilters={tab.filter}
              indexName={tab.index}
              query={searchQuery}
              searchClient={tab?.searchClient}
            >
              {tab.component}
            </InstantSearch>
          ) : (
            tab.component
          )}
        </div>
      ))}
    </>
  );
};

export default SearchPage;
