import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { useRouter } from '@boss/hooks';
import { IEvent, IStore } from '@boss/services';
import { Button, ContactCard, EventCard, ImageTextSection, Section, ShoppingCartTotalPrice, Skeleton } from '@boss/ui';
import {
  formatDate,
  formatLocalDate,
  formatPrice,
  getLocalizedSlugForEventPage,
  getTimeFromDatestring,
  slugify,
} from '@boss/utils';

import { useEventById } from '../../client-queries';
import { EducationForm, WorkshopForm } from '../../components';
import FormWrapper from '../../components/Form/FormWrapper';
import { eventConfig, isB2b } from '../../utils';

const FormStyles = cva('', {
  variants: {
    variant: {
      primary: 'bg-beige-light',
      secondary: 'bg-blue-light',
    },
  },
});

const ImageSectionStyles = cva('break-before break-after relative', {
  variants: {
    variant: {
      primary: 'bg-gray-dark before:bg-gray-dark after:bg-gray-dark',
      secondary: 'bg-gray-100 before:bg-gray-100 after:bg-gray-100',
    },
  },
});

const ImageSectionTextStyles = cva('col-span-1 flex items-center', {
  variants: {
    variant: {
      primary: 'text-white',
      secondary: '',
    },
  },
});

const WorkshopPage = ({ event, store }: { event: IEvent; store?: IStore | null }) => {
  const { locale } = useRouter();
  const { data: clientSideEvent, isLoading } = useEventById(event.id);

  const formTypeMap: Record<(typeof eventConfig)['formType'], ReactNode> = {
    workshop: (
      <FormWrapper behavior={isB2b ? 'showNothingWhenLoggedOut' : 'showAlways'}>
        <WorkshopForm eventId={event.id} />
      </FormWrapper>
    ),
    education: (
      <FormWrapper behavior={isB2b ? 'showLogin' : 'showAlways'}>
        <EducationForm eventId={event.id} />
      </FormWrapper>
    ),
  };

  const { t } = useTranslation(['workshop', 'event']);

  const { variant, formType } = eventConfig;

  const renderEventStatus = () => {
    if (event.complete) {
      return <p className="text-red text-right font-bold">{t('full', { ns: 'workshop' })}</p>;
    } else if (!isLoading) {
      return (
        <p className="text-green text-right font-bold">
          {t('freePlaces', { ns: 'workshop', count: clientSideEvent?.numberOfParticipants })}!
        </p>
      );
    } else {
      return (
        <div className="float-right">
          <Skeleton className="h-5 w-40 " />
        </div>
      );
    }
  };

  return (
    <>
      <div className="lg-grid-cols-2 lg:mb-30 mb-10 grid grid-cols-1 gap-4 md:grid-cols-2">
        <h1 className="col-span-1 md:col-span-2">{event.description}</h1>
        <div className="col-span-1 grid gap-4 text-gray-500 md:grid-cols-3">
          <p className="font-bold capitalize">{formatLocalDate(event.date, locale)}</p>
          <p className="font-bold">{`${getTimeFromDatestring(event.startTime)} -  ${getTimeFromDatestring(
            event.endTime,
          )}`}</p>
          <p className="font-bold">
            {t('price', { ns: 'workshop' })}: {formatPrice(event.price, locale)}
          </p>
        </div>
        <div className="col-span-1">{renderEventStatus()}</div>
      </div>
      <ImageTextSection
        className={ImageSectionStyles({ variant })}
        image={{
          src: '//images.ctfassets.net/1qe7d7vhswmi/5ECKrmYHJaFvnM8dwbrvyC/a41c78bb0aea58fee5c92d9963a374c7/pexels-photo-1457842.jpeg',
        }}
        imageFirst={true}
        text={
          <div className="grid gap-4 md:grid-cols-2">
            <div className={ImageSectionTextStyles({ variant })}>
              {/* MOCK: This data is mocked since this needs to be enriched in contentful */}
              <p className="flex content-center ">
                Geef jouw muren een extra dimensie! Advies en demonstratie van verschillende mooie vormen op je muur.
                Welke technieken bestaan er? Hoe begin ik er aan? We tonen je de meest voorkomende technieken.
              </p>
            </div>
            <div className="col-span-1">
              <ContactCard
                address={{
                  street: store?.address?.street ?? '',
                  streetnumber: Number(store?.address?.streetnumber ?? 0),
                  zipcode: store?.address?.zipcode ?? '',
                  city: store?.address?.city ?? '',
                  country: store?.address?.country ?? '',
                }}
                className="rounded-modal bg-white"
                email={store?.email}
                firstname={store?.name}
                hideButtons={true}
                phone={store?.phone}
                translations={{ call: 'bel me', mail: 'schrijf me' }}
              />
            </div>
          </div>
        }
        variant={variant}
      />
      <Section
        backgroundColor={FormStyles({ variant })}
        className="md:mt-30 md:py-15 mt-10"
        content={
          <div className="flex flex-col gap-5">
            <span className="h2">{t('form.title')}</span>
            {formTypeMap[formType]}
          </div>
        }
      />
      <Section
        content={
          <div className="mt-0 md:mt-20">
            <h2>{t('related.title')}</h2>
            <div className="mt-10 grid gap-4 md:mt-20 md:grid-cols-3">
              {event.relatedEvents?.map(related => {
                const eventLink = getLocalizedSlugForEventPage(locale, slugify(related.id));

                return (
                  <EventCard
                    className="col-span-1"
                    footerAction={
                      !event.complete ? (
                        <Button
                          className="py-3"
                          href={eventLink}
                          icon={faArrowRight}
                          iconPosition="right"
                          label={t('sections.overview.actions.register', { ns: 'event' })}
                          type="primary"
                        />
                      ) : (
                        <Button
                          className="bg-red-dark py-3"
                          href={eventLink}
                          label={t('sections.overview.actions.full', { ns: 'event' })}
                          type="primary"
                        />
                      )
                    }
                    footerContent={
                      <ShoppingCartTotalPrice
                        bold
                        className="pt-0"
                        price={formatPrice(event.price, locale)}
                        totalText={t('price', { ns: 'workshop' })}
                      />
                    }
                    // MOCK: This is mock since API not yet available
                    image="//images.ctfassets.net/1qe7d7vhswmi/5ECKrmYHJaFvnM8dwbrvyC/a41c78bb0aea58fee5c92d9963a374c7/pexels-photo-1457842.jpeg"
                    key={related.id}
                    title={related.description}
                    variant={variant}
                  >
                    <div className="text-gray py-3">
                      {event.date && <p>{formatDate(event.date)}</p>}
                      {event.location && <p>{event.location}</p>}
                    </div>
                  </EventCard>
                );
              })}
            </div>
          </div>
        }
      />
    </>
  );
};

export default WorkshopPage;
