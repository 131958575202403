import { useTranslation } from 'next-i18next';

import { useSubmitForm } from '../../client-queries';
import { useFormField } from '../../hooks';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

type Props = {
  id?: string;
  eventId?: string;
};

const WorkshopForm = ({ id = 'workshop-form', eventId }: Props) => {
  const { t } = useTranslation('forms');
  const {
    salutation,
    firstname,
    lastname,
    email,
    phonenumber,
    question,
    termsandconditions,
    newsletter,
    street,
    streetnumber,
    bus,
    zipcode,
    city,
    country,
  } = useFormField();

  const successToast = {
    title: t('success.title'),
    description: t('success.subscribeSubtitle'),
  };
  const errorToast = {
    title: t('errors.submitTitle'),
    description: t('errors.submitSubtitle'),
  };
  const {
    mutate: submitForm,
    isLoading: isSubmitting,
    isSuccess,
    reset,
  } = useSubmitForm({
    toasts: { success: successToast, error: errorToast },
  });

  const handleSubmit = (vals: FormValues) => submitForm(vals);

  const fieldClassName = 'col-span-6 md:col-span-3';

  const addressFields: FormField = {
    name: 'address',
    type: 'address',
    fields: [
      {
        ...street,
      },
      { ...streetnumber, label: t('fields.streetnumberShort') },
      { ...bus },
      { ...zipcode, colStyle: fieldClassName },
      { ...city, colStyle: fieldClassName },
      { ...country, colStyle: fieldClassName },
    ],
    colStyle: 'md:col-span-6',
    required: false,
  };

  const userInfoFields: FormField[] = [
    { ...salutation, colStyle: fieldClassName },
    { ...firstname, colStyle: fieldClassName },
    { ...lastname, colStyle: fieldClassName },
    addressFields,
    { ...email, colStyle: fieldClassName },
    { ...phonenumber, colStyle: fieldClassName },
  ];

  const questionFields: FormField[] = [
    {
      ...question,
      label: t('fields.questionWorkshop'),
      colStyle: 'h-full [&>textarea]:h-full flex flex-col',
    },
  ];

  const checkboxFields: FormField[] = [
    {
      ...termsandconditions,
      colStyle: '',
    },
    newsletter,
  ];

  return (
    <DynamicForm
      buttonProps={{ label: t('buttons.subscribe') ?? '' }}
      columns={[
        { id: `${id}-user-info`, fields: userInfoFields },
        { id: `${id}-question-info`, fields: questionFields, formFieldsWrapperClassName: 'flex flex-col gap-5 h-full' },
        { id: `${id}-checkboxes`, fields: checkboxFields, formFieldsWrapperClassName: 'flex flex-col gap-4' },
      ]}
      columnsWrapperClassName="grid gap-6 lg:gap-x-30 md:grid-cols-2"
      eventId={eventId}
      id={id}
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      onCloseAlert={reset}
      onSubmit={handleSubmit}
      variant="light"
    />
  );
};

export default WorkshopForm;
