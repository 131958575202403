import { useRouter as useNextRouter } from 'next/router';

/**
 * TODO: Add a deschription and explain the added value from the use of the next router
 * Potentially mark for removal.
 */
export const useRouter = (fallbackLocale = 'nl-BE') => {
  const { locales: routerLocales, locale, ...props } = useNextRouter();

  const locales = routerLocales?.filter(locale => locale !== 'default') || [];

  return { locales, locale: locale || fallbackLocale, ...props };
};

export default useRouter;
