import { COLLAPSE_GRID_ANIMATE, COLLAPSE_GRID_INITIAL, getCollapsePropsWithOffset } from '@boss/constants/b2b-b2c';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ColorPickerModalProps } from '..';
import {
  Alert,
  Button,
  ColorGroupButton,
  ColorGroupButtonSkeleton,
  ColorsGrid,
  ColorsGridSkeleton,
  Divider,
  Modal,
  Presence,
  Repeat,
  SearchBar,
} from '../..';

const DesktopColorPickerModal = ({
  activeColorGroup,
  activeColors,
  callToActions,
  className,
  colorGroups,
  handleShowMore,
  handleShowMoreSearch,
  searchValue,
  hasShowMore,
  hasShowMoreSearch,
  onChangeColorClick,
  onClose,
  onColorGroupClick,
  onFavorite,
  onSearch,
  searchResults,
  selectedColor,
  showMore,
  showMoreSearch,
  translations,
  setSearchValue,
  hasSearched,
  colorsLoading,
  searchLoading,
  favoriteColorIds,
  colorGroupsLoading,
  disclaimer,
  colorType,
}: ColorPickerModalProps) => {
  const [expand, setExpand] = useState(false);

  return (
    <Modal
      className={twMerge('rounded-b-modal rounded-l-modal flex h-full flex-col gap-10 overflow-y-auto', className)}
      onClose={onClose}
    >
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <h2>{translations.title}</h2>
          {disclaimer && <Alert type="info">{disclaimer}</Alert>}
        </div>

        <SearchBar
          className="w-full md:max-w-full"
          onSearch={onSearch}
          searchLabel={translations.searchLabel}
          setSearchValue={setSearchValue}
          type="primary"
          value={searchValue}
        />

        <Presence
          id="color-picker-search-presence"
          {...getCollapsePropsWithOffset('-1.25rem')}
          isLoading={searchLoading}
          loader={<ColorsGridSkeleton amount={8} variant="secondary" />}
          visible={hasSearched}
        >
          <div className="flex flex-col gap-10">
            <ColorsGrid
              colorType={colorType}
              colors={searchResults}
              favoriteColorIds={favoriteColorIds}
              handleShowMore={handleShowMoreSearch}
              hasShowMore={hasShowMoreSearch}
              onChangeColorClick={onChangeColorClick}
              onFavorite={onFavorite}
              selectedColor={selectedColor}
              showMore={showMoreSearch}
              title={translations.results}
              translations={translations}
              variant="secondary"
            />
            <Divider />
            <button className="text-blue-dark flex items-center gap-2" onClick={() => setExpand(expand => !expand)}>
              {expand ? translations.hideLabel : translations.showLabel}
              <FontAwesomeIcon className={expand ? 'rotate-180' : ''} icon={faChevronDown} />
            </button>
          </div>
        </Presence>
      </div>

      <Presence
        animate={COLLAPSE_GRID_ANIMATE}
        exit={{}} // Overwriting default exit animation
        id="color-picker-overview-presence"
        initial={COLLAPSE_GRID_INITIAL}
        visible={!hasSearched || (hasSearched && expand)}
      >
        <div className="gap-15 flex">
          <Presence
            className="flex flex-col gap-4"
            id="modal-color-group-presence"
            isLoading={colorGroupsLoading}
            loader={
              <div className="min-w-40 flex flex-col gap-4">
                <Repeat amount={10}>
                  <ColorGroupButtonSkeleton />
                </Repeat>
              </div>
            }
            visible
          >
            {colorGroups?.map(colorGroup => (
              <ColorGroupButton
                active={colorGroup.code === activeColorGroup?.code}
                backgroundColor={colorGroup.rgb}
                key={colorGroup.code}
                name={colorGroup.name ?? colorGroup.code}
                onClick={() => onColorGroupClick(colorGroup)}
                variant="secondary"
              />
            ))}
          </Presence>
          <Presence
            className="h-full w-full"
            id="color-picker-colors-presence"
            isLoading={colorsLoading}
            loader={<ColorsGridSkeleton variant="secondary" />}
            visible
          >
            <ColorsGrid
              colorType={colorType}
              colors={activeColors}
              favoriteColorIds={favoriteColorIds}
              handleShowMore={handleShowMore}
              hasShowMore={hasShowMore}
              onChangeColorClick={onChangeColorClick}
              onFavorite={onFavorite}
              selectedColor={selectedColor}
              showMore={showMore}
              title={translations.subtitle}
              translations={translations}
              variant="secondary"
            />
          </Presence>
        </div>
      </Presence>

      {!!callToActions?.length && (
        <div className="flex justify-end gap-6">
          {callToActions.map((callToAction, index) => (
            <Button
              className={
                callToAction.highlight
                  ? ''
                  : 'text-brown-dark border-brown-dark border-1 hover:bg-brown-dark bg-white hover:text-white'
              }
              key={`${callToAction.label}-${index}`}
              label={callToAction.label}
              onClick={callToAction.onClick}
              type="primary"
            />
          ))}
        </div>
      )}
    </Modal>
  );
};

export default DesktopColorPickerModal;
