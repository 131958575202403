import { useRouter } from '@boss/hooks';
import { ContentPageFields, Nullable } from '@boss/types/b2b-b2c';
import { Presence } from '@boss/ui';

import { ComponentMapper, RecentlyViewedProducts } from '../../components';

type Props = {
  cmsData?: Nullable<ContentPageFields>;
};

const NotFoundPage = ({ cmsData }: Props) => {
  const { locale } = useRouter();

  return (
    <Presence className="flex flex-col" id="404-page-presence" visible>
      {cmsData?.content?.map(entry => (
        <ComponentMapper entry={entry} key={entry.id} locale={locale} />
      ))}

      <RecentlyViewedProducts backgroundColor="bg-blue-light/30 before:bg-blue-light/30 after:bg-blue-light/30" />
    </Presence>
  );
};

export default NotFoundPage;
