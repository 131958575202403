import type { LocalizedSlugs } from '@boss/types/corporate-jobs';

export const newsSlugs: LocalizedSlugs = {
  nl: 'laatste-nieuws',
  fr: 'dernieres-nouvelles',
  en: 'latest-news',
};

export const notFoundSlugs: LocalizedSlugs = {
  fr: '404',
  nl: '404',
  en: '404',
};

export const vacanciesSlugs: LocalizedSlugs = {
  fr: 'offres-emplois',
  nl: 'vacatures',
  en: '',
};
