import React, { ComponentType, useEffect } from 'react';

import { useLogin, useProfile } from '../../hooks';

/**
 * A Higher-Order Component (HOC) that adds authentication logic to a component.
 *
 * @param WrappedComponent - The component to be wrapped by the HOC.
 * @returns A new component with authentication logic.
 */
const withAuth = <P extends object>(WrappedComponent: ComponentType<P>) => {
  /**
   * A functional component that wraps the original component and includes authentication logic.
   *
   * @param props - Props to be passed to the WrappedComponent.
   * @returns The WrappedComponent with additional authentication logic.
   */
  return function WithAuthComponent(props: P) {
    const { isLoggedIn, status: loadProfileStatus } = useProfile();
    const { handleLogin } = useLogin();

    useEffect(() => {
      // If the user is not logged in and the profile status is idle, trigger the login process.
      if (!isLoggedIn && loadProfileStatus === 'idle') {
        handleLogin();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadProfileStatus]);

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
