/// to test trusted shops average review functionality you need to use ngrok. the script is blocked on localhost
import { ReactElement, useEffect } from 'react';
import { useScript } from 'usehooks-ts';

import { useRouter } from '@boss/hooks';
type WithProductStickerSummary = Window & {
  productStickerSummary: () => { showSummary: (options: unknown) => void };
};

// TODO: Use interface, internalise id generation. (Use reference counting and randomization)
// The combination of both techniques should be sufficient.
type Props = {
  id: string;
  elementSuffix: string;
  smallScale?: boolean;
};

export const getTrustedShopWidgetID = (locale: string) => {
  return {
    nl: process.env.NEXT_PUBLIC_TRUSTEDSHOPS_ID_BE,
    fr: process.env.NEXT_PUBLIC_TRUSTEDSHOPS_ID_FR,
    'nl-NL': process.env.NEXT_PUBLIC_TRUSTEDSHOPS_ID_NL,
  }[locale];
};

const ProductAverageReview = ({ id, elementSuffix, smallScale = false }: Props): ReactElement => {
  const status = useScript(`https://widgets.trustedshops.com/reviews/tsSticker/tsProductStickerSummary.js`, {
    removeOnUnmount: false,
  });
  const elementID = `apps_ts_product_average_review_${id}_${elementSuffix}_script`;
  const { locale } = useRouter();

  useEffect(() => {
    if (status !== 'ready') {
      return;
    }
    // check if function - first time its undefined that is why we need to check if its a function
    if (typeof (window as unknown as WithProductStickerSummary).productStickerSummary === 'function') {
      const summaryBadge = (window as unknown as WithProductStickerSummary).productStickerSummary();

      summaryBadge.showSummary({
        tsId: getTrustedShopWidgetID(locale), // TrustedShops ID GOES HERE
        sku: [id], // PRODUCT ID GOES HERE
        element: `#${elementID}`,
        starColor: '#FFDC0F',
        starSize: smallScale ? '9px' : '15px',
        fontSize: smallScale ? '0px' : '12px',
        showRating: true,
        scrollToReviews: false,
      });
    }
  }, [status, id, elementID, locale, smallScale]);
  return <div id={elementID} />;
};

export default ProductAverageReview;
