import { useContext } from 'react';

import { trackEvents } from '@boss/constants/shared';
import { FormFields } from '@boss/types/b2b-b2c';

import { useSaveServiceRequest } from '../../client-queries';
import { FormValues } from '../../components/DynamicForm';
import { PagePropsContext } from '../../containers/googleTagManager';
import { useEventTracker } from '../../hooks';

type BossPaintsFormType = FormFields['bossPaintsFormType'];
type ColoraFormType = FormFields['coloraFormType'];
type FormType = NonNullable<BossPaintsFormType | ColoraFormType>;

type MailApiFormFieldKey =
  | 'type'
  | 'storeid'
  | 'contactpersonid'
  | 'firstname'
  | 'lastname'
  | 'street'
  | 'streetnumber'
  | 'bus'
  | 'zipcode'
  | 'city'
  | 'country'
  | 'email'
  | 'phone'
  | 'servicemessage'
  | 'subscribetonewsletter'
  | 'subscribetomagazine'
  | 'incidenttype'
  | 'companyname'
  | 'deliverymethod'
  | 'requesteddatetimeutc';

type ServiceApiFormFieldKey =
  | 'type'
  | 'incidenttype'
  | 'contactpersonid'
  | 'firstname'
  | 'lastname'
  | 'email'
  | 'message'
  | 'deliverymethod'
  | 'deliverywarehouseid'
  | 'address'
  | 'companyname';

type Address = {
  street: string;
  streetnumber: string;
  postbox: string;
  zipcode: string;
  city: string;
  country: string;
};

type FormBody = Partial<Record<MailApiFormFieldKey | ServiceApiFormFieldKey, string | Address | number>>;

const checkDeliveryMethods = (val: string) => {
  const mapping: { [key: string]: string | number } = {
    storeid: 0,
    colorastore: 0,
    bossdepot: 1,
    worksite: 2,
    address: 2,
    addresses: 2,
    samplesaddresses: 2,
  };

  return mapping[val];
};

const mapType = (type: FormType): string => {
  if (type === 'color-advice') {
    return 'CA';
  }
  if (type === 'samples-service') {
    return 'Sample Rental';
  }

  if (type === 'machine-rental') {
    return 'Equipment Rental';
  }

  if (type === 'machine-repair') {
    return 'Equipment Repair';
  }

  return type;
};

const getValue = (formValues: FormValues) => (key: string) => formValues[key]?.toString() ?? '';

const mapServiceObject = (type: FormType, formValues: FormValues): FormBody => {
  const valueOrEmpty = getValue(formValues);

  return {
    type: mapType(type),
    incidenttype: valueOrEmpty('incidenttype'),
    contactpersonid: valueOrEmpty('contactpersonid'),
    message: valueOrEmpty('message'),
    deliverymethod: checkDeliveryMethods(formValues['deliverymethod'] as string),
    deliverywarehouseid: valueOrEmpty('deliverywarehouseid'),
    firstname: valueOrEmpty('firstname'),
    lastname: valueOrEmpty('lastname'),
    email: valueOrEmpty('email'),
    companyname: valueOrEmpty('companyname'),
    address: {
      street: valueOrEmpty('street'),
      streetnumber: valueOrEmpty('streetnumber'),
      postbox: valueOrEmpty('bus'),
      zipcode: valueOrEmpty('zipcode'),
      city: valueOrEmpty('city'),
      country: valueOrEmpty('country'),
    },
  };
};

/**
 * useForm hook which exports generic form functions
 */
export const useServiceRequest = () => {
  const {
    mutate: submitServiceRequest,
    isLoading: isSubmitting,
    isSuccess: isSuccess,
    isError: isServiceError,
    reset,
  } = useSaveServiceRequest();

  const { trackCustomEvent } = useEventTracker();
  const pageProps = useContext(PagePropsContext);

  const onSubmit = (type: FormType, vals: FormValues) => {
    const formObject = mapServiceObject(type, vals);

    submitServiceRequest(formObject, {
      onSuccess: () => {
        trackCustomEvent(trackEvents.FORM_SUBMITTED, {
          formName: type,
          pageInfo: pageProps,
        });
      },
    });
  };

  return { onSubmit, isSubmitting, isSuccess, reset, isServiceError };
};
