import { useRouter } from 'next/router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, RadioGroup, SearchDropdown } from '@boss/ui';

import { useAccount } from '../../../client-queries';
import { usePointOfSale } from '../../../hooks';

interface Props {
  onUpdateWarehouseId: (warehouseId: string) => void;
}

const StockCheckOptions = ({ onUpdateWarehouseId: handleUpdateWarehouseId }: Props) => {
  const { t } = useTranslation(['product']);
  const { stores } = usePointOfSale(3);
  const { locale } = useRouter();
  const { data: account } = useAccount(locale as string);

  const [selectedMethod, _setSelectedMethod] = useState<string>('delivery'); // ['delivery', 'store'
  const [showStoreSelection, setShowStoreSelection] = useState(false);
  const [selectedStore, _setSelectedStore] = useState<string | null>(null);

  const setSelectedStore = (storeId: string) => {
    const store = stores.find(store => store.id === storeId);

    handleUpdateWarehouseId(store?.coloradepotid ?? '');
    _setSelectedStore(storeId);
  };

  const setSelectedMethod = (method: string) => {
    _setSelectedMethod(method);
    if (method === 'delivery') {
      handleUpdateWarehouseId('HM');
      setShowStoreSelection(false);
    } else if (!selectedStore && account?.preference?.shop) {
      setSelectedStore(account.preference.shop);
    } else if (selectedStore) {
      handleUpdateWarehouseId(stores.find(store => store.id === selectedStore)?.coloradepotid ?? '');
    } else {
      setShowStoreSelection(true);
    }
  };

  const stockOptions = [
    {
      label: (
        <div className="flex flex-col gap-1">
          <span>{t('productPanel.stock.delivery')}</span>
          <span className="text-gray text-sm italic">{t('productPanel.stock.deliveryExtra')}</span>
        </div>
      ),
      value: 'delivery',
    },
    {
      label: (
        <div className="flex flex-col">
          <span>{t('productPanel.stock.store')}</span>
          {!showStoreSelection && !selectedStore ? (
            <Button
              className="text-blue"
              label={t('productPanel.stock.selectStore')}
              onClick={() => setShowStoreSelection(true)}
              type="link"
            />
          ) : !selectedStore ? (
            <SearchDropdown
              className="mt-1"
              dropdownClassName="z-1000"
              onChange={setSelectedStore}
              options={stores.map(store => ({ value: store.id, label: store.name }))}
              searchLabel={t('productPanel.stock.searchStoreLabel') ?? ''}
              selectLabel={t('productPanel.stock.selectStoreOption') ?? ''}
              value={selectedStore}
            />
          ) : (
            <div className="text-blue mt-2 flex items-center gap-2">
              <span>{stores?.find(store => store.id === selectedStore)?.name}</span>

              <button
                className="text-gray text-xs"
                onClick={() => {
                  _setSelectedStore(null);
                  setShowStoreSelection(true);
                }}
              >
                ({t('productPanel.stock.updateStore')})
              </button>
            </div>
          )}
        </div>
      ),
      value: 'store',
    },
  ];

  return (
    <RadioGroup
      className="text-blue-dark"
      name="deliveryoption"
      onChange={method => {
        setSelectedMethod(method);
      }}
      options={stockOptions}
      value={selectedMethod}
    />
  );
};

export default StockCheckOptions;
