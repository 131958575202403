import { useTranslation } from 'next-i18next';
import { Fragment } from 'react';

import { StepByStepPlanStepFields } from '@boss/types/b2b-b2c';
import { Divider, Image, ProductCardHorizontal } from '@boss/ui';

import { useProductsById } from '../../client-queries';
import { useProductMap } from '../../hooks';

type Props = {
  steps: StepByStepPlanStepFields[];
};

const getProductIds = (steps: StepByStepPlanStepFields[]) => {
  const idsPerStep: string[][] = [];

  steps.forEach(step => {
    const ids = step.usedProducts?.map(product => product.productID);

    if (ids?.length > 0) {
      idsPerStep.push(ids);
    }
  });

  return [...new Set(idsPerStep.flat())];
};

const StepByStepProductList = ({ steps }: Props) => {
  const productIds = getProductIds(steps);
  const { t } = useTranslation('routes');
  const { mapProductComponent } = useProductMap();
  const { data: products } = useProductsById(productIds);

  return (
    <div
      className="shadow-m md:px-12.5 md:py-15 relative relative flex flex-col gap-6 rounded-lg rounded-tl-none bg-white px-6 py-10 md:gap-10"
      data-testid="step-by-step-product-list"
    >
      <Image
        alt="tape"
        className="w-45 -top-4.5 absolute left-1/2 h-9 -translate-x-1/2"
        height={36}
        src="/tape.png"
        useNext
        width={180}
      />
      <h2 className="text-coloredTitleColor md:text-center">{t('usedProducts.title')}</h2>
      <div className="md:gap-7.5 flex flex-col gap-4">
        {steps.map((step, index) => {
          const stepHasProducts = step.usedProducts?.length > 0;

          return (
            <div
              className="md:gap-7.5 flex flex-col gap-4"
              data-testid={`step-by-step-product-list-step-${index + 1}`}
              key={`${step.id}-${index}`}
            >
              <span className="h3 md:body text-textColor">
                <span className="h3 md:body md:underline">{t('stepX', { number: index + 1 })}</span>: {step.stepTitle}
              </span>
              {step.usedProducts?.map((cfProduct, i) => {
                const searchProduct = products?.find(product => product?.id === cfProduct.productID);

                if (!searchProduct) {
                  return null;
                }

                const product = mapProductComponent(searchProduct);

                return (
                  <Fragment key={`${product.id}-product-card`}>
                    <ProductCardHorizontal
                      buttonLabel={t('order', { ns: 'common' }) as string}
                      imageSrc={product.imageSrc}
                      price={product.price}
                      slug={product.slug}
                      testId={`step-by-step-product-list-step-${index + 1}-product-${i + 1}`}
                      title={product.title}
                      translations={t('product.price', { ns: 'common', returnObjects: true })}
                    />
                    {index !== step.usedProducts.length - 1 && <Divider />}
                  </Fragment>
                );
              })}
              {!stepHasProducts && <span className="italic">{t('usedProducts.noneNeeded')}</span>}
              {index !== steps.length - 1 && <Divider />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StepByStepProductList;
