import { trackEvents } from '@boss/constants/shared';

import { getAgentSystem } from '../';

interface CommonEventProps {
  language?: string;
  visitorLoginStatus?: string;
  userId?: string;
  brand?: string;
  pageInfo?: Record<string, unknown>;
}

interface PageViewProps extends CommonEventProps {
  pageTitle?: string;
  pageType?: string;
}

interface LinkClickProps extends CommonEventProps {
  type?: string;
  label?: string;
  url?: string;
}

interface CustomEvent extends CommonEventProps {
  event: string;
}

declare const window: Window & { dataLayer: Record<string, unknown>[] };

/**
 * Class for tracking analytics events through a data layer.
 */
class Tracker {
  /**
   * Common properties that will be included in every tracking call.
   */
  private commonProps: Record<string, unknown>;

  /**
   * Creates an instance of Tracker.
   * @param {Record<string, unknown>} commonProps - Optional common properties to be used with every event.
   */
  constructor(commonProps?: Record<string, unknown>) {
    this.commonProps = commonProps ?? {};
  }

  /**
   * Pushes an event to the dataLayer if available.
   * @param {Record<string, unknown>} eventData - The data to be pushed to the data layer.
   * @private
   */
  private trackEvent(eventData: Record<string, unknown>) {
    if (typeof window === 'undefined' || !window.dataLayer) {
      return;
    }

    window.dataLayer.push(eventData);
  }

  /**
   * Gets the properties for a page view event.
   * @param {PageViewProps} props - Properties specific to the page view event.
   * @returns {Record<string, unknown>} - The properties for the page view event.
   * @private
   */
  private getPageViewProps(props: PageViewProps) {
    return {
      slug: window.location.pathname,
      pageTitle: props.pageTitle ?? document.title,
      userAgent: navigator.userAgent,
      platform: getAgentSystem(),
    };
  }

  /**
   * Tracks a page view event.
   * @param {PageViewProps} props - Properties specific to the page view event.
   */
  public trackPageView(props: PageViewProps) {
    this.trackEvent({
      event: trackEvents.PAGE_VIEWED,
      ...this.getPageViewProps(props),
      ...this.commonProps,
      ...props,
    });
  }

  /**
   * Tracks a link click event.
   * @param {LinkClickProps} props - Properties specific to the link click event.
   */
  public trackLinkClick(props: LinkClickProps) {
    this.trackEvent({
      event: trackEvents.LINK_CLICKED,
      ...this.commonProps,
      ...props,
      pageInfo: { ...this.getPageViewProps({}), ...props.pageInfo },
    });
  }

  /**
   * Tracks a custom event.
   * @param {Record<string, unknown>} eventData - The data to be pushed to the data layer.
   */
  public trackCustomEvent(eventData: CustomEvent) {
    this.trackEvent({
      ...this.commonProps,
      ...eventData,
    });
  }
}

export { Tracker };
