import { Nullable } from '@boss/types/b2b-b2c';
import { IconDefinition, faCartShopping } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import Link from '../Link';

export interface LinkProps {
  slug?: string | null;
  label?: Nullable<string>;
  icon?: Nullable<IconDefinition>;
  className?: string;
  onClick?: () => void;
}

interface UniqueProps extends LinkProps {
  className?: string;
  iconClass?: string;
  labelClass?: string;
  ariaLabel?: string;
}

interface BasketProps extends LinkProps {
  type: 'primary' | 'secondary';
  current: number;
}

export const ShoppingBasket = ({ current, slug, onClick, type }: BasketProps) => {
  return (
    <Link aria-label="shopping-basket-navlink" className="relative flex gap-2" href={slug} onClick={onClick}>
      {!!current && (
        <div
          className={twMerge(
            'rounded-1/2 h-5.5 w-5.5 font-raleway absolute -right-4 -top-4 flex items-baseline justify-center text-center font-bold text-white',
            type === 'primary' ? 'bg-red-dark' : 'bg-red-light',
          )}
        >
          {current}
        </div>
      )}
      <FontAwesomeIcon icon={faCartShopping} />
    </Link>
  );
};

export const UniqueLink = ({
  slug,
  className,
  label,
  icon,
  iconClass,
  labelClass = 'hidden lg:inline',
  onClick,
  ariaLabel,
}: UniqueProps) => (
  <Link
    aria-label={ariaLabel ? ariaLabel : `unique-link-to-${slug}`}
    className={twMerge('flex items-center gap-2 whitespace-nowrap', className)}
    href={slug}
    onClick={onClick}
  >
    {icon && <FontAwesomeIcon className={iconClass} icon={icon} />}
    {label && <span className={labelClass}>{label}</span>}
  </Link>
);

export const SecondaryLink = ({ icon, slug, label, className }: LinkProps) => (
  <Link
    aria-label={`secondary-link-to-${slug}`}
    className={twMerge('flex items-center gap-2 whitespace-nowrap', className)}
    href={slug}
  >
    {icon && <FontAwesomeIcon className="text-base" icon={icon} />}
    <span className="hidden lg:inline">{label}</span>
  </Link>
);
