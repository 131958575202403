export * from './queries';

export const productKeys = {
  productsByIds: (locale: string, ids: string[]) => ['productByIds', locale, ids],
  searchProductsById: (locale: string, ids: string[]) => ['searchProductsById', locale, ids],
  productById: (locale: string, id: string) => ['productById', locale, id],
  fullProductById: (locale: string, id: string) => ['fullProductById', locale, id],
  accountPriceInfoByProductId: (locale: string, productId: string, isLoggedIn: boolean) => [
    'accountPriceInfoByProductId',
    locale,
    productId,
    isLoggedIn,
  ],
};
