import PrimaryColorButton from './Primary';
import SecondaryColorButton from './Secondary';

type Translations = {
  trending?: string;
};

type Props = {
  active?: boolean;
  className?: string;
  backgroundColor: string;
  id?: string;
  isFavorite?: boolean;
  name?: string;
  onClick?: () => void;
  onFavorite?: () => void;
  translations?: Translations;
  trending?: boolean;
  variant?: 'primary' | 'secondary';
  loading?: boolean;
  onClickAction?: 'navigate' | 'click';
  slug?: string;
  colorType?: 'default' | 'transparant';
  trackInfo?: Record<string, string | number>;
};

export type ColorButtonProps = Omit<Props, 'variant'>;

const ColorButton = ({ variant = 'primary', ...props }: Props) => {
  if (variant === 'secondary') {
    return <SecondaryColorButton {...props} />;
  }
  return <PrimaryColorButton {...props} />;
};

export default ColorButton;
