import { faBarcodeRead } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMemo, useState } from 'react';
import { InstantSearch } from 'react-instantsearch';

import { getAlgolia } from '@boss/algolia-client';

import Modal from './Modal';
import { getProductSearchIndexName } from '../../utils';

const BarcodeScanner = () => {
  const { locale } = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('common');

  const searchClient = useMemo(() => getAlgolia({}).algoliaClient, []);
  const productIndexName = useMemo(() => getProductSearchIndexName(locale as string), [locale]);

  return (
    <InstantSearch indexName={productIndexName} searchClient={searchClient}>
      {isOpen && <Modal indexName={productIndexName} onClose={() => setIsOpen(false)} />}
      <button
        className="bg-gray-light flex flex-col items-center gap-1 rounded bg-opacity-50 px-2 py-2"
        onClick={() => setIsOpen(true)}
      >
        <FontAwesomeIcon icon={faBarcodeRead} />
        <div className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-[0.5em]">
          {t('barcodeScanner.scan')}
        </div>
      </button>
    </InstantSearch>
  );
};

export default BarcodeScanner;
