import { Repeat, Skeleton } from '@boss/ui';

const OrderdetailSkeleton = () => (
  <div className="flex flex-col justify-between gap-5">
    <Skeleton className="w-100 h-7" />
    <div>
      <div className="flex gap-5">
        <Repeat amount={3}>
          <Skeleton className="w-100 mb-5 h-7" />
        </Repeat>
      </div>
      <Repeat amount={3}>
        <div className="h-25 my-3 flex gap-2">
          <Skeleton className="w-full" />
        </div>
      </Repeat>
      <div className="flex gap-5">
        <Repeat amount={2}>
          <Skeleton className="w-100 h-50 my-3" />
        </Repeat>
      </div>
      <Skeleton className="h-50 my-3 w-1/2" />
    </div>
  </div>
);

export default OrderdetailSkeleton;
