import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type CardLabelProps = {
  children: ReactNode;
  className?: string;
  iconClassName?: string;
  icon?: IconDefinition;
  round?: 'left' | 'right';
};

const LabelStyles = cva(
  'capitalize bg-white text-textColor text-sm md:text-md leading-5 py-2 px-4 flex gap-4 items-center',
  {
    variants: {
      round: {
        left: 'rounded-tl-30 rounded-bl-30',
        right: 'rounded-tr-30 rounded-br-30',
      },
    },
  },
);

const CardLabel = ({ children, icon, iconClassName, className, round }: CardLabelProps) => (
  <div className={twMerge(LabelStyles({ round }), className)} data-testid="cardlabel">
    {icon && <FontAwesomeIcon className={iconClassName} icon={icon} />}
    <span className="font-sans font-medium">{children}</span>
  </div>
);

export default CardLabel;
