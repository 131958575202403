import { IconDefinition, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from '../_Button';

interface Props {
  href: string;
  title: string;
  body: ReactNode;
  className?: string;
  icon?: IconDefinition;
}

const FullCta = ({ title, href, className, body, icon = faArrowRight }: Props) => {
  return (
    <Button
      className={twMerge(
        'rounded-5 md:max-w-72 flex h-full flex-col gap-12 bg-white px-6 pb-6 pt-10 shadow-lg',
        className,
      )}
      href={href}
    >
      <h4>{title}</h4>
      <div className="mt-auto flex flex-col gap-4">
        {body && <div className="mr-4">{body}</div>}
        <FontAwesomeIcon className="ml-auto" icon={icon} />
      </div>
    </Button>
  );
};

export default FullCta;
