import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  packageValue: string;
  usageValueLabel?: string;
  usageValue?: string;
};

const PackagingInfo = ({ className, packageValue, usageValueLabel, usageValue }: Props) => {
  return (
    <div className={twMerge('block', className)}>
      <span className="flex">{packageValue}</span>
      {(usageValueLabel || usageValue) && (
        <span className="caption absolute flex pl-5 pt-1 italic">
          {usageValueLabel} {usageValue}
        </span>
      )}
    </div>
  );
};

export default PackagingInfo;
