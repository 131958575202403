import { formatPhoneNumber, getPhoneUri } from '@boss/utils';
import { faEnvelope, faFax, faLocationPin, faPhone, faPhoneOffice } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

type Address = {
  street: string;
  streetnumber: number;
  zipcode: string;
  city: string;
  country: string;
};
export interface ContactDetailLinksProps {
  phone?: string;
  officePhone?: string;
  email?: string;
  fax?: string;
  address?: Address;
  className?: string;
}

const linkStyle = 'hover:text-brown-dark flex gap-4 items-center';

export const ContactDetailLinks = ({ address, phone, officePhone, email, fax, className }: ContactDetailLinksProps) => {
  return (
    <div className={twMerge('flex flex-col gap-2', className)}>
      {address && (
        <a
          className={linkStyle}
          href={`https://www.google.com/maps/search/?api=1&query=${address.street}+${address.streetnumber}+${address.zipcode}+${address.city}+${address.country}`}
          rel="noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faLocationPin} width="15" />
          <div className="flex flex-col">
            <span>
              {address.street} {address.streetnumber}
            </span>
            <span>
              {address.zipcode} {address.city}
            </span>
          </div>
        </a>
      )}
      {phone && (
        <a className={linkStyle} href={getPhoneUri(phone)}>
          <FontAwesomeIcon icon={faPhone} size="sm" width="15" />
          <span className="underline decoration-1 md:no-underline md:decoration-0">{formatPhoneNumber(phone)}</span>
        </a>
      )}
      {officePhone && (
        <a className={linkStyle} href={getPhoneUri(officePhone)}>
          <FontAwesomeIcon icon={faPhoneOffice} size="sm" width="15" />
          <span className="underline decoration-1 md:no-underline md:decoration-0">
            {formatPhoneNumber(officePhone)}
          </span>
        </a>
      )}
      {fax && (
        <div className={linkStyle}>
          <FontAwesomeIcon icon={faFax} size="sm" width="15" />
          <span className="underline decoration-1 md:no-underline md:decoration-0">{formatPhoneNumber(fax)}</span>
        </div>
      )}
      {email && (
        <a className={linkStyle} href={`mailto:${email}`}>
          <FontAwesomeIcon icon={faEnvelope} size="sm" width="15" />
          <span className="underline decoration-1 md:no-underline md:decoration-0">{email}</span>
        </a>
      )}
    </div>
  );
};

export default ContactDetailLinks;
