import { twMerge } from 'tailwind-merge';

import { useSort } from '../../../hooks';
import { SortOption } from '../Sort';

type Props = {
  className?: string;
  indexName: string;
  options: SortOption[];
};

const SortButtons = ({ className, indexName, options: optionsOverwrite }: Props) => {
  const { options, refine, currentRefinement } = useSort(optionsOverwrite, indexName);

  if (!options.length) {
    return null;
  }

  return (
    <div className={twMerge('no-scrollbar flex gap-2', className)} data-testid="sorting-section">
      {options.map(option => {
        const active = option.value === currentRefinement;

        return (
          <button
            aria-label={`sort-option-${option.value}`}
            className={twMerge(
              'caption min-h-6 border-1 border-gray-soft whitespace-nowrap px-4 py-1',
              active ? 'bg-gray' : '',
            )}
            key={option.value}
            onClick={() => refine(option.value)}
          >
            {option.label}
          </button>
        );
      })}
    </div>
  );
};

export default SortButtons;
