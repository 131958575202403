import { twMerge } from 'tailwind-merge';

import { Button, Image, Link, Price } from '../..';

type Translations = {
  quantityPrefix: string;
  quantitySufix: string;
  strikePrice: string;
};

export type ProductCardHorizontalProps = {
  slug: string;
  imageSrc?: string | null;
  title?: string;
  price?: number;
  buttonLabel?: string;
  className?: string;
  translations: Translations;
  testId?: string;
};

const ProductCardHorizontal = ({
  imageSrc,
  title,
  price,
  buttonLabel,
  className,
  slug,
  translations,
  testId,
}: ProductCardHorizontalProps) => (
  <div className={twMerge('flex justify-between gap-4', className)} data-testid={testId}>
    <div className="flex gap-3">
      <Link anchorClassName="shrink-0" className="flex h-fit" href={slug}>
        <Image alt={title} className="h-20 w-20 object-contain" height={80} src={imageSrc} useNext width={80} />
      </Link>

      <Link href={slug}>
        <span className="h4">{title}</span>
      </Link>
    </div>

    <div className="flex flex-col items-end gap-2">
      {!!price && <Price className="items-end" prefix translations={translations} value={price} variant="strike" />}
      <Button
        className="h-8 w-fit py-0 text-base	"
        href={slug}
        label={buttonLabel}
        testId={testId + '-order-button'}
        type="primary"
      />
    </div>
  </div>
);

export default ProductCardHorizontal;
