import { useRouter } from 'next/router';

import { stepByStepPageSlugs, trackEvents } from '@boss/constants/b2b-b2c';
import { ISearchStepByStepPlan } from '@boss/types/b2b-b2c';
import { PageCard } from '@boss/ui';
import { appendValueToSlugsObject } from '@boss/utils';

import CommonSearchGrid from './commonSearchGrid';

type Props = {
  indexName: string;
  onUpdateTotalResults: (results: number | null) => void;
  query?: string;
};

const StepByStepSearch = ({ indexName, onUpdateTotalResults: handleResultsUpdate, query }: Props) => {
  const { locale } = useRouter();

  return (
    <CommonSearchGrid<ISearchStepByStepPlan>
      indexName={indexName}
      onUpdateTotalResults={handleResultsUpdate}
      renderCard={(page, index) => (
        <PageCard
          imageAlt={page.mainImage.alt}
          imageSrc={page.mainImage.src}
          key={page.id}
          slug={appendValueToSlugsObject(`/${page.slug}`, stepByStepPageSlugs)[locale as string]}
          title={page.title}
          trackInfo={{
            customEvent: trackEvents.SEARCH_RESULT_SELECTED,
            searchIndex: 'stepByStep',
            resultName: page.title,
            searchPosition: index + 1,
            searchQuery: query ?? '',
          }}
        />
      )}
      sortOptions={[]}
    />
  );
};

export default StepByStepSearch;
