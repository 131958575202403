import { accountPageSlugs } from '@boss/constants/b2b-b2c';
import { LocalizedSlugs } from '@boss/types/b2b-b2c';

export const COLORA_LINK = 'https://www.colora.be';

export const DESTINATIONS = ['ACCOUNT'] as const;

export const DESTINATION_MAP: Record<(typeof DESTINATIONS)[number], LocalizedSlugs> = {
  ACCOUNT: accountPageSlugs,
};
