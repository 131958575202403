import { Repeat, Skeleton } from '@boss/ui';
type Props = {
  withNavigation?: boolean;
};

const AccountSkeleton = ({ withNavigation }: Props) => (
  <div className="mb-10 flex pt-10">
    {withNavigation && (
      <div className="w-57 mx-10 hidden md:block">
        <Repeat amount={3}>
          <div className="mb-10">
            <Repeat amount={3}>
              <Skeleton className="rounded-0 mb-3 h-8 w-full" />
            </Repeat>
          </div>
        </Repeat>
      </div>
    )}
    <div className="w-full px-10">
      <Skeleton className="mb-10 h-8 w-full" />
      <div className="flex flex-wrap items-center justify-between gap-5">
        <Repeat amount={3}>
          <Skeleton className="h-50 xl:w-56.25 w-full" />
        </Repeat>
      </div>
      <Repeat amount={3}>
        <Skeleton className="mt-10 h-8 w-full" />
        <Skeleton className="mt-10 h-64 w-full" />
      </Repeat>
    </div>
  </div>
);

export default AccountSkeleton;
