import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

import { normalizePhonenumber } from '../intl';

Yup.addMethod<Yup.StringSchema>(Yup.string, 'phone', function (msg = 'This field must be a valid phonenumber.') {
  // The test should only fail when the given value is false, so no value should return a succeeding test
  return this.test('PhoneValidation', msg, value => (value ? isValidPhoneNumber(normalizePhonenumber(value)) : true));
});

declare module 'yup' {
  interface StringSchema {
    phone(msg?: string): this;
  }
}

export default Yup;
