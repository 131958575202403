import { StringWithAutoComplete } from '@boss/types/b2b-b2c';

import { FormType, useFormField, useFormSubmit } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

const FORM_FIELD_KEYS = [
  'firstname',
  'lastname',
  'address',
  'street',
  'streetnumber',
  'bus',
  'zipcode',
  'email',
  'phonenumber',
  'companyname',
  'question',
  'termsandconditions',
] as const;

type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};

type Props = {
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: FieldOverwrite;
  additionalFields?: FormField[];
  className?: string;
  type?: FormType;
};

const VRForm = ({
  fieldsToShow: initialFieldsToShow,
  fieldsOverwrite,
  additionalFields,
  className,
  type = 'technical-advice',
}: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { onSubmit, isSubmitting, isSuccess, reset } = useFormSubmit();
  const { getAddressField, firstname, lastname, phonenumber, companyname, question, termsandconditions } =
    useFormField();

  const address = getAddressField(['street', 'streetnumber', 'bus', 'zipcode']);

  const baseFields: Array<FormField> = [
    firstname,
    lastname,
    companyname,
    phonenumber,
    question,
    address,
    termsandconditions,
  ];

  const handleSubmit = (vals: FormValues) => onSubmit(type, vals);

  return (
    <DynamicForm
      className={className}
      fields={buildFormFields(baseFields, fieldsToShow, additionalFields, fieldsOverwrite)}
      id={type}
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      onCloseAlert={reset}
      onSubmit={handleSubmit}
      variant="light"
    />
  );
};

export default VRForm;
