export enum PaymentStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}

/**
 * Retrieves the payment status based on the given code.
 *
 * @param {number} code The code representing the payment status.
 * - `100` indicates a successful payment.
 * - A non-negative number (0 included) indicates a pending payment.
 * - A negative number indicates a failed payment.
 *
 * @returns {PaymentStatus} The payment status:
 * - `PaymentStatus.SUCCESS` for successful payments.
 * - `PaymentStatus.PENDING` for pending payments.
 * - `PaymentStatus.FAILED` for failed payments.
 */
export const getPaymentStatusByCode = (code: number): PaymentStatus => {
  if (code === 100) {
    return PaymentStatus.SUCCESS;
  } else if (code >= 0) {
    return PaymentStatus.PENDING;
  } else {
    return PaymentStatus.FAILED;
  }
};
