import React from 'react';

import { CorporateTabItem } from '..';
import CorporateTabNavigationTab from '../CorporateNavigationTab';

/**
 * Represents the properties of the CorporateTabsNavigationTabs component.
 *
 * @interface CorporateTabsNavigationTabsProps
 * @typedef {CorporateTabsNavigationTabsProps}
 * @property {CorporateTabitem[]} items - Items to be rendered inside the component.
 * @property {number} [currentTab] - The index of the active tab
 * @property {(index: number) => void} [navigate] - action to be done when clicking on a tab
 */
interface CorporateTabsNavigationTabsProps {
  items: CorporateTabItem[];
  currentTab?: number;
  navigate: (index: number) => void;
  buttonClassName?: string;
}

/**
 * Functional React component that displays the CorporateTabs component.
 * @param {CorporateTabsProps} props - The props for the CorporateTabs component.
 * @returns {JSX.Element} - The JSX representation of the CorporateTabs component.
 */
const CorporateTabsNavigationTabs: React.FC<CorporateTabsNavigationTabsProps> = ({
  items,
  currentTab,
  navigate,
  buttonClassName,
}) => {
  return (
    <div className="grid grid-flow-col grid-cols-1 gap-3 py-4 text-white md:auto-cols-fr lg:gap-4">
      {items.map((item: CorporateTabItem, index: number) => {
        return (
          <CorporateTabNavigationTab
            buttonClassName={buttonClassName}
            index={index}
            isActive={index === currentTab}
            item={item}
            key={`button-${index}`}
            navigate={navigate}
          />
        );
      })}
    </div>
  );
};

export default CorporateTabsNavigationTabs;
