import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { useRouter } from '@boss/hooks';
import { IWorksiteDetail } from '@boss/services/client';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';

import { useContacts, useIncidentTypes, useWorkSitesDetail } from '../../client-queries';
import { FormType, useFormField, useProfile, useServiceRequest } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

const FORM_FIELD_KEYS = [
  'clientnumber',
  'companyname',
  'kvknumber',
  'firstname',
  'lastname',
  'incidenttype',
  'preferenceDate',
  'deliverymethod',
  'new',
  'worksite',
  'deliverydate',
  'message',
  'termsandconditions',
  'street',
  'streetnumber',
  'postbox',
  'zipcode',
  'city',
  'country',
] as const;

const DELIVERY_OPTION_KEYS = ['worksite', 'address'] as const;

type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];
type DeliveryOptionKey = (typeof DELIVERY_OPTION_KEYS)[number];

type Props = {
  type: FormType;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  className?: string;
};

const excludedFieldKeysMap: Record<DeliveryOptionKey, string[]> = {
  worksite: ['address', 'new'],
  address: ['worksite'],
};

const SiteAssistanceForm = ({ fieldsToShow: initialFieldsToShow, className, type }: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { onSubmit, isSubmitting, isSuccess, reset } = useServiceRequest();
  const { t } = useTranslation('forms');
  const chooseTranslation = t('select.choose');

  const { locale } = useRouter();
  const { data: incidentTypes } = useIncidentTypes('DAS');
  const baseValues = {
    deliverymethod: 'worksite',
  };

  const deliveryMethodOptions = DELIVERY_OPTION_KEYS.map(key => ({
    value: key,
    label: t(`select.machineDeliveryOptions.${key}`),
  }));

  type FormValueTypes = Record<string, string | undefined>;

  const [values, setValues] = useState<FormValueTypes>(baseValues);

  const {
    clientnumber,
    companyname,
    firstname,
    lastname,
    street,
    streetnumber,
    bus,
    zipcode,
    city,
    country,
    termsandconditions,
  } = useFormField();
  const { data: worksites } = useWorkSitesDetail(locale);
  const { data: contacts } = useContacts(locale);

  const { data: profile } = useProfile();
  const contact = contacts?.find(contact => contact.id === profile?.extension_ContactPersonId);

  const worksiteOptions =
    worksites?.map((worksite, index) => ({
      value: index.toString(),
      label: `${worksite.street} ${worksite.streetnumber}, ${worksite.zipcode} ${worksite.city}`,
    })) ?? [];

  worksiteOptions.unshift({ value: '', label: chooseTranslation });

  const incidentOptions =
    incidentTypes?.map(incidentType => ({
      value: incidentType.id,
      label: incidentType.name,
    })) ?? [];

  incidentOptions.unshift({ value: '', label: chooseTranslation });

  const today = new Date();

  const baseFields: FormField[] = [
    {
      ...clientnumber,
    },
    {
      ...companyname,
      disabled: true,
    },
    {
      ...firstname,
      initialValue: contact?.firstname ?? '',
      disabled: true,
    },
    {
      ...lastname,
      initialValue: contact?.lastname ?? '',
      disabled: true,
    },
    {
      name: 'incidenttype',
      type: 'select',
      label: t('fields.assistancetype'),
      options: incidentOptions,
      initialValue: values?.['incidenttype'],
    },
    {
      name: 'deliverymethod',
      type: 'select',
      options: deliveryMethodOptions,
      initialValue: values?.['deliverymethod'] ?? 'worksite',
    },
    {
      name: 'worksite',
      type: 'select',
      options: worksiteOptions,
      initialValue: values?.['worksite'],
    },
    {
      name: 'new',
      type: 'address',
      fields: [
        {
          ...street,
          initialValue: values?.street,
        },
        { ...streetnumber, initialValue: values?.streetnumber },
        { ...bus, initialValue: values?.postbox },
        { ...zipcode, initialValue: values?.zipcode },
        { ...city, initialValue: values?.city },
        { ...country, initialValue: values?.country },
      ],
      colStyle: 'md:col-span-6',
      required: false,
    },
    {
      name: 'preferenceDate',
      type: 'date',
      initialValue: values?.['preferenceDate'] ?? today.toString(),
      disclaimer: t('disclaimers.preferenceDate'),
    },
    {
      name: 'message',
      type: 'textarea',
      label: t('fields.questionHelp'),
      disclaimer: t('disclaimers.colorAdvice'),
      initialValue: values?.['message'],
    },
    termsandconditions,
  ];

  const getFieldsToShow = () => {
    const fields = fieldsToShow ?? FORM_FIELD_KEYS;
    const excludedKeys = excludedFieldKeysMap[values?.deliverymethod as DeliveryOptionKey];

    return fields.filter(field => !excludedKeys?.includes(field));
  };

  const compareAddressValues = (
    values: {
      [K in FormFieldKey]?: string | undefined;
    },
    worksite: IWorksiteDetail,
  ) => {
    return (
      values.street !== worksite.street ||
      values.streetnumber !== worksite.streetnumber ||
      values.postbox !== worksite.postbox ||
      values.zipcode !== worksite.zipcode ||
      values.city !== worksite.city ||
      values.country !== worksite.country
    );
  };

  const compareObject = (
    object: Record<FormFieldKey, string | undefined>,
    values: Record<FormFieldKey, string | undefined>,
  ) => (Object.keys(object) as FormFieldKey[]).some(key => object[key] !== values[key]);

  const handleFormValuesChange = (formik: ReturnType<typeof useFormik>) => {
    const keys = Object.keys(formik.values) as FormFieldKey[];

    let setterObject: Record<string, string | undefined> = {};

    keys.forEach(key => {
      if (key === 'deliverymethod') {
        const newDeliveryMethod = DELIVERY_OPTION_KEYS.find(type => type === formik.values.deliverymethod);

        if (newDeliveryMethod && values?.deliverymethod !== newDeliveryMethod) {
          setValues({
            ...formik.values,
            deliverymethod: newDeliveryMethod,
          });
        }
      } else if (key === 'worksite') {
        const worksite = worksites?.[parseInt(formik.values.worksite)];

        if (worksite && compareAddressValues(values, worksite)) {
          setValues({
            ...formik.values,
            street: worksite.street,
            streetnumber: worksite.streetnumber,
            postbox: worksite.postbox,
            zipcode: worksite.zipcode,
            city: worksite.city,
            country: worksite.country,
            worksite: formik.values.worksite,
          });
        }
      } else if (formik.values[key] !== values?.[key]) {
        setterObject = {
          ...setterObject,
          ...values,
          [key]: formik.values[key],
        };
      }
    });

    if (Object.keys(setterObject).length === 0 || compareObject(setterObject, values)) {
      return;
    }
    setValues(setterObject);
  };

  const handleSubmit = (vals: FormValues) => {
    const submitObject = {
      ...values,
      ...vals,
      email: contact?.email ?? '',
      phonenumber: contact?.mobilephonenumber ?? '',
    };

    setValues(baseValues);

    onSubmit(type, submitObject);
  };

  return (
    <DynamicForm
      buttonProps={{
        label: t('buttons.submitQuestion') ?? '',
      }}
      className={className}
      fields={buildFormFields(baseFields, getFieldsToShow())}
      id={type}
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      onCloseAlert={reset}
      onFormValuesChange={handleFormValuesChange}
      onSubmit={handleSubmit}
      variant="light"
    />
  );
};

export default SiteAssistanceForm;
