import { Wishlist, Wishlists } from '../../types/wishlist';

// Mock Wishlist 1
const wishlist1: Wishlist = {
  id: '1',
  accountid: '12345',
  description: 'Tech Gadgets',
  type: 'favoriteproduct',
  lines: [
    {
      id: '101',
      typeid: '456',
      color: {
        colorcode: '789',
        colorname: 'Green',
        rgb: 'rgb(0, 255, 0)',
      },
      packaging: 'Box',
      quantity: 2,
    },
    {
      id: '102',
      typeid: '789',
      color: {
        colorcode: '123',
        colorname: 'Blue',
        rgb: 'rgb(0, 0, 255)',
      },
      packaging: 'Bag',
      quantity: 1,
    },
  ],
};

// Mock Wishlist 2
const wishlist2: Wishlist = {
  id: '2',
  accountid: '54321',
  description: 'Home Decor',
  type: 'color',
  lines: [
    {
      id: '201',
      typeid: '987',
      color: {
        colorcode: '123',
        colorname: 'Blue',
        rgb: 'rgb(0, 0, 255)',
      },
      packaging: 'Wrap',
      quantity: 3,
    },
    {
      id: '202',
      typeid: '654',
      color: {
        colorcode: '456',
        colorname: 'Red',
        rgb: 'rgb(255, 0, 0)',
      },
      packaging: 'Box',
      quantity: 2,
    },
  ],
};

// Mock Wishlist 3
const wishlist3: Wishlist = {
  id: '3',
  accountid: '67890',
  description: 'Outdoor Gear',
  type: 'sku',
  lines: [
    {
      id: '301',
      typeid: '321',
      color: {
        colorcode: '789',
        colorname: 'Green',
        rgb: 'rgb(0, 255, 0)',
      },
      packaging: 'Bag',
      quantity: 1,
    },
    {
      id: '302',
      typeid: '654',
      color: {
        colorcode: '456',
        colorname: 'Red',
        rgb: 'rgb(255, 0, 0)',
      },
      packaging: 'Box',
      quantity: 4,
    },
  ],
};

const wishlists: Wishlists = [wishlist1, wishlist2, wishlist3];

export default wishlists;
