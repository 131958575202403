export const toPascalCase = (inputString: string): string => {
  const words = inputString.split(/[-_\s]+/);

  // Capitalize the first letter of each word
  const pascalCasedWords = words.map((word, index) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  return pascalCasedWords.join('');
};

/**
 * Capitalize the firs char of a string, lowercase the rest.
 */
export const toTitleCase = (str: string) => {
  return str[0].toUpperCase() + str.toLowerCase().slice(1);
};
