import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { twMerge } from 'tailwind-merge';

import { Button } from '../';

type Props = {
  label: string;
  filterName: string;
  filterValue: string;
  className: string;
  onClick: (name: string, value: string) => void;
};

const SelectedButton = ({ label, filterName, filterValue, className, onClick }: Props) => (
  <Button
    className={twMerge('hover:bg-brown-dark bg-beige-light text-brown-dark hover:text-white', className)}
    icon={faXmark}
    iconPosition="right"
    label={label}
    onClick={() => onClick(filterName, filterValue)}
    type="primary"
  />
);

export default SelectedButton;
