import { twMerge } from 'tailwind-merge';

import Image from '../../Image';
import { HeroProps } from '../index';

const HeroPrimary = ({
  title,
  image,
  imageAlt,
  backgroundColor,
  className,
  imageClassName,
  imageWrapperClassName,
}: HeroProps) => (
  <div
    className={twMerge(
      'rounded-r-brand rounded-bl-brand relative flex min-h-[300px] w-full flex-row bg-gray-600 ',
      className,
    )}
    style={{ backgroundColor }}
  >
    <h1 className="h3 z-20 flex w-full shrink-0 items-center justify-center text-white md:w-2/5 md:w-3/5 md:pl-24 md:pl-6">
      {title}
    </h1>
    {image && (
      <div className="absolute inset-x-0 inset-y-0 z-0 md:relative md:w-2/5 ">
        <Image
          alt={imageAlt}
          className={twMerge('rounded-r-brand object-contain', imageClassName)}
          focalpoint="right"
          src={image}
          wrapperClassName={imageWrapperClassName}
        />
      </div>
    )}
  </div>
);

export default HeroPrimary;
