import { useFormik } from 'formik';

import { IAddress } from '@boss/types/b2b-b2c';

import AddressSuggestion from './AddressSuggestion';
import { useFormField } from '../../hooks';
import FormFieldMapper, { AddressBlockProps } from '../Mappers/FormFieldMapper';

export type AddressBlockprops = AddressBlockProps & {
  formik: ReturnType<typeof useFormik>;
  formId?: string;
  name?: string;
  testId?: string;
  variant?: 'light' | 'dark' | 'transparent';
};

const AddressBlock = ({ formik, formId, name, testId, fields, variant, scope }: AddressBlockprops) => {
  const { getConvertedCountry } = useFormField();

  const onSelectSuggestion = async (suggestion: IAddress) => {
    const suggestionKeys = Object.keys(suggestion);

    fields.forEach(field => {
      // we're clearing all fields except the addressSuggestion field
      if (field.name !== 'addressSuggestion') {
        formik.setFieldValue(field.name, '');
        formik.setTouched({ ...formik.touched, [field.name]: false });
      }
    });

    suggestionKeys.forEach(async key => {
      const combinedKey = scope ? `${scope}-${key}` : key;
      const foundField = fields.find(field => field.name === combinedKey);

      if (foundField) {
        const fieldValue = getConvertedCountry(foundField.key ?? foundField.name, suggestion[key as keyof IAddress]);

        if (fieldValue === '' && foundField.required) {
          formik.setFieldError(combinedKey, 'Required');
          formik.setTouched({ ...formik.touched, [combinedKey]: true });
        } else {
          await formik.setFieldValue(
            combinedKey,
            getConvertedCountry(foundField.key ?? foundField.name, suggestion[key as keyof IAddress]),
          );
          await formik.validateField(combinedKey);
          const helpers = formik.getFieldHelpers(combinedKey);

          helpers.setError(undefined);
          helpers.setTouched(false);
        }
      }
    });
  };

  return (
    <div className="flex flex-col gap-5 md:grid md:grid-cols-6" data-test-id={testId}>
      <div className="col-span-3 grid gap-5 md:col-span-6 md:grid-cols-6">
        <AddressSuggestion
          className="md:col-span-3"
          name={name}
          onSelectSuggestion={onSelectSuggestion}
          scope={scope}
        />
      </div>
      {fields.map(field => (
        <FormFieldMapper
          className={field.colStyle ?? 'md:col-span-3'}
          field={field}
          formId={formId}
          formik={formik}
          key={field.name}
          variant={variant}
        />
      ))}
    </div>
  );
};

AddressBlock.displayName = 'AddressBlock';

export default AddressBlock;
