import React from 'react';
import { twMerge } from 'tailwind-merge';

import { CorporateTabItem } from '..';
import CorporateTabNavigationTab from '../CorporateNavigationTab';

/**
 * Represents the properties of the CorporateTabsNavigationTabs component.
 * @date 4-1-2024 - 16:25:11
 *
 * @interface CorporateTabsNavigationTabsProps
 * @typedef {CorporateTabsNavigationTabsProps}
 * @property {CorporateTabitem[]} items - Items to be rendered inside the component.
 * @property {number} [currentTab] - The index of the active tab
 * @property {(index: number) => void} [navigate] - action to be done when clicking on a tab
 */
interface CorporateTabsNavigationTabsProps {
  items: CorporateTabItem[];
  currentTab?: number;
  navigate: (index: number) => void;
  className?: string;
  buttonClassName?: string;
}

/**
 * Functional React component that displays an CorporateTabs component.
 * @param {CorporateTabsProps} props - The props for the CorporateTabs component.
 * @returns {JSX.Element} - The JSX representation of the CorporateTabs component.
 */
const CorporateTabsNavigationDropdown: React.FC<CorporateTabsNavigationTabsProps> = ({
  items,
  currentTab,
  navigate,
  className,
  buttonClassName,
}) => {
  return (
    <div className={twMerge('grid grid-cols-1 gap-4 py-4', className)}>
      {items.map((item: CorporateTabItem, index: number) => {
        return (
          <div className="w-full" key={`dropdown-${index}`}>
            <CorporateTabNavigationTab
              buttonClassName={buttonClassName}
              index={index}
              isActive={index === currentTab}
              item={item}
              navigate={navigate}
            />
            {currentTab === index && <div className="pb-2 pt-6">{item.content}</div>}
          </div>
        );
      })}
    </div>
  );
};

export default CorporateTabsNavigationDropdown;
