import { Repeat, Skeleton } from '@boss/ui';

interface Props {
  numberOfCards?: number;
}

const ArticlesLoader = ({ numberOfCards = 5 }: Props) => {
  return (
    <Repeat amount={numberOfCards}>
      <Skeleton className="h-50 mb-2 w-full" />
    </Repeat>
  );
};

export default ArticlesLoader;
