import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false, authScope: process.env.BOSS_API_AUTH_SCOPE_SERVICE };

export const saveServiceRequest = (data: Record<string, unknown>) => {
  return bossApi(`/service/v2/services/service-requests`, {
    ...DEFAULT_OPTIONS,
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
    body: data,
  });
};
