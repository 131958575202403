export const DEFAULT_TRANSITION_TIMING = {
  ease: [0.15, 1, 0.3, 1],
  duration: 1,
};

export const TRANSITION_TIMING_FAST = {
  ease: [0.15, 1, 0.3, 1],
  duration: 0.5,
};

export const DEFAULT_EXIT_ANIMATION = {
  opacity: 0,
};

export const DEFAULT_ANIMATE_ANIMATION = {
  opacity: 1,
};

export const DEFAULT_TRANSITION_PROPS = {
  type: 'spring',
  stiffness: 400,
  damping: 40,
};

export const COLLAPSE_GRID_ANIMATE = {
  opacity: 1,
  height: 'auto',
};

/**
 * Adding a negative margin might be necessary to avoid a layout shift on exit
 **/
export const COLLAPSE_GRID_EXIT = {
  opacity: 0,
  height: 0,
};

export const COLLAPSE_GRID_INITIAL = { opacity: 0, height: 'auto' };

export const COLLAPSE_GRID = {
  exit: COLLAPSE_GRID_EXIT,
  animate: COLLAPSE_GRID_ANIMATE,
  initial: COLLAPSE_GRID_INITIAL,
};

export const getCollapsePropsWithOffset = (offset: string) => ({
  ...COLLAPSE_GRID,
  exit: { ...COLLAPSE_GRID.exit, marginTop: offset || '0px' },
});
