import { useTranslation } from 'next-i18next';

import { trackEvents } from '@boss/constants/shared';
import { useRouter } from '@boss/hooks';
import { ISearchColor, SimpleColor } from '@boss/types/b2b-b2c';
import { ColorButton } from '@boss/ui';

import CommonSearchGrid from './commonSearchGrid';
import { useFavorites } from '../../hooks';
import { mapToSimpleColor } from '../../utils';

interface Props {
  indexName: string;
  onUpdateTotalResults: (results: number | null) => void;
  query?: string;
}

const ColorSearch = ({ indexName, onUpdateTotalResults: handleResultsUpdate, query }: Props) => {
  const { t } = useTranslation('color');
  const translations = t('grid', { ns: 'color', returnObjects: true });
  const { locale } = useRouter();
  const { favorites, toggleFavorite } = useFavorites();

  return (
    <CommonSearchGrid<ISearchColor, SimpleColor>
      indexName={indexName}
      onUpdateTotalResults={handleResultsUpdate}
      renderCard={(color, index) => (
        <ColorButton
          backgroundColor={color.rgb}
          id={color.code}
          isFavorite={favorites?.COLORS?.includes(color.code?.toString())}
          key={`colors-grid-${color.code}-${color.name}`}
          name={color.name}
          onClickAction="navigate"
          onFavorite={() => toggleFavorite('COLORS', color.code, color)}
          slug={color.slug}
          trackInfo={{
            customEvent: trackEvents.SEARCH_RESULT_SELECTED,
            searchIndex: 'colors',
            resultName: color.name,
            searchPosition: index + 1,
            searchQuery: query ?? '',
          }}
          translations={translations}
          trending={color.trending}
          variant="secondary"
        />
      )}
      transformer={color => mapToSimpleColor(color, locale)}
    />
  );
};

export default ColorSearch;
