export interface ISearchObject {
  objectID: string;
  status?: SearchObjectStatus;
  lastUpdated?: string;
  [index: string]: unknown; // explicit index signature, required for useHits hook. (further reading, https://www.totaltypescript.com/type-vs-interface-which-should-you-use#index-signatures-in-types-vs-interfaces)
}

export enum SearchObjectStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}
