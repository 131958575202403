import { SectionDuplexProps, SectionFocus } from '@boss/types/b2b-b2c';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

const GridStyle = cva('grid grid-cols-1 md:grid-cols-2 gap-6', {
  variants: {
    withFocus: { true: 'md:grid-cols-3' },
  },
});

const SectionDuplex = ({ contentA, contentB, focus, className }: SectionDuplexProps) => {
  const focusStyling = 'md:col-span-2';

  const hasFocus = (value: SectionFocus) => focus === value;

  return (
    <div className={twMerge(GridStyle({ withFocus: !!focus }), className)}>
      <div className={twMerge(hasFocus('A') ? focusStyling : '', 'relative flex h-full')}>{contentA}</div>
      <div className={twMerge(hasFocus('B') ? focusStyling : '', 'relative flex h-full')}>{contentB}</div>
    </div>
  );
};

export default SectionDuplex;
