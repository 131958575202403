import { useTranslation } from 'next-i18next';

import { useRouter } from '@boss/hooks';

import { useAccount, useContacts } from '../../client-queries';
import { TermsAndConditionsLink } from '../../components';
import { FormField } from '../../components/Mappers/FormFieldMapper';
import { useProfile } from '../../hooks';
import { isB2b } from '../../utils';
import { useCountry } from '../use-country/use-country';

type FormFieldConfig = {
  enableInitialValues?: boolean;
  customOverwrite?: Record<string, Partial<FormField>>; // Overwrite default settings for single exceptions
};

const DEFAULT_CONFIG: FormFieldConfig = {
  enableInitialValues: true,
};

const applyConfig = <T extends Record<string, FormField>>(
  fields: T,
  config: FormFieldConfig,
): { [K in keyof T]: T[K] } => {
  return Object.fromEntries(
    Object.entries(fields).map(([key, formField]) => {
      const { initialValue, ...rest } = formField;

      if (config.customOverwrite && config.customOverwrite[key]) {
        const updatedField = {
          ...rest,
          ...config.customOverwrite[key],
          ...(config.enableInitialValues && { initialValue }),
        };

        return [key, updatedField];
      }

      const updatedField = config.enableInitialValues ? { ...rest, initialValue } : rest;

      return [key, updatedField];
    }),
  ) as { [K in keyof T]: T[K] };
};

enum Country {
  EMPTY = '',
  BEL = 'BEL',
  NLD = 'NLD',
  FRA = 'FRA',
  LUX = 'LUX',
  GER = 'GER',
}

export enum CountryValidationValues {
  BEL = 'BE',
  NLD = 'NL',
  FRA = 'FR',
  LUX = 'LU',
  GER = 'DE',
}

const CountryValues = [
  'België',
  'Nederland',
  'Frankrijk',
  'Luxemburg',
  'Duitsland',
  'Belgique',
  'Pays-Bas',
  'France',
  'Luxembourg',
  'Allemagne',
];

const mapCountryValue = (val: string): Country => {
  if (val.includes('België') || val.includes('Belgique')) {
    return Country.BEL;
  }
  if (val.includes('Nederland') || val.includes('Pays-Bas')) {
    return Country.NLD;
  }
  if (val.includes('Frankrijk') || val.includes('France')) {
    return Country.FRA;
  }
  if (val.includes('Luxemburg') || val.includes('Luxembourg')) {
    return Country.LUX;
  }
  if (val.includes('Duitsland') || val.includes('Allemagne')) {
    return Country.GER;
  }
  return Country.EMPTY;
};

const getConvertedCountry = (fieldName: string, val?: string | number) => {
  if (fieldName === 'country') {
    if (val && typeof val === 'string' && CountryValues.includes(val)) {
      return mapCountryValue(val);
    } else if (Object.values(Country).includes(val as Country)) {
      return val as string;
    }
    return Country.EMPTY;
  }

  return val as string;
};

/**
 * useFormField hook which exports generic form fields
 */

export const useFormField = (givenConfig?: FormFieldConfig) => {
  const config = { ...DEFAULT_CONFIG, ...givenConfig };
  const { t } = useTranslation('forms');
  const { locale } = useRouter();
  const { data: account } = useAccount(locale);

  const { countryName } = useCountry();
  const { accountinfo, addresses, accountnumber } = account || {};

  const { data: contacts } = useContacts(locale);
  const { data: profile } = useProfile();
  const contactinfo = contacts?.find(contact => contact.id === profile?.extension_ContactPersonId);

  const invoiceAddr = addresses?.find(adr => adr.type === 'invoice') ?? null;
  const singleColumnStyling = 'md:col-span-1';

  const salutationOptions = [
    { label: '', value: '' },
    { label: t('select.salutation.mrs'), value: 'mrs' },
    { label: t('select.salutation.mr'), value: 'mr' },
  ];

  const salutation: FormField = {
    name: 'salutation',
    key: 'salutation',
    type: 'select',
    options: salutationOptions,
    initialValue: salutationOptions[0].value,
  };

  const firstname: FormField = {
    name: 'firstname',
    key: 'firstname',
    initialValue: contactinfo?.firstname ?? accountinfo?.firstname,
    type: 'input',
  };

  const lastname: FormField = {
    name: 'lastname',
    key: 'lastname',
    initialValue: contactinfo?.lastname ?? accountinfo?.lastname,
    type: 'input',
  };

  const name: FormField = {
    name: 'name',
    key: 'name',
    initialValue: accountinfo?.name,
    type: 'input',
    disabled: isB2b && !!accountinfo?.name,
  };

  const contactname: FormField = {
    name: 'contact',
    key: 'contactpersonid',
    initialValue: `${contactinfo?.firstname} ${contactinfo?.lastname}`,
    type: 'input',
  };

  const password: FormField = {
    name: 'password',
    key: 'password',
    type: 'input',
    validationType: 'password',
  };

  const addressSuggestion: FormField = {
    name: 'addressSuggestion',
    key: 'addressSuggestion',
    type: 'addressSuggestion',
    required: false,
  };

  const street: FormField = {
    name: 'street',
    key: 'street',
    type: 'input',
    initialValue: invoiceAddr?.street,
  };

  const streetnumber: FormField = {
    name: 'streetnumber',
    key: 'streetnumber',
    type: 'input',
    initialValue: invoiceAddr?.streetnumber,
    colStyle: singleColumnStyling,
  };

  const bus: FormField = {
    name: 'bus',
    key: 'bus',
    type: 'input',
    colStyle: singleColumnStyling,
    initialValue: invoiceAddr?.postbox,
    required: false,
  };

  const zipcode: FormField = {
    name: 'zipcode',
    key: 'zipcode',
    type: 'input',
    initialValue: invoiceAddr?.zipcode,
    colStyle: singleColumnStyling,
  };

  const city: FormField = {
    name: 'city',
    key: 'city',
    type: 'input',
    initialValue: invoiceAddr?.city,
  };

  const countryOptions = [
    { label: '', value: '' },
    { label: t('select.countries.be'), value: Country.BEL },
    { label: t('select.countries.nl'), value: Country.NLD },
    { label: t('select.countries.fr'), value: Country.FRA },
    { label: t('select.countries.lu'), value: Country.LUX },
    { label: t('select.countries.de'), value: Country.GER },
  ];

  const country: FormField = {
    name: 'country',
    key: 'country',
    type: 'select',
    options: countryOptions,
    initialValue: invoiceAddr?.country ?? getConvertedCountry('country', countryName),
  };

  const address: FormField = {
    name: 'address',
    key: 'address',
    type: 'address',
    fields: [addressSuggestion, street, streetnumber, bus, zipcode, city, country],
    colStyle: 'md:col-span-6',
    required: false,
  };

  const getAddressField = (fields: string[]): FormField => {
    const fieldObject = {
      street: street,
      streetnumber: streetnumber,
      bus: bus,
      zipcode: zipcode,
      city: city,
      country: country,
    };

    const usedFields: FormField[] = [];

    fields.forEach(field => {
      usedFields.push(fieldObject[field as keyof typeof fieldObject]);
    });

    return { ...address, fields: usedFields };
  };

  const email: FormField = {
    name: 'email',
    key: 'email',
    initialValue: contactinfo?.email,
    validationType: 'email',
    type: 'input',
  };

  const phonenumber: FormField = {
    name: 'phonenumber',
    key: 'phonenumber',
    initialValue: contactinfo?.officephonenumber ?? contactinfo?.mobilephonenumber,
    type: 'phone',
    required: true,
  };

  const newsletter: FormField = {
    label: t('fields.subscribeNewsletter'),
    name: 'newsletter',
    key: 'newsletter',
    type: 'checkbox',
    required: false,
  };

  const termsandconditions: FormField = {
    label: <TermsAndConditionsLink />,
    name: 'termsandconditions',
    key: 'termsandconditions',
    type: 'checkbox',
    required: true,
    colStyle: 'md:col-span-full w-fit ml-auto',
  };

  const clientnumber: FormField = {
    name: 'clientnumber',
    key: 'clientnumber',
    type: 'input',
    validationType: 'number',
    initialValue: accountnumber,
    disabled: isB2b && !!accountnumber,
  };

  const companyname: FormField = {
    name: 'companyname',
    key: 'companyname',
    type: 'input',
    initialValue: accountinfo?.name ?? '',
    disabled: isB2b && !!accountinfo?.name,
  };

  const kvknumber: FormField = {
    name: 'kvknumber',
    key: 'kvknumber',
    placeholder: t('placeholders.kvknumber') ?? '',
    type: 'input',
    required: false,
  };

  const orderreference: FormField = {
    name: 'orderreference',
    key: 'orderreference',
    type: 'input',
  };

  const question: FormField = {
    name: 'message',
    key: 'question',
    type: 'textarea',
  };

  const message: FormField = {
    name: 'message',
    key: 'message',
    type: 'textarea',
  };

  const appliedFields = applyConfig(
    {
      salutation,
      firstname,
      lastname,
      name,
      password,
      address,
      street,
      streetnumber,
      bus,
      zipcode,
      city,
      country,
      email,
      phonenumber,
      newsletter,
      termsandconditions,
      clientnumber,
      companyname,
      kvknumber,
      orderreference,
      question,
      message,
      contactname,
    },
    config,
  );

  return {
    getAddressField,
    getConvertedCountry,
    ...appliedFields,
  };
};
