import { useRouter } from 'next/router';

type Props = {
  environment?: string;
  preview?: boolean;
};

const RefreshBanner = ({ environment = 'test', preview }: Props) => {
  const { asPath, reload, push, locale } = useRouter();
  const pagePath = `/${locale}${asPath}`;
  const refresh = async () => {
    await fetch(`/api/revalidate?path=${pagePath}`, { method: 'POST' });
    reload();
  };
  const exitPreview = async () => {
    await push(`/api/exit-preview?path=${pagePath}`);
  };

  return (
    <div
      className="z-nav fixed top-0 flex h-[3rem] w-full items-center gap-x-6 bg-gray-900 px-6 py-2.5 text-sm uppercase text-white sm:px-3.5 sm:before:flex-1"
      id="dev-tools-bar"
    >
      <div className="hidden md:block">
        <div> {`Environment: ${environment}`}</div>
        <div>{`Preview mode: ${preview ? 'Enabled' : 'disabled'}`}</div>
      </div>
      {preview && (
        <button
          className="rounded bg-white px-4 py-[0.3rem] text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={exitPreview}
          type="button"
        >
          Exit preview
        </button>
      )}
      <button
        className="rounded bg-white px-4 py-[0.3rem] text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={refresh}
        type="button"
      >
        Sync with Contentful
      </button>
    </div>
  );
};

export default RefreshBanner;
