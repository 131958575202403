import * as Sentry from '@sentry/browser';
import parsePhoneNumber from 'libphonenumber-js';

const throwPhoneException = (tel: string) => {
  const ErrorMessage = 'Could not get country from phonenumber';

  Sentry.captureMessage(ErrorMessage);
  throw new Error(ErrorMessage + ' ' + tel);
};

const getCountryFromPhoneNumber = (phoneNumber: string): 'NL' | 'BE' => {
  switch (phoneNumber.substring(0, 4)) {
    case '0032':
      return 'BE';
    case '0031':
      return 'NL';
    default:
      return throwPhoneException(phoneNumber);
  }
};

const formatPhoneNumber = (tel: string): string => {
  try {
    const phoneNumber = parsePhoneNumber(tel, getCountryFromPhoneNumber(tel));

    return phoneNumber ? phoneNumber.formatInternational() : tel;
  } catch {
    return tel;
  }
};

const getPhoneUri = (tel: string): string => {
  try {
    const phoneNumber = parsePhoneNumber(tel, getCountryFromPhoneNumber(tel));

    return phoneNumber ? phoneNumber.getURI() : tel;
  } catch {
    return tel;
  }
};

export { formatPhoneNumber, getPhoneUri };
