import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { colorToolSlugs } from '@boss/constants/b2b-b2c';
import { Color, SectionFields } from '@boss/types/b2b-b2c';
import { Button, Section } from '@boss/ui';
import { toTitleCase } from '@boss/utils';

import { ComponentMapper } from '../Mappers';

type Props = {
  locale: string;
  entry: SectionFields;
  selectedColor: Color | undefined;
};

const createColorUri = (selectedColor: Color | undefined) => {
  if (!selectedColor) {
    return '';
  }

  const { rgb, code, name } = selectedColor;
  const colorCodeArr = code.split(' ');
  const rgbVal = rgb.replace('#', '');

  colorCodeArr[0] = toTitleCase(colorCodeArr[0]);
  const colorCode = colorCodeArr.join(' ');

  // The trailing comma's are intentional, BOSS uses an external tool, that nobody is maintaining.
  return `?colors=${rgbVal},${colorCode} ${name},,,,,`;
};

const ColorToolSection = ({ entry, locale, selectedColor }: Props) => {
  const { t } = useTranslation('colorTool');
  const toolHref = `${colorToolSlugs[locale]}${createColorUri(selectedColor)}`;

  return (
    <Section
      backgroundColor="bg-green-light"
      content={entry.content?.map(item =>
        item.__typename === 'image' ? (
          <Link href={`/${toolHref}`} key={item.id}>
            <ComponentMapper
              additionalProps={{ parent: entry.__typename, parentDisplay: '2-columns', colorToolLink: toolHref }}
              entry={item}
              key={item.id}
              locale={locale}
            />
          </Link>
        ) : (
          <ComponentMapper
            additionalProps={{ parent: entry.__typename, parentDisplay: '2-columns', colorToolLink: toolHref }}
            entry={item}
            key={item.id}
            locale={locale}
          />
        ),
      )}
      cta={<Button href={toolHref} label={t('buttonText')} type="primary" />}
      displayAs={'2-columns'}
      id={entry.id}
    />
  );
};

export default ColorToolSection;
