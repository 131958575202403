import { faSave } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

import { useProfile } from '@boss/auth';
import { useRouter } from '@boss/hooks';
import { Button, InputField, Modal } from '@boss/ui';

import { useUpdatePaintguideResults } from '../../client-queries';
import { useLogin } from '../../hooks';

type Props = { className?: string };

const SavePaintguideButton = ({ className }: Props) => {
  const { t } = useTranslation(['paintguide', 'common', 'product']);
  const [showSaveResultModal, setShowSaveResultModal] = useState(false);
  const [resultDescription, setResultDescription] = useState('');
  const { asPath } = useRouter();
  const { mutate: updatePaintguideResults } = useUpdatePaintguideResults();
  const { handleLogin } = useLogin();
  const { isLoggedIn } = useProfile();

  const handleSavebutton = (e?: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
    e?.preventDefault();
    updatePaintguideResults({ description: resultDescription });
    setShowSaveResultModal(false);
  };

  const handleOpenSaveResultsModal = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    if (isLoggedIn) {
      setShowSaveResultModal(true);
    } else {
      handleLogin(asPath);
    }
  };

  return (
    <>
      <Button
        className={twMerge(className, 'ml-auto px-6 py-4')}
        icon={faSave}
        iconPosition="left"
        label={isLoggedIn ? t('saveResults') : t('login')}
        onClick={handleOpenSaveResultsModal}
        type="secondary"
      />
      {showSaveResultModal && (
        <Modal className="p-15 md:p-20" onClose={() => setShowSaveResultModal(false)}>
          <>
            <h4 className="mb-8">{t('description')}</h4>
            <div className="flex flex-col md:flex-row">
              <InputField
                className="w-full md:w-1/2"
                name="list-name"
                onChange={e => setResultDescription(e.target.value)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    handleSavebutton();
                  }
                }}
                value={resultDescription}
                variant="light"
              />
              <Button
                className="py-2.75 mt-8 h-full md:ml-4 md:mt-0"
                disabled={!resultDescription}
                icon={faSave}
                iconPosition="left"
                label={t('saveResults')}
                onClick={handleSavebutton}
                type="primary"
              />
            </div>
          </>
        </Modal>
      )}
    </>
  );
};

export default SavePaintguideButton;
