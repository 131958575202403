import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useHits, useSearchBox } from 'react-instantsearch';

import { stepByStepPageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { OverviewFields, StepByStepPlan } from '@boss/types/b2b-b2c';
import { PageCard, Section } from '@boss/ui';
import { appendValueToSlugsObject } from '@boss/utils';

import { ComponentMapper, RecentlyViewedProducts, SearchGrid } from '../../components';
import { useSearchAttributes } from '../../hooks';

type Props = {
  indexName: string;
  cmsData?: OverviewFields | null;
};

const RoadmapOverviewPage = ({ indexName, cmsData }: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation(['routes']);
  const { attributes } = useSearchAttributes();
  const { refine } = useSearchBox();
  const { results } = useHits<StepByStepPlan>();
  const steps = results?.hits;
  const { locale } = useRouter();

  const handleSearch = () => {
    refine(searchValue);
  };

  return (
    <div>
      <Section content={<h1>{t('overviewTitle')}</h1>} />
      <Section
        className="pt-0"
        content={
          <SearchGrid
            attributes={attributes}
            indexName={indexName}
            internalSearch
            onSearch={handleSearch}
            searchLabel={t('search.placeholder') ?? ''}
            searchPrefixLabel={t('search.prefixLabel') ?? ''}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            sortOptions={[]} // TODO: Implement sorting
          >
            {steps?.map(step => (
              <PageCard
                imageAlt={step.mainImage.alt}
                imageSrc={step.mainImage.src}
                key={step.id ?? step.title}
                slug={appendValueToSlugsObject(`/${step.slug}`, stepByStepPageSlugs)[locale]}
                title={step.title}
              />
            ))}
          </SearchGrid>
        }
      />

      {cmsData?.prefixSections?.map(entry => (
        <ComponentMapper entry={entry} key={entry.id} locale={locale} />
      ))}

      <RecentlyViewedProducts />

      {cmsData?.suffixSections?.map(entry => (
        <ComponentMapper entry={entry} key={entry.id} locale={locale} />
      ))}
    </div>
  );
};

export default RoadmapOverviewPage;
