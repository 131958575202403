import * as Sentry from '@sentry/nextjs';

import { withSession } from '@boss/auth';
import { quotation as quotationService } from '@boss/services/client';

/**
 * Client query to fetch all quotations from an account
 *
 */
const fetchQuotations = withSession(
  async (accountId: string) => {
    try {
      return await quotationService.get(accountId);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          type: 'Fetch quotations',
        },
      });

      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client query to fetch the quotation file link
 *
 */
const fetchQuotationLink = async (quotationId: string, locale: string) => {
  try {
    return await quotationService.getFileLink(quotationId, locale);
  } catch (error) {
    console.error(error);
    Sentry.captureException(error, {
      tags: {
        type: 'Fetch quotations',
      },
    });

    throw error;
  }
};

export { fetchQuotations, fetchQuotationLink };
