import { useTranslation } from 'next-i18next';

import { ColorsGridSkeleton, Presence, Section } from '@boss/ui';

import { ColorGridWrapper } from '..';
import { useRecentViewedColors } from '../../hooks';

const RecentlyViewedColors = () => {
  const { viewedColors } = useRecentViewedColors();
  const { t } = useTranslation('common');

  return (
    <Presence
      id="recently-viewed-colors-presence"
      loader={<ColorsGridSkeleton amount={10} gridClassName="md:grid-cols-5" hasTitle={false} variant="secondary" />}
      visible={!!viewedColors?.length}
    >
      <Section
        className="flex w-full flex-col gap-10"
        content={
          <ColorGridWrapper
            colors={viewedColors}
            gridClassName="grid-cols-2 sm:grid-cols-3 md:grid-cols-6"
            hasShowMore={false}
            id="recently-viewed-colors-grid"
            onClickAction="navigate"
            title={t('recentlyViewedColors') ?? undefined}
            titleClassName="h3"
            translations={t('colorPickerModal', { ns: 'product', returnObjects: true })}
            variant="secondary"
          />
        }
        testId="recently-viewed-colors"
      />
    </Presence>
  );
};

export default RecentlyViewedColors;
