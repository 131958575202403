import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  icon?: IconProp;
  title: string;
};

const IconCard = ({ title, className, icon }: Props) => (
  <div
    className={twMerge(
      'min-h-22 md:min-h-35 shadow-m flex h-full w-full flex-col items-center justify-center gap-2.5 p-2.5 text-center md:gap-4',
      className,
    )}
  >
    {icon && <FontAwesomeIcon className="md:text-2xl" icon={icon} />}
    <span className="small md:leading-24 md:text-xl">{title}</span>
  </div>
);

export default IconCard;
