import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const definition: IconDefinition = {
  prefix: 'far', // Needs something predefined. Take fas. Has no value
  iconName: 'leaf', // Needs something predefined. Take closest value
  icon: [
    17, // Viewport width
    14, // Viewport height
    [], // Aliasses (no relevance for this approach) dummy value
    '2b', // unicode char (no relevance for this approach) dummy value
    // SVG Path or an array of SVG Paths
    'M16.1875 0.25C16.0938 0.09375 15.9375 0 15.7812 0C15.6562 0 15.5 0.09375 15.4062 0.25C14.625 1.59375 13.2188 2.28125 11.7812 2.28125C11.4375 2.28125 11.0938 2.25 10.75 2.15625C10.3438 2.0625 9.90625 2 9.5 2C6.4375 2 4 4.46875 4 7.53125C4 7.78125 4 8.0625 4.0625 8.3125C1.5625 10.5 1 13.0938 1 13.125C0.90625 13.5312 1.1875 13.9062 1.59375 14C1.625 14 1.6875 14 1.75 14C2.09375 14 2.40625 13.7812 2.46875 13.4062C2.5 13.2812 2.90625 11.5312 4.53125 9.875C5.40625 11.75 7.3125 13 9.5 13C10.1875 13 10.875 12.875 11.5 12.625C14.7812 11.5312 17 8.1875 17 4.1875C17 2.8125 16.6875 1.40625 16.1875 0.25ZM11.0312 11.1875H11L10.9375 11.2188C10.4688 11.4062 9.96875 11.5 9.46875 11.5C7.75 11.5 6.3125 10.375 5.75 8.84375C7.03125 7.96875 8.8125 7.21875 11.2812 7C11.6875 6.9375 12 6.59375 11.9375 6.15625C11.9062 5.75 11.5312 5.46875 11.125 5.5C8.71875 5.71875 6.90625 6.375 5.46875 7.25C5.59375 5.15625 7.34375 3.5 9.46875 3.5C9.78125 3.5 10.0938 3.53125 10.375 3.59375C10.8125 3.6875 11.2812 3.75 11.75 3.75C13.0938 3.75 14.3125 3.3125 15.3125 2.5625C15.4375 3.09375 15.5 3.65625 15.5 4.1875C15.5 7.5 13.6875 10.3125 11.0312 11.1875Z',
  ],
};

export default definition;
