import { ComponentProps, useEffect, useState } from 'react';

import { useRouter } from '@boss/hooks';
import { IStore } from '@boss/services';
import { SimpleColor } from '@boss/types/b2b-b2c';

import {
  useAccountStore,
  useArticlesByProductAndColor,
  useColorByColorTypeGroup,
  useColorsByColorCodes,
  useSearchColor,
} from '../../client-queries';
import { SurfaceCalculatorModal } from '../../components';
import { ArticleIdsObject } from '../../components/ArticlesTable';
import { COLOR_TYPE } from '../../constants';
import { useColors, useFavorites, usePrice, useRecentViewedColors, useSpecification, useStock } from '../../hooks';

type Props = {
  showBaseColors: boolean;
  isPaint: boolean;
  productId: string;
  selectedArticleIdsObject: ArticleIdsObject;
  selectedColor: SimpleColor | undefined;
  hasArticles?: boolean;
  colorTypes: string[] | null;
};

const useProductColors = ({
  showBaseColors,
  colorTypes,
  colorId,
  colorGroupType,
  productId,
}: {
  showBaseColors: boolean;
  colorTypes: string[] | null;
  colorId?: string;
  colorGroupType?: string;
  productId: string;
}) => {
  const {
    colorBase,
    setSelectedColorGroup,
    showColors,
    productOptions,
    selectedColorGroup,
    handleProductOptionClick,
    activeProductOption,
    preSelectedColor,
    preSelectedColorIsLoading,
  } = useColors({
    showBaseColors,
    colorTypes,
    colorId,
    colorGroupType,
    productId,
  });

  return {
    colorBase,
    setSelectedColorGroup,
    showColors,
    productOptions,
    selectedColorGroup,
    handleProductOptionClick,
    activeProductOption,
    preSelectedColor,
    preSelectedColorIsLoading,
  };
};

const useArticles = ({
  selectedColor,
  productId,
  activeProductOption,
  isPaint,
  hasArticles,
  isReady,
}: {
  selectedColor: SimpleColor | undefined;
  productId: string;
  activeProductOption: { key: string; value: string } | undefined;
  isPaint: boolean;
  hasArticles?: boolean;
  isReady: boolean;
}) => {
  return useArticlesByProductAndColor(
    {
      colorId: selectedColor?.id ?? '',
      colorCode: selectedColor?.code ?? '',
      productId,
      colorType: activeProductOption?.key ?? '',
      isPaint,
    },
    !!(hasArticles && (activeProductOption || !isPaint) && isReady),
  );
};

export function useProductData({
  showBaseColors,
  isPaint,
  productId,
  selectedArticleIdsObject,
  selectedColor,
  hasArticles,
  colorTypes,
}: Props) {
  const [searchValue, setSearchValue] = useState('');
  const [searchedValue, setSearchedValue] = useState('');
  const [calculated, setCalculated] = useState<ComponentProps<typeof SurfaceCalculatorModal>['calculated']>();
  const [savedStore, setSavedStore] = useState<IStore>();
  const { locale, query, isReady } = useRouter();

  const { showPrice, showPrivatePrices, handleTogglePrivatePrices } = usePrice();
  const { specifications } = useSpecification();
  const { addColor, viewedColors } = useRecentViewedColors();
  const [selectedColorPerGroup, setSelectedColorPerGroup] = useState<Record<string, SimpleColor | undefined>>({});

  const {
    colorBase,
    setSelectedColorGroup,
    showColors,
    productOptions,
    selectedColorGroup,
    handleProductOptionClick,
    activeProductOption,
    preSelectedColor,
    preSelectedColorIsLoading,
  } = useProductColors({
    showBaseColors,
    colorTypes,
    colorId: query?.colorId as string,
    colorGroupType: query?.colorGroupType as string,
    productId,
  });

  const { favorites } = useFavorites();

  const {
    data: articles,
    isLoading: articlesLoading,
    fetchStatus,
  } = useArticles({
    selectedColor,
    productId,
    activeProductOption,
    isPaint: isPaint && !!productOptions?.length,
    hasArticles,
    isReady,
  });

  const { data: favoriteColors } = useColorsByColorCodes(favorites.COLORS, productId);
  const { data: colorsByGroup } = useColorByColorTypeGroup(colorBase, productId, colorBase !== COLOR_TYPE.MYMX);
  const { data: accountStore } = useAccountStore(locale);

  const { data: searchColors, isInitialLoading: searchLoading } = useSearchColor({
    value: searchedValue,
    productId,
    colortypegroup: COLOR_TYPE.MYMX,
    enabled: !!searchedValue,
  });

  const { showStock, stock } = useStock({
    warehouseIds: [savedStore?.coloradepotid ?? ''],
    skus: Object.entries(selectedArticleIdsObject).map(([id, articleValues]) => ({
      id,
      skuId: articles?.find(article => article.id === id)?.stockproductnumber ?? '',
      ...articleValues,
    })),
  });

  useEffect(() => {
    if (accountStore) {
      setSavedStore(accountStore);
    }
  }, [accountStore]);

  const onColorClick = (simpleColor: SimpleColor) => {
    if (colorBase === COLOR_TYPE.MYMX) {
      addColor(simpleColor);
    }
  };

  useEffect(() => {
    setCalculated(undefined);
  }, [selectedColor]);

  return {
    favoriteColors,
    colorsByGroup: colorBase !== COLOR_TYPE.MYMX ? colorsByGroup : undefined,
    accountStore,
    searchColors,
    searchLoading,
    activeProductOption,
    setSelectedColorGroup,
    productOptions,
    selectedColorGroup,
    handleProductOptionClick,
    setSearchedValue,
    searchValue,
    addColor: onColorClick,
    calculated,
    searchedValue,
    setSavedStore,
    setCalculated,
    setSearchValue,
    specifications,
    viewedColors,
    showPrivatePrices,
    showStock,
    stock,
    showPrice,
    savedStore,
    showColors,
    handleTogglePrivatePrices,
    articles: articles?.sort((a, b) => (a?.displayorder ?? 0) - (b?.displayorder ?? 0)),
    articlesLoading: articlesLoading && fetchStatus !== 'idle',
    colorBase,
    preSelectedColor,
    preSelectedColorIsLoading: preSelectedColorIsLoading && !!query.colorId,
    setSelectedColorPerGroup,
    selectedColorPerGroup,
    noArticlesAvailable: hasArticles && !activeProductOption && isPaint && isReady,
  };
}
