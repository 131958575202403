import * as Sentry from '@sentry/nextjs';
import { useQuery } from '@tanstack/react-query';

import { fetchPaintSystems } from './connector';

import { paintSystemsKeys } from '.';

const usePaintSystems = (indexName: string, query: string) => {
  return useQuery({
    queryKey: paintSystemsKeys.paintSystems(indexName, query),
    queryFn: async () => await fetchPaintSystems(indexName, query),
    onError: e => {
      console.error(e);
      Sentry.captureException(e, {
        tags: {
          type: 'Fetch client side paintsystems',
        },
      });
    },
  });
};

export { usePaintSystems };
