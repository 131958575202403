import { IStore } from '@boss/services';
import { BasicVariant } from '@boss/types/b2b-b2c';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import ContactDetailLinks from '../ContactDetailLinks/contact-detail-links';
import OpeningHours, { type Translations as HoursTranslations } from '../OpeningHours';

interface TitleTranslations {
  contact: string;
  hours: string;
}

interface Props {
  title: React.ReactNode;
  hoursTranslations: HoursTranslations;
  titleTranslations: TitleTranslations;
  store: IStore;
  variant: BasicVariant;
  className?: string;
}

export const cardLayout = cva('p-5 md:p-6 w-full shadow-m w-full', {
  variants: {
    variant: {
      primary: 'p-5 md:p-6 w-full shadow-m rounded-5 md:rounded-10 md:rounded-tl-none rounded-tl-none',
      secondary: '',
    },
  },
});

const StoreContactCard = ({ store, variant, hoursTranslations, titleTranslations, title, className }: Props) => {
  const { address, email, mobilephone, phone } = store;

  return (
    <>
      <span className="mb-4 block md:hidden">{title}</span>

      <div className={twMerge(cardLayout({ variant }), className)}>
        <span className="mb-8 hidden md:block">{title}</span>

        <div className="my-3 flex flex-wrap md:my-0 md:ml-2">
          <div className="md:mr-35 mb-6 ml-2.5 mr-10 md:ml-0">
            <h5 className="text-generic-blue -ml-2.5 mb-3 font-medium md:mb-6 md:ml-0">{titleTranslations.contact}</h5>
            <ContactDetailLinks
              address={{
                street: address.street,
                streetnumber: Number(address.streetnumber),
                zipcode: address.zipcode,
                city: address.city,
                country: address.country,
              }}
              className="[&>*]:mb-2 md:[&>*]:mb-4"
              email={email}
              phone={mobilephone ?? phone}
            />
          </div>

          <div>
            <h5 className="text-generic-blue mb-3 font-medium md:mb-6">{titleTranslations.hours}</h5>
            <OpeningHours
              defaults={store.openinghours.defaults}
              exceptions={store.openinghours.exceptions}
              translations={hoursTranslations}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreContactCard;
