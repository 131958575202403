import { ParsedUrlQuery } from 'querystring';
import { UrlObject } from 'url';

import { Nullable } from '@boss/types/b2b-b2c';

const isValidHttpUrl = (string: string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

const isAnchorLink = (string: string) => {
  return string.startsWith('#') && string.length > 1;
};

const cleanPath = (path: string) => {
  let cleanedPath = path;

  // Remove the query parameters (if present)
  const queryIndex = cleanedPath.indexOf('?');

  if (queryIndex !== -1) {
    cleanedPath = cleanedPath.split('?')[0];
  }

  // Remove the anchor link (if present)
  const anchorIndex = cleanedPath.indexOf('#');

  if (anchorIndex !== -1) {
    cleanedPath = cleanedPath.substring(0, anchorIndex);
  }

  // Replace "-" characters with spaces
  cleanedPath = cleanedPath.replace(/-/g, ' ');

  // Capitalize the first letter
  cleanedPath = cleanedPath.charAt(0).toUpperCase() + cleanedPath.slice(1);

  return cleanedPath;
};

const isExternalUrl = (url: string | UrlObject) => {
  const uriSchemeArr = ['http', 'www', 'mailto:', 'tel:'];

  if (!url || typeof url === 'undefined' || typeof url === 'object') {
    return false;
  }

  return uriSchemeArr.some(scheme => url.startsWith(scheme));
};

const getHrefString = (href: Nullable<string | UrlObject>) => {
  if (!href) {
    return;
  }

  const hrefString = typeof href === 'object' ? href?.href : href;

  if (href === 'home') {
    return '/';
  }

  return hrefString;
};

const processQuery = (query: ParsedUrlQuery, fieldName: keyof ParsedUrlQuery): string | undefined => {
  const fieldValue = query[fieldName];

  if (!fieldValue) {
    return;
  }
  return Array.isArray(fieldValue) ? fieldValue.join(' ') : fieldValue;
};

export { cleanPath, isAnchorLink, isExternalUrl, isValidHttpUrl, getHrefString, processQuery };
