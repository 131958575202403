// eslint-disable-next-line
import { v4 as uuid } from 'uuid';

import { IBasketLine } from '@boss/services/client';
import { IArticle, ISearchProduct } from '@boss/types/b2b-b2c';

import { ExtentedArticle } from '../../components/PagedArticles';
import useProductMap from '../use-product-map/use-product-map';

/**
 * Custom hook for accessing the shopping basket state and functions.
 * @returns The basket state and functions.
 */
export const useArticleMap = () => {
  const { mapProductSlug } = useProductMap();

  const commonProductFields = ({
    product,
    colorId,
    colorTypeGroups,
  }: {
    product?: ISearchProduct;
    colorId?: string;
    colorTypeGroups?: string[];
  }) => ({
    slug: product
      ? mapProductSlug({
          productName: product.name,
          id: product.id,
          seoTitle: product.seoTitle,
          colorId: colorId,
          colorGroups: colorTypeGroups,
        })
      : undefined,
    imageSrc: product?.packshot?.src,
    isPaint: product?.type !== 'NON_PAINT',
  });

  const mapBasketArticle = (
    articles: IArticle[],
    lines: IBasketLine[],
    products: ISearchProduct[] = [],
  ): ExtentedArticle[] =>
    lines.reduce((prev: ExtentedArticle[], line) => {
      const article: IArticle | undefined = articles.find(article => line.item.skuid === article?.id);
      const product = products?.find(product => product?.id === article?.productId);

      if (!article) {
        return prev;
      }

      return [
        ...prev,
        {
          ...article,
          lineId: line.id ?? uuid(),
          price: {
            netInclVat: line.price?.netamountinclvat,
            netExclVat: line.price?.netamountexvat,
            salesPrice: line.price?.salesprice,
          },
          discount: line.discount?.linepercentage ?? 0,
          selectedPackaging: line.quantity.secondaryunit,
          selectedQuantity: line.quantity.secondaryquantity,
          salesQuantity: line.quantity.salesquantity,
          salesUnit: line.quantity.salesunit,
          selectedColor: {
            id: line.item.color?.colorcode,
            code: line.item.color?.colorcode,
            rgb: line.item.color?.rgb,
            name: line.item.color?.colorname,
          },
          ...commonProductFields({
            product,
            colorId: line.item.color?.colorid,
            colorTypeGroups: line.item?.colortypegroups,
          }),
        },
      ];
    }, []);

  const mapArticlesWithProductInfo = (articles: IArticle[], products: ISearchProduct[]) => {
    return articles.map(article => {
      const product = products?.find(product => product?.id === article.productId);

      return {
        ...article,
        ...commonProductFields({ product }),
      };
    });
  };

  return { mapBasketArticle, mapArticlesWithProductInfo };
};
