import { types } from '@boss/boss-client';
import { Nullable } from '@boss/types/b2b-b2c';
import { toHoursAndMinutes } from '@boss/utils';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import ContactDetailLinks from '../ContactDetailLinks/contact-detail-links';
import { StatusLabel } from '../StatusLabel';
import { StoreCardTranslations } from '../StoreCard';

type Props = {
  title: string;
  tel?: string;
  email?: string;
  isOpen: boolean;
  address?: addressObject;
  ShopDetailTranslations: shopDetailTranslations;
  currentOpeningHours: Nullable<types.TimeObject[]>;
  variant: 'primary' | 'secondary';
};

type addressObject = {
  street?: string;
  zipcode?: string;
  city?: string;
  country?: string;
  streetnumber?: string;
};
type shopDetailTranslations = {
  email?: string;
  tel?: string;
  open?: string;
  closed?: string;
  from?: string;
  and?: string;
};

const cardLayout = cva('shadow-m font-gotham w-full', {
  variants: {
    variant: {
      primary: 'rounded-lg',
      secondary: '',
    },
  },
});

const addressLayout = cva('', {
  variants: {
    variant: {
      primary: 'hover:text-brown-dark',
      secondary: 'text-generic-brown font-bold',
    },
  },
});

const DetailCard = ({
  title,
  address,
  tel,
  email,
  isOpen,
  ShopDetailTranslations,
  currentOpeningHours,
  variant,
}: Props) => {
  const { t } = useTranslation('stores');
  const translations: StoreCardTranslations = t('storeFinder.card', { returnObjects: true });

  return (
    <div className={twMerge(cardLayout({ variant }))}>
      <div className=" px-4 py-6 md:px-6 md:py-7">
        <div className="flex flex-col justify-between md:mb-9 md:flex-row">
          <h1 className="mb-5 md:mb-0 ">{title}</h1>
          <div className="inline-flex items-center">
            <span className={`inline-flex items-center ${isOpen ? 'text-green' : 'text-red'}`}>
              <StatusLabel isOpen={isOpen} translations={translations} />
            </span>
            {isOpen && (
              <>
                <span className=" text-brown-dark mx-1 inline-block text-xs md:hidden">
                  {' '}
                  {ShopDetailTranslations?.from}{' '}
                </span>
                <span>
                  {!!currentOpeningHours?.length &&
                    currentOpeningHours.map((time, index) => (
                      <span className="text-brown-dark text-xs md:hidden" key={time.from}>
                        {toHoursAndMinutes(time.from)} - {toHoursAndMinutes(time.till)}
                        <span className="mx-1 text-xs">
                          {index === 0 && currentOpeningHours.length > 1 ? ShopDetailTranslations?.and : ' '}
                        </span>
                      </span>
                    ))}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="text-gray-dark flex flex-col md:flex-row">
          {address && (
            <div className="my-3 mr-16 md:my-0">
              <a
                className={twMerge(addressLayout({ variant }))}
                href={`https://www.google.com/maps/search/?api=1&query=${address.street}+${address.streetnumber}+${address.zipcode}+${address.city}+${address.country}`}
                rel="noreferrer"
                target="_blank"
              >
                {address?.street} {address?.streetnumber}
                {','} <br />
                {address?.zipcode} {address?.city} <br />
              </a>
            </div>
          )}
          <ContactDetailLinks email={email} phone={tel} />
        </div>
      </div>
    </div>
  );
};

export default DetailCard;
