/* eslint-disable sonarjs/no-duplicate-string*/
export default {
  productid: '5',
  name: 'Bolatex',
  imageurl: 'https://resources-test.boss.be/dam?type=packshot&productgroepnr=000005&maxsize=low',
  info: 'Licht zijdeglanzende acrylaatverf voor binnen- en buitenmuren.',
  targetgroups: ['PAR', 'VAK'],
  relatedproduct: ['957', '460', '462', '1192', '1200', '1208', '1435', '1847', '2097', '8007', '6003', '9842'],
  crossSell: ['164306', '310215', '310216', '555345', '557115'],
  glosslevel: 'Licht zijdeglanzend',
  diluent: 'W',
  mxsetcode: 'STD',
  mymxcolors: 'N',
  colorset: '1',
  adrtransportunit: 'L',
  legalinfo: {
    legaltext:
      'Buiten het bereik van kinderen houden. Let op! Bij verneveling kunnen gevaarlijke inhaleerbare druppels worden gevormd. Spuitnevel niet inademen., Bevat 1,2-benzisothiazool-3(2H)-on, reactiemassa (3:1) van 5-chloor-2-methyl-2H-isothiazool-3-on en 2-methyl-2H-isothiazool-3-on. Kan een allergische reactie veroorzaken., Veiligheidsinformatieblad op verzoek verkrijgbaar. EU grenswaarde voor dit product (A/a): 30 g/l (2010). Dit product bevat maximaal 30 g/l VOS. Geen/zeer weinig oplosmiddel; voldoet voor professioneel gebruik binnen aan ARBO.',
  },
  attributes: [
    {
      attributename: 'Index korting',
      attributevalue: '20200301',
    },
    {
      attributename: 'Marge-cat',
      attributevalue: 'Highrunner',
    },
    {
      attributename: 'Ondergrond',
      attributevalue: 'Muren',
    },
    {
      attributename: 'Onderhoudbaarheid',
      attributevalue: 'Uitstekend',
    },
    {
      attributename: 'Onderhoudbaarheid',
      attributevalue: 'Zeer goed',
    },
    {
      attributename: 'Ruimte',
      attributevalue: 'Badkamer',
    },
    {
      attributename: 'Ruimte',
      attributevalue: 'Keuken',
    },
    {
      attributename: 'Spuitverf',
      attributevalue: 'Nee',
    },
    {
      attributename: 'BinnenBuiten',
      attributevalue: 'Intern',
    },
    {
      attributename: 'Kortingsklasse',
      attributevalue: 'Standaard verfvoorwaarden',
    },
    {
      attributename: 'Magento Family',
      attributevalue: 'Verf',
    },
    {
      attributename: 'Merkgroep',
      attributevalue: 'B-BOSS',
    },
    {
      attributename: 'Ruimte',
      attributevalue: 'Berging - garage',
    },
    {
      attributename: 'Ruimte',
      attributevalue: 'Living - slaapkamer',
    },
    {
      attributename: 'Transparant graad',
      attributevalue: 'Dekkend',
    },
    {
      attributename: 'Type laag',
      attributevalue: 'Afwerklaag',
    },
  ],
  processing: {
    brush: 1,
    roller: 1,
    consumptionmin: 7,
    consumptionmax: 11,
    consumptionavg: 9,
    airless: 1,
    airlessconsumptionmax: 6.0,
    airlessconsumptionmin: 4.0,
    airlessconsumptionavg: 5.0,
    consumptionstucmax: 0.0,
    consumptionstucmin: 0.0,
    consumptionstucavg: 0,
    paintabletimemin: 5.0,
    paintabletimemax: 8.0,
    hardenedtimemin: 0.0,
    hardenedtimemax: 0.0,
    dustdrytimemin: 2.0,
    dustdrytimemax: 3.0,
    dryingtimemin: 5.0,
    dryingtimemax: 8.0,
    layersmin: 0,
  },
  keywords: [
    {
      keyword: 'Bolatex',
      language: 'fr-BE',
    },
    {
      keyword: 'Bolatex',
      language: 'nl-BE',
    },
  ],
  textblocks: [
    {
      name: 'productgroep',
      textblocks: [
        {
          name: 'Toepassingen en eigenschappen',
          textblocks: [
            {
              name: 'infovinkjes',
              texttranslations: [
                {
                  language: 'nl',
                  texts: ['keukens en badkamers<br/>'],
                },
                {
                  language: 'fr',
                  texts: ['cuisines et salles de bain<br/>'],
                },
              ],
            },
            {
              name: 'eigenschappen',
              texttranslations: [
                {
                  language: 'de',
                  texts: ['Ausgezeichnete Reinigungsfähigkeit und Gute Verarbeitung.<br/>'],
                },
                {
                  language: 'nl',
                  texts: ['Zeer goed reinigbaar en goed verwerkbaar<br/>'],
                },
                {
                  language: 'fr',
                  texts: ['Excellente facilité de nettoyage et bonne mise en oeuvre.<br/>'],
                },
              ],
              textblocks: [
                {
                  name: 'verwerking',
                  texttranslations: [
                    {
                      language: 'nl',
                      texts: ['Gemakkelijk verwerkbaar.<br/>'],
                    },
                    {
                      language: 'fr',
                      texts: ['Mise en oeuvre facile.<br/>'],
                    },
                  ],
                },
              ],
            },
            {
              name: 'toepassingen',
              texttranslations: [
                {
                  language: 'nl',
                  texts: [
                    'Zijdeglanzende acrylaatverf voor binnenmuren (pleisterwerk, gipsplaten, baksteen, cementering,...) en droge buitenmuren.<br/>',
                    'Overschilderen van oude muurverflagen in goede staat.<br/>',
                    'Goed geschikt voor afwerking op muren in badkamer en keuken.<br/>',
                  ],
                },
                {
                  language: 'de',
                  texts: [
                    'Seidenglänzende Dispersionsfarbe für Innenwände (Putz, Gipsplatten, Ziegelstein, Zement, ...) und trockene Außenwände.<br/>',
                  ],
                },
                {
                  language: 'fr',
                  texts: [
                    'Peinture acrylique satinée pour murs intérieurs (en plâtre, plaque de plâtre, brique, cimentation,...) et murs extérieurs sec.<br/>',
                    'Pour rafraîchir des anciennes couches de peinture en bon état.<br/>',
                    'Convient parfaitement pour la finition des murs dans des salles de bain et cuisines.<br/>',
                  ],
                },
              ],
            },
          ],
        },
        {
          name: 'gebruik',
          textblocks: [
            {
              name: 'voorbereiding',
              texttranslations: [
                {
                  language: 'nl',
                  texts: [
                    'De ondergrond moet droog, proper en vast zijn, zonodig reinigen en/of herstellen.<br/>',
                    'BINNEN:<br/>',
                    'Onbehandelde ondergronden in goede staat (pleisterwerk, gipsplaten, beton)<br/>Een grondlaag geen waarde aanbrengen.<br/>',
                    'Licht verpoederde lagen, uitgedroogde vochtvlekken, nicotineaanslag, roetvlekken<br/>Vooraf een isolerende grondlaag Bisol of Epomur aanbrengen.<br/>',
                    'Geschilderde ondergronden met goede hechting<br/>Oude poreuze dispersieverven niet afwassen, niet poreuze (afwasbare) dispersieverven reinigen met water en Formule Mc Neutral Oude alkydverven afwassen met water en Formule Mc Neutral nadien mat schuren en grondlaag geen waarde aanbrengen.<br/>',
                    'Geschilderde ondergronden in slechte staat:<br/>Slecht hechtende verflagen verwijderen, de ondergrond waar nodig bijwerken met plamuur en/of vulmiddel en een gepaste grondlaag aanbrengen (geen waarde, Bisol, Epofix).<br/>',
                    'BUITEN:<br/>',
                    'Mos- of algengroei:<br/>Vuil en groenbegroeiing instrijken met een verdunde oplossing van bleekwater (bleekwater 38°: 1 deel bleekwater + 6 delen water; bleekwater van 10°: 1 deel bleekwater + 2 delen water) en minimum een 6-tal uren laten inwerken. Nadien grondig naspoelen met water of met hogedrukreiniger. De muren voldoende lang laten uitdrogen (tot < 30 %).<br/>',
                    'Verpoederde ondergronden en zoutuitbloedingen<br/>Zoveel mogelijk droog verwijderen en daarna fixeren met Primer P77 of  Epofix.<br/>',
                    'Poreuze ondergronden (bv. bakstenen)<br/>Een grondlaag Subliprim aanbrengen<br/>',
                    'Herstellingen <br/>Dienen te gebeuren met een rijnzand + cement of met andere cementgebonden vulmiddelen.<br/><p/>Openstaande voegen of barsten<br/>Afdichten met Renokit, na het aanbrengen van de grondlaag.<br/>',
                    "Raadpleeg eveneens de technische nota's van de beschreven voorbereidende producten.<br/>",
                  ],
                },
                {
                  language: 'fr',
                  texts: [
                    'Le support doit être sec, solide et propre, au besoin nettoyer et/ou réparer.<br/>',
                    "A L'INTERIEUR:<br/>",
                    "Supports non traités en bon état (plâtre, plaques de plâtre, béton)<br/>Appliquer une couche de fond de d'geen waarde.<br/>",
                    "Couches légèrement poudreuses ou support forts encrassés (nicotine, taches d'eau séchées,...):<br/>Appliquer une couche isolante Bisol ou Epomur.<br/>",
                    "Supports peints avec bonne adhérence<br/>Ne pas nettoyer les anciennes peintures poreuses à l'eau.  Anciennes peintures non-poreuses (lavables) : nettoyer avec de l'eau et  Formule Mc Neutral. Anciennes peintures alkydes : nettoyer avec de l'eau et Formule Mc Neutral, puis dépolir et  appliquer une couche de fond de geen waarde.<br/>",
                    'Supports peints en mauvais état:<br/>Eliminer les couches mal adhérentes, enduire le support au besoin, et appliquer une couche de fond adéquate (geen waarde, Bisol, Epofix).<br/>',
                    "A L'EXTERIEUR:<br/>",
                    "Croissance de mousses ou algues:<br/>Appliquer l'eau de javel dilué (eau de javel de 38°: 1 unité de javel sur 6 unités d'eau, eau de javel de 10°: 1 unité de javel sur 2 unités d'eau), laisser agir 6 heures au minimum puis rincer à l'eau ou avec un nettoyeur à haute pression. Laisser sécher les murs assez longtemps (jusque  < 30 %).<br/>",
                    'Support poudreux et/ou avec efflorescences<br/>Eliminer à sec puis fixer avec Primer P77 ou Epofix.<br/>',
                    'Support poudreux et/ou avec efflorescences<br/>Eliminer à sec puis fixer avec Subliprim.<br/>',
                    "Réparations <br/>Au sable de Rhin + ciment ou avec d'autres enduits à base de ciment.<br/><p/>Joints ou fissures ouvertes<br/>Boucher au  Renokit, après l'application d'une couche de fond.<br/>",
                    'Consultez aussi les fiches techniques des produits de préparation.<br/>',
                  ],
                },
              ],
            },
            {
              name: 'verbruik',
              texttranslations: [
                {
                  language: 'fr',
                  texts: ['Brosse - rouleau: 7 - 11 m²/l par couche.<br/>'],
                },
                {
                  language: 'nl',
                  texts: [
                    '<P>Rol: 7 - 11 m²/l per laag.<BR>Kwast: 7 - 11 m²/l per laag.<BR>Airless: 4 - 6 m²/l per laag.<BR>Stuc: 0 - 0 m²/l per laag.</P>\r\n<P><BR>&nbsp;</P>',
                  ],
                },
              ],
            },
            {
              name: 'reiniging',
              texttranslations: [
                {
                  language: 'nl',
                  texts: ['Onmiddellijk na gebruik met water.<br/>'],
                },
                {
                  language: 'fr',
                  texts: ["Immédiatement après l'usage à l'eau.<br/>"],
                },
              ],
            },
          ],
        },
        {
          name: 'algemeen',
          textblocks: [
            {
              name: 'omschrijving',
              texttranslations: [
                {
                  language: 'nl',
                  texts: [
                    'Licht zijdeglanzende en goed reinigbare acrylaatverf voor binnenmuren en droge buitenmuren.<br/>',
                    '<P>Dit is een test </P>\r\n<P>licht zijdeglanzende watergedragen muurverf op basis van acrylaatcopolymeer</P>',
                  ],
                },
                {
                  language: 'fr',
                  texts: [
                    'Peinture acrylique légèrement satinée, facilement nettoyable, pour les murs intérieurs et murs extérieurs secs<br/>',
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  documents: [
    {
      type: 'technische nota',
      language: 'N',
      productid: '5',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=j213dmlgvt26j7nas071u9i21i&scheme=document',
    },
    {
      type: 'technische nota',
      language: 'F',
      productid: '5',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=cb3j4thrt14av6chth9qv0u82l&scheme=document',
    },
    {
      type: 'veiligheidsinformatieblad',
      language: 'D',
      productid: '5',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=39pogmgdul57b33rau5t7jd56j&scheme=document',
    },
    {
      type: 'veiligheidsinformatieblad',
      language: 'E',
      productid: '5',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=n9rt7fkbq5373bcb7qescej40g&scheme=document',
    },
    {
      type: 'veiligheidsinformatieblad',
      language: 'F',
      productid: '5',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=suqvhtljs55qb7q23olpl1gp6s&scheme=document',
    },
    {
      type: 'veiligheidsinformatieblad',
      language: 'N',
      productid: '5',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=d4nln19dah46r0fav24aagch1s&scheme=document',
    },
    {
      type: 'veiligheidsinformatieblad',
      language: 'F',
      productid: '5',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      country: 'Boss paints',
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=dbrjp5f6a92kv60kn0k3nkae7k&scheme=document',
    },
    {
      type: 'veiligheidsinformatieblad',
      language: 'N',
      productid: '5',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      country: 'Boss paints',
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=ijas5p3i2h0h17ki971optlc10&scheme=document',
    },
    {
      type: 'veiligheidsinformatieblad',
      language: 'D',
      productid: '5',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      country: 'Boss paints',
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=ts1ejc31bh3f74dafsjqi1vs6j&scheme=document',
    },
    {
      type: 'veiligheidsinformatieblad',
      language: 'E',
      productid: '5',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=jgjq09lse92otdv1ekf69o562g&scheme=document',
    },
    {
      type: 'duurzaamheidsfiche',
      language: 'N',
      productid: '5',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=ltp7hctuj17a1d7e4hpp06uq57&scheme=document',
    },
    {
      type: 'duurzaamheidsfiche',
      language: 'F',
      productid: '5',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=pcb6msihg91i51lhjunhetuu42&scheme=document',
    },
    {
      type: 'technische informatie productgerelateerd',
      language: 'F',
      productid: '5',
      publicationtitlenl: 'Voedingsattest',
      publicationtitlefr: 'Certificat Alimentaire',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=bv0r2g6jkh4p3ad8mrf8hptf6e&scheme=document',
    },
    {
      type: 'technische informatie productgerelateerd',
      language: 'N',
      productid: '5',
      publicationtitlenl: 'Voedingsattest',
      publicationtitlefr: 'Certificat Alimentaire',
      fileformat: 'application/pdf',
      targetgrouplist: ['Vakman'],
      resourcelink:
        'https://app-bosspaints-api-resource-d.azurewebsites.net/resource/dam?recordid=2dpqa5r0516qvbljtasq2j4d4r&scheme=document',
    },
  ],
  pricefrom: {
    frompriceamountb2b: 999,
    frompricecurrencyb2b: 'EUR',
    frompriceunitb2b: 'stk',
    frompriceamountb2c: 999,
    frompricecurrencyb2c: 'EUR',
    frompriceunitb2c: 'stk',
  },
};
