import { BasicVariant, ImageProps, Nullable } from '@boss/types/b2b-b2c';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Image from '../Image';

type Props = {
  image: ImageProps;
  text: ReactNode;
  className?: Nullable<string>;
  imageFirst?: boolean;
  variant?: BasicVariant;
};

const GridStyle = cva('grid grid-cols-1 lg:grid-cols-2 content-center lg:my-11');

const ImageStyle = cva('object-cover', {
  variants: {
    variant: {
      primary: 'rounded-l-30 rounded-rl-30 lg:rounded-l-50 lg:rounded-tr-50',
      secondary: '',
    },
  },
});

const ImageTextSection = ({ image, text, className, imageFirst, variant = 'primary' }: Props) => {
  return (
    <div className={className ?? undefined}>
      <div className={twMerge(GridStyle())}>
        <div className="mt-6 lg:-my-10">
          <Image {...image} className={ImageStyle({ variant })} />
        </div>
        <div className={`grid content-center py-7 ${!imageFirst ? 'order-first lg:pr-10' : 'lg:pl-10'}`}>{text}</div>
      </div>
    </div>
  );
};

export default ImageTextSection;
