import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { CardProps } from '..';
import Counter from '../../Counter';
import Orb from '../../Orb';
import Select from '../../Select';

type Props = Pick<
  CardProps,
  | 'className'
  | 'translations'
  | 'quantity'
  | 'packaging'
  | 'packagingOptions'
  | 'onUpdatePackaging'
  | 'onUpdateQuantity'
  | 'onColorSelect'
  | 'color'
  | 'showColorPicker'
  | 'discountConditions'
>;

const Action = ({
  title,
  children,
  className,
  childClassName,
}: {
  title: string;
  children: ReactNode;
  className?: string;
  childClassName?: string;
}) => (
  <div className={twMerge('flex items-center gap-4 sm:flex-col sm:items-start sm:gap-1', className)}>
    <div className="h-fit">{title}</div>

    <div className={twMerge('flex items-center gap-3', childClassName)}>{children}</div>
  </div>
);

const ArticleCardActions = ({
  translations,
  quantity,
  packaging,
  packagingOptions,
  onUpdateQuantity: handleUpdateQuantity,
  onUpdatePackaging: handleUpdatePackaging,
  onColorSelect: handleSelectColor,
  color,
  className,
  showColorPicker,
  discountConditions,
}: Props) => {
  const colorEnabled = handleSelectColor && showColorPicker;
  const { t } = useTranslation('common');

  return (
    <div className={twMerge('w-full gap-4 md:gap-x-6 md:gap-y-2 lg:gap-x-10', className)}>
      <div className="md:h-15 mb-3 w-full md:mb-0">
        {colorEnabled && (
          <Action className="md:col-span-1" title={translations.color ?? ''}>
            <Orb className="shadow-m h-6 w-6 items-center justify-center" style={{ backgroundColor: color?.rgb }}>
              <FontAwesomeIcon icon={faCheck} size="xs" />
            </Orb>

            {handleSelectColor ? (
              <button className="flex text-left underline" onClick={handleSelectColor}>
                {color?.name ?? translations.selectColor}
              </button>
            ) : (
              <span>{color?.name}</span>
            )}
          </Action>
        )}
      </div>
      <div className="grid w-full gap-4 sm:grid-cols-2">
        {handleUpdateQuantity && (
          <Action childClassName="flex flex-col items-start" className="items-start" title={translations.amount}>
            <div>
              <Counter className="h-7.5" min={1} onChange={handleUpdateQuantity} value={quantity ?? 1} />
            </div>
            <div className="lg:hidden">
              {discountConditions?.map(discount => (
                <div className="text-sm text-yellow-500" key={discount}>
                  {discount}
                </div>
              ))}
            </div>
          </Action>
        )}
        {packagingOptions && packagingOptions?.length > 1 && handleUpdatePackaging && (
          <Action childClassName="w-full" title={translations.unit}>
            <Select
              className="w-full"
              name="packaging"
              onChange={e => handleUpdatePackaging(e.target.value)}
              options={packagingOptions.map(option => ({
                label: t('perValue', { value: t(`unit.${option.name}`) + ` (${option.quantity})` })?.toLowerCase(),
                value: option.name,
              }))}
              selectClassName="min-h-0 h-7.5 py-0 pl-3 w-full"
              value={packaging}
            />
          </Action>
        )}
      </div>
    </div>
  );
};

export default ArticleCardActions;
