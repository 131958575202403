import { useIsResponsive } from '@boss/hooks';
import { BasicVariant, Nullable, Image as ProductDetailImage } from '@boss/types/b2b-b2c';
import { faMemoCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { cva } from 'class-variance-authority';
import { ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { CardLabel, Carousel, Image, Price, Specifications } from '../../';
import Button from '../Button';
import ColoraButton from '../Button/ColoraButton';
import { CardLabelProps } from '../CardLabel';
import FavoriteButton from '../FavoriteButton';
import Skeleton from '../Skeleton';

const Asset = ({ image }: { image: ProductDetailImage }) => {
  if (image.image) {
    return <Image className="h-46 sm:h-50 md:h-57 object-contain" height={255} image={image.image} width={165} />;
  }

  return (
    <Image className="h-46 sm:h-50 md:h-57 object-contain" height={255} src={image.src} useNext={true} width={165} />
  );
};

const WrapperStyles = cva('relative p-6 md:pl-12 md:pr-8 md:pt-8 md:pb-6 bg-generic-blue-light', {
  variants: {
    variant: {
      primary: 'rounded-lg rounded-tr-none md:rounded-tr-lg',
      secondary: 'relative',
    },
  },
});

export type Props = {
  className?: string;
  complementaryImages?: Nullable<ProductDetailImage[]>;
  description?: Nullable<string>;
  discountPercentage?: number;
  handleAddToWishlist?: () => void;
  id?: string;
  inWishlist?: boolean;
  labels?: CardLabelProps[];
  price?: number;
  productImage: ProductDetailImage;
  renderRating?: () => ReactElement | null;
  showProductActions?: boolean;
  specifications?: ReactNode[];
  strikePrice?: number;
  subtitle: string;
  technicalLink: { href: string; label: string } | null;
  title: string;
  translations: Record<string, string>;
  variant: BasicVariant;
  priceIsLoading?: boolean;
};

const labelWrapperStyling = cva('flex flex-col items-end gap-2', {
  variants: {
    variant: {
      primary: '-mr-13 ml-auto',
      secondary: '-mr-8 ml-auto',
    },
  },
});

const titleStyling = cva('pr-10', {
  variants: {
    isPrimary: {
      false: 'md:text-30',
    },
  },
});

const PriceComponent = ({
  price,
  strikePrice,
  discountPercentage,
  translations,
  priceIsLoading,
}: {
  price?: number;
  strikePrice?: number;
  discountPercentage?: number;
  translations: Record<string, string>;
  priceIsLoading?: boolean;
}) => (
  <div>
    <div>{translations.fromPrice}</div>
    {(strikePrice || discountPercentage) && (
      <div className="mb-2 flex items-center">
        {strikePrice && (
          <Price className="[&>*]:body text-price-strike text-gray mr-5 line-through" strikePrice={strikePrice} />
        )}
        {discountPercentage && <span className="text-price-base">- {discountPercentage}%</span>}
      </div>
    )}
    <div className="flex flex-row items-center gap-2">
      {priceIsLoading ? (
        <Skeleton className="w-30 h-6" />
      ) : (
        <>
          <Price className="[&>*]:text-price-highlight [&>span]:font-brandon" size="small" value={price} />
          <span>{translations.unitPrice}</span>
        </>
      )}
    </div>
  </div>
);

/**
 * This component has been made in a very bad way and should be refactored in the future as this is not maintainable
 * TODO: Refactor this component
 **/
const ProductDetailCard = ({
  className,
  complementaryImages,
  description,
  discountPercentage,
  handleAddToWishlist,
  inWishlist,
  labels,
  price,
  productImage,
  renderRating,
  showProductActions,
  specifications,
  strikePrice,
  subtitle,
  technicalLink,
  title,
  translations,
  variant = 'primary',
  priceIsLoading,
}: Props) => {
  const isMobile = useIsResponsive();
  const isPrimary = variant === 'primary';

  return (
    <>
      <div className={twMerge(WrapperStyles({ variant }), className)}>
        {handleAddToWishlist && (
          <FavoriteButton className="absolute right-6 ml-auto" isFavorite={inWishlist} onClick={handleAddToWishlist} />
        )}
        <h1 className={titleStyling({ isPrimary })}>{title}</h1>
        <div className="flex justify-between">
          <div className="md:max-w-95 w-full md:mr-10">
            <div className="mb-5 flex flex-col flex-wrap items-baseline justify-between md:mb-4">
              <div className={!isPrimary ? 'font-brandon text-blue-dark' : ''}>
                <h2 className="text-xl font-light">{subtitle}</h2>
              </div>
              {isPrimary && renderRating ? <div className="min-h-[1rem]">{renderRating()}</div> : null}
            </div>

            {isMobile && (
              <div className="mb-12 pt-3 md:hidden">
                {complementaryImages?.length ? (
                  <Carousel options={{ navigation: true }}>
                    {[productImage, ...complementaryImages].map(image => (
                      <Asset image={image} key={image.image ? image.image.title : image.src} />
                    ))}
                  </Carousel>
                ) : (
                  <Asset image={productImage} />
                )}
              </div>
            )}

            <span className="mb-6 block font-bold">{description}</span>
            {specifications?.length && (
              <Specifications
                border={false}
                fontStyle="md:text-lg text-generic-brown"
                items={specifications}
                variant={isPrimary ? 'bullets' : 'checked'}
              />
            )}
            <div className="mt-3 hidden md:inline-block">
              <PriceComponent
                discountPercentage={discountPercentage}
                price={price}
                priceIsLoading={priceIsLoading}
                strikePrice={strikePrice}
                translations={translations}
              />
            </div>
            {!isPrimary && technicalLink && (
              <ColoraButton
                className="text-grey-dark min-h-10 shadow-m my-4 mt-10 bg-white px-4 text-sm font-bold underline md:text-lg [&>svg]:text-2xl"
                href={technicalLink.href}
                icon={faMemoCircleCheck}
                label={technicalLink.label}
                type="primary"
              />
            )}
          </div>

          {labels && (
            <div className="absolute bottom-8 right-0 z-20 hidden flex-col items-end gap-2.5 md:flex">
              {labels.map((label, index) => (
                <CardLabel className="w-fit" key={`${title}-label-${index}`} round="left" {...label} />
              ))}
            </div>
          )}

          {/* 
            Take notice that width: 25% , a necessary evil because safari needs a container width! Otherwise the image
            will be realy small. We can also use grid layout but then the image will not align at the end.
          */}
          <div className="relative hidden w-1/4 flex-col items-end py-4 md:flex">
            <Asset image={productImage} />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-8 bg-white px-8 md:hidden">
        <div className="flex justify-between">
          <PriceComponent
            discountPercentage={discountPercentage}
            price={price}
            priceIsLoading={priceIsLoading}
            strikePrice={strikePrice}
            translations={translations}
          />
          {!!labels?.length && (
            <div className={labelWrapperStyling({ variant })}>
              {labels.map((label, index) => (
                <CardLabel key={`${title}-label-${index}`} round="left" {...label} />
              ))}
            </div>
          )}
        </div>
        {showProductActions && (
          <Button
            className="min-h-12 w-full justify-center text-center font-bold [&>*]:w-fit"
            href="#product-panel-presence"
            label={translations.order}
            type="primary"
          />
        )}
      </div>
    </>
  );
};

export default ProductDetailCard;
