import { faEnvelope, faMessage, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';

import { IAccount } from '@boss/services/client';
import { ContactInformationFields, ContentPageFields, OverviewFields } from '@boss/types/b2b-b2c';
import { Alert, Button, FullCta, Link, Presence, SearchDropdown, StoreContactCard } from '@boss/ui';
import { getStorepageSlugByName, slugify } from '@boss/utils';

import { useUpdateAccount } from '../../../client-queries';
import { AccountSkeleton, mapFullCta } from '../../../components';
import { usePointOfSale } from '../../../hooks';
import { storeFinderVariant } from '../../../utils';

type Props = {
  account: IAccount;
  locale: string;
  contactInfo: ContactInformationFields | null;
  footerCards?: (ContentPageFields | OverviewFields)[] | null;
};

const Contact = ({ locale, account, contactInfo, footerCards }: Props) => {
  const { t } = useTranslation(['account', 'common', 'stores']);
  const searchOption = t('info.option');
  const searchLabel = t('info.searchLabel');
  const { stores, isLoading } = usePointOfSale();
  const { mutate: updateAccount, isError: updateAccountError, isLoading: updateAccountLoading } = useUpdateAccount();
  const store = stores?.find(store => slugify(store.name) === account.preference?.shop);

  const updateFavoriteStore = (id: string) => {
    updateAccount({
      ...account,
      preference: {
        ...account.preference,
        shop: id,
      },
    });
  };

  return (
    <Presence id="account-contact-page" isLoading={isLoading} loader={<AccountSkeleton />} visible>
      <h1 className="mb-5">{t('contact.title', { ns: 'account' })}</h1>
      {stores?.length && (
        <div>
          <h3 className="mb-8 md:mb-11">{t('contact.title', { ns: 'account' })}</h3>
          {store ? (
            <StoreContactCard
              className="mb-13.5"
              hoursTranslations={{ ...t('openingHours', { ns: 'stores', returnObjects: true }), title: '' }}
              store={store}
              title={
                <h4>
                  {`${t('contact.favorite', { ns: 'account' })} `}
                  <Link className="text-generic-blue h4 underline" href={getStorepageSlugByName(locale, store.name)}>
                    {store.name}
                  </Link>
                </h4>
              }
              titleTranslations={t('contact.hoursTitles', { ns: 'account', returnObjects: true })}
              variant={storeFinderVariant}
            />
          ) : (
            <div className="mb-13.5">
              <h4 className="mb-3">{t('info.selectYourFavorite')}</h4>
              <SearchDropdown
                onChange={updateFavoriteStore}
                options={stores.map(store => ({ value: store.id, label: store.name }))}
                searchLabel={searchLabel}
                selectLabel={searchOption}
                value={account.preference?.shop}
              />
              {!updateAccountLoading && updateAccountError && (
                <Alert className="mt-2" type="error">
                  {t('info.updateError')}
                </Alert>
              )}
            </div>
          )}

          {contactInfo && (
            <>
              <h4 className="mb-6">{t('contact.otherQuestion', { ns: 'account' })}</h4>
              <Button
                className="mb-8 py-3 md:py-6"
                href="/"
                icon={faMessage}
                iconPosition="right"
                label="Chat live met Ronny, onze Colora expert"
                type="primary"
              />

              <div className="flex flex-wrap items-center gap-5 [&>*]:text-gray-500">
                <Link href={`tel:${contactInfo.phoneNumber}`}>
                  <FontAwesomeIcon className="mr-2" icon={faPhone} />
                  <span>{t('info.call')}</span>
                  {contactInfo.phoneNumber}
                </Link>
                <Link href={`mailto:${contactInfo.email}`}>
                  <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
                  <span>{t('info.mail')}</span>
                  {contactInfo.email}
                </Link>
                {contactInfo.openingHoursText && (
                  <div className="text-xs md:ml-auto">{contactInfo.openingHoursText}</div>
                )}
              </div>
            </>
          )}

          {!!footerCards?.length && (
            <>
              <h4 className="mt-25 mb-7">{t('contact.themes', { ns: 'account' })}</h4>
              <div className="mb-30 grid gap-6 sm:grid-cols-2 md:grid-cols-3">
                {footerCards.map(card => {
                  const props = mapFullCta(card, locale);

                  return props ? <FullCta {...props} /> : null;
                })}
              </div>
            </>
          )}
        </div>
      )}
    </Presence>
  );
};

export default Contact;
