import { RefinementListConnectorParams } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';
import { useDynamicWidgets, useRefinementList } from 'react-instantsearch';

const Refinement = (props: RefinementListConnectorParams) => {
  useRefinementList(props);
  return null;
};

// Needed to percist refinements when using useDynamicWidgets
const VirtualRefinements = () => {
  const { attributesToRender } = useDynamicWidgets({
    facets: ['*'],
  });

  return (
    <>
      {attributesToRender.map(attr => (
        <Refinement attribute={attr} key={attr} />
      ))}
    </>
  );
};

export default VirtualRefinements;
