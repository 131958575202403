import { useRouter } from '@boss/hooks';
import { ColorsGrid } from '@boss/ui';

import { useFavorites } from '../../hooks';
import { mapColorsSlugField } from '../../utils';

const ColorsGridWrapper = (props: React.ComponentProps<typeof ColorsGrid>) => {
  const { favorites, toggleFavorite } = useFavorites();
  const { locale } = useRouter();

  return (
    <ColorsGrid
      {...props}
      colors={mapColorsSlugField(locale, props.colors)}
      favoriteColorIds={favorites.COLORS}
      onFavorite={color => toggleFavorite('COLORS', color.code.toString(), color)}
    />
  );
};

export default ColorsGridWrapper;
