import { useEffect, useState } from 'react';

import { session } from '../../utils';

/**
 * A hook that communicates the current status
 */
export const useProfile = () => {
  const [profileState, setProfileState] = useState(session.getData());
  const [statusState, setStatusState] = useState(session.getStatus());

  const updateProfile = (profile: session.Session) => setProfileState(profile);
  const updateStatus = (status: 'idle' | 'loading' | 'pristine') => setStatusState(status);

  useEffect(() => {
    session.on(session.EMITTER_CHANGE_EVENT, updateProfile);
    session.on(session.EMITTER_STATUS_CHANGE_EVENT, updateStatus);

    return () => {
      session.off(session.EMITTER_CHANGE_EVENT, updateProfile);
      session.off(session.EMITTER_STATUS_CHANGE_EVENT, updateStatus);
    };
  }, []);

  return {
    accountId: profileState?.extension_AccountId?.toString() ?? '',
    data: profileState,
    isLoggedIn: session.isActive(),
    status: statusState,
  };
};

export default useProfile;
