import { Repeat, Skeleton } from '@boss/ui';

const OrderOverviewSkeleton = ({ pageSize }: { pageSize: number }) => (
  <div className="my-10 flex flex-col justify-between gap-7">
    <Repeat amount={pageSize}>
      <div className="h-50 flex gap-2">
        <Skeleton className="w-full" />
      </div>
    </Repeat>
  </div>
);

export default OrderOverviewSkeleton;
