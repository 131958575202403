// orderStatus: enum for order status baed on boss API
export enum orderStatus {
  OPEN = 'Open Order',
  CANCELLED = 'Canceled',
  CLOSED = 'Invoiced',
  BACKORDER = 'Backorder',
}

export const getOrderTypeByStatus = (value: string): string | undefined => {
  return Object.keys(orderStatus).find(key => orderStatus[key as keyof typeof orderStatus] === value);
};
