import { faMagnifyingGlass, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import React, { useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useOnClickOutside } from 'usehooks-ts';

import SearchMenu, { SearchResults } from '../SearchMenu';
interface Props {
  searchLabel?: string;
  onSearch: () => void;
  onCloseSearchMenu?: () => void;
  type?: 'primary' | 'secondary';
  className?: string;
  value?: string;
  setSearchValue: (value: string) => void;
  searchResults?: SearchResults;
  wrapperClassname?: string;
  onClearSearch?: () => void;
  suggestions?: JSX.Element;
  showSearchSuggestions?: boolean;
  onShowSearchSuggestions?: (val: boolean) => void;
}

const Styles = cva('flex h-12 w-full items-center px-6', {
  variants: {
    type: {
      primary: 'bg-gray-soft text-gray-dark rounded-9.5',
      secondary: 'text-brown-dark bg-gray-lightest md:bg-white',
    },
  },
});

const IconStyle = cva(null, {
  variants: {
    type: {
      primary: 'text-brown-dark',
      secondary: 'text-gray-dark',
    },
  },
});

const SearchBar = ({
  searchLabel,
  onCloseSearchMenu: handleCloseSearchMenu = () => undefined,
  onSearch,
  setSearchValue,
  value = '',
  type,
  className,
  searchResults,
  wrapperClassname,
  onClearSearch: handleClearSearch,
  suggestions,
  showSearchSuggestions,
  onShowSearchSuggestions,
}: Props) => {
  const inputRef = useRef<null | HTMLInputElement>(null);
  const searchMenuRef = useRef<HTMLSpanElement | null>(null);
  const searchSuggestionsRef = useRef<HTMLSpanElement | null>(null);

  useOnClickOutside(searchMenuRef, handleCloseSearchMenu);
  useOnClickOutside(searchSuggestionsRef, () => (onShowSearchSuggestions ? onShowSearchSuggestions(false) : undefined));

  const handleSearch = (key: string) => {
    if (key === 'Enter') {
      onSearch();
    }
  };

  const handleClick = () => onSearch();

  const clearInput = () => {
    setSearchValue('');
    if (handleClearSearch) {
      handleClearSearch();
    }
  };

  return (
    <div className={twMerge('relative w-full', wrapperClassname)}>
      <div className={twMerge(Styles({ type }), className)}>
        <input
          aria-label="search-bar"
          autoComplete="off"
          className="mr-2.5 w-full border-transparent bg-transparent p-0 outline-none focus:border-transparent focus:ring-0"
          name="search"
          onChange={e => setSearchValue(e.target.value)}
          onFocus={onShowSearchSuggestions ? () => onShowSearchSuggestions(true) : undefined}
          onKeyDown={e => handleSearch(e.key)}
          placeholder={searchLabel}
          ref={inputRef}
          type="text"
          value={value}
        />
        {!!value && handleClearSearch && (
          <span className="mr-2 cursor-pointer" onClick={clearInput}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
        )}
        <span className="cursor-pointer" onClick={handleClick}>
          <FontAwesomeIcon className={IconStyle({ type })} icon={faMagnifyingGlass} />
        </span>
      </div>
      {!!searchResults?.length && value && (
        <span
          className={twMerge('z-100 absolute top-12 w-full bg-white px-5 shadow-lg', className)}
          ref={searchMenuRef}
        >
          <SearchMenu query={value} results={searchResults} />
        </span>
      )}
      {showSearchSuggestions && !!suggestions && value?.length < 3 && (
        <span
          className={twMerge('z-100 absolute top-12 w-full bg-white px-5 shadow-lg', className)}
          ref={searchSuggestionsRef}
        >
          {suggestions}
        </span>
      )}
    </div>
  );
};

export default SearchBar;
