import { SEARCH_ATTRIBUTES_OBJECT } from '../../constants';

export const mapFacetValue = (facetValue: string) => facetValue.replaceAll(' > ', ' ');

export const getCurrentCategoryFromFacetValue = (facetValue: string) => {
  const categories = facetValue.split(' > ');

  return categories[categories.length - 1];
};

/* 
  returns the following search attribute based on the current attribute given.
  e.g: if current attribute is l2, it will return l3.
*/
export const getNextSearchAttribute = (currentAttribute: string) => {
  const categories = Object.values(SEARCH_ATTRIBUTES_OBJECT);

  if (categories.includes(currentAttribute)) {
    const categoryIndex = categories.indexOf(currentAttribute);

    return categories[categoryIndex + 1];
  }
};
