import { ChangeEvent } from 'react';

import FormFieldWrapper, { BaseFieldProps } from '../FormFieldWrapper';
import InputAction from '../InputAction';

type Option = {
  value: string;
  label: string;
};

type Props = BaseFieldProps & {
  onChange: (values: string[]) => void;
  options: Option[];
  values: string[];
  ariaLabel?: string;
};

export const CheckboxGroup = ({
  label,
  values = [],
  className,
  options,
  name,
  ariaLabel = 'checkboxGroup',
  onChange,
  touched,
  error,
  disclaimer,
  required,
  tooltip,
  id,
}: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (values.includes(value)) {
      return onChange(values.filter(v => v !== value));
    }

    return onChange([...values, value]);
  };

  return (
    <FormFieldWrapper
      className={className}
      disclaimer={disclaimer}
      error={error}
      id={id ?? name}
      label={label}
      name={name}
      required={required}
      tooltip={tooltip}
      touched={touched}
    >
      <div aria-label={ariaLabel} className={className} role="group">
        {options.map(option => (
          <InputAction
            checked={values.includes(option.value)}
            id={`${id ?? name}-${option.value}`}
            key={`${name}-${option.value}`}
            name={name}
            onChange={handleChange}
            value={option.value}
          >
            {option.label}
          </InputAction>
        ))}
      </div>
    </FormFieldWrapper>
  );
};

export default CheckboxGroup;
