import { env as codeEnv } from '../code/env';
import { env as ropcEnv } from '../ropc/env';
import { env as ce } from '../utils';
/**
 * Hook to use to get the correct handles for logging in.
 */
export const env = (() => {
  if (ce.FLOW === 'code') {
    return codeEnv;
  }

  if (ce.FLOW === 'ropc') {
    return ropcEnv;
  }

  throw new Error('@boss/auth is incorrectly configured.');
})();

export default env;
