import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';

import { paintguidePageSlugs } from '@boss/constants/b2b-b2c';
import { CtaBanner, Link, Presence } from '@boss/ui';

import { usePaintguideResults } from '../../../client-queries';
import { accountPageConfig } from '../../../utils';

type Props = {
  locale: string;
};

const ListCardStyle = cva('shadow-m relative', {
  variants: {
    variant: {
      primary: 'rounded-tl-none rounded-5 p-8',
      secondary: 'p-5',
    },
  },
});

const AccountPaintguideResults = ({ locale }: Props) => {
  const { data: paintguideResults, isLoading } = usePaintguideResults(locale);
  const { t } = useTranslation(['account', 'common']);

  return (
    <Presence className="mb-10" id="account-paintguide-results-page" isLoading={isLoading} visible>
      <h1 className="mb-8">{t('paintguides.title', { ns: 'account' })}</h1>
      <div className="mb-10 grid flex-col gap-6 md:grid-cols-2">
        {paintguideResults?.map(({ url, creationdatetime, description }) => (
          <Link href={url} key={creationdatetime} testId="list-card">
            <div className={ListCardStyle({ variant: accountPageConfig.variant })}>
              <div className="mb-2 overflow-x-clip text-xl">{description}</div>
              <button className="text-blue w-full overflow-hidden text-ellipsis whitespace-nowrap underline">
                {url}
              </button>
            </div>
          </Link>
        ))}
      </div>
      <CtaBanner
        cta={{
          label: t('product.ctaBanner.ctaLabel', { ns: 'common' }),
          icon: faArrowRight,
          href: paintguidePageSlugs[locale],
        }}
        description={t('product.ctaBanner.description', { ns: 'common' })}
        prefix="😳"
        title={t('product.ctaBanner.title', { ns: 'common' })}
      />
    </Presence>
  );
};

export default AccountPaintguideResults;
