import env from '../env';

const MARKER_KEY = 'fromlogin';
let fromlogin = false;

/**
 * Test if the current request comes from a log in attempt
 */
export const fromLoginAttempt = () => fromlogin;

/**
 * Load the login attempt marker
 */
const loadMarker = () => {
  const uri = new URL(window.location.href);

  fromlogin = uri.searchParams.has(MARKER_KEY);
};

// Load on the client
if (typeof window === 'object') {
  loadMarker();
}

type Props = { redirect?: string };

/**
 * Format and go to login url with correct parameters
 */
export const login = ({ redirect }: Props) => {
  const current = new URL(redirect ?? window.location.href);

  current.searchParams.append(MARKER_KEY, 'yes');

  window.location.href = `${env.PATH_PREFIX}/login?source=${current.toString()}`;
};

export default login;
