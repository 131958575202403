import { types } from '@boss/boss-client';
import {
  CHANNEL,
  accountAdviceSlugs,
  accountAppointmentsSLugs,
  accountColorsSlugs,
  accountContactSlugs,
  accountDashboardSlugs,
  accountDiscountSlugs,
  accountInfoSlugs,
  accountInvoicesSlugs,
  accountListSlugs,
  accountOrderSlugs,
  accountPageSlugs,
  accountPaintguideSlugs,
  accountPlansSlugs,
  accountPriceListPagesSlugs,
  accountProjectsSlugs,
  accountQuotationSlugs,
  categoryPageSlugs,
  colorPageSlugs,
  colorToolSlugs,
  contenfulOverviewTypes,
  eventPageSlugsB2B,
  eventPageSlugsB2C,
  inspirationPageSlugs,
  overviewPageSlugs,
  overviewTypes,
  paintguidePageSlugs,
  productDetailPageBase,
  stepByStepPageSlugs,
  storePageSlugs,
} from '@boss/constants/b2b-b2c';
import { newsSlugs, vacanciesSlugs } from '@boss/constants/corporate-jobs';
import {
  ContentPageFields,
  InspirationImageFields,
  LocalizedSlugs,
  Nullable,
  OverviewFields,
  PageLink,
  StepByStepPlanFields,
} from '@boss/types/b2b-b2c';

import { getEntries } from './array';
import slugify from './slugify';

export type TAccountPageType =
  | 'ADVICE'
  | 'APPOINTMENTS'
  | 'COLORS'
  | 'CONTACT'
  | 'DISCOUNT'
  | 'DASHBOARD'
  | 'INFO'
  | 'INVOICES'
  | 'LIST'
  | 'ORDERS'
  | 'PLANS'
  | 'PROJECTS'
  | 'QUOTATION'
  | 'LOGOUT'
  | 'PAINTGUIDES'
  | 'PRICE_LIST';

export type TCheckoutPageType = 'CART' | 'DELIVERY' | 'DELIVERY_DATE' | 'INFORMATION' | 'FINISH' | 'OVERVIEW';

export const overviewPageMap: {
  [key in string]: {
    [key in string]: string;
  };
} = {
  'Inspiration overview': inspirationPageSlugs,
  'Step by step plans': stepByStepPageSlugs,
  'Category overview': categoryPageSlugs,
  'Store overview': storePageSlugs,
  'Color overview': colorPageSlugs,
  'Paint guide': paintguidePageSlugs,
  'Event overview': CHANNEL !== 'colora' ? eventPageSlugsB2B : eventPageSlugsB2C,
  'Color tool': colorToolSlugs,
  vacancies: vacanciesSlugs,
  'latest-news': newsSlugs,
  [contenfulOverviewTypes[overviewTypes.INSIDE_VIEWER]]: overviewPageSlugs[overviewTypes.INSIDE_VIEWER],
  [contenfulOverviewTypes[overviewTypes.TESTIMONIAL]]: overviewPageSlugs[overviewTypes.TESTIMONIAL],
  [contenfulOverviewTypes[overviewTypes.PROFESSIONAL_TIPS]]: overviewPageSlugs[overviewTypes.PROFESSIONAL_TIPS],
  [contenfulOverviewTypes[overviewTypes.PAINTING_TIPS]]: overviewPageSlugs[overviewTypes.PAINTING_TIPS],
  [contenfulOverviewTypes[overviewTypes.COLOR_TIPS]]: overviewPageSlugs[overviewTypes.COLOR_TIPS],
};

/**
 * When extending this, make sure to also extend the necessary rewrites
 * -> config/rewrites.js
 * */
const accountPagesSlugs: Record<TAccountPageType, LocalizedSlugs | undefined> = {
  ADVICE: accountAdviceSlugs,
  APPOINTMENTS: accountAppointmentsSLugs,
  COLORS: accountColorsSlugs,
  CONTACT: accountContactSlugs,
  DISCOUNT: accountDiscountSlugs,
  DASHBOARD: accountDashboardSlugs,
  INFO: accountInfoSlugs,
  INVOICES: accountInvoicesSlugs,
  LIST: accountListSlugs,
  ORDERS: accountOrderSlugs,
  PLANS: accountPlansSlugs,
  PROJECTS: accountProjectsSlugs,
  QUOTATION: accountQuotationSlugs,
  PAINTGUIDES: accountPaintguideSlugs,
  PRICE_LIST: accountPriceListPagesSlugs,
  LOGOUT: undefined,
};

const isKeyOfLocalizedSlugs = (key: string, collection: LocalizedSlugs): key is keyof LocalizedSlugs =>
  key in collection;

export const appendValueToSlugsObject = (value: string, slugs: LocalizedSlugs) =>
  Object.entries(slugs).reduce((acc: Record<string, string>, [locale, slug]) => {
    acc[locale] = `${slug}${value}`;
    return acc;
  }, {});

export const getTranslatedSlugsByType = (slug: string, type: string): LocalizedSlugs => {
  const slugObj = { ...(overviewPageMap[type] as LocalizedSlugs) };

  for (const [key, prefix] of getEntries(slugObj)) {
    slugObj[key] = `${prefix}/${slug}`;
  }

  return slugObj;
};

export const getStorepageSlugByName = (locale: string, storeName?: Nullable<string>) => {
  if (!isKeyOfLocalizedSlugs(locale, storePageSlugs)) {
    return '';
  }
  const prefix = storePageSlugs[locale];

  return storeName ? `${prefix}/${slugify(storeName)}` : `${prefix}`;
};

export const getInspirationTranslatedSlug = (locale: string, slug?: Nullable<string>) => {
  if (!isKeyOfLocalizedSlugs(locale, inspirationPageSlugs)) {
    return '';
  }
  const prefix = inspirationPageSlugs[locale];

  return slug ? `${prefix}/${slug}` : `${prefix}`;
};

export const getAccountPageSlugs = (type: TAccountPageType) => {
  return accountPagesSlugs[type] || accountPageSlugs;
};

export const getAccountPageSlugByType = (type: TAccountPageType, locale: string) => {
  const slugs = accountPagesSlugs[type];

  return slugs ? slugs[locale] : accountPageSlugs[locale];
};

export const getAccountPageTypeBySlug = (targetValue: string): TAccountPageType => {
  for (const [key, value] of getEntries(accountPagesSlugs)) {
    const slugs = (value && Object.values(value)) ?? [];

    if (slugs.some(slug => targetValue.startsWith(slug))) {
      return key;
    }
  }
  return 'DASHBOARD';
};

export const getPagePathByPageType = (slug: string, locale: string, pageType: string, tags?: string[]) => {
  if (pageType === 'contentPage') {
    const overviewType = tags?.find(tag => Object.keys(overviewPageSlugs).includes(tag));

    if (overviewType) {
      return `/${overviewPageSlugs[overviewType as keyof typeof overviewPageSlugs][locale]}/${slug}`;
    }

    return `/${slug}`;
  }

  if (pageType === 'stepByStepPlan' && isKeyOfLocalizedSlugs(locale, stepByStepPageSlugs)) {
    const prefix = stepByStepPageSlugs[locale];

    return `/${prefix}/${slug}`;
  }

  if (pageType === 'inspirationImage' && isKeyOfLocalizedSlugs(locale, inspirationPageSlugs)) {
    const prefix = inspirationPageSlugs[locale];

    return `/${prefix}/${slug}`;
  }

  if (pageType === 'product') {
    return `/${productDetailPageBase}/${slug}`;
  }

  if (pageType === 'overviewPage') {
    return `/${overviewPageMap[slug]?.[locale]}`;
  }

  if (pageType === 'servicePoint') {
    return `/${getStorepageSlugByName(locale, slug)}`;
  }

  return '/';
};

export const getLink = (link: PageLink, locale: string): string => {
  if (!link || link.slug === 'home') {
    return '/';
  }

  if (link.type) {
    const overviewPagePath = overviewPageMap[link.type]?.[locale];

    if (overviewPagePath) {
      return overviewPagePath;
    }
  }

  return link?.slug ?? '/';
};

export const getPagePathByEntry = (
  page: ContentPageFields | OverviewFields | StepByStepPlanFields | InspirationImageFields | undefined,
  locale: string,
): string => {
  if (!page) {
    return '/';
  }
  const pageType = page?.__typename;

  // TODO - For some reason this throws an error in the build process. Don't know why.
  // https://www.typescriptlang.org/tsconfig#noPropertyAccessFromIndexSignature

  if (pageType === 'overviewPage') {
    return overviewPageMap[page.type]?.[locale] || '/';
  }
  const slug = page['slug'] === 'home' ? '' : page['slug'];
  const tags = page['tags'];

  return getPagePathByPageType(slug || '', locale, pageType, tags);
};

/**
 * Because every category page is based upon an array of slugs: /cat1/cat2/cat3
 * Start from the last entry of the slugs array and find the next parent category slugs from the allCategories array
 * */
export const getLocalizedSlugsForCategories = (slugs: string[], allCategories: types.product.IAssortment[]) => {
  const slugMap = { ...categoryPageSlugs };
  let slugIndex = slugs.length - 1;
  const parent = allCategories?.find(cat => slugify(cat.description) === slugs[slugs.length - 1]);

  if (!parent) {
    return slugMap;
  }

  let parentId = parent.parentcategoryid;
  const allSlugs: Record<string, string>[] = [parent.slug];

  while (slugIndex > 0) {
    slugIndex--;

    const nextCat = allCategories.find(cat => cat.categoryid === parentId);

    if (!nextCat) {
      continue;
    }

    parentId = nextCat.parentcategoryid;
    allSlugs.unshift(nextCat.slug);
  }

  for (const localSlugs of allSlugs) {
    for (const [locale, slug] of Object.entries(localSlugs)) {
      slugMap[locale] += `/${slug}`;
    }
  }

  return slugMap;
};

/**
 * Create translations for a product page based on the localized slugs and productId
 * */
export const getTranslatedslugsForProducts = (
  slugs: Record<string, string>,
  productId: string,
  localeMapper?: (arg0: string) => string,
): LocalizedSlugs => {
  return Object.entries(slugs).reduce((acc: Record<string, string>, [locale, slug]) => {
    const _locale = localeMapper ? localeMapper(locale) : locale;

    acc[_locale] = `/${productDetailPageBase}/${slug}/${productId}`;
    return acc;
  }, {});
};

export const getLocalizedSlugsForEventPage = () => {
  return CHANNEL === 'colora' ? eventPageSlugsB2C : eventPageSlugsB2B;
};

export const getLocalizedSlugForEventPage = (locale: string, slug?: Nullable<string>) => {
  const eventPageSlugs = getLocalizedSlugsForEventPage();

  if (!isKeyOfLocalizedSlugs(locale, eventPageSlugs)) {
    return '';
  }
  const prefix = eventPageSlugs[locale];

  return slug ? `${prefix}/${slug}` : `${prefix}`;
};

export const getLastPartOfPath = (url: string): string => {
  const parts = url.split('/');

  return parts.pop() || '';
};
