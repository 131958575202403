import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { IAccountOrder } from '@boss/services/client';
import { Accordion, Alert, Presence, SearchBar } from '@boss/ui';

import { DateFilters, OrderOverviewSkeleton, PagedOrders } from '../../../components';
import { useOrders } from '../../../hooks';
import { isB2b } from '../../../utils';

interface OrderProps {
  title: string;
  isOpen: boolean;
  orders: IAccountOrder[];
  totalItems: number;
  activePage: number;
  onPaginate: (pageNumber: number) => void;
  pageSize: number;
  isLoading?: boolean;
  children?: React.ReactNode;
}

const Orders: React.FC<OrderProps> = ({
  title,
  orders,
  isOpen,
  totalItems,
  activePage,
  onPaginate,
  pageSize,
  isLoading,
  children,
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  return isB2b ? (
    <Accordion isOpen={isOpen} title={title} variant="secondary">
      {isLoading ? (
        <OrderOverviewSkeleton pageSize={pageSize} />
      ) : (
        <>
          {children}
          <PagedOrders currentPage={activePage} onPaginate={onPaginate} orders={orders} totalPages={totalPages} />
        </>
      )}
    </Accordion>
  ) : (
    <>
      <h2 className="mb-4 text-lg font-bold">{title}</h2>
      {children}
      <PagedOrders currentPage={activePage} onPaginate={onPaginate} orders={orders} totalPages={totalPages} />
    </>
  );
};

const OrderOverview = () => {
  const { t } = useTranslation('account');
  const { locale } = useRouter();
  // The pageSize is set to 1000 for B2C users since pagination is not needed
  const pageSize = isB2b ? 5 : 1000;

  const { closedOrders, inProcessOrders, openOrders, filters, setFilters, isInitialLoading } = useOrders({
    locale: locale as string,
    pageSize,
  });

  const [searchValue, setSearchValue] = useState('');

  return (
    <>
      <h1 className="mb-3">{t('orders.myOrders')}</h1>

      <Presence
        id="orderOverview"
        isLoading={isInitialLoading}
        loader={<OrderOverviewSkeleton pageSize={pageSize} />}
        visible
      >
        <div className="flex flex-col py-5">
          {[inProcessOrders, openOrders, closedOrders].map(orderData => {
            const title = t(`orders.${orderData.type}Orders`);

            if (!orderData?.data?.count && !orderData.isLoading && orderData.type !== 'closed') {
              return null;
            }

            return (
              <Orders
                activePage={orderData.currentPage}
                isLoading={orderData.isLoading}
                isOpen={false}
                key={orderData.type}
                onPaginate={orderData.paginate}
                orders={orderData?.data?.orders ?? []}
                pageSize={pageSize}
                title={`${title} (${orderData?.data?.count ?? 0})`}
                totalItems={orderData?.data?.count ?? 0}
              >
                {orderData.type === 'closed' && (
                  <div className="my-5" data-testid="order-search">
                    <SearchBar
                      onClearSearch={() => setFilters({ ...filters, orderId: undefined })}
                      onSearch={() => setFilters({ ...filters, orderId: searchValue })}
                      searchLabel={t('orders.search') ?? ''}
                      setSearchValue={setSearchValue}
                      type="primary"
                      value={searchValue}
                    />
                    <DateFilters fromDate={filters.fromDate} onSetFilters={setFilters} toDate={filters.toDate} />
                  </div>
                )}
              </Orders>
            );
          })}
          {closedOrders.data?.orders?.length === 0 && (
            <Alert className="mt-2" type="info">
              {t('orders.noResults')}
            </Alert>
          )}
        </div>
      </Presence>
    </>
  );
};

export default OrderOverview;
