import { useLocalStorage } from 'usehooks-ts';

import { useRouter } from '@boss/hooks';
import { SimpleColor } from '@boss/types/b2b-b2c';

const MAX_AMOUNT_OF_ITEMS = 8;

export const useRecentViewedColors = () => {
  const { isReady } = useRouter();
  const [viewedColors, setViewedColors] = useLocalStorage<SimpleColor[]>('RECENT_VIEWED_COLORS', []);

  const addColor = (color: SimpleColor) => {
    const oldColors = viewedColors.filter(c => c.code !== color.code);
    const newRecentColors = [...new Set([color, ...oldColors].slice(0, MAX_AMOUNT_OF_ITEMS))];

    setViewedColors(newRecentColors);
  };

  return { addColor, viewedColors: isReady ? viewedColors : [] };
};

export default useRecentViewedColors;
