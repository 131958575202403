import { basket } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: true, authScope: process.env.BOSS_API_AUTH_SCOPE_BASKET };

/**
 * Fetch basket by basket id
 */
export const getBasketById = (basketId: string) => {
  return bossApi<basket.Basket>(`/basket/v2/baskets/${basketId}`, DEFAULT_OPTIONS);
};
