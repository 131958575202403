import { useRouter } from 'next/router';

import OrderDetailB2b from './detail/b2b';
import OrderDatailB2c from './detail/b2c';
import OrderOverview from './overview';
import { isB2b } from '../../../utils';

type Props = {
  className?: string;
};

const Orders = ({ className }: Props) => {
  const { asPath } = useRouter();

  const pagePathParts = asPath.split('/');
  const orderId = pagePathParts.length >= 4 ? pagePathParts[3]?.split('?')[0] : undefined;

  const orderDetailComponent = orderId ? (
    isB2b ? (
      <OrderDetailB2b orderId={orderId} />
    ) : (
      <OrderDatailB2c orderId={orderId} />
    )
  ) : null;

  return <div className={className}>{orderId ? orderDetailComponent : <OrderOverview />}</div>;
};

export default Orders;
