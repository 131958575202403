import { faCheck, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { CardProps } from '..';
import { Counter, Image, Link, Orb, Price, Skeleton } from '../..';

export interface Props extends CardProps {
  onSelect?: () => void;
  selected?: boolean;
}

const PricingBlock = ({
  loading = false,
  className,
  salesPrice,
  netPrice,
}: {
  loading?: boolean;
  className?: string;
  salesPrice: number;
  netPrice: number;
}) =>
  loading ? (
    <Skeleton className={twMerge('rounded-brand min-w-20 h-5', className)} skeletonClassName="bg-gray-200" />
  ) : (
    <Price className={className} strikePrice={netPrice !== salesPrice ? salesPrice : undefined} value={netPrice} />
  );

const CardWrapperStyling = cva('flex w-full flex-col p-5', {
  variants: {
    type: {
      default: 'flex-row justify-between',
      extended: 'gap-1',
    },
  },
});

const PrimaryArticleCard = ({
  article,
  onRemove: handleRemove,
  translations,
  quantity,
  onUpdateQuantity: handleUpdateQuantity,
  onColorSelect: handleSelectColor,
  color,
  slug,
  imageSrc,
  price,
  title,
  type,
  className,
  isUpdating,
}: CardProps) => (
  <div className={twMerge(CardWrapperStyling({ type }), className)}>
    <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
      <div className="flex w-full items-center gap-4 md:gap-8">
        {imageSrc && (
          <Link anchorClassName="w-15 md:h-25 h-20 md:w-20" href={slug}>
            <Image alt={article.name} className="object-contain" height={100} src={imageSrc} width={80} />
          </Link>
        )}

        <div className="flex flex-col gap-1">
          <Link className="flex flex-col" href={slug}>
            <strong>{title ?? article.product?.name ?? article.name}</strong>
          </Link>
          {color?.name && (
            <span className="flex flex-wrap items-center gap-1 md:flex-col md:items-start">
              <div className="h-fit">{translations.color}:</div>
              <div className="flex items-center gap-3">
                <Orb className="shadow-m h-6 w-6 items-center justify-center" style={{ backgroundColor: color.rgb }}>
                  <FontAwesomeIcon icon={faCheck} size="xs" />
                </Orb>
                {handleSelectColor ? (
                  <button className="flex underline" onClick={handleSelectColor}>
                    {color.name}
                  </button>
                ) : (
                  <span>{color.name}</span>
                )}
              </div>
            </span>
          )}

          {!!price?.salesPrice && (
            <PricingBlock
              className="md:hidden"
              loading={isUpdating}
              netPrice={price.netInclVat}
              salesPrice={price.salesPrice}
            />
          )}
        </div>
      </div>

      {(handleUpdateQuantity || handleRemove) && (
        <div className="ml-auto flex items-center gap-9 md:ml-0 md:gap-4">
          {handleUpdateQuantity && <Counter min={1} onChange={handleUpdateQuantity} value={quantity ?? 1} />}
          {handleRemove && (
            <button
              onClick={e => {
                e.stopPropagation();
                handleRemove();
              }}
            >
              <FontAwesomeIcon className="text-red-light" icon={faTrashCan} size="lg" />
            </button>
          )}
        </div>
      )}
    </div>

    {price && (
      <PricingBlock
        className="hidden self-end md:block"
        loading={isUpdating}
        netPrice={price.netInclVat}
        salesPrice={price.salesPrice}
      />
    )}
  </div>
);

export default PrimaryArticleCard;
