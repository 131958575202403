import { twMerge } from 'tailwind-merge';

import { Image } from '..';

type Props = {
  className?: string;
  height?: string;
  colorCode?: string;
  colorType?: 'default' | 'transparant';
};

const PaintDrop = ({ className, colorCode, height = '72', colorType = 'default' }: Props) => {
  if (colorType === 'transparant') {
    return (
      <Image
        className={twMerge('min-h-20 min-w-20 rounded-full', className)}
        height={80}
        src="/wood-overlay.png"
        style={{ backgroundColor: colorCode }}
        useNext
        width={80}
      />
    );
  }

  return (
    <svg
      className={className}
      fill="none"
      height={height}
      style={{ fill: colorCode }}
      viewBox="0 0 78 72"
      width="78"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2982_806687)" id="Drop">
        <path
          d="M37.7419 0.5C47.1592 0.5 57.1119 4.84514 64.7157 11.3929C72.3243 17.9448 77.5 26.6293 77.5 35.2448C77.5 43.8998 73.2934 52.9669 66.3867 59.8773C59.4845 66.7832 49.9201 71.5 39.2516 71.5C17.7992 71.5 0.5 57.544 0.5 38.7692C0.5 19.8642 15.4189 0.5 37.7419 0.5Z"
          id="Color"
          stroke="#F2F2F2"
        />
        <mask height="72" id="mask0_2982_806687" maskUnits="userSpaceOnUse" width="78" x="0" y="0">
          <path
            d="M37.7419 0.5C47.1592 0.5 57.1119 4.84514 64.7157 11.3929C72.3243 17.9448 77.5 26.6293 77.5 35.2448C77.5 43.8998 73.2934 52.9669 66.3867 59.8773C59.4845 66.7832 49.9201 71.5 39.2516 71.5C17.7992 71.5 0.5 57.544 0.5 38.7692C0.5 19.8642 15.4189 0.5 37.7419 0.5Z"
            fill="#91A18F"
            id="Vector 27"
            stroke="#F2F2F2"
          />
        </mask>
        <g mask="url(#mask0_2982_806687)">
          <g filter="url(#filter0_f_2982_806687)" id="Vector 24" opacity="0.2">
            <path
              d="M74.5044 39.4976C72.5044 38.9976 73.0043 47.4964 62.5044 56.4976C52.0046 65.4988 44.6645 67 45.5003 69.5C46.0013 71 62.0001 63.5 69.5 54C74.5435 47.6115 76.5044 39.9976 74.5044 39.4976Z"
              fill="#757575"
            />
          </g>
          <g filter="url(#filter1_f_2982_806687)" id="Vector 26" opacity="0.7">
            <path
              d="M11.7219 21.9902C14.9637 17.1459 17.3783 15.8822 16.4287 14.8343C14.7002 12.927 10.7763 18.3143 9.27406 20.8686C7.77183 23.4229 4.72975 28.781 6.703 29.3743C8.67625 29.9676 8.48018 26.8344 11.7219 21.9902Z"
              fill="white"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="44.2148"
          id="filter0_f_2982_806687"
          width="43.8408"
          x="38.4355"
          y="32.4766"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_2982_806687" stdDeviation="3.5" />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="17.0117"
          id="filter1_f_2982_806687"
          width="12.5801"
          x="5.05762"
          y="13.4336"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_2982_806687" stdDeviation="0.5" />
        </filter>
        <clipPath id="clip0_2982_806687">
          <rect fill="white" height="72" width="78" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PaintDrop;
