import { ImageTextSection } from '@boss/ui';
import { isInstanceOfImage } from '@boss/utils';

import { SectionMapperProps } from '..';
import ComponentMapper from '../../ComponentMapper';

const TwoColumnsWithImageOutOfBoundsMapper = ({ entry, theme, locale, className }: SectionMapperProps) => {
  const textContentIndex = entry.content.findIndex(item => item?.__typename === 'richText');
  const imageContentIndex = entry.content.findIndex(item => item?.__typename === 'image');

  const textContent = entry.content[textContentIndex];
  const imageContent = entry.content[imageContentIndex];

  if (textContent && imageContent && isInstanceOfImage(imageContent)) {
    return (
      <ImageTextSection
        className={className}
        image={imageContent}
        imageFirst={imageContentIndex < textContentIndex}
        text={<ComponentMapper entry={textContent} locale={locale} theme={theme} />}
      />
    );
  }

  return <>wrong section content</>;
};

export default TwoColumnsWithImageOutOfBoundsMapper;
