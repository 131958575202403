import * as code from '../code';
import * as ropc from '../ropc';
import { env } from '../utils';

/**
 * Hook to use to get the correct handles for register in.
 */
export const register = (() => {
  if (env.FLOW === 'code') {
    return code.register;
  }

  if (env.FLOW === 'ropc') {
    return ropc.login; //@todo the register flow for ropc is yet to be determined
  }

  throw new Error('@boss/auth is incorrectly configured.');
})();

export default register;
