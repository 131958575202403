import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import { ColorButtonProps } from '..';
import { Orb, PaintDrop, Skeleton } from '../..';
import FavoriteButton from '../../FavoriteButton';
import Link from '../../Link';

const PrimaryColorButton = ({
  isFavorite,
  onFavorite,
  className,
  backgroundColor,
  id,
  name,
  onClick,
  translations,
  trending,
  loading,
  colorType,
  trackInfo,
}: ColorButtonProps) => {
  if (loading) {
    return (
      <div className={twMerge('flex flex-col items-center gap-3', className)}>
        <Orb className="shadow-lg" loading size="lg" />
        <div className="flex flex-col items-center gap-1">
          <Skeleton className="w-15 h-3" />
          <Skeleton className="h-2 w-10" />
        </div>
      </div>
    );
  }

  return (
    <div className={twMerge('relative w-full', className)}>
      <Link className={'flex flex-col items-center gap-1'} key={id} onClick={onClick} trackInfo={trackInfo}>
        <PaintDrop className="w-15" colorCode={backgroundColor} colorType={colorType} />
        <div className="flex flex-col items-center">
          {id && <span className="caption text-center">{id}</span>}
          {name && <span className="caption text-center">{name}</span>}
          {trending && translations && (
            <span className="bg-red-light flex flex-nowrap items-center rounded-lg px-3 text-[10px] leading-[16px] text-white">
              <FontAwesomeIcon icon={faStar} /> {translations.trending}
            </span>
          )}
        </div>
      </Link>
      {onFavorite && (
        <FavoriteButton
          className="-right-15 absolute -top-2 left-0 ml-auto mr-auto"
          isFavorite={isFavorite}
          onClick={onFavorite}
        />
      )}
    </div>
  );
};

export default PrimaryColorButton;
