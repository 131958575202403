import { NavigationItem } from '@boss/types/b2b-b2c';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import FooterNavItem from './FooterNavItem';

type DesktopNavigationItemProps = {
  navItem: NavigationItem;
  mobileText?: boolean;
  labelClassName?: string;
  itemClassName?: string;
};

const LinkStyles = cva('flex items-center gap-1.5 text-footer-text pb-2.5 cursor-pointer', {
  variants: {
    mobileText: {
      true: 'md:text-body text-body-mobile',
      false: 'text-body',
    },
    hasUrl: {
      true: 'hover:underline hover:text-white',
      false: '',
    },
  },
  defaultVariants: {
    mobileText: false,
  },
});

const DesktopNavigationItem = ({
  navItem,
  mobileText = false,
  labelClassName,
  itemClassName,
}: DesktopNavigationItemProps) => {
  return (
    <li className={twMerge('hidden pb-5 lg:block', itemClassName)} key={navItem.label}>
      {navItem.label && (
        <div
          className={twMerge(
            'min-h-20 flex w-full justify-between break-words pb-5 text-xl text-white',
            labelClassName,
          )}
        >
          {navItem.label}
        </div>
      )}
      <ul className="w-full">
        {navItem.childNavigationItems?.map((childNavItem, i) => {
          return (
            <li
              className={LinkStyles({
                mobileText,
                hasUrl: !!childNavItem.href,
              })}
              key={`${childNavItem.label}-${i}`}
            >
              {childNavItem?.icon && <FontAwesomeIcon icon={childNavItem.icon} />}
              <FooterNavItem link={childNavItem.href}>{childNavItem.label}</FooterNavItem>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default DesktopNavigationItem;
