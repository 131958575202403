import { Select } from '@boss/ui';

import { useSort } from '../../../hooks';

export type SortOption = {
  labelKey: string;
  indexSuffix?: string;
  condition?: {
    isLoggedIn?: boolean;
  };
};

type Props = {
  className?: string;
  indexName?: string;
  options: SortOption[];
};

const Sort = ({ className, indexName, options: optionsOverwrite }: Props) => {
  const { options, refine, currentRefinement } = useSort(optionsOverwrite, indexName);

  if (!options.length) {
    return null;
  }

  return (
    <Select
      className={className}
      name="sort-component-select"
      onChange={e => refine(e.currentTarget.value)}
      options={options}
      value={currentRefinement}
    />
  );
};

export default Sort;
