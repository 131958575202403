import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'next-i18next';
import { ComponentProps } from 'react';
import { useHits } from 'react-instantsearch';

import { paintguidePageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { ISearchProduct, SectionFields } from '@boss/types/b2b-b2c';
import { CtaBanner, PromotionCard, Section } from '@boss/ui';

import { ComponentMapper, ProductCardWrapper, RecentlyViewedProducts, SearchGrid } from '../../components';
import { CATEGORY_SEARCH_CONFIG } from '../../constants';
import { useProductMap, useSearchAttributes } from '../../hooks';
import { categoryPageConfig, expandableFacets, productSortOptions } from '../../utils';

type Props = {
  title: string;
  promotion?: ComponentProps<typeof PromotionCard>;
  attributes: string[];
  categoryType: string;
  indexName: string;
  suffixSections?: SectionFields[] | null;
};

const CategoryPage = ({
  title,
  promotion,
  attributes: initialAttributes,
  categoryType,
  indexName,
  suffixSections,
}: Props) => {
  const { mapProductComponent } = useProductMap();
  const { showPaintGuideLink } = categoryPageConfig;
  const { locale } = useRouter();
  const { t } = useTranslation();

  const { attributes } = useSearchAttributes(CATEGORY_SEARCH_CONFIG.attributesToHide);
  const { results } = useHits<ISearchProduct>();
  const hits = results?.hits;

  const getActionType = () => {
    if (expandableFacets) {
      return 'expand';
    }

    if (categoryType === 'l1') {
      return 'navigate';
    }

    return 'refine';
  };

  const getAttributes = () => {
    if (categoryType === 'l1' && !expandableFacets) {
      return initialAttributes;
    }

    return initialAttributes?.concat(attributes);
  };

  const ctaBanner = showPaintGuideLink && (
    <CtaBanner
      className="col-span-full"
      cta={{
        label: t('product.ctaBanner.ctaLabel'),
        icon: faArrowRight,
        href: paintguidePageSlugs[locale],
      }}
      description={t('product.ctaBanner.description')}
      prefix="😳"
      title={t('product.ctaBanner.title')}
    />
  );

  return (
    <div>
      <Section
        className="pt-0"
        content={
          <SearchGrid
            actionType={getActionType()}
            attributes={getAttributes()}
            ctaBanner={ctaBanner}
            indexName={indexName}
            promotion={promotion}
            sortOptions={productSortOptions}
            title={title}
          >
            {hits?.map(product => (
              <ProductCardWrapper key={`category-product-card-${product.id}`} {...mapProductComponent(product)} />
            ))}
          </SearchGrid>
        }
      />

      <RecentlyViewedProducts />

      {suffixSections?.map(entry => (
        <ComponentMapper entry={entry} key={entry.id} locale={locale} />
      ))}
    </div>
  );
};

export default CategoryPage;
