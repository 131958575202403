import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { useIsResponsive } from '@boss/hooks';
import { OrderCard } from '@boss/ui';
import { getAccountPageSlugByType } from '@boss/utils';

import { isB2b, orderCardVariant } from '../../../utils';
import OrderMocks from '../__mocks__/orders.mock';
type Props = {
  locale: string;
  className?: string;
};

const OrderOverviewStyle = cva('flex flex-col gap-10 pb-6', {
  variants: {
    b2b: {
      true: 'bg-brown text-white px-6 py-6',
    },
  },
});

const Orders = ({ locale, className }: Props) => {
  const { t } = useTranslation('account');
  const isMobile = useIsResponsive('xl');
  const Orders = OrderMocks;

  // TODO: this is a temporary solution, since it's still based on the mock
  const getTitle = (id: string): string => {
    const titles: { [key: string]: string } = {
      '1': t('general.alreadyPickedup'),
      '2': t('general.deliveryToday'),
      '3': t('general.readyForPickup'),
    };

    return titles[id];
  };

  return (
    <div
      className={twMerge(
        OrderOverviewStyle({
          b2b: isB2b,
        }),
        className,
      )}
    >
      <h1>{t('dashboard.myLastOrders')}</h1>
      <div className="grid grid-cols-2 gap-2 md:gap-7 xl:grid-cols-3">
        {Orders.slice(0, isMobile ? 2 : 3).map(order => (
          <OrderCard
            key={order.id}
            variant={orderCardVariant}
            {...order}
            title={getTitle(order.id)}
            translations={{
              products: t('dashboard.product', { count: order.products.length }),
            }}
          />
        ))}
      </div>
      <Link className="underline" href={getAccountPageSlugByType('ORDERS', locale as string)}>
        {t('dashboard.viewAllOrders')} <FontAwesomeIcon icon={faChevronRight} size="xs" />
      </Link>
    </div>
  );
};

export default Orders;
