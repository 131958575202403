import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import ArticleCardActions from './ArticleCardActions';
import ArticleCardPricing from './ArticleCardPricing';
import { CardProps } from '..';
import CardLabel from '../../CardLabel';
import Link from '../../Link';

export interface Props extends CardProps {
  onSelect?: () => void;
  selected?: boolean;
}

const SecondaryArticleCard = ({
  article,
  onRemove: handleRemove,
  translations,
  deleted,
  type = 'default',
  quantity,
  packaging,
  packagingOptions,
  onUpdateQuantity,
  onUpdatePackaging,
  onColorSelect,
  color,
  slug,
  price,
  discount,
  className,
  isUpdating,
  showColorPicker,
  discountConditions,
  labels,
}: CardProps) => (
  <div className={twMerge('border-1 border-divider w-full p-5', className)}>
    <div className="relative grid gap-3 md:grid-cols-3 md:gap-x-10">
      <Link className="flex w-fit flex-col" href={slug}>
        <strong className="h3 mb-2 pr-6 md:pr-0">{article.product?.name ?? article.name}</strong>
        {article.product?.name && article.name && <div>{article.name}</div>}
        <div>
          {translations.articleRef}: <span className="text-gray">{article.id}</span>
        </div>
      </Link>

      <div className="col-span-3 flex flex-col gap-4 md:flex-grow">
        {!!labels?.length && (
          <div className="-mr-5 flex flex-col items-end gap-1 md:hidden">
            {labels.map((label, index) => (
              <CardLabel key={`${article.name}-label-${index}`} round="left" {...label} />
            ))}
          </div>
        )}
        <div className="grid gap-4 md:flex-row md:gap-6 lg:grid-cols-2">
          {type === 'extended' && (onUpdateQuantity ?? (onColorSelect && showColorPicker)) && (
            <ArticleCardActions
              color={color}
              discountConditions={discountConditions}
              onColorSelect={onColorSelect}
              onUpdatePackaging={onUpdatePackaging}
              onUpdateQuantity={onUpdateQuantity}
              packaging={packaging}
              packagingOptions={packagingOptions}
              quantity={quantity}
              showColorPicker={showColorPicker}
              translations={translations}
            />
          )}

          {!!price && (
            <div>
              {!!labels?.length && (
                <div className="-mr-5 mb-5 hidden flex-col items-end gap-1 md:flex">
                  {labels.map((label, index) => (
                    <CardLabel key={`${article.name}-label-${index}`} round="left" {...label} />
                  ))}
                </div>
              )}
              <ArticleCardPricing
                discount={discount}
                isLoading={isUpdating}
                netPrice={price.netExclVat}
                salesPrice={price.salesPrice}
                translations={translations}
              />
            </div>
          )}
          {discountConditions && (
            <div className="hidden lg:inline">
              {discountConditions.map(discount => (
                <div className="text-yellow-500" key={discount}>
                  {discount}
                </div>
              ))}
            </div>
          )}

          {handleRemove && (
            <button
              className="absolute right-0 top-0 h-fit md:ml-auto md:self-end"
              onClick={e => {
                e.stopPropagation();
                handleRemove();
              }}
            >
              <FontAwesomeIcon className="text-red-light" icon={faTrashCan} size="lg" />
            </button>
          )}
        </div>
      </div>
    </div>

    {deleted && (
      <div className="mb-5">
        <div className="absolute right-0 mt-3 w-full bg-[#FFB802] px-12 py-1 text-center">
          {translations.itemdeleted}
        </div>
      </div>
    )}
  </div>
);

export default SecondaryArticleCard;
