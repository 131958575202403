import { faBars, faClock, faEuroSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';

import { Paragraph } from '@boss/ui';

import RoundedItem from './RoundedItem';

type Props =
  | {
      difficulty: 'Easy' | 'Medium' | 'Hard';
      duration?: never;
      budget?: never;
    }
  | {
      difficulty?: never;
      duration: string;
      budget?: never;
    }
  | {
      difficulty?: never;
      duration?: never;
      budget: '€' | '€€' | '€€€' | '€€€€';
    };

const DEFAULT_STYLE = 'w-12 h-12 bg-white relative';

const DifficultyStyle = cva(DEFAULT_STYLE, {
  variants: {
    difficulty: {
      true: 'bg-steps-characteristics-difficulty',
      false: '',
    },
  },
});

const DurationStyle = cva(DEFAULT_STYLE, {
  variants: {
    duration: {
      true: 'bg-steps-characteristics-duration',
      false: '',
    },
  },
});

const BudgetStyle = cva(DEFAULT_STYLE, {
  variants: {
    budget: {
      true: 'bg-steps-characteristics-budget',
      false: '',
    },
  },
});

const Characteristics = ({ difficulty, duration, budget }: Props) => {
  const { t } = useTranslation();

  if (difficulty) {
    return (
      <div className="flex flex-row items-center md:flex-col">
        <RoundedItem className={DifficultyStyle({ difficulty: difficulty ? true : false })}>
          <FontAwesomeIcon className="text-white" icon={faBars} />
        </RoundedItem>
        <div className="ml-4 flex flex-col md:ml-0 md:mt-2 md:items-center">
          <Paragraph className="my-0 mb-1 font-bold">{t('roadmap.difficulty')}</Paragraph>
          <Paragraph className="my-0 md:text-center">{difficulty}</Paragraph>
        </div>
      </div>
    );
  }

  if (duration) {
    return (
      <div className="flex flex-row items-center md:flex-col">
        <RoundedItem className={DurationStyle({ duration: duration ? true : false })}>
          <FontAwesomeIcon className="text-white" icon={faClock} />
        </RoundedItem>
        <div className="ml-4 flex flex-col md:ml-0 md:mt-2 md:items-center">
          <Paragraph className="my-0 mb-1 font-bold">{t('roadmap.duration')}</Paragraph>
          <Paragraph className="my-0 whitespace-pre-line md:text-center">{duration}</Paragraph>
        </div>
      </div>
    );
  }

  if (budget) {
    return (
      <div className="flex flex-row items-center md:flex-col">
        <RoundedItem className={BudgetStyle({ budget: budget ? true : false })}>
          <FontAwesomeIcon className="text-white" icon={faEuroSign} size="lg" />
        </RoundedItem>
        <div className="ml-4 flex flex-col md:ml-0 md:mt-2 md:items-center">
          <Paragraph className="my-0 mb-1 font-bold">{t('roadmap.budget')}</Paragraph>
          <Paragraph className="my-0 md:text-center">{budget}</Paragraph>
        </div>
      </div>
    );
  }

  return null;
};

export default Characteristics;
