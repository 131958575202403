import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  children: ReactNode;
  className?: string;
};

const Container = ({ className, children, ...props }: Props) => (
  <div className={twMerge('max-w-315 mx-auto my-0 w-full px-5 md:px-8 xl:px-0', className)} {...props}>
    {children}
  </div>
);

export default Container;
