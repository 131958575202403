import { PHASE_DEVELOPMENT_SERVER, PHASE_PRODUCTION_BUILD } from 'next/constants';

export const environments = ['develop', 'test', 'staging', 'master'] as const;

// TODO: Develop more. It seems the PHASE_PRODUCTION_BUILD gives a false positive
// due to environment embedding
export const IS_BUILD =
  process.env.NEXT_PHASE === PHASE_PRODUCTION_BUILD ||
  (process.env.NEXT_PHASE === PHASE_DEVELOPMENT_SERVER && process.env.DEBUG_BUILD === 'true');

export const IS_DEV = process.env.NEXT_PHASE === PHASE_DEVELOPMENT_SERVER;
