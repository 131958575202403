import { type VariantProps, cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import style from './style.module.scss';
import type { CommonProps } from '../../types';

export interface ListProps extends CommonProps, VariantProps<typeof ListStyles> {
  as?: 'ordered' | 'unordered';
  children?: ReactNode;
}

export const ListStyles = cva('', {
  variants: {
    as: {
      ordered: `${style.list} list-decimal`,
      unordered: `${style.list} list-disc`,
    },
  },
  defaultVariants: {
    as: 'unordered',
  },
});

const List = ({ as = 'unordered', className, children, ...props }: ListProps) => {
  const ListTag = as === 'ordered' ? 'ol' : 'ul';

  return (
    <ListTag className={twMerge(ListStyles({ as, className }))} {...props}>
      {children}
    </ListTag>
  );
};

export default List;
