import { IOrder } from '@boss/types/b2b-b2c';
import { Faker, en } from '@faker-js/faker';

export const faker = new Faker({
  locale: [en],
});

const generateOrders = (count: number, when: 'PAST' | 'CURRENT') => {
  const statusValues = ['Active', 'Pending', 'Inactive'];
  const orders: IOrder[] = [];

  for (let i = 0; i < count; i++) {
    orders.push({
      orderDate:
        when === 'PAST'
          ? faker.date.past().toISOString().split('T')[0]
          : faker.date.future().toISOString().split('T')[0],
      orderId: faker.number.int({ min: 100000, max: 9000000 }) + '',
      modeOfDelivery: 'home',
      status: faker.helpers.arrayElement(statusValues),
      customerReference: faker.number.int({ min: 100000, max: 9000000 }) + '',
      addresses: [],
      lines: [],
      invoice: {},
      delivery: {},
    });
  }
  return orders;
};

const generateFutureOrders = (count: number) => generateOrders(count, 'CURRENT');

const generateHistoricOrders = (count: number) => generateOrders(count, 'PAST');

export { generateFutureOrders, generateHistoricOrders };
