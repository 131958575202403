import { Nullable, SimpleImage } from '@boss/types/corporate-jobs';
import { faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import Image from '../../../Image';
import Link from '../../../Link';

const ContentStyles = cva('', {
  variants: {
    isOpen: {
      true: 'text-white',
      false: 'text-black',
    },
  },
});

const BlockStyles = cva('', {
  variants: {
    isOpen: {
      true: 'bg-black',
      false: 'bg-white',
    },
  },
});

type MobileMainBarProps = {
  className?: string;
  logo: Nullable<SimpleImage>;
  translations: {
    menuLabelOpen: string;
    menuLabelClosed: string;
  };
  isOpen?: boolean;
  onClick?: () => void;
  invertedLogo?: Nullable<SimpleImage>;
};

const MobileMainBar = ({ translations, logo, isOpen, onClick, className, invertedLogo }: MobileMainBarProps) => (
  <div className={twMerge('flex items-center justify-between gap-2 p-4', BlockStyles({ isOpen }), className)}>
    {logo && !isOpen && (
      <Link className={`relative block h-9 w-fit`} href="/">
        <Image alt={logo.imageAlt} resizingBehavior="fill" src={logo.imageUrl} />
      </Link>
    )}
    {invertedLogo && isOpen && (
      <Link className={`relative block h-9 w-fit`} href="/">
        <Image alt={invertedLogo.imageAlt} resizingBehavior="fill" src={invertedLogo.imageUrl} />
      </Link>
    )}

    <button className="flex items-center gap-4" onClick={onClick}>
      <span className={ContentStyles({ isOpen })}>
        {isOpen ? translations.menuLabelOpen : translations.menuLabelClosed}
      </span>
      <FontAwesomeIcon className={twMerge('text-2xl', ContentStyles({ isOpen }))} icon={isOpen ? faTimes : faBars} />
    </button>
  </div>
);

export default MobileMainBar;
