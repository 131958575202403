import { IStore } from '@boss/services';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import Button from '../../Button';
import Skeleton from '../../Skeleton';

export type StockShopCardTranslations = {
  actionButtonLabel: string;
  available: string;
  limited: string;
  outOfStock: string;
};

interface Props {
  store: IStore;
  translations: StockShopCardTranslations;
  className?: string;
  stockRemarks?: string[];
  stockStatus: 'available' | 'limited' | 'outOfStock';
  stockLoading: boolean;
  selectStore: (store: IStore) => void;
}

const AvailabilityStyle = cva('font-raleway md:ml-8 py-2 block small md:body', {
  variants: {
    stockStatus: {
      available: 'text-green',
      limited: 'text-red-light',
      outOfStock: 'text-red',
    },
  },
});

const ShopCard = ({ store, translations, stockStatus, className, stockRemarks, stockLoading, selectStore }: Props) => {
  if (!store) {
    return null;
  }

  return (
    <div
      className={twMerge('border-gray border-b-1 w-full pb-4 pl-8 pt-4 last-of-type:border-b-0', className)}
      data-testid="shop-card"
    >
      <h3>{store.name}</h3>
      {stockLoading ? (
        <Skeleton className="border-gray mb-4 mt-2 h-6 w-full pl-8 last-of-type:border-b-0" />
      ) : (
        <>
          {stockStatus && (
            <span className={twMerge(AvailabilityStyle({ stockStatus }))}>{translations[stockStatus]}</span>
          )}
        </>
      )}
      {stockRemarks && (
        <div className={`w-full sm:flex sm:justify-between`}>
          <ul className="mb-4 list-disc pl-8 pr-8 md:w-full md:pl-20 md:pr-0">
            {stockRemarks.map(remark => (
              <li key={`remark-${remark}`}>{remark}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="flex sm:justify-end">
        <Button
          icon={faArrowRight}
          iconPosition="right"
          label={translations?.actionButtonLabel}
          onClick={() => selectStore(store)}
          type="secondary"
        />
      </div>
    </div>
  );
};

export default ShopCard;
