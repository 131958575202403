import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { ChangeEvent, ChangeEventHandler, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useOnClickOutside } from 'usehooks-ts';

import FormFieldWrapper from '../FormFieldWrapper';
import { InputFieldProps } from '../InputField';

type Option = {
  value: string;
  label: string;
};

type Props = Omit<InputFieldProps, 'onChange' | 'onBlur' | 'type'> & {
  onChange: (val: Option) => void;
  onQueryChange: ChangeEventHandler<HTMLInputElement>;
  options: Option[];
  ariaLabel?: string;
  inputClassName?: string;
};

const InputStyles = cva(
  'bg-white border-0 px-3 py-2 text-gray-dark w-full ring-inset ring-1 focus:ring-2 focus:ring-inset focus:ring-gray-light',
  {
    variants: {
      variant: {
        light: 'ring-gray-light',
        dark: '!bg-brown-light ring-transparent',
        transparent: 'ring-transparent',
      },
    },
  },
);

/**
 * Component for autocomplete dropdowns
 * If a user starts typing, a dropdown of available options is given
 */
const SearchableSelect = ({
  ariaLabel = 'searchable-select',
  className,
  label,
  variant = 'light',
  name,
  onChange,
  onKeyDown,
  onQueryChange,
  options,
  placeholder,
  error,
  disclaimer,
  touched,
  required,
  tooltip,
  id,
  inputClassName,
}: Props) => {
  const [query, setQuery] = useState('');
  const [openOptions, setOpenOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [activeOptionIn, setActiveOptionIn] = useState(0);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const optionsRef = useRef<Array<HTMLLIElement | null>>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);

  // When an option is selected:
  // - Overwrite input with the selected option label
  // - Close the options box
  // - run the parent onChange func
  const handleChange = (option: Option) => {
    onChange(option);
    setQuery(option.label);
    setSelectedOption(option);
    setOpenOptions(false);
    inputRef?.current?.blur();
  };

  // Key input on options
  // - on enter select the option
  // - navigate with arrow down/up
  // - close on esc
  const handleOptionKeyDown = (e: React.KeyboardEvent<HTMLLIElement>, option: Option, position: number) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      handleChange(option);
    }

    if ((e.key === 'ArrowDown' || e.key === 'ArrowUp') && optionsRef.current.length) {
      e.preventDefault();
      const direction = e.key === 'ArrowUp' ? -1 : 1;
      const nextPos = position + direction;
      const next = optionsRef.current[nextPos];

      setActiveOptionIn(nextPos);
      next?.focus();
    }

    if (e.key === 'Escape') {
      e.preventDefault();
      setOpenOptions(false);
    }
  };

  // Key input event on input
  // - on enter select first option
  // - on keydown, start navigating through option list
  // - blur on esc
  const handleInputKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && options.length) {
      e.preventDefault();
      const option = selectedOption ?? options[0];

      handleChange(option);
    }

    if (e.key === 'ArrowDown' && optionsRef.current?.[0]) {
      e.preventDefault();

      optionsRef.current[0].focus();
    }

    if (e.key === 'Escape' && inputRef.current) {
      e.preventDefault();
      inputRef.current.blur();
      setOpenOptions(false);
    }

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  //useHooks-ts, close the dropdown when clicking outside the box
  useOnClickOutside(wrapperRef, () => setOpenOptions(false));

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setSelectedOption(null);
    onQueryChange(e);
  };

  return (
    <div className={twMerge('w-full', className)} ref={wrapperRef}>
      <FormFieldWrapper
        className="relative"
        disclaimer={disclaimer}
        error={error}
        id={id}
        label={label}
        name={name}
        required={required}
        tooltip={tooltip}
        touched={touched}
      >
        <div className="relative">
          <input
            aria-activedescendant={`option-${activeOptionIn}`}
            aria-autocomplete="list"
            aria-controls={`listbox-${name}`}
            aria-expanded={openOptions}
            aria-label={ariaLabel}
            autoComplete="off"
            className={twMerge(
              InputStyles({
                variant,
              }),
              inputClassName,
            )}
            id={id ?? name}
            onChange={handleInputChange}
            onFocus={() => setOpenOptions(true)}
            onKeyDown={handleInputKeydown}
            placeholder={placeholder}
            ref={inputRef}
            role="combobox"
            type="text"
            value={query}
          />
          <FontAwesomeIcon className="absolute right-4 top-1/2 -translate-y-1/2" icon={faChevronDown} />
        </div>
        {options.length > 0 && openOptions && (
          <ul
            className="max-h-75 absolute z-10 mt-2 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            id={`listbox-${name}`}
            role="listbox"
          >
            {options.map((option, i) => {
              const selected = selectedOption?.value === option.value;
              const initialActive = !activeOptionIn && i === 0;

              return (
                <li
                  aria-label={`option-${i}`}
                  aria-selected={selected}
                  className={twMerge(
                    'hover:bg-gray focus:bg-gray relative cursor-pointer select-none py-2 pl-3 pr-9 outline-none',
                    initialActive ? 'bg-gray' : 'text-gray-900',
                  )}
                  key={option.value}
                  onClick={() => handleChange(option)}
                  onKeyDown={e => handleOptionKeyDown(e, option, i)}
                  ref={elRef => {
                    optionsRef.current[i] = elRef;
                  }}
                  role="option"
                  tabIndex={-1}
                  value={option.label}
                >
                  <span className={twMerge('block cursor-pointer truncate', selected && 'font-semibold')}>
                    {option.label}
                  </span>
                  {selected && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 hover:text-white" />
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </FormFieldWrapper>
    </div>
  );
};

export default SearchableSelect;
