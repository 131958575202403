import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { IStore } from '@boss/services';
import { StoreCard, StoreMap } from '@boss/ui';
import { slugify } from '@boss/utils';

import { storeMapCenterPostion } from '../../constants';
import { storeCardVariant } from '../../utils/options';

type Props = {
  stores: IStore[];
  shopBasePath: string;
};

const TabsStyle = cva('whitespace-nowrap border-b-2 py-4 text-sm w-[50%] ', {
  variants: {
    active: {
      true: 'border-blue text-blue',
      false: 'border-gray md:border-transparant text-gray-500 hover:border-gray-300 hover:text-gray-700',
    },
  },
});

const MapComp = ({ stores, storeBasePath }: { stores: IStore[]; storeBasePath: string }) => {
  const { t } = useTranslation('stores');

  return (
    <StoreMap
      apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY as string}
      centerPosition={storeMapCenterPostion}
      storeBasePath={storeBasePath}
      storeCardVariant={storeCardVariant}
      stores={stores}
      translations={t('storeFinder.card', { returnObjects: true })}
    />
  );
};

const StoreList = ({ stores, shopBasePath }: { stores: IStore[]; shopBasePath: string }) => {
  const { t } = useTranslation('stores');

  return (
    <>
      {stores.map(store => (
        <div className="mb-6" key={store.id}>
          <StoreCard
            href={store.storetype === 'Colora' ? `${shopBasePath}/${slugify(store.name)}` : undefined}
            message={store.customMessage}
            store={store}
            translations={t('storeFinder.card', { returnObjects: true })}
            variant={storeCardVariant}
          />
        </div>
      ))}
    </>
  );
};
const StoreResults = ({ stores, shopBasePath }: Props) => {
  const { t } = useTranslation('stores');
  const tabs = [
    { name: t('results.map'), value: 'maps' },
    { name: t('results.results'), value: 'results' },
  ];
  const [activeTab, setActiveTab] = useState(tabs[1].value);

  useEffect(() => {
    const myDiv = document.getElementById('storeResults');

    if (myDiv) {
      myDiv.scrollTop = 0;
    }
  }, [stores]);
  return (
    <>
      <div className="-mt-10 mb-5 w-full lg:hidden">
        <nav aria-label="Tabs" className="flex w-full">
          {tabs.map(tab => (
            <button
              className={TabsStyle({
                active: activeTab === tab.value,
              })}
              key={tab.name}
              onClick={() => setActiveTab(tab.value)}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
      <div className="lg:hidden">
        {activeTab === 'results' ? (
          <StoreList shopBasePath={shopBasePath} stores={stores} />
        ) : (
          <div className="h-[40em] w-full">
            <MapComp storeBasePath={shopBasePath} stores={stores} />
          </div>
        )}
      </div>
      <div className="hidden grid-cols-2 gap-4 lg:grid">
        <div className="h-[90vh] overflow-y-scroll pl-1 pr-7 pt-1" id="storeResults">
          <StoreList shopBasePath={shopBasePath} stores={stores} />
        </div>
        <div>
          <MapComp storeBasePath={shopBasePath} stores={stores} />
        </div>
      </div>
    </>
  );
};

export default StoreResults;
