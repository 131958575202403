import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';

import { registerPageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { Button, Link, Section, Specifications } from '@boss/ui';

import { LoginSectionProps } from '..';
import { LoginForm } from '../../';

const CardStyling = cva('rounded-brand flex flex-col gap-6 rounded-bl-none px-5 py-4 lg:col-span-5 md:gap-8 md:p-9', {
  variants: {
    variant: {
      form: 'bg-beige-light lg:col-start-2',
      info: 'bg-blue-light/30 md:rounded-bl-brand md:rounded-br-none',
    },
  },
});

const LoginSectionPrimary = ({ footerLink }: LoginSectionProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation('login');

  return (
    <Section
      className="md:pt-13 md:pb-35 grid gap-4 pb-20 md:grid-cols-2 md:gap-6 lg:grid-cols-12"
      content={
        <>
          <div className={CardStyling({ variant: 'form' })}>
            <h2 className="md:h3">{t('existingClientCard.title')}</h2>
            <LoginForm />
          </div>
          <div className={CardStyling({ variant: 'info' })}>
            <div className="flex flex-col gap-3 md:gap-6">
              <h2 className="md:h3 text-blue">{t('newClientCard.title')}</h2>
              <span className="text-blue">{t('newClientCard.subtitle')}</span>
            </div>
            <Button
              className="h-12 w-full justify-center text-center md:w-fit [&>*]:w-auto"
              href={registerPageSlugs[locale]}
              icon={faArrowRight}
              iconPosition="right"
              label={t('newClientCard.continueButtonLabel')}
              type="primary"
            />
            <div className="text-blue flex flex-col gap-3 md:gap-6">
              <span>{t('newClientCard.benefits.title')}</span>
              <Specifications
                border={false}
                className="md:gap-4"
                items={[1, 2].map(item => (
                  <span className="text-textColor" key={`client-benefit-${item}`}>
                    {t(`newClientCard.benefits.${item}`)}
                  </span>
                ))}
              />
            </div>
            {footerLink && (
              <Link className="small text-blue-dark mt-auto underline" href={footerLink.href}>
                {footerLink.label}
              </Link>
            )}
          </div>
        </>
      }
    />
  );
};

export default LoginSectionPrimary;
