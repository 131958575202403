import { useTranslation } from 'next-i18next';

import { IColorGroup } from '@boss/services';

export function useColorGroup() {
  const { t } = useTranslation();

  const normalizeColorGroupNames = (colorGroups: IColorGroup[]) =>
    colorGroups?.map(group => ({
      ...group,
      name: t(`colors.${group.code}`, { ns: 'common' }),
    }));

  return {
    normalizeColorGroupNames,
  };
}

export default useColorGroup;
