import { twMerge } from 'tailwind-merge';

import { usePagination } from '@boss/hooks';
import { ISearchProduct } from '@boss/types/b2b-b2c';
import { Pagination, ProductsGrid } from '@boss/ui';

import { ProductCardWrapper } from '../../components';
import { useProductMap } from '../../hooks';

interface Props {
  products?: ISearchProduct[];
  itemsPerPage?: number;
  onSelect?: (product: ISearchProduct) => void;
  onRemove?: (product: ISearchProduct) => void;
  className?: string;
}

const PagedProducts = ({
  products,
  itemsPerPage = 6,
  onSelect: handleSelect,
  onRemove: handleRemove,
  className,
}: Props) => {
  const { mapProductComponent } = useProductMap();
  const { currentPage, paginate, currentItems, totalPages } = usePagination<ISearchProduct>(itemsPerPage, products);

  return (
    <>
      <ProductsGrid className={twMerge('col-span-3', className)}>
        {currentItems?.map(product => (
          <ProductCardWrapper
            key={`search-card-${product.id}`}
            {...mapProductComponent(product)}
            handleAddToWishlist={undefined}
            handleRemove={handleRemove ? () => handleRemove(product) : undefined}
            handleSelect={handleSelect ? () => handleSelect(product) : undefined}
            hideAddToCartAction={!!handleSelect}
          />
        ))}
      </ProductsGrid>
      {totalPages > 1 && (
        <Pagination className="mt-5" currentPage={currentPage} onPaginate={paginate} totalPages={totalPages} />
      )}
    </>
  );
};

export default PagedProducts;
