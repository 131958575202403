import { faCheck, faLocationCrosshairs, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/sharp-solid-svg-icons';
import { cva } from 'class-variance-authority';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useDebounce } from 'usehooks-ts';

import { useGeoLocation } from '@boss/hooks';
import type { IAddressSuggestions, StoreFinderProps } from '@boss/types/b2b-b2c';
import { StoreFinderSearch } from '@boss/ui';

import { useAutoCompleteAddress } from '../../client-queries';

interface CompactFinderProps extends StoreFinderProps {
  permissionDenied: boolean;
  setQuery: (s: string) => void;
  searchByCurrentLocation: () => Promise<void>;
  suggestions: IAddressSuggestions | undefined;
}

type FinderProps = Omit<CompactFinderProps, 'permissionDenied' | 'searchByCurrentLocation'>;

type LocationButtonProps = Pick<
  CompactFinderProps,
  'className' | 'permissionDenied' | 'searchByCurrentLocation' | 'translations'
>;

const backgroundColor = cva('', {
  variants: {
    variant: {
      primary: 'before:bg-beige after:bg-beige bg-beige',
      secondary: 'before:bg-gray-lightest after:bg-gray-lightest bg-gray-lightest',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

const filterButton = cva('relative inline-block rounded-30 px-5 py-2', {
  variants: {
    variant: {
      primary: 'bg-white text-green',
      secondary: 'ring-1 ring-inset ring-blue-dark text-blue-dark',
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

const SearchStyles = cva('md:mt-4 md:py-4 md:px-4 xl:py-4 mb-2 px-4 py-4 xl:px-4', {
  variants: {
    variant: {
      primary: 'bg-beige rounded-full',
      secondary: 'bg-gray-lightest',
    },
  },
});

const getPrimaryIconColor = (isActive: boolean | undefined) => {
  return isActive ? 'pr-2 text-white' : 'text-green pr-2';
};

const getSecondaryIconColor = (isActive: boolean | undefined) => {
  return isActive ? 'pl-2 text-white' : 'text-green pl-2';
};

/**
 * Small button comp to search on current location
 */
const SearchByLocationButton = ({
  className,
  permissionDenied,
  searchByCurrentLocation,
  translations,
}: LocationButtonProps) => (
  <div className={twMerge('text-blue-dark mt-5 flex items-center md:ml-4 md:mt-2 lg:mt-0', className)}>
    {!permissionDenied ? (
      <>
        <FontAwesomeIcon className="pr-2 " icon={faLocationCrosshairs} />
        <button
          className="disabled:text-blue-dark/[.50] text-left underline md:text-center"
          onClick={() => searchByCurrentLocation()}
        >
          {translations.searchByCurrentLocationLabel ?? 'Zoek op huidige locatie'}
        </button>
      </>
    ) : (
      <div className="text-small text-red">
        {translations.geoLocationError ??
          'Het is niet mogelijk om uw huidige locatie te bepalen. Gelieve uw browserinstellingen te controleren.'}
      </div>
    )}
  </div>
);

/**
 * Compact finder variant, only the searchbar, button and search from current location button are used
 */
const CompactFinder = ({
  variant,
  setQuery,
  onSearch,
  overviewUrl,
  redirect,
  suggestions,
  translations,
  searchByCurrentLocation,
  permissionDenied,
}: CompactFinderProps) => {
  return (
    <>
      <div className={SearchStyles({ variant })}>
        <StoreFinderSearch
          getSuggestions={setQuery}
          onSearch={onSearch}
          overviewUrl={overviewUrl}
          redirect={redirect}
          suggestions={suggestions}
          translations={translations}
          variant={variant}
        />
      </div>
      <div className="mb-4 px-0 pb-3 xl:px-6">
        <SearchByLocationButton
          className="text-left"
          permissionDenied={permissionDenied}
          searchByCurrentLocation={searchByCurrentLocation}
          translations={translations}
        />
      </div>
    </>
  );
};

/**
 * Basic store finder variant: title, bullet points and compact finder
 */
const Finder = ({
  variant,
  setQuery,
  onSearch,
  overviewUrl,
  redirect,
  suggestions,
  translations,
  className,
}: FinderProps) => {
  return (
    <div
      className={twMerge(
        'rounded-r-30 rounded-bl-30 xl:rounded-r-50 xl:rounded-bl-50 align-center flex min-h-[22em] items-center px-10 py-7',
        backgroundColor({ variant }),
        className,
      )}
    >
      <div className="w-full flex-col">
        <h2 className="mb-5">{translations.title}</h2>
        {translations.bullets?.map(bullet => (
          <div key={bullet}>
            <FontAwesomeIcon className="pr-2" icon={faCheck} />
            <span>{bullet}</span>
          </div>
        ))}
        <div className="mb-4 px-0 pb-3 xl:px-6"></div>
        <div className="mt-10">
          <StoreFinderSearch
            getSuggestions={setQuery}
            onSearch={onSearch}
            overviewUrl={overviewUrl}
            redirect={redirect}
            suggestions={suggestions}
            translations={translations}
            variant={variant}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * Store finder component with three structural variations: compact, normal and extended
 * The extended variant includes filters
 */
const StoreFinder = ({
  translations,
  overviewUrl,
  onSearch,
  variant,
  extended,
  compact,
  activeFilters,
  onUpdateFilters: handleUpdateFilters,
  sortByCurrentLocation,
  className,
  redirect,
  noFilters,
}: StoreFinderProps) => {
  const { latitude, longitude, permissionDenied, fetchGeolocation } = useGeoLocation();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 400);
  const { data: suggestions } = useAutoCompleteAddress(debouncedQuery);

  const updateFilter = (filter: object) =>
    handleUpdateFilters &&
    handleUpdateFilters({
      ...activeFilters,
      ...filter,
    });

  const searchByCurrentLocation = async () => {
    if (!sortByCurrentLocation) {
      return;
    }

    if (!latitude || !longitude) {
      const response = await fetchGeolocation();

      if (response?.latitude && response.longitude) {
        sortByCurrentLocation(response);
      }
      return;
    }

    sortByCurrentLocation({ latitude, longitude });
  };

  if (compact) {
    return (
      <CompactFinder
        {...{
          variant,
          setQuery,
          onSearch,
          overviewUrl,
          redirect,
          suggestions,
          translations,
          searchByCurrentLocation,
          permissionDenied,
        }}
      />
    );
  }

  if (!extended) {
    return (
      <Finder
        {...{
          variant,
          setQuery,
          onSearch,
          overviewUrl,
          redirect,
          suggestions,
          translations,
        }}
      />
    );
  }

  return (
    <div
      className={twMerge(
        'break-before break-after align-center before:z-1 after:z-1 relative flex min-h-[12em] w-full items-center pb-7',
        backgroundColor({ variant }),
        className,
      )}
    >
      <div className="w-full flex-col">
        <h2 className="mb-5">{translations.title}</h2>
        <div className="mt-5 grid grid-cols-1 align-middle lg:grid-cols-2">
          <StoreFinderSearch
            getSuggestions={setQuery}
            onSearch={onSearch}
            overviewUrl={overviewUrl}
            redirect={redirect}
            suggestions={suggestions}
            translations={translations}
            variant={variant}
          />
          <SearchByLocationButton
            permissionDenied={permissionDenied}
            searchByCurrentLocation={searchByCurrentLocation}
            translations={translations}
          />
        </div>
        {!noFilters && (
          <div className="relative mt-6">
            <span className="pr-3">Filters:</span>
            <button
              className={twMerge(filterButton({ variant }), activeFilters?.isOpen ? 'bg-green text-white' : '')}
              onClick={() => updateFilter({ isOpen: !activeFilters?.isOpen })}
            >
              {variant === 'primary' && (
                <FontAwesomeIcon className={getPrimaryIconColor(activeFilters?.isOpen)} icon={faCircle} size="2xs" />
              )}
              {translations.openFilterLabel ?? 'Nu open'}
              {variant === 'secondary' && (
                <FontAwesomeIcon className={getSecondaryIconColor(activeFilters?.isOpen)} icon={faCircle} size="2xs" />
              )}
            </button>
            {activeFilters?.isOpen && (
              <button
                className="absolute -top-1 -ml-3 rounded-full bg-white px-[0.6em] shadow-sm"
                onClick={() => updateFilter({ isOpen: false })}
              >
                <FontAwesomeIcon icon={faXmark} size="2xs" />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreFinder;
