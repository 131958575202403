import * as bossClient from '@boss/boss-client';
import { Nullable } from 'vitest';

import { IWishlist, IWishlistLine, IWishlists } from './types';

/**
 * Fetches all wishlist items for an account
 */
export const all = async (accountId: string) => {
  const lists = (await bossClient.wishlist.getAllWishlists(accountId)) || [];

  return lists.reduce((acc, list) => {
    const type = list.type;

    if (type === 'sku' || type === 'product') {
      if (acc[type]?.length) {
        acc[type].push(list);
      } else {
        acc[type] = [list];
      }
    } else if (type === 'favoritecolor') {
      // Filter out lines without valid color object
      acc[type] = {
        ...list,
        lines: list.lines?.filter(line => line.color && line.color.colorcode && line.color.rgb) || [],
      };
    } else {
      acc[type] = list;
    }
    return acc;
  }, {} as IWishlists);
};

/**
 * Create a new wishlist
 */
export const create = async ({ wishlist }: { wishlist: IWishlist }): Promise<Nullable<IWishlist>> => {
  return await bossClient.wishlist.createWishlist({ wishlist });
};

/**
 * Delete a wishlist
 */
export const deleteWishlist = async ({ wishlistId }: { wishlistId: string }) => {
  return await bossClient.wishlist.deleteWishlist({ wishlistId });
};

/**
 * Update a wishlist
 */
export const update = async ({ wishlist }: { wishlist: IWishlist }) => {
  return await bossClient.wishlist.updateWishlist({ wishlist });
};

/**
 * Create a wishlistLine
 */
export const createLine = async ({
  wishlistLine,
  wishlistId,
}: {
  wishlistLine: IWishlistLine;
  wishlistId: string;
}): Promise<Nullable<IWishlistLine>> => {
  return await bossClient.wishlist.createWishlistLine({
    wishlistLine,
    wishlistId,
  });
};

/**
 * Create multiple wishlistLines
 */
export const createLines = async ({
  wishlistLines,
  wishlistId,
}: {
  wishlistLines: IWishlistLine[];
  wishlistId: string;
}): Promise<Nullable<IWishlistLine>[]> => {
  return await Promise.all(
    wishlistLines.map(wishlistLine => bossClient.wishlist.createWishlistLine({ wishlistLine, wishlistId })),
  );
};

/**
 * Delete a wishlistLine
 */
export const deleteLine = async ({ wishlistLineId, wishlistId }: { wishlistLineId: string; wishlistId: string }) => {
  return await bossClient.wishlist.deleteWishlistLine({ wishlistLineId, wishlistId });
};

/**
 * Update a wishlistLine
 */
export const updateLine = async ({
  wishlistLine,
  wishlistId,
}: {
  wishlistLine: IWishlistLine;
  wishlistId: string;
}): Promise<Nullable<IWishlistLine>> => {
  return await bossClient.wishlist.updateWishlistLine({ wishlistLine, wishlistId });
};
