import { IconDefinition, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  border?: boolean;
  className?: string;
  fontStyle?: string;
  icon?: IconDefinition;
  iconClassName?: string;
  id?: string;
  items: ReactNode[];
  liClassName?: string;
  variant?: 'checked' | 'bullets';
};

const BorderStyles = cva('pb-1', {
  variants: {
    variant: {
      checked: 'border-dashed border gray',
      bullets: 'flex-row flex-wrap',
    },
    border: { true: 'pb-1 border-b-1 border-dashed border-gray', false: 'border-none' },
  },
});

const BulletStyles = cva('flex', {
  variants: {
    variant: {
      checked: 'items-top gap-2',
      bullets:
        'before:content-[""] before:-left-6 before:top-2 md:before:top-2 before:absolute relative ml-6 before:w-1.5 before:h-1.5 md:before:w-2 md:before:h-2 before:rounded-full before:bg-generic-brown',
    },
  },
});

const Specifications = ({
  border = true,
  className,
  fontStyle = 'small',
  icon = faCheck,
  iconClassName,
  id,
  items,
  liClassName,
  variant = 'checked',
}: Props) => (
  <ul className={twMerge('flex flex-col gap-2', className)}>
    {items.map((specification, index) => (
      <li className={twMerge(BulletStyles({ variant }), liClassName)} key={`${id ?? specification}-${index}`}>
        {variant === 'checked' && <FontAwesomeIcon className={twMerge('text-green pt-1', iconClassName)} icon={icon} />}
        <span className={twMerge(BorderStyles({ variant, border }), fontStyle)}>{specification}</span>
      </li>
    ))}
  </ul>
);

export default Specifications;
