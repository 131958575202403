const API_CREDENTIALS = {
  client_id: process.env.BOSS_API_CLIENT_ID || '',
  client_secret: process.env.BOSS_API_CLIENT_SECRET || '',
};

const API_CREDENTIALS_NL_NL = {
  client_id: process.env.BOSS_API_CLIENT_ID_NL_NL || '',
  client_secret: process.env.BOSS_API_CLIENT_SECRET_NL_NL || '',
};

const API_CREDENTIALS_B2B = {
  client_id: process.env.BOSS_API_CLIENT_ID_B2B || '',
  client_secret: process.env.BOSS_API_CLIENT_SECRET_B2B || '',
};

const API_CREDENTIALS_B2B_NL_NL = {
  client_id: process.env.BOSS_API_CLIENT_ID_B2B || '',
  client_secret: process.env.BOSS_API_CLIENT_SECRET_B2B || '',
};

const API_CREDENTIALS_B2C = {
  client_id: process.env.BOSS_API_CLIENT_ID_B2C || '',
  client_secret: process.env.BOSS_API_CLIENT_SECRET_B2C || '',
};

const API_CREDENTIALS_B2C_NL_NL = {
  client_id: process.env.BOSS_API_CLIENT_ID_B2C_NL_NL || '',
  client_secret: process.env.BOSS_API_CLIENT_SECRET_B2C_NL_NL || '',
};

const CHANNEL_CREDENTIALS: Record<string, { client_id: string; client_secret: string }> = {
  'boss-paints': API_CREDENTIALS_B2B,
  colora: API_CREDENTIALS_B2C,
};

const CHANNEL_CREDENTIALS_NL_NL: Record<string, { client_id: string; client_secret: string }> = {
  'boss-paints': API_CREDENTIALS_B2B_NL_NL,
  colora: API_CREDENTIALS_B2C_NL_NL,
};

export const bossApiContext = {
  channel: '',
};

export const getApiCredentials = (locale: string) => {
  if (process.env.AZURE_FUNCTIONS_ENVIRONMENT && bossApiContext.channel) {
    if (locale === 'nl-NL') {
      return CHANNEL_CREDENTIALS_NL_NL[bossApiContext.channel] ?? API_CREDENTIALS;
    }

    return CHANNEL_CREDENTIALS[bossApiContext.channel] ?? API_CREDENTIALS;
  }

  return locale === 'nl-NL' ? API_CREDENTIALS_NL_NL : API_CREDENTIALS;
};
