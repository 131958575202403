import { usePagination } from 'react-instantsearch';

import { Pagination } from '@boss/ui';

type Props = {
  className?: string;
  onPaginate?: () => void;
  totalPages?: number;
};

const PaginationWidget = ({ className, onPaginate, totalPages }: Props) => {
  const { currentRefinement, refine } = usePagination();

  const handlePagination = (page: number) => {
    refine(page);
    onPaginate?.();
  };

  return (
    <Pagination
      className={className}
      currentPage={currentRefinement}
      onPaginate={handlePagination}
      totalPages={totalPages}
    />
  );
};

export default PaginationWidget;
