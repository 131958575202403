export const PAYMENT_TYPE = {
  cash: 1,
  invoice: 2,
} as const;

export type PAYMENT_TYPE_UNION = (typeof PAYMENT_TYPE)[keyof typeof PAYMENT_TYPE];

export interface Charge {
  code: string;
  category: string;
  value: number;
  description: string;
  vatcode: string;
}

export interface BasketDeliveryAddress {
  name?: string;
  street?: string;
  streetnumber?: string;
  postbox?: string;
  zipcode?: string;
  city?: string;
  country?: string;
}

export interface DeliveryOptions {
  shipcomplete?: boolean;
  deliverymethod: 1 | 2 | 3 | 4;
  site?: string;
  warehouse?: string;
  info?: string;
  requesteddate?: string;
  contactfordelivery?: string;
  address?: BasketDeliveryAddress;
}

export interface BasketLine {
  id?: string; // When creating a new line, the id should be excluded as this will get generated by the backend
  linenumber?: number;
  modeofdelivery?: string;
  item: {
    skuid: string;
    variant?: string;
    description?: string;
    color: {
      colorcode: string;
      colorid: string;
      colorname: string;
      rgb: string;
    };
    stockproductnumber?: string;
    vatcode?: string;
    chargegroup?: string;
  };
  quantity: {
    salesquantity: number;
    salesunit: string;
    secondaryquantity: number;
    secondaryunit: string;
    returnreasoncode?: string;
  };
  price: {
    salesprice: number;
    netamountexvat: number;
    netamountinclvat: number;
    amounttopay: number;
  };
  discount?: {
    linepercentage: number;
    discountorigin: string;
    discountid: string;
    specialdiscount: boolean;
    campaignid: string;
    giveawayid: string;
    discountsingleline: boolean;
    origlinepercent: number;
    origdiscountorigin: string;
    origdiscountid: string;
    origspecialdiscount: boolean;
    origcampaignid: string;
    bestlinepercent: number;
    bestdiscountorigin: string;
    bestdiscountid: string;
    bestspecialdiscount: boolean;
    bestcampaignid: string;
    complinepercent: number;
    compdiscountorigin: string;
    compdiscountid: string;
    compspecialdiscount: boolean;
    compcampaignid: string;
  };
  charges?: Charge[];
  creationdatetime?: string;
  modifiedby?: string;
  lastsynceddatetimeutc?: string;
}

export interface Basket {
  id: string;
  companyid: string;
  type: string;
  status: string;
  ordertype: string;
  orderdate: string;
  worksitediscountcode: string;
  tracking: {
    creationdatetime: string;
    modificationdatetime: string;
  };
  account: {
    accountnumber: string;
    contactpersonid: string;
    cashdiscountpercentage: number;
    taxgroup: string;
    pricegroup: string;
    chargegroup: string;
    paymentrequired: boolean;
    termsofpayment: string;
  };
  pricesummary: {
    calculateorderdiscounts: boolean;
    calculategiveaways: boolean;
    totalnetamountexvat: number;
    totalnetamountinclvat: number;
    totalchargeamountexvat: number;
    totalchargeamountinclvat: number;
    totalvat: number;
    totalgrossamountinclvat: number;
    totalgrossamountexclvat: number;
    amounttopay: number;
  };
  delivery: DeliveryOptions;
  invoice: {
    separatefororder: boolean;
    onhold: boolean;
    info: string;
  };
  payment: {
    paymenttype: PAYMENT_TYPE_UNION;
    pending: boolean;
    payreference: string;
  };
  lines: BasketLine[];
  lastsynceddatetimeutc: string;
}

export interface DeliveryDates {
  dates: string[];
  deliverymode: string;
}

export interface BasketPatchLine {
  path: string;
  op: string;
  value: string;
}
