import { useTranslation } from 'next-i18next';
import { useSortBy } from 'react-instantsearch';

import { SortOption } from '../../components/Algolia/Sort';
import { useProfile } from '../../hooks';

export const useSort = (options: SortOption[], indexName?: string) => {
  const { t } = useTranslation('common');

  // TODO: should come from hook/API/Usermgmt
  const { data } = useProfile();

  const checkConditions = (option: SortOption) => {
    if (!option.condition) {
      return true;
    }
    if (option.condition.isLoggedIn) {
      return !!data;
    }
  };

  const SORT_OPTIONS = options.filter(checkConditions).map(({ labelKey, indexSuffix }) => {
    const mappedSuffix = indexSuffix ? `_${indexSuffix}` : '';

    return {
      label: t(labelKey),
      value: `${indexName}${mappedSuffix}`,
    };
  });

  return useSortBy({
    items: SORT_OPTIONS,
  });
};

export default useSort;
