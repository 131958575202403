import { useQuery } from '@tanstack/react-query';

import { useRouter } from '@boss/hooks';
import { IArticleStock } from '@boss/types/b2b-b2c';

import { fetchStockByWarehouseIds } from './connector';

import { stockKeys } from '.';

export interface Sku {
  skuid: string;
  quantity?: number;
  unit?: string;
}

const useStockByWarehouseIds = ({ warehouseIds, skus }: { warehouseIds: string[]; skus: Sku[] }, enabled: boolean) => {
  const { locale } = useRouter();

  return useQuery<IArticleStock | undefined>({
    queryKey: stockKeys.stockByWarehouseIds({ locale, warehouseIds, skus }),
    queryFn: async () => await fetchStockByWarehouseIds({ warehouseIds, skus }),
    enabled: !!enabled,
  });
};

export { useStockByWarehouseIds };
