import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import { useFormField, useLogin } from '../../hooks';
import DynamicForm from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

const areMatchingFieldsValid = (formik: ReturnType<typeof useFormik>, keyA: string, keyB: string) =>
  formik.touched?.[keyA] &&
  formik.touched?.[keyB] &&
  formik.values?.[keyA]?.toLowerCase() !== formik.values?.[keyB]?.toLowerCase() &&
  !formik.errors?.[keyA] &&
  !formik.errors?.[keyB];

const RegisterForm = () => {
  const { t } = useTranslation('forms');
  const [isError, setIsError] = useState(false);
  const { handleRegister } = useLogin();
  const { asPath } = useRouter();

  const { firstname, lastname, email, password, termsandconditions } = useFormField({
    enableInitialValues: false,
  });

  const additionalEmailProps = {
    placeholder: t('placeholders.email') ?? '',
  };
  const additionalPasswordProps = {
    placeholder: t('placeholders.password') ?? '',
  };

  const fields: FormField[] = [
    { ...firstname, placeholder: t('placeholders.firstname') ?? '' },
    { ...lastname, placeholder: t('placeholders.lastname') ?? '' },
    { ...email, ...additionalEmailProps },
    {
      ...email,
      ...additionalEmailProps,
      name: 'emailConfirm',
      errorLabel: t('errors.emailConfirm') ?? '',
    },
    { ...password, ...additionalPasswordProps },
    {
      ...password,
      ...additionalPasswordProps,
      name: 'passwordConfirm',
      errorLabel: t('errors.passwordConfirm') ?? '',
    },
    termsandconditions,
  ];

  const handleFormValuesChange = (formik: ReturnType<typeof useFormik>) => {
    if (areMatchingFieldsValid(formik, 'emailConfirm', 'email')) {
      formik.setFieldError('emailConfirm', 'error'); // Original field error has priority so the error value here doesn't matter
    }

    if (areMatchingFieldsValid(formik, 'passwordConfirm', 'password')) {
      formik.setFieldError('passwordConfirm', 'error'); // Original field error has priority so the error value here doesn't matter
    }
  };

  const handleSubmit = async () => setIsError(true); // TODO: Implement register action

  return (
    <DynamicForm
      buttonProps={{
        className: 'h-12 w-full justify-center text-center md:w-auto [&>*]:w-auto md:self-start',
        label: t('buttons.register') ?? '',
        iconPosition: 'right',
        icon: faArrowRight,
        onClick: () => handleRegister(asPath),
      }}
      errorSubtitle={t('errors.submitSubtitle') ?? ''}
      errorTitle={t('errors.submitTitle') ?? ''}
      fields={fields}
      formFieldsWrapperClassName="grid gap-5 md:grid-cols-3 lg:grid-cols-6"
      id="register-form"
      isError={isError}
      onFormValuesChange={handleFormValuesChange}
      onSubmit={handleSubmit}
      options={{ validateOnChange: false }}
    />
  );
};

export default RegisterForm;
