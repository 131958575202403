import Cookies from 'universal-cookie';

import { needsRefresh, session } from '../../utils';
import env from '../env';
import logout from '../logout/logout';
import * as wellKnown from '../well-known/well-known';

/**
 * Launches a refresh attempt
 *
 * First tries if the current session is still active,
 * if not, and a refresh token is present, attempt refresh
 *
 * NOTE: This will clear the session if the refresh attempt is
 * unsuccesfull
 */
export const refresh = async (force = false) => {
  const profile = session.getData();
  const token = session.getRefreshToken();

  if (token && profile && (needsRefresh(profile) || force)) {
    // Attempt refresh
    try {
      session.setStatus('loading');
      const wn = await wellKnown.get();

      const response = await fetch(wn.tokenUri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          response_type: 'id_token',
          client_id: env.CLIENT_ID,
          resource: env.CLIENT_ID,
          refresh_token: token,
        }),
      });

      const data = await response.json();

      // Re-activate the session
      session.activate(JSON.parse(atob(data.id_token.split('.')[1])), data.refresh_token);

      const cookies = new Cookies(null, { path: '/' });

      cookies.set(env.ACCESS_TOKEN_COOKIE_NAME, data.access_token, { maxAge: data.expires_in });
    } catch (error) {
      logout();
    }

    session.setStatus('idle');
  }
};

export default refresh;
