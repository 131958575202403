import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { OverviewFields } from '@boss/types/b2b-b2c';
import { ColorTool } from '@boss/ui';

import { ComponentMapper } from '../../components';

type Props = {
  page?: OverviewFields | null;
  locale: string;
};

const ColorToolOverviewPage = ({ page, locale }: Props) => {
  const { t } = useTranslation('colorTool');
  const { query } = useRouter();

  return (
    <>
      <h1 className="mb-20">{t('overviewTitle')}</h1>
      {page?.prefixSections?.map(entry => (
        <ComponentMapper entry={entry} key={entry.id} locale={locale} />
      ))}

      <ColorTool className="my-20" query={query} />

      {page?.suffixSections?.map(entry => (
        <ComponentMapper entry={entry} key={entry.id} locale={locale} />
      ))}
    </>
  );
};

export default ColorToolOverviewPage;
