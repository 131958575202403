import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../Button';

type Cta = {
  label: string;
  href: string;
  id: string;
};

type Props = {
  phone: string;
  email: string;
  address?: string;
  ctas?: Cta[];
};

const InfoRow = ({ icon, label }: { icon: IconDefinition; label: string }) => (
  <div className="flex items-center gap-3">
    <FontAwesomeIcon icon={icon} />
    <span>{label}</span>
  </div>
);

const ContactInfoBlock = ({ phone, email, address, ctas }: Props) => (
  <div className="flex flex-col gap-3">
    <InfoRow icon={faPhone} label={phone} />
    <InfoRow icon={faEnvelope} label={email} />
    {address && <InfoRow icon={faLocationDot} label={address} />}
    {ctas && (
      <div className="flex flex-col">
        {ctas.map(cta => (
          <Button
            className="border-b-green [&>*]:text-md w-fit pb-1"
            href={cta.href}
            key={cta.id}
            label={cta.label}
            type="tertiary"
          />
        ))}
      </div>
    )}
  </div>
);

export default ContactInfoBlock;
