import { getPixelPositionOffset } from '@boss/utils';
import { OverlayView } from '@react-google-maps/api';

type Props = {
  position: google.maps.LatLngLiteral;
  count: number;
  onClick: () => void;
};

const ClusterMarker = ({ position, count, onClick: handleClick }: Props) => {
  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width, height) =>
        getPixelPositionOffset({
          width,
          height,
        })
      }
    >
      <div onClick={handleClick} className="flex items-center justify-center w-12 h-12 rounded-full bg-white/80">
        <span className="text-lg font-bold">{count}</span>
      </div>
    </OverlayView>
  );
};

export default ClusterMarker;
