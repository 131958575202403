import '@fortawesome/fontawesome-svg-core/styles';
import { config } from '@fortawesome/fontawesome-svg-core';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppProps } from 'next/app';
import { Raleway } from 'next/font/google';
import Head from 'next/head';
import { UserConfig, appWithTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { Toaster, toast } from 'sonner';

import { CHANNEL } from '@boss/constants/b2b-b2c';

import { AppScripts, RecaptchaProvider } from '../components';
import { GoogleTagManagerProvider } from '../containers';
import { FavoriteProvider, ProfileProvider } from '../hooks';
import i18nConfig from '../next-i18next.config';
import '../styles/global.scss';
import { isB2b, toasterConfig } from '../utils';

config.autoAddCss = false;

const raleway = Raleway({
  subsets: ['latin'],
  variable: '--font-raleway',
});

type Query = {
  meta?: {
    errorMessage?: string;
  };
};

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
  queryCache: new QueryCache({
    onError: (_, query: Query) => {
      if (query?.meta?.errorMessage) {
        toast.error(query.meta.errorMessage);
      }
    },
  }),
});

const favicon = isB2b ? '/favicon_b2b.ico' : '/favicon_b2c.ico';

const CustomApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    if (document) {
      const cookieName = 'NEXT_LOCALE';
      const localeCookie = document.cookie.split('; ').find(row => row.startsWith(`${cookieName}=`));

      if (localeCookie) {
        // If NEXT_LOCALE cookie exists, remove it by setting the expiration date in the past
        document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      }

      // Set or replace the NEXT_LOCALE cookie with the new value
      const newLocale = pageProps.locale;
      const expires = new Date();

      expires.setFullYear(expires.getFullYear() + 1); // 1 year expiration
      document.cookie = `${cookieName}=${newLocale};expires=${expires.toUTCString()};path=/`;
    }
  }, [pageProps.locale]);

  return (
    <>
      <AppScripts locale={pageProps.locale} />
      <Head>
        <title>{`Welcome to ${CHANNEL}`}</title>
        <link href={favicon} rel="icon" sizes="any" />
      </Head>
      <main className={`${raleway.variable} app`}>
        <ProfileProvider>
          <GoogleTagManagerProvider gtmId={process.env.NEXT_PUBLIC_GTM_ID ?? ''} pageProps={pageProps}>
            <QueryClientProvider client={queryClient}>
              <RecaptchaProvider>
                <FavoriteProvider>
                  <Toaster closeButton {...toasterConfig} />
                  <Component {...pageProps} />
                  <ReactQueryDevtools initialIsOpen={false} />
                </FavoriteProvider>
              </RecaptchaProvider>
            </QueryClientProvider>
          </GoogleTagManagerProvider>
        </ProfileProvider>
      </main>
    </>
  );
};

/**
 * we need to cast the i18nConfig to UserConfig because typescript is complaining about this config
 * localeDetection: false (Type 'boolean' is not assignable to type 'false'.)
 *  */
export default appWithTranslation(CustomApp, i18nConfig as UserConfig);
