import * as Sentry from '@sentry/nextjs';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useRouter } from '@boss/hooks';

import { fetchAddressById, fetchAutoCompleteSuggestions, validateAddress } from './connector';

import { addressesKey } from '.';

export type Address = {
  street: string;
  streetnumber: string;
  postbox: string;
  zipcode: string;
  city: string;
  country: string;
};

const useAutoCompleteAddress = (query: string) => {
  const { locale } = useRouter();

  return useQuery({
    queryKey: addressesKey.addressBySuggestion(locale, query),
    queryFn: async () => await fetchAutoCompleteSuggestions(locale, query),
    onError: e => {
      console.error(e);
      Sentry.captureException(e, {
        tags: {
          type: 'Fetch autocomplete suggestions',
        },
      });
    },
  });
};

const useAddressById = (id: string) => {
  return useQuery({
    queryKey: addressesKey.addressById(id),
    queryFn: async () => await fetchAddressById(id),
    onError: e => {
      console.error(e);
      Sentry.captureException(e, {
        tags: {
          type: 'Fetch autocomplete by id',
        },
      });
    },
  });
};

const useAddressValidation = () => {
  return useMutation({
    mutationFn: async (address: Address) => await validateAddress(address),
    onError: e => {
      console.error(e);
      Sentry.captureException(e, {
        tags: {
          type: 'Address validation',
        },
      });
    },
  });
};

export { useAutoCompleteAddress, useAddressById, useAddressValidation };
