import { ComponentProps } from 'react';
import { Toaster } from 'sonner';

import { eventPageSlugsB2C, homeDeliverySlugsB2C } from '@boss/constants/b2b-b2c';
import { IStore } from '@boss/services';
import type {
  BasicVariant,
  IContactInformationFields,
  ImageProps,
  LocalizedSlugs,
  Nullable,
  SectionQuoteProps,
  Theme,
} from '@boss/types/b2b-b2c';
import { TAccountPageType } from '@boss/utils';

import { SortOption } from '../../components/Algolia/Sort';
import { B2C_QUESTIONS, DESTINATIONS, ENRICHED_QUESTIONS_MAP_B2C } from '../../constants';

export type Options = {
  inspirationGridVariant: BasicVariant;
  youtubeLink: string;
  showSubscribeForm: boolean;
  headerVariant: BasicVariant;
  stepperVariant: BasicVariant;
  getSectionBgColor: (Theme?: Theme) => Nullable<string>;
  sectionQuoteProps: Partial<SectionQuoteProps>;
  sectionQuoteQuoteProps: { className?: string };
  sectionQuoteImageProps: Partial<ImageProps>;
  componentsToExclude: string[];
  storeFinderVariant: BasicVariant;
  productCardVariant: BasicVariant;
  storeCardVariant: BasicVariant;
  orderCardVariant: BasicVariant;
  articleCardVariant: BasicVariant;
  displayPWAPopup: boolean;
  storeDetailTheme: Theme;
  showProductDocuments: boolean;
  specialQuoteCarousel: boolean;
  productPanelVariant: BasicVariant;
  expandableFacets: boolean;
  accountPages: string[];
  accountNavigation: {
    mainNav: TAccountPageType[];
    navigationType: BasicVariant;
    atelierNav?: readonly TAccountPageType[];
    personalNav?: readonly TAccountPageType[];
  };
  accountPageConfig: {
    showBreadcrumb?: boolean;
    customHeader?: boolean;
    variant: BasicVariant;
    listPageTypeToShow: 'PROJECTS' | 'LIST';
    contactPageTypeToShow: 'CONTACTS' | 'CONTACT';
    wishlistType: 'sku' | 'product';
  };
  accountDashboardVariant: BasicVariant;
  accountShowAppointments: boolean;
  productLabelVisibilityConfig: {
    [key: string]: 'hide' | 'show';
  };
  isB2b: boolean;
  promotionCardVariant: 'primary' | 'secondary';
  pageOptions?: {
    [key: string]: {
      [key: string]: boolean;
    };
  };
  productSortOptions: SortOption[];
  paintSystemTarget: 'PAR' | 'VAK';
  categoryPageConfig: {
    showPaintGuideLink?: boolean;
  };
  ordersVariant: BasicVariant;
  basketPageConfig: {
    pageBg: 'primary' | 'secondary';
    sideLayout: 'primary' | 'secondary';
    articlesWrapperBackground: 'none' | 'filled';
    productImages: boolean;
    needsLogin: boolean;
  };
  homeDeliverySlugs: LocalizedSlugs;
  priceConfig: {
    visible: 'auth' | boolean;
  };
  stockConfig: {
    visible: 'auth' | boolean;
  };
  colorsConfig: {
    visible: 'auth' | boolean;
  };
  unitConfig: {
    visible: boolean;
  };
  paintguideQuestionAttributes: Record<string, string>;
  paintguideEnrichedQuestionMap: Record<string, string>;
  accountContactInformationType: IContactInformationFields['internalName'] | null;
  checkoutConfig: {
    showDeliveryDateStep: boolean;
    showOverviewStep?: boolean;
    deliveryVariant: BasicVariant;
    informationVariant?: 'b2b' | 'b2c';
    form: {
      hideFields: string[];
    };
    optionOrder: string[];
    showPaymentMethods?: boolean;
  };
  crossSellingModalConfig: { variant: 'primary' | 'secondary' };
  seoConfig: { titleSuffixContent: string };
  eventConfig: {
    variant: 'primary' | 'secondary';
    resultsPerPage: number;
    hasFavoriteSection: boolean;
    showUpcomingEvents: boolean;
    eventPageSlugs: LocalizedSlugs;
    formType: 'workshop' | 'education';
  };
  loginPageConfig: {
    enabled: boolean;
  };
  registerPageConfig: {
    enabled: boolean;
  };
  toasterConfig: ComponentProps<typeof Toaster>;
  productPageConfig: {
    needsLoginForBasket: boolean;
  };
  productListConfig: {
    needsRatingRendered: boolean;
  };
  storeConfig: {
    sortPriorityStoreType?: IStore['storetype'];
    excludeNamePrefix?: string;
    lowerCasePrefix?: string;
    filterTypesOut?: IStore['storetype'][];
    shopsOnly?: boolean;
    orderDepotFirst?: boolean;
    fetchOnlyType?: IStore['storetype'];
  };
  contactInfoConfig: { formVariant: 'primary' | 'secondary' | 'tertiary'; enableMap: boolean };
  loginSectionConfig: {
    variant: 'primary' | 'secondary';
  };
  homePageConfig: {
    showLoginSection: boolean;
    redirect?: {
      destination: (typeof DESTINATIONS)[number];
      auth: boolean;
    };
  };
  surfaceCalculationConfig: {
    showTechnique: boolean;
  };
  overviewPageTypes: string[];
};

const defaultOptions: Options = {
  inspirationGridVariant: 'primary',
  youtubeLink: 'https://www.youtube.com/@colorapaint',
  showSubscribeForm: true,
  headerVariant: 'primary',
  stepperVariant: 'primary',
  getSectionBgColor: () => null,
  sectionQuoteProps: { variant: 'primary' },
  sectionQuoteImageProps: {},
  sectionQuoteQuoteProps: {},
  componentsToExclude: [] as string[],
  storeFinderVariant: 'primary',
  storeCardVariant: 'primary',
  productCardVariant: 'secondary',
  articleCardVariant: 'primary',
  orderCardVariant: 'primary',
  displayPWAPopup: false,
  storeDetailTheme: 'pink',
  showProductDocuments: false,
  productPanelVariant: 'primary',
  specialQuoteCarousel: false,
  expandableFacets: false,
  accountPages: [] as string[],
  accountNavigation: {
    mainNav: ['DASHBOARD'],
    navigationType: 'primary',
  },
  accountPageConfig: {
    showBreadcrumb: true,
    customHeader: false,
    variant: 'primary',
    listPageTypeToShow: 'LIST',
    contactPageTypeToShow: 'CONTACTS',
    wishlistType: 'sku',
  },
  accountShowAppointments: false,
  accountDashboardVariant: 'primary',
  productLabelVisibilityConfig: {},
  isB2b: true,
  productSortOptions: [
    {
      labelKey: 'relevance',
    },
    {
      labelKey: 'alphabetical',
      indexSuffix: 'name_asc',
    },
    {
      labelKey: 'priceAscending',
      indexSuffix: 'price_asc',
    },
    {
      labelKey: 'priceDescending',
      indexSuffix: 'price_desc',
    },
  ],
  promotionCardVariant: 'primary',
  paintSystemTarget: 'PAR',
  categoryPageConfig: {},
  ordersVariant: 'primary',
  basketPageConfig: {
    pageBg: 'primary',
    sideLayout: 'primary',
    articlesWrapperBackground: 'filled',
    productImages: true,
    needsLogin: false,
  },
  homeDeliverySlugs: homeDeliverySlugsB2C,
  priceConfig: {
    visible: true,
  },
  stockConfig: {
    visible: true,
  },
  colorsConfig: {
    visible: true,
  },
  checkoutConfig: {
    showDeliveryDateStep: false,
    showOverviewStep: true,
    deliveryVariant: 'primary',
    informationVariant: 'b2c',
    form: {
      hideFields: ['companyname'],
    },
    optionOrder: ['delivery', 'pickup'],
    showPaymentMethods: false,
  },
  paintguideQuestionAttributes: B2C_QUESTIONS,
  paintguideEnrichedQuestionMap: ENRICHED_QUESTIONS_MAP_B2C,
  crossSellingModalConfig: { variant: 'primary' },
  accountContactInformationType: null,
  seoConfig: { titleSuffixContent: 'bosspaints.be' },
  eventConfig: {
    variant: 'primary',
    resultsPerPage: 6,
    hasFavoriteSection: true,
    showUpcomingEvents: false,
    eventPageSlugs: eventPageSlugsB2C,
    formType: 'workshop',
  },
  loginPageConfig: {
    enabled: true,
  },
  registerPageConfig: {
    enabled: true,
  },
  toasterConfig: {
    richColors: true,
    toastOptions: {
      // Since tailwind is not yet available, we need to set the styling individually
      style: { padding: '1rem', fontSize: '1rem' },
    },
  },
  productPageConfig: {
    needsLoginForBasket: false,
  },
  productListConfig: {
    needsRatingRendered: false,
  },
  storeConfig: {},
  loginSectionConfig: {
    variant: 'primary',
  },
  homePageConfig: {
    showLoginSection: false,
  },
  unitConfig: {
    visible: false,
  },
  contactInfoConfig: { formVariant: 'primary', enableMap: false },
  surfaceCalculationConfig: {
    showTechnique: true,
  },
  overviewPageTypes: [],
};

export const mergeOptions = (channelOptions: Partial<Options>): Options => ({ ...defaultOptions, ...channelOptions });
