import { faCheck, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import ColorFlyout from './ColorFlyout';
import { ColorPickerInstanceProps } from '..';
import { PaintDrop, Presence, RadioGroup, Triangle } from '../../';

const ProductOptionButtonStyling = cva('px-4 py-3 text-gray-dark rounded block', {
  variants: {
    active: {
      true: 'border-blue border-1 bg-white',
      false: 'text-gray-dark bg-gray-light',
    },
  },
});

type CurrentColor = {
  name: string;
  hex: string;
  value: string;
};

const SecondaryColorPicker = ({
  className,
  translations,
  selectedColor,
  productOptions,
  colorOptions,
  activeProductOption,
  onProductOptionClick,
  onChangeGroupClick,
  recentColors,
  favoriteColors,
  searchValue,
  onSearch,
  setSearchValue,
  onColorClick,
  searchColors,
  searchLoading,
  hasSearched,
  colorType,
}: ColorPickerInstanceProps) => {
  const [flyoutIsOpen, setFlyoutIsOpen] = useState(false);
  const [currentColor, setCurrentColor] = useState<CurrentColor | null>(null);

  useEffect(() => {
    if (
      colorOptions?.length &&
      (!selectedColor || !colorOptions.some(color => color.colorcode === selectedColor.code))
    ) {
      const { colorname, rgb, colorcode, colorid } = colorOptions[0];
      const color = {
        name: colorname,
        rgb: rgb,
        code: colorcode,
        id: colorid?.toString(),
        hex: rgb,
        value: colorcode,
      };

      setCurrentColor(color);
      onColorClick(color);
      setFlyoutIsOpen(false);
    } else if (selectedColor) {
      const { name, rgb, code } = selectedColor;

      setCurrentColor({
        name: name ?? '',
        hex: rgb,
        value: code,
      });
    } else {
      setCurrentColor(null);
    }
  }, [colorOptions, selectedColor, onColorClick]);

  useEffect(() => {
    setFlyoutIsOpen(false);
  }, [selectedColor]);

  const handleRadioOnChange = (value: string) => {
    const color = colorOptions?.find(color => color.colorcode === value);

    if (color) {
      const currentColor = {
        name: color.colorname,
        rgb: color.rgb,
        code: color.colorcode,
        id: color.colorid?.toString(),
        hex: color.rgb,
        value: color.colorcode,
      };

      setCurrentColor(currentColor);
      onColorClick(currentColor);
    }
  };

  return (
    <div className={twMerge('flex flex-col gap-5', className)}>
      {!!productOptions?.length && onProductOptionClick && (
        <div className="xs:flex-row flex flex-col justify-between gap-5 md:items-center">
          <span className="h4 font-bold">{translations.productOption}</span>
          <div className="xs:flex-row xs:items-center flex flex-col gap-3">
            {productOptions.map(option => (
              <button
                className={ProductOptionButtonStyling({ active: option.key === activeProductOption?.key })}
                key={option.key}
                onClick={() => onProductOptionClick(option)}
              >
                {option.value}
              </button>
            ))}
          </div>
        </div>
      )}
      <div className="grid grid-cols-3 items-center justify-between gap-5 lg:grid lg:grid-cols-2">
        <span className="h4 col-span-1 font-bold">{translations.chosenColor}:</span>
        <div className="col-span-2 grid grid-cols-2 items-center gap-5 lg:col-span-1">
          <div className="relative col-span-1 flex justify-center">
            <PaintDrop
              className="min-w-18 min-h-18 max-w-[100px]"
              colorCode={currentColor?.hex}
              colorType={colorType}
            />
            <FontAwesomeIcon
              className="text-gray-dark absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
              icon={faCheck}
            />
            <Presence id="flyout-triangle-presence" visible={flyoutIsOpen && !colorOptions?.length}>
              <Triangle
                className="border-b-blue-light absolute -bottom-6 left-1/2 -translate-x-1/2"
                height={16}
                width={30}
              />
            </Presence>
          </div>
          <div className="col-span-1 flex flex-col gap-1">
            {currentColor?.name ? `${currentColor.value} - ${currentColor.name}` : currentColor?.value}
            {!colorOptions?.length && (
              <button
                className="small text-blue mt-2 flex items-center gap-1 whitespace-nowrap underline"
                onClick={() => setFlyoutIsOpen(val => !val)}
              >
                {translations.changeColor}
                <FontAwesomeIcon icon={faPencil} />
              </button>
            )}
          </div>
        </div>
      </div>
      {!!colorOptions?.length && currentColor && (
        <RadioGroup
          name="colorgroup"
          onChange={handleRadioOnChange}
          options={colorOptions.map(color => ({ value: color.colorcode, label: color.colorname }))}
          value={currentColor.value}
        />
      )}
      <Presence
        animate={{ opacity: 1, height: 'auto' }}
        exit={{ opacity: 0, height: 0, marginTop: '-1.25rem' }}
        id="color-flyout-presence"
        initial={{ opacity: 0, height: 0 }}
        visible={flyoutIsOpen && !colorOptions?.length}
      >
        <ColorFlyout
          className="bg-blue-light"
          favoriteColors={favoriteColors}
          hasSearched={hasSearched}
          onChangeColorClick={onColorClick}
          onChangeGroupClick={onChangeGroupClick}
          onSearch={onSearch}
          recentColors={recentColors}
          searchColors={searchColors}
          searchLoading={searchLoading}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          translations={translations.flyout}
        />
      </Presence>
    </div>
  );
};

export default SecondaryColorPicker;
