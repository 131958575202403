import { IArticle } from '@boss/types/b2b-b2c';
import { excludeUndefinedFromArray } from '@boss/utils';

import { useArticleMap } from '..';
import { useArticlesByIds, useProductsById } from '../../client-queries';

export const useArticles = (ids: string[]) => {
  const { mapArticlesWithProductInfo } = useArticleMap();
  const { data: initialArticles, isSuccess: articlesLoaded, isLoading } = useArticlesByIds(ids);
  const articles = excludeUndefinedFromArray<IArticle>(initialArticles || []);
  const uniqueProductIds = [
    ...new Set(
      articles
        .reduce((prev: string[], article) => (article.productId ? [...prev, article.productId] : prev), [])
        .flat(),
    ),
  ];
  const { data: products, isLoading: productsLoading } = useProductsById(uniqueProductIds, articlesLoaded);

  return {
    articles: articlesLoaded && articles && products?.length ? mapArticlesWithProductInfo(articles, products) : [],
    articlesLoaded,
    articlesLoading: isLoading || productsLoading,
  };
};
