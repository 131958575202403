import { env as commonEnv } from '../../utils';

/**
 * Crash the runtime or build when a required variable is missing
 */
const crash = (name: string) => {
  // temp comment out
  // throw new Error(`The value of 'process.env.${name}' is required but none was present in the runtime.`);
  return '';
};

const SCOPE_DEFAULT_BASE = 'openid profile offline_access';

/**
 * Get the scope or default based on the chosen flow
 */
const getScope = () => {
  const clientId = process.env.NEXT_PUBLIC_AUTH_CLIENT_ID;

  return process.env.NEXT_PUBLIC_AUTH_SCOPE || `${SCOPE_DEFAULT_BASE} ${clientId}`;
};

// The environment store
export const env = {
  ...commonEnv,
  SIGNIN_WELL_KNOWN_URI:
    process.env.NEXT_PUBLIC_AUTH_SIGNIN_WELL_KNOWN_URI || crash('NEXT_PUBLIC_AUTH_SIGNIN_WELL_KNOWN_URI'),
  CLIENT_ID: process.env.NEXT_PUBLIC_AUTH_CLIENT_ID || crash('NEXT_PUBLIC_AUTH_CLIENT_ID'),
  SCOPE: getScope(),
  ACCESS_TOKEN_COOKIE_NAME: process.env.AUTH_ACCESS_TOKEN_COOKIE_NAME || 'at',
};

export default env;
