/*
 * Build breadcrumbs for category pages
 *
 * Example:
 * [verf, lakken]
 *
 * Becomes:
 * [
 *   { href: '/c/verf', label: 'Verf' }
 *   { href: '/c/verf/lakken', label: 'Lakken' }
 */
export const buildCategoryBreadcrumb = (slugs: string[], basePath = '/c') =>
  slugs?.map((slug, index) => {
    let href = basePath;

    for (let i = 0; i < index + 1; i++) {
      href += `/${slugs[i]}`;
    }

    return {
      href,
      label: slug.replaceAll('-', ' '),
    };
  });
