import React, { createContext, useContext, useMemo, useState } from 'react';

import { SearchResults as TSearchResults } from '@boss/ui';

type Props = {
  children: React.ReactNode;
};

interface SearchMenuResultsState {
  searchResults: TSearchResults;
  updateSearchSuggestions: (results: TSearchResults) => void;
  updateProductResults: (results: TSearchResults) => void;
  updateStepByStepResults: (results: TSearchResults) => void;
  reset: () => void;
}

export const SearchMenuResultsContext = createContext<SearchMenuResultsState>({
  searchResults: [],
  updateProductResults: () => undefined,
  updateSearchSuggestions: () => undefined,
  updateStepByStepResults: () => undefined,
  reset: () => undefined,
});

export const SearchMenuResultsProvider: React.FC<Props> = ({ children }) => {
  const [searchSuggestions, setSearchSuggestions] = useState<TSearchResults>([]);
  const [stepByStepResults, setStepByStepResults] = useState<TSearchResults>([]);
  const [productResults, setProductResults] = useState<TSearchResults>([]);

  const reset = () => {
    setSearchSuggestions([]);
    setStepByStepResults([]);
    setProductResults([]);
  };

  const providerValue = useMemo(
    () => ({
      searchResults: [...searchSuggestions, ...productResults, ...stepByStepResults],
      updateSearchSuggestions: setSearchSuggestions,
      updateProductResults: setProductResults,
      updateStepByStepResults: setStepByStepResults,
      reset,
    }),
    [searchSuggestions, productResults, stepByStepResults],
  );

  return <SearchMenuResultsContext.Provider value={providerValue}>{children}</SearchMenuResultsContext.Provider>;
};

export const useSearchMenuResults = (): SearchMenuResultsState => {
  const context = useContext(SearchMenuResultsContext);

  if (!context) {
    throw new Error('useSearchResults must be used within a SearchMenuResultsProvider');
  }
  return context;
};
