import { useTranslation } from 'next-i18next';

import { LoginSection } from '../../components';

const LoginPage = () => {
  const { t } = useTranslation('login');

  return (
    <div className="flex flex-col">
      <h1 className="mt-4 md:mt-12">{t('title')}</h1>
      <LoginSection />
    </div>
  );
};

export default LoginPage;
