import { Address } from '..';

export interface AddressProps {
  address?: Address;
}

const AddressLine = ({ address }: AddressProps) => {
  if (!address) {
    return null;
  }
  return (
    <>
      <p>
        {address.street} {address.number}
      </p>
      <p>
        {address.postalCode} {address.city}
      </p>
      <p>{address.country}</p>
    </>
  );
};

export default AddressLine;
