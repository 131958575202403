import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

import { IColorGroup } from '@boss/services';
import { ISearchProduct } from '@boss/types/b2b-b2c';
import { Button, ColorPicker } from '@boss/ui';

import { ColorPickerModal } from '../../components';
import { useProductColor } from '../../hooks';

type Props = {
  preSelectedColor?: string | undefined;
  colorGroups: IColorGroup[];
  product: ISearchProduct;
  onNextClick: (chosenColor: string) => void;
};

const PaintguideColorPicker = ({ preSelectedColor, colorGroups, product, onNextClick }: Props) => {
  const productId = product.id;
  const { t } = useTranslation('paintguide');

  const {
    colorType,
    handleColorGroupClick,
    onColorClick,
    searchValue,
    selectedColor,
    setSearchValue,
    searchedValue,
    setSelectedColorGroup,
    handleProductPanelSearch,
    showColorModal,
    selectedColorGroup,
    setShowColorModal,
    setSelectedColor,
    showColors,
  } = useProductColor({ initialColor: preSelectedColor, isPaint: true, product, showBaseColors: false });

  return (
    <>
      <ColorPicker
        colorType={colorType}
        colors={colorGroups}
        onChangeGroupClick={handleColorGroupClick}
        onColorClick={onColorClick}
        onSearch={handleProductPanelSearch}
        searchValue={searchValue}
        selectedColor={selectedColor}
        setSearchValue={setSearchValue}
        translations={t('productPanel.colorPicker', { ns: 'product', returnObjects: true })}
        variant="fullwidth"
      />
      {selectedColor && (
        <Button
          className="ml-auto mt-20"
          icon={faArrowRight}
          iconPosition="right"
          label={t('next')}
          onClick={() => onNextClick(selectedColor.id)}
          type="primary"
        />
      )}
      {showColors && showColorModal && (
        <ColorPickerModal
          colorGroups={colorGroups}
          colorType={colorType}
          initialColorGroup={selectedColorGroup}
          initialSearchValue={searchedValue}
          onClose={() => setShowColorModal(false)}
          productId={productId}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          setSelectedColorGroup={setSelectedColorGroup}
        />
      )}
    </>
  );
};

export default PaintguideColorPicker;
