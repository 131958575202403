import { paintsystems } from '../../types';
import bossApi from '../../utils/fetch-api';
import { mapLocale } from '../../utils/mappers';

const DEFAULT_OPTIONS = { m2m: true, authScope: process.env.BOSS_API_AUTH_SCOPE_PAINTSYSTEMS };

/**
 * Fetch all paint systems
 */

export const getPaintSystems = async ({ locale, target }: { locale: string; target: string }) => {
  const mappedLocale = mapLocale(locale);

  return await bossApi<paintsystems.Paintsystem[]>(
    `/productapplicationsystem/v2/paintsystems?target=${target}&language=${mappedLocale}`,
    {
      ...DEFAULT_OPTIONS,
      locale,
    },
  );
};
