import type { Component, CtaFields } from '@boss/types/b2b-b2c';
import { PromotionBanner } from '@boss/ui';

import { SectionMapperProps } from '..';
import { getButtonProps, getPromotionCtaProps } from '../../';

const PromotionBannerMapper = ({ entry, locale, theme, className }: SectionMapperProps) => {
  const getPromotionCta = (item: Component, variant: 'primary' | 'secondary') => {
    if (item?.__typename === 'promotionCta') {
      return {
        ...getPromotionCtaProps(item, theme, locale),
        variant,
      };
    }

    return {
      className: 'bg-green',
      text: 'Wrong content type',
      link: {
        href: '/',
      },
      variant,
    };
  };

  const promotionCta = getPromotionCta(entry.content?.[0], 'primary');
  const secondaryPromotionCta = getPromotionCta(entry.content?.[1], 'secondary');

  const links = entry.content
    .filter((item): item is CtaFields => item?.__typename === 'cta')
    .map(item => getButtonProps(item, theme, locale));

  return (
    <PromotionBanner
      className={className}
      links={links}
      promotionCta={promotionCta}
      secondaryPromotionCta={secondaryPromotionCta}
    />
  );
};

export default PromotionBannerMapper;
