import { removeLeadingZeros } from '@boss/utils';

/**
 * Normalizes a phonenumber to the international variant e.g. 0032 to +32
 **/
export const normalizePhonenumber = (phonenumber: string) => {
  if (!phonenumber) {
    return '';
  }

  const number = removeLeadingZeros(phonenumber);

  return number.startsWith('+') ? number : `+${number}`;
};

/**
 * Normalizes a phonenumber from the international variant to the 00 notation e.g. +32 to 0032
 **/
export const revertPhonenumberNotation = (phonenumber: string) => {
  if (!phonenumber?.startsWith('+')) {
    return phonenumber;
  }

  return phonenumber.replace('+', '00');
};
