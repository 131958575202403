import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export interface Props {
  label: string;
  subLabel: string;
  icon?: IconDefinition;
  className?: string;
  value?: string;
  children?: ReactNode;
}

const DiscountDropdown = ({ label, subLabel, icon, className, value, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [value]);

  return (
    <div className="relative">
      <button
        className={twMerge('rounded-brand flex w-full justify-between bg-white px-6 py-2', className)}
        onClick={() => setIsOpen(!isOpen)}
      >
        {value ? (
          <div className="flex">{value}</div>
        ) : (
          <div className="flex">
            <p className="small text-blue font-bold">{label}</p>
            {subLabel && <p className="small ml-3 text-gray-500">{subLabel}</p>}
          </div>
        )}
        {icon && <FontAwesomeIcon className="ml-3 mt-1" icon={icon} size="sm" />}
      </button>
      {isOpen && (
        <div
          className="rounded-brand absolute left-0 right-0 top-[100%] flex flex-col bg-white  shadow-lg"
          id="discount-dropdown"
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default DiscountDropdown;
