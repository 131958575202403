import debounce from 'lodash.debounce';
import { useEffect, useRef } from 'react';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';

interface Props {
  query: string;
  indexName: string;
}

/**
 * VirtualSearchBox component for Algolia's React InstantSearch.
 * It provides a way to perform search queries without rendering an actual input field.
 */
const VirtualSearchBox = ({ query, indexName }: Props) => {
  const { uiState } = useInstantSearch();
  const { refine } = useSearchBox();

  // useRef is used to persist the debounced refine function across renders
  const debouncedRefine = useRef(debounce(refine, 500)).current;

  useEffect(() => {
    const currentUiQuery = uiState[indexName]?.query;

    // Trigger the refine function when the query changes and is different from the current UI query
    if (query && currentUiQuery !== query) {
      debouncedRefine(query);
    }

    // Cleanup function to cancel the debounce when the component unmounts
    return () => {
      debouncedRefine.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, uiState, indexName]); // Dependencies for re-running the effect

  return null; // As this is a virtual component, it does not render anything
};

export default VirtualSearchBox;
