export * from './queries';
import { Sku } from './queries';

export const stockKeys = {
  stockByWarehouseIds: ({ skus, warehouseIds, locale }: { locale: string; warehouseIds: string[]; skus: Sku[] }) => [
    'stockByWarehouseIds',
    locale,
    warehouseIds,
    skus,
  ],
};
