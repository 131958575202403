import { FormikErrors, FormikValues } from 'formik';
export type FormRefType = {
  errors: Promise<FormikErrors<FormikValues>>;
  values: FormikValues;
};

export enum BossFormType {
  SearchPainter = 'search-painter',
  GeneralQuestion = 'general-question',
  TechnicalAdvice = 'technical-advice',
  ColorAdvice = 'color-advice',
  SamplesService = 'samples-service',
  PostPurchaseService = 'post-purchase-service',
  SiteAssistance = 'site-assistance',
  CustomDeco = 'custom-deco',
  ColorSupportLab = 'color-support-lab',
  MachineRental = 'machine-rental',
  MachineRepair = 'machine-repair',
  Return = 'return',
  SpecialOrder = 'special-order',
}
