import { addBusinessDays } from 'date-fns';

/**
 * Gets x amount of business days after the given start date
 *
 * @param {Date} startDate - The date of which the function needs to start getting business days from
 * @param {number} amountOfDays - The amount of business days the function needs to return.
 * @returns {Date[]} Array of business day dates
 */
export const getXAmountOfBusinessDays = (startDate: Date, amountOfDays: number) => {
  const businessDays: Date[] = [];
  let index = 1;

  while (businessDays.length < amountOfDays) {
    businessDays.push(addBusinessDays(startDate, index));
    index += 1;
  }

  return businessDays;
};
