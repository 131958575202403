import { useRouter } from '@boss/hooks';
import { formatPrice } from '@boss/utils';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

// Used in price, no relevance to this file
type ProductUnit = '1L';

export type PriceProps = {
  className?: string;
  discountPercentage?: number;
  quantity?: ProductUnit;
  strikePrice?: number;
  value?: number;
  privatePrice?: number;
  translations?: {
    quantityPrefix?: string;
    quantitySufix?: string;
    strikePrice?: string;
  };
  prefix?: boolean;
  variant?: 'base' | 'strike' | 'info';
  size?: 'medium' | 'large' | 'small' | 'xSmall';
  font?: 'alt' | 'raleway' | 'sans';
  valueClassName?: string;
};

export const PriceValueStyles = cva('pricing', {
  variants: {
    priceVariant: {
      base: 'text-price-base',
      strike: 'text-price-strike',
      info: 'text-price-info',
    },
    size: {
      xSmall: 'body',
      small: 'text-lg',
      medium: 'pricing',
      large: 'pricingLarge',
    },
    font: {
      alt: 'font-alt',
      sans: 'font-sand',
      raleway: 'font-raleway',
    },
  },
});

const Price = ({
  className,
  quantity,
  strikePrice,
  value,
  size = 'medium',
  prefix,
  variant,
  privatePrice,
  translations,
  font = 'alt',
  valueClassName,
}: PriceProps) => {
  const { locale } = useRouter();
  const captionStyling = 'caption text-gray-dark';

  const getPriceStylingVariant = () => {
    if (variant) {
      return variant;
    }
    if (strikePrice) {
      return 'strike';
    }
    return 'base';
  };

  return (
    <div className={twMerge('flex flex-col', className)}>
      {prefix && translations?.quantityPrefix && (
        <span className={twMerge(captionStyling, 'italic')}>{translations.quantityPrefix}</span>
      )}
      {quantity && translations?.quantityPrefix && (
        <span className={captionStyling}>{translations.quantityPrefix}</span>
      )}
      {value !== undefined && (
        <span
          className={twMerge(PriceValueStyles({ priceVariant: getPriceStylingVariant(), size, font }), valueClassName)}
        >
          {formatPrice(value, locale)}
        </span>
      )}
      {quantity && (
        <span className={captionStyling}>
          {translations?.quantitySufix} {quantity}
        </span>
      )}
      {!!strikePrice && (
        <span className="caption">
          {translations?.strikePrice} <s>{formatPrice(strikePrice, locale)}</s>
        </span>
      )}
      {!!privatePrice && <span className="caption text-blue italic">{formatPrice(privatePrice, locale)}</span>}
    </div>
  );
};

export default Price;
