import type { Router, StateMapping } from 'instantsearch.js';
import singletonRouter from 'next/router';
import qs from 'qs';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';

import { getAlgolia } from '@boss/algolia-client';
import { KEYWORD_SUGGESTION_INDEX_SUFFIX } from '@boss/constants/b2b-b2c';

import { mapNextToContentfulLocale } from '../localeMapper';

type Refinement = { [key: string]: string[] };

type RouteState = {
  q?: string;
  currentPage?: number;
  refinementList?: Refinement;
};

type RouteKeys = keyof RouteState;

type UiState = {
  [key: string]: {
    query?: string;
    page?: number;
    refinementList?: Refinement;
  };
};

type InstantSearchRouterProps<TUiState extends UiState = UiState, TRouteState = TUiState> = {
  router?: Router<TRouteState>;
  stateMapping?: StateMapping<TUiState, TRouteState>;
};

export const getColorSearchIndexName = (locale: string) => {
  const { getSearchIndexName } = getAlgolia({});
  const indexBase = process.env.NEXT_PUBLIC_ALGOLIA_COLOR_INDEX;

  return indexBase ? getSearchIndexName(indexBase, mapNextToContentfulLocale(locale)) : '';
};

export const getProductSearchIndexName = (locale: string) => {
  const { getSearchIndexName } = getAlgolia({});
  const indexBase = process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEX;

  return indexBase ? getSearchIndexName(indexBase, mapNextToContentfulLocale(locale)) : '';
};

export const getStepByStepSearchIndexName = (locale: string) => {
  const indexBase = process.env.NEXT_PUBLIC_ALGOLIA_STEP_BY_STEP_INDEX;

  return indexBase ? `${indexBase}_${mapNextToContentfulLocale(locale)}` : '';
};

export const getPaintguideSearchIndexName = (locale: string) => {
  const indexBase = process.env.NEXT_PUBLIC_ALGOLIA_PAINTGUIDE_INDEX;

  return indexBase ? `${indexBase}_${mapNextToContentfulLocale(locale)}` : '';
};

export const getInspirationImagesSearchIndexName = (locale: string) => {
  const indexBase = process.env.NEXT_PUBLIC_ALGOLIA_IMAGES_INDEX;

  return indexBase ? `${indexBase}_${mapNextToContentfulLocale(locale)}` : '';
};

export const getContenPagesIndexName = (locale: string) => {
  const indexBase = process.env.NEXT_PUBLIC_ALGOLIA_CONTENT_INDEX;

  return indexBase ? `${indexBase}_${mapNextToContentfulLocale(locale)}` : '';
};

export const getProductKeyWordSuggestionIndexName = (locale: string) => {
  const productIndex = getProductSearchIndexName(locale);

  return productIndex ? `${productIndex}_${KEYWORD_SUGGESTION_INDEX_SUFFIX}` : '';
};

const stateToRoute = (uiState: UiState, indexName: string): RouteState => {
  const indexUiState = uiState[indexName] || {};

  return {
    q: indexUiState.query,
    currentPage: indexUiState.page,
    refinementList: indexUiState.refinementList,
  };
};

const routeToState = (routeState: RouteState, indexName: string): UiState => {
  return {
    [indexName]: {
      query: routeState.q,
      page: routeState.currentPage,
      refinementList: routeState.refinementList,
    },
  };
};

const getLocation = (): Location => window.location;

const createURL = (qsModule: typeof qs, routeState: RouteState, location: Location) => {
  const queryParameters: { [key: string]: unknown } = {};
  const defaultParams: RouteKeys[] = ['q', 'currentPage'];

  defaultParams.forEach(param => {
    if (routeState[param]) {
      queryParameters[param] = routeState[param];
    }
  });

  if (routeState.refinementList && Object.keys(routeState.refinementList).length > 0) {
    Object.keys(routeState.refinementList).forEach(refinement => {
      const currentRefinement = routeState.refinementList?.[refinement];

      queryParameters[refinement] = Array.isArray(currentRefinement) ? currentRefinement : null;
    });
  }

  const queryString = qsModule.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  });

  return `${location.protocol}//${location.host}${location.pathname}${queryString}`;
};

const getDynamicRefinements = (refinements: Refinement) => {
  const dynamicRefinements: { [key: string]: string[] } = {};

  Object.entries(refinements).forEach(([key, dynamicRefinement]) => {
    dynamicRefinements[key] = Array.isArray(dynamicRefinement) ? dynamicRefinement : [dynamicRefinement];
  });

  return dynamicRefinements;
};

export const parseURL = (qsModule: typeof qs, location: Location) => {
  const { q: initQuery = '', pageSize, currentPage = 1, ...refinements } = qsModule.parse(location.search.slice(1));

  const q = (Array.isArray(initQuery) ? initQuery.join(' ') : initQuery)?.toString();

  return {
    q: decodeURIComponent(q),
    pageSize,
    currentPage: Number(currentPage),
    refinementList: getDynamicRefinements(refinements as Refinement),
  };
};

export const getRouting = (indexName: string): InstantSearchRouterProps<UiState, RouteState> => ({
  router: createInstantSearchRouterNext({
    singletonRouter,
    routerOptions: {
      cleanUrlOnDispose: false,
      writeDelay: 400,
      getLocation: () => getLocation(),
      createURL: ({ qsModule, routeState, location }) => createURL(qsModule, routeState, location),
      parseURL: ({ qsModule, location }) => parseURL(qsModule, location),
    },
  }),
  stateMapping: {
    stateToRoute: uiState => stateToRoute(uiState, indexName),
    routeToState: routeState => routeToState(routeState, indexName),
  },
});
