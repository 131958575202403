import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const definition: IconDefinition = {
  prefix: 'fas', // Needs something predefined. Take fas. Has no value
  iconName: 'percent', // Needs something predefined. Take closest value
  icon: [
    15, // Viewport width
    14, // Viewport height
    [], // Aliasses (no relevance for this approach) dummy value
    '2b', // unicode char (no relevance for this approach) dummy value
    // SVG Path or an array of SVG Paths
    'M3.65083 6.7C5.48683 6.7 6.72883 5.242 6.72883 3.478V3.442C6.72883 1.678 5.50483 0.256 3.68683 0.256C1.85083 0.256 0.608828 1.696 0.608828 3.478V3.514C0.608828 5.278 1.83283 6.7 3.65083 6.7ZM2.62483 12.262C2.62483 12.73 3.00283 13.108 3.47083 13.108C3.75883 13.108 4.04683 12.964 4.20883 12.73L12.2548 1.714C12.3808 1.552 12.4528 1.372 12.4528 1.138C12.4528 0.669999 12.0748 0.291999 11.6068 0.291999C11.3188 0.291999 11.0308 0.436 10.8688 0.669999L2.82283 11.686C2.71483 11.848 2.62483 12.028 2.62483 12.262ZM11.3908 13.144C13.2268 13.144 14.4688 11.686 14.4688 9.922V9.886C14.4688 8.122 13.2448 6.7 11.4268 6.7C9.59083 6.7 8.34883 8.14 8.34883 9.922V9.958C8.34883 11.722 9.57283 13.144 11.3908 13.144ZM3.68683 5.062C2.96683 5.062 2.49883 4.324 2.49883 3.478V3.442C2.49883 2.614 2.89483 1.894 3.65083 1.894C4.37083 1.894 4.83883 2.632 4.83883 3.478V3.514C4.83883 4.324 4.44283 5.062 3.68683 5.062ZM11.4268 11.506C10.7068 11.506 10.2388 10.768 10.2388 9.922V9.886C10.2388 9.058 10.6348 8.338 11.3908 8.338C12.1108 8.338 12.5788 9.076 12.5788 9.922V9.958C12.5788 10.768 12.1828 11.506 11.4268 11.506Z',
  ],
};

export default definition;
