import * as Sentry from '@sentry/nextjs';

import { getAlgolia } from '@boss/algolia-client';
import { product as productClient } from '@boss/services/client';
import { ISearchProduct } from '@boss/types/b2b-b2c';

import { getProductSearchIndexName } from '../../utils';
import { mapNextToContentfulLocale } from '../../utils/localeMapper';

const fetchProductsById = async (locale: string, ids: string[]): Promise<ISearchProduct[] | undefined> => {
  try {
    const data = await fetch(
      `/api/product/byProductIds?locale=${mapNextToContentfulLocale(locale)}&ids=` + ids.join(','),
    );

    return await data.json();
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch products by id',
      },
    });
  }
};

const fetchSearchProductsById = async (locale: string, ids: string[]): Promise<ISearchProduct[] | undefined> => {
  try {
    const { searchIndex } = getAlgolia({ indexName: getProductSearchIndexName(locale) });

    if (!searchIndex) {
      return;
    }

    const { results } = await searchIndex.getObjects<ISearchProduct>(ids);

    return results.filter((v): v is ISearchProduct => !!v); // Exclude the possible [null] value when the given ids arn't found
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch search products by id',
      },
    });
  }
};

const fetchProductById = async (locale: string, id: string) => {
  try {
    const { searchIndex } = getAlgolia({ indexName: getProductSearchIndexName(locale) });

    if (!searchIndex) {
      return;
    }
    return await searchIndex.getObject<ISearchProduct>(id);
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch product by id',
      },
    });
  }
};

const fetchAccountPriceInfoByProductId = async (productId: string, locale: string, isLoggedIn: boolean) => {
  try {
    return await productClient.getAccountPriceInfoByProductId({ productId, locale, isLoggedIn });
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch account price info by product id',
      },
    });
  }
};

export { fetchSearchProductsById, fetchProductById, fetchProductsById, fetchAccountPriceInfoByProductId };
