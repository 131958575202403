import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { InstantSearch } from 'react-instantsearch';

import { getAlgolia } from '@boss/algolia-client';
import { useRouter } from '@boss/hooks';
import { IWishlist, IWishlists, TWishlistType } from '@boss/services/client';
import { getAccountPageSlugs } from '@boss/utils';

import CreateArticleList from './createList/articles';
import CreateProductList from './createList/products';
import ArticleListDetail from './listDetail/articles';
import ColorListDetail from './listDetail/colors';
import ProductListDetail from './listDetail/products';
import Overview from './overview';
import { useWishlists } from '../../../client-queries';
import { accountPageConfig, getProductSearchIndexName } from '../../../utils';

/**
 * Enum representing the available pages.
 * @enum {string}
 */
enum Page {
  OVERVIEW = 'overview',
  CREATELIST = 'createList',
  LIST_DETAIL = 'listDetail',
}

type Props = {
  setSubBreadcrumb: (value: string) => void;
};

/**
 * The Lists container renders different pages based on the active page.
 * @component
 */
const Lists = ({ setSubBreadcrumb }: Props) => {
  const { t } = useTranslation('account', { keyPrefix: 'list' });
  const { listPageTypeToShow, wishlistType } = accountPageConfig;
  const { data: wishlists, isLoading: wishlistsLoading } = useWishlists();
  const [wishlistTypeToShow, setWishlistTypeToShow] = useState<TWishlistType>(wishlistType);
  const [readonly, setReadonly] = useState(false);

  /**
   * State to track the active page.
   * @type {[Page, function(Page): void]}
   */
  const { locale, push, query } = useRouter();
  const [activePage, setActivePage] = useState<Page>(Page.OVERVIEW);
  const [activeWishlist, setActiveWishlist] = useState<IWishlist>();
  const { asPath } = useRouter();

  const pagePathParts = asPath.split('/');
  const subPage = pagePathParts.length >= 4 ? pagePathParts[3]?.split('?')[0] : undefined;
  const listId = pagePathParts.length >= 5 ? pagePathParts[4] : undefined;
  const type = query?.type?.toString() ?? '';

  useEffect(() => {
    if (subPage === 'create') {
      setActivePage(Page.CREATELIST);
      setSubBreadcrumb('create');
    } else if (subPage === 'view' && listId) {
      setActivePage(Page.LIST_DETAIL);
      if (activeWishlist && activeWishlist.description) {
        setSubBreadcrumb(activeWishlist.description);
      }
    } else {
      setActivePage(Page.OVERVIEW);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId, subPage, activeWishlist]);

  const DEFAULT_DESCRIPTIONS = {
    frequentlyorderedsku: t('overview.actions.frequentlyOrdered'),
    favoriteproduct: t('overview.actions.favoriteProducts'),
    favoritecolor: t('overview.actions.favoriteColors'),
  };

  const getListFromWishlists = (
    wishlists: IWishlists | undefined,
    type: 'frequentlyorderedsku' | 'favoriteproduct' | 'favoritecolor',
    defaultType: 'sku' | 'product' | 'color',
  ) => ({
    ...wishlists?.[type],
    type: type || defaultType,
    lines: wishlists?.[type]?.lines ?? [],
    description: DEFAULT_DESCRIPTIONS[type],
  });

  useEffect(() => {
    if (activePage === Page.LIST_DETAIL && listId) {
      const readonly = type === 'frequentlyorderedsku' || type === 'favoriteproduct' || type === 'favoritecolor';
      let list;
      let wishlistTypeToShow: 'sku' | 'product' | 'color' = wishlistType;

      if (readonly) {
        wishlistTypeToShow = type === 'frequentlyorderedsku' ? 'sku' : type === 'favoriteproduct' ? 'product' : 'color';
        list = getListFromWishlists(wishlists, type, wishlistTypeToShow);
      } else {
        list = wishlists?.[wishlistType]?.find(({ id }) => id === listId);
      }

      setWishlistTypeToShow(wishlistTypeToShow);
      setReadonly(readonly);

      if (list) {
        setActiveWishlist(list);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, wishlists, wishlistType, listId, type]);

  /**
   * Object mapping page values to their corresponding components.
   * @type {Object.<string, JSX.Element>}
   */
  const pageComponents = {
    [Page.OVERVIEW]: <Overview loading={wishlistsLoading} wishlists={wishlists} />,
    [Page.CREATELIST]:
      wishlistTypeToShow === 'sku' ? (
        <CreateArticleList
          onListCreated={() => {
            push(getAccountPageSlugs(listPageTypeToShow)[locale]);
          }}
        />
      ) : (
        <CreateProductList
          onListCreated={() => {
            push(getAccountPageSlugs(listPageTypeToShow)[locale]);
          }}
        />
      ),
    [Page.LIST_DETAIL]:
      activeWishlist &&
      (wishlistTypeToShow === 'sku' ? (
        <ArticleListDetail list={activeWishlist} readonly={readonly} />
      ) : wishlistTypeToShow === 'product' ? (
        <ProductListDetail list={activeWishlist} readonly={readonly} />
      ) : (
        <ColorListDetail list={activeWishlist} />
      )),
  };

  /**
   * Render the Lists component.
   * @returns {JSX.Element}
   */
  return (
    <div key={activePage}>
      <InstantSearch indexName={getProductSearchIndexName(locale)} searchClient={getAlgolia({}).algoliaClient}>
        {pageComponents[activePage]}
      </InstantSearch>
    </div>
  );
};

export default Lists;
