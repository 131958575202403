import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
// eslint-disable-next-line import/named
import { v4 as uuid } from 'uuid';
import * as Yup from 'yup';

import { getAlgolia } from '@boss/algolia-client';
import { ISearchArticle } from '@boss/types/b2b-b2c';
import { Alert, Button, InputField } from '@boss/ui';
import { getAccountPageSlugs, isDefined } from '@boss/utils';

import { useAccountPriceInfo, useArticleDiscountInfo, useCreateWishlist } from '../../../../client-queries';
import { InstantSearch, PagedArticles } from '../../../../components';
import { ExtentedArticle } from '../../../../components/PagedArticles';
import { accountPageConfig, getProductSearchIndexName, isB2b } from '../../../../utils';
import ArticleSearch from '../search/articles';

type UpdateArticleData =
  | { type: 'selectedQuantity'; value: number }
  | { type: 'selectedPackaging'; value: string }
  | {
      type: 'selectedColor';
      value: {
        code: string;
        name: string;
        rgb: string;
      };
    };

type Props = {
  onListCreated: () => void;
};

const CreateArticleList = ({ onListCreated: handleListCreated }: Props) => {
  const { listPageTypeToShow, wishlistType } = accountPageConfig;
  const [selectedResults, setSelectedResults] = useState<ExtentedArticle[]>([]);
  const { locale } = useRouter();
  const { mutate: createWishlist, isError: createWishListError } = useCreateWishlist({});
  const { t } = useTranslation('account', { keyPrefix: 'list.createNewList' });

  const accountPriceInfoData = useAccountPriceInfo(
    selectedResults.map(article => {
      return {
        skuId: article.id,
        quantity: article.selectedQuantity ?? 1,
        unit: article.selectedPackaging ?? 'stk',
      };
    }),
  );

  const accountPriceInfo = accountPriceInfoData.map(({ data }) => data?.price).filter(isDefined);
  const accountPriceInfoLoading = accountPriceInfoData.some(({ isLoading }) => isLoading);

  const { data: articleDiscountInfo } = useArticleDiscountInfo(
    selectedResults?.map(result => {
      return {
        skuId: result.id ?? '',
        quantity: result.selectedQuantity ?? 0,
        unit: result.selectedPackaging ?? result.unit ?? '',
      };
    }) ?? [],
  );

  const createList = () => {
    createWishlist(
      {
        description: formik.values.listName,
        type: wishlistType,
        lines: [
          ...selectedResults.map(article => ({
            typeid: article.id,
            quantity: article.selectedQuantity || 1,
            packaging: article.selectedPackaging || article.unit,
            color: article.selectedColor
              ? {
                  colorcode: article.selectedColor.code,
                  colorname: article.selectedColor.name,
                  rgb: article.selectedColor.rgb,
                }
              : undefined,
          })),
        ],
      },
      {
        onSuccess: () => {
          handleListCreated();
        },
      },
    );
  };

  const formik = useFormik({
    initialValues: {
      listName: '',
    },
    validationSchema: Yup.object({
      listName: Yup.string().required(t('nameRequired') || ''),
    }),
    onSubmit: createList,
  });

  const addArticlesToList = (articles: ISearchArticle[]) => {
    setSelectedResults([
      ...selectedResults,
      ...articles
        .filter(article => !selectedResults.find(selectedArticle => selectedArticle.id === article.id))
        .map(article => ({
          ...article,
          isPaint: article?.product?.type !== 'NON_PAINT',
          lineId: uuid(),
        })),
    ]);
  };

  const removeResult = (lineId: string) => {
    setSelectedResults(selectedResults.filter(selectedArticle => selectedArticle.lineId !== lineId));
  };

  const updateArticle = (lineId: string, data: UpdateArticleData) => {
    const resultLineIndex = selectedResults.findIndex(result => result.lineId === lineId);
    const updatedResults = [...selectedResults];

    updatedResults[resultLineIndex] = {
      ...updatedResults[resultLineIndex],
      [data.type]: data.value,
    };

    setSelectedResults(updatedResults);
  };

  return (
    <div className="mb-10 flex flex-col gap-5">
      <h1>{t('title')}</h1>
      {createWishListError && (
        <Alert className="mt-2" type="error">
          {t('createError')}
        </Alert>
      )}
      {isB2b && (
        <div className="md:w-1/2">
          <InputField
            error={formik.errors.listName}
            label={t('nameField.label') || ''}
            name="listName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder={t('nameField.placeholder') || ''}
            testId="input-search-product"
            value={formik.values.listName}
            variant="light"
          />
        </div>
      )}
      <PagedArticles
        accountPriceInfo={accountPriceInfo}
        articles={selectedResults}
        discountConditions={articleDiscountInfo}
        editableArticles
        isUpdating={accountPriceInfoLoading}
        onRemove={removeResult}
        onUpdateColor={(lineId, value) =>
          updateArticle(lineId, {
            type: 'selectedColor',
            value,
          })
        }
        onUpdatePackaging={(lineId, value) => updateArticle(lineId, { type: 'selectedPackaging', value })}
        onUpdateQuantity={(lineId, value) => updateArticle(lineId, { type: 'selectedQuantity', value })}
      />

      <InstantSearch
        disableRouting
        indexName={getProductSearchIndexName(locale as string)}
        searchClient={getAlgolia({}).algoliaClient}
      >
        <ArticleSearch addArticlesToList={articles => addArticlesToList(articles as ISearchArticle[])} />
      </InstantSearch>

      <div className="flex justify-end">
        <Button
          className="mr-4 px-10 py-4"
          href={getAccountPageSlugs(listPageTypeToShow)[locale as string]}
          label={t('cancel')}
          testId="cancel"
          type="secondary"
        />
        <Button
          className="px-10 py-4"
          label={t('saveList')}
          onClick={() => formik.handleSubmit()}
          testId="save-list"
          type="primary"
        />
      </div>
    </div>
  );
};

export default CreateArticleList;
