// import { Nullable } from '@boss/types/b2b-b2c';

// Define the structure of a token
// type Token = {
//   bearerToken: string;
//   tokenExpiration: Nullable<number>;
//   tokenRetrievalInProgress: boolean;
// };

// Define the structure of the token response from the API
type TokenResponse = {
  access_token: string;
  expires_in: number;
  // Add other relevant fields if needed
};

// Define the structure of API client credentials
export type APIClientCredentials = {
  client_id: string;
  client_secret: string;
};

// Create a dictionary to store bearer tokens by scope
// const bearerTokens: Record<string, Token> = Object.create(null);

// // Create an array to store token resolver functions
// const tokenResolvers: Array<(token: string) => void> = [];

/**
 * Requests and retrieves a bearer token for the specified scope.
 * @param {string} scope - The scope for which to request a token.
 * @param {APIClientCredentials} credentials - The API client credentials.
 * @returns {Promise<string>} A promise that resolves to the bearer token.
 */
const requestToken = async (scope: string, credentials: APIClientCredentials): Promise<string> => {
  // TEMP CODE: TEST TOKEN RETRIEVAL

  // const cacheKey = `${scope}-${credentials.client_id}`;
  // // Destructure token properties from the dictionary or provide defaults
  // const { bearerToken, tokenExpiration, tokenRetrievalInProgress } = bearerTokens[cacheKey] ?? {};

  // // If a valid token exists and is not expired, return it immediately
  // if (bearerToken && tokenExpiration && tokenExpiration > Date.now() + 10000) {
  //   return bearerToken;
  // }

  // // If token retrieval is already in progress, wait for it to complete
  // if (tokenRetrievalInProgress) {
  //   // If a token retrieval process is already in progress, return a promise that resolves when the token is available
  //   // TODO: Just returning the centrally kept token is enough. A
  //   // promise can be waited on by multiple execution flows
  //   return new Promise<string>(resolve => {
  //     tokenResolvers.push(resolve);
  //   });
  // }

  // // Mark token retrieval as in progress to prevent concurrent requests
  // bearerTokens[cacheKey] = {
  //   bearerToken: '',
  //   tokenExpiration: null,
  //   tokenRetrievalInProgress: true,
  // };

  // Get the token endpoint URL from environment variables
  const tokenEndpoint = process.env.BOSS_API_TOKEN_ENDPOINT as string;

  // Create the request parameters with client credentials
  const params = new URLSearchParams({
    client_id: credentials.client_id,
    client_secret: credentials.client_secret,
    grant_type: 'client_credentials',
    scope,
  });

  // // Send a POST request to the token endpoint
  const response = await fetch(tokenEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: params.toString(),
  });

  // // Parse the response data
  const responseData: TokenResponse = await response.json();

  return responseData.access_token;

  // // Update the token information in the dictionary
  // bearerTokens[cacheKey].bearerToken = responseData.access_token || '';
  // bearerTokens[cacheKey].tokenExpiration = responseData.expires_in ? Date.now() + responseData.expires_in * 1000 : null;

  // // Resolve all pending token resolver promises
  // while (tokenResolvers.length > 0) {
  //   const resolve = tokenResolvers.shift();

  //   if (resolve) {
  //     resolve(bearerTokens[cacheKey].bearerToken);
  //   }
  // }

  // // Mark token retrieval as complete
  // bearerTokens[cacheKey].tokenRetrievalInProgress = false;

  // // Return the retrieved bearer token
  // return bearerTokens[cacheKey].bearerToken;
};

export default requestToken;
