import { product } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false, authScope: process.env.BOSS_API_AUTH_SCOPE_PRODUCT };

/**
 * Fetches account price info by product id
 */

export const getAccountPriceInfoByProductId = ({
  productId,
  locale,
  isLoggedIn,
}: {
  productId: string;
  locale: string;
  isLoggedIn?: boolean;
}) => {
  // If logged in we use the /user prefix so that the user token is used during the proxy request
  // If not logged in we use the /product prefix so that the request is proxied with the client token
  const basePath = isLoggedIn ? '/user/product/v2' : '/product/v2';

  return bossApi<product.AccountPriceInfo>(`${basePath}/products/${productId}/priceinfo`, {
    ...DEFAULT_OPTIONS,
    m2m: false,
    locale,
  });
};
