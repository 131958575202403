import { useQueries, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';

import { useRouter } from '@boss/hooks';
import { IArticle } from '@boss/types/b2b-b2c';

import {
  fetchAccountPriceInfoBySkuId,
  fetchArticleDiscountInfo,
  fetchArticlesByIds,
  fetchArticlesByProductAndColor,
} from './connector';
import { COLOR_TYPE } from '../../constants/product';

import { articleKeys } from '.';

const useArticleById = (id: string, colorCode?: string) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  return useQuery<IArticle[] | undefined>({
    queryKey: articleKeys.articleByIds(locale, [id]),
    queryFn: async () => await fetchArticlesByIds(locale, [id], colorCode),
    meta: {
      errorMessage: t('toast.articleById.error.title'),
    },
  });
};

const useArticlesByIds = (ids: string[], enabled = true) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  return useQuery<IArticle[] | undefined>({
    queryKey: articleKeys.articleByIds(locale, ids),
    queryFn: async () => await fetchArticlesByIds(locale, ids),
    enabled,
    meta: {
      errorMessage: t('toast.articlesById.error.title'),
    },
  });
};

const useArticlesByProductAndColor = (
  {
    colorId,
    productId,
    colorType,
    colorCode,
    isPaint,
  }: {
    colorId: string;
    productId: string;
    colorType: string;
    colorCode: string;
    isPaint: boolean;
  },
  enabled = true,
) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  const isEnabled = () => {
    if (!enabled) {
      return false;
    }

    if (!productId) {
      return false;
    }

    if (isPaint) {
      if (colorType === COLOR_TYPE.MYMX) {
        return true;
      }
      return !!colorType && !!colorCode;
    }

    return true;
  };

  return useQuery<IArticle[] | undefined>({
    queryKey: articleKeys.articleByProductId(locale, productId, colorId ?? colorCode, colorType),
    queryFn: async () =>
      await fetchArticlesByProductAndColor({ locale, colorId, productId, colorType, colorCode, isPaint }),
    meta: {
      errorMessage: t('toast.articleByProductId.error.title'),
    },
    enabled: isEnabled(),
  });
};

const useAccountPriceInfo = (skuObjects: { skuId: string; quantity: number; unit: string }[]) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  const queries = skuObjects.map(({ skuId, quantity, unit }) => {
    return {
      queryKey: articleKeys.accountPriceInfo({
        locale,
        skuId,
        unit,
        quantity,
      }),
      queryFn: async () => await fetchAccountPriceInfoBySkuId({ skuId, quantity, unit, locale }),
      enabled: !!skuId,
      meta: {
        errorMessage: t('toast.accountPriceInfo.error.title'),
      },
    };
  });

  return useQueries({
    queries,
  });
};

const useArticleDiscountInfo = (
  skus: {
    skuId: string;
    quantity: number;
    unit: string;
  }[],
  enabled = true,
) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  const queries = skus.map(({ skuId, quantity, unit }) => {
    return {
      queryKey: articleKeys.articleDiscountInfo(locale, unit, quantity ?? 1, skuId),
      queryFn: async () => await fetchArticleDiscountInfo({ skuId, quantity, unit, locale }),
      enabled,
      meta: {
        errorMessage: t('toast.articleDiscountInfo.error.title'),
      },
    };
  });

  const articleDiscountInfo = useQueries({
    queries,
  });

  const articleDiscountInfoData = Object.values(articleDiscountInfo).reduce((acc, discountInfo) => {
    const { skuId, info } = discountInfo?.data ?? {};

    if (skuId && info?.length) {
      acc[skuId] = info;
    }

    return acc;
  }, {} as Record<string, string[]>);

  const isLoading = Object.values(articleDiscountInfo).some(({ isLoading }) => isLoading);

  const lastDataRef = useRef(articleDiscountInfoData);

  // Update the ref only when we are not loading anymore
  if (!isLoading) {
    lastDataRef.current = articleDiscountInfoData;
  }

  // Return the data from the ref while loading, otherwise return the latest data
  return { data: isLoading ? lastDataRef.current : articleDiscountInfoData, isLoading };
};

export { useArticleById, useArticlesByIds, useArticlesByProductAndColor, useAccountPriceInfo, useArticleDiscountInfo };
