import { useState } from 'react';
import { useHits, useInstantSearch, useSearchBox } from 'react-instantsearch';

import { ISearchProduct } from '@boss/types/b2b-b2c';

export const useSearchProducts = () => {
  const { status } = useInstantSearch();
  const [showResults, setShowResults] = useState(true);
  const { query, refine } = useSearchBox();
  const [inputValue, setInputValue] = useState(query);
  const { hits } = useHits<ISearchProduct>();

  const handleSearch = () => {
    if (inputValue) {
      refine(inputValue);
      setShowResults(true);
    }
  };

  const loading = ['stalled', 'loading'].includes(status);

  return { query: inputValue, inputValue, setInputValue, handleSearch, loading, showResults, results: hits };
};

export default useSearchProducts;
