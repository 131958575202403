import type { StockStatus as Status } from '@boss/types/b2b-b2c';

import { useStockByWarehouseIds } from '../../client-queries';
import { STOCK_STATUS } from '../../constants';
import { useProfile } from '../../hooks';
import { stockConfig } from '../../utils';

export interface Sku {
  id: string;
  quantity: number;
  unit: string;
  skuId: string;
}

export type Stock = Record<string, Status>;

interface Props {
  warehouseIds: string[];
  skus: Sku[];
  disableStockFetch?: boolean;
}

interface UseStock {
  stock: Stock;
  unavailableProducts: Record<string, string[]>;
  isLoading: boolean;
  showStock: boolean;
}

/**
 * Determines the appropriate status based on the current status and a provided status.
 *
 * @param {Status} status - The desired status to evaluate.
 * @param {Status} currentStatus - The current status to compare against.
 * @returns {Status} - The resulting status, which is either the provided status or the current status, based on certain conditions.
 */
const getStatus = (status: Status, currentStatus: Status): Status => {
  // Check if the provided status should take precedence over the current status based on specific conditions.
  if (
    status === 'NOT_AVAILABLE' ||
    (status === 'LOW_STOCK' && currentStatus !== 'NOT_AVAILABLE') ||
    (status === 'AVAILABLE' && currentStatus !== 'NOT_AVAILABLE' && currentStatus !== 'LOW_STOCK')
  ) {
    return status;
  }
  // If none of the conditions are met, return the current status.
  return currentStatus;
};

const getStockVisibility = (isLoggedIn: boolean) => {
  if (stockConfig.visible === 'auth') {
    return isLoggedIn;
  }

  return stockConfig.visible;
};

export function useStock({ warehouseIds, skus, disableStockFetch }: Props): UseStock {
  const { isLoggedIn } = useProfile();
  const showStock = getStockVisibility(isLoggedIn);

  const filteredWarehouseIds = warehouseIds.filter(Boolean);

  const { data: results, isLoading } = useStockByWarehouseIds(
    {
      warehouseIds: filteredWarehouseIds as string[],
      skus: skus.map(sku => ({
        skuid: sku.skuId,
        quantity: sku.quantity,
        unit: sku.unit,
      })),
    },
    !!showStock && !!filteredWarehouseIds?.length && !!skus?.length && !disableStockFetch,
  );

  const getStock = (): Stock => {
    return (
      results?.reduce((stockResults: Stock, sku) => {
        const status = STOCK_STATUS[sku.availability] ?? 'Unknown';
        const warehouse = sku.warehouse;
        const currentStatus = stockResults[warehouse];

        stockResults[warehouse] = getStatus(status, currentStatus);

        return stockResults;
      }, {}) ?? {}
    );
  };

  const getUnavailableProductsPerWarehouse = (): Record<string, string[]> => {
    return (
      results?.reduce((stockResults: Record<string, string[]>, sku) => {
        const status = STOCK_STATUS[sku.availability] ?? 'Unknown';
        const warehouse = sku.warehouse;
        const currentStatus = stockResults[warehouse] ?? [];

        if (status === 'NOT_AVAILABLE') {
          stockResults[warehouse] = [...currentStatus, sku.id];
        }

        return stockResults;
      }, {}) ?? {}
    );
  };

  return { stock: getStock(), unavailableProducts: getUnavailableProductsPerWarehouse(), isLoading, showStock };
}

export default useStock;
