import * as bossClient from '@boss/boss-client';
import { IOrder } from '@boss/types/b2b-b2c';

import { mapOrder } from './mapper';
import { PAYMENT_TYPE_UNION } from '../client';

export interface OrderParams {
  basketId: string;
  paymentType: PAYMENT_TYPE_UNION;
  payReference: string | undefined;
  timeStamp: string;
}

export const getHistoricOrders = async (): Promise<IOrder[] | undefined> => await bossClient.order.getHistoricOrders();
export const getOrdersByDate = async (): Promise<IOrder[] | undefined> => await bossClient.order.getOrdersByDate();
export const getOrderById = async (orderId: string, locale: string): Promise<IOrder | null> => {
  const order = await bossClient.orderClient.getOrderById(orderId, locale);

  return order ? mapOrder(order) : null;
};

export const createOrder = async (
  { basketId, paymentType, payReference, timeStamp }: OrderParams,
  m2m = false,
): Promise<IOrder | null> => {
  let order;

  const params = {
    basketid: basketId,
    paymenttype: paymentType.toString(),
    payreference: payReference ?? '',
    paytimestamp: timeStamp,
  };

  if (m2m) {
    order = await bossClient.order.createOrder(params);
  } else {
    order = await bossClient.orderClient.createOrder(params);
  }

  return order ? mapOrder(order) : null;
};
