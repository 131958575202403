export const trackEvents = {
  PAGE_VIEWED: 'page_view',
  LINK_CLICKED: 'link_click',
  SEARCH_RESULT_SELECTED: 'search_result_selected',
  FORM_SUBMITTED: 'form_submitted',
  PRODUCT_DISPLAYED: 'product_displayed',
  ADD_TO_CART: 'add_to_cart',
  BEGIN_CHECKOUT: 'begin_checkout',
  WIZARD_INTERACTED: 'wizard_interacted',
  KEYWORD_SEARCHED: 'keyword_searched',
};
