import { TAccountDiscountInfoType } from '@boss/services/client';

type DiscountInfo = {
  [key: string]: {
    code: TAccountDiscountInfoType;
    rgb: string;
  };
};

export const DISCOUNT_TYPES: DiscountInfo = {
  DEFAULT: {
    code: '1',
    rgb: '#BC6A58',
  },
  SPECIFIC: {
    code: '2',
    rgb: '#324996',
  },
  WORKSITE: {
    code: '3',
    rgb: '#399960',
  },
};
