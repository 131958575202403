import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { useClearRefinements, useCurrentRefinements, useHits } from 'react-instantsearch';
import { twMerge } from 'tailwind-merge';

import { Button, Divider } from '@boss/ui';

import FilterDrawerAccordion from '../FilterDrawerAccordion';
import { SortOption } from '../Sort';
import SortButtons from '../SortButtons';

type Props = {
  className?: string;
  attributes: string[];
  onClose: () => void;
  actionType?: 'refine' | 'navigate' | 'expand';
  indexName: string;
  sortOptions: SortOption[];
};

const FilterDrawer = ({ className, attributes, onClose, actionType, indexName, sortOptions }: Props) => {
  const { t } = useTranslation();
  const { results } = useHits();
  const { refine: removeFilters } = useClearRefinements();
  const { items: refinementGroups } = useCurrentRefinements();

  const activeRefinements = refinementGroups.map(refinementGroup => refinementGroup.refinements)?.flat();
  const amountOfActiveRefinements = activeRefinements?.length ?? 0;
  const totalHits = results?.nbHits;

  return (
    <div className={twMerge('flex h-screen w-screen flex-col overflow-y-auto bg-white', className)}>
      <div className="flex items-center justify-between px-4 py-6">
        <button className="caption flex items-center gap-1.5 text-black" onClick={onClose}>
          <FontAwesomeIcon icon={faChevronLeft} />
          {t('filters')} {amountOfActiveRefinements > 0 && `(${amountOfActiveRefinements})`}
        </button>
        {!!amountOfActiveRefinements && (
          <button className="caption text-black" onClick={removeFilters}>
            {t('removeAllFilters')}
          </button>
        )}
      </div>

      <Divider />

      <div className="flex flex-col gap-4 px-4 pb-6">
        <div className="border-b-1 border-b-gray flex flex-col gap-1.5 py-6">
          <span className="small font-bold text-black">{t('sortBy')}</span>
          <SortButtons className="-mr-4 flex-nowrap overflow-x-auto pr-4" indexName={indexName} options={sortOptions} />
        </div>

        {!!attributes?.length && (
          <div className={'flex flex-col gap-4'}>
            {attributes.map(attribute => (
              <FilterDrawerAccordion actionType={actionType} attribute={attribute} key={`${attribute}-accordion`} />
            ))}
          </div>
        )}

        <Button
          className="mt-6 w-full"
          label={t('showXResults', { amount: totalHits }) ?? undefined}
          onClick={onClose}
          type="primary"
        />
      </div>
    </div>
  );
};

export default FilterDrawer;
