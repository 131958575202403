import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { IProductCategory } from '@boss/services';
import { Nullable, OverviewFields } from '@boss/types/b2b-b2c';
import { IconCard, Link, Presence, Section, icons } from '@boss/ui';

import { CategoryPillsSection, ComponentMapper, RecentlyViewedProducts } from '../../components';
import { mapNextToContentfulLocale } from '../../utils/localeMapper';

type Props = {
  categories: IProductCategory[];
  popularCategories: IProductCategory[];
  cmsData: Nullable<OverviewFields>;
};

const CategoryOverviewPage = ({ categories, popularCategories, cmsData }: Props) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const overviewCategories = categories?.filter(category => category.settings?.includes('available on overview'));

  return (
    <Presence className="flex flex-col" id="category-overviewpage-content" visible>
      <h1
        className={twMerge('pt-6 md:p-0 md:pt-12', cmsData?.prefixSections?.length ? '' : 'pb-6 md:pb-6')}
        data-testid="category-overview-page-title"
      >
        {t('products')}
      </h1>
      {cmsData?.prefixSections?.map(entry => (
        <ComponentMapper entry={entry} key={entry.id} locale={locale} />
      ))}

      {(!!popularCategories?.length || !!overviewCategories?.length) && (
        <Section
          className="pb-7.5 flex flex-col gap-12 pt-0 md:gap-20 md:pb-20"
          content={
            <>
              {!!overviewCategories?.length && (
                <div
                  className="grid grid-cols-3 gap-2 sm:grid-cols-4 md:grid-cols-5 md:gap-6 lg:grid-cols-6"
                  data-testid="category-overview-page-grid"
                >
                  {overviewCategories.map(category => (
                    <Link
                      className="w-full"
                      href={category.uris[mapNextToContentfulLocale(locale)]}
                      key={category.name}
                    >
                      <IconCard
                        icon={(category.icon && icons.getPreloadedIcon(category.icon)) || undefined}
                        title={category.description}
                      />
                    </Link>
                  ))}
                </div>
              )}

              {!!popularCategories?.length && (
                <CategoryPillsSection categories={popularCategories} className="p-0" title={t('popularCategories')} />
              )}
            </>
          }
          testId="category-overview-page-categories-section"
        />
      )}

      <RecentlyViewedProducts backgroundColor="bg-blue-light/30 before:bg-blue-light/30 after:bg-blue-light/30" />

      {cmsData?.suffixSections?.map(entry => (
        <ComponentMapper entry={entry} key={entry.id} locale={locale} />
      ))}
    </Presence>
  );
};

export default CategoryOverviewPage;
