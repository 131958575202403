import { faSquareList, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IWishlist } from '@boss/services/client';
import { Button, InputField, RadioGroup, SearchDropdown } from '@boss/ui';

type Props = {
  children: JSX.Element;
  inStock: boolean;
  onRemoveProduct?: () => void;
  wishlists?: IWishlist[];
  name?: string;
  selectedStore?: string;
  onAddToWishlist?: (wishlistId: string) => void;
  onCreateWishlist?: (description: string) => void;
};

const StockProductCardWrapper = ({
  children,
  inStock,
  onRemoveProduct: handleRemoveProduct,
  wishlists,
  name,
  selectedStore,
  onAddToWishlist: handleAddToWishlist,
  onCreateWishlist: handleCreateWishlist,
}: Props) => {
  const { t } = useTranslation(['product']);
  const [selectedWishlist, setSelectedWishlist] = useState<string | null>(null);
  const [showWishlistDropdown, setShowWishlistDropdown] = useState(false);
  const [wishlistType, setWishlistType] = useState<'existing' | 'new'>('existing');
  const [wishlistName, setWishlistName] = useState<string>('');

  const addToWishlist = () => {
    setShowWishlistDropdown(false);
    if (handleAddToWishlist) {
      handleAddToWishlist(selectedWishlist ?? '');
    }
  };

  const createWishlist = () => {
    setShowWishlistDropdown(false);
    if (handleCreateWishlist) {
      handleCreateWishlist(wishlistName);
    }
  };

  return inStock ? (
    <>{children}</>
  ) : (
    <div className="border-1 border-red my-1 flex flex-col gap-1 px-2">
      {children}
      <div className="text-red">
        {selectedStore ? t('stockModal.storeOutOfStock', { storeName: selectedStore }) : t('stockModal.outOfStock')}
      </div>
      {handleRemoveProduct && !showWishlistDropdown && (
        <Button
          className="text-red hover:text-red-dark"
          icon={faTrashCan}
          iconPosition="left"
          label={t('stockModal.removeProduct')}
          onClick={handleRemoveProduct}
          type="link"
        />
      )}
      {!showWishlistDropdown && handleAddToWishlist && (
        <Button
          className="text-blue"
          icon={faSquareList}
          iconPosition="left"
          label={t('stockModal.addToWishlist')}
          onClick={() => setShowWishlistDropdown(true)}
          type="link"
        />
      )}
      {showWishlistDropdown && handleAddToWishlist && (
        <div className="flex flex-col gap-4">
          <RadioGroup
            name={`wishlistType-${name}`}
            onChange={type => setWishlistType(type as 'existing' | 'new')}
            options={[
              {
                label: (
                  <SearchDropdown
                    onChange={setSelectedWishlist}
                    options={
                      wishlists?.map(wishlist => ({
                        label: wishlist.description ?? '',
                        value: wishlist.id ?? '',
                      })) ?? []
                    }
                    searchLabel={t('stockModal.searchWishlist') ?? ''}
                    selectLabel={t('stockModal.selectWishlist') ?? ''}
                    value={selectedWishlist ?? ''}
                  />
                ),
                value: 'existing',
              },
              {
                label: (
                  <InputField
                    label={t('stockModal.createNewList')}
                    name={t('stockModal.createNewList')}
                    onChange={e => setWishlistName(e.target.value)}
                    onFocus={() => setWishlistType('new')}
                    value={wishlistName}
                  />
                ),
                value: 'new',
              },
            ]}
            value={wishlistType}
          ></RadioGroup>

          <div className="flex gap-2">
            <Button
              label={t('stockModal.add')}
              onClick={() => (wishlistType === 'existing' ? addToWishlist() : createWishlist())}
              type="primary"
            />
            <Button label={t('stockModal.cancel')} onClick={() => setShowWishlistDropdown(false)} type="secondary" />
          </div>
        </div>
      )}
    </div>
  );
};

export default StockProductCardWrapper;
