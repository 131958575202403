import env from '../env';

const STRIPPER = new RegExp(`^${env.PATH_PREFIX}/`);

/**
 * Strip Prefix from path
 */
export const strip = (pathname: string): string => pathname.replace(STRIPPER, '');

/**
 * Export the prefix value
 */
export const PATH = env.PATH_PREFIX;
