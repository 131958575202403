/**
 * @param activeUsage - intensity of usage
 * @param totalSurface - total surface to be painted in m2
 *
 * Calculates total volume of paint for a given surface
 */
export const calcAmountOfpaintForSurface = (activeUsage: number, totalSurface: number) =>
  (1 / activeUsage) * totalSurface;

/**
 * @param amountOfPaintNeeded - liters of paint nedeed
 * @param layers - the amount of layers
 *
 * Calculates the total volume of paint for given layers, to 2 decimal points of precision
 */
export const calcTotalPaintVolume = (amountOfPaintNeeded: number, layers = 1) =>
  Number((amountOfPaintNeeded * layers).toFixed(2));
