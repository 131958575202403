import { SCREENS, inspirationPageSlugs } from '@boss/constants/b2b-b2c';
import {
  BasicVariant,
  I18nFilter,
  InspirationImageFields,
  Nullable,
  PromotionCtaFields,
  Theme,
} from '@boss/types/b2b-b2c';
import { chunkify, getPagePathByEntry } from '@boss/utils';
import { Document } from '@contentful/rich-text-types';
import * as Sentry from '@sentry/browser';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import InspirationImage from './Image';
import Filters, { type FilterType } from '../Filters';
import Cta from '../PromotionCta';
import Spinner from '../Spinner';

export type QueryObject = Nullable<{ filt: string; val: string }>;

type Props = {
  query?: QueryObject;
  infiniteImageFetcher: Nullable<(skipCounter: number, filters: string) => Promise<InspirationImageFields[]>>;
  initialContentFetcher: Nullable<
    (filters: string) => Promise<{ images: InspirationImageFields[]; cta: PromotionCtaFields; filters: FilterType[] }>
  >;
  textRenderer: (
    text: Document,
    options?: {
      noParagraph?: boolean;
      noWrapper?: boolean;
    },
  ) => JSX.Element;
  theme?: Theme;
  locale: string;
  translations: I18nFilter;
  variant?: BasicVariant;
};

type FirstRowData = {
  image: InspirationImageFields;
  cta: PromotionCtaFields;
};

const InspirationGrid = ({
  theme,
  locale,
  initialContentFetcher,
  infiniteImageFetcher,
  textRenderer,
  translations,
  query,
  variant = 'primary',
}: Props) => {
  const [filterQuery, setfilterQuery] = useState('');
  const [inspImages, setInspImages] = useState<InspirationImageFields[]>([]);

  const [inspColumns, setInspColumns] = useState<InspirationImageFields[][]>([]);
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [loading, setLoading] = useState(true);
  const { width: windowWidth } = useWindowSize();

  const [useTrigger, setUseTrigger] = useState(false);
  const [firstRow, setFirstRow] = useState<FirstRowData | null>(null);
  const numColumnsRef = useRef(0);
  const triggerRef = useRef<HTMLSpanElement | null>(null);

  const makechunks = useCallback((imgs: InspirationImageFields[]) => {
    return chunkify(imgs, imgs.length / numColumnsRef.current);
  }, []);

  //Fetch initial images/cta on mount
  const setInitialContent = useCallback(async () => {
    try {
      if (!initialContentFetcher) {
        return;
      }
      const { images, cta, filters } = await initialContentFetcher(filterQuery);

      const [firstImage, ...restImages] = images;

      setFirstRow({ image: firstImage, cta });
      setUseTrigger(!!images.length);

      const imgs = cta ? restImages : images;

      setInspImages(imgs);
      setInspColumns(makechunks(imgs));
      setFilters(filters);
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          type: 'Inspiration images',
        },
      });
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [initialContentFetcher, filterQuery, makechunks]);

  useEffect(() => {
    setInitialContent();
  }, [setInitialContent, filterQuery]);

  // Set responsive columns
  useEffect(() => {
    let columns = 1;

    if (windowWidth >= SCREENS.sm && windowWidth <= SCREENS.md) {
      columns = 2;
    } else if (windowWidth >= SCREENS.md) {
      columns = 3;
    }

    numColumnsRef.current = columns;
  }, [windowWidth]);

  useEffect(() => {
    setInspColumns(makechunks(inspImages));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [makechunks]);

  //On infinite scroll trigger fetch new images
  const getAndAppendImages = useCallback(async () => {
    try {
      if (!infiniteImageFetcher) {
        return;
      }
      setLoading(true);
      const skipCta = firstRow?.cta ? 1 : 0;
      const skip = inspImages.length - skipCta;
      const nextImages = await infiniteImageFetcher(skip, filterQuery);

      setUseTrigger(!!nextImages.length);
      const newImages = nextImages.length ? [...inspImages, ...nextImages] : inspImages;

      setInspImages(newImages);
      setInspColumns(imgColums => {
        const newColums = makechunks(nextImages);

        if (!newColums.length || imgColums.length !== newColums.length) {
          return imgColums;
        }

        //Adding the newColumns from right to left, will even out the distribution,
        //as smallest chunks are getting added to bigger chunks
        for (let i = 0; i < imgColums.length; i++) {
          const newColumnIndex = imgColums.length - 1 - i; //Reverse index

          imgColums[i].push(...newColums[newColumnIndex]);
        }

        return imgColums;
      });
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          type: 'Inspiration images grid',
        },
      });
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [infiniteImageFetcher, makechunks, inspImages, firstRow, filterQuery]);

  //Infinite load listener
  useEffect(() => {
    if (!useTrigger) {
      return;
    }
    const trigger = triggerRef.current;
    const observerOptions = { threshold: 1 };
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        getAndAppendImages();
      }
    }, observerOptions);

    if (trigger) {
      observer.observe(trigger);
    }

    return () => {
      if (trigger) {
        observer.unobserve(trigger);
      }
    };
  }, [useTrigger, triggerRef, getAndAppendImages]);

  //When a query is passed as a prop, search
  useEffect(() => {
    if (!query) {
      return;
    }
    const queryString = `${query.filt}:"${query.val}"`;

    setfilterQuery(queryString);
  }, [query]);

  const getInpirationPageSlug = (slug: string) => `${inspirationPageSlugs[locale]}/${slug}`;

  return (
    <>
      <Filters
        className="mb-6"
        filters={filters}
        onSelect={(query: string) => setfilterQuery(query)}
        preSelected={query}
        translations={translations}
        variant={variant}
      />

      {firstRow?.cta && firstRow.image && (
        <div className="mb-8 grid grid-cols-1 md:grid-cols-2 md:gap-6">
          <InspirationImage
            asset={firstRow.image.image.image}
            caption={firstRow.image.title ? textRenderer(firstRow.image.title) : null}
            className="[&_p]:mb-0"
            imageClassName="rounded-tr-none"
            slug={getInpirationPageSlug(firstRow.image.slug)}
          />
          <Cta
            className="h-85"
            image={firstRow.cta.image}
            link={{
              href: firstRow.cta?.cta?.internalLink ? getPagePathByEntry(firstRow.cta.cta.internalLink, locale) : '/',
              label: firstRow.cta.cta?.label,
            }}
            noRoundedCorners={variant === 'secondary'}
            text={firstRow.cta.text ? textRenderer(firstRow.cta.text, { noWrapper: true, noParagraph: true }) : null}
            theme={theme}
          />
        </div>
      )}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
        {inspColumns.map((images, i) => (
          <div className="md:mb-12 md:gap-6" key={i}>
            {images.map(({ slug, image, id, title }, i) => (
              <InspirationImage
                asset={image.image}
                caption={title ? textRenderer(title) : null}
                imageClassName="w-full h-full"
                key={id}
                order={i + 1}
                slug={getInpirationPageSlug(slug)}
              />
            ))}
          </div>
        ))}
      </div>
      {loading && (
        <span className="my-10 flex min-h-screen w-full items-center justify-center">
          <Spinner />
        </span>
      )}
      {useTrigger && <span className="block" ref={triggerRef}></span>}
    </>
  );
};

export default InspirationGrid;
