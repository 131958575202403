import { faChevronDown, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import camelcase from 'camelcase';
import { cva } from 'class-variance-authority';
import { useRef, useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { twMerge } from 'tailwind-merge';
import { useOnClickOutside } from 'usehooks-ts';

import { FilterType } from '.';

interface FilterProps extends FilterType {
  onChange: (value: string, filterName: string) => void;
  activeFilters: string[];
  className?: string;
  isMobile?: boolean;
}

const FilterWrapperStyles = cva('relative inline-block ', {
  variants: {
    isMobile: {
      false: 'text-center min-w-45',
      true: 'w-full',
    },
  },
});

const FilterButtonStyles = cva(
  'inline-flex w-full whitespace-nowrap items-center border-solid border-gray-light justify-between',
  {
    variants: {
      isMobile: {
        false: 'rounded-brand-xs border-1 py-3 pl-4 pr-6',
        true: 'border-b-1 text-sm pt-4 pb-3 pl-1 font-semibold',
      },
    },
  },
);

const FilterCheckWrapperStyles = cva('whitespace-nowrap flex flex-col break-normal mt-2 bg-white focus:outline-none', {
  variants: {
    isMobile: {
      false: 'absolute min-w-45 left-[0px] z-10 shadow-lg rounded-brand-xs ring-1 ring-black ring-opacity-5',
      true: 'w-full border-0',
    },
  },
});

const Filter = ({
  filterName,
  filterLabel,
  filterValues,
  onChange,
  activeFilters,
  className,
  isMobile = false,
}: FilterProps) => {
  const [open, setOpen] = useState(false);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const { getCollapseProps, getToggleProps } = useCollapse();

  const handleClickOpen = () => {
    if (isMobile) {
      return;
    }
    setOpen(!open);
  };

  useOnClickOutside(filterRef, () => setOpen(false));

  return (
    <div className={twMerge(FilterWrapperStyles({ isMobile }), className)} ref={filterRef}>
      <button
        aria-expanded="true"
        aria-haspopup="true"
        className={FilterButtonStyles({ isMobile })}
        onClick={handleClickOpen}
        type="button"
        {...(isMobile ? getToggleProps() : null)}
      >
        <span className="mr-5 block capitalize">{filterLabel}</span>
        {isMobile ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faChevronDown} />}
      </button>

      {(open || isMobile) && (
        <div
          aria-labelledby="menu-button"
          aria-orientation="vertical"
          className={FilterCheckWrapperStyles({ isMobile })}
          role="menu"
          {...(isMobile ? getCollapseProps() : null)}
        >
          <ul className="cursor-pointer space-y-1 text-sm">
            {filterValues.map(({ label, value }) => (
              <li className="cursor-pointer" key={`${filterName}${camelcase(label)}`}>
                <div className="hover:bg-gray-light rounded-brand-xs flex items-center">
                  <label
                    className="rounded-brand-xs flex w-full cursor-pointer items-center p-2"
                    htmlFor={`${filterName}${camelcase(label)}`}
                  >
                    {activeFilters && (
                      <input
                        checked={activeFilters.includes(value)}
                        className="border-gray-light rounded-brand-xs h-3 w-3 cursor-pointer"
                        id={`${filterName}${camelcase(label)}`}
                        onChange={e => onChange(e.target.value, filterName)}
                        type="checkbox"
                        value={value}
                      />
                    )}
                    <span className="ml-3 block">{label}</span>
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Filter;
