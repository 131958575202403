import { ImageProps } from '@boss/types/b2b-b2c';
import { cva } from 'class-variance-authority';
import React, { ReactNode } from 'react';

import Image from '../Image';

/**
 * Represents the properties of the CorporateValueCardProps
 * @property {String} title - The title shown in the card
 * @property {ReactNode} children - The content to be displayed inside the card
 * @property {String} color - The hex color for the header of the card. Can be nullable.
 * @property {ImageProps} icon - The icon shown in the top of the card
 * @property {String} className - ClassName overwrite for external styling adjustments.
 */
export type CorporateValueCardProps = {
  title: string;
  children: ReactNode;
  color?: string;
  icon?: ImageProps;
  className?: string;
};

/**
 * Styling of the ValueCard heading container
 * @property {boolean} hasIcon - header contains an image or not
 * @property {boolean} hasColor - header receives a color or not
 */
const HeadingStyles = cva('flex justify-center items-center py-5 px-20 text-center text-white', {
  variants: {
    hasIcon: {
      true: 'relative',
      false: 'px-5',
    },
    hasColor: {
      true: '',
      false: 'bg-gray-400',
    },
  },
});

const CorporateValueCard: React.FC<CorporateValueCardProps> = ({ title, children, color, icon, className }) => {
  const hasIcon = !!icon;
  const hasColor = !!color;

  const style = hasColor ? { backgroundColor: color } : undefined;

  return (
    <div className={className}>
      <div className={HeadingStyles({ hasIcon, hasColor })} style={style}>
        {hasIcon && (
          <div className="absolute left-5 w-20">
            <Image className="h-10 w-10" {...icon} />
          </div>
        )}
        <h2 className="w-full text-left">{title}</h2>
      </div>
      <div className="pt-8">{children}</div>
    </div>
  );
};

export default CorporateValueCard;
