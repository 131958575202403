import { BasicVariant, BossColor, SimpleColor } from '@boss/types/b2b-b2c';

import FullWidthColorpicker from './fullwidth';
import PrimaryColorPicker from './Primary';
import SecondaryColorPicker from './Secondary';
import { FlyoutTranslations } from './Secondary/ColorFlyout';

export type ColorPickerTranslations = {
  chosenColor: string;
  chooseColorFromGroup: string;
  searchByColorName: string;
  changeColor: string;
  productOption: string;
  flyout: FlyoutTranslations;
};

export type ProductOption = {
  key: string;
  value: string;
};

type ColorPickerProps = {
  activeProductOption?: ProductOption;
  className?: string;
  colors: SimpleColor[];
  onColorClick: (SimpleColor: SimpleColor) => void;
  onChangeGroupClick: (color?: SimpleColor) => void;
  onProductOptionClick?: (val: ProductOption) => void;
  onSearch: () => void;
  pageSize?: number;
  productOptions?: ProductOption[];
  searchValue?: string;
  selectedColor?: SimpleColor;
  setSearchValue: (val: string) => void;
  translations: ColorPickerTranslations;
  variant: BasicVariant | 'fullwidth';
  recentColors?: SimpleColor[];
  favoriteColors?: SimpleColor[];
  searchColors?: SimpleColor[];
  searchLoading?: boolean;
  hasSearched?: boolean;
  colorType?: 'transparant' | 'default';
  colorOptions?: BossColor[];
  onColorOptionClick?: (color: string) => void;
};

export type ColorPickerInstanceProps = Omit<ColorPickerProps, 'variant'>;

const ColorPicker = ({ variant, ...props }: ColorPickerProps) => {
  if (variant === 'primary') {
    return <PrimaryColorPicker {...props} />;
  }
  if (variant === 'secondary') {
    return <SecondaryColorPicker {...props} />;
  }
  if (variant === 'fullwidth') {
    return <FullWidthColorpicker {...props} />;
  }
  return null;
};

export default ColorPicker;
