import { types } from '@boss/boss-client';
import { Nullable } from '@boss/types/b2b-b2c';

export const toHoursAndMinutes = (totalSeconds: number | string) => {
  if (typeof totalSeconds === 'number') {
    const totalMs = totalSeconds * 1000;

    return new Date(totalMs).toISOString().slice(11, 16);
  }
  return totalSeconds;
};

export const dayHasPassed = (currentDate: Date) => {
  const date = new Date();
  const formatCurrentDate = new Date(currentDate.setHours(0, 0, 0));
  const formatToday = new Date(date.setHours(0, 0, 0));

  return formatCurrentDate < formatToday;
};

export const getMonday = (d: Date) => {
  const date = new Date(d);
  const day = date.getDay(),
    diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

  return new Date(date.setDate(diff));
};

export const getDatesForWeek = (monday: Date) => {
  const dates = [];
  const current = new Date(monday);

  for (let i = 0; i < 7; i++) {
    dates.push(new Date(current));
    current.setDate(current.getDate() + 1);
  }
  return dates;
};

export const isBetweenOpeningTimes = (
  timeFrame: Nullable<types.DayObject>,
  exceptions: Nullable<types.ExceptionObject>,
) => {
  const today = new Date();

  if (!timeFrame || timeFrame.times?.length === 0) {
    return false;
  }

  const usedTimeFrame = !exceptions ? timeFrame : exceptions;

  return usedTimeFrame.times.some(time => {
    if (time.fromSeconds && time.tillSeconds) {
      const openingHour = new Date();

      openingHour.setHours(0, 0, 0, 0);
      openingHour.setSeconds(time.fromSeconds);

      const closingHour = new Date();

      closingHour.setHours(0, 0, 0, 0);
      closingHour.setSeconds(time.tillSeconds);

      return today >= openingHour && today <= closingHour;
    }
    return false;
  });
};

export const shopIsOpen = (store: types.Store) => {
  const date = new Date();

  const monday = getMonday(date);
  const week = getDatesForWeek(monday);
  const currentShop = store;

  //check if a value returns true
  return week.some(currentDate => {
    const day = currentDate.getDay() === 0 ? 7 : currentDate.getDay();
    const timeFrame = currentShop?.openinghours?.defaults?.find(
      (currentDefault: types.DayObject) => currentDefault?.day === day.toString(),
    );

    const exceptionTimeFrame = currentShop?.openinghours?.exceptions?.find(
      (exceptionDefault: types.ExceptionObject) =>
        new Date(exceptionDefault?.date).toDateString() === date.toDateString(),
    );

    return day === date.getDay() && isBetweenOpeningTimes(timeFrame, exceptionTimeFrame);
  });
};

export const getCurrentOpeningHours = (store: types.Store) => {
  const currentDay = new Date().getDay().toString();
  const defaultOpeningHours = store?.openinghours.defaults;

  if (store?.openinghours?.exceptions.length > 0) {
    for (const day of store.openinghours.exceptions) {
      if (new Date(day.date).toDateString() === new Date().toDateString()) {
        return day.times;
      }
    }
  }

  for (const day of defaultOpeningHours) {
    if (day.day === currentDay) {
      return day.times;
    }
  }

  return null; // Opening hours for the current day not found
};

export const showSpecialMessage = (currentDate: Date, exceptions: Array<types.ExceptionObject>) => {
  return exceptions.find(
    currentException => new Date(currentException.date).toDateString() === currentDate.toDateString(),
  );
};

export const openinghoursToStructuredString = (openinghours: types.Store['openinghours']) => {
  const week = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
  let str = '';

  for (const day of openinghours.defaults) {
    const weekday = week[Number(day.day)];
    const hours = day.times.map(time => `${toHoursAndMinutes(time.from)}-${toHoursAndMinutes(time.till)}`).join(' ');

    str += `${weekday} ${hours}, `;
  }

  return str;
};
