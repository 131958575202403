import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { RichText } from '@boss/rich-text';
import { RichTextFields } from '@boss/types/b2b-b2c';

import { ComponentMapper } from '../../components';
import { stepperVariant as variant } from '../../utils';

type InfoBoxProps = {
  locale: string;
  info: RichTextFields | null;
  className?: string;
};

const InfoboxStyles = cva('col-span-1 flex w-full p-6 shadow-sm md:col-span-2 xl:col-span-3', {
  variants: {
    variant: {
      primary: 'rounded-30',
      secondary: '',
    },
  },
});

const Infobox = ({ info, locale, className }: InfoBoxProps) =>
  info ? (
    <div className={twMerge(InfoboxStyles({ variant }), className)}>
      <FontAwesomeIcon className="text-blue-dark mr-4 mt-1 block text-xs" icon={faCircleInfo} />
      <RichText
        ComponentMapper={ComponentMapper}
        content={info.content}
        mapperOptions={{ locale }}
        renderOptions={{ noParagraph: true }}
      />
    </div>
  ) : null;

export default Infobox;
