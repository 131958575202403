import { useRouter } from '@boss/hooks';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Link from '../Link';
import Popper from '../Popper';

type Props = {
  className?: string;
  localizedSlugs: Record<string, string>;
  placement?: 'top' | 'bottom';
};

const LocaleSelector = ({ className, localizedSlugs, placement = 'bottom' }: Props) => {
  const { locale, locales } = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  const getLanguage = (locale: string) => locale.split('-')[0];
  const getCountry = (locale: string) => locale.split('-')[1];

  const filteredLocales = locales.filter(
    l => getCountry(l) === getCountry(locale) && getLanguage(l) !== getLanguage(locale),
  );

  if (!locale || filteredLocales?.length < 1) {
    return null;
  }

  return (
    <Popper
      button={
        <button aria-label="locale-selector" className="inline-flex w-full items-center gap-x-2.5 whitespace-nowrap">
          <span className="small uppercase">{locale && getLanguage(locale)}</span>
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
      }
      className={twMerge(className)}
      floating={
        <div className={twMerge('z-modal bg-white shadow-lg')}>
          {filteredLocales.map(locale => (
            <Link
              aria-label={`locale-selector-option-${locale}`}
              className="inline-block px-4 py-2 text-sm uppercase text-gray-700 transition duration-100 ease-in hover:bg-gray-100 hover:text-gray-900"
              href={localizedSlugs?.[locale] || '/'}
              key={locale}
              locale={locale}
              onClick={() => setIsOpen(false)}
              role="menuitem"
            >
              {getLanguage(locale)}
            </Link>
          ))}
        </div>
      }
      isOpen={isOpen}
      placement={placement}
    />
  );
};

export default LocaleSelector;
