import React from 'react';

import Button from '../Button';

interface ConfirmationModalProps {
  isOpen: boolean;
  children: JSX.Element | string;
  onCancel: () => void;
  onConfirm: () => void;
  cancelLabel: string;
  confirmLabel: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  children,
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
}) => {
  return (
    <div className={`z-100 fixed inset-0 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
      <div>
        <div className="fixed inset-0 z-40 bg-gray-800 bg-opacity-50" onClick={onCancel} />
        <div className="relative z-50 rounded-md bg-white p-4 shadow-lg">
          <div>{children}</div>
          <div className="mt-4 flex gap-2">
            <Button label={cancelLabel} onClick={onCancel} testId="modal-cancel" type="secondary" />
            <Button label={confirmLabel} onClick={onConfirm} testId="modal-confirm" type="primary" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
