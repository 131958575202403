import * as Sentry from '@sentry/nextjs';

import { article as articleService } from '@boss/services/client';

const fetchStockByWarehouseIds = async ({
  skus,
  warehouseIds,
}: {
  skus: {
    skuid: string;
    quantity?: number;
    unit?: string;
  }[];
  warehouseIds: string[];
}) => {
  try {
    return await articleService.getArticleStockByWarehouseIds({ skus, warehouseIds });
  } catch (e) {
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch stock by warehouse ids',
      },
    });
  }
};

export { fetchStockByWarehouseIds };
