import { twMerge } from 'tailwind-merge';

import { ColorButton, Repeat, Skeleton } from '../';

type Props = {
  amount?: number;
  variant?: 'primary' | 'secondary';
  hasTitle?: boolean;
  className?: string;
  gridClassName?: string;
};

const ColorsGridSkeleton = ({ className, gridClassName, amount = 8, variant, hasTitle = true }: Props) => (
  <div className={twMerge('flex w-full flex-col gap-6 md:gap-10', className)}>
    {hasTitle && <Skeleton className="w-50 h-5" />}
    <div
      className={twMerge(
        'grid grid-cols-3 justify-items-center gap-x-4 gap-y-6 sm:grid-cols-4 md:gap-x-5 md:gap-y-8',
        gridClassName,
      )}
    >
      <Repeat amount={amount}>
        <ColorButton backgroundColor="" loading translations={{ trending: '' }} variant={variant} />
      </Repeat>
    </div>
  </div>
);

export default ColorsGridSkeleton;
