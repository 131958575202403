import { useEffect, useState } from 'react';

const SCREENS = {
  xs: '475',
  sm: '576',
  md: '786',
  lg: '1024',
  xl: '1440',
} as const;

export type Screen = keyof typeof SCREENS;

/**
 * Hook that returns wether or not the current viewport is within the given breakpoint size or not
 * DISCLAIMER: This hook should NOT be used for conditional styling as this hook ALWAYS returns false on first render, meaning this WILL result in layout shifts!
 */
export const useIsResponsive = (breakpoint: Screen = 'md') => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(`(max-width:${SCREENS[breakpoint]}px)`);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setMatches(media.matches);
    };

    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, breakpoint]);

  return matches;
};

export default useIsResponsive;
