import Cookies from 'universal-cookie';

import { useProfile } from '../../hooks';
import { isB2b } from '../../utils';

const getBasketIdFromCookie = () => {
  const cookies = new Cookies();

  return cookies.get('basketId');
};

const setBasketIdToCookie = (basketId: string) => {
  const cookies = new Cookies();

  cookies.set('basketId', basketId, { path: '/' });
};

const deleteBasketIdFromCookie = () => {
  const cookies = new Cookies();

  cookies.remove('basketId');
};

/**
 * Hook to manage the anonymous basket id in a cookie
 * @returns {Object} anonymousBasketId, setAnonymousBasketId, deleteAnonymousBasketId, getAnonymousBasketId
 * @returns {string} anonymousBasketId - The anonymous basket id
 * @returns {function} setAnonymousBasketId - Function to set the anonymous basket id
 * @returns {function} deleteAnonymousBasketId - Function to delete the anonymous basket id
 * @returns {function} getAnonymousBasketId - Function to get the anonymous basket id
 **/

export const useAnonymousBasket = () => {
  const { isLoggedIn } = useProfile();

  const anonymousBasketAllowed = !isB2b && !isLoggedIn;
  const anonymousBasketId = anonymousBasketAllowed ? getBasketIdFromCookie() : null;

  return {
    anonymousBasketId,
    setAnonymousBasketId: setBasketIdToCookie,
    deleteAnonymousBasketId: deleteBasketIdFromCookie,
    getAnonymousBasketId: getBasketIdFromCookie,
  };
};
