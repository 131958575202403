import { useTranslation } from 'next-i18next';

import { FormType, useFormField, useFormSubmit } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

export const FORM_FIELD_KEYS = ['firstname', 'lastname', 'email', 'phonenumber', 'message', 'termsandconditions'];

type FormFieldKeys = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKeys]: Partial<FormField>;
};

type Props = {
  type?: FormType;
  fieldsToShow?: FormFieldKeys[];
  fieldsOverwrite?: FieldOverwrite;
  additionalFields?: FormField[];
  className?: string;
};

const ContactForm = ({
  fieldsToShow,
  fieldsOverwrite,
  additionalFields,
  className,
  type = 'general-contact',
}: Props) => {
  const { t } = useTranslation('forms');
  const { onSubmit, isSubmitting, isSuccess, reset } = useFormSubmit();
  const { email, phonenumber, message, firstname, lastname, termsandconditions } = useFormField();

  const baseFields: FormField[] = [
    firstname,
    lastname,
    email,
    phonenumber,
    { ...message, colStyle: 'md:col-span-full' },
    { ...termsandconditions, colStyle: 'ml-0 col-span-full' },
  ];

  const handleSubmit = (vals: FormValues) => onSubmit(type, vals);

  return (
    <div className="flex flex-col gap-4">
      <span className="h2 text-[2rem]">{t('titles.contact')}</span>
      <DynamicForm
        buttonProps={{
          className: 'self-start h-10',
        }}
        className={className}
        fields={buildFormFields(baseFields, fieldsToShow, additionalFields, fieldsOverwrite)}
        id={type}
        isSubmitting={isSubmitting}
        isSuccess={isSuccess}
        onCloseAlert={reset}
        onSubmit={handleSubmit}
        variant="light"
      />
    </div>
  );
};

export default ContactForm;
