import { ImageFields } from '@boss/types/b2b-b2c';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { twMerge } from 'tailwind-merge';

import ContentfulImage from '../Image';

type Props = {
  src: string;
  thumbnail?: ImageFields | null;
  className?: string;
  showControls?: boolean;
  playing?: boolean;
  onEnded?: (() => void) | undefined;
};

type ThumbnailProps = {
  thumbnail: ImageFields;
  className?: string;
};

const Thumbnail = ({ thumbnail, className }: ThumbnailProps) => {
  return (
    <div className={twMerge('aspect-video w-full cursor-pointer', className)}>
      <ContentfulImage alt={thumbnail.altText} className="object-cover" image={thumbnail.image} />
    </div>
  );
};

const Video = ({ src, thumbnail, className, showControls, playing, onEnded }: Props) => {
  const [hasWindow, setHasWindow] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);

  return (
    <div className={twMerge('aspect-video w-full', className)}>
      {hasWindow ? (
        <ReactPlayer
          className="aspect-video"
          controls={showControls ?? true}
          height="100%"
          light={thumbnail ? <Thumbnail className={className} thumbnail={thumbnail} /> : ''}
          onEnded={onEnded}
          playing={playing}
          url={src}
          width="100%"
        />
      ) : (
        thumbnail && <Thumbnail thumbnail={thumbnail} />
      )}
    </div>
  );
};

export default Video;
