import { Nullable, SimpleImage } from '@boss/types/b2b-b2c';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import NextImage from 'next/image';
import { twMerge } from 'tailwind-merge';

import Image from '../Image';
import Link from '../Link';

type HighlightProps = {
  type: 'primary' | 'secondary';
  label: string;
  slug?: string;
  image?: SimpleImage;
  closeMenu: (hasLink: boolean) => void;
};

type MainNavItemProps = {
  children: string;
  href?: Nullable<string>;
  hasChildren: boolean;
  onClick: () => void;
  active: boolean;
  type: 'primary' | 'secondary';
  className?: string;
};

type SubNavLinkProps = {
  closeMenu: () => void;
  label: string;
  active: boolean;
  type: 'primary' | 'secondary';
  icon?: Nullable<IconProp>;
  href?: Nullable<string>;
  hasChildren: boolean;
  className?: string;
};

export const Highlight = ({ closeMenu, label, slug, image, type }: HighlightProps) => {
  if (!image) {
    return <span>{label}</span>;
  }
  if (!slug) {
    return null;
  }

  const classes = {
    primary:
      'rounded-2.5 aspect-[5/4] before:bg-[linear-gradient(360deg,_rgba(0,0,0,0.2)_-68%,_rgba(255,255,255,0)_269%)]',
    secondary: 'rounded-0 aspect-[16/9] drop-shadow-highlight',
  }[type];

  return (
    <Link
      className={`relative block overflow-hidden before:absolute before:z-10 before:h-full before:w-full before:content-[''] ${classes}`}
      href={slug}
      onClick={() => closeMenu(!!slug)}
    >
      <Image alt={image.imageAlt} className="z-0 object-cover" src={image.imageUrl} />
      <h4 className="absolute bottom-[0px] z-20 pb-2 pl-2.5 pr-4 text-white">{label}</h4>
    </Link>
  );
};

export const MainNavItem = ({ active: activeLink, href, children, hasChildren, onClick, type }: MainNavItemProps) => {
  const { classes, activeClass } = {
    primary: {
      classes:
        'group-hover:font-bold before:block before:h-0 before:content-[attr(title)] before:font-bold before:overflow-hidden before:opacity-0',
      activeClass: 'font-bold',
    },
    secondary: {
      classes: '',
      activeClass: '',
    },
  }[type];

  return (
    <span className="group relative mx-2 flex cursor-pointer items-center pb-6" onClick={onClick}>
      <span className={`inline-block ${classes} ${activeLink && hasChildren ? activeClass : ''}`} title={children}>
        {href && !hasChildren ? <Link href={href}>{children}</Link> : children}
      </span>
      {hasChildren && <FontAwesomeIcon className="ml-2 mt-0.5 flex text-xl" icon={faAngleDown} />}
      <NextImage
        alt="underline"
        className={`object-fit absolute h-3 w-44 opacity-0 group-hover:opacity-100 ${
          type === 'primary' ? 'bottom-0' : 'top-1/2'
        } `}
        height={7}
        src="/underline_nav.png"
        width={175}
      />
    </span>
  );
};

const SubNavLinkIconStyle = cva('', {
  variants: {
    type: {
      primary: 'ml-4.25',
      secondary: 'ml-auto text-brown',
    },
  },
});

export const SubNavLink = ({ className, href, label, icon, type, active, hasChildren, closeMenu }: SubNavLinkProps) => {
  return (
    <Link className={className} href={href} onClick={closeMenu}>
      <span className={twMerge('group z-10 flex w-full items-center justify-between', hasChildren ? 'pr-3' : 'pr-10')}>
        <span className="relative z-10 ml-4 truncate">
          {type === 'secondary' && icon && (
            <FontAwesomeIcon className="relative z-10 mr-2 hover:text-white" icon={icon} />
          )}
          <span>{label}</span>
        </span>
        {type === 'secondary' && (
          <NextImage
            alt="link background"
            className={`object-fit absolute -left-8 z-0 block h-full w-[calc(100%+2rem)] opacity-0 group-hover:opacity-100 ${
              active ? 'opacity-100' : ''
            }`}
            fill={true}
            src="/background_nav_link.png"
          />
        )}
        {hasChildren && <FontAwesomeIcon className={SubNavLinkIconStyle({ type })} icon={faChevronRight} />}
      </span>
    </Link>
  );
};
