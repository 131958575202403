import React, { ReactNode, createContext, useContext } from 'react';

import { useProfile as useProfileHook } from '@boss/auth';

type Profile = ReturnType<typeof useProfileHook>;

const ProfileContext = createContext<Profile>({
  accountId: '',
  data: null,
  isLoggedIn: false,
  status: 'idle',
});

export const ProfileProvider = ({ children }: { children: ReactNode }) => {
  const profile = useProfileHook();

  return <ProfileContext.Provider value={profile}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => {
  const context = useContext(ProfileContext);

  if (context === undefined) {
    throw new Error('useProfileContext must be used within a ProfileProvider');
  }
  return context;
};
