import { useEffect, useRef, useState } from 'react';

import { useIsSSR } from '@boss/hooks';
import { IBasketLine } from '@boss/services/client';
import { DeepPartial, IArticle } from '@boss/types/b2b-b2c';
import { excludeUndefinedFromArray } from '@boss/utils';

import { useArticleMap } from '../';
import {
  useAddBasketLine,
  useArticlesByIds,
  useDeleteBasketLine,
  useGetBasket,
  usePatchBasket,
  useProductsById,
  useUpdateBasketLine,
} from '../../client-queries';

type Options = {
  id?: string;
  fallbackUrl?: string;
  onlinePayment?: boolean;
};

/**
 * Custom hook for accessing the shopping basket state and functions.
 *
 * @param fallbackUrl When the last line of the basket is deleted, a fallback url can be used for re-routing
 * @returns The basket state and functions.
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const useBasket = ({ id, fallbackUrl, onlinePayment }: Options = {}) => {
  const isSSR = useIsSSR();
  const { mapBasketArticle } = useArticleMap();
  const { data: basket, isInitialLoading: isFullBasketLoading, refetch: refetchBasket } = useGetBasket(id);
  const {
    mutate: deleteBasketLineMutation,
    isLoading: isDeleteBasketLineLoading,
    isError: isDeleteError,
  } = useDeleteBasketLine(fallbackUrl);
  const {
    mutateAsync: addBasketLineMutation,
    isError: isAddError,
    isLoading: isUpdateBasketLinesLoading,
  } = useAddBasketLine();
  const {
    mutateAsync: updateBasketLineMutation,
    isLoading: isUpdateBasketLoading,
    isError: isUpdateError,
  } = useUpdateBasketLine();

  const { mutateAsync: patchBasket } = usePatchBasket();

  const basketId = basket?.id ?? '';
  const basketLines = basket?.lines ?? [];

  const [basketLinesToFetch, setBasketLinesToFetch] = useState(basket?.lines ?? []);

  const prevLineIdsRef = useRef(new Set(basket?.lines.map(line => line.id)));

  useEffect(() => {
    const currentLineIds = new Set(basket?.lines.map(line => line.id));
    const lineAdded =
      currentLineIds.size > prevLineIdsRef.current.size &&
      [...currentLineIds].some(id => !prevLineIdsRef.current.has(id));

    if (lineAdded) {
      setBasketLinesToFetch(basket?.lines ?? []);
    }

    prevLineIdsRef.current = currentLineIds;
  }, [basket?.lines]);

  const {
    data: initialArticles,
    isSuccess: articlesLoaded,
    isLoading: articlesLoading,
  } = useArticlesByIds(basketLinesToFetch.map(line => line.item.skuid));

  const articles = excludeUndefinedFromArray<IArticle>(initialArticles || []);
  const uniqueProductIds = [
    ...new Set(
      articles
        .reduce((prev: string[], article) => (article.productId ? [...prev, article.productId] : prev), [])
        .flat(),
    ),
  ];
  const { data: products } = useProductsById(uniqueProductIds, articlesLoaded);
  const isBasketLoading = isSSR || articlesLoading || isFullBasketLoading;

  const getArticles = () => {
    if (articlesLoaded) {
      return mapBasketArticle(articles, basketLines, products);
    }

    return [];
  };

  const deleteBasketLine = (basketLineId: string) => deleteBasketLineMutation({ basketId, basketLineId });

  const addBasketLine = async (newBasketLine: IBasketLine) =>
    await addBasketLineMutation({
      basketId,
      newBasketLines: [newBasketLine],
    });

  const addBasketLines = async (newBasketLines: DeepPartial<IBasketLine>[]) =>
    await addBasketLineMutation({ basketId, newBasketLines });

  const updateBasketLine = async (basketLine: DeepPartial<IBasketLine>) => {
    await updateBasketLineMutation({ basketId, basketLine });
  };

  const updateWorksite = async (worksite: string) => {
    await patchBasket({
      basketId,
      patchLines: [
        {
          op: 'replace',
          value: worksite,
          path: 'worksitediscountcode',
        },
      ],
    });
  };

  const getAmountOfItems = () => basketLines?.length ?? 0;

  const { pricesummary } = basket ?? {};

  return {
    addBasketLine,
    addBasketLines,
    deleteBasketLine,
    updateBasketLine,
    updateWorksite,
    amountOfItems: getAmountOfItems(),
    basket,
    basketId,
    basketLines,
    salesPrice: pricesummary?.totalgrossamountinclvat ?? 0,
    grossAmountExVat: pricesummary?.totalgrossamountexclvat ?? 0,
    netAmountExVat: pricesummary?.totalnetamountexvat ?? 0,
    netAmountInclVat: (onlinePayment ? pricesummary?.amounttopay : pricesummary?.totalnetamountinclvat) ?? 0,
    worksitediscountcode: basket?.worksitediscountcode,
    isBasketLoading,
    isUpdateBasketLoading: isUpdateBasketLoading || isUpdateBasketLinesLoading,
    isDeleteBasketLineLoading,
    articles: getArticles(),
    errors: { isAddError, isDeleteError, isUpdateError },
    orderConfirmed: basket?.payment?.pending === false,
    refetchBasket,
    invoicePaymentAllowed: !basket?.account?.paymentrequired,
  };
};
