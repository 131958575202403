import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { storePageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';
import { Link, Note, Presence } from '@boss/ui';

import { useParticipateEvent } from '../../client-queries';
import { COLORA_LINK } from '../../constants';
import { FormType, useFormField } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

export const FORM_FIELD_KEYS = ['salutation', 'firstname', 'lastname', 'email'];

const CLIENT_TYPES = ['professionalClient', 'professionalNonClient', 'private'];

type ClientType = (typeof CLIENT_TYPES)[number];
type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};

type UserOption = {
  value: ClientType;
  label: string;
};

type Props = {
  type?: FormType;
  eventId: string;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: FieldOverwrite;
  additionalFields?: FormField[];
  className?: string;
};

const EducationForm = ({
  fieldsToShow: initialFieldsToShow,
  fieldsOverwrite,
  additionalFields,
  className,
  type = 'technical-advice',
  eventId,
}: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { mutate: submit, isLoading: isSubmitting, isSuccess, reset } = useParticipateEvent();
  const { t } = useTranslation('forms');
  const { locale } = useRouter();

  const { salutation, email, firstname, lastname } = useFormField();

  const userOptions: UserOption[] = [
    {
      value: 'professionalClient',
      label: t('select.clientTypeOptions.professionalClient'),
    },
    {
      value: 'professionalNonClient',
      label: t('select.clientTypeOptions.professionalNonClient'),
    },
    {
      value: 'private',
      label: t('select.clientTypeOptions.private'),
    },
  ];

  const [clientType, setClientType] = useState(userOptions[0].value);
  const isPrivate = clientType === 'private';

  const handleFormValuesChange = (formik: ReturnType<typeof useFormik>) => {
    const newClientType = CLIENT_TYPES.find(type => type === formik.values.clienttype);

    if (newClientType && clientType !== newClientType) {
      setClientType(newClientType);
    }
  };

  const baseFields: FormField[] = [salutation, email, firstname, lastname];

  const getFieldsToShow = (): FormFieldKey[] => {
    const fields = fieldsToShow ?? FORM_FIELD_KEYS;

    if (clientType === 'private') {
      return ['clienttype'];
    }

    return fields;
  };

  const handleSubmit = (vals: FormValues) => {
    submit({
      eventId,
      formData: vals,
    });
  };

  return (
    <Presence className={twMerge('flex flex-col gap-4', className)} id="help-form-presence" visible>
      <DynamicForm
        eventId={eventId}
        fields={buildFormFields(baseFields, getFieldsToShow(), additionalFields, fieldsOverwrite)}
        id={type}
        isSubmitting={isSubmitting}
        isSuccess={isSuccess}
        onCloseAlert={reset}
        onFormValuesChange={handleFormValuesChange}
        onSubmit={handleSubmit}
        showSubmitButton={!isPrivate}
        variant="light"
      />
      {isPrivate && (
        <div className="grid gap-5 md:grid-cols-2">
          <Note>
            <span>{t('note.private.1')}</span>{' '}
            <Link className="underline" href={COLORA_LINK}>
              {t('note.private.2')}
            </Link>{' '}
            <span>{t('note.private.3')}</span>{' '}
            <Link className="underline" href={storePageSlugs[locale]}>
              {t('note.private.4')}
            </Link>
          </Note>
        </div>
      )}
    </Presence>
  );
};

export default EducationForm;
