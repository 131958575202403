import { ChangeEvent, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import FormFieldWrapper, { BaseFieldProps, FieldTooltipWrapper } from '../FormFieldWrapper';

type Props = BaseFieldProps & {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  ariaLabel?: string;
  checked: boolean;
  children: ReactNode;
  type?: 'checkbox' | 'radio';
  value?: string;
  id?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  inputClassName?: string;
};

const InputAction = ({
  children,
  value,
  name,
  ariaLabel,
  onChange,
  checked,
  className,
  error,
  touched,
  required,
  type = 'checkbox',
  tooltip,
  id,
  fullWidth,
  disabled,
  inputClassName,
}: Props) => (
  <FormFieldWrapper className={className} error={error} name={name} touched={touched}>
    <div className={twMerge('flex h-fit items-center gap-2', className)}>
      <input
        aria-label={ariaLabel}
        checked={checked}
        className={twMerge('h-4 w-4', inputClassName)}
        disabled={disabled}
        id={id ?? name}
        name={name}
        onChange={onChange}
        type={type}
        value={value}
      />
      <FieldTooltipWrapper tooltip={tooltip}>
        <label className={twMerge('flex items-center', fullWidth ? 'w-full' : '')} htmlFor={id ?? name}>
          {children}
          {required && '*'}
        </label>
      </FieldTooltipWrapper>
    </div>
  </FormFieldWrapper>
);

export default InputAction;
