import { LocalizedSlugs, NavigationItem, Nullable, SimpleImage } from '@boss/types/b2b-b2c';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Container, LocaleSelector, Logo, MainNavTranslations, SearchBar, SearchResults } from '../../../';
import { UniqueLink } from '../../MenuLinks';
import MenuItem from '../MenuItem';

type Props = {
  links: Nullable<NavigationItem[]>;
  subLinks?: (NavigationItem & { amount?: number })[];
  className?: string;
  localizedSlugs: LocalizedSlugs;
  translations: MainNavTranslations;
  onSearch: () => void;
  searchLabel: string;
  setSearchValue: (val: string) => void;
  searchValue: string;
  logo?: SimpleImage;
  onClose?: () => void;
  searchResults?: SearchResults;
  onCloseSearchMenu?: () => void;
  extraNavigation?: ReactNode;
};

const MobileMenu = ({
  translations,
  links,
  subLinks,
  className,
  localizedSlugs,
  onSearch,
  searchLabel,
  searchValue,
  setSearchValue,
  logo,
  onClose,
  searchResults,
  onCloseSearchMenu,
  extraNavigation,
}: Props) => (
  <nav className={twMerge('text-brown-dark rounded-br-30 relative bg-white', className)}>
    <button className="absolute right-5 top-5" onClick={onClose}>
      <FontAwesomeIcon icon={faClose} />
    </button>
    <div className="bg-green-light max-h-screen overflow-y-scroll py-8">
      <Container className="flex flex-col gap-4">
        <div className="border-b-3 border-brown-dark flex items-center justify-between border-dotted pb-2">
          {logo ? (
            <Logo alt={logo.imageAlt} src={logo.imageUrl} />
          ) : (
            translations?.menuLabel && <h2 className="h1 text-blue ">{translations.menuLabel}</h2>
          )}
        </div>
        {!!links?.length && (
          <div className="flex flex-col gap-5">
            {links.map(link => (
              <MenuItem
                iconPosition="before"
                key={link.id ?? `${link.href ?? 'main-link'}-${link.label}`}
                link={link}
                showIcon
              />
            ))}
          </div>
        )}
      </Container>
    </div>
    <Container className="flex flex-col gap-5 py-6">
      <SearchBar
        onCloseSearchMenu={onCloseSearchMenu}
        onSearch={onSearch}
        searchLabel={searchLabel}
        searchResults={searchResults}
        setSearchValue={setSearchValue}
        type="primary"
        value={searchValue}
      />
      {!!subLinks?.length && (
        <div className="flex flex-col gap-5">
          {subLinks.map(
            link =>
              link.href && (
                <UniqueLink
                  className="flex items-center gap-2"
                  icon={link.icon}
                  key={link.id ?? `${link.href ?? 'main-link'}-${link.label}`}
                  label={link.label}
                  labelClass="inline"
                  slug={link.href}
                />
              ),
          )}
        </div>
      )}
      {extraNavigation && (
        <Container className="border-t-1 border-b-1 flex w-full max-w-full flex-col gap-5 px-0 py-4">
          {extraNavigation}
        </Container>
      )}
      <LocaleSelector className="w-fit" localizedSlugs={localizedSlugs} placement="top" />
    </Container>
  </nav>
);

export default MobileMenu;
