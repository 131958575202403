import { faCartShopping } from '@fortawesome/pro-regular-svg-icons';
import { twMerge } from 'tailwind-merge';

import { ProductPanelInstanceProps } from '..';
import { Button, Presence, Price, Specifications } from '../..';
import ProductPanelFooterSkeleton from '../../ProductPanelFooterSkeleton';

const PrimaryProductPanel = ({
  className,
  translations,
  table,
  price,
  specifications,
  inStock,
  calculator,
  colorPicker,
  addToBasket,
  isLoading,
  deliveryPromises,
  alert,
  info,
}: ProductPanelInstanceProps) => (
  <div className={twMerge('rounded-tr-30 rounded-b-30 flex flex-col shadow-lg', className)}>
    <div className="rounded-tr-30 flex flex-col gap-8 bg-white px-8 py-6">
      {colorPicker}
      {calculator}
      {table}
    </div>

    <Presence
      className="rounded-b-30 bg-paint-coloredBackground flex flex-col gap-6 px-8 py-6"
      id="product-panel-footer-presence"
      isLoading={isLoading}
      loader={<ProductPanelFooterSkeleton className="flex flex-col gap-6 px-8 py-6" />}
      visible
    >
      {!!price && (
        <div className="flex items-center justify-between gap-2">
          <span className="large">{translations.priceLabel}</span>
          <Price {...price} size="large" />
        </div>
      )}

      <Button
        className="w-full justify-center px-6 py-4 text-center sm:[&>*]:text-xl"
        disabled={!inStock}
        icon={faCartShopping}
        iconPosition="left"
        label={inStock ? translations.button.addToBasket : translations.button.outOfStock}
        onClick={addToBasket}
        type="primary"
      />

      {alert}

      {info}

      {!!deliveryPromises && (
        <div className="flex flex-col gap-3">
          <span>{translations.deliveryOptions}</span>
          {deliveryPromises}
        </div>
      )}

      {!!specifications?.length && (
        <>
          <div className="border-b-3 border-b-red-ghost w-full border-dotted text-4xl" />
          <Specifications
            border={false}
            className="gap-4 md:grid md:grid-cols-2"
            id="product-panel-specs"
            items={specifications}
          />
        </>
      )}
    </Presence>
  </div>
);

export default PrimaryProductPanel;
