import { OrderAddress } from '..';
import AddressLine from '../AddressLine';

export interface Props {
  title: string;
}

export interface InformationProps {
  address: OrderAddress[];
  name?: string;
}

const OrderInformation = ({ address, name }: InformationProps) => {
  return (
    <>
      {name && <p className="font-bold">{name}</p>}

      {address?.map(({ label, address }, index) => (
        <div className="mb-5 last-of-type:mb-0" key={index}>
          <p>{label}</p>
          <AddressLine address={address} />
        </div>
      ))}
    </>
  );
};

export default OrderInformation;
