import { IAddress, IAddressSuggestions } from '@boss/types/b2b-b2c';

import { Address } from './queries';
import { mapNextToContentfulLocale } from '../../utils/localeMapper';

/**
 * Takes user query input, and fetches address suggestions from the BOSS api
 */
const fetchAutoCompleteSuggestions = async (locale: string, query: string): Promise<IAddressSuggestions> => {
  if (query.length < 2) {
    return [];
  }

  const nextLocale = mapNextToContentfulLocale(locale);

  const region = nextLocale.split('-')[1];
  const path = `/api/addresses/autocomplete?searchstring=${query}&region=${region}&types=geocode`;
  const response = await fetch(path);

  // No content -> gibberish input user
  if (response.status === 204) {
    return [];
  }

  return await response.json();
};

const fetchAddressById = async (id: string): Promise<IAddress | null> => {
  if (!id) {
    return null;
  }
  const path = `/api/addresses/autocomplete/${id}`;
  const response = await fetch(path);

  return await response.json();
};

const validateAddress = async (
  address: Address,
): Promise<{
  cityvalidated: boolean;
  completeaddressvalidated: boolean;
  countryvalidated: boolean;
  postboxvalidated: boolean;
  streetnumbervalidated: boolean;
  streetvalidated: boolean;
  zipcodevalidated: boolean;
}> => {
  const response = await fetch('/api/addresses/validation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(address),
  });

  return await response.json();
};

export { fetchAddressById, fetchAutoCompleteSuggestions, validateAddress };
