import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

type SimpleProps = {
  simpleDiscountAmount?: string;
  simpleTitle?: string;
};

type FullProps = {
  complimentaryColor?: string;
  label?: string;
  discountTitle?: string;
  discountSubTitle?: string;
  lines?: string[];
};

type Props = SimpleProps &
  FullProps & {
    backgroundColor: string;
    variant?: 'simple' | 'full';
    className?: string;
  };

const Layout = cva('rounded-2 text-white', {
  variants: {
    variant: {
      simple: 'py-12.5 pl-5 px-3',
      full: 'relative uppercase',
    },
  },
});

const Simple = ({ simpleDiscountAmount, simpleTitle }: SimpleProps) => {
  return (
    <>
      <h2 className="leading-60 text-4xl">{simpleDiscountAmount}</h2>
      <p className="leading-48 text-2xl">{simpleTitle}</p>
    </>
  );
};

const Full = ({ complimentaryColor, label, discountTitle, discountSubTitle, lines }: FullProps) => {
  return (
    <>
      {label && (
        <div className="bg-yellow rounded-l-50 text-gray-dark absolute right-0 top-0 mt-2 py-1.5 pl-5 pr-3 text-sm capitalize">
          {label}
        </div>
      )}
      <div className="px-3 pb-5 pt-8">
        <h3 className="text-2xl">{discountTitle}</h3>
        <h4 className="leading-14 mt-3 text-xs">{discountSubTitle}</h4>
      </div>

      <div className="rounded-b-2 relative px-3 pb-5 pt-3" style={{ backgroundColor: `${complimentaryColor ?? ''}` }}>
        {lines?.length && (
          <div>
            {lines?.map((line, i) => (
              <p className="mb-3 text-sm" key={i}>
                {line}
              </p>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const DiscountTile = ({
  variant = 'full',
  backgroundColor,
  simpleDiscountAmount,
  simpleTitle,
  className,
  ...props
}: Props) => {
  return (
    <div className={twMerge(Layout({ variant }), className)} style={{ backgroundColor: `${backgroundColor ?? ''}` }}>
      {variant === 'simple' && <Simple simpleDiscountAmount={simpleDiscountAmount} simpleTitle={simpleTitle} />}
      {variant === 'full' && <Full {...props} />}
    </div>
  );
};

export default DiscountTile;
