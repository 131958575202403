import options from './__generated__';
import { PageOptions } from '../../constants';

export const shouldShowSubscribeForm = options['showSubscribeForm'];
export const youtubeLink = options['youtubeLink'];
export const getSectionBgColor = options['getSectionBgColor'];
export const headerVariant = options['headerVariant'];
export const stepperVariant = options['stepperVariant'];
export const paintSystemTarget = options['paintSystemTarget'];
export const sectionQuoteProps = options['sectionQuoteProps'];
export const sectionQuoteQuoteProps = options['sectionQuoteQuoteProps'];
export const sectionQuoteImageProps = options['sectionQuoteImageProps'];
export const componentsToExclude = options['componentsToExclude'];
export const productCardVariant = options['productCardVariant'];
export const storeFinderVariant = options['storeFinderVariant'];
export const storeCardVariant = options['storeCardVariant'];
export const orderCardVariant = options['orderCardVariant'];
export const articleCardVariant = options['articleCardVariant'];
export const displayPWAPopup = options['displayPWAPopup'];
export const storeDetailTheme = options['storeDetailTheme'];
export const showProductDocuments = options['showProductDocuments'];
export const productPanelVariant = options['productPanelVariant'];
export const inspirationGridVariant = options['inspirationGridVariant'];
export const expandableFacets = options['expandableFacets'];
export const accountPages = options['accountPages'];
export const accountNavigation = options['accountNavigation'];
export const accountPageConfig = options['accountPageConfig'];
export const accountDashboardVariant = options['accountDashboardVariant'];
export const productLabelVisibilityConfig = options['productLabelVisibilityConfig'];
export const isB2b = options['isB2b'];
export const specialQuoteCarousel = options['specialQuoteCarousel'];
export const accountShowAppointments = options['accountShowAppointments'];
export const pageOptions = options['pageOptions'];
export const productSortOptions = options['productSortOptions'];
export const promotionCardVariant = options['promotionCardVariant'];
export const categoryPageConfig = options['categoryPageConfig'];
export const basketPageConfig = options['basketPageConfig'];
export const ordersVariant = options['ordersVariant'];
export const homeDeliverySlugs = options['homeDeliverySlugs'];
export const priceConfig = options['priceConfig'];
export const paintguideQuestionAttributes = options['paintguideQuestionAttributes'];
export const paintguideEnrichedQuestionMap = options['paintguideEnrichedQuestionMap'];
export const stockConfig = options['stockConfig'];
export const colorsConfig = options['colorsConfig'];
export const accountContactInformationType = options['accountContactInformationType'];
export const checkoutConfig = options['checkoutConfig'];
export const crossSellingModalConfig = options['crossSellingModalConfig'];
export const seoConfig = options['seoConfig'];
export const eventConfig = options['eventConfig'];
export const loginPageConfig = options['loginPageConfig'];
export const registerPageConfig = options['registerPageConfig'];
export const toasterConfig = options['toasterConfig'];
export const productPageConfig = options['productPageConfig'];
export const storeConfig = options['storeConfig'];
export const homePageConfig = options['homePageConfig'];
export const loginSectionConfig = options['loginSectionConfig'];
export const unitConfig = options['unitConfig'];
export const contactInfoConfig = options['contactInfoConfig'];
export const productListConfig = options['productListConfig'];
export const surfaceCalculationConfig = options['surfaceCalculationConfig'];
export const overviewPageTypes = options['overviewPageTypes'];

export const getOptionsForPage = (pageName: string) => {
  return options['pageOptions']?.[pageName];
};

export const getBreadcrumbVisibilityForPage = (pageName: string) => {
  const getOptions = getOptionsForPage(pageName);

  return !getOptions || getOptions?.[PageOptions.BREADCRUMB_VISIBILITY];
};
