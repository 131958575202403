import { trackEvents } from '@boss/constants/shared';
import { ISearchProduct } from '@boss/types/b2b-b2c';

import CommonSearchGrid from './commonSearchGrid';
import { ProductCardWrapper } from '../../components';
import { useProductMap } from '../../hooks';
import { productSortOptions } from '../../utils';

type Props = {
  indexName: string;
  onUpdateTotalResults: (results: number | null) => void;
  query?: string;
};

const ProductSearch = ({ indexName, onUpdateTotalResults: handleResultsUpdate, query }: Props) => {
  const { mapProductComponent } = useProductMap();

  return (
    <CommonSearchGrid<ISearchProduct>
      indexName={indexName}
      onUpdateTotalResults={handleResultsUpdate}
      renderCard={(product, index) => (
        <ProductCardWrapper
          key={`search-card-${product.id}`}
          {...mapProductComponent(product)}
          trackInfo={{
            customEvent: trackEvents.SEARCH_RESULT_SELECTED,
            searchIndex: 'products',
            resultName: product.name,
            searchPosition: index + 1,
            searchQuery: query ?? '',
          }}
        />
      )}
      sortOptions={productSortOptions}
    />
  );
};

export default ProductSearch;
