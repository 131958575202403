import { useDynamicWidgets } from 'react-instantsearch';

export const useSearchAttributes = (attributesToHide: string[] = []) => {
  const { attributesToRender } = useDynamicWidgets({
    facets: ['*'],
  });

  const attributes = attributesToRender?.filter(attribute => !attributesToHide.includes(attribute)) || [];

  return { attributes };
};

export default useSearchAttributes;
