import type { Options } from '@sentry/types';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const config: Options = {
  dsn: SENTRY_DSN,
  enabled: process.env.SENTRY_ENVIRONMENT !== 'develop',
  tracesSampleRate: 1.0,
  environment: process.env.SENTRY_ENVIRONMENT,
};

export default config;
