import { useState } from 'react';

interface Pagination<T> {
  currentPage: number;
  paginate: (pageNumber: number) => void;
  currentItems: T[] | undefined;
  totalPages: number;
}

/**
 * A hook for handling pagination of a list of items.
 *
 * @template T
 * @param {number} itemsPerPage - The number of items to display per page.
 * @param {T[]} items - The array of items to be paginated.
 * @returns {{
 *   currentPage: number,
 *   paginate: (pageNumber: number) => void,
 *   currentItems: T[],
 *   totalPages: number
 * }}
 */
export function usePagination<T>(itemsPerPage: number, items: T[] | undefined): Pagination<T> {
  const [currentPage, setCurrentPage] = useState(0);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const getPaginatedItems = () => {
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return items?.slice(startIndex, endIndex);
  };

  const totalPages = items?.length ? Math.ceil(items?.length / itemsPerPage) : 0;

  return { currentPage, paginate, currentItems: getPaginatedItems(), totalPages };
}
