import { useState } from 'react';

interface PaginationProps {
  currentPage: number;
  paginate: (pageNumber: number) => void;
  offset: number;
  limit: number;
}

export function useApiPagination(pageSize: number): PaginationProps {
  const [currentPage, setCurrentPage] = useState(0);

  return { currentPage, paginate: setCurrentPage, offset: currentPage * pageSize, limit: pageSize };
}
