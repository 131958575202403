import { LocalizedSlugs, NavigationItem, Nullable } from '@boss/types/b2b-b2c';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Container, LocaleSelector, MainNavTranslations } from '../../../';
import MenuItem from '../MenuItem';

type Props = {
  links: Nullable<NavigationItem[]>;
  highlightedLinks?: Nullable<NavigationItem[]>;
  className?: string;
  localizedSlugs: LocalizedSlugs;
  translations: MainNavTranslations;
  closeMenu: () => void;
  extraNavigation?: ReactNode;
};

const MobileMenu = ({ translations, links, className, localizedSlugs, closeMenu, extraNavigation }: Props) => (
  <nav className={twMerge('text-brown overflow-auto bg-white', className)}>
    <Container className="flex flex-col gap-8 py-5">
      {translations?.menuLabel && (
        <div className="flex items-center justify-between">
          <h2 className="display">{translations.menuLabel}</h2>{' '}
          <FontAwesomeIcon className="cursor-pointer" fontWeight="light" icon={faTimes} onClick={closeMenu} size="lg" />
        </div>
      )}
      {!!links?.length && (
        <div className="flex flex-col gap-4">
          {links.map(link => (
            <MenuItem key={link.id ?? `${link.href ?? 'main-link'}-${link.label}`} link={link} topLevel />
          ))}
        </div>
      )}
      {extraNavigation && <Container className="flex flex-col gap-5 px-0">{extraNavigation}</Container>}
      <LocaleSelector className="w-fit" localizedSlugs={localizedSlugs} placement="top" />
    </Container>
  </nav>
);

export default MobileMenu;
