export * from './queries';

export const articleKeys = {
  articleByIds: (locale: string, ids: string[]) => ['articleById', locale, ids],
  articleByProductId: (locale: string, id: string, colorId: string, colorType: string) => {
    return ['articleByProductId', locale, id, colorId, colorType];
  },
  accountPriceInfo: ({
    locale,
    skuId,
    unit,
    quantity,
  }: {
    locale: string;
    skuId: string;
    unit: string;
    quantity: number;
  }) => ['accountPriceInfo', locale, skuId, unit, quantity],
  articleDiscountInfo: (locale: string, unit: string, quantity: number, skuId: string) => [
    'articleDiscountInfo',
    locale,
    unit,
    quantity,
    skuId,
  ],
};
