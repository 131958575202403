import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { Presence } from '@boss/ui';

import { DynamicForm } from '..';
import { FormType, useFormField, useFormSubmit } from '../../hooks';
import { buildFormFields } from '../../utils';
import { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

const FORM_FIELD_KEYS = ['email', 'termsandconditions'];

export type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];

type Props = {
  type: FormType;
  className?: string;
};

const NewsLetterForm = ({ type, className }: Props) => {
  const fieldsToShow = [...FORM_FIELD_KEYS];
  const { t } = useTranslation('forms');

  // ASSUMPTION: Currently using the mail API since there is no clear answer on which API to use
  const { onSubmit, isSubmitting, isSuccess, reset } = useFormSubmit();

  const { email, termsandconditions } = useFormField();

  const baseFields: FormField[] = [
    { ...email, colStyle: 'col-span-6' },
    { ...termsandconditions, colStyle: 'col-span-6' },
  ];

  const handleSubmit = (values: FormValues) => {
    const formObject = {
      ...values,
      type: 'ColoraNewsletter',
    };

    onSubmit(type, formObject);
  };

  return (
    <Presence className={twMerge('flex flex-col gap-4', className)} id="newsletter-form-presence" visible>
      <DynamicForm
        buttonProps={{
          label: t('buttons.submitQuestion') ?? '',
        }}
        fields={buildFormFields(baseFields, fieldsToShow, [])}
        id={type}
        isSubmitting={isSubmitting}
        isSuccess={isSuccess}
        onCloseAlert={reset}
        onSubmit={handleSubmit}
        variant="light"
      />
    </Presence>
  );
};

export default NewsLetterForm;
