import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { Image, Link } from '..';

type Props = {
  title: string;
  description: string;
  slug: string;
  imageUrl: string;
  imageAlt: string;
  className?: string;
  translations?: {
    linkLabel: string;
  };
  whitespace?: 'ratio' | 'none';
};

const textStyles = cva('w-full p-4 md:col-span-2 md:px-12 md:py-5', {
  variants: {
    variant: {
      ratio: '',
      none: 'h-auto',
    },
  },
});

const imageStyles = cva('w-full overflow-hidden bg-gray-100', {
  variants: {
    variant: {
      ratio: 'aspect-[16/12]',
      none: 'aspect-[16/8]',
    },
  },
});

const ContentTile = ({
  imageUrl,
  imageAlt,
  title,
  description,
  slug,
  className,
  translations,
  whitespace = 'ratio',
}: Props) => (
  <Link className="block h-full w-full md:flex" href={slug}>
    <div
      className={twMerge(
        'border-1 rounded-t-brand rounded-r-brand border-gray-light md:min-h-60 grid h-full grid-cols-1 grid-rows-[max-content_auto_4rem] overflow-hidden bg-white shadow-sm',
        className,
      )}
    >
      <div className={imageStyles({ variant: whitespace })}>{imageUrl && <Image alt={imageAlt} src={imageUrl} />}</div>
      <div className={textStyles({ variant: whitespace })}>
        <div className="flex flex-col gap-2">
          <span className="h3 hyphens-auto">{title}</span>
          <span>{description}</span>
        </div>
      </div>
      <div className="flex w-full self-end justify-self-end px-4 pb-4 md:px-12">
        {translations?.linkLabel && (
          <span className="text-blue flex items-center gap-2 py-2">
            {translations?.linkLabel} <FontAwesomeIcon icon={faArrowRight} />
          </span>
        )}
      </div>
    </div>
  </Link>
);

export default ContentTile;
