import { CountryCode } from 'libphonenumber-js';
import { ComponentProps, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

import FormFieldWrapper from '../FormFieldWrapper';
import InputField, { InputFieldStyles } from '../InputField';

type InputFieldProps = ComponentProps<typeof InputField>;

type Props = Omit<InputFieldProps, 'type' | 'onChange'> & {
  onChange: (val: string) => void;
  defaultCountry?: CountryCode;
  highlightedCountryCodes?: CountryCode[];
};

const PhoneField = ({
  className,
  highlightedCountryCodes,
  error,
  touched,
  name,
  label,
  defaultCountry,
  variant,
  ref,
  disclaimer,
  required,
  tooltip,
  id,
  onChange,
  value = '',
  ...props
}: Props) => {
  const [phoneValue, setPhoneValue] = useState('');

  const handleChange = (val: string) => {
    setPhoneValue(val);
    onChange(val);
  };

  useEffect(() => {
    setPhoneValue(value);
  }, [value]);

  return (
    <FormFieldWrapper
      className={className}
      disclaimer={disclaimer}
      error={error}
      id={id}
      label={label}
      name={name}
      required={required}
      tooltip={tooltip}
      touched={touched}
    >
      <PhoneInput
        {...props}
        className={InputFieldStyles({ variant, error: !!error })}
        countryOptionsOrder={highlightedCountryCodes}
        defaultCountry={defaultCountry}
        inputComponent={InputField}
        international
        name={name}
        numberInputProps={{
          inputClassName: 'bg-transparent p-0 pl-3 focus:ring-0 ring-0 focus:border-none focus-visible:outline-none',
        }}
        onChange={handleChange}
        value={phoneValue}
      />
    </FormFieldWrapper>
  );
};

export default PhoneField;
