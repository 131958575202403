import { useTranslation } from 'next-i18next';

import { ISearchProduct } from '@boss/types/b2b-b2c';

import SearchWrapper from './wrapper';
import { ProductSearchContext } from './wrapper';
import { PagedProducts } from '../../../../components';

type Props = {
  addProductToList?: (products: ISearchProduct) => void;
};

const SearchProducts = ({ addProductToList: handleAddProductToList }: Props) => {
  const { t } = useTranslation('account', { keyPrefix: 'list.createNewList' });

  return (
    <SearchWrapper>
      <ProductSearchContext.Consumer>
        {({ products, query, loading }) => {
          return (
            <>
              <PagedProducts onSelect={handleAddProductToList} products={products} />
              {!loading && query && products.length === 0 && <div className="mt-5">{t('noResults')}</div>}
            </>
          );
        }}
      </ProductSearchContext.Consumer>
    </SearchWrapper>
  );
};

export default SearchProducts;
