import * as bossClient from '@boss/boss-client';
import deepMerge from 'deepmerge';

import { mapOrderToAccountOrder } from './mapper';
import {
  IAccount,
  IAccountDiscountInfo,
  IAccountOrder,
  ICommonFilterOptions,
  IContact,
  IInvoiceFilterOptions,
  TAccountDiscountInfoType,
} from './types';

/**
 * Fetches an account by id
 */
export const byId = async ({ accountId, locale }: { accountId: string; locale: string }): Promise<IAccount> => {
  const account = await bossClient.account.getAccount({ accountId, locale });

  if (!account) {
    throw new Error(`Account with id ${accountId} not found`);
  }

  return account;
};

/**
 * Fetches appointments by accountid
 */
export const appointments = ({ accountId, locale }: { accountId: string; locale: string }) => {
  return bossClient.account.getAppointments({ accountId, locale });
};

/**
 * Fetches worksites by acocuntid
 */
export const worksites = ({ accountId, locale }: { accountId: string; locale: string }) => {
  return bossClient.account.getWorksites({ accountId, locale });
};

export const worksitesDetails = async ({ accountId, locale }: { accountId: string; locale: string }) => {
  const account = await byId({ accountId, locale });

  return account.addresses.filter(address => address.type === 'worksite');
};

/**
 * Update an account by id
 *
 * !!!! If the crmdeeplink key of the fieldsToupdate obj on account IS NOT set to an empty string,
 *      the reques wil return a 403 (Forbidden) HTML page.
 *
 *@todo Remove the crmdeeplink logic, when the BOSS API fixes this error
 */
export const update = ({ accountId, account }: { accountId: string; account: IAccount }) => {
  const body = deepMerge(account, {
    accountinfo: {
      crmdeeplink: '',
    },
  });

  return bossClient.account.updateAccount({ accountId, body });
};

/**
 * gets the paintguide results from an account
 */
export const paintguideResults = (params: { accountId: string; locale: string }) => {
  return bossClient.account.getPaintguideResults(params);
};

/**
 * saves the paintguide result url to the account
 */
export const savePaintguideResults = (body: { accountnumber: string; url: string; description: string }) => {
  return bossClient.account.updatePaintguideResults(body);
};

/**
 * Get all contacts by account id
 */
export const contacts = async ({ accountId, locale }: { accountId: string; locale: string }): Promise<IContact[]> => {
  const contacts = (await bossClient.account.getContacts({ accountId, locale })) || [];

  return contacts.map(contact => ({
    id: contact.contactpersonid,
    firstname: contact.firstname,
    lastname: contact.lastname,
    jobtitle: contact.jobtitle,
    email: contact.email,
    mobilephonenumber: contact.mobilephonenumber,
    officephonenumber: contact.officephonenumber,
  }));
};

/**
 * Update a contact
 */
export const updateContact = async ({ contact }: { contact: IContact }) => {
  return await bossClient.account.updateContact({
    contact: {
      ...contact,
      contactpersonid: contact.id,
    },
  });
};

/**
 * Get all invoices
 */
export const invoices = async ({
  accountId,
  locale,
  filterOptions,
}: {
  accountId: string;
  locale: string;
  filterOptions: IInvoiceFilterOptions;
}) => {
  const { limit, offset, paid, fromDate, toDate } = filterOptions;
  const data = await bossClient.account.getInvoices({ accountId, locale, paid, fromDate, toDate, limit, offset });

  if (data && data.invoices) {
    return (
      data.invoices?.map(invoice => ({
        ...invoice,
        expired: !paid && new Date(invoice.duedate) < new Date(),
      })) ?? []
    );
  } else {
    return [];
  }
};

/**
 * Get orders
 */
export const orders = async ({
  accountId,
  locale,
  filterOptions,
}: {
  accountId: string;
  locale: string;
  filterOptions: ICommonFilterOptions;
}): Promise<{
  orders: IAccountOrder[];
  count: number;
}> => {
  const orders = await bossClient.account.getOrders({ accountId, locale, ...filterOptions });

  if (!orders) {
    return { orders: [], count: 0 };
  }

  return { orders: orders.orders?.map(mapOrderToAccountOrder), count: orders.count ?? 0 };
};

/**
 * Get inProcess orders
 */
export const inProcessOrders = async ({
  accountId,
  locale,
  filterOptions,
}: {
  accountId: string;
  locale: string;
  // type is either 1 (ToDeliverLines) or 2 (OpenOrderLines)
  filterOptions: { type: 1 | 2 } & ICommonFilterOptions;
}) => {
  const orders = await bossClient.account.getInProcessOrders({ accountId, locale, ...filterOptions });

  if (!orders) {
    return { orders: [], count: 0 };
  }

  return { orders: orders.orders?.map(mapOrderToAccountOrder), count: orders.count ?? 0 };
};

/**
 * Get all advices
 */
export const advices = async ({ accountId, locale }: { accountId: string; locale: string }) => {
  return await bossClient.account.getAdvices({ accountId, locale });
};

/**
 *  Get account discount info
 */
export const accountDiscountInfo = async ({
  accountId,
  locale,
  type,
}: {
  accountId: string;
  locale: string;
  type: TAccountDiscountInfoType;
}): Promise<IAccountDiscountInfo[]> => {
  const data = await bossClient.account.getAccountDiscountInfo({ accountId, locale, type });

  return (
    data?.map(info => ({
      title: info.title,
      discountLabelInfo: info.discountlabelinfo,
      productDetail: info.productdetail,
      toDate: info.todate,
    })) ?? []
  );
};
