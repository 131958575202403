import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { IProduct } from '@boss/services';
import { Color } from '@boss/types/b2b-b2c';
import { PointerCard, icons } from '@boss/ui';
import { getTranslatedSlugsByType } from '@boss/utils';

import { useAccountPriceInfoByProductId } from '../../client-queries';
import { useProductMap } from '../../hooks';

type Props = {
  color?: Color;
  product?: IProduct;
  className?: string;
};

const PointerCardWrapper = ({ className, color, product }: Props) => {
  const { mapProductSlug } = useProductMap();
  const { data: accountPriceInfo, isLoading: priceInfoLoading } = useAccountPriceInfoByProductId(product?.id ?? '');

  const { t } = useTranslation('common');
  const { locale } = useRouter();

  if (!product) {
    return null;
  }

  const slug = mapProductSlug({
    productName: product.slug,
    id: product.id,
    seoTitle: product.seoTitle,
    colorId: color?.id,
  });
  const productData = product.usps?.[0];
  const subtitle = productData ? documentToPlainTextString(productData) : '/';

  return (
    <PointerCard
      className={className}
      color={
        color
          ? {
              eyebrow: t('color'),
              title: `${color.code} ${color.name}`,
              href: getTranslatedSlugsByType(color.slug ?? '', 'Color overview')[locale as string] ?? color.slug,
              footerLabel: t('pointer.footerLinkLabel'),
              footerHref: slug,
              hex: color.rgb,
            }
          : undefined
      }
      href={slug}
      icon={icons.getPreloadedIcon('paint-roller')}
      product={{
        imageSrc: product.packshot.src,
        eyebrow: t('from'),
        price: accountPriceInfo?.prices?.fromPriceAmount,
        priceInfoLoading,
      }}
      subtitle={subtitle}
      title={product.seoTitle ?? product.name}
    />
  );
};

export default PointerCardWrapper;
