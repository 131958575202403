import { useQuery } from '@tanstack/react-query';

import { IIncidentType, WorkerOrderType } from '@boss/services/client';

import { fetchAllIncidentTypes } from './connector';

import { incidentTypeKeys } from './index';

const useIncidentTypes = (workerOrderType: WorkerOrderType) => {
  return useQuery<IIncidentType[]>(
    incidentTypeKeys.allIncidentTypes(workerOrderType),
    async () => await fetchAllIncidentTypes(workerOrderType),
  );
};

export { useIncidentTypes };
