import { formatLocalDate } from '@boss/utils';
import React, { useEffect, useState } from 'react';

import RadioGroup from '../RadioGroup';
import Select from '../Select';

interface DateOptionsProps {
  dates: string[];
  onChange: (selectedDate: string) => void;
  locale: string;
  showMoreLabel?: string;
  value?: string;
}

const DateOptions: React.FC<DateOptionsProps> = ({ value, dates, onChange, locale, showMoreLabel }) => {
  const [showSelect, setShowSelect] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>(value ?? dates[0] ?? '');

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setSelectedDate(e.target.value);
    onChange(e.target.value);
  };

  const handleRadioChange = (value: string) => {
    setSelectedDate(value);
    onChange(value);
  };

  const options = dates.map(date => ({
    label: formatLocalDate(date, locale),
    value: date,
  }));

  useEffect(() => {
    setSelectedDate(value ?? dates[0] ?? '');
  }, [dates, value]);

  return (
    <div>
      {!showSelect ? (
        <div>
          <RadioGroup
            name="date selector"
            onChange={handleRadioChange}
            options={options.slice(0, 3)}
            value={selectedDate}
          />
          <button className="text-blue mt-4" onClick={() => setShowSelect(true)}>
            {showMoreLabel}...
          </button>
        </div>
      ) : (
        <div>
          <Select
            className="form-select mt-1 block w-full"
            name="date selector"
            onChange={handleSelectChange}
            options={options}
            value={selectedDate}
          />
        </div>
      )}
    </div>
  );
};

export default DateOptions;
