export default {
  accountnumber: '23058180',
  accountinfo: {
    name: 'Lisa Peeters',
    firstname: 'Lisa',
    lastname: 'Peeters',
    status: 'string',
    crmdeeplink: 'string',
    mixingmachine: 'string',
    salesdistrict: 'string',
    deliverymode: 'string',
    origin: 'string',
  },
  billinginfo: {
    vatnumber: 'BE 0653.912.2394',
    paymentterm: 'string',
  },
  contactinfo: {
    phonenumber: '123456789',
    logisticsphonenumber: 'string',
    primarycontact: 'string',
    primarycontactid: 'string',
    email: 'lisa.peeters@gmail.com',
    language: 'string',
  },
  addresses: [
    {
      type: 'delivery',
      street: 'kerkstraat',
      streetnumber: '2',
      postbox: '',
      zipcode: '9000',
      city: 'Gent',
      country: 'Belgium',
    },
    {
      type: 'invoice',
      street: 'kerkstraat',
      streetnumber: '2',
      postbox: '',
      zipcode: '9000',
      city: 'Gent',
      country: 'Belgium',
    },
  ],
  serviceteam: [
    {
      type: 'string',
      name: 'string',
      phonenumber: 'string',
      email: 'string',
    },
  ],
  preference: {
    bossflash: true,
    magazine: true,
    emailings: true,
    pricelist: true,
    shop: 'string',
    onlinecommunication: true,
    offlinecommunication: true,
  },
  segmentation: {
    segment: 'string',
    subsegment: 'string',
    companyprofile: 'string',
  },
  socialmedia: {
    websiteurl: 'string',
    twitterurl: 'string',
    instagramurl: 'string',
    pinteresturl: 'string',
    facebookurl: 'string',
  },
  discountagreement: {
    highrunnerpaint: 0,
    otherpaint: 0,
    nonpaint: 0,
  },
  availableservices: ['string'],
};
