import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  children?: ReactNode;
};

const Note = ({ className, children }: Props) => (
  <div className={twMerge('bg-blue-light rounded p-3', className)}>{children}</div>
);

export default Note;
