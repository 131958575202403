import { useRouter } from 'next/router';

import { inspirationPageSlugs, trackEvents } from '@boss/constants/b2b-b2c';
import { IInspirationImageSearch } from '@boss/types/b2b-b2c';
import { InspirationImage } from '@boss/ui';

import CommonSearchGrid from './commonSearchGrid';

type Props = {
  indexName: string;
  onUpdateTotalResults: (results: number | null) => void;
  query?: string;
};

const InspirationImageSearch = ({ indexName, onUpdateTotalResults: handleResultsUpdate, query }: Props) => {
  const { locale } = useRouter();

  return (
    <CommonSearchGrid<IInspirationImageSearch>
      indexName={indexName}
      onUpdateTotalResults={handleResultsUpdate}
      renderCard={(image, index) => (
        <InspirationImage
          asset={image?.image?.image}
          key={image.id}
          slug={`${inspirationPageSlugs[locale as string]}/${image.slug}`}
          trackInfo={{
            customEvent: trackEvents.SEARCH_RESULT_SELECTED,
            searchIndex: 'inspirationImages',
            resultName: image.slug,
            searchPosition: index + 1,
            searchQuery: query ?? '',
          }}
        />
      )}
      sortOptions={[]}
    />
  );
};

export default InspirationImageSearch;
