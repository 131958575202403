import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { twMerge } from 'tailwind-merge';

import Button from '../Button';
import ContactDetailLinks from '../ContactDetailLinks/contact-detail-links';
import { cardLayout } from '../StoreContactCard';

type Address = {
  street: string;
  streetnumber: number;
  zipcode: string;
  city: string;
  country: string;
};

type Translations = {
  call: string;
  mail: string;
};

export interface Props {
  firstname?: string;
  lastname?: string;
  address?: Address;
  phone?: string;
  officePhone?: string;
  fax?: string;
  email?: string;
  className?: string;
  translations: Translations;
  hideButtons?: boolean;
}

const actionButtonStyle = 'w-full text-center py-3';

const ContactCard = ({
  firstname,
  lastname,
  address,
  phone,
  officePhone,
  email,
  fax,
  className,
  translations,
  hideButtons,
}: Props) => {
  return (
    <div className={twMerge(cardLayout({ variant: 'secondary' }), 'flex flex-col gap-4', className)}>
      <div className="text-generic-blue text-xl font-bold">
        {firstname} {lastname}
      </div>
      <ContactDetailLinks address={address} email={email} fax={fax} officePhone={officePhone} phone={phone} />
      {(phone || email) && !hideButtons && (
        <div className="flex flex-col gap-3 md:flex-row">
          {phone && (
            <div className="w-full md:w-1/2">
              <Button
                className={actionButtonStyle}
                href={`tel:${phone}`}
                icon={faPhone}
                iconPosition="left"
                label={translations?.call}
                type="secondary"
              />
            </div>
          )}
          {email && (
            <div className="w-full md:w-1/2">
              <Button
                className={actionButtonStyle}
                href={`mailto:${email}`}
                icon={faEnvelope}
                iconPosition="left"
                label={translations?.mail}
                type="secondary"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContactCard;
