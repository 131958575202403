import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import { useRouter } from '@boss/hooks';

import {
  fetchAccountPriceInfoByProductId,
  fetchProductById,
  fetchProductsById,
  fetchSearchProductsById,
} from './connector';
import { useProfile } from '../../hooks';
import { isB2b } from '../../utils';

import { productKeys } from '.';

const useProductById = (id: string | undefined = '', enabled = true) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  return useQuery({
    queryKey: productKeys.productById(locale, id),
    queryFn: async () => await fetchProductById(locale, id),
    enabled: !!id && enabled,
    meta: {
      errorMessage: t('toast.productById.error.title'),
    },
  });
};

const useProductsById = (ids: string[], enabled = true) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  return useQuery({
    queryKey: productKeys.productsByIds(locale, ids),
    queryFn: async () => await fetchProductsById(locale, ids),
    enabled: enabled && ids?.length > 0,
    meta: {
      errorMessage: t('toast.productsById.error.title'),
    },
  });
};

/**
 * Fetch an array of ISearchProducts from Algolia
 * !! Use sparingly: this is only needed when you need the full enriched Productdata,
 *  for all other instances use the useProductById method, wich is much more cost efficient
 */
const useSearchProductsById = (ids: string[], enabled = true) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();

  return useQuery({
    queryKey: productKeys.searchProductsById(locale, ids),
    queryFn: async () => await fetchSearchProductsById(locale, ids),
    enabled: enabled && ids?.length > 0,
    meta: {
      errorMessage: t('toast.productsById.error.title'),
    },
  });
};

const useAccountPriceInfoByProductId = (productId: string, enabled = true) => {
  const { locale } = useRouter();
  const { isLoggedIn } = useProfile();
  const _isLoggedIn = isB2b ? true : isLoggedIn;

  return useQuery({
    queryKey: productKeys.accountPriceInfoByProductId(locale, productId, _isLoggedIn),
    queryFn: async () => await fetchAccountPriceInfoByProductId(productId, locale, _isLoggedIn),
    enabled: !!productId && enabled && (isB2b ? isLoggedIn : true),
  });
};

export { useSearchProductsById, useProductById, useProductsById, useAccountPriceInfoByProductId };
