import { useEffect, useState } from 'react';

type PWAType = {
  standalone: boolean;
  platform: string;
};

export enum Platforms {
  Ios = 'ios',
  Android = 'android',
}

/**
 * TODO: Add a description on what this hook does
 */
export const useIsPwa = () => {
  const [PWA] = useState<PWAType>();
  const [platform, setPlatform] = useState('');
  const isStandalone = () => {
    //if standalone is true , we never show the popup for ios or android
    return (
      // Cast to any needed. window.navigator.standalone is not a part of the W3C dom spec
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (typeof window !== 'undefined' && (window.navigator as any).standalone) ||
      (typeof window !== 'undefined' && window?.matchMedia('(display-mode: standalone)').matches)
    );
  };

  useEffect(() => {
    const isIos = () => {
      const userAgent = window?.navigator?.userAgent.toLowerCase();

      return /iphone|ipad|ipod/.test(userAgent);
    };

    setPlatform(isIos() ? Platforms.Ios : Platforms.Android);
  }, []);

  return { ...PWA, platform: platform, standalone: isStandalone() };
};

export default useIsPwa;
