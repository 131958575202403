import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  IconDefinition,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

/**
 * Represents the properties for the Alert component.
 * @typedef {Object} AlertProps
 * @property {'success' | 'warning' | 'error' | 'info'} type - The type of the alert (success, warning, error, info).
 * @property {ReactNode} children - The content to be displayed inside the alert.
 * @property {string} [title] - The title of the alert.
 * @property {string} [className] - The CSS class name(s) to be applied to the alert.
 * @property {string} [iconClassName] - The CSS class name(s) to be applied to the icon.
 * @property {IconDefinition} [icon] - The Icon that will overwrite the
 * @property {boolean} [closable] - Whether or not the alert can be closed.
 */

interface AlertProps {
  type?: 'success' | 'warning' | 'error' | 'info' | 'confirm';
  children: ReactNode;
  title?: string | ReactNode;
  className?: string;
  onClose?: () => void;
  icon?: IconDefinition;
  iconSize?: 'sm' | 'md' | 'xl';
  iconClassName?: string;
  iconVariant?: SizeProp;
  hideIcon?: boolean;
}

/**
 * Map of alert types to corresponding FontAwesome icons.
 * @type {Object}
 */

const iconMap = {
  success: faCircleCheck,
  warning: faCircleExclamation,
  error: faCircleXmark,
  info: faCircleInfo,
  confirm: faCircleCheck,
};

/**
 * CSS class generator for the Alert component based on the type.
 * @type {function}
 */

const AlertStyle = cva('relative p-4 rounded-brand-sm', {
  variants: {
    type: {
      success: 'bg-green-200 text-green-800',
      warning: 'bg-yellow-200 text-yellow-800',
      error: 'bg-red-200 text-red-800',
      info: 'bg-blue-200 text-blue-800',
      confirm: 'w-fit',
    },
  },
});

const IconStyling = cva('', {
  variants: {
    size: {
      sm: 'mr-0',
      md: 'mr-3',
      xl: 'mr-5 h-12 w-12',
    },
    type: {
      success: '',
      warning: '',
      error: '',
      info: '',
      confirm: 'text-success',
    },
  },
});

/**
 * Functional React component that displays an alert with an icon and content.
 * @param {AlertProps} props - The props for the Alert component.
 * @returns {JSX.Element} - The JSX representation of the Alert component.
 */

const Alert: React.FC<AlertProps> = ({
  type,
  icon: iconOverwrite,
  iconSize = 'md',
  iconClassName,
  iconVariant,
  children,
  title,
  className,
  onClose,
  hideIcon,
}) => {
  const icon = iconOverwrite ?? (type && iconMap[type]);

  return (
    <div className={twMerge(AlertStyle({ type }), className)} data-testid="alert-message">
      <div className="flex items-center">
        {icon && !hideIcon && (
          <div className={iconClassName}>
            <FontAwesomeIcon className={IconStyling({ size: iconSize, type })} icon={icon} size={iconVariant} />
          </div>
        )}
        <div>
          {title && <div className="text-lg font-semibold">{title}</div>}
          <p className="text-sm">{children}</p>
        </div>
        {onClose && (
          <button className="absolute right-3 top-3" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
      </div>
    </div>
  );
};

export default Alert;
