import { faPalette, faScrewdriverWrench } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';

interface Props {
  address: {
    street: string;
    number: string;
    zipcode: string;
    city: string;
  };
  adviceType: 'technical' | 'color';
  downloadLink: string;
  translations: {
    label: string;
    download: string;
  };
}

const labelStyle = cva('rounded px-4 py-2', {
  variants: {
    type: {
      technical: 'bg-yellow-200',
      color: 'bg-indigo-200',
    },
  },
});

const iconMap = {
  technical: faScrewdriverWrench,
  color: faPalette,
};

const AdviceCard = ({ address, adviceType, downloadLink, translations }: Props) => {
  return (
    <div className="shadow-m flex flex-col justify-between gap-2 bg-white p-5 md:flex-row md:items-center">
      <div className="text-2xl font-bold">
        <div>
          {address.street} {address.number}
        </div>
        <div>
          {address.zipcode} {address.city}
        </div>
      </div>
      <div className="flex flex-col gap-3 text-right">
        <div className="w-50 flex flex-col text-center">
          <span
            className={labelStyle({
              type: adviceType,
            })}
          >
            <span className="pr-2">
              <FontAwesomeIcon icon={iconMap[adviceType]} />
            </span>
            {translations.label}
          </span>
        </div>
        {downloadLink && (
          <a className="w-auto text-xl text-blue-500 underline" href={downloadLink} target="blank">
            {translations.download}
          </a>
        )}
      </div>
    </div>
  );
};

export default AdviceCard;
