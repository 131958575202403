import { ChangeEvent } from 'react';

import FormFieldWrapper, { BaseFieldProps } from '../FormFieldWrapper';
import InputAction from '../InputAction';

type Option = {
  value: string;
  label: string | JSX.Element;
  disabled?: boolean;
};

type Props = BaseFieldProps & {
  onChange: (values: string) => void;
  options: Option[];
  ariaLabel?: string;
  value?: string;
  fullWidth?: boolean;
  inputClassName?: string;
  optionClassName?: string;
};

export const RadioGroup = ({
  label,
  value,
  className,
  options,
  name,
  ariaLabel = 'radioGroup',
  onChange,
  touched,
  optionClassName,
  error,
  disclaimer,
  required,
  tooltip,
  id,
  fullWidth,
  inputClassName,
}: Props) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    return onChange(value);
  };

  return (
    <FormFieldWrapper
      className={className}
      disclaimer={disclaimer}
      error={error}
      id={id}
      label={label}
      name={name}
      required={required}
      tooltip={tooltip}
      touched={touched}
    >
      {options.map((option, i) => (
        <InputAction
          ariaLabel={ariaLabel}
          checked={value === option.value}
          className={optionClassName ?? 'mt-2'}
          disabled={option.disabled}
          fullWidth={fullWidth}
          id={`${id ?? name}-${option.value}`}
          inputClassName={inputClassName}
          key={`${name}-${option.value}-${i}`}
          name={name}
          onChange={handleChange}
          type="radio"
          value={option.value}
        >
          {option.label}
        </InputAction>
      ))}
    </FormFieldWrapper>
  );
};

export default RadioGroup;
