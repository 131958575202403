import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { DiscountTile, Repeat, Skeleton } from '@boss/ui';

import { useAccountDiscountInfo } from '../../../client-queries';
import { DISCOUNT_TYPES } from '../../../constants';

const Discounts = () => {
  const { locale } = useRouter();
  const { t } = useTranslation('account');
  const { data: defaultDiscounts, isLoading: defaultDiscountsLoading } = useAccountDiscountInfo(
    locale as string,
    DISCOUNT_TYPES.DEFAULT.code,
  );
  const { data: specificDiscounts, isLoading: specificDiscountsLoading } = useAccountDiscountInfo(
    locale as string,
    DISCOUNT_TYPES.SPECIFIC.code,
  );
  const { data: worksiteDiscounts, isLoading: worksiteDiscountsLoading } = useAccountDiscountInfo(
    locale as string,
    DISCOUNT_TYPES.WORKSITE.code,
  );

  const discounts = [
    {
      discounts: defaultDiscounts,
      isLoading: defaultDiscountsLoading,
      rgb: DISCOUNT_TYPES.DEFAULT.rgb,
      title: t('discount.default'),
    },
    {
      discounts: specificDiscounts,
      isLoading: specificDiscountsLoading,
      rgb: DISCOUNT_TYPES.SPECIFIC.rgb,
      title: t('discount.specific'),
    },
    {
      discounts: worksiteDiscounts,
      isLoading: worksiteDiscountsLoading,
      rgb: DISCOUNT_TYPES.WORKSITE.rgb,
      title: t('discount.worksite'),
    },
  ];

  return (
    <div>
      <h1 className="mb-5">{t('discount.title')}</h1>
      <div className="flex flex-col gap-7">
        {discounts.map(({ discounts, isLoading, rgb, title }) => (
          <div key={title}>
            <h2 className="mb-5 text-lg">{title}</h2>
            <div className="-wrap grid grid-cols-1 gap-5 md:grid-cols-3">
              {isLoading ? (
                <Repeat amount={3}>
                  <Skeleton className="h-50 w-50" />
                </Repeat>
              ) : discounts?.length ? (
                discounts?.map(discount => (
                  <DiscountTile
                    backgroundColor={rgb}
                    key={discount.title}
                    simpleDiscountAmount={discount.discountLabelInfo}
                    simpleTitle={discount.title}
                    variant="simple"
                  />
                ))
              ) : (
                <>{t('discount.noDiscounts')}</>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Discounts;
