import { cva } from 'class-variance-authority';
import { ChangeEvent, FocusEvent, InputHTMLAttributes, LegacyRef, ReactNode, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import FormFieldWrapper, { BaseFieldProps } from '../FormFieldWrapper';

export type InputFieldProps = React.InputHTMLAttributes<HTMLInputElement> &
  BaseFieldProps & {
    type?: InputHTMLAttributes<HTMLInputElement>['type'];
    variant?: 'light' | 'dark' | 'transparent';
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
    inputClassName?: string;
    value?: string;
    testId?: string;
    min?: string;
    max?: string;
    step?: string;
    children?: ReactNode;
    autocomplete?: string;
    disabled?: boolean;
  };

/**
 * Shared inputField styling
 **/
export const InputFieldStyles = cva(
  'bg-white rounded-brand-xs border-0 px-3 py-2 text-gray-dark w-full ring-inset ring-1 focus:ring-2 focus:ring-inset focus:ring-gray-light',
  {
    variants: {
      error: {
        true: '!ring-error !text-error !focus:ring-error',
      },
      variant: {
        light: 'ring-gray-light',
        dark: '!bg-brown-light ring-transparent',
        transparent: 'ring-transparent',
      },
      disabled: {
        true: 'bg-gray-lighter text-gray-dark',
      },
    },
  },
);

const InputField = forwardRef(
  (
    {
      className,
      disclaimer,
      error,
      inputClassName,
      label,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      placeholder = '',
      touched,
      type = 'text',
      value,
      required,
      tooltip,
      id,
      testId,
      variant = 'light',
      children,
      min,
      max,
      step,
      autocomplete,
      disabled,
    }: InputFieldProps,
    ref: LegacyRef<HTMLInputElement>,
  ) => (
    <FormFieldWrapper
      className={className}
      disclaimer={disclaimer}
      error={error}
      id={id}
      label={label}
      name={name}
      required={required}
      tooltip={tooltip}
      touched={touched}
      variant={variant}
    >
      <input
        autoComplete={autocomplete}
        className={twMerge(
          InputFieldStyles({
            error: !!error,
            variant: variant,
            disabled: disabled,
          }),
          inputClassName,
        )}
        data-testid={testId}
        disabled={disabled}
        id={id ?? name}
        max={max}
        min={min}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        ref={ref}
        step={step}
        type={type}
        value={value}
      />
      {children}
    </FormFieldWrapper>
  ),
);

InputField.displayName = 'InputField';

export default InputField;
